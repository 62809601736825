// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as ReactUtils$DraftbitBuilder from "../../utilities/ReactUtils.bs.js";
import * as DataCyWrapper$DraftbitBuilder from "../DataCyWrapper.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";

function PropertiesPanelIcon(Props) {
  var icon = Props.icon;
  var name = Props.name;
  var size = Props.size;
  var selectedOpt = Props.selected;
  var disabledOpt = Props.disabled;
  var dataCy = Props.dataCy;
  var selected = selectedOpt !== undefined ? selectedOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var dataCy$1 = Belt_Option.getWithDefault(dataCy, "");
  return React.createElement(DataCyWrapper$DraftbitBuilder.make, {
              children: React.createElement("div", {
                    className: "items-center justify-center text-center"
                  }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                        icon: icon,
                        className: Cn.make({
                              hd: Cn.ifTrue("text-mono-400 w-6 h-6", !selected),
                              tl: {
                                hd: Cn.ifTrue("text-mono-100 w-5 h-5", selected),
                                tl: {
                                  hd: Cn.ifTrue("text-mono-700", disabled),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        size: size
                      }), ReactUtils$DraftbitBuilder.ifTrueRender(selected, React.createElement("div", {
                            className: "text-mono-200 text-xs pt-px"
                          }, name))),
              name: dataCy$1,
              addId: true
            });
}

var make = PropertiesPanelIcon;

export {
  make ,
  
}
/* react Not a pure module */
