// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Text$DraftbitBuilder from "./Text/Text.bs.js";
import * as Tooltip$DraftbitBuilder from "./Tooltip/Tooltip.bs.js";

function InputReminderTooltip(Props) {
  var visible = Props.visible;
  var onClose = Props.onClose;
  var children = Props.children;
  return React.createElement(Tooltip$DraftbitBuilder.make, {
              placement: /* Bottom */3,
              size: /* Small */1,
              content: React.createElement(Text$DraftbitBuilder.make, {
                    color: "Mono200",
                    children: "Your changes will update when you click or tab out of this field or select another field",
                    kind: "B3"
                  }),
              visible: visible,
              onClose: onClose,
              className: "w-full flex items-center h-full",
              children: children
            });
}

var make = InputReminderTooltip;

export {
  make ,
  
}
/* react Not a pure module */
