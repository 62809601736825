// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as LodashIsequal from "lodash.isequal";
import * as LodashDebounce from "lodash.debounce";
import ReactFetchHook from "react-fetch-hook";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as LocalStorage from "@rehooks/local-storage";

function useMouseoverState(initialValue) {
  var match = React.useState(function () {
        return initialValue;
      });
  var setValue_ = match[1];
  var setValue = LodashDebounce((function (v) {
          return Curry._1(setValue_, (function (param) {
                        return v;
                      }));
        }), 250);
  return [
          match[0],
          setValue
        ];
}

function useEffect1(cb, obj) {
  React.useEffect((function () {
          Curry._1(cb, undefined);
          
        }), [obj]);
  
}

function useDebounce(value, delay) {
  var match = React.useState(function () {
        return value;
      });
  var setDebouncedValue = match[1];
  React.useEffect((function () {
          var handler = setTimeout((function (param) {
                  return Curry._1(setDebouncedValue, (function (param) {
                                return value;
                              }));
                }), delay);
          return (function (param) {
                    clearTimeout(handler);
                    
                  });
        }), [value]);
  return match[0];
}

function usePrevious(value) {
  var ref = React.useRef(value);
  React.useEffect((function () {
          ref.current = value;
          
        }), [value]);
  return ref.current;
}

function useDeepCompareEffect(onChange, dep) {
  var previousDep = usePrevious(dep);
  React.useEffect((function () {
          if (!LodashIsequal(dep, previousDep)) {
            Curry._1(onChange, undefined);
          }
          
        }), [dep]);
  
}

function useDeepCompareMemoize(value) {
  var ref = React.useRef(value);
  if (!LodashIsequal(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareMemo(factory, dep) {
  return React.useMemo((function () {
                return Curry._1(factory, undefined);
              }), [useDeepCompareMemoize(dep)]);
}

function usePageTitle(title) {
  React.useEffect((function () {
          var title$1 = title + " | Draftbit";
          document.title = title$1;
          
        }), [title]);
  
}

function useOnUpdate1(onChange, dep) {
  var mounted = React.useRef(true);
  React.useEffect((function () {
          if (!mounted.current) {
            Curry._1(onChange, undefined);
          }
          mounted.current = false;
          
        }), [dep]);
  
}

function useOnUpdate2(onChange, dep) {
  var mounted = React.useRef(true);
  React.useEffect((function () {
          if (!mounted.current) {
            Curry._1(onChange, undefined);
          }
          mounted.current = false;
          
        }), dep);
  
}

function useOnUpdate3(onChange, dep) {
  var mounted = React.useRef(true);
  React.useEffect((function () {
          if (!mounted.current) {
            Curry._1(onChange, undefined);
          }
          mounted.current = false;
          
        }), dep);
  
}

function useJsonStorage(key, initialValue, toJson, fromJson) {
  var match = LocalStorage.useLocalStorage(key, Curry._1(toJson, initialValue));
  var setJsonValue = match[1];
  var currentValue;
  try {
    currentValue = Curry._1(fromJson, match[0]);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error(key + ": failed to parse cached value", e);
    currentValue = initialValue;
  }
  return [
          currentValue,
          (function (newValue) {
              return Curry._1(setJsonValue, Curry._1(toJson, newValue));
            })
        ];
}

function useBoolStorage(key, initialValue) {
  return useJsonStorage(key, initialValue, (function (prim) {
                return prim;
              }), Json_decode.bool);
}

function useEnumStorage(key, initialValue, toString, fromString) {
  return useJsonStorage(key, initialValue, Curry.__1(toString), (function (j) {
                return Belt_Option.getWithDefault(Curry._1(fromString, Json_decode.string(j)), initialValue);
              }));
}

var $$Storage = {
  useJsonStorage: useJsonStorage,
  useBoolStorage: useBoolStorage,
  useEnumStorage: useEnumStorage
};

function useFetch(options, url) {
  return ReactFetchHook(url, options);
}

function useFetchJson(decode, options, url) {
  var res = ReactFetchHook(url, options);
  if (res.isLoading) {
    return "Loading";
  }
  var json = res.data;
  var err = res.error;
  if (err !== undefined) {
    return {
            NAME: "Error",
            VAL: Caml_option.valFromOption(err)
          };
  }
  if (json === undefined) {
    return "NoData";
  }
  try {
    return {
            NAME: "Data",
            VAL: Curry._1(decode, Caml_option.valFromOption(json))
          };
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Json_decode.DecodeError) {
      return {
              NAME: "DecodeError",
              VAL: e._1
            };
    }
    throw e;
  }
}

function eventTargetFromRef(ref) {
  return Belt_Option.map(Belt_Option.flatMap(Caml_option.nullable_to_opt(ref.current), Webapi__Dom__Element.asHtmlElement), (function (htmlElement) {
                return htmlElement;
              }));
}

function useHover(param) {
  var match = React.useState(function () {
        return false;
      });
  var setIsHovered = match[1];
  var ref = React.useRef(null);
  var onMouseOver = function (param) {
    return Curry._1(setIsHovered, (function (param) {
                  return true;
                }));
  };
  var onMouseOut = function (param) {
    return Curry._1(setIsHovered, (function (param) {
                  return false;
                }));
  };
  React.useEffect((function () {
          Belt_Option.forEach(eventTargetFromRef(ref), (function (eventTarget) {
                  eventTarget.addEventListener("mouseenter", onMouseOver);
                  eventTarget.addEventListener("mouseleave", onMouseOut);
                  
                }));
          return (function (param) {
                    Belt_Option.forEach(eventTargetFromRef(ref), (function (eventTarget) {
                            eventTarget.removeEventListener("mouseenter", onMouseOver);
                            eventTarget.removeEventListener("mouseleave", onMouseOut);
                            
                          }));
                    
                  });
        }), [ref.current]);
  return [
          ref,
          match[0]
        ];
}

var Hover = {
  eventTargetFromRef: eventTargetFromRef,
  useHover: useHover
};

export {
  useMouseoverState ,
  useEffect1 ,
  useDebounce ,
  usePrevious ,
  useDeepCompareEffect ,
  useDeepCompareMemoize ,
  useDeepCompareMemo ,
  usePageTitle ,
  useOnUpdate1 ,
  useOnUpdate2 ,
  useOnUpdate3 ,
  $$Storage ,
  useJsonStorage ,
  useBoolStorage ,
  useFetch ,
  useFetchJson ,
  Hover ,
  
}
/* react Not a pure module */
