// Generated by ReScript, PLEASE EDIT WITH CARE

import * as StringUtils$DraftbitBuilder from "../../../../../utilities/StringUtils.bs.js";

var _map = {"Colors":"Colors","Settings":"Settings"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var toLabel = StringUtils$DraftbitBuilder.pascalToWords;

var allTabs = [
  "Colors",
  "Settings"
];

export {
  tToJs ,
  tFromJs ,
  toLabel ,
  allTabs ,
  
}
/* StringUtils-DraftbitBuilder Not a pure module */
