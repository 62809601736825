// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as ReactRouterDom from "react-router-dom";
import * as App$DraftbitBuilder from "./App.bs.js";
import * as Notify$DraftbitBuilder from "./waterloo/components/Notify/Notify.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as Sentry$DraftbitBuilder from "./utilities/Sentry.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as LoadingSpinner$DraftbitBuilder from "./components/LoadingSpinner.bs.js";
import * as ErrorBoundaryInternal$DraftbitBuilder from "./components/ErrorBoundaryInternal.bs.js";

function AppRedirectToFirstScreen(Props) {
  var appUuid = Props.appUuid;
  var path = Props.path;
  var history = ReactRouterDom.useHistory();
  var appQuery = Curry._3(App$DraftbitBuilder.GQL.Get.useQuery, appUuid, false, undefined);
  React.useEffect((function () {
          Belt_Option.forEach(ApolloUtils$DraftbitBuilder.Query.data(appQuery), (function (param) {
                  var firstScreenUuid = App$DraftbitBuilder.getFirstScreenUuid(param.screens);
                  if (firstScreenUuid !== undefined) {
                    return Router$DraftbitBuilder.NavigateTo.screen(appUuid, firstScreenUuid, undefined, undefined, undefined, path, history);
                  } else {
                    console.warn("No firstScreenUuid on app " + appUuid + ", redirecting to dashboard");
                    return Router$DraftbitBuilder.NavigateTo.dashboard(history);
                  }
                }));
          
        }), [appQuery]);
  if (typeof appQuery !== "number" && appQuery.TAG === /* Error */1) {
    var error = appQuery._0;
    var match = Belt_Option.flatMap(Belt_Option.map(Belt_Option.flatMap(Caml_option.nullable_to_opt(error.graphQLErrors), ArrayUtils$DraftbitBuilder.first), (function (e) {
                return e.extensions;
              })), (function (ext) {
            return ext.code;
          }));
    var exit = 0;
    if (match !== undefined) {
      switch (match) {
        case "BAD_USER_INPUT" :
            Notify$DraftbitBuilder.failure(appUuid, "App " + appUuid + " was not found. Maybe it was deleted?");
            Router$DraftbitBuilder.NavigateTo.dashboard(history);
            break;
        case "PERMISSIONS_ERROR" :
            Notify$DraftbitBuilder.failure(appUuid, "You do not have permission to view this app.");
            Router$DraftbitBuilder.NavigateTo.dashboard(history);
            break;
        default:
          exit = 2;
      }
    } else {
      exit = 2;
    }
    if (exit === 2) {
      Sentry$DraftbitBuilder.captureException(error);
      Notify$DraftbitBuilder.failure(appUuid, error.message);
    }
    return React.createElement(ErrorBoundaryInternal$DraftbitBuilder.make, {
                error: error
              });
  }
  return React.createElement(LoadingSpinner$DraftbitBuilder.make, {
              theme: "Dark",
              inline: false
            });
}

var make = AppRedirectToFirstScreen;

export {
  make ,
  
}
/* react Not a pure module */
