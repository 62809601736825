/* TypeScript file generated from Analytics.resi by genType. */
/* eslint-disable import/first */


// tslint:disable-next-line:no-var-requires
const Curry = require('bs-platform/lib/es6/curry.mjs');

// tslint:disable-next-line:no-var-requires
const AnalyticsBS = require('./Analytics.bs');

import {Dict_t as Js_Dict_t} from '../../src/gentype-shims/Js.shim';

export const track: (_1:string) => void = AnalyticsBS.track;

export const trackWithProperties: <properties>(_1:string, _2:{ readonly properties: Js_Dict_t<properties> }) => void = function <properties>(Arg1: any, Arg2: any) {
  const result = Curry._2(AnalyticsBS.trackWithProperties, Arg1, Arg2.properties);
  return result
};

export const identify: <properties>(_1:string, _2:{ readonly properties: Js_Dict_t<properties> }) => void = function <properties>(Arg1: any, Arg2: any) {
  const result = Curry._2(AnalyticsBS.identify, Arg1, Arg2.properties);
  return result
};
