/* TypeScript file generated from Icon.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

const $$toRE30967458: { [key: string]: any } = {"XSmall": 0, "Small": 1, "Medium": 2, "Large": 3, "XLarge": 4};

const $$toRE458048662: { [key: string]: any } = {"ArrowCircleDown": 0, "ArrowCircleRight": 1, "ArrowCircleUp": 2, "ArrowToRight": 3, "Ban": 4, "BookOpen": 5, "BoxBallot": 6, "BracketsCurly": 7, "Browser": 8, "Check": 9, "CheckCircle": 10, "ChevronDown": 11, "ChevronLeft": 12, "ChevronRight": 13, "Clone": 14, "Code": 15, "CodeBranch": 16, "CodeSolid": 17, "Cog": 18, "Copy": 19, "Database": 20, "DatabaseDuotone": 21, "Ellipsis": 22, "EllipsisV": 23, "ExclamationTriangle": 24, "ExternalLink": 25, "ExternalLinkRegular": 26, "EyeDropper": 27, "Folder": 28, "FontCase": 29, "Image": 30, "InfoCircle": 31, "Keyboard": 32, "LayerGroup": 33, "Megaphone": 34, "Minus": 35, "Mobile": 36, "MobileSolid": 37, "MobileLight": 38, "PaperPlane": 39, "Pen": 40, "PenSquare": 41, "PhotoVideo": 42, "Play": 43, "PlayLivePreview": 44, "PlayCircle": 45, "Plus": 46, "PlusCircle": 47, "PlusCircleRegular": 48, "PlusCircleDuoTone": 49, "PlusCircleWorkspaceAppCard": 50, "PlusSquare": 51, "QuestionCircle": 52, "RedoDuoTone": 53, "Redo": 54, "Rocket": 55, "RocketSolid": 56, "Save": 57, "Search": 58, "ShareSquare": 59, "ShareSquareSolid": 60, "SiteMap": 61, "Stream": 62, "Times": 63, "ThLarge": 64, "TimesCircle": 65, "Trash": 66, "Undo": 67, "User": 68, "Users": 69, "AngleRight": 70, "AngleDown": 71, "Swatchbook": 72, "PaintBrush": 73, "SlidersVSquare": 74, "Magic": 75};

// tslint:disable-next-line:no-var-requires
const IconBS = require('./Icon.bs');

import {Mouse_t as ReactEvent_Mouse_t} from '../../../../src/gentype-shims/React.shim';

import {t as WL_Color_t} from '../../../../src/waterloo/WL_Color.gen';

// tslint:disable-next-line:interface-over-type-literal
export type size = 
    "XSmall"
  | "Small"
  | "Medium"
  | "Large"
  | "XLarge";

// tslint:disable-next-line:interface-over-type-literal
export type icon = 
    "ArrowCircleDown"
  | "ArrowCircleRight"
  | "ArrowCircleUp"
  | "ArrowToRight"
  | "Ban"
  | "BookOpen"
  | "BoxBallot"
  | "BracketsCurly"
  | "Browser"
  | "Check"
  | "CheckCircle"
  | "ChevronDown"
  | "ChevronLeft"
  | "ChevronRight"
  | "Clone"
  | "Code"
  | "CodeBranch"
  | "CodeSolid"
  | "Cog"
  | "Copy"
  | "Database"
  | "DatabaseDuotone"
  | "Ellipsis"
  | "EllipsisV"
  | "ExclamationTriangle"
  | "ExternalLink"
  | "ExternalLinkRegular"
  | "EyeDropper"
  | "Folder"
  | "FontCase"
  | "Image"
  | "InfoCircle"
  | "Keyboard"
  | "LayerGroup"
  | "Megaphone"
  | "Minus"
  | "Mobile"
  | "MobileSolid"
  | "MobileLight"
  | "PaperPlane"
  | "Pen"
  | "PenSquare"
  | "PhotoVideo"
  | "Play"
  | "PlayLivePreview"
  | "PlayCircle"
  | "Plus"
  | "PlusCircle"
  | "PlusCircleRegular"
  | "PlusCircleDuoTone"
  | "PlusCircleWorkspaceAppCard"
  | "PlusSquare"
  | "QuestionCircle"
  | "RedoDuoTone"
  | "Redo"
  | "Rocket"
  | "RocketSolid"
  | "Save"
  | "Search"
  | "ShareSquare"
  | "ShareSquareSolid"
  | "SiteMap"
  | "Stream"
  | "Times"
  | "ThLarge"
  | "TimesCircle"
  | "Trash"
  | "Undo"
  | "User"
  | "Users"
  | "AngleRight"
  | "AngleDown"
  | "Swatchbook"
  | "PaintBrush"
  | "SlidersVSquare"
  | "Magic";

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly color?: WL_Color_t; 
  readonly icon: icon; 
  readonly size: size; 
  readonly transform?: string
};

export const Icon: React.ComponentType<{
  readonly color?: WL_Color_t; 
  readonly icon: icon; 
  readonly size: size; 
  readonly transform?: string
}> = function Icon_Icon(Arg1: any) {
  const $props = {color:Arg1.color, icon:$$toRE458048662[Arg1.icon], size:$$toRE30967458[Arg1.size], transform:Arg1.transform};
  const result = React.createElement(IconBS.make, $props);
  return result
};

// tslint:disable-next-line:interface-over-type-literal
export type Button_IconButton_Props<dataCy> = {
  readonly alt: string; 
  readonly color?: WL_Color_t; 
  readonly dataCy?: dataCy; 
  readonly disabled?: boolean; 
  readonly icon: icon; 
  readonly onClick: (_1:ReactEvent_Mouse_t) => void; 
  readonly onMouseOver?: (_1:ReactEvent_Mouse_t) => void; 
  readonly size: size
};

export const Button_IconButton: React.ComponentType<{
  readonly alt: string; 
  readonly color?: WL_Color_t; 
  readonly dataCy?: any; 
  readonly disabled?: boolean; 
  readonly icon: icon; 
  readonly onClick: (_1:ReactEvent_Mouse_t) => void; 
  readonly onMouseOver?: (_1:ReactEvent_Mouse_t) => void; 
  readonly size: size
}> = function Icon_Button_IconButton<dataCy>(Arg1: any) {
  const $props = {alt:Arg1.alt, color:Arg1.color, dataCy:Arg1.dataCy, disabled:Arg1.disabled, icon:$$toRE458048662[Arg1.icon], onClick:Arg1.onClick, onMouseOver:Arg1.onMouseOver, size:$$toRE30967458[Arg1.size]};
  const result = React.createElement(IconBS.Button.make, $props);
  return result
};
