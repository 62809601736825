// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as StringUtils$DraftbitBuilder from "../utilities/StringUtils.bs.js";

function tToString(t) {
  if (typeof t !== "number") {
    if (t.TAG === /* Draftbit101Detail */0) {
      return "Draftbit101_" + t._0;
    } else {
      return "HowToVideos_" + t._0;
    }
  }
  switch (t) {
    case /* Draftbit101 */0 :
        return "Draftbit101";
    case /* HowToVideos */1 :
        return "HowToVideos";
    case /* Walkthroughs */2 :
        return "Walkthroughs";
    case /* ConsoleLogs */3 :
        return "ConsoleLogs";
    
  }
}

function tFromString(str) {
  switch (str) {
    case "ConsoleLogs" :
        return /* ConsoleLogs */3;
    case "Draftbit101" :
        return /* Draftbit101 */0;
    case "HowToVideos" :
        return /* HowToVideos */1;
    case "Walkthroughs" :
        return /* Walkthroughs */2;
    default:
      if (str.startsWith("Draftbit101_")) {
        return {
                TAG: /* Draftbit101Detail */0,
                _0: StringUtils$DraftbitBuilder.emptyIfNone(Belt_Array.get(str.split("_"), 1))
              };
      } else if (str.startsWith("HowToVideos_")) {
        return {
                TAG: /* HowToVideoDetail */1,
                _0: StringUtils$DraftbitBuilder.emptyIfNone(Belt_Array.get(str.split("_"), 1))
              };
      } else {
        return ;
      }
  }
}

var tabs = [{
    label: "Console Logs",
    tabType: /* ConsoleLogs */3
  }];

export {
  tToString ,
  tFromString ,
  tabs ,
  
}
/* StringUtils-DraftbitBuilder Not a pure module */
