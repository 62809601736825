const twcolors = require("tailwindcss/colors");

module.exports = {
  content: ["./src/**/*.{js,re,res,tsx}"],
  safelist: [
    {
      pattern:
        /bg-(brand|mono|primary|selection|accent|success|warning|failure)-[\d]{3}/,
    },
    {
      pattern:
        /text-(brand|mono|primary|selection|accent|success|warning|failure)-[\d]{3}/,
    },
    {
      pattern:
        /border-(brand|mono|primary|selection|accent|success|warning|failure)-[\d]{3}/,
    },
  ],
  theme: {
    colors: {
      current: "currentColor",
      transparent: "transparent",
      currentcolor: "currentColor",
      black: "#000000",
      white: "#FFFFFF",
      brand: "#5A45FF",
      mono: {
        /* Old Colors
        100: "#FFFFFF",
        200: "#D4DFED",
        300: "#B9C4D7",
        400: "#909FBF",
        500: "#617295",
        600: "#4F5D7F",
        700: "#3D4B6C",
        800: "#26324B",
        900: "#131A2A", */
        50: twcolors.slate[50], // #f8fafc
        100: twcolors.slate[100], // #f1f5f9
        200: twcolors.slate[200], // #e2e8f0
        300: twcolors.slate[300], // #cbd5e1
        400: twcolors.slate[400], // #94a3b8
        500: twcolors.slate[500], // #64748b
        600: twcolors.slate[600], // #475569
        700: twcolors.slate[700], // #334155
        800: twcolors.slate[800], // #1e293b
        900: twcolors.slate[900], // #0f172a
        950: twcolors.slate[950], // #020617
      },
      primary: twcolors.indigo /* {
        Old Colors -- Keeping because we still have them in places manually that need to be fixed.
        100: "#E0D9FF",
        200: "#8E7DFE",
        300: "#6C57FF",
        400: "#5A45FF",
        500: "#3F2DBD",
        600: "#27197E",
        700: "#120A3D",
        100: twcolors.indigo[100], // #e0e7ff
        200: twcolors.indigo[200], // #c7d2fe
        300: twcolors.indigo[300], // #a5b4fc
        400: twcolors.indigo[400], // #818cf8
        500: twcolors.indigo[500], // #6366f1
        600: twcolors.indigo[600], // #4f46e5
        700: twcolors.indigo[700], // #4338ca
      }, */,
      accent: twcolors.fuchsia,
      selection: twcolors.sky /* {
        Old Colors -- Keeping because we still have them in places manually that need to be fixed.
        100: "#D9F5FF",
        300: "#5CD0FA",
        400: "#08ABE7",
        500: "#007FAE",
        600: "#363096",
        700: "#032735",
        100: twcolors.sky[100], // #e0f2fe
        200: twcolors.sky[200], // #bae6fd
        300: twcolors.sky[300], // #7dd3fc
        400: twcolors.sky[400], // #38bdf8
        500: twcolors.sky[500], // #0ea5e9
        600: twcolors.sky[600], // #0284c7
        700: twcolors.sky[700], // #0369a1
      },*/,
      success: {
        100: "#D9FFDE",
        300: "#7CE288",
        400: "#37C148",
        500: "#24802F",
        700: "#144119",
      },
      warning: {
        100: "#FFF5D9",
        300: "#F9C266",
        400: "#F39637",
        500: "#C1560C",
        600: "#6C4C2E",
        700: "#541401",
      },
      failure: {
        100: "#FFD9E6",
        300: "#DB2A69",
        400: "#C90D4F",
        500: "#A9033E",
        700: "#6D0026",
      },
      // Tailwind Colors
      slate: twcolors.slate,
      gray: twcolors.gray,
      zinc: twcolors.zinc,
      neutral: twcolors.neutral,
      stone: twcolors.stone,
      red: twcolors.red,
      orange: twcolors.orange,
      amber: twcolors.amber,
      yellow: twcolors.yellow,
      lime: twcolors.lime,
      green: twcolors.green,
      emerald: twcolors.emerald,
      teal: twcolors.teal,
      cyan: twcolors.cyan,
      sky: twcolors.sky,
      blue: twcolors.blue,
      indigo: twcolors.indigo,
      violet: twcolors.violet,
      purple: twcolors.purple,
      fuchsia: twcolors.fuchsia,
      pink: twcolors.pink,
      rose: twcolors.rose,
    },
    fontFamily: {
      sans: ["Inter var, sans-serif"],
      mono: [
        "SFMono-Regular",
        "Fira Code",
        "Iosevka",
        "Menlo",
        "Monaco",
        "Consolas",
        "Liberation Mono",
        "Courier New",
        "monospace",
      ],
    },
    fontSize: {
      xs: "0.625rem", // 10px
      sm: "0.75rem", // 12px
      md: "0.875rem", // 14px
      lg: "1rem", // 16px
      xl: "1.5rem", // 24px
      "2xl": "2rem", // 32px
      "3xl": "3rem", // 48px
      "4xl": "4rem", // 64px
      "5xl": "5rem", // 80px
    },
    extend: {
      letterSpacing: {
        tighter: "-0.05em",
        tight: "-0.025em",
        normal: "0",
        wide: "0.05em",
        wider: "0.1em",
        widest: "0.2em",
      },
      opacity: {
        0: "0",
        10: "0.1",
        20: "0.2",
        40: "0.4",
        60: "0.6",
        80: "0.8",
        100: "1",
        initial: "initial",
      },
      rotate: {
        "-90": "-90deg",
        90: "90deg",
        180: "180deg",
        270: "270deg",
      },
      boxShadow: {
        DEFAULT: "0 2px 4px rgba(15, 23, 42, 0.2)", // theme.colors.mono-900
        md: "0 8px 16px rgba(15, 23, 42, 0.2)", // theme.colors.mono-900
        outline: "0 0 0 rgba(15, 23, 42, 0.4)", // theme.colors.mono-900
        none: "none",
      },
      borderRadius: {
        none: "0",
        DEFAULT: "0.25rem", // 4px
        full: "999px",
      },
      borderWidth: {
        0: "0",
        DEFAULT: "1px",
        2: "2px",
        4: "4px",
        8: "8px",
      },
      spacing: {
        0: "0",
        px: "1px",
        "6px": "6px",
        "10px": "10px",
        1: "0.25rem", // 4px
        2: "0.5rem", // 8px
        3: "0.75rem", // 12px
        4: "1rem", // 16px
        6: "1.5rem", // 24px
        7: "1.875rem", // 30px
        8: "2rem", // 32px
        9: "2.25rem", // 36px
        10: "2.5rem", // 40px
        12: "3rem", // 48px
        14: "3.5rem", // 56px
        16: "4rem", // 64px
        18: "4.5rem", // 72px
        20: "5rem", // 80px
        22: "5.5rem", // 88px
        24: "6rem", // 96px
        26: "6.5rem", // 104px
        28: "7rem", // 112px
        32: "8rem", // 128px
        36: "9rem", // 144px
        40: "10rem", // 160px
        44: "11rem", // 176px
        48: "12rem", // 192px
        52: "13rem", // 208px
        56: "14rem", // 224px
        60: "15rem", // 240px
        64: "16rem", // 256px
        72: "18rem", // 288px
        80: "20rem", // 320px
        88: "22rem", // 352px
        96: "24rem", // 384px
        104: "26rem", // 416px
        120: "30rem", // 480px
        128: "32rem", // 512px
        136: "34rem", // 544px
        144: "36rem", // 576px
        160: "40rem", // 640px
        200: "50rem", // 800px
        "9/10": "90%",
      },
      height: {
        22: "5.5rem",
      },
      width: {
        22: "5.5rem",
      },
      maxHeight: {
        3: "0.75rem", // 12px
        4: "1rem", // 16px
        6: "1.5rem", // 24px
        8: "2rem", // 32px
        12: "3rem",
        20: "5rem",
        32: "8rem", // 128px
        40: "10rem", // 160px
        48: "12rem", // 192px
        56: "14rem", // 224px
        64: "16rem", // 256px
        72: "18rem", // 288px
        80: "20rem", // 320px
        88: "22rem", // 352px
        96: "24rem", // 384px
        104: "26rem", // 416px
        120: "30rem", // 480px
        160: "40rem", // 640pxc
        200: "50rem", // 800px
        xs: "20rem",
        sm: "24rem",
        md: "28rem",
        lg: "32rem",
        xl: "36rem",
        "2xl": "42rem",
        "3xl": "48rem",
        "4xl": "56rem",
        "5xl": "64rem",
        "6xl": "72rem",
        full: "100%",
        screen: "100vh",
      },
      maxWidth: {
        3: "0.75rem", // 12px
        4: "1rem", // 16px
        6: "1.5rem", // 24px
        8: "2rem", // 32px
        16: "4rem", // 64px
        48: "12rem", // 192px
        96: "24rem", // 384px
        360: "90rem", // 1440px
        "1/2": "50%",
        "2/3": "66.6666%",
        "3/4": "75%",
        "6/7": "85%",
        full: "100%",
      },
      minHeight: {
        0: "0",
        4: "1rem", // 16px
        6: "1.5rem", // 24px
        8: "2rem", // 32px
        12: "3rem",
        20: "5rem",
        32: "8rem", // 128px
        40: "10rem", // 160px
      },
      minWidth: {
        0: "0",
        8: "2rem", // 32px
        12: "3rem", // 48px
        16: "4rem", // 64px
        20: "5rem", // 80px
        24: "6rem", // 96px
        32: "8rem", // 128px
        40: "10rem", // 160px
        48: "12rem", // 192px
        56: "14rem", // 224px
        64: "16rem", // 256px
        72: "18rem", // 288px
        80: "20rem", // 320px
        88: "22rem", // 352px
        96: "24rem", // 384px
        104: "26rem", // 416px
        120: "30rem", // 480px
        200: "50rem", // 800px
        full: "100%",
      },
      zIndex: {
        0: "0",
        10: "10",
        20: "20",
        30: "30",
        40: "40",
        50: "50",
        1200: "1200",
      },
    },
  },
  plugins: [],
};
