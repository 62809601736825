// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

var _map = {"Mobile":"Mobile","Tablet":"Tablet","Laptop":"Laptop","Desktop":"Desktop","BigScreen":"BigScreen"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function t_encode(b) {
  return b;
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("Breakpoint.t", tFromJs, param);
}

var allBreakpoints = [
  "Mobile",
  "Tablet",
  "Laptop",
  "Desktop",
  "BigScreen"
];

function getBreakpointsUpTo(bp) {
  var idx = allBreakpoints.indexOf(bp);
  return allBreakpoints.slice(0, idx + 1 | 0);
}

var getLabel = StringUtils$DraftbitBuilder.pascalToWords;

function getMinWidth(b) {
  if (b === "Mobile") {
    return 0;
  } else if (b === "Laptop") {
    return 992;
  } else if (b === "BigScreen") {
    return 1920;
  } else if (b === "Tablet") {
    return 480;
  } else {
    return 1440;
  }
}

function breakpointFromString(str) {
  return Belt_Option.getWithDefault(tFromJs(str), "Mobile");
}

function isPhone(breakpoint) {
  if (breakpoint === "Mobile") {
    return true;
  } else {
    return breakpoint === "Tablet";
  }
}

var breakpointDict = Js_dict.fromArray(allBreakpoints.map(function (b) {
          return [
                  b,
                  getMinWidth(b)
                ];
        }));

var breakpointsJsFile = "export default " + JSON.stringify(Json_encode.dict((function (prim) {
            return prim;
          }), breakpointDict));

export {
  tToJs ,
  tFromJs ,
  t_encode ,
  t_decode ,
  allBreakpoints ,
  getBreakpointsUpTo ,
  getLabel ,
  getMinWidth ,
  breakpointFromString ,
  isPhone ,
  breakpointDict ,
  breakpointsJsFile ,
  
}
/* breakpointDict Not a pure module */
