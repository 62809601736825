// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as ReactRouterDom from "react-router-dom";
import * as Text$DraftbitBuilder from "./waterloo/components/Text/Text.bs.js";
import * as Utils$DraftbitBuilder from "./utilities/Utils.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as DataCyDiv$DraftbitBuilder from "./components/DataCyDiv.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as ContextMenu$DraftbitBuilder from "./waterloo/components/ContextMenu.bs.js";
import * as OptionUtils$DraftbitBuilder from "./utilities/OptionUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

function getUsages(components) {
  return Belt_Array.keepMap(ArrayUtils$DraftbitBuilder.uniqBy(components, (function (c) {
                      return c.uuid;
                    })).filter(function (c) {
                  return c.deletedAt === undefined;
                }), (function (c) {
                return Belt_Option.map(OptionUtils$DraftbitBuilder.noneIf(c.screen, (function (s) {
                                  return s.deletedAt !== undefined;
                                })), (function (s) {
                              return [
                                      s,
                                      c
                                    ];
                            }));
              }));
}

function isUsed(components) {
  return getUsages(components).length > 0;
}

function renderUsageShort(components) {
  var usages = getUsages(components);
  var screenUuids = ArrayUtils$DraftbitBuilder.uniqBy(usages, (function (param) {
          return param[0].uuid;
        }));
  return StringUtils$DraftbitBuilder.renderLength(usages, "component") + ", " + StringUtils$DraftbitBuilder.renderLength(screenUuids, "screen");
}

function renderUsageLong(components, singleScreenOpt, objectName) {
  var singleScreen = singleScreenOpt !== undefined ? singleScreenOpt : false;
  var usages = getUsages(components);
  if (usages.length === 0) {
    return "This " + objectName + " is not currently used by any components";
  }
  var screenUuids = ArrayUtils$DraftbitBuilder.uniqBy(usages, (function (param) {
          return param[0].uuid;
        }));
  var componentCount = StringUtils$DraftbitBuilder.renderLength(usages, "component");
  return "This " + objectName + " is used by " + componentCount + (
          singleScreen ? "" : " on " + StringUtils$DraftbitBuilder.renderLength(screenUuids, "screen")
        );
}

function getContextMenuOptions(appUuid, history, components, singleScreenOpt, param) {
  var singleScreen = singleScreenOpt !== undefined ? singleScreenOpt : false;
  return Belt_List.fromArray(getUsages(components).map(function (param) {
                  var c = param[1];
                  var s = param[0];
                  return ContextMenu$DraftbitBuilder.makeOption(c.name, undefined, undefined, undefined, undefined, OptionUtils$DraftbitBuilder.someIf(!singleScreen, s.name), undefined, {
                              TAG: /* CloseAfter */1,
                              _0: (function (param) {
                                  return Router$DraftbitBuilder.NavigateTo.screen(appUuid, s.uuid, c.uuid, undefined, undefined, undefined, history);
                                })
                            });
                }));
}

var ppx_printed_query = "fragment ComponentUsageFields on Component   {\n__typename\n__typename  \nuuid  \nname  \ndeletedAt  \nscreen  {\n__typename\n__typename  \nuuid  \nname  \ndeletedAt  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type Component, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type Component is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type Component is missing");
  }
  var value$9 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_name = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type Component is missing");
  }
  var value$12 = Js_dict.get(value$2, "deletedAt");
  var field_deletedAt;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var match = Js_json.decodeNull(value$13);
    field_deletedAt = match !== undefined ? undefined : Caml_option.some(value$13);
  } else {
    field_deletedAt = undefined;
  }
  var value$14 = Js_dict.get(value$2, "screen");
  var field_screen;
  if (value$14 !== undefined) {
    var value$15 = Caml_option.valFromOption(value$14);
    var match$1 = Js_json.decodeNull(value$15);
    if (match$1 !== undefined) {
      field_screen = undefined;
    } else {
      var value$16 = Js_json.decodeObject(value$15);
      var tmp;
      if (value$16 !== undefined) {
        var value$17 = Caml_option.valFromOption(value$16);
        var value$18 = Js_dict.get(value$17, "__typename");
        var field___typename$1;
        if (value$18 !== undefined) {
          var value$19 = Caml_option.valFromOption(value$18);
          var value$20 = Js_json.decodeString(value$19);
          field___typename$1 = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
        } else {
          field___typename$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type Screen is missing");
        }
        var value$21 = Js_dict.get(value$17, "uuid");
        var field_uuid$1;
        if (value$21 !== undefined) {
          var value$22 = Caml_option.valFromOption(value$21);
          var value$23 = Js_json.decodeString(value$22);
          field_uuid$1 = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$22));
        } else {
          field_uuid$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type Screen is missing");
        }
        var value$24 = Js_dict.get(value$17, "name");
        var field_name$1;
        if (value$24 !== undefined) {
          var value$25 = Caml_option.valFromOption(value$24);
          var value$26 = Js_json.decodeString(value$25);
          field_name$1 = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$25));
        } else {
          field_name$1 = Js_exn.raiseError("graphql_ppx: Field name on type Screen is missing");
        }
        var value$27 = Js_dict.get(value$17, "deletedAt");
        var field_deletedAt$1;
        if (value$27 !== undefined) {
          var value$28 = Caml_option.valFromOption(value$27);
          var match$2 = Js_json.decodeNull(value$28);
          field_deletedAt$1 = match$2 !== undefined ? undefined : Caml_option.some(value$28);
        } else {
          field_deletedAt$1 = undefined;
        }
        tmp = {
          __typename: field___typename$1,
          uuid: field_uuid$1,
          name: field_name$1,
          deletedAt: field_deletedAt$1
        };
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Expected object of type Screen, got " + JSON.stringify(value$15));
      }
      field_screen = tmp;
    }
  } else {
    field_screen = undefined;
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          name: field_name,
          screen: field_screen,
          deletedAt: field_deletedAt
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ComponentUsageFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "ComponentUsageFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  ComponentUsageFields: ComponentUsageFields
};

var GQL = {
  Fragment: Fragment
};

function ComponentUsage$UsageMenu(Props) {
  var objectName = Props.objectName;
  var components = Props.components;
  var singleScreenOpt = Props.singleScreen;
  var onCloseOpt = Props.onClose;
  var maxHeightClass = Props.maxHeightClass;
  var textClassOpt = Props.textClass;
  var singleScreen = singleScreenOpt !== undefined ? singleScreenOpt : false;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : Utils$DraftbitBuilder.noop;
  var textClass = textClassOpt !== undefined ? textClassOpt : "";
  var match = Router$DraftbitBuilder.getParams(undefined);
  var history = ReactRouterDom.useHistory();
  var options = getContextMenuOptions(match.appUuid, history, components, singleScreen, undefined);
  var tmp = {
    onClose: onClose,
    options: options,
    sortGroups: (function (prim) {
        return prim[0];
      })
  };
  if (maxHeightClass !== undefined) {
    tmp.maxHeightClass = Caml_option.valFromOption(maxHeightClass);
  }
  return React.createElement(DataCyDiv$DraftbitBuilder.make, {
              dataCy: objectName + "Usage",
              children: null
            }, React.createElement("div", {
                  className: textClass
                }, React.createElement(Text$DraftbitBuilder.make, {
                      color: "Mono50",
                      children: renderUsageLong(components, singleScreen, objectName) + ".",
                      kind: "B3"
                    })), React.createElement(ContextMenu$DraftbitBuilder.make, tmp));
}

var UsageMenu = {
  make: ComponentUsage$UsageMenu
};

export {
  getUsages ,
  isUsed ,
  renderUsageShort ,
  renderUsageLong ,
  getContextMenuOptions ,
  GQL ,
  UsageMenu ,
  
}
/* react Not a pure module */
