// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";

var _map = {"local":"local","staging":"staging","production":"production"};

function envToJs(param) {
  return param;
}

function envFromJs(param) {
  return _map[param];
}

var env = Belt_Option.flatMap(process.env.REACT_APP_ENV, envFromJs);

var _map$1 = {"development":"development","test":"test","production":"production"};

function nodeEnvToJs(param) {
  return param;
}

function nodeEnvFromJs(param) {
  return _map$1[param];
}

var nodeEnv = Belt_Option.flatMap(process.env.NODE_ENV, nodeEnvFromJs);

var isDev = Caml_obj.caml_equal(nodeEnv, "development");

var expoAccessToken = process.env.REACT_APP_EXPO_ACCESS_TOKEN;

var turnstileKey = process.env.REACT_APP_CLOUDFLARE_TURNSTILE_KEY;

export {
  envToJs ,
  envFromJs ,
  env ,
  nodeEnvToJs ,
  nodeEnvFromJs ,
  nodeEnv ,
  isDev ,
  expoAccessToken ,
  turnstileKey ,
  
}
/* env Not a pure module */
