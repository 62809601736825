// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as LodashIsequal from "lodash.isequal";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";
import * as Hooks$DraftbitBuilder from "./hooks/Hooks.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as Sentry$DraftbitBuilder from "./utilities/Sentry.bs.js";
import * as ImportGQL$DraftbitBuilder from "./ImportGQL.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";
import * as ApolloClient$DraftbitBuilder from "./bindings/ReasonApollo/ApolloClient.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./utilities/PromiseUtils.bs.js";
import * as ScreenVariable$DraftbitBuilder from "./ScreenVariable.bs.js";
import * as ScreenNavigationParam$DraftbitBuilder from "./ScreenNavigationParam.bs.js";

var ppx_printed_query = "fragment SnackScreenFields on Screen   {\n__typename\n__typename  \nuuid  \nblockType  \nname  \nslug  \ncode  \nsnapshotHash  \nwebPreviewCode  \ndeepLink  \nisHidden  \nnavigationParams  {\n__typename\n..." + ScreenNavigationParam$DraftbitBuilder.GQL.Fragment.ScreenNavigationParamFields.name + "   \n}\n\nvariables  {\n__typename\n..." + ScreenVariable$DraftbitBuilder.GQL.Fragment.ScreenVariableFields.name + "   \n}\n\nfonts  {\n__typename\nfamily  \nvariant  \n}\n\n}\n" + ScreenNavigationParam$DraftbitBuilder.GQL.Fragment.ScreenNavigationParamFields.query + ScreenVariable$DraftbitBuilder.GQL.Fragment.ScreenVariableFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type Screen, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type Screen is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type Screen is missing");
  }
  var value$9 = Js_dict.get(value$2, "blockType");
  var field_blockType;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    if (value$11 !== undefined) {
      switch (value$11) {
        case "Block" :
            field_blockType = "Block";
            break;
        case "Screen" :
            field_blockType = "Screen";
            break;
        default:
          field_blockType = Js_exn.raiseError("graphql_ppx: Unknown enum variant for BlockType: " + value$11);
      }
    } else {
      field_blockType = Js_exn.raiseError("graphql_ppx: Expected enum value for BlockType, got " + JSON.stringify(value$10));
    }
  } else {
    field_blockType = Js_exn.raiseError("graphql_ppx: Field blockType on type Screen is missing");
  }
  var value$12 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    field_name = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type Screen is missing");
  }
  var value$15 = Js_dict.get(value$2, "slug");
  var field_slug;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeString(value$16);
    field_slug = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
  } else {
    field_slug = Js_exn.raiseError("graphql_ppx: Field slug on type Screen is missing");
  }
  var value$18 = Js_dict.get(value$2, "code");
  var field_code;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeString(value$19);
    field_code = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
  } else {
    field_code = Js_exn.raiseError("graphql_ppx: Field code on type Screen is missing");
  }
  var value$21 = Js_dict.get(value$2, "snapshotHash");
  var field_snapshotHash;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var value$23 = Js_json.decodeString(value$22);
    field_snapshotHash = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$22));
  } else {
    field_snapshotHash = Js_exn.raiseError("graphql_ppx: Field snapshotHash on type Screen is missing");
  }
  var value$24 = Js_dict.get(value$2, "webPreviewCode");
  var field_webPreviewCode;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var value$26 = Js_json.decodeString(value$25);
    field_webPreviewCode = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$25));
  } else {
    field_webPreviewCode = Js_exn.raiseError("graphql_ppx: Field webPreviewCode on type Screen is missing");
  }
  var value$27 = Js_dict.get(value$2, "deepLink");
  var field_deepLink;
  if (value$27 !== undefined) {
    var value$28 = Caml_option.valFromOption(value$27);
    var value$29 = Js_json.decodeBoolean(value$28);
    field_deepLink = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$28));
  } else {
    field_deepLink = Js_exn.raiseError("graphql_ppx: Field deepLink on type Screen is missing");
  }
  var value$30 = Js_dict.get(value$2, "isHidden");
  var field_isHidden;
  if (value$30 !== undefined) {
    var value$31 = Caml_option.valFromOption(value$30);
    var value$32 = Js_json.decodeBoolean(value$31);
    field_isHidden = value$32 !== undefined ? value$32 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$31));
  } else {
    field_isHidden = Js_exn.raiseError("graphql_ppx: Field isHidden on type Screen is missing");
  }
  var value$33 = Js_dict.get(value$2, "navigationParams");
  var field_navigationParams = value$33 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$33))).map(function (value) {
          return Curry._1(ScreenNavigationParam$DraftbitBuilder.GQL.Fragment.ScreenNavigationParamFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field navigationParams on type Screen is missing");
  var value$34 = Js_dict.get(value$2, "variables");
  var field_variables = value$34 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$34))).map(function (value) {
          return Curry._1(ScreenVariable$DraftbitBuilder.GQL.Fragment.ScreenVariableFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field variables on type Screen is missing");
  var value$35 = Js_dict.get(value$2, "fonts");
  var field_fonts = value$35 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$35))).map(function (value) {
          var value$1 = Js_json.decodeObject(value);
          if (value$1 === undefined) {
            return Js_exn.raiseError("graphql_ppx: Expected object of type SelectedFont, got " + JSON.stringify(value));
          }
          var value$2 = Caml_option.valFromOption(value$1);
          var value$3 = Js_dict.get(value$2, "family");
          var field_family;
          if (value$3 !== undefined) {
            var value$4 = Caml_option.valFromOption(value$3);
            var value$5 = Js_json.decodeString(value$4);
            field_family = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
          } else {
            field_family = Js_exn.raiseError("graphql_ppx: Field family on type SelectedFont is missing");
          }
          var value$6 = Js_dict.get(value$2, "variant");
          var field_variant;
          if (value$6 !== undefined) {
            var value$7 = Caml_option.valFromOption(value$6);
            var value$8 = Js_json.decodeString(value$7);
            if (value$8 !== undefined) {
              switch (value$8) {
                case "FV100" :
                    field_variant = "FV100";
                    break;
                case "FV100italic" :
                    field_variant = "FV100italic";
                    break;
                case "FV200" :
                    field_variant = "FV200";
                    break;
                case "FV200italic" :
                    field_variant = "FV200italic";
                    break;
                case "FV300" :
                    field_variant = "FV300";
                    break;
                case "FV300italic" :
                    field_variant = "FV300italic";
                    break;
                case "FV400" :
                    field_variant = "FV400";
                    break;
                case "FV400italic" :
                    field_variant = "FV400italic";
                    break;
                case "FV500" :
                    field_variant = "FV500";
                    break;
                case "FV500italic" :
                    field_variant = "FV500italic";
                    break;
                case "FV600" :
                    field_variant = "FV600";
                    break;
                case "FV600italic" :
                    field_variant = "FV600italic";
                    break;
                case "FV700" :
                    field_variant = "FV700";
                    break;
                case "FV700italic" :
                    field_variant = "FV700italic";
                    break;
                case "FV800" :
                    field_variant = "FV800";
                    break;
                case "FV800italic" :
                    field_variant = "FV800italic";
                    break;
                case "FV900" :
                    field_variant = "FV900";
                    break;
                case "FV900italic" :
                    field_variant = "FV900italic";
                    break;
                case "FV950" :
                    field_variant = "FV950";
                    break;
                case "FV950italic" :
                    field_variant = "FV950italic";
                    break;
                default:
                  field_variant = Js_exn.raiseError("graphql_ppx: Unknown enum variant for FontVariant: " + value$8);
              }
            } else {
              field_variant = Js_exn.raiseError("graphql_ppx: Expected enum value for FontVariant, got " + JSON.stringify(value$7));
            }
          } else {
            field_variant = Js_exn.raiseError("graphql_ppx: Field variant on type SelectedFont is missing");
          }
          return {
                  family: field_family,
                  variant: field_variant
                };
        }) : Js_exn.raiseError("graphql_ppx: Field fonts on type Screen is missing");
  return {
          __typename: field___typename,
          uuid: field_uuid,
          blockType: field_blockType,
          name: field_name,
          slug: field_slug,
          code: field_code,
          snapshotHash: field_snapshotHash,
          webPreviewCode: field_webPreviewCode,
          deepLink: field_deepLink,
          navigationParams: field_navigationParams,
          variables: field_variables,
          fonts: field_fonts,
          isHidden: field_isHidden
        };
}

var name = "SnackScreenFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var SnackScreenFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ScreenFragment = {
  SnackScreenFields: SnackScreenFields
};

var ppx_printed_query$1 = "query Screen($screenUuid: ID!)  {\n__typename\nscreen(screenUuid: $screenUuid)  {\n__typename\n...SnackScreenFields   \n}\n\n}\n" + ppx_printed_query;

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "screen");
  return {
          screen: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field screen on type Query is missing")
        };
}

function make(screenUuid, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "screenUuid",
                    screenUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var screenUuid = variables.screenUuid;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "screenUuid",
                    screenUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(screenUuid, param) {
  return Js_dict.fromArray([[
                  "screenUuid",
                  screenUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, screenUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "screenUuid",
                      screenUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var GetScreen = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function modifySnackScreenInCache(client, screenUuid, modify) {
  ApolloClient$DraftbitBuilder.ReadQuery({
        query: ppx_printed_query$1,
        parse: parse$1
      });
  var ScreenWriteQuery = ApolloClient$DraftbitBuilder.WriteQuery({
        query: ppx_printed_query$1,
        parse: parse$1
      });
  var query = make(screenUuid, undefined);
  var options = ApolloHooks$DraftbitBuilder.toQueryObj(query);
  var cachedData;
  try {
    cachedData = Caml_option.nullable_to_opt(client.readQuery(options));
  }
  catch (raw_e){
    return Sentry$DraftbitBuilder.captureException(Caml_js_exceptions.internalToOCamlException(raw_e));
  }
  if (cachedData === undefined) {
    return Sentry$DraftbitBuilder.captureMessage("SnackScreen read query returned no data");
  }
  var cachedScreen = Caml_option.valFromOption(cachedData).screen;
  var screen = Curry._1(modify, cachedScreen);
  return Curry._4(ScreenWriteQuery.make, client, Caml_option.some(query.variables), {
              screen: screen
            }, undefined);
}

var ppx_printed_query$2 = "query SnackScreens($appUuid: ID!, $isDeleted: Boolean!)  {\n__typename\n__typename  \nscreens(appUuid: $appUuid, isDeleted: $isDeleted)  {\n__typename\n...SnackScreenFields   \n}\n\n}\n" + ppx_printed_query;

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "__typename");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type Query is missing");
  }
  var value$5 = Js_dict.get(value$1, "screens");
  return {
          __typename: tmp,
          screens: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map(parse) : Js_exn.raiseError("graphql_ppx: Field screens on type Query is missing")
        };
}

function make$1(appUuid, isDeleted, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "isDeleted",
                    isDeleted
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$1(variables) {
  var appUuid = variables.appUuid;
  var isDeleted = variables.isDeleted;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "isDeleted",
                    isDeleted
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$1(appUuid, isDeleted, param) {
  return Js_dict.fromArray([
                [
                  "appUuid",
                  appUuid
                ],
                [
                  "isDeleted",
                  isDeleted
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, appUuid, isDeleted, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "appUuid",
                      appUuid
                    ],
                    [
                      "isDeleted",
                      isDeleted
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$1
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

function useQuery(appUuid, skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables$1(appUuid, false, undefined)), undefined, undefined, undefined, skip, undefined, undefined, definition$1);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (r) {
                return r.screens.filter(function (s) {
                            return !s.isHidden;
                          });
              }));
}

function refetch(appUuid, isDeleted) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make$1(appUuid, isDeleted, undefined));
}

var ListScreens = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2,
  useQuery: useQuery,
  refetch: refetch
};

var ppx_printed_query$3 = "query SnackFiles($appUuid: ID!, $env: PublicationEnvironment!)  {\n__typename\napp(appUuid: $appUuid)  {\n__typename\n__typename  \nuuid  \nimports  {\n__typename\n..." + ImportGQL$DraftbitBuilder.Fragment.ImportFields.name + "   \n}\n\nsnackFiles(env: $env)  {\n__typename\n__typename  \nname  \npath  \ntype_: type  \ncontents  \n}\n\n}\n\n}\n" + ImportGQL$DraftbitBuilder.Fragment.ImportFields.query;

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "app");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeObject(value$3);
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_dict.get(value$5, "__typename");
      var field___typename;
      if (value$6 !== undefined) {
        var value$7 = Caml_option.valFromOption(value$6);
        var value$8 = Js_json.decodeString(value$7);
        field___typename = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
      } else {
        field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
      }
      var value$9 = Js_dict.get(value$5, "uuid");
      var field_uuid;
      if (value$9 !== undefined) {
        var value$10 = Caml_option.valFromOption(value$9);
        var value$11 = Js_json.decodeString(value$10);
        field_uuid = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
      } else {
        field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
      }
      var value$12 = Js_dict.get(value$5, "imports");
      var field_imports = value$12 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$12))).map(function (value) {
              return Curry._1(ImportGQL$DraftbitBuilder.Fragment.ImportFields.parse, value);
            }) : Js_exn.raiseError("graphql_ppx: Field imports on type App is missing");
      var value$13 = Js_dict.get(value$5, "snackFiles");
      var field_snackFiles = value$13 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$13))).map(function (value) {
              var value$1 = Js_json.decodeObject(value);
              if (value$1 === undefined) {
                return Js_exn.raiseError("graphql_ppx: Expected object of type SnackFileStructure, got " + JSON.stringify(value));
              }
              var value$2 = Caml_option.valFromOption(value$1);
              var value$3 = Js_dict.get(value$2, "__typename");
              var field___typename;
              if (value$3 !== undefined) {
                var value$4 = Caml_option.valFromOption(value$3);
                var value$5 = Js_json.decodeString(value$4);
                field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
              } else {
                field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type SnackFileStructure is missing");
              }
              var value$6 = Js_dict.get(value$2, "name");
              var field_name;
              if (value$6 !== undefined) {
                var value$7 = Caml_option.valFromOption(value$6);
                var value$8 = Js_json.decodeString(value$7);
                field_name = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
              } else {
                field_name = Js_exn.raiseError("graphql_ppx: Field name on type SnackFileStructure is missing");
              }
              var value$9 = Js_dict.get(value$2, "path");
              var field_path;
              if (value$9 !== undefined) {
                var value$10 = Caml_option.valFromOption(value$9);
                var value$11 = Js_json.decodeString(value$10);
                field_path = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
              } else {
                field_path = Js_exn.raiseError("graphql_ppx: Field path on type SnackFileStructure is missing");
              }
              var value$12 = Js_dict.get(value$2, "type_");
              var field_type_;
              if (value$12 !== undefined) {
                var value$13 = Caml_option.valFromOption(value$12);
                var value$14 = Js_json.decodeString(value$13);
                field_type_ = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
              } else {
                field_type_ = Js_exn.raiseError("graphql_ppx: Field type_ on type SnackFileStructure is missing");
              }
              var value$15 = Js_dict.get(value$2, "contents");
              var field_contents;
              if (value$15 !== undefined) {
                var value$16 = Caml_option.valFromOption(value$15);
                var value$17 = Js_json.decodeString(value$16);
                field_contents = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
              } else {
                field_contents = Js_exn.raiseError("graphql_ppx: Field contents on type SnackFileStructure is missing");
              }
              return {
                      __typename: field___typename,
                      name: field_name,
                      path: field_path,
                      type: field_type_,
                      contents: field_contents
                    };
            }) : Js_exn.raiseError("graphql_ppx: Field snackFiles on type App is missing");
      tmp = {
        __typename: field___typename,
        uuid: field_uuid,
        imports: field_imports,
        snackFiles: field_snackFiles
      };
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected object of type App, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field app on type Query is missing");
  }
  return {
          app: tmp
        };
}

function json_of_PublicationEnvironment(value) {
  if (value === "Production") {
    return "Production";
  } else {
    return "Development";
  }
}

function make$2(appUuid, env, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "env",
                    json_of_PublicationEnvironment(env)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$2(variables) {
  var appUuid = variables.appUuid;
  var env = variables.env;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "env",
                    json_of_PublicationEnvironment(env)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$2(appUuid, env, param) {
  return Js_dict.fromArray([
                [
                  "appUuid",
                  appUuid
                ],
                [
                  "env",
                  json_of_PublicationEnvironment(env)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, appUuid, env, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "appUuid",
                      appUuid
                    ],
                    [
                      "env",
                      json_of_PublicationEnvironment(env)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$2
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function useFullQuery(appUuid, skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = Router$DraftbitBuilder.usePublicationEnvironment(undefined);
  var variables = makeVariables$2(appUuid, match[0], undefined);
  var match$1 = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(variables), undefined, undefined, undefined, skip, undefined, undefined, definition$2);
  var fullSnackFiles = match$1[1];
  var previousVariables = Hooks$DraftbitBuilder.usePrevious(variables);
  Hooks$DraftbitBuilder.useOnUpdate2((function (param) {
          if (!LodashIsequal(previousVariables, variables) && !fullSnackFiles.loading) {
            return PromiseUtils$DraftbitBuilder.ignore(Curry._2(fullSnackFiles.refetch, Caml_option.some(variables), undefined));
          }
          
        }), [
        fullSnackFiles,
        StringUtils$DraftbitBuilder.toJsonOrString(variables)
      ]);
  return [
          ApolloUtils$DraftbitBuilder.Query.map(match$1[0], (function (data) {
                  return data.app;
                })),
          fullSnackFiles
        ];
}

function refetch$1(appUuid, env, param) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make$2(appUuid, env, undefined));
}

var GetFiles = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  json_of_PublicationEnvironment: json_of_PublicationEnvironment,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  useFullQuery: useFullQuery,
  refetch: refetch$1
};

var GQL = {
  ScreenFragment: ScreenFragment,
  GetScreen: GetScreen,
  modifySnackScreenInCache: modifySnackScreenInCache,
  ListScreens: ListScreens,
  GetFiles: GetFiles
};

export {
  GQL ,
  
}
/* lodash.isequal Not a pure module */
