// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$DraftbitBuilder from "./utilities/Utils.bs.js";
import * as Button$DraftbitBuilder from "./waterloo/components/Button/Button.bs.js";
import * as Images$DraftbitBuilder from "./components/Images.bs.js";

function make(param) {
  var showSupportChat = function (param) {
    return (window.gist?.chat && window.gist?.chat("open"));
  };
  React.useEffect((function () {
          showSupportChat(undefined);
          
        }), []);
  return React.createElement("div", {
              className: "h-screen w-screen text-mono-100 p-4 md:p-10 lg:p-16 flex items-center justify-center bg-mono-900"
            }, React.createElement("div", {
                  className: "flex flex-col md:flex-row items-center h-full"
                }, React.createElement("div", {
                      className: "md:w-1/2"
                    }, React.createElement("div", {
                          className: "flex flex-col gap-y-8"
                        }, React.createElement("div", {
                              className: "flex justify-center md:justify-start mt-12 lg:mt-0"
                            }, React.createElement("img", {
                                  className: "w-12 h-12 fill-current text-mono-50",
                                  alt: "Draftbit Logo",
                                  src: Images$DraftbitBuilder.logoWhite
                                })), React.createElement("h1", {
                              className: "text-2xl md:text-4xl leading-none font-extrabold text-center md:text-left"
                            }, "Down for Maintenance"), React.createElement("h3", {
                              className: "text-lg md:text-xl text-mono-200 text-center md:text-left"
                            }, "Draftbit is currently undergoing scheduled maintenance and will be back soon. Thank you for your patience! For updates, please visit our ", React.createElement("a", {
                                  className: "text-mono-100 underline cursor-pointer",
                                  href: "http://status.draftbit.com"
                                }, "status page"), "."), React.createElement("div", {
                              className: "flex justify-center md:justify-start"
                            }, React.createElement(Button$DraftbitBuilder.make, {
                                  label: "Monitor Status ->",
                                  kind: /* Solid */2,
                                  onClick: (function (param) {
                                      return Utils$DraftbitBuilder.openNewTab("https://status.draftbit.com");
                                    })
                                })), React.createElement("p", {
                              className: "text-md text-mono-300 text-center md:text-left"
                            }, "Need help? ", React.createElement("a", {
                                  href: "mailto:help@draftbit.com"
                                }, "help@draftbit.com")))), React.createElement("div", {
                      className: "flex items-center justify-center md:w-1/2"
                    }, React.createElement("img", {
                          className: "w-120",
                          alt: "maintenance",
                          src: Images$DraftbitBuilder.maintenance
                        }))));
}

export {
  make ,
  
}
/* react Not a pure module */
