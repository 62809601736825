// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "UIBackgroundModes",
                Decco.arrayToJson(Decco.stringToJson, v.UIBackgroundModes)
              ],
              [
                "LSApplicationQueriesSchemes",
                Decco.arrayToJson(Decco.stringToJson, v.LSApplicationQueriesSchemes)
              ],
              [
                "NSBluetoothPeripheralUsageDescription",
                Decco.stringToJson(v.NSBluetoothPeripheralUsageDescription)
              ],
              [
                "NSBluetoothAlwaysUsageDescription",
                Decco.stringToJson(v.NSBluetoothAlwaysUsageDescription)
              ],
              [
                "NSCalendarsUsageDescription",
                Decco.stringToJson(v.NSCalendarsUsageDescription)
              ],
              [
                "NSRemindersUsageDescription",
                Decco.stringToJson(v.NSRemindersUsageDescription)
              ],
              [
                "NSCameraUsageDescription",
                Decco.stringToJson(v.NSCameraUsageDescription)
              ],
              [
                "NSMicrophoneUsageDescription",
                Decco.stringToJson(v.NSMicrophoneUsageDescription)
              ],
              [
                "NSContactsUsageDescription",
                Decco.stringToJson(v.NSContactsUsageDescription)
              ],
              [
                "NSFaceIDUsageDescription",
                Decco.stringToJson(v.NSFaceIDUsageDescription)
              ],
              [
                "NSGKFriendListUsageDescription",
                Decco.stringToJson(v.NSGKFriendListUsageDescription)
              ],
              [
                "NSHealthClinicalHealthRecordsShareUsageDescription",
                Decco.stringToJson(v.NSHealthClinicalHealthRecordsShareUsageDescription)
              ],
              [
                "NSHealthShareUsageDescription",
                Decco.stringToJson(v.NSHealthShareUsageDescription)
              ],
              [
                "NSHealthUpdateUsageDescription",
                Decco.stringToJson(v.NSHealthUpdateUsageDescription)
              ],
              [
                "NSHomeKitUsageDescription",
                Decco.stringToJson(v.NSHomeKitUsageDescription)
              ],
              [
                "NSLocationAlwaysAndWhenInUseUsageDescription",
                Decco.stringToJson(v.NSLocationAlwaysAndWhenInUseUsageDescription)
              ],
              [
                "NSLocationWhenInUseUsageDescription",
                Decco.stringToJson(v.NSLocationWhenInUseUsageDescription)
              ],
              [
                "NSAppleMusicUsageDescription",
                Decco.stringToJson(v.NSAppleMusicUsageDescription)
              ],
              [
                "NSMotionUsageDescription",
                Decco.stringToJson(v.NSMotionUsageDescription)
              ],
              [
                "NSFallDetectionUsageDescription",
                Decco.stringToJson(v.NSFallDetectionUsageDescription)
              ],
              [
                "NSLocalNetworkUsageDescription",
                Decco.stringToJson(v.NSLocalNetworkUsageDescription)
              ],
              [
                "NFCReaderUsageDescription",
                Decco.stringToJson(v.NFCReaderUsageDescription)
              ],
              [
                "NSPhotoLibraryAddUsageDescription",
                Decco.stringToJson(v.NSPhotoLibraryAddUsageDescription)
              ],
              [
                "NSPhotoLibraryUsageDescription",
                Decco.stringToJson(v.NSPhotoLibraryUsageDescription)
              ],
              [
                "NSUserTrackingUsageDescription",
                Decco.stringToJson(v.NSUserTrackingUsageDescription)
              ],
              [
                "NSSensorKitUsageDescription",
                Decco.stringToJson(v.NSSensorKitUsageDescription)
              ],
              [
                "NSSiriUsageDescription",
                Decco.stringToJson(v.NSSiriUsageDescription)
              ],
              [
                "NSSpeechRecognitionUsageDescription",
                Decco.stringToJson(v.NSSpeechRecognitionUsageDescription)
              ],
              [
                "CFBundleLocalizations",
                Decco.stringToJson(v.CFBundleLocalizations)
              ],
              [
                "CFBundleDevelopmentRegion",
                Decco.arrayToJson(Decco.stringToJson, v.CFBundleDevelopmentRegion)
              ],
              [
                "TICapsLockLanguageSwitchCapable",
                Decco.optionToJson(Decco.boolToJson, v.TICapsLockLanguageSwitchCapable)
              ],
              [
                "CFBundleAllowMixedLocalizations",
                Decco.optionToJson(Decco.boolToJson, v.CFBundleAllowMixedLocalizations)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var uIBackgroundModes = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "UIBackgroundModes"), null));
  if (uIBackgroundModes.TAG === /* Ok */0) {
    var lSApplicationQueriesSchemes = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "LSApplicationQueriesSchemes"), null));
    if (lSApplicationQueriesSchemes.TAG === /* Ok */0) {
      var nSBluetoothPeripheralUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSBluetoothPeripheralUsageDescription"), null));
      if (nSBluetoothPeripheralUsageDescription.TAG === /* Ok */0) {
        var nSBluetoothAlwaysUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSBluetoothAlwaysUsageDescription"), null));
        if (nSBluetoothAlwaysUsageDescription.TAG === /* Ok */0) {
          var nSCalendarsUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSCalendarsUsageDescription"), null));
          if (nSCalendarsUsageDescription.TAG === /* Ok */0) {
            var nSRemindersUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSRemindersUsageDescription"), null));
            if (nSRemindersUsageDescription.TAG === /* Ok */0) {
              var nSCameraUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSCameraUsageDescription"), null));
              if (nSCameraUsageDescription.TAG === /* Ok */0) {
                var nSMicrophoneUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSMicrophoneUsageDescription"), null));
                if (nSMicrophoneUsageDescription.TAG === /* Ok */0) {
                  var nSContactsUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSContactsUsageDescription"), null));
                  if (nSContactsUsageDescription.TAG === /* Ok */0) {
                    var nSFaceIDUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSFaceIDUsageDescription"), null));
                    if (nSFaceIDUsageDescription.TAG === /* Ok */0) {
                      var nSGKFriendListUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSGKFriendListUsageDescription"), null));
                      if (nSGKFriendListUsageDescription.TAG === /* Ok */0) {
                        var nSHealthClinicalHealthRecordsShareUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSHealthClinicalHealthRecordsShareUsageDescription"), null));
                        if (nSHealthClinicalHealthRecordsShareUsageDescription.TAG === /* Ok */0) {
                          var nSHealthShareUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSHealthShareUsageDescription"), null));
                          if (nSHealthShareUsageDescription.TAG === /* Ok */0) {
                            var nSHealthUpdateUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSHealthUpdateUsageDescription"), null));
                            if (nSHealthUpdateUsageDescription.TAG === /* Ok */0) {
                              var nSHomeKitUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSHomeKitUsageDescription"), null));
                              if (nSHomeKitUsageDescription.TAG === /* Ok */0) {
                                var nSLocationAlwaysAndWhenInUseUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSLocationAlwaysAndWhenInUseUsageDescription"), null));
                                if (nSLocationAlwaysAndWhenInUseUsageDescription.TAG === /* Ok */0) {
                                  var nSLocationWhenInUseUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSLocationWhenInUseUsageDescription"), null));
                                  if (nSLocationWhenInUseUsageDescription.TAG === /* Ok */0) {
                                    var nSAppleMusicUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSAppleMusicUsageDescription"), null));
                                    if (nSAppleMusicUsageDescription.TAG === /* Ok */0) {
                                      var nSMotionUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSMotionUsageDescription"), null));
                                      if (nSMotionUsageDescription.TAG === /* Ok */0) {
                                        var nSFallDetectionUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSFallDetectionUsageDescription"), null));
                                        if (nSFallDetectionUsageDescription.TAG === /* Ok */0) {
                                          var nSLocalNetworkUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSLocalNetworkUsageDescription"), null));
                                          if (nSLocalNetworkUsageDescription.TAG === /* Ok */0) {
                                            var nFCReaderUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NFCReaderUsageDescription"), null));
                                            if (nFCReaderUsageDescription.TAG === /* Ok */0) {
                                              var nSPhotoLibraryAddUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSPhotoLibraryAddUsageDescription"), null));
                                              if (nSPhotoLibraryAddUsageDescription.TAG === /* Ok */0) {
                                                var nSPhotoLibraryUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSPhotoLibraryUsageDescription"), null));
                                                if (nSPhotoLibraryUsageDescription.TAG === /* Ok */0) {
                                                  var nSUserTrackingUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSUserTrackingUsageDescription"), null));
                                                  if (nSUserTrackingUsageDescription.TAG === /* Ok */0) {
                                                    var nSSensorKitUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSSensorKitUsageDescription"), null));
                                                    if (nSSensorKitUsageDescription.TAG === /* Ok */0) {
                                                      var nSSiriUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSSiriUsageDescription"), null));
                                                      if (nSSiriUsageDescription.TAG === /* Ok */0) {
                                                        var nSSpeechRecognitionUsageDescription = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "NSSpeechRecognitionUsageDescription"), null));
                                                        if (nSSpeechRecognitionUsageDescription.TAG === /* Ok */0) {
                                                          var cFBundleLocalizations = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "CFBundleLocalizations"), null));
                                                          if (cFBundleLocalizations.TAG === /* Ok */0) {
                                                            var cFBundleDevelopmentRegion = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "CFBundleDevelopmentRegion"), null));
                                                            if (cFBundleDevelopmentRegion.TAG === /* Ok */0) {
                                                              var tICapsLockLanguageSwitchCapable = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "TICapsLockLanguageSwitchCapable"), null));
                                                              if (tICapsLockLanguageSwitchCapable.TAG === /* Ok */0) {
                                                                var cFBundleAllowMixedLocalizations = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "CFBundleAllowMixedLocalizations"), null));
                                                                if (cFBundleAllowMixedLocalizations.TAG === /* Ok */0) {
                                                                  return {
                                                                          TAG: /* Ok */0,
                                                                          _0: {
                                                                            UIBackgroundModes: uIBackgroundModes._0,
                                                                            LSApplicationQueriesSchemes: lSApplicationQueriesSchemes._0,
                                                                            NSBluetoothPeripheralUsageDescription: nSBluetoothPeripheralUsageDescription._0,
                                                                            NSBluetoothAlwaysUsageDescription: nSBluetoothAlwaysUsageDescription._0,
                                                                            NSCalendarsUsageDescription: nSCalendarsUsageDescription._0,
                                                                            NSRemindersUsageDescription: nSRemindersUsageDescription._0,
                                                                            NSCameraUsageDescription: nSCameraUsageDescription._0,
                                                                            NSMicrophoneUsageDescription: nSMicrophoneUsageDescription._0,
                                                                            NSContactsUsageDescription: nSContactsUsageDescription._0,
                                                                            NSFaceIDUsageDescription: nSFaceIDUsageDescription._0,
                                                                            NSGKFriendListUsageDescription: nSGKFriendListUsageDescription._0,
                                                                            NSHealthClinicalHealthRecordsShareUsageDescription: nSHealthClinicalHealthRecordsShareUsageDescription._0,
                                                                            NSHealthShareUsageDescription: nSHealthShareUsageDescription._0,
                                                                            NSHealthUpdateUsageDescription: nSHealthUpdateUsageDescription._0,
                                                                            NSHomeKitUsageDescription: nSHomeKitUsageDescription._0,
                                                                            NSLocationAlwaysAndWhenInUseUsageDescription: nSLocationAlwaysAndWhenInUseUsageDescription._0,
                                                                            NSLocationWhenInUseUsageDescription: nSLocationWhenInUseUsageDescription._0,
                                                                            NSAppleMusicUsageDescription: nSAppleMusicUsageDescription._0,
                                                                            NSMotionUsageDescription: nSMotionUsageDescription._0,
                                                                            NSFallDetectionUsageDescription: nSFallDetectionUsageDescription._0,
                                                                            NSLocalNetworkUsageDescription: nSLocalNetworkUsageDescription._0,
                                                                            NFCReaderUsageDescription: nFCReaderUsageDescription._0,
                                                                            NSPhotoLibraryAddUsageDescription: nSPhotoLibraryAddUsageDescription._0,
                                                                            NSPhotoLibraryUsageDescription: nSPhotoLibraryUsageDescription._0,
                                                                            NSUserTrackingUsageDescription: nSUserTrackingUsageDescription._0,
                                                                            NSSensorKitUsageDescription: nSSensorKitUsageDescription._0,
                                                                            NSSiriUsageDescription: nSSiriUsageDescription._0,
                                                                            NSSpeechRecognitionUsageDescription: nSSpeechRecognitionUsageDescription._0,
                                                                            CFBundleLocalizations: cFBundleLocalizations._0,
                                                                            CFBundleDevelopmentRegion: cFBundleDevelopmentRegion._0,
                                                                            TICapsLockLanguageSwitchCapable: tICapsLockLanguageSwitchCapable._0,
                                                                            CFBundleAllowMixedLocalizations: cFBundleAllowMixedLocalizations._0
                                                                          }
                                                                        };
                                                                }
                                                                var e = cFBundleAllowMixedLocalizations._0;
                                                                return {
                                                                        TAG: /* Error */1,
                                                                        _0: {
                                                                          path: ".CFBundleAllowMixedLocalizations" + e.path,
                                                                          message: e.message,
                                                                          value: e.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$1 = tICapsLockLanguageSwitchCapable._0;
                                                              return {
                                                                      TAG: /* Error */1,
                                                                      _0: {
                                                                        path: ".TICapsLockLanguageSwitchCapable" + e$1.path,
                                                                        message: e$1.message,
                                                                        value: e$1.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$2 = cFBundleDevelopmentRegion._0;
                                                            return {
                                                                    TAG: /* Error */1,
                                                                    _0: {
                                                                      path: ".CFBundleDevelopmentRegion" + e$2.path,
                                                                      message: e$2.message,
                                                                      value: e$2.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$3 = cFBundleLocalizations._0;
                                                          return {
                                                                  TAG: /* Error */1,
                                                                  _0: {
                                                                    path: ".CFBundleLocalizations" + e$3.path,
                                                                    message: e$3.message,
                                                                    value: e$3.value
                                                                  }
                                                                };
                                                        }
                                                        var e$4 = nSSpeechRecognitionUsageDescription._0;
                                                        return {
                                                                TAG: /* Error */1,
                                                                _0: {
                                                                  path: ".NSSpeechRecognitionUsageDescription" + e$4.path,
                                                                  message: e$4.message,
                                                                  value: e$4.value
                                                                }
                                                              };
                                                      }
                                                      var e$5 = nSSiriUsageDescription._0;
                                                      return {
                                                              TAG: /* Error */1,
                                                              _0: {
                                                                path: ".NSSiriUsageDescription" + e$5.path,
                                                                message: e$5.message,
                                                                value: e$5.value
                                                              }
                                                            };
                                                    }
                                                    var e$6 = nSSensorKitUsageDescription._0;
                                                    return {
                                                            TAG: /* Error */1,
                                                            _0: {
                                                              path: ".NSSensorKitUsageDescription" + e$6.path,
                                                              message: e$6.message,
                                                              value: e$6.value
                                                            }
                                                          };
                                                  }
                                                  var e$7 = nSUserTrackingUsageDescription._0;
                                                  return {
                                                          TAG: /* Error */1,
                                                          _0: {
                                                            path: ".NSUserTrackingUsageDescription" + e$7.path,
                                                            message: e$7.message,
                                                            value: e$7.value
                                                          }
                                                        };
                                                }
                                                var e$8 = nSPhotoLibraryUsageDescription._0;
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: {
                                                          path: ".NSPhotoLibraryUsageDescription" + e$8.path,
                                                          message: e$8.message,
                                                          value: e$8.value
                                                        }
                                                      };
                                              }
                                              var e$9 = nSPhotoLibraryAddUsageDescription._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".NSPhotoLibraryAddUsageDescription" + e$9.path,
                                                        message: e$9.message,
                                                        value: e$9.value
                                                      }
                                                    };
                                            }
                                            var e$10 = nFCReaderUsageDescription._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".NFCReaderUsageDescription" + e$10.path,
                                                      message: e$10.message,
                                                      value: e$10.value
                                                    }
                                                  };
                                          }
                                          var e$11 = nSLocalNetworkUsageDescription._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".NSLocalNetworkUsageDescription" + e$11.path,
                                                    message: e$11.message,
                                                    value: e$11.value
                                                  }
                                                };
                                        }
                                        var e$12 = nSFallDetectionUsageDescription._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".NSFallDetectionUsageDescription" + e$12.path,
                                                  message: e$12.message,
                                                  value: e$12.value
                                                }
                                              };
                                      }
                                      var e$13 = nSMotionUsageDescription._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".NSMotionUsageDescription" + e$13.path,
                                                message: e$13.message,
                                                value: e$13.value
                                              }
                                            };
                                    }
                                    var e$14 = nSAppleMusicUsageDescription._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".NSAppleMusicUsageDescription" + e$14.path,
                                              message: e$14.message,
                                              value: e$14.value
                                            }
                                          };
                                  }
                                  var e$15 = nSLocationWhenInUseUsageDescription._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".NSLocationWhenInUseUsageDescription" + e$15.path,
                                            message: e$15.message,
                                            value: e$15.value
                                          }
                                        };
                                }
                                var e$16 = nSLocationAlwaysAndWhenInUseUsageDescription._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".NSLocationAlwaysAndWhenInUseUsageDescription" + e$16.path,
                                          message: e$16.message,
                                          value: e$16.value
                                        }
                                      };
                              }
                              var e$17 = nSHomeKitUsageDescription._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".NSHomeKitUsageDescription" + e$17.path,
                                        message: e$17.message,
                                        value: e$17.value
                                      }
                                    };
                            }
                            var e$18 = nSHealthUpdateUsageDescription._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".NSHealthUpdateUsageDescription" + e$18.path,
                                      message: e$18.message,
                                      value: e$18.value
                                    }
                                  };
                          }
                          var e$19 = nSHealthShareUsageDescription._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".NSHealthShareUsageDescription" + e$19.path,
                                    message: e$19.message,
                                    value: e$19.value
                                  }
                                };
                        }
                        var e$20 = nSHealthClinicalHealthRecordsShareUsageDescription._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".NSHealthClinicalHealthRecordsShareUsageDescription" + e$20.path,
                                  message: e$20.message,
                                  value: e$20.value
                                }
                              };
                      }
                      var e$21 = nSGKFriendListUsageDescription._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".NSGKFriendListUsageDescription" + e$21.path,
                                message: e$21.message,
                                value: e$21.value
                              }
                            };
                    }
                    var e$22 = nSFaceIDUsageDescription._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".NSFaceIDUsageDescription" + e$22.path,
                              message: e$22.message,
                              value: e$22.value
                            }
                          };
                  }
                  var e$23 = nSContactsUsageDescription._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".NSContactsUsageDescription" + e$23.path,
                            message: e$23.message,
                            value: e$23.value
                          }
                        };
                }
                var e$24 = nSMicrophoneUsageDescription._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".NSMicrophoneUsageDescription" + e$24.path,
                          message: e$24.message,
                          value: e$24.value
                        }
                      };
              }
              var e$25 = nSCameraUsageDescription._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".NSCameraUsageDescription" + e$25.path,
                        message: e$25.message,
                        value: e$25.value
                      }
                    };
            }
            var e$26 = nSRemindersUsageDescription._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".NSRemindersUsageDescription" + e$26.path,
                      message: e$26.message,
                      value: e$26.value
                    }
                  };
          }
          var e$27 = nSCalendarsUsageDescription._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".NSCalendarsUsageDescription" + e$27.path,
                    message: e$27.message,
                    value: e$27.value
                  }
                };
        }
        var e$28 = nSBluetoothAlwaysUsageDescription._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".NSBluetoothAlwaysUsageDescription" + e$28.path,
                  message: e$28.message,
                  value: e$28.value
                }
              };
      }
      var e$29 = nSBluetoothPeripheralUsageDescription._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".NSBluetoothPeripheralUsageDescription" + e$29.path,
                message: e$29.message,
                value: e$29.value
              }
            };
    }
    var e$30 = lSApplicationQueriesSchemes._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".LSApplicationQueriesSchemes" + e$30.path,
              message: e$30.message,
              value: e$30.value
            }
          };
  }
  var e$31 = uIBackgroundModes._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".UIBackgroundModes" + e$31.path,
            message: e$31.message,
            value: e$31.value
          }
        };
}

export {
  t_encode ,
  t_decode ,
  
}
/* No side effect */
