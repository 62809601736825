// Generated by ReScript, PLEASE EDIT WITH CARE

import GraphqlTag from "graphql-tag";
import * as ApolloHooksQuery$DraftbitBuilder from "./ApolloHooksQuery.bs.js";
import * as ApolloHooksMutation$DraftbitBuilder from "./ApolloHooksMutation.bs.js";
import * as ApolloHooksSubscription$DraftbitBuilder from "./ApolloHooksSubscription.bs.js";

function toQueryObj(result) {
  return {
          query: GraphqlTag(result.query),
          variables: result.variables
        };
}

var Mutation;

var Query;

var Provider;

var Subscription;

var useQuery = ApolloHooksQuery$DraftbitBuilder.useQuery;

var useMutation = ApolloHooksMutation$DraftbitBuilder.useMutation;

var useSubscription = ApolloHooksSubscription$DraftbitBuilder.useSubscription;

export {
  Mutation ,
  Query ,
  Provider ,
  Subscription ,
  useQuery ,
  useMutation ,
  useSubscription ,
  toQueryObj ,
  
}
/* graphql-tag Not a pure module */
