// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as DataCyWrapper$DraftbitBuilder from "./DataCyWrapper.bs.js";

var make = React.forwardRef(function (Props, ref_) {
      var addId = Props.addId;
      var dataCy = Props.dataCy;
      var className = Props.className;
      var onClick = Props.onClick;
      var onMouseOver = Props.onMouseOver;
      var onMouseLeave = Props.onMouseLeave;
      var childrenOpt = Props.children;
      var title = Props.title;
      var style = Props.style;
      var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
      var tmp = {};
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      if (className !== undefined) {
        tmp.className = Caml_option.valFromOption(className);
      }
      if (style !== undefined) {
        tmp.style = Caml_option.valFromOption(style);
      }
      if (title !== undefined) {
        tmp.title = Caml_option.valFromOption(title);
      }
      if (onClick !== undefined) {
        tmp.onClick = Caml_option.valFromOption(onClick);
      }
      if (onMouseLeave !== undefined) {
        tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
      }
      if (onMouseOver !== undefined) {
        tmp.onMouseOver = Caml_option.valFromOption(onMouseOver);
      }
      var tmp$2 = {
        children: React.createElement("div", tmp, children),
        name: dataCy
      };
      if (addId !== undefined) {
        tmp$2.addId = Caml_option.valFromOption(addId);
      }
      return React.createElement(DataCyWrapper$DraftbitBuilder.make, tmp$2);
    });

export {
  make ,
  
}
/* make Not a pure module */
