// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as JsMap from "rescript-js-collections/src/JsMap.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "./utilities/OptionUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "package",
                Decco.stringToJson(v.package)
              ],
              [
                "module",
                Decco.stringToJson(v.module)
              ],
              [
                "alias",
                Decco.optionToJson(Decco.stringToJson, v.alias)
              ],
              [
                "isDefaultExport",
                Decco.boolToJson(v.isDefaultExport)
              ],
              [
                "wildCardImport",
                Decco.boolToJson(v.wildCardImport)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
  if (uuid.TAG === /* Ok */0) {
    var $$package = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "package"), null));
    if ($$package.TAG === /* Ok */0) {
      var module_ = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "module"), null));
      if (module_.TAG === /* Ok */0) {
        var alias = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "alias"), null));
        if (alias.TAG === /* Ok */0) {
          var isDefaultExport = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isDefaultExport"), null));
          if (isDefaultExport.TAG === /* Ok */0) {
            var wildCardImport = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "wildCardImport"), null));
            if (wildCardImport.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        uuid: uuid._0,
                        package: $$package._0,
                        module: module_._0,
                        alias: alias._0,
                        isDefaultExport: isDefaultExport._0,
                        wildCardImport: wildCardImport._0
                      }
                    };
            }
            var e = wildCardImport._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".wildCardImport" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = isDefaultExport._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".isDefaultExport" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = alias._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".alias" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = module_._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".module" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = $$package._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".package" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = uuid._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".uuid" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function make(isDefaultExportOpt, wildCardImportOpt, alias, $$package, module_, param) {
  var isDefaultExport = isDefaultExportOpt !== undefined ? isDefaultExportOpt : false;
  var wildCardImport = wildCardImportOpt !== undefined ? wildCardImportOpt : false;
  return {
          uuid: "",
          package: $$package,
          module: module_,
          alias: alias,
          isDefaultExport: isDefaultExport,
          wildCardImport: wildCardImport
        };
}

function makeWildCard(as_, $$package) {
  return make(undefined, true, undefined, $$package, as_, undefined);
}

function makeDefault(as_, $$package) {
  return make(true, undefined, undefined, $$package, as_, undefined);
}

function makeMulti($$package, modules) {
  return modules.map(function (module_) {
              return make(undefined, undefined, undefined, $$package, module_, undefined);
            });
}

function importFromJson(param) {
  return JsonUtils$DraftbitBuilder.decodeDecco(t_decode, param);
}

function importsToStatements(imports) {
  return ArrayUtils$DraftbitBuilder.moveMatchToFront(ArrayUtils$DraftbitBuilder.moveMatchesToBack(ArrayUtils$DraftbitBuilder.sortBy(JsMap.entriesArray(JsMap.groupBy(imports, (function (i) {
                                  return i.package;
                                }))), (function (prim) {
                          return prim[0];
                        })), (function (param) {
                      return param[0].startsWith(".");
                    })), (function (param) {
                  return param[0] === "react";
                })).map(function (param) {
              var imports = ArrayUtils$DraftbitBuilder.sortBy(param[1], (function (i) {
                      return i.module;
                    }));
              var match = Belt_Array.partition(imports, (function (i) {
                      if (i.isDefaultExport) {
                        return false;
                      } else {
                        return !i.wildCardImport;
                      }
                    }));
              var normalImports = match[0];
              var renderedNormalImports = normalImports.length !== 0 ? StringUtils$DraftbitBuilder.curlies(ArrayUtils$DraftbitBuilder.uniqBy(normalImports, (function (i) {
                                return OptionUtils$DraftbitBuilder.or_(i.module, i.alias);
                              })).map(function (i) {
                            return Belt_Option.mapWithDefault(i.alias, i.module, (function (a) {
                                          return i.module + " as " + a;
                                        }));
                          }).join(", ")) : "";
              var renderedOtherImports = ArrayUtils$DraftbitBuilder.uniq(Belt_Array.keepMap(match[1], (function (i) {
                            if (i.isDefaultExport) {
                              return i.module;
                            } else if (i.wildCardImport) {
                              return "* as " + i.module;
                            } else {
                              return ;
                            }
                          }))).join(",");
              return "import " + [
                          renderedOtherImports,
                          renderedNormalImports
                        ].filter(function (r) {
                            return r !== "";
                          }).join(",") + " from " + StringUtils$DraftbitBuilder.quote(param[0]);
            });
}

export {
  t_encode ,
  t_decode ,
  make ,
  makeWildCard ,
  makeDefault ,
  makeMulti ,
  importFromJson ,
  importsToStatements ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
