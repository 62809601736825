/* TypeScript file generated from WL_Color.res by genType. */
/* eslint-disable import/first */


// tslint:disable-next-line:no-var-requires
const WL_ColorBS = require('./WL_Color.bs');

// tslint:disable-next-line:interface-over-type-literal
export type t = 
    "Success200"
  | "Success100"
  | "Mono100"
  | "Mono50"
  | "Mono400"
  | "Primary300"
  | "CurrentColor"
  | "Primary100"
  | "Mono800"
  | "Warning300"
  | "Transparent"
  | "Selection500"
  | "Mono950"
  | "Failure500"
  | "Failure300"
  | "Primary400"
  | "Mono700"
  | "Mono500"
  | "Failure400"
  | "Warning100"
  | "Primary200"
  | "Accent600"
  | "Failure100"
  | "Success500"
  | "Selection100"
  | "Black"
  | "Selection600"
  | "Selection300"
  | "Selection200"
  | "Mono900"
  | "Mono600"
  | "Warning500"
  | "Primary500"
  | "Warning200"
  | "White"
  | "Mono300"
  | "Failure200"
  | "Warning400"
  | "Primary600"
  | "Brand"
  | "Mono200"
  | "Success300"
  | "Selection400"
  | "Success400";

export const transparent: "Transparent" = WL_ColorBS.transparent;

export const currentColor: "CurrentColor" = WL_ColorBS.currentColor;

export const mono50: "Mono50" = WL_ColorBS.mono50;

export const mono100: "Mono100" = WL_ColorBS.mono100;

export const mono200: "Mono200" = WL_ColorBS.mono200;

export const mono300: "Mono300" = WL_ColorBS.mono300;

export const mono400: "Mono400" = WL_ColorBS.mono400;

export const mono500: "Mono500" = WL_ColorBS.mono500;

export const mono600: "Mono600" = WL_ColorBS.mono600;

export const mono700: "Mono700" = WL_ColorBS.mono700;

export const mono800: "Mono800" = WL_ColorBS.mono800;

export const mono900: "Mono900" = WL_ColorBS.mono900;

export const mono950: "Mono950" = WL_ColorBS.mono950;

export const black: "Black" = WL_ColorBS.black;

export const white: "White" = WL_ColorBS.white;

export const brand: "Brand" = WL_ColorBS.brand;

export const accent600: "Accent600" = WL_ColorBS.accent600;

export const primary100: "Primary100" = WL_ColorBS.primary100;

export const primary200: "Primary200" = WL_ColorBS.primary200;

export const primary300: "Primary300" = WL_ColorBS.primary300;

export const primary400: "Primary400" = WL_ColorBS.primary400;

export const primary500: "Primary500" = WL_ColorBS.primary500;

export const primary600: "Primary600" = WL_ColorBS.primary600;

export const selection100: "Selection100" = WL_ColorBS.selection100;

export const selection200: "Selection200" = WL_ColorBS.selection200;

export const selection300: "Selection300" = WL_ColorBS.selection300;

export const selection400: "Selection400" = WL_ColorBS.selection400;

export const selection500: "Selection500" = WL_ColorBS.selection500;

export const selection600: "Selection600" = WL_ColorBS.selection600;

export const warning100: "Warning100" = WL_ColorBS.warning100;

export const warning200: "Warning200" = WL_ColorBS.warning200;

export const warning300: "Warning300" = WL_ColorBS.warning300;

export const warning400: "Warning400" = WL_ColorBS.warning400;

export const warning500: "Warning500" = WL_ColorBS.warning500;

export const success100: "Success100" = WL_ColorBS.success100;

export const success200: "Success200" = WL_ColorBS.success200;

export const success300: "Success300" = WL_ColorBS.success300;

export const success400: "Success400" = WL_ColorBS.success400;

export const success500: "Success500" = WL_ColorBS.success500;

export const failure100: "Failure100" = WL_ColorBS.failure100;

export const failure200: "Failure200" = WL_ColorBS.failure200;

export const failure300: "Failure300" = WL_ColorBS.failure300;

export const failure400: "Failure400" = WL_ColorBS.failure400;

export const failure500: "Failure500" = WL_ColorBS.failure500;
