// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Belt_Result from "bs-platform/lib/es6/belt_Result.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";
import * as StringUtils$DraftbitBuilder from "./StringUtils.bs.js";

function catchJsError(fn) {
  try {
    return {
            TAG: /* Ok */0,
            _0: Curry._1(fn, undefined)
          };
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID === Js_exn.$$Error) {
      return {
              TAG: /* Error */1,
              _0: obj._1
            };
    }
    throw obj;
  }
}

function catchJsErrorMessage(fn) {
  try {
    return {
            TAG: /* Ok */0,
            _0: Curry._1(fn, undefined)
          };
  }
  catch (raw_obj){
    var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
    if (obj.RE_EXN_ID === Js_exn.$$Error) {
      return {
              TAG: /* Error */1,
              _0: StringUtils$DraftbitBuilder.emptyIfNone(obj._1.message)
            };
    }
    throw obj;
  }
}

function toOption(r) {
  if (r.TAG === /* Ok */0) {
    return Caml_option.some(r._0);
  }
  
}

function toOptionError(r) {
  if (r.TAG === /* Ok */0) {
    return ;
  } else {
    return Caml_option.some(r._0);
  }
}

function fromOption(opt, errIfNone) {
  if (opt !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Caml_option.valFromOption(opt)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: errIfNone
          };
  }
}

function fromOptionError(opt, okIfNone) {
  if (opt !== undefined) {
    return {
            TAG: /* Error */1,
            _0: Caml_option.valFromOption(opt)
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: okIfNone
          };
  }
}

function tapError(res, f) {
  if (res.TAG !== /* Ok */0) {
    Curry._1(f, res._0);
  }
  return res;
}

function tapOk(res, f) {
  if (res.TAG === /* Ok */0) {
    Curry._1(f, res._0);
  }
  return res;
}

function mapError(r, f) {
  if (r.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: r._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Curry._1(f, r._0)
          };
  }
}

function recover(res, handler) {
  if (res.TAG === /* Ok */0) {
    return res;
  } else {
    return Curry._1(handler, res._0);
  }
}

function forEach(res, f) {
  if (res.TAG === /* Ok */0) {
    return Curry._1(f, res._0);
  }
  
}

var getExn = Belt_Result.getExn;

var mapWithDefaultU = Belt_Result.mapWithDefaultU;

var mapWithDefault = Belt_Result.mapWithDefault;

var mapU = Belt_Result.mapU;

var map = Belt_Result.map;

var flatMapU = Belt_Result.flatMapU;

var flatMap = Belt_Result.flatMap;

var getWithDefault = Belt_Result.getWithDefault;

var isOk = Belt_Result.isOk;

var isError = Belt_Result.isError;

var eqU = Belt_Result.eqU;

var eq = Belt_Result.eq;

var cmpU = Belt_Result.cmpU;

var cmp = Belt_Result.cmp;

export {
  getExn ,
  mapWithDefaultU ,
  mapWithDefault ,
  mapU ,
  map ,
  flatMapU ,
  flatMap ,
  getWithDefault ,
  isOk ,
  isError ,
  eqU ,
  eq ,
  cmpU ,
  cmp ,
  catchJsError ,
  catchJsErrorMessage ,
  toOption ,
  toOptionError ,
  fromOption ,
  fromOptionError ,
  tapError ,
  tapOk ,
  mapError ,
  recover ,
  forEach ,
  
}
/* StringUtils-DraftbitBuilder Not a pure module */
