// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function branch_encode(v) {
  return Js_dict.fromArray([[
                "apiKey",
                Decco.stringToJson(v.apiKey)
              ]]);
}

function branch_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var apiKey = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "apiKey"), null));
  if (apiKey.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              apiKey: apiKey._0
            }
          };
  }
  var e = apiKey._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".apiKey" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function decodeBranch(json) {
  return {
          apiKey: Json_decode.field("apiKey", Json_decode.string, json)
        };
}

function decode(json) {
  return {
          googleMapsApiKey: Json_decode.optional((function (param) {
                  return Json_decode.field("googleMapsApiKey", Json_decode.string, param);
                }), json),
          googleMobileAdsAppId: Json_decode.optional((function (param) {
                  return Json_decode.field("googleMobileAdsAppId", Json_decode.string, param);
                }), json),
          googleMobileAdsAutoInit: Json_decode.optional((function (param) {
                  return Json_decode.field("googleMobileAdsAutoInit", Json_decode.bool, param);
                }), json),
          branch: Json_decode.optional((function (param) {
                  return Json_decode.field("branch", decodeBranch, param);
                }), json),
          usesNonExemptEncryption: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("usesNonExemptEncryption", Json_decode.bool, param);
                }), json)
        };
}

var Decode = {
  decodeBranch: decodeBranch,
  decode: decode
};

function encodeBranch(branch) {
  return Json_encode.object_({
              hd: [
                "apiKey",
                branch.apiKey
              ],
              tl: /* [] */0
            });
}

function encode(config) {
  return Json_encode.object_({
              hd: [
                "googleMapsApiKey",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), config.googleMapsApiKey)
              ],
              tl: {
                hd: [
                  "googleMobileAdsAppId",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), config.googleMobileAdsAppId)
                ],
                tl: {
                  hd: [
                    "googleMobileAdsAutoInit",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), config.googleMobileAdsAutoInit)
                  ],
                  tl: {
                    hd: [
                      "branch",
                      Json_encode.nullable(encodeBranch, config.branch)
                    ],
                    tl: {
                      hd: [
                        "usesNonExemptEncryption",
                        config.usesNonExemptEncryption
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var Encode = {
  encodeBranch: encodeBranch,
  encode: encode
};

var defaults = {
  googleMapsApiKey: undefined,
  googleMobileAdsAppId: undefined,
  googleMobileAdsAutoInit: undefined,
  branch: undefined,
  usesNonExemptEncryption: false
};

export {
  branch_encode ,
  branch_decode ,
  defaults ,
  Decode ,
  Encode ,
  
}
/* No side effect */
