// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_array from "bs-platform/lib/es6/caml_array.mjs";

var _map = {"iphone8":"iphone8","iphone8plus":"iphone8plus","iphone11pro":"iphone11pro","iphone12":"iphone12","iphone13pro":"iphone13pro","iphone13promax":"iphone13promax","iphone14pro":"iphone14pro","iphone14promax":"iphone14promax","iphone15pro":"iphone15pro","iphone15promax":"iphone15promax","ipadair4thgeneration":"ipadair4thgeneration","ipadpro129inch5thgeneration":"ipadpro129inch5thgeneration","ipad9thgeneration":"ipad9thgeneration","nexus5":"nexus5","pixel4":"pixel4","pixel4xl":"pixel4xl","pixel6":"pixel6","pixel6pro":"pixel6pro","pixel7":"pixel7","pixel7pro":"pixel7pro","pixel8":"pixel8","pixel8pro":"pixel8pro","galaxytabs7":"galaxytabs7","laptop":"laptop","desktop":"desktop","bigscreen":"bigscreen"};

function deviceToJs(param) {
  return param;
}

function deviceFromJs(param) {
  return _map[param];
}

var _map$1 = {"ios":"ios","android":"android","web":"web"};

function platformToJs(param) {
  return param;
}

function platformFromJs(param) {
  return _map$1[param];
}

var _map$2 = {"portrait":"portrait","landscape":"landscape"};

function orientationToJs(param) {
  return param;
}

function orientationFromJs(param) {
  return _map$2[param];
}

function getDimensions(device, orientationOpt, param) {
  var orientation = orientationOpt !== undefined ? orientationOpt : "portrait";
  var dimensions = device === "galaxytabs7" ? ({
        width: 640,
        height: 1024
      }) : (
      device === "laptop" ? ({
            width: 992,
            height: 760
          }) : (
          device === "ipad9thgeneration" ? ({
                width: 810,
                height: 1080
              }) : (
              device === "iphone14promax" ? ({
                    width: 430,
                    height: 932
                  }) : (
                  device === "iphone13pro" || device === "iphone12" ? ({
                        width: 390,
                        height: 844
                      }) : (
                      device === "nexus5" ? ({
                            width: 360,
                            height: 640
                          }) : (
                          device === "pixel6pro" ? ({
                                width: 480,
                                height: 1040
                              }) : (
                              device === "pixel7pro" ? ({
                                    width: 412,
                                    height: 892
                                  }) : (
                                  device === "pixel8pro" ? ({
                                        width: 448,
                                        height: 899
                                      }) : (
                                      device === "pixel4xl" ? ({
                                            width: 480,
                                            height: 1013
                                          }) : (
                                          device === "iphone8" ? ({
                                                width: 375,
                                                height: 667
                                              }) : (
                                              device === "pixel4" ? ({
                                                    width: 360,
                                                    height: 760
                                                  }) : (
                                                  device === "pixel6" ? ({
                                                        width: 360,
                                                        height: 800
                                                      }) : (
                                                      device === "pixel7" ? ({
                                                            width: 412,
                                                            height: 732
                                                          }) : (
                                                          device === "pixel8" ? ({
                                                                width: 411,
                                                                height: 816
                                                              }) : (
                                                              device === "iphone15promax" ? ({
                                                                    width: 490,
                                                                    height: 932
                                                                  }) : (
                                                                  device === "iphone11pro" ? ({
                                                                        width: 375,
                                                                        height: 812
                                                                      }) : (
                                                                      device === "iphone15pro" || device === "iphone14pro" ? ({
                                                                            width: 393,
                                                                            height: 852
                                                                          }) : (
                                                                          device === "desktop" ? ({
                                                                                width: 1440,
                                                                                height: 1080
                                                                              }) : (
                                                                              device === "ipadpro129inch5thgeneration" ? ({
                                                                                    width: 1024,
                                                                                    height: 1366
                                                                                  }) : (
                                                                                  device === "ipadair4thgeneration" ? ({
                                                                                        width: 820,
                                                                                        height: 1180
                                                                                      }) : (
                                                                                      device === "bigscreen" ? ({
                                                                                            width: 1920,
                                                                                            height: 1080
                                                                                          }) : (
                                                                                          device === "iphone13promax" ? ({
                                                                                                width: 428,
                                                                                                height: 926
                                                                                              }) : ({
                                                                                                width: 414,
                                                                                                height: 736
                                                                                              })
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  if (orientation === "portrait") {
    return dimensions;
  } else {
    return {
            width: dimensions.height,
            height: dimensions.width
          };
  }
}

function getAppetizeFrameSize(device, orientation) {
  var dimensions;
  if (device === "iphone8plus") {
    dimensions = {
      width: 460,
      height: 946
    };
  } else if (device === "galaxytabs7") {
    dimensions = {
      width: 718,
      height: 1099
    };
  } else if (device === "ipad9thgeneration") {
    dimensions = {
      width: 904,
      height: 1290
    };
  } else if (device === "iphone14promax") {
    dimensions = {
      width: 482,
      height: 974
    };
  } else if (device === "iphone13pro" || device === "iphone12") {
    dimensions = {
      width: 440,
      height: 884
    };
  } else if (device === "nexus5") {
    dimensions = {
      width: 400,
      height: 795
    };
  } else if (device === "pixel7pro" || device === "pixel6pro") {
    dimensions = {
      width: 508,
      height: 1092
    };
  } else if (device === "pixel4xl") {
    dimensions = {
      width: 531,
      height: 1119
    };
  } else if (device === "iphone8") {
    dimensions = {
      width: 416,
      height: 870
    };
  } else if (device === "pixel4") {
    dimensions = {
      width: 397,
      height: 840
    };
  } else if (device === "pixel7" || device === "pixel6") {
    dimensions = {
      width: 404,
      height: 852
    };
  } else if (device === "iphone15promax") {
    dimensions = {
      width: 482,
      height: 967
    };
  } else if (device === "iphone11pro") {
    dimensions = {
      width: 416,
      height: 860
    };
  } else if (device === "iphone14pro") {
    dimensions = {
      width: 439,
      height: 892
    };
  } else if (device === "iphone15pro") {
    dimensions = {
      width: 439,
      height: 887
    };
  } else if (device === "ipadpro129inch5thgeneration") {
    dimensions = {
      width: 1122,
      height: 1464
    };
  } else if (device === "ipadair4thgeneration") {
    dimensions = {
      width: 924,
      height: 1280
    };
  } else if (device === "iphone13promax") {
    dimensions = {
      width: 481,
      height: 971
    };
  } else {
    var dimensions$1 = getDimensions(device, orientation, undefined);
    dimensions = {
      width: dimensions$1.width + 100 | 0,
      height: dimensions$1.height + 200 | 0
    };
  }
  if (orientation === "portrait") {
    return dimensions;
  } else {
    return {
            width: dimensions.height,
            height: dimensions.width
          };
  }
}

function getName(device) {
  if (device === "galaxytabs7") {
    return "Galaxy Tab S7";
  } else if (device === "laptop") {
    return "Laptop";
  } else if (device === "ipad9thgeneration") {
    return "iPad";
  } else if (device === "iphone14promax") {
    return "iPhone 14 Pro Max";
  } else if (device === "iphone12") {
    return "iPhone 12";
  } else if (device === "nexus5") {
    return "Nexus 5";
  } else if (device === "pixel6pro") {
    return "Pixel 6 Pro";
  } else if (device === "pixel7pro") {
    return "Pixel 7 Pro";
  } else if (device === "pixel8pro") {
    return "Pixel 8 Pro";
  } else if (device === "pixel4xl") {
    return "Pixel 4 XL";
  } else if (device === "iphone8") {
    return "iPhone 8";
  } else if (device === "pixel4") {
    return "Pixel 4";
  } else if (device === "pixel6") {
    return "Pixel 6";
  } else if (device === "pixel7") {
    return "Pixel 7";
  } else if (device === "pixel8") {
    return "Pixel 8";
  } else if (device === "iphone15promax") {
    return "iPhone 15 Pro Max";
  } else if (device === "iphone11pro") {
    return "iPhone 11 Pro";
  } else if (device === "iphone13pro") {
    return "iPhone 13 Pro";
  } else if (device === "iphone14pro") {
    return "iPhone 14 Pro";
  } else if (device === "iphone15pro") {
    return "iPhone 15 Pro";
  } else if (device === "desktop") {
    return "Desktop";
  } else if (device === "ipadpro129inch5thgeneration") {
    return "iPad Pro 12.9in";
  } else if (device === "ipadair4thgeneration") {
    return "iPad Air";
  } else if (device === "bigscreen") {
    return "Big Screen";
  } else if (device === "iphone13promax") {
    return "iPhone 13 Pro Max";
  } else {
    return "iPhone 8 Plus";
  }
}

function getPlatform(device) {
  if (device === "pixel8" || device === "pixel7" || device === "pixel6" || device === "pixel4" || device === "pixel4xl" || device === "pixel8pro" || device === "pixel7pro" || device === "pixel6pro" || device === "nexus5" || device === "galaxytabs7") {
    return "android";
  } else if (device === "bigscreen" || device === "desktop" || device === "laptop") {
    return "web";
  } else {
    return "ios";
  }
}

function isAppetizeCompatible(device) {
  return !(device === "bigscreen" || device === "desktop" || device === "laptop");
}

function availableDevices(viewMode, breakpoint) {
  var devices = breakpoint === "Mobile" ? [
      "iphone14pro",
      "iphone14promax",
      "iphone15pro",
      "iphone15promax",
      "iphone12",
      "iphone8",
      "iphone8plus",
      "iphone11pro",
      "iphone13pro",
      "iphone13promax",
      "pixel7",
      "nexus5",
      "pixel4",
      "pixel4xl",
      "pixel6",
      "pixel6pro",
      "pixel7pro",
      "pixel8",
      "pixel8pro"
    ] : (
      breakpoint === "Laptop" ? ["laptop"] : (
          breakpoint === "BigScreen" ? ["bigscreen"] : (
              breakpoint === "Tablet" ? [
                  "ipadair4thgeneration",
                  "ipad9thgeneration",
                  "ipadpro129inch5thgeneration",
                  "galaxytabs7"
                ] : ["desktop"]
            )
        )
    );
  if (viewMode === "IosPreview") {
    return devices.filter(function (device) {
                if (getPlatform(device) === "ios") {
                  return isAppetizeCompatible(device);
                } else {
                  return false;
                }
              });
  } else if (viewMode === "AndroidPreview") {
    return devices.filter(function (device) {
                if (getPlatform(device) === "android") {
                  return isAppetizeCompatible(device);
                } else {
                  return false;
                }
              });
  } else {
    return devices;
  }
}

function getDefaultDevice(viewMode, breakpoint) {
  return Caml_array.get(availableDevices(viewMode, breakpoint), 0);
}

function getDevice(device, viewMode, breakpoint) {
  var devices = availableDevices(viewMode, breakpoint);
  var match = devices.findIndex(function (d) {
        return d === device;
      });
  if (match !== -1) {
    return device;
  } else {
    return getDefaultDevice(viewMode, breakpoint);
  }
}

export {
  deviceToJs ,
  deviceFromJs ,
  platformToJs ,
  platformFromJs ,
  orientationToJs ,
  orientationFromJs ,
  getDimensions ,
  getAppetizeFrameSize ,
  getName ,
  getPlatform ,
  isAppetizeCompatible ,
  availableDevices ,
  getDefaultDevice ,
  getDevice ,
  
}
/* No side effect */
