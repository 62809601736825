// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as JsonSchema$DraftbitBuilder from "./JsonSchema.bs.js";

var _map = {"Data":"Data","Callback":"Callback","Icon":"Icon","Asset":"Asset"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var allParamTypes = [
  "Data",
  "Callback",
  "Icon",
  "Asset"
];

function t_encode(st) {
  return st;
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("ParamType.t", tFromJs, param);
}

function toSchema(testValue, type_) {
  if (type_ === "Data") {
    return JsonSchema$DraftbitBuilder.detect(testValue);
  } else if (type_ === "Asset" || type_ === "Icon") {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, "String");
  } else {
    return ;
  }
}

function toLabel(pt) {
  if (pt === "Callback") {
    return "Workflow";
  } else {
    return pt;
  }
}

function toFormType(pt) {
  if (pt === "Data") {
    return "string";
  } else if (pt === "Icon") {
    return "icon";
  } else if (pt === "Asset") {
    return "image";
  } else {
    return "action";
  }
}

export {
  tToJs ,
  tFromJs ,
  allParamTypes ,
  t_encode ,
  t_decode ,
  toSchema ,
  toLabel ,
  toFormType ,
  
}
/* JsonUtils-DraftbitBuilder Not a pure module */
