import "./index.css";
import "waterloo/tailwind.css";

import "overlayscrollbars/styles/overlayscrollbars.css";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "react-apollo";
import { createBrowserHistory } from "history";
import { matchPath } from "react-router-dom";
import { make as ReasonApolloHooksProvider } from "./ReasonApolloHooksProvider.bs";
import App from "./App";
import ApolloClient from "./config/Apollo";
import { HTML5Backend } from "react-dnd-html5-backend";

import * as serviceWorker from "./serviceWorkerRegistration.ts";
import { DndProvider } from "react-dnd";

// Fix setImmediate not defined error: https://github.com/prisma/prisma/issues/8558
global.setImmediate =
  global.setImmediate || ((fn, ...args) => global.setTimeout(fn, 0, ...args));

const { REACT_APP_SENTRY_DSN, REACT_APP_ENV, REACT_APP_API_URL } = process.env;

const history = createBrowserHistory();

// The SentryRoute component is not parameterizing
// this route for some reason - this fixes
const routes = [{ path: "/apps/:appUuid/screens/:screenUuid" }];

if (REACT_APP_SENTRY_DSN && process.env.REACT_APP_ENV !== "local") {
  const integrations = [
    Sentry.browserTracingIntegration({
      tracingOrigins: [REACT_APP_API_URL],
      idleTimeout: 3000, // see https://github.com/getsentry/sentry-javascript/issues/2860#issuecomment-691160291
      routingInstrumentation: Sentry.reactRouterV5BrowserTracingIntegration(
        history,
        routes,
        matchPath
      ),
    }),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      autoInject: false,
    }),
  ];

  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    release: `draftbit-builder@v${require("../package.json").version}`,
    environment: REACT_APP_ENV,
    integrations,
    tracesSampleRate: 0.2,
    maxBreadcrumbs: 50,

    beforeSend(event) {
      const sessionRewind = window.sessionRewind;
      if (sessionRewind) {
        const sessionRewindUrl = sessionRewind.getSessionUrl();
        if (sessionRewindUrl !== null) {
          event.extra = event.extra || {};
          event.extra["SessionRewind"] = sessionRewindUrl;
          return event;
        } else {
          return event;
        }
      }
    },
  });
}

const client = new ApolloClient();
// We need to clear the cache in Cypress testing
window._apolloClientCypress = client;

const rootComponent = (
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ReasonApolloHooksProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <App history={history} />
        </DndProvider>
      </ReasonApolloHooksProvider>
    </ApolloProvider>
  </React.StrictMode>
);

ReactDOM.render(rootComponent, document.getElementById("root"));

serviceWorker.unregister();

// This polyfills the Error.captureStackTrace function, which is a V8 feature, it's used
// by the json-parse-even-better-errors package, but it's missing on firefox causing breakage.
// Generated by ChatGPT
if (typeof Error.captureStackTrace !== "function") {
  Error.captureStackTrace = function (error, constructorOpt) {
    const stack = new Error().stack;

    // If `constructorOpt` is provided, remove frames up to and including that function
    if (constructorOpt) {
      const constructorName = constructorOpt.name;
      const stackArray = stack.split("\n");
      const constructorIndex = stackArray.findIndex(line =>
        line.includes(constructorName)
      );
      error.stack = stackArray.slice(constructorIndex + 1).join("\n");
    } else {
      // Otherwise, just assign the stack to the error
      error.stack = stack;
    }
  };
}
