// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as Asset$DraftbitBuilder from "./Asset.bs.js";
import * as AssetGQL$DraftbitBuilder from "./AssetGQL.bs.js";
import * as ParamType$DraftbitBuilder from "./ParamType.bs.js";

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "__typename",
                Decco.stringToJson(v.__typename)
              ],
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "name",
                Decco.stringToJson(v.name)
              ],
              [
                "testValue",
                v.testValue
              ],
              [
                "deepLink",
                Decco.boolToJson(v.deepLink)
              ],
              [
                "type_",
                ParamType$DraftbitBuilder.t_encode(v.type_)
              ],
              [
                "callbackParams",
                Decco.arrayToJson(Decco.stringToJson, v.callbackParams)
              ],
              [
                "asset",
                Decco.optionToJson(Asset$DraftbitBuilder.t_encode, v.asset)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var __typename = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "__typename"), null));
  if (__typename.TAG === /* Ok */0) {
    var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
    if (uuid.TAG === /* Ok */0) {
      var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
      if (name.TAG === /* Ok */0) {
        var testValue = {
          TAG: /* Ok */0,
          _0: Belt_Option.getWithDefault(Js_dict.get(dict$1, "testValue"), null)
        };
        if (testValue.TAG === /* Ok */0) {
          var deepLink = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "deepLink"), null));
          if (deepLink.TAG === /* Ok */0) {
            var type_ = ParamType$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "type_"), null));
            if (type_.TAG === /* Ok */0) {
              var callbackParams = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "callbackParams"), null));
              if (callbackParams.TAG === /* Ok */0) {
                var asset = Decco.optionFromJson(Asset$DraftbitBuilder.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "asset"), null));
                if (asset.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            __typename: __typename._0,
                            uuid: uuid._0,
                            name: name._0,
                            testValue: testValue._0,
                            deepLink: deepLink._0,
                            type_: type_._0,
                            callbackParams: callbackParams._0,
                            asset: asset._0
                          }
                        };
                }
                var e = asset._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".asset" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = callbackParams._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".callbackParams" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = type_._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".type_" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = deepLink._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".deepLink" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = testValue._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".testValue" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".name" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = uuid._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".uuid" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = __typename._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".__typename" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function toMeta(np) {
  return {
          uuid: np.uuid,
          name: np.name,
          type: np.type_,
          callbackParams: np.callbackParams,
          schema: ParamType$DraftbitBuilder.toSchema(np.testValue, np.type_)
        };
}

function getParamType(navParam) {
  return navParam.type_;
}

var ppx_printed_query = "fragment ScreenNavigationParamFields on ScreenNavigationParam   {\n__typename\n__typename  \nuuid  \nname  \ntestValue  \ndeepLink  \ntype_: type  \ncallbackParams  \nasset  {\n__typename\n..." + AssetGQL$DraftbitBuilder.AssetFragment.AssetFields.name + "   \n}\n\n}\n" + AssetGQL$DraftbitBuilder.AssetFragment.AssetFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type ScreenNavigationParam, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type ScreenNavigationParam is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type ScreenNavigationParam is missing");
  }
  var value$9 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_name = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type ScreenNavigationParam is missing");
  }
  var value$12 = Js_dict.get(value$2, "testValue");
  var field_testValue = value$12 !== undefined ? Caml_option.valFromOption(value$12) : Js_exn.raiseError("graphql_ppx: Field testValue on type ScreenNavigationParam is missing");
  var value$13 = Js_dict.get(value$2, "deepLink");
  var field_deepLink;
  if (value$13 !== undefined) {
    var value$14 = Caml_option.valFromOption(value$13);
    var value$15 = Js_json.decodeBoolean(value$14);
    field_deepLink = value$15 !== undefined ? value$15 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$14));
  } else {
    field_deepLink = Js_exn.raiseError("graphql_ppx: Field deepLink on type ScreenNavigationParam is missing");
  }
  var value$16 = Js_dict.get(value$2, "type_");
  var field_type_;
  if (value$16 !== undefined) {
    var value$17 = Caml_option.valFromOption(value$16);
    var value$18 = Js_json.decodeString(value$17);
    if (value$18 !== undefined) {
      switch (value$18) {
        case "Asset" :
            field_type_ = "Asset";
            break;
        case "Callback" :
            field_type_ = "Callback";
            break;
        case "Data" :
            field_type_ = "Data";
            break;
        case "Icon" :
            field_type_ = "Icon";
            break;
        default:
          field_type_ = Js_exn.raiseError("graphql_ppx: Unknown enum variant for ParamType: " + value$18);
      }
    } else {
      field_type_ = Js_exn.raiseError("graphql_ppx: Expected enum value for ParamType, got " + JSON.stringify(value$17));
    }
  } else {
    field_type_ = Js_exn.raiseError("graphql_ppx: Field type_ on type ScreenNavigationParam is missing");
  }
  var value$19 = Js_dict.get(value$2, "callbackParams");
  var field_callbackParams = value$19 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$19))).map(function (value) {
          var value$1 = Js_json.decodeString(value);
          if (value$1 !== undefined) {
            return value$1;
          } else {
            return Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value));
          }
        }) : Js_exn.raiseError("graphql_ppx: Field callbackParams on type ScreenNavigationParam is missing");
  var value$20 = Js_dict.get(value$2, "asset");
  var field_asset;
  if (value$20 !== undefined) {
    var value$21 = Caml_option.valFromOption(value$20);
    var match = Js_json.decodeNull(value$21);
    field_asset = match !== undefined ? undefined : Curry._1(AssetGQL$DraftbitBuilder.AssetFragment.AssetFields.parse, value$21);
  } else {
    field_asset = undefined;
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          name: field_name,
          testValue: field_testValue,
          deepLink: field_deepLink,
          type_: field_type_,
          callbackParams: field_callbackParams,
          asset: field_asset
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ScreenNavigationParamFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "ScreenNavigationParamFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  ScreenNavigationParamFields: ScreenNavigationParamFields
};

var GQL = {
  Fragment: Fragment
};

export {
  t_encode ,
  t_decode ,
  toMeta ,
  getParamType ,
  GQL ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
