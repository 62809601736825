// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as JsonSchema$DraftbitBuilder from "./JsonSchema.bs.js";

var _map = {"ArrayArray":"ArrayArray","Boolean":"Boolean","BooleanArray":"BooleanArray","Color":"Color","Date":"Date","DateArray":"DateArray","Function":"Function","Number":"Number","NumberArray":"NumberArray","Object":"Object","ObjectArray":"ObjectArray","String":"String","StringArray":"StringArray","Void":"Void"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function t_encode(st) {
  return st;
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("VariableType.t", tFromJs, param);
}

var allTypes = [
  "ArrayArray",
  "Boolean",
  "BooleanArray",
  "Color",
  "Date",
  "DateArray",
  "Function",
  "Number",
  "NumberArray",
  "Object",
  "ObjectArray",
  "String",
  "StringArray",
  "Void"
];

function detectJsonType(json) {
  var arr = Js_json.classify(json);
  if (typeof arr === "number") {
    switch (arr) {
      case /* JSONFalse */0 :
      case /* JSONTrue */1 :
          return "Boolean";
      case /* JSONNull */2 :
          return "Object";
      
    }
  } else {
    switch (arr.TAG | 0) {
      case /* JSONString */0 :
          return "String";
      case /* JSONNumber */1 :
          return "Number";
      case /* JSONObject */2 :
          return "Object";
      case /* JSONArray */3 :
          var match = Belt_Option.map(Belt_Array.get(arr._0, 0), Js_json.classify);
          if (match === undefined) {
            return "ObjectArray";
          }
          if (typeof match === "number") {
            switch (match) {
              case /* JSONFalse */0 :
              case /* JSONTrue */1 :
                  return "BooleanArray";
              default:
                return "ObjectArray";
            }
          } else {
            switch (match.TAG | 0) {
              case /* JSONString */0 :
                  return "StringArray";
              case /* JSONNumber */1 :
                  return "NumberArray";
              default:
                return "ObjectArray";
            }
          }
      
    }
  }
}

function detectJsonSchemaType(jsonSchema) {
  var match = jsonSchema.variant;
  if (typeof match === "string") {
    if (match === "String") {
      return "String";
    } else if (match === "Boolean") {
      return "Boolean";
    } else if (match === "Number") {
      return "Number";
    } else {
      return "Object";
    }
  }
  if (match.NAME !== "Array") {
    return "Object";
  }
  var match$1 = match.VAL.variant;
  if (match$1 === "String") {
    return "StringArray";
  } else if (match$1 === "Boolean") {
    return "BooleanArray";
  } else if (match$1 === "Number") {
    return "NumberArray";
  } else {
    return "ObjectArray";
  }
}

function toJsonSchema(vt) {
  if (vt === "String") {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, "String");
  } else if (vt === "Void" || vt === "Object") {
    return JsonSchema$DraftbitBuilder.empty(undefined);
  } else if (vt === "Boolean") {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, "Boolean");
  } else if (vt === "Number") {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, "Number");
  } else if (vt === "NumberArray") {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, {
                NAME: "Array",
                VAL: JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, "Number")
              });
  } else if (vt === "StringArray") {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, {
                NAME: "Array",
                VAL: JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, "String")
              });
  } else if (vt === "BooleanArray") {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, {
                NAME: "Array",
                VAL: JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, "Boolean")
              });
  } else {
    return JsonSchema$DraftbitBuilder.fromVariant(undefined, undefined, undefined, {
                NAME: "Array",
                VAL: JsonSchema$DraftbitBuilder.empty(undefined)
              });
  }
}

function isAtomic(t) {
  if (t === "Void" || t === "Date" || t === "Color" || t === "Number" || t === "Boolean") {
    return true;
  } else {
    return t === "String";
  }
}

function toInitialValue(type_) {
  if (type_ === "Object") {
    return "{}";
  } else if (type_ === "Boolean") {
    return "false";
  } else if (type_ === "Number") {
    return "0";
  } else if (type_ === "Color") {
    return "\"rgba(0,0,0,0)\"";
  } else if (type_ === "BooleanArray" || type_ === "DateArray" || type_ === "ArrayArray" || type_ === "StringArray" || type_ === "NumberArray" || type_ === "ObjectArray") {
    return "[]";
  } else if (type_ === "Date") {
    return "new Date()";
  } else if (type_ === "Void") {
    return "undefined";
  } else {
    return "\"\"";
  }
}

export {
  tToJs ,
  tFromJs ,
  t_encode ,
  t_decode ,
  allTypes ,
  detectJsonType ,
  detectJsonSchemaType ,
  toJsonSchema ,
  isAtomic ,
  toInitialValue ,
  
}
/* JsonUtils-DraftbitBuilder Not a pure module */
