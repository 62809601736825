// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GetTextWidth from "./getTextWidth";

function getTextWidth(prim, prim$1) {
  return GetTextWidth.getTextWidth(prim, prim$1);
}

export {
  getTextWidth ,
  
}
/* ./getTextWidth Not a pure module */
