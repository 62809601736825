const LOADING = "loading";
const COLOR = "color";
const CSS = "css";
const SIZE = "size";
const SIZE_UNIT = "sizeUnit";
const WIDTH = "width";
const WIDTH_UNIT = "widthUnit";
const HEIGHT = "height";
const HEIGHT_UNIT = "heightUnit";
const RADIUS = "radius";
const RADIUS_UNIT = "radiusUnit";
const MARGIN = "margin";

const commonValues = {
  [LOADING]: true,
  [COLOR]: "#000000",
  [CSS]: {},
};

const heightWidthValues = (height, width) => ({
  [HEIGHT]: height,
  [HEIGHT_UNIT]: "px",
  [WIDTH]: width,
  [WIDTH_UNIT]: "px",
});

const sizeValues = sizeValue => ({
  [SIZE]: sizeValue,
  [SIZE_UNIT]: "px",
});

export const sizeDefaults = sizeValue => {
  return Object.assign({}, commonValues, sizeValues(sizeValue));
};

export const sizeMarginDefaults = sizeValue => {
  return Object.assign({}, sizeDefaults(sizeValue), {
    [MARGIN]: "2px",
  });
};

export const heightWidthDefaults = (height, width) => {
  return Object.assign({}, commonValues, heightWidthValues(height, width));
};

export const heightWidthRadiusDefaults = (height, width, radius = 2) => {
  return Object.assign({}, heightWidthDefaults(height, width), {
    [RADIUS]: radius,
    [RADIUS_UNIT]: "px",
    [MARGIN]: "2px",
  });
};
