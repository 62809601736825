// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Notify from "./Notify";

function successWithProps(prim, prim$1, prim$2) {
  Notify.success(prim, prim$1, prim$2);
  
}

function success(message) {
  return successWithProps(message, undefined, undefined);
}

function successWithAction(prim, prim$1, prim$2, prim$3) {
  Notify.successWithAction(prim, prim$1, prim$2, prim$3);
  
}

function failure_(prim, prim$1) {
  Notify.failure(prim, prim$1);
  
}

function failure(key, message) {
  Notify.failure(message, key);
  
}

function loading(prim, prim$1, prim$2) {
  Notify.loading(prim, prim$1, prim$2);
  
}

function remove(prim) {
  Notify.remove(prim);
  
}

export {
  successWithProps ,
  success ,
  successWithAction ,
  failure_ ,
  failure ,
  loading ,
  remove ,
  
}
/* ./Notify Not a pure module */
