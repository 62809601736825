// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as React$1 from "@sentry/react";
import * as Text$DraftbitBuilder from "../waterloo/components/Text/Text.bs.js";
import * as Button$DraftbitBuilder from "../waterloo/components/Button/Button.bs.js";
import * as Spacer$DraftbitBuilder from "../waterloo/components/Layout/Spacer.bs.js";
import * as EnvUtils$DraftbitBuilder from "../utilities/EnvUtils.bs.js";
import * as ReactUtils$DraftbitBuilder from "../utilities/ReactUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "../utilities/StringUtils.bs.js";

function ErrorBoundaryInternal(Props) {
  var messageOpt = Props.message;
  var error = Props.error;
  var message = messageOpt !== undefined ? messageOpt : "Something went wrong. Please help us fix this by letting us know what happened.";
  return React.createElement("div", {
              className: "flex flex-col items-center justify-center p-4 text-center bg-mono-800"
            }, React.createElement(Spacer$DraftbitBuilder.make, {
                  children: React.createElement("span", {
                        className: "block font-sans text-sm text-mono-50"
                      }, message),
                  bottom: 4
                }), React.createElement(Spacer$DraftbitBuilder.make, {
                  children: React.createElement(Button$DraftbitBuilder.make, {
                        label: "File Bug Report",
                        kind: /* Warning */5,
                        size: /* Small */1,
                        onClick: (function (param) {
                            React$1.showReportDialog();
                            
                          })
                      }),
                  bottom: 2
                }), React.createElement(Spacer$DraftbitBuilder.make, {
                  children: React.createElement(Button$DraftbitBuilder.make, {
                        label: "Reload",
                        kind: /* Outline */3,
                        size: /* Small */1,
                        onClick: (function (param) {
                            window.location.reload();
                            
                          })
                      }),
                  bottom: 2
                }), ReactUtils$DraftbitBuilder.ifTrueRender(Caml_obj.caml_equal(EnvUtils$DraftbitBuilder.env, "local"), React.createElement("div", {
                      className: "cursor-pointer",
                      onClick: (function (param) {
                          console.log("Error", error);
                          
                        })
                    }, React.createElement(Text$DraftbitBuilder.make, {
                          color: "Warning400",
                          children: StringUtils$DraftbitBuilder.toJsonOrString(error)
                        }))));
}

var make = ErrorBoundaryInternal;

export {
  make ,
  
}
/* react Not a pure module */
