// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Pervasives from "bs-platform/lib/es6/pervasives.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Import$DraftbitBuilder from "./Import.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";
import * as AppJsonConfig$DraftbitBuilder from "./AppJsonConfig.bs.js";

var _map = {"GoogleMaps":"GoogleMaps","OpenAI":"OpenAI","RevenueCat":"RevenueCat","Segment":"Segment","Sentry":"Sentry"};

function simpleToJs(param) {
  return param;
}

function simpleFromJs(param) {
  return _map[param];
}

var allTypes = [
  "Segment",
  "GoogleMaps",
  "OpenAI",
  "Sentry",
  "RevenueCat"
];

function simple_encode(st) {
  return st;
}

function simple_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("Integrations.simple", simpleFromJs, param);
}

function sentryData_encode(v) {
  return Js_dict.fromArray([
              [
                "apiToken",
                Decco.stringToJson(v.apiToken)
              ],
              [
                "dataSourceName",
                Decco.stringToJson(v.dataSourceName)
              ],
              [
                "project",
                Decco.optionToJson(Decco.stringToJson, v.project)
              ],
              [
                "organization",
                Decco.optionToJson(Decco.stringToJson, v.organization)
              ],
              [
                "tracesSampleRate",
                Decco.optionToJson(Decco.floatToJson, v.tracesSampleRate)
              ],
              [
                "profilesSampleRate",
                Decco.optionToJson(Decco.floatToJson, v.profilesSampleRate)
              ]
            ]);
}

function sentryData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var apiToken = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "apiToken"), null));
  if (apiToken.TAG === /* Ok */0) {
    var dataSourceName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dataSourceName"), null));
    if (dataSourceName.TAG === /* Ok */0) {
      var project = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "project"), null));
      if (project.TAG === /* Ok */0) {
        var organization = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "organization"), null));
        if (organization.TAG === /* Ok */0) {
          var tracesSampleRate = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tracesSampleRate"), null));
          if (tracesSampleRate.TAG === /* Ok */0) {
            var profilesSampleRate = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "profilesSampleRate"), null));
            if (profilesSampleRate.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        apiToken: apiToken._0,
                        dataSourceName: dataSourceName._0,
                        project: project._0,
                        organization: organization._0,
                        tracesSampleRate: tracesSampleRate._0,
                        profilesSampleRate: profilesSampleRate._0
                      }
                    };
            }
            var e = profilesSampleRate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".profilesSampleRate" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = tracesSampleRate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".tracesSampleRate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = organization._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".organization" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = project._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".project" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = dataSourceName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dataSourceName" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = apiToken._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".apiToken" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function sentryDataFromJson(param) {
  return JsonUtils$DraftbitBuilder.decodeDecco(sentryData_decode, param);
}

function revenueCatData_encode(v) {
  return Js_dict.fromArray([
              [
                "iosToken",
                Decco.stringToJson(v.iosToken)
              ],
              [
                "androidToken",
                Decco.stringToJson(v.androidToken)
              ]
            ]);
}

function revenueCatData_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var iosToken = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "iosToken"), null));
  if (iosToken.TAG === /* Ok */0) {
    var androidToken = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "androidToken"), null));
    if (androidToken.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                iosToken: iosToken._0,
                androidToken: androidToken._0
              }
            };
    }
    var e = androidToken._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".androidToken" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = iosToken._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".iosToken" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function revenueCatDataFromJson(param) {
  return JsonUtils$DraftbitBuilder.decodeDecco(revenueCatData_decode, param);
}

function fullToSimple(full) {
  var variant = full.NAME;
  if (variant === "GoogleMaps") {
    return "GoogleMaps";
  } else if (variant === "Segment") {
    return "Segment";
  } else if (variant === "Sentry") {
    return "Sentry";
  } else if (variant === "RevenueCat") {
    return "RevenueCat";
  } else {
    return "OpenAI";
  }
}

function app_encode(v) {
  return Js_dict.fromArray([
              [
                "disabledIntegrations",
                Decco.arrayToJson(simple_encode, v.disabledIntegrations)
              ],
              [
                "config",
                AppJsonConfig$DraftbitBuilder.config_encode(v.config)
              ],
              [
                "openAiApiKey",
                Decco.optionToJson(Decco.stringToJson, v.openAiApiKey)
              ],
              [
                "segmentKey",
                Decco.optionToJson(Decco.stringToJson, v.segmentKey)
              ],
              [
                "sentryData",
                Decco.optionToJson(sentryData_encode, v.sentryData)
              ],
              [
                "revenueCatData",
                Decco.optionToJson(revenueCatData_encode, v.revenueCatData)
              ]
            ]);
}

function app_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var disabledIntegrations = Decco.arrayFromJson(simple_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "disabledIntegrations"), null));
  if (disabledIntegrations.TAG === /* Ok */0) {
    var config = AppJsonConfig$DraftbitBuilder.config_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "config"), null));
    if (config.TAG === /* Ok */0) {
      var openAiApiKey = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "openAiApiKey"), null));
      if (openAiApiKey.TAG === /* Ok */0) {
        var segmentKey = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "segmentKey"), null));
        if (segmentKey.TAG === /* Ok */0) {
          var sentryData = Decco.optionFromJson(sentryData_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sentryData"), null));
          if (sentryData.TAG === /* Ok */0) {
            var revenueCatData = Decco.optionFromJson(revenueCatData_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "revenueCatData"), null));
            if (revenueCatData.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        disabledIntegrations: disabledIntegrations._0,
                        config: config._0,
                        openAiApiKey: openAiApiKey._0,
                        segmentKey: segmentKey._0,
                        sentryData: sentryData._0,
                        revenueCatData: revenueCatData._0
                      }
                    };
            }
            var e = revenueCatData._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".revenueCatData" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = sentryData._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".sentryData" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = segmentKey._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".segmentKey" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = openAiApiKey._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".openAiApiKey" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = config._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".config" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = disabledIntegrations._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".disabledIntegrations" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function getIntegration(app, integrationType) {
  if (integrationType !== "GoogleMaps") {
    if (integrationType === "Segment") {
      return Belt_Option.map(StringUtils$DraftbitBuilder.getNonEmpty(app.segmentKey), (function (k) {
                    return {
                            NAME: "Segment",
                            VAL: k
                          };
                  }));
    } else if (integrationType === "Sentry") {
      return Belt_Option.map(app.sentryData, (function (d) {
                    return {
                            NAME: "Sentry",
                            VAL: d
                          };
                  }));
    } else if (integrationType === "RevenueCat") {
      return Belt_Option.map(app.revenueCatData, (function (d) {
                    return {
                            NAME: "RevenueCat",
                            VAL: d
                          };
                  }));
    } else {
      return Belt_Option.map(StringUtils$DraftbitBuilder.getNonEmpty(app.openAiApiKey), (function (k) {
                    return {
                            NAME: "OpenAI",
                            VAL: k
                          };
                  }));
    }
  }
  var match = app.config;
  var match$1 = match.expo;
  var match$2 = StringUtils$DraftbitBuilder.getNonEmpty(match$1.web.googleMapsApiKey);
  var match$3 = StringUtils$DraftbitBuilder.getNonEmpty(match$1.ios.config.googleMapsApiKey);
  var match$4 = Belt_Option.flatMap(Belt_Option.flatMap(match$1.android.config, (function (c) {
              return c.googleMaps;
            })), (function (m) {
          return StringUtils$DraftbitBuilder.noneIfEmpty(m.apiKey);
        }));
  if (match$2 === undefined && match$3 === undefined && match$4 === undefined) {
    return ;
  }
  return {
          NAME: "GoogleMaps",
          VAL: {
            web: match$2,
            ios: match$3,
            android: match$4
          }
        };
}

function getIntegrations(app) {
  return Belt_Array.keepMap(allTypes, (function (param) {
                return getIntegration(app, param);
              }));
}

function getLibraries(it) {
  if (typeof it === "string") {
    return [];
  }
  var variant = it.NAME;
  if (variant === "Segment") {
    return [
            "@segment/analytics-react-native",
            "@segment/sovran-react-native",
            "react-native-get-random-values"
          ];
  } else if (variant === "Sentry") {
    return ["@sentry/react-native"];
  } else if (variant === "RevenueCat") {
    return ["react-native-purchases"];
  } else {
    return [];
  }
}

var sentryImport = Import$DraftbitBuilder.makeWildCard("Sentry", "@sentry/react-native");

var revenueCatImport = Import$DraftbitBuilder.makeDefault("Purchases", "react-native-purchases");

function getAppJsImports(it) {
  if (typeof it === "string") {
    return [];
  }
  var variant = it.NAME;
  if (variant === "Segment") {
    return Import$DraftbitBuilder.makeMulti("@segment/analytics-react-native", [
                "createClient",
                "AnalyticsProvider"
              ]);
  } else if (variant === "Sentry") {
    return [
            sentryImport,
            Import$DraftbitBuilder.make(undefined, undefined, undefined, "./AppNavigator", "routingInstrumentation", undefined)
          ];
  } else if (variant === "RevenueCat") {
    return [revenueCatImport];
  } else {
    return [];
  }
}

function getScreenImports(it) {
  if (typeof it === "string") {
    return [];
  }
  var variant = it.NAME;
  if (variant === "Segment") {
    return Import$DraftbitBuilder.makeMulti("@segment/analytics-react-native", ["useAnalytics"]);
  } else if (variant === "Sentry") {
    return [sentryImport];
  } else if (variant === "RevenueCat") {
    return [revenueCatImport];
  } else {
    return [];
  }
}

function getAppNavigatorJsImports(it) {
  if (typeof it === "string") {
    return [];
  } else if (it.NAME === "Sentry") {
    return [sentryImport];
  } else {
    return [];
  }
}

function getAppNavigatorJsInitialization(intg) {
  if (typeof intg === "string") {
    return [];
  } else if (intg.NAME === "Sentry") {
    return ["export const routingInstrumentation = new Sentry.ReactNavigationInstrumentation({\n         enableTimeToInitialDisplay: true,\n       });\n      "];
  } else {
    return [];
  }
}

function getInRootNavigatorComponentCode(intg) {
  if (typeof intg === "string" || intg.NAME !== "Sentry") {
    return ;
  } else {
    return "const navigationRef = React.useRef()";
  }
}

function getRootNavigatorContainerArgs(intg) {
  if (typeof intg === "string") {
    return [];
  } else if (intg.NAME === "Sentry") {
    return [
            "ref={navigationRef}",
            "onReady={() => routingInstrumentation.registerNavigationContainer(navigationRef)}"
          ];
  } else {
    return [];
  }
}

function getAppJsInitialization(env, intg) {
  if (typeof intg === "string") {
    return [];
  }
  var variant = intg.NAME;
  if (variant === "Segment") {
    return ["const segmentClient = createClient({ writeKey: " + StringUtils$DraftbitBuilder.quote(intg.VAL) + ", trackAppLifecycleEvents: true });"];
  }
  if (variant !== "Sentry") {
    return [];
  }
  var match = intg.VAL;
  return ["Sentry.init({\n         dsn: " + StringUtils$DraftbitBuilder.quote(match.dataSourceName) + ",\n         enableInExpoDevelopment: true,\n         debug: " + Pervasives.string_of_bool(env === "Development") + ",\n         integrations: [new Sentry.ReactNativeTracing({ routingInstrumentation })],\n         attachScreenshot: true,\n         tracesSampleRate: " + Belt_Option.getWithDefault(match.tracesSampleRate, 1.0).toString() + ",\n         _experiments: {\n           profilesSampleRate: " + Belt_Option.getWithDefault(match.profilesSampleRate, 1.0).toString() + ",\n         },\n       });"];
}

function getInAppComponentCode(intg) {
  if (typeof intg === "string") {
    return ;
  }
  if (intg.NAME !== "RevenueCat") {
    return ;
  }
  var match = intg.VAL;
  return "\n    React.useEffect(() => {\n      async function initRevenueCat() {\n        if (Platform.OS == \"android\") {\n          await Purchases.configure({ apiKey: " + StringUtils$DraftbitBuilder.quote(match.androidToken) + " });\n        } else {\n          await Purchases.configure({ apiKey: " + StringUtils$DraftbitBuilder.quote(match.iosToken) + " });\n        }\n      }\n      initRevenueCat()\n    }, [])\n    ";
}

function getAppProviders(intg) {
  if (typeof intg === "string") {
    return [];
  } else if (intg.NAME === "Segment") {
    return [[
              "AnalyticsProvider",
              "client={segmentClient}"
            ]];
  } else {
    return [];
  }
}

function getAppComponentWrappers(intg) {
  if (typeof intg === "string") {
    return [];
  } else if (intg.NAME === "Sentry") {
    return ["Sentry.wrap"];
  } else {
    return [];
  }
}

function getCustomFiles(intg) {
  return [];
}

function getExpoPlugins(intg) {
  if (typeof intg === "string") {
    return [];
  }
  if (intg.NAME !== "Sentry") {
    return [];
  }
  var match = intg.VAL;
  return [[
            "@sentry/react-native/expo",
            {
              url: "https://sentry.io/",
              project: match.project,
              organization: match.organization
            }
          ]];
}

function getDotEnv(intg) {
  if (typeof intg === "string") {
    return [];
  } else if (intg.NAME === "Sentry") {
    return [[
              "SENTRY_AUTH_TOKEN",
              intg.VAL.apiToken
            ]];
  } else {
    return [];
  }
}

var emptySentryData = {
  apiToken: "",
  dataSourceName: "",
  project: undefined,
  organization: undefined,
  tracesSampleRate: undefined,
  profilesSampleRate: undefined
};

var emptyRevenueCatData = {
  iosToken: "",
  androidToken: ""
};

export {
  simpleToJs ,
  simpleFromJs ,
  allTypes ,
  simple_encode ,
  simple_decode ,
  sentryData_encode ,
  sentryData_decode ,
  emptySentryData ,
  sentryDataFromJson ,
  revenueCatData_encode ,
  revenueCatData_decode ,
  emptyRevenueCatData ,
  revenueCatDataFromJson ,
  fullToSimple ,
  app_encode ,
  app_decode ,
  getIntegration ,
  getIntegrations ,
  getLibraries ,
  sentryImport ,
  revenueCatImport ,
  getAppJsImports ,
  getScreenImports ,
  getAppNavigatorJsImports ,
  getAppNavigatorJsInitialization ,
  getInRootNavigatorComponentCode ,
  getRootNavigatorContainerArgs ,
  getAppJsInitialization ,
  getInAppComponentCode ,
  getAppProviders ,
  getAppComponentWrappers ,
  getCustomFiles ,
  getExpoPlugins ,
  getDotEnv ,
  
}
/* sentryImport Not a pure module */
