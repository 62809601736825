// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./PromiseUtils.bs.js";

function tap(value, f) {
  Curry._1(f, value);
  return value;
}

function tapLog(value, description) {
  console.log(description, value);
  return value;
}

function tapLogIf(value, description, cond) {
  if (Curry._1(cond, value)) {
    return tapLog(value, description);
  } else {
    return value;
  }
}

function tapLogMap(value, description, f) {
  console.log(description, Curry._1(f, value));
  return value;
}

function tapLogMapIf(value, description, cond, f) {
  if (Curry._1(cond, value)) {
    return tapLogMap(value, description, f);
  } else {
    return value;
  }
}

function tapLogOpt(value, description) {
  if (value !== undefined) {
    console.log(description, Caml_option.valFromOption(value));
  }
  return value;
}

function noop(param) {
  
}

function noop2(param, param$1) {
  
}

function toPx($$int) {
  return String($$int) + "px";
}

function toPt($$int) {
  return String($$int) + "pt";
}

function resetStorageAndLogout(client, param) {
  sessionStorage.clear();
  localStorage.clear();
  Belt_Option.forEach(client, (function (client) {
          return PromiseUtils$DraftbitBuilder.ignore(client.clearStore());
        }));
  window.location.href = "/login";
  window.location.reload();
  
}

function openNewTab(url) {
  var a = document.createElement("a");
  a.setAttribute("target", "_blank");
  a.setAttribute("href", url);
  var element = Webapi__Dom__Element.asHtmlElement(a);
  if (element !== undefined) {
    Caml_option.valFromOption(element).click();
    return ;
  }
  
}

function downloadUrl(url) {
  var a = document.createElement("a");
  a.setAttribute("href", url);
  var element = Webapi__Dom__Element.asHtmlElement(a);
  if (element === undefined) {
    return ;
  }
  var element$1 = Caml_option.valFromOption(element);
  element$1.click();
  element$1.hidden = true;
  
}

var whereAmI = (msg => console.log(new Error(msg).stack));

export {
  tap ,
  tapLog ,
  tapLogIf ,
  tapLogMap ,
  tapLogMapIf ,
  tapLogOpt ,
  noop ,
  noop2 ,
  toPx ,
  toPt ,
  resetStorageAndLogout ,
  openNewTab ,
  downloadUrl ,
  whereAmI ,
  
}
/* Webapi__Dom__Element Not a pure module */
