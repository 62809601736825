// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Utils$DraftbitBuilder from "../../../utilities/Utils.bs.js";
import * as Button$DraftbitBuilder from "../Button/Button.bs.js";
import Tooltip from "rc-tooltip/lib/Tooltip";
import * as DataCyDiv$DraftbitBuilder from "../../../components/DataCyDiv.bs.js";
import * as ReactUtils$DraftbitBuilder from "../../../utilities/ReactUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "../../../utilities/OptionUtils.bs.js";
import * as ReactMarkdown$DraftbitBuilder from "../../../components/ReactMarkdown.bs.js";
import * as OverlayTrigger$DraftbitBuilder from "../OverlayTrigger.bs.js";

((require('./Tooltip.css')));

function placementToString(x) {
  switch (x) {
    case /* Left */0 :
        return "left";
    case /* Right */1 :
        return "right";
    case /* Top */2 :
        return "top";
    case /* Bottom */3 :
        return "bottom";
    case /* TopLeft */4 :
        return "topLeft";
    case /* TopRight */5 :
        return "topRight";
    case /* BottomLeft */6 :
        return "bottomLeft";
    case /* BottomRight */7 :
        return "bottomRight";
    case /* LeftTop */8 :
        return "leftTop";
    case /* LeftBottom */9 :
        return "leftBottom";
    case /* RightTop */10 :
        return "rightTop";
    case /* RightBottom */11 :
        return "rightBottom";
    
  }
}

function Tooltip$Header(Props) {
  var iconOpt = Props.icon;
  var title = Props.title;
  var titleRightHand = Props.titleRightHand;
  var dataCyOpt = Props.dataCy;
  var colorwayOpt = Props.colorway;
  var icon = iconOpt !== undefined ? Caml_option.valFromOption(iconOpt) : null;
  var dataCy = dataCyOpt !== undefined ? dataCyOpt : "TooltipTitle";
  var colorway = colorwayOpt !== undefined ? colorwayOpt : /* Dark */0;
  return React.createElement(DataCyDiv$DraftbitBuilder.make, {
              dataCy: dataCy,
              className: "font-sans font-bold text-md text-mono-100 flex justify-between",
              children: null
            }, React.createElement("div", {
                  className: Cn.make({
                        hd: "flex items-center",
                        tl: {
                          hd: colorway ? "text-mono-900" : "text-mono-50",
                          tl: /* [] */0
                        }
                      })
                }, icon, React.createElement(ReactMarkdown$DraftbitBuilder.make, {
                      source: title
                    })), ReactUtils$DraftbitBuilder.maybeRender(titleRightHand, (function (source) {
                    return React.createElement(ReactMarkdown$DraftbitBuilder.make, {
                                source: source,
                                className: Cn.make({
                                      hd: "text-sm",
                                      tl: {
                                        hd: colorway ? "text-mono-800" : "text-mono-300",
                                        tl: /* [] */0
                                      }
                                    })
                              });
                  })));
}

var Header = {
  make: Tooltip$Header
};

function Tooltip$Description(Props) {
  var onClickLink = Props.onClickLink;
  var description = Props.description;
  var colorwayOpt = Props.colorway;
  var colorway = colorwayOpt !== undefined ? colorwayOpt : /* Dark */0;
  var tmp = {
    source: description
  };
  if (onClickLink !== undefined) {
    tmp.onClickLink = Caml_option.valFromOption(onClickLink);
  }
  return React.createElement("div", {
              className: Cn.make({
                    hd: "font-sans text-sm cursor-default pt-1",
                    tl: {
                      hd: colorway ? "text-mono-900" : "text-mono-300",
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(ReactMarkdown$DraftbitBuilder.make, tmp));
}

var Description = {
  make: Tooltip$Description
};

function Tooltip$Content(Props) {
  var icon = Props.icon;
  var title = Props.title;
  var titleRightHand = Props.titleRightHand;
  var description = Props.description;
  var onClickLink = Props.onClickLink;
  var tmp = {
    title: title
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  if (titleRightHand !== undefined) {
    tmp.titleRightHand = Caml_option.valFromOption(titleRightHand);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip$Header, tmp), ReactUtils$DraftbitBuilder.maybeRender(description, (function (description) {
                    var tmp = {
                      description: description
                    };
                    if (onClickLink !== undefined) {
                      tmp.onClickLink = Caml_option.valFromOption(onClickLink);
                    }
                    return React.createElement(Tooltip$Description, tmp);
                  })));
}

var Content = {
  make: Tooltip$Content
};

function Tooltip$Footer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "flex justify-between mt-2"
            }, children);
}

var Footer = {
  make: Tooltip$Footer
};

function Tooltip$ConfirmCancelFooter(Props) {
  var buttonSizeOpt = Props.buttonSize;
  var onCancel = Props.onCancel;
  var onConfirm = Props.onConfirm;
  var cancelTextOpt = Props.cancelText;
  var cancelButtonKindOpt = Props.cancelButtonKind;
  var cancelButtonDataCyOpt = Props.cancelButtonDataCy;
  var confirmText = Props.confirmText;
  var confirmButtonKindOpt = Props.confirmButtonKind;
  var confirmButtonDataCyOpt = Props.confirmButtonDataCy;
  var confirmButtonTitle = Props.confirmButtonTitle;
  var confirmDisabledOpt = Props.confirmDisabled;
  var confirmLoadingOpt = Props.confirmLoading;
  var confirmButtonFullWidthOpt = Props.confirmButtonFullWidth;
  var buttonSize = buttonSizeOpt !== undefined ? buttonSizeOpt : /* Small */1;
  var cancelText = cancelTextOpt !== undefined ? cancelTextOpt : "Cancel";
  var cancelButtonKind = cancelButtonKindOpt !== undefined ? cancelButtonKindOpt : /* Outline */3;
  var cancelButtonDataCy = cancelButtonDataCyOpt !== undefined ? cancelButtonDataCyOpt : "CancelButton";
  var confirmButtonKind = confirmButtonKindOpt !== undefined ? confirmButtonKindOpt : /* Solid */2;
  var confirmButtonDataCy = confirmButtonDataCyOpt !== undefined ? confirmButtonDataCyOpt : "ConfirmButton";
  var confirmDisabled = confirmDisabledOpt !== undefined ? confirmDisabledOpt : false;
  var confirmLoading = confirmLoadingOpt !== undefined ? confirmLoadingOpt : false;
  var confirmButtonFullWidth = confirmButtonFullWidthOpt !== undefined ? confirmButtonFullWidthOpt : true;
  var tmp = {
    label: confirmText,
    kind: confirmButtonKind,
    disabled: confirmDisabled || confirmLoading,
    loading: confirmLoading,
    fullWidth: confirmButtonFullWidth,
    size: buttonSize,
    dataCy: confirmButtonDataCy,
    onClick: confirmDisabled ? Utils$DraftbitBuilder.noop : onConfirm,
    stopPropagation: true
  };
  if (confirmButtonTitle !== undefined) {
    tmp.title = Caml_option.valFromOption(confirmButtonTitle);
  }
  return React.createElement(Tooltip$Footer, {
              children: null
            }, React.createElement(Button$DraftbitBuilder.make, {
                  label: cancelText,
                  kind: cancelButtonKind,
                  size: buttonSize,
                  dataCy: cancelButtonDataCy,
                  onClick: onCancel,
                  stopPropagation: true
                }), React.createElement("div", {
                  className: Cn.ifTrue("flex-1 ml-1", confirmButtonFullWidth)
                }, React.createElement(Button$DraftbitBuilder.make, tmp)));
}

var ConfirmCancelFooter = {
  make: Tooltip$ConfirmCancelFooter
};

function Tooltip$Confirmation(Props) {
  var title = Props.title;
  var description = Props.description;
  var onCancel = Props.onCancel;
  var onConfirm = Props.onConfirm;
  var confirmTextOpt = Props.confirmText;
  var buttonSize = Props.buttonSize;
  var cancelText = Props.cancelText;
  var cancelButtonKind = Props.cancelButtonKind;
  var cancelButtonDataCy = Props.cancelButtonDataCy;
  var confirmButtonKind = Props.confirmButtonKind;
  var confirmButtonDataCy = Props.confirmButtonDataCy;
  var confirmLoading = Props.confirmLoading;
  var confirmDisabled = Props.confirmDisabled;
  var childrenOpt = Props.children;
  var confirmText = confirmTextOpt !== undefined ? confirmTextOpt : "Ok";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var tmp = {
    title: title
  };
  if (description !== undefined) {
    tmp.description = Caml_option.valFromOption(description);
  }
  var tmp$1 = {
    onCancel: onCancel,
    onConfirm: onConfirm,
    confirmText: confirmText
  };
  if (buttonSize !== undefined) {
    tmp$1.buttonSize = Caml_option.valFromOption(buttonSize);
  }
  if (cancelText !== undefined) {
    tmp$1.cancelText = Caml_option.valFromOption(cancelText);
  }
  if (cancelButtonKind !== undefined) {
    tmp$1.cancelButtonKind = Caml_option.valFromOption(cancelButtonKind);
  }
  if (cancelButtonDataCy !== undefined) {
    tmp$1.cancelButtonDataCy = Caml_option.valFromOption(cancelButtonDataCy);
  }
  if (confirmButtonKind !== undefined) {
    tmp$1.confirmButtonKind = Caml_option.valFromOption(confirmButtonKind);
  }
  if (confirmButtonDataCy !== undefined) {
    tmp$1.confirmButtonDataCy = Caml_option.valFromOption(confirmButtonDataCy);
  }
  if (confirmDisabled !== undefined) {
    tmp$1.confirmDisabled = Caml_option.valFromOption(confirmDisabled);
  }
  if (confirmLoading !== undefined) {
    tmp$1.confirmLoading = Caml_option.valFromOption(confirmLoading);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip$Content, tmp), children, React.createElement(Tooltip$ConfirmCancelFooter, tmp$1));
}

var Confirmation = {
  make: Tooltip$Confirmation
};

function Tooltip$1(Props) {
  var placementOpt = Props.placement;
  var sizeOpt = Props.size;
  var colorwayOpt = Props.colorway;
  var content = Props.content;
  var offset = Props.offset;
  var visible = Props.visible;
  var onClose = Props.onClose;
  var classNameOpt = Props.className;
  var children = Props.children;
  var pointerOpt = Props.pointer;
  var overlayDataCyOpt = Props.overlayDataCy;
  var overlayClassNameOpt = Props.overlayClassName;
  var dataCyOpt = Props.dataCy;
  var placement = placementOpt !== undefined ? placementOpt : /* Bottom */3;
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */2;
  var colorway = colorwayOpt !== undefined ? colorwayOpt : /* Dark */0;
  var className = classNameOpt !== undefined ? classNameOpt : "h-full";
  var pointer = pointerOpt !== undefined ? pointerOpt : false;
  var overlayDataCy = overlayDataCyOpt !== undefined ? overlayDataCyOpt : "TooltipOverlay";
  var overlayClassName = overlayClassNameOpt !== undefined ? overlayClassNameOpt : "";
  var dataCy = dataCyOpt !== undefined ? dataCyOpt : "TooltipChildren";
  var tmp;
  if (typeof size === "number") {
    switch (size) {
      case /* XSmall */0 :
          tmp = "w-16";
          break;
      case /* Small */1 :
          tmp = "w-32";
          break;
      case /* Medium */2 :
          tmp = "w-48";
          break;
      case /* Large */3 :
          tmp = "w-64";
          break;
      case /* XLarge */4 :
          tmp = "w-80";
          break;
      case /* Fluid */5 :
          tmp = "";
          break;
      
    }
  } else {
    tmp = size._0;
  }
  var tmp$1 = {
    placement: placementToString(placement),
    overlay: ReactUtils$DraftbitBuilder.maybeRender(OptionUtils$DraftbitBuilder.noneIf(content, (function (param) {
                return !visible;
              })), (function (content) {
            return React.createElement(OverlayTrigger$DraftbitBuilder.make, {
                        id: "TooltipOverlay",
                        onClick: onClose,
                        children: content,
                        pointer: pointer,
                        dataCy: overlayDataCy
                      });
          })),
    mouseEnterDelay: 0.2,
    overlayClassName: Cn.make({
          hd: "p-2 rounded z-30 border border-solid",
          tl: {
            hd: overlayClassName,
            tl: {
              hd: colorway ? "bg-mono-100 text-mono-900 border-mono-200 light" : "bg-mono-900 border-mono-600",
              tl: {
                hd: tmp,
                tl: /* [] */0
              }
            }
          }
        }),
    visible: visible,
    destroyTooltipOnHide: true,
    arrowContent: React.createElement("div", {
          className: "rc-tooltip-arrow-inner"
        }),
    children: React.createElement(DataCyDiv$DraftbitBuilder.make, {
          dataCy: dataCy,
          className: className,
          children: children
        })
  };
  var tmp$2 = Belt_Option.map(offset, (function (o) {
          return {
                  offset: o
                };
        }));
  if (tmp$2 !== undefined) {
    tmp$1.align = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(Tooltip, tmp$1);
}

var make = Tooltip$1;

export {
  placementToString ,
  Header ,
  Description ,
  Content ,
  Footer ,
  ConfirmCancelFooter ,
  Confirmation ,
  make ,
  
}
/*  Not a pure module */
