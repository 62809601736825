// Generated by ReScript, PLEASE EDIT WITH CARE

import ScrollContainerJs from "./ScrollContainer.js";

var make = ScrollContainerJs;

export {
  make ,
  
}
/* make Not a pure module */
