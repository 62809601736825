// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as DictUtils$DraftbitBuilder from "./utilities/DictUtils.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";

var ppx_printed_query = "fragment ExpoAccountFields on ExpoAccount   {\n__typename\nuuid  \ndraftbitAccountEmail  \nuserAccountName  \nisPending  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type ExpoAccount, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type ExpoAccount is missing");
  }
  var value$6 = Js_dict.get(value$2, "draftbitAccountEmail");
  var field_draftbitAccountEmail;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_draftbitAccountEmail = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_draftbitAccountEmail = Js_exn.raiseError("graphql_ppx: Field draftbitAccountEmail on type ExpoAccount is missing");
  }
  var value$9 = Js_dict.get(value$2, "userAccountName");
  var field_userAccountName;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_userAccountName = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_userAccountName = Js_exn.raiseError("graphql_ppx: Field userAccountName on type ExpoAccount is missing");
  }
  var value$12 = Js_dict.get(value$2, "isPending");
  var field_isPending;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeBoolean(value$13);
    field_isPending = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$13));
  } else {
    field_isPending = Js_exn.raiseError("graphql_ppx: Field isPending on type ExpoAccount is missing");
  }
  return {
          uuid: field_uuid,
          draftbitAccountEmail: field_draftbitAccountEmail,
          userAccountName: field_userAccountName,
          isPending: field_isPending
        };
}

var name = "ExpoAccountFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ExpoAccountFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  ExpoAccountFields: ExpoAccountFields
};

var ppx_printed_query$1 = "mutation CreateExpoAccount($userAccountName: String!)  {\n__typename\ncreateExpoAccount(userAccountName: $userAccountName)  {\n__typename\n...ExpoAccountFields   \n}\n\n}\nfragment ExpoAccountFields on ExpoAccount   {\n__typename\nuuid  \ndraftbitAccountEmail  \nuserAccountName  \nisPending  \n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createExpoAccount");
  return {
          createExpoAccount: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field createExpoAccount on type Mutation is missing")
        };
}

function make(userAccountName, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "userAccountName",
                    userAccountName
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var userAccountName = variables.userAccountName;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "userAccountName",
                    userAccountName
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(userAccountName, param) {
  return Js_dict.fromArray([[
                  "userAccountName",
                  userAccountName
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, userAccountName, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "userAccountName",
                      userAccountName
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function useMutation(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition);
  var mutation = match[0];
  return function (userAccountName) {
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables(userAccountName, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                  return data.createExpoAccount;
                }), undefined, undefined, undefined, undefined, Caml_option.some(DictUtils$DraftbitBuilder.one("userAccountName", userAccountName)), undefined, "Create Expo Account", undefined, undefined, undefined);
  };
}

var Create = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  useMutation: useMutation
};

var ppx_printed_query$2 = "fragment AdminExpoAccountFields on ExpoAccount   {\n__typename\nuuid  \ndraftbitAccountEmail  \nuserAccountName  \nisPending  \nuser  {\n__typename\nemail  \n}\n\napps  {\n__typename\nuuid  \nname  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type ExpoAccount, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type ExpoAccount is missing");
  }
  var value$6 = Js_dict.get(value$2, "draftbitAccountEmail");
  var field_draftbitAccountEmail;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_draftbitAccountEmail = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_draftbitAccountEmail = Js_exn.raiseError("graphql_ppx: Field draftbitAccountEmail on type ExpoAccount is missing");
  }
  var value$9 = Js_dict.get(value$2, "userAccountName");
  var field_userAccountName;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_userAccountName = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_userAccountName = Js_exn.raiseError("graphql_ppx: Field userAccountName on type ExpoAccount is missing");
  }
  var value$12 = Js_dict.get(value$2, "isPending");
  var field_isPending;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeBoolean(value$13);
    field_isPending = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$13));
  } else {
    field_isPending = Js_exn.raiseError("graphql_ppx: Field isPending on type ExpoAccount is missing");
  }
  var value$15 = Js_dict.get(value$2, "user");
  var field_user;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeObject(value$16);
    if (value$17 !== undefined) {
      var value$18 = Js_dict.get(Caml_option.valFromOption(value$17), "email");
      var field_email;
      if (value$18 !== undefined) {
        var value$19 = Caml_option.valFromOption(value$18);
        var value$20 = Js_json.decodeString(value$19);
        field_email = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
      } else {
        field_email = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
      }
      field_user = {
        email: field_email
      };
    } else {
      field_user = Js_exn.raiseError("graphql_ppx: Expected object of type User, got " + JSON.stringify(value$16));
    }
  } else {
    field_user = Js_exn.raiseError("graphql_ppx: Field user on type ExpoAccount is missing");
  }
  var value$21 = Js_dict.get(value$2, "apps");
  var field_apps = value$21 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$21))).map(function (value) {
          var value$1 = Js_json.decodeObject(value);
          if (value$1 === undefined) {
            return Js_exn.raiseError("graphql_ppx: Expected object of type App, got " + JSON.stringify(value));
          }
          var value$2 = Caml_option.valFromOption(value$1);
          var value$3 = Js_dict.get(value$2, "uuid");
          var field_uuid;
          if (value$3 !== undefined) {
            var value$4 = Caml_option.valFromOption(value$3);
            var value$5 = Js_json.decodeString(value$4);
            field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
          } else {
            field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
          }
          var value$6 = Js_dict.get(value$2, "name");
          var field_name;
          if (value$6 !== undefined) {
            var value$7 = Caml_option.valFromOption(value$6);
            var value$8 = Js_json.decodeString(value$7);
            field_name = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
          } else {
            field_name = Js_exn.raiseError("graphql_ppx: Field name on type App is missing");
          }
          return {
                  uuid: field_uuid,
                  name: field_name
                };
        }) : Js_exn.raiseError("graphql_ppx: Field apps on type ExpoAccount is missing");
  return {
          uuid: field_uuid,
          draftbitAccountEmail: field_draftbitAccountEmail,
          userAccountName: field_userAccountName,
          isPending: field_isPending,
          user: field_user,
          apps: field_apps
        };
}

var name$1 = "AdminExpoAccountFields";

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var AdminExpoAccountFields = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  name: name$1,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var Fragment$1 = {
  AdminExpoAccountFields: AdminExpoAccountFields
};

var ppx_printed_query$3 = "query ExpoAccounts  {\n__typename\nexpoAccounts  {\n__typename\n...AdminExpoAccountFields   \n}\n\n}\nfragment AdminExpoAccountFields on ExpoAccount   {\n__typename\nuuid  \ndraftbitAccountEmail  \nuserAccountName  \nisPending  \nuser  {\n__typename\nemail  \n}\n\napps  {\n__typename\nuuid  \nname  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "expoAccounts");
  return {
          expoAccounts: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(parse$2) : Js_exn.raiseError("graphql_ppx: Field expoAccounts on type Query is missing")
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$1
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function useQuery(param) {
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$1);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (res) {
                return res.expoAccounts;
              }));
}

function refetch(param) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make$1(undefined));
}

var List = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  useQuery: useQuery,
  refetch: refetch
};

var ppx_printed_query$4 = "mutation AdminCreateExpoAccount($draftbitAccountEmail: String!, $userAccountName: String!)  {\n__typename\ncreateExpoAccount(draftbitAccountEmail: $draftbitAccountEmail, userAccountName: $userAccountName)  {\n__typename\n...AdminExpoAccountFields   \n}\n\n}\nfragment AdminExpoAccountFields on ExpoAccount   {\n__typename\nuuid  \ndraftbitAccountEmail  \nuserAccountName  \nisPending  \nuser  {\n__typename\nemail  \n}\n\napps  {\n__typename\nuuid  \nname  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createExpoAccount");
  return {
          createExpoAccount: value$2 !== undefined ? parse$2(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field createExpoAccount on type Mutation is missing")
        };
}

function make$2(draftbitAccountEmail, userAccountName, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "draftbitAccountEmail",
                    draftbitAccountEmail
                  ],
                  [
                    "userAccountName",
                    userAccountName
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeWithVariables$2(variables) {
  var draftbitAccountEmail = variables.draftbitAccountEmail;
  var userAccountName = variables.userAccountName;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "draftbitAccountEmail",
                    draftbitAccountEmail
                  ],
                  [
                    "userAccountName",
                    userAccountName
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeVariables$2(draftbitAccountEmail, userAccountName, param) {
  return Js_dict.fromArray([
                [
                  "draftbitAccountEmail",
                  draftbitAccountEmail
                ],
                [
                  "userAccountName",
                  userAccountName
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, draftbitAccountEmail, userAccountName, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "draftbitAccountEmail",
                      draftbitAccountEmail
                    ],
                    [
                      "userAccountName",
                      userAccountName
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$2
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

function useMutation$1(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, (function (param) {
          return [ApolloHooks$DraftbitBuilder.toQueryObj(make$1(undefined))];
        }), undefined, undefined, undefined, undefined, undefined, definition$2);
  var mutation = match[0];
  return function (draftbitAccountEmail, userAccountName) {
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables$2(draftbitAccountEmail, userAccountName, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                  return data.createExpoAccount;
                }), undefined, undefined, undefined, undefined, Caml_option.some(Js_dict.fromArray([
                        [
                          "userAccountName",
                          userAccountName
                        ],
                        [
                          "draftbitAccountEmail",
                          draftbitAccountEmail
                        ]
                      ])), undefined, "Admin Create Expo Account", undefined, undefined, undefined);
  };
}

var Create$1 = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4,
  useMutation: useMutation$1
};

var ppx_printed_query$5 = "mutation UpdateExpoAccount($input: UpdateExpoAccountInput!)  {\n__typename\nupdateExpoAccount(input: $input)  {\n__typename\n...AdminExpoAccountFields   \n}\n\n}\nfragment AdminExpoAccountFields on ExpoAccount   {\n__typename\nuuid  \ndraftbitAccountEmail  \nuserAccountName  \nisPending  \nuser  {\n__typename\nemail  \n}\n\napps  {\n__typename\nuuid  \nname  \n}\n\n}\n";

function parse$5(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateExpoAccount");
  return {
          updateExpoAccount: value$2 !== undefined ? parse$2(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field updateExpoAccount on type Mutation is missing")
        };
}

function json_of_UpdateExpoAccountInput(value) {
  return Js_dict.fromArray([
                [
                  "accountUuid",
                  value.accountUuid
                ],
                [
                  "isPending",
                  value.isPending
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$3(input, param) {
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateExpoAccountInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeWithVariables$3(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateExpoAccountInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeVariables$3(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_UpdateExpoAccountInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_UpdateExpoAccountInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$5,
  ppx_printed_query$5,
  definition_2$3
];

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

function useMutation$2(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$3);
  var mutation = match[0];
  return function (account, isPending) {
    var match = account.isPending;
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables$3({
                            accountUuid: account.uuid,
                            isPending: isPending
                          }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                  return data.updateExpoAccount;
                }), match ? (
                  isPending ? undefined : account.userAccountName + " is no longer pending"
                ) : (
                  isPending ? account.userAccountName + " has been reverted to pending state" : undefined
                ), undefined, undefined, undefined, Caml_option.some(DictUtils$DraftbitBuilder.fromArray([
                        [
                          "accountUuid",
                          account.uuid
                        ],
                        [
                          "isPending",
                          isPending ? "true" : "false"
                        ]
                      ])), undefined, "Update Expo Account", undefined, undefined, undefined);
  };
}

var Update = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  json_of_UpdateExpoAccountInput: json_of_UpdateExpoAccountInput,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5,
  useMutation: useMutation$2
};

var ppx_printed_query$6 = "mutation DeleteExpoAccount($accountUuid: ID!)  {\n__typename\ndeleteExpoAccount(accountUuid: $accountUuid)  \n}\n";

function parse$6(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "deleteExpoAccount");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeBoolean(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field deleteExpoAccount on type Mutation is missing");
  }
  return {
          deleteExpoAccount: tmp
        };
}

function make$4(accountUuid, param) {
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([[
                    "accountUuid",
                    accountUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeWithVariables$4(variables) {
  var accountUuid = variables.accountUuid;
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([[
                    "accountUuid",
                    accountUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeVariables$4(accountUuid, param) {
  return Js_dict.fromArray([[
                  "accountUuid",
                  accountUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, accountUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "accountUuid",
                      accountUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$6,
  ppx_printed_query$6,
  definition_2$4
];

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

function useMutation$3(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, (function (param) {
          return [ApolloHooks$DraftbitBuilder.toQueryObj(make$1(undefined))];
        }), undefined, undefined, undefined, undefined, undefined, definition$4);
  var mutation = match[0];
  return function (account) {
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables$4(account.uuid, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                  return data.deleteExpoAccount;
                }), "Deleted Expo Account for " + account.userAccountName, undefined, undefined, undefined, Caml_option.some(DictUtils$DraftbitBuilder.one("accountUuid", account.uuid)), undefined, "Delete Expo Account", undefined, undefined, undefined);
  };
}

var Delete = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6,
  useMutation: useMutation$3
};

var Admin = {
  Fragment: Fragment$1,
  List: List,
  Create: Create$1,
  Update: Update,
  Delete: Delete
};

export {
  Fragment ,
  Create ,
  Admin ,
  
}
/* DictUtils-DraftbitBuilder Not a pure module */
