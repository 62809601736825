/** @jsx jsx */
import * as React from "react";
import { keyframes, css, jsx } from "@emotion/core";
import { heightWidthRadiusDefaults } from "utilities/loaderHelpers";

const fade = keyframes`
  50% {opacity: 0.3}
  100% {opacity: 1}
`;

class Loader extends React.PureComponent {
  static defaultProps = heightWidthRadiusDefaults(15, 5, 2);
  quarter = () => this.props.loaderRadius / 2 + this.props.loaderRadius / 5.5;

  style = i => {
    const {
      height,
      width,
      margin,
      color,
      radius,
      widthUnit,
      heightUnit,
      radiusUnit,
    } = this.props;

    return css`
      position: absolute;
      width: ${`${width}${widthUnit}`};
      height: ${`${height}${heightUnit}`};
      margin: ${margin};
      background-color: ${color};
      border-radius: ${`${radius}${radiusUnit}`};
      transition: 2s;
      animation-fill-mode: "both";
      animation: ${fade} 1.2s ${i * 0.12}s infinite ease-in-out;
    `;
  };

  wrapper = () => {
    return css`
      position: relative;
      font-size: 0;
      top: ${this.props.loaderRadius}px;
      left: ${this.props.loaderRadius}px;
      width: ${this.props.loaderRadius * 3}px;
      height: ${this.props.loaderRadius * 3}px;
    `;
  };

  a = () => css`
    ${this.style(1)};
    top: ${this.props.loaderRadius}px;
    left: 0;
  `;
  b = () => css`
    ${this.style(2)};
    top: ${this.quarter()}px;
    left: ${this.quarter()}px;
    transform: rotate(-45deg);
  `;
  c = () => css`
    ${this.style(3)};
    top: 0;
    left: ${this.props.loaderRadius}px;
    transform: rotate(90deg);
  `;
  d = () => css`
    ${this.style(4)};
    top: ${-this.quarter()}px;
    left: ${this.quarter()}px;
    transform: rotate(45deg);
  `;
  e = () => css`
    ${this.style(5)};
    top: ${-this.props.loaderRadius}px;
    left: 0;
  `;
  f = () => css`
    ${this.style(6)};
    top: ${-this.quarter()}px;
    left: ${-this.quarter()}px;
    transform: rotate(-45deg);
  `;
  g = () => css`
    ${this.style(7)};
    top: 0;
    left: ${-this.props.loaderRadius}px;
    transform: rotate(90deg);
  `;
  h = () => css`
    ${this.style(8)};
    top: ${this.quarter()}px;
    left: ${-this.quarter()}px;
    transform: rotate(45deg);
  `;

  render() {
    const { loading, css } = this.props;

    return loading ? (
      <div css={[this.wrapper(), css]}>
        <div css={this.a()} />
        <div css={this.b()} />
        <div css={this.c()} />
        <div css={this.d()} />
        <div css={this.e()} />
        <div css={this.f()} />
        <div css={this.g()} />
        <div css={this.h()} />
      </div>
    ) : null;
  }
}

export default Loader;
