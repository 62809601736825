// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_int from "bs-platform/lib/es6/js_int.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Breakpoint$DraftbitBuilder from "./Breakpoint.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as FreeBrandsSvgIcons from "@fortawesome/free-brands-svg-icons";

var _map = {"SingleScreen":"SingleScreen","WebPreview":"WebPreview","IosPreview":"IosPreview","AndroidPreview":"AndroidPreview"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var all = [
  "SingleScreen",
  "WebPreview",
  "IosPreview",
  "AndroidPreview"
];

function fromString(str) {
  return Belt_Option.getWithDefault(tFromJs(str), "SingleScreen");
}

function isPhoneOnly(t) {
  if (t === "AndroidPreview") {
    return true;
  } else {
    return t === "IosPreview";
  }
}

function maximumScale(viewMode) {
  if (viewMode === "AndroidPreview" || viewMode === "IosPreview") {
    return 100;
  } else {
    return Js_int.max;
  }
}

function isAvailable(viewMode, breakpoint) {
  if (Breakpoint$DraftbitBuilder.isPhone(breakpoint)) {
    return false;
  } else {
    return isPhoneOnly(viewMode);
  }
}

function toLabel(viewMode) {
  if (viewMode === "IosPreview") {
    return "iOS Preview";
  } else if (viewMode === "SingleScreen") {
    return "Draft View";
  } else if (viewMode === "AndroidPreview") {
    return "Android Preview";
  } else {
    return "Web Preview";
  }
}

function toIcon(viewMode) {
  if (viewMode === "IosPreview") {
    return FreeBrandsSvgIcons.faApple;
  } else if (viewMode === "SingleScreen") {
    return ProSolidSvgIcons.faDrawSquare;
  } else if (viewMode === "AndroidPreview") {
    return FreeBrandsSvgIcons.faAndroid;
  } else {
    return ProLightSvgIcons.faGlobe;
  }
}

export {
  tToJs ,
  tFromJs ,
  all ,
  fromString ,
  isPhoneOnly ,
  maximumScale ,
  isAvailable ,
  toLabel ,
  toIcon ,
  
}
/* Breakpoint-DraftbitBuilder Not a pure module */
