// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as JsonSchema$DraftbitBuilder from "./JsonSchema.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as ComponentUsage$DraftbitBuilder from "./ComponentUsage.bs.js";
import * as RestApiHeaderUsage$DraftbitBuilder from "./RestApiHeaderUsage.bs.js";

function toMeta(gv) {
  return {
          uuid: gv.uuid,
          name: gv.name,
          schema: JsonSchema$DraftbitBuilder.detect(gv.value)
        };
}

function toTemplateVariable(gv) {
  return {
          name: gv.name,
          value: gv.value,
          prodValue: gv.prodValue,
          kind: gv.kind,
          fallbackToDevValue: gv.fallbackToDevValue
        };
}

var ppx_printed_query = "fragment GlobalVariableUsageFields on GlobalVariable   {\n__typename\n__typename  \nuuid  \ncomponentsUsingGlobalVariable  {\n__typename\n..." + ComponentUsage$DraftbitBuilder.GQL.Fragment.ComponentUsageFields.name + "   \n}\n\nrestApiHeadersUsingGlobalVariable  {\n__typename\n..." + RestApiHeaderUsage$DraftbitBuilder.GQL.Fragment.RestApiHeaderUsageFields.name + "   \n}\n\n}\n" + ComponentUsage$DraftbitBuilder.GQL.Fragment.ComponentUsageFields.query + RestApiHeaderUsage$DraftbitBuilder.GQL.Fragment.RestApiHeaderUsageFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type GlobalVariable, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type GlobalVariable is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type GlobalVariable is missing");
  }
  var value$9 = Js_dict.get(value$2, "componentsUsingGlobalVariable");
  var field_componentsUsingGlobalVariable = value$9 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$9))).map(function (value) {
          return Curry._1(ComponentUsage$DraftbitBuilder.GQL.Fragment.ComponentUsageFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field componentsUsingGlobalVariable on type GlobalVariable is missing");
  var value$10 = Js_dict.get(value$2, "restApiHeadersUsingGlobalVariable");
  var field_restApiHeadersUsingGlobalVariable = value$10 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$10))).map(function (value) {
          return Curry._1(RestApiHeaderUsage$DraftbitBuilder.GQL.Fragment.RestApiHeaderUsageFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field restApiHeadersUsingGlobalVariable on type GlobalVariable is missing");
  return {
          __typename: field___typename,
          uuid: field_uuid,
          componentsUsingGlobalVariable: field_componentsUsingGlobalVariable,
          restApiHeadersUsingGlobalVariable: field_restApiHeadersUsingGlobalVariable
        };
}

var name = "GlobalVariableUsageFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var GlobalVariableUsageFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var UsageFragment = {
  GlobalVariableUsageFields: GlobalVariableUsageFields
};

var ppx_printed_query$1 = "query GlobalVariableUsagesByApp($appUuid: ID!)  {\n__typename\napp(appUuid: $appUuid)  {\n__typename\n__typename  \nuuid  \nglobalVariables  {\n__typename\n...GlobalVariableUsageFields   \n}\n\n}\n\n}\n" + ppx_printed_query;

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "app");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "__typename");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "uuid");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeString(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$10 = Js_dict.get(value$3, "globalVariables");
    tmp = {
      __typename: tmp$1,
      uuid: tmp$2,
      globalVariables: value$10 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$10))).map(parse) : Js_exn.raiseError("graphql_ppx: Field globalVariables on type App is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field app on type Query is missing");
  }
  return {
          app: tmp
        };
}

function make(appUuid, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var appUuid = variables.appUuid;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(appUuid, param) {
  return Js_dict.fromArray([[
                  "appUuid",
                  appUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, appUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "appUuid",
                      appUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function useQuery(appUuid, skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables(appUuid, undefined)), undefined, undefined, undefined, skip || !Uuid$DraftbitBuilder.isUuid(appUuid), undefined, undefined, definition);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.app.globalVariables;
              }));
}

function refetch(appUuid) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make(appUuid, undefined));
}

var ListUsageByApp = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  useQuery: useQuery,
  refetch: refetch
};

var ppx_printed_query$2 = "fragment GlobalVariableFields on GlobalVariable   {\n__typename\n__typename  \nuuid  \nname  \nvalue  \nprodValue  \nkind  \nfallbackToDevValue  \n}\n";

function parse$2(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type GlobalVariable, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type GlobalVariable is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type GlobalVariable is missing");
  }
  var value$9 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_name = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type GlobalVariable is missing");
  }
  var value$12 = Js_dict.get(value$2, "value");
  var field_value = value$12 !== undefined ? Caml_option.valFromOption(value$12) : Js_exn.raiseError("graphql_ppx: Field value on type GlobalVariable is missing");
  var value$13 = Js_dict.get(value$2, "prodValue");
  var field_prodValue;
  if (value$13 !== undefined) {
    var value$14 = Caml_option.valFromOption(value$13);
    var match = Js_json.decodeNull(value$14);
    field_prodValue = match !== undefined ? undefined : Caml_option.some(value$14);
  } else {
    field_prodValue = undefined;
  }
  var value$15 = Js_dict.get(value$2, "kind");
  var field_kind;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeString(value$16);
    if (value$17 !== undefined) {
      switch (value$17) {
        case "PERSISTED" :
            field_kind = "PERSISTED";
            break;
        case "STATIC" :
            field_kind = "STATIC";
            break;
        default:
          field_kind = Js_exn.raiseError("graphql_ppx: Unknown enum variant for GlobalVariableKind: " + value$17);
      }
    } else {
      field_kind = Js_exn.raiseError("graphql_ppx: Expected enum value for GlobalVariableKind, got " + JSON.stringify(value$16));
    }
  } else {
    field_kind = Js_exn.raiseError("graphql_ppx: Field kind on type GlobalVariable is missing");
  }
  var value$18 = Js_dict.get(value$2, "fallbackToDevValue");
  var field_fallbackToDevValue;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeBoolean(value$19);
    field_fallbackToDevValue = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$19));
  } else {
    field_fallbackToDevValue = Js_exn.raiseError("graphql_ppx: Field fallbackToDevValue on type GlobalVariable is missing");
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          name: field_name,
          value: field_value,
          prodValue: field_prodValue,
          kind: field_kind,
          fallbackToDevValue: field_fallbackToDevValue
        };
}

var name$1 = "GlobalVariableFields";

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var GlobalVariableFields = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  name: name$1,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var GlobalVariableFragment = {
  GlobalVariableFields: GlobalVariableFields
};

var ppx_printed_query$3 = "query GlobalVariablesByApp($appUuid: ID!)  {\n__typename\napp(appUuid: $appUuid)  {\n__typename\n__typename  \nuuid  \nglobalVariables  {\n__typename\n...GlobalVariableFields   \n}\n\n}\n\n}\nfragment GlobalVariableFields on GlobalVariable   {\n__typename\n__typename  \nuuid  \nname  \nvalue  \nprodValue  \nkind  \nfallbackToDevValue  \n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "app");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "__typename");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "uuid");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeString(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$10 = Js_dict.get(value$3, "globalVariables");
    tmp = {
      __typename: tmp$1,
      uuid: tmp$2,
      globalVariables: value$10 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$10))).map(parse$2) : Js_exn.raiseError("graphql_ppx: Field globalVariables on type App is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field app on type Query is missing");
  }
  return {
          app: tmp
        };
}

function make$1(appUuid, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$1(variables) {
  var appUuid = variables.appUuid;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$1(appUuid, param) {
  return Js_dict.fromArray([[
                  "appUuid",
                  appUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, appUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "appUuid",
                      appUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$1
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function refetch$1(appUuid) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make$1(appUuid, undefined));
}

var ListByApp = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  refetch: refetch$1
};

var GQL = {
  UsageFragment: UsageFragment,
  ListUsageByApp: ListUsageByApp,
  GlobalVariableFragment: GlobalVariableFragment,
  ListByApp: ListByApp
};

export {
  toMeta ,
  toTemplateVariable ,
  GQL ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
