// Generated by ReScript, PLEASE EDIT WITH CARE


var iconGridNoResult = (require('../images/icon-grid-no-result.svg').default);

var noApp = (require('../images/no-app.svg').default);

var logo = (require('../images/logo-color.svg').default);

var logoWhite = (require('../images/logo-white.svg').default);

var wordmarkColorBlack = (require('../images/Wordmark-color-black.svg').default);

var wordmarkColorWhite = (require('../images/Wordmark-color-white.svg').default);

var expo = (require('../images/expo.svg').default);

var defaultWorkspaceIcon = (require('../images/default-workspace-icon.svg').default);

var defaultAppIcon = (require('../images/default-app-icon.png'));

var uploadAssetArrow = (require('../images/upload-arrow-dotted.svg').default);

var stepperDivider = (require('../images/stepper-divider.svg').default);

var upgradeConfirmationRocket = (require('images/upgraderocket.svg').default);

var contentfulLogoUrl = (require('images/contentful.svg').default);

var webflowLogoUrl = (require('images/webflow.svg').default);

var wordpressLogoUrl = (require('images/wordpress.svg').default);

var sheetyLogoUrl = (require('images/sheety.svg').default);

var bubbleLogoUrl = (require('images/bubble.svg').default);

var xanoLogoUrl = (require('images/xano.png'));

var backendlessLogoUrl = (require('images/backendless.svg').default);

var airtableLogoUrl = (require('images/airtable.svg').default);

var restApiLogoUrl = (require('images/rest-generic.svg').default);

var squareRestUrl = (require('images/ui-brand-rest-generic.png'));

var info = (require('images/info.svg').default);

var error = (require('images/error.svg').default);

var warning = (require('images/warning.svg').default);

var maintenance = (require('images/maintenance.svg').default);

var googleMapsLogo = (require('../images/google_maps-logo.svg').default);

var segmentLogo = (require('../images/segment-logo.svg').default);

var sentryLogo = (require('../images/sentry-logo.svg').default);

var revenueCatLogo = (require('../images/revenuecat-logo.png'));

var openAiLogo = (require('../images/openai-logo.svg').default);

export {
  iconGridNoResult ,
  noApp ,
  logo ,
  logoWhite ,
  wordmarkColorBlack ,
  wordmarkColorWhite ,
  expo ,
  defaultWorkspaceIcon ,
  defaultAppIcon ,
  uploadAssetArrow ,
  stepperDivider ,
  upgradeConfirmationRocket ,
  contentfulLogoUrl ,
  webflowLogoUrl ,
  wordpressLogoUrl ,
  sheetyLogoUrl ,
  bubbleLogoUrl ,
  xanoLogoUrl ,
  backendlessLogoUrl ,
  airtableLogoUrl ,
  restApiLogoUrl ,
  squareRestUrl ,
  info ,
  error ,
  warning ,
  maintenance ,
  googleMapsLogo ,
  segmentLogo ,
  sentryLogo ,
  revenueCatLogo ,
  openAiLogo ,
  
}
/* iconGridNoResult Not a pure module */
