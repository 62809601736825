import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import * as Sentry from "@sentry/react";
import debounce from "lodash.debounce";
import { resetStorageAndLogout } from "../utilities/Utils.gen";
import { gql } from "graphql.macro";
import { updateServerVersion } from "OutdatedFrontendVersionBanner";

const RECENT_OPERATIONS = [];

/* This is to track recent graphql operations that could have broken the WebPreviewCode. By capturing the operation
 * name + variables we can figure out what went wrong */
function trackRecentOperation(operation) {
  RECENT_OPERATIONS.push({
    query: operation.operationName,
    variables: operation.variables,
    date: new Date(),
  });

  /* Only store a handful of operations. There's no need to store too many */
  if (RECENT_OPERATIONS.length > 4) {
    RECENT_OPERATIONS.splice(0, 1);
  }

  sessionStorage.setItem("recentOperations", JSON.stringify(RECENT_OPERATIONS));
}

export function getRecentOperations() {
  try {
    return sessionStorage.getItem("recentOperations");
  } catch (_) {
    return [];
  }
}

const REBUILD_SCREEN_CODE = gql`
  mutation RebuildScreenCode($screenUuid: ID!) {
    rebuildScreenCode(screenUuid: $screenUuid) {
      __typename
      uuid
      updatedAt
      code
      webPreviewCode
      serializedSnapshot
      snapshotHash
      codeHash
      customBlocksInUse {
        uuid
        name
        slug
      }
      fonts {
        family
        variant
      }
    }
  }
`;

const { REACT_APP_API_URL: API_URL, NODE_ENV, CI } = process.env;
if (!API_URL) throw new Error("No API URL");

function maybeLogError(log) {
  if (NODE_ENV === "development" || CI === "true") {
    // eslint-disable-next-line
    console.error(log);
  }
}

const uri = `${API_URL}/graphql`;

function dataIdFromObject(responseObject) {
  const { __typename } = responseObject;
  if (__typename) {
    if (__typename === "SnackFileStructure") {
      return `${__typename}:${responseObject?.path}`;
    }

    if (__typename === "UpdateScreenPayload") {
      return `${__typename}:${responseObject?.screen?.uuid}`;
    }

    if (__typename === "ScreenFont") {
      return `${__typename}:${responseObject?.family}-${responseObject?.variant}`;
    }

    if (__typename === "GoogleFont") {
      return `${__typename}:${responseObject?.family}`;
    }

    if (__typename === "CustomPackage") {
      return `${__typename}:${responseObject?.package}-${responseObject?.version}`;
    }

    if (responseObject.uuid !== undefined) {
      return `${__typename}:${responseObject.uuid}`;
    }

    if (responseObject.id !== undefined) {
      return `${__typename}:${responseObject.id}`;
    }

    if (responseObject._id !== undefined) {
      return `${__typename}:${responseObject._id}`;
    }
  } else {
    // eslint-disable-next-line
    console.warn(
      `Missing __typename. Fix this by adding __typename to dataIdFromObject fn inside Apollo.js`,
      JSON.stringify(responseObject)
    );
  }

  return null;
}

const cache = new InMemoryCache({
  dataIdFromObject,
  cacheRedirects: {
    Query: {
      component: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "Component" }),
      rebuiltScreens: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "Screen" }),
      styles: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "ComponentStyleObject" }),
      actions: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "Action" }),
      props: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "ComponentProp" }),
      definition: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "ComponentDefinition" }),
      componentDefinition: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "ComponentDefinition" }),
      customCode: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "CustomCode" }),
      restApiEndpoint: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "RestApiEndpoint" }),
      restApiService: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "RestApiService" }),
      screen: (_, { screenUuid }, { getCacheKey }) =>
        getCacheKey({ uuid: screenUuid, __typename: "Screen" }),
      theme: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "Theme" }),
      defaultTheme: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "Theme" }),
      template: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "Template" }),
      workspace: (_, { uuid }, { getCacheKey }) =>
        getCacheKey({ uuid, __typename: "Workspace" }),
      googleFonts: (_, gf, { getCacheKey }) =>
        getCacheKey({ ...gf, __typename: "GoogleFont" }),
    },
  },
});

const request = async operation => {
  const token = localStorage.getItem("token");
  const headers = {};

  if (token && token !== "undefined") {
    headers.authorization = `Bearer ${token}`;
  }

  operation.setContext({
    headers,
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;

      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    })
);

const requestError = onError(
  ({ graphQLErrors, networkError, operation, forward, _response }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        maybeLogError(
          `[GraphQL]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        );
        switch (err.extensions.code) {
          case "TOKEN_EXPIRED":
            const token = err.extensions.refreshedToken;
            if (token) {
              localStorage.setItem("token", token);
              return forward(operation);
            }
            return;
          case "UNAUTHENTICATED": {
            return resetStorageAndLogout({});
          }
          case "PERMISSIONS_ERROR":
            if (err.path[0] === "refreshToken") {
              return resetStorageAndLogout({});
            }
            break;
          case "APP_NOT_FOUND":
          case "FORBIDDEN":
          case "BAD_USER_INPUT":
          case "INVALID_LOGIN":
            // Only report internal server errors -
            // all errors extending ApolloError should be user-facing.
            // Note that checking if an error is instanceof ApolloError is not
            // possible due to TS, nor does the isApolloError function exported
            // by Apollo work. See here for more:
            // https://github.com/apollographql/apollo-client/issues/1194#issuecomment-274925241
            continue;
          default:
            Sentry.withScope(function (scope) {
              scope.setExtra("query", operation.query);
              scope.setExtra("variables", operation.variables);
              scope.setExtra("locations", err.locations);

              if (err.path) {
                scope.addBreadcrumb({
                  category: "query-path",
                  message: err.path.join(" > "),
                  level: "debug",
                });
              }

              Sentry.captureException(err.message);
            });

            Sentry.forceLoad();

            return;
        }
      }
    }

    if (networkError) {
      maybeLogError(`[Network error]: ${networkError}`);
    }
  }
);

const REFETCH_CODE_MUTATIONS = new Set([
  "AddComponent",
  "AddCustomBlock",
  "AddCustomCode",
  "AddCustomFunction",
  "AddGlobalVariable",
  "AddRestApiVariableMapping",
  "AddVariableTransformation",
  "ChangeCustomBlockRoot",
  "CreateAction",
  "CreateActionArgument",
  "CreateAsset",
  "CreateCustomBlock",
  "CreateGlobalVariable",
  "CreateProp",
  "CreateRestApiService",
  "CreateStyleSheet",
  "DeleteAction",
  "DeleteActionArgument",
  "DeleteComponentPermanently",
  "DeleteComponentProp", // TODO deprecated in favor of DeleteProp
  "DeleteComponentPropByPropUuid", // TODO deprecated in favor of DeleteProp
  "DeleteComponentStyle",
  "DeleteComponentStyles",
  "DeleteCustomComponent",
  "DeleteProp",
  "DeleteTheme",
  "DeleteVariableTransformation",
  "DuplicateComponent",
  "DuplicateTheme",
  "ImportActions",
  "MoveAction",
  "MoveComponent",
  "MoveComponentToTrash",
  "RemoveAsset",
  "RemoveCustomCode",
  "RemoveFetchEndpoint",
  "RemoveGlobalVariable",
  "RemoveRestApiVariableMapping",
  "RenameComponentVariable",
  "RenameScreenVariable",
  "ReplaceDeprecatedDefinition",
  "SaveComponentSchema",
  "SetBlockVariableLink",
  "SetComponentCustomFiles",
  "SetComponentStyleSheets",
  "SetComponentVariable",
  "SetComponentVariableTransformations",
  "SetFetchEndpoint",
  "SetResultName",
  "SetScreenLocalState",
  "SetScreenNavigationParam",
  "SetScreenVariable",
  "SwapActionOrder",
  "UnpackCustomBlock",
  "UnsetBlockVariableLink",
  "UnsetComponentRole",
  "UnsetComponentVariable",
  "UnsetResultName",
  "UnsetScreenLocalState",
  "UnsetScreenNavigationParam",
  "UnsetScreenVariable",
  "UpdateAction",
  "UpdateActionArgument",
  "UpdateActionsOrder",
  "UpdateApp",
  "UpdateAppDefaultTheme",
  "UpdateColor",
  "UpdateComponent",
  "UpdateComponentStyle",
  "UpdateCustomCode",
  "UpdateCustomComponentDefinitionVersion",
  "UpdateCustomFunction",
  "UpdateGlobalVariable",
  "UpdateGlobalVariable",
  "UpdateProp",
  "UpdatePropByName",
  "UpdateScreen",
  "UpdateScreenTheme",
  "UpdateTheme",
]);

const getScreenUuid = pathname => {
  const screenUuid = pathname.split("screens").pop();
  if (!screenUuid.includes("/")) {
    return screenUuid;
  }

  return screenUuid.split("/")[1];
};

const isInNavigateMode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  return params["m"] === "Navigate";
};

const rebuildScreenCode = client => {
  const screenUuid = getScreenUuid(window.location.pathname);
  // Only rebuild the screen code if we get a valid screen ID from the pathname
  // AND not in Navigate mode, since navigation does a bunch of these same queries
  // but on navigator level, not screen level so we don't need to rebuild screen
  if (screenUuid.length === 8 && !isInNavigateMode()) {
    client.mutate({
      mutation: REBUILD_SCREEN_CODE,
      variables: { screenUuid },
    });
  }
};

const rebuildScreenCodeDebounce = debounce(rebuildScreenCode, 100);

class Client extends ApolloClient {
  constructor() {
    const httpLink = new HttpLink({
      uri,
      credentials: "same-origin",
    });

    super({
      link: ApolloLink.from([
        requestError,
        requestLink,
        new ApolloLink((operation, forward) => {
          return forward(operation).map(response => {
            const context = operation.getContext();
            const {
              response: { headers },
            } = context;

            if (headers) {
              const serverVersion = headers.get("X-Draftbit-Server-Version");
              updateServerVersion(serverVersion ?? undefined);
            }

            return response;
          });
        }),

        new ApolloLink((operation, forward) => {
          return forward(operation).map(response => {
            trackRecentOperation(operation);

            if (REFETCH_CODE_MUTATIONS.has(operation.operationName)) {
              if (response.data) {
                rebuildScreenCodeDebounce(this);
              }
            }

            return response;
          });
        }),
        httpLink,
      ]),
      cache,
    });
  }
}

export default Client;
