// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./utilities/PromiseUtils.bs.js";

var _map = {"FREE":"FREE","BASIC":"BASIC","INDIVIDUAL":"INDIVIDUAL","PRO":"PRO","FREELANCER":"FREELANCER","TEAM":"TEAM","AGENCY":"AGENCY","ENTERPRISE":"ENTERPRISE","EXPERTS":"EXPERTS","PRO_CODE":"PRO_CODE","LOW_CODE":"LOW_CODE","NO_CODE":"NO_CODE","STARTER":"STARTER","LEGACY":"LEGACY","SUPPORT":"SUPPORT"};

function levelToJs(param) {
  return param;
}

function levelFromJs(param) {
  return _map[param];
}

var levels = [
  "FREE",
  "BASIC",
  "INDIVIDUAL",
  "PRO",
  "FREELANCER",
  "TEAM",
  "AGENCY",
  "ENTERPRISE",
  "EXPERTS",
  "PRO_CODE",
  "LOW_CODE",
  "NO_CODE",
  "STARTER",
  "LEGACY",
  "SUPPORT"
];

var _map$1 = {"PLATFORM":"PLATFORM","EXPERTS":"EXPERTS","SUPPORT":"SUPPORT"};

function kindToJs(param) {
  return param;
}

function kindFromJs(param) {
  return _map$1[param];
}

var kinds = [
  "PLATFORM",
  "EXPERTS",
  "SUPPORT"
];

function level_encode(l) {
  return l;
}

function level_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("BillingPlan.level", levelFromJs, param);
}

function kind_encode(k) {
  return k;
}

function kind_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("BillingPlan.kind", kindFromJs, param);
}

function simpleBillingPlan_encode(v) {
  return Js_dict.fromArray([
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "name",
                Decco.stringToJson(v.name)
              ],
              [
                "level",
                v.level
              ],
              [
                "kind",
                v.kind
              ],
              [
                "stripePlanId",
                Decco.stringToJson(v.stripePlanId)
              ],
              [
                "supportsCodeExport",
                Decco.boolToJson(v.supportsCodeExport)
              ],
              [
                "supportsScreenCode",
                Decco.boolToJson(v.supportsScreenCode)
              ],
              [
                "supportsCustomCode",
                Decco.boolToJson(v.supportsCustomCode)
              ],
              [
                "supportsLiveCollaboration",
                Decco.boolToJson(v.supportsLiveCollaboration)
              ],
              [
                "maximumCollaboratorsAllowed",
                Decco.intToJson(v.maximumCollaboratorsAllowed)
              ],
              [
                "maximumAppsAllowed",
                Decco.intToJson(v.maximumAppsAllowed)
              ],
              [
                "maximumAppBackupsAllowed",
                Decco.intToJson(v.maximumAppBackupsAllowed)
              ],
              [
                "maximumWorkspacesAllowed",
                Decco.intToJson(v.maximumWorkspacesAllowed)
              ],
              [
                "deletedScreenHistoryDays",
                Decco.optionToJson(Decco.intToJson, v.deletedScreenHistoryDays)
              ],
              [
                "deletedComponentHistoryDays",
                Decco.optionToJson(Decco.intToJson, v.deletedComponentHistoryDays)
              ],
              [
                "maximumLiveAppsAllowed",
                Decco.intToJson(v.maximumLiveAppsAllowed)
              ],
              [
                "maximumScreensPerApp",
                Decco.intToJson(v.maximumScreensPerApp)
              ],
              [
                "supportsCustomPackages",
                Decco.boolToJson(v.supportsCustomPackages)
              ],
              [
                "supportsGithubCommit",
                Decco.boolToJson(v.supportsGithubCommit)
              ],
              [
                "supportsSplashScreenChanges",
                Decco.boolToJson(v.supportsSplashScreenChanges)
              ],
              [
                "supportsAppetizePreviews",
                Decco.boolToJson(v.supportsAppetizePreviews)
              ],
              [
                "supportsPwaPublishing",
                Decco.boolToJson(v.supportsPwaPublishing)
              ],
              [
                "supportsIosPublishing",
                Decco.boolToJson(v.supportsIosPublishing)
              ],
              [
                "supportsAndroidPublishing",
                Decco.boolToJson(v.supportsAndroidPublishing)
              ],
              [
                "supportsSharePage",
                Decco.boolToJson(v.supportsSharePage)
              ],
              [
                "supportsCustomOpenAiApiKey",
                Decco.boolToJson(v.supportsCustomOpenAiApiKey)
              ],
              [
                "maximumChangeStateToLivePerMonth",
                Decco.intToJson(v.maximumChangeStateToLivePerMonth)
              ],
              [
                "aiImageGenerationPerMonth",
                Decco.intToJson(v.aiImageGenerationPerMonth)
              ],
              [
                "aiCodeGenerationPerMonth",
                Decco.intToJson(v.aiCodeGenerationPerMonth)
              ],
              [
                "aiScreenCreationPerMonth",
                Decco.intToJson(v.aiScreenCreationPerMonth)
              ]
            ]);
}

function simpleBillingPlan_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
  if (uuid.TAG === /* Ok */0) {
    var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
    if (name.TAG === /* Ok */0) {
      var level = JsonUtils$DraftbitBuilder.makeStringLikeDecode("BillingPlan.level", levelFromJs, Belt_Option.getWithDefault(Js_dict.get(dict$1, "level"), null));
      if (level.TAG === /* Ok */0) {
        var kind = JsonUtils$DraftbitBuilder.makeStringLikeDecode("BillingPlan.kind", kindFromJs, Belt_Option.getWithDefault(Js_dict.get(dict$1, "kind"), null));
        if (kind.TAG === /* Ok */0) {
          var stripePlanId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "stripePlanId"), null));
          if (stripePlanId.TAG === /* Ok */0) {
            var supportsCodeExport = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsCodeExport"), null));
            if (supportsCodeExport.TAG === /* Ok */0) {
              var supportsScreenCode = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsScreenCode"), null));
              if (supportsScreenCode.TAG === /* Ok */0) {
                var supportsCustomCode = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsCustomCode"), null));
                if (supportsCustomCode.TAG === /* Ok */0) {
                  var supportsLiveCollaboration = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsLiveCollaboration"), null));
                  if (supportsLiveCollaboration.TAG === /* Ok */0) {
                    var maximumCollaboratorsAllowed = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maximumCollaboratorsAllowed"), null));
                    if (maximumCollaboratorsAllowed.TAG === /* Ok */0) {
                      var maximumAppsAllowed = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maximumAppsAllowed"), null));
                      if (maximumAppsAllowed.TAG === /* Ok */0) {
                        var maximumAppBackupsAllowed = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maximumAppBackupsAllowed"), null));
                        if (maximumAppBackupsAllowed.TAG === /* Ok */0) {
                          var maximumWorkspacesAllowed = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maximumWorkspacesAllowed"), null));
                          if (maximumWorkspacesAllowed.TAG === /* Ok */0) {
                            var deletedScreenHistoryDays = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deletedScreenHistoryDays"), null));
                            if (deletedScreenHistoryDays.TAG === /* Ok */0) {
                              var deletedComponentHistoryDays = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deletedComponentHistoryDays"), null));
                              if (deletedComponentHistoryDays.TAG === /* Ok */0) {
                                var maximumLiveAppsAllowed = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maximumLiveAppsAllowed"), null));
                                if (maximumLiveAppsAllowed.TAG === /* Ok */0) {
                                  var maximumScreensPerApp = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maximumScreensPerApp"), null));
                                  if (maximumScreensPerApp.TAG === /* Ok */0) {
                                    var supportsCustomPackages = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsCustomPackages"), null));
                                    if (supportsCustomPackages.TAG === /* Ok */0) {
                                      var supportsGithubCommit = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsGithubCommit"), null));
                                      if (supportsGithubCommit.TAG === /* Ok */0) {
                                        var supportsSplashScreenChanges = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsSplashScreenChanges"), null));
                                        if (supportsSplashScreenChanges.TAG === /* Ok */0) {
                                          var supportsAppetizePreviews = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsAppetizePreviews"), null));
                                          if (supportsAppetizePreviews.TAG === /* Ok */0) {
                                            var supportsPwaPublishing = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsPwaPublishing"), null));
                                            if (supportsPwaPublishing.TAG === /* Ok */0) {
                                              var supportsIosPublishing = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsIosPublishing"), null));
                                              if (supportsIosPublishing.TAG === /* Ok */0) {
                                                var supportsAndroidPublishing = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsAndroidPublishing"), null));
                                                if (supportsAndroidPublishing.TAG === /* Ok */0) {
                                                  var supportsSharePage = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsSharePage"), null));
                                                  if (supportsSharePage.TAG === /* Ok */0) {
                                                    var supportsCustomOpenAiApiKey = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "supportsCustomOpenAiApiKey"), null));
                                                    if (supportsCustomOpenAiApiKey.TAG === /* Ok */0) {
                                                      var maximumChangeStateToLivePerMonth = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maximumChangeStateToLivePerMonth"), null));
                                                      if (maximumChangeStateToLivePerMonth.TAG === /* Ok */0) {
                                                        var aiImageGenerationPerMonth = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "aiImageGenerationPerMonth"), null));
                                                        if (aiImageGenerationPerMonth.TAG === /* Ok */0) {
                                                          var aiCodeGenerationPerMonth = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "aiCodeGenerationPerMonth"), null));
                                                          if (aiCodeGenerationPerMonth.TAG === /* Ok */0) {
                                                            var aiScreenCreationPerMonth = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "aiScreenCreationPerMonth"), null));
                                                            if (aiScreenCreationPerMonth.TAG === /* Ok */0) {
                                                              return {
                                                                      TAG: /* Ok */0,
                                                                      _0: {
                                                                        uuid: uuid._0,
                                                                        name: name._0,
                                                                        level: level._0,
                                                                        kind: kind._0,
                                                                        stripePlanId: stripePlanId._0,
                                                                        supportsCodeExport: supportsCodeExport._0,
                                                                        supportsScreenCode: supportsScreenCode._0,
                                                                        supportsCustomCode: supportsCustomCode._0,
                                                                        supportsLiveCollaboration: supportsLiveCollaboration._0,
                                                                        maximumCollaboratorsAllowed: maximumCollaboratorsAllowed._0,
                                                                        maximumAppsAllowed: maximumAppsAllowed._0,
                                                                        maximumAppBackupsAllowed: maximumAppBackupsAllowed._0,
                                                                        maximumWorkspacesAllowed: maximumWorkspacesAllowed._0,
                                                                        deletedScreenHistoryDays: deletedScreenHistoryDays._0,
                                                                        deletedComponentHistoryDays: deletedComponentHistoryDays._0,
                                                                        maximumLiveAppsAllowed: maximumLiveAppsAllowed._0,
                                                                        maximumScreensPerApp: maximumScreensPerApp._0,
                                                                        supportsCustomPackages: supportsCustomPackages._0,
                                                                        supportsGithubCommit: supportsGithubCommit._0,
                                                                        supportsSplashScreenChanges: supportsSplashScreenChanges._0,
                                                                        supportsAppetizePreviews: supportsAppetizePreviews._0,
                                                                        supportsPwaPublishing: supportsPwaPublishing._0,
                                                                        supportsIosPublishing: supportsIosPublishing._0,
                                                                        supportsAndroidPublishing: supportsAndroidPublishing._0,
                                                                        supportsSharePage: supportsSharePage._0,
                                                                        supportsCustomOpenAiApiKey: supportsCustomOpenAiApiKey._0,
                                                                        maximumChangeStateToLivePerMonth: maximumChangeStateToLivePerMonth._0,
                                                                        aiImageGenerationPerMonth: aiImageGenerationPerMonth._0,
                                                                        aiCodeGenerationPerMonth: aiCodeGenerationPerMonth._0,
                                                                        aiScreenCreationPerMonth: aiScreenCreationPerMonth._0
                                                                      }
                                                                    };
                                                            }
                                                            var e = aiScreenCreationPerMonth._0;
                                                            return {
                                                                    TAG: /* Error */1,
                                                                    _0: {
                                                                      path: ".aiScreenCreationPerMonth" + e.path,
                                                                      message: e.message,
                                                                      value: e.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$1 = aiCodeGenerationPerMonth._0;
                                                          return {
                                                                  TAG: /* Error */1,
                                                                  _0: {
                                                                    path: ".aiCodeGenerationPerMonth" + e$1.path,
                                                                    message: e$1.message,
                                                                    value: e$1.value
                                                                  }
                                                                };
                                                        }
                                                        var e$2 = aiImageGenerationPerMonth._0;
                                                        return {
                                                                TAG: /* Error */1,
                                                                _0: {
                                                                  path: ".aiImageGenerationPerMonth" + e$2.path,
                                                                  message: e$2.message,
                                                                  value: e$2.value
                                                                }
                                                              };
                                                      }
                                                      var e$3 = maximumChangeStateToLivePerMonth._0;
                                                      return {
                                                              TAG: /* Error */1,
                                                              _0: {
                                                                path: ".maximumChangeStateToLivePerMonth" + e$3.path,
                                                                message: e$3.message,
                                                                value: e$3.value
                                                              }
                                                            };
                                                    }
                                                    var e$4 = supportsCustomOpenAiApiKey._0;
                                                    return {
                                                            TAG: /* Error */1,
                                                            _0: {
                                                              path: ".supportsCustomOpenAiApiKey" + e$4.path,
                                                              message: e$4.message,
                                                              value: e$4.value
                                                            }
                                                          };
                                                  }
                                                  var e$5 = supportsSharePage._0;
                                                  return {
                                                          TAG: /* Error */1,
                                                          _0: {
                                                            path: ".supportsSharePage" + e$5.path,
                                                            message: e$5.message,
                                                            value: e$5.value
                                                          }
                                                        };
                                                }
                                                var e$6 = supportsAndroidPublishing._0;
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: {
                                                          path: ".supportsAndroidPublishing" + e$6.path,
                                                          message: e$6.message,
                                                          value: e$6.value
                                                        }
                                                      };
                                              }
                                              var e$7 = supportsIosPublishing._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".supportsIosPublishing" + e$7.path,
                                                        message: e$7.message,
                                                        value: e$7.value
                                                      }
                                                    };
                                            }
                                            var e$8 = supportsPwaPublishing._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".supportsPwaPublishing" + e$8.path,
                                                      message: e$8.message,
                                                      value: e$8.value
                                                    }
                                                  };
                                          }
                                          var e$9 = supportsAppetizePreviews._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".supportsAppetizePreviews" + e$9.path,
                                                    message: e$9.message,
                                                    value: e$9.value
                                                  }
                                                };
                                        }
                                        var e$10 = supportsSplashScreenChanges._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".supportsSplashScreenChanges" + e$10.path,
                                                  message: e$10.message,
                                                  value: e$10.value
                                                }
                                              };
                                      }
                                      var e$11 = supportsGithubCommit._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".supportsGithubCommit" + e$11.path,
                                                message: e$11.message,
                                                value: e$11.value
                                              }
                                            };
                                    }
                                    var e$12 = supportsCustomPackages._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".supportsCustomPackages" + e$12.path,
                                              message: e$12.message,
                                              value: e$12.value
                                            }
                                          };
                                  }
                                  var e$13 = maximumScreensPerApp._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".maximumScreensPerApp" + e$13.path,
                                            message: e$13.message,
                                            value: e$13.value
                                          }
                                        };
                                }
                                var e$14 = maximumLiveAppsAllowed._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".maximumLiveAppsAllowed" + e$14.path,
                                          message: e$14.message,
                                          value: e$14.value
                                        }
                                      };
                              }
                              var e$15 = deletedComponentHistoryDays._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".deletedComponentHistoryDays" + e$15.path,
                                        message: e$15.message,
                                        value: e$15.value
                                      }
                                    };
                            }
                            var e$16 = deletedScreenHistoryDays._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".deletedScreenHistoryDays" + e$16.path,
                                      message: e$16.message,
                                      value: e$16.value
                                    }
                                  };
                          }
                          var e$17 = maximumWorkspacesAllowed._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".maximumWorkspacesAllowed" + e$17.path,
                                    message: e$17.message,
                                    value: e$17.value
                                  }
                                };
                        }
                        var e$18 = maximumAppBackupsAllowed._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".maximumAppBackupsAllowed" + e$18.path,
                                  message: e$18.message,
                                  value: e$18.value
                                }
                              };
                      }
                      var e$19 = maximumAppsAllowed._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".maximumAppsAllowed" + e$19.path,
                                message: e$19.message,
                                value: e$19.value
                              }
                            };
                    }
                    var e$20 = maximumCollaboratorsAllowed._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".maximumCollaboratorsAllowed" + e$20.path,
                              message: e$20.message,
                              value: e$20.value
                            }
                          };
                  }
                  var e$21 = supportsLiveCollaboration._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".supportsLiveCollaboration" + e$21.path,
                            message: e$21.message,
                            value: e$21.value
                          }
                        };
                }
                var e$22 = supportsCustomCode._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".supportsCustomCode" + e$22.path,
                          message: e$22.message,
                          value: e$22.value
                        }
                      };
              }
              var e$23 = supportsScreenCode._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".supportsScreenCode" + e$23.path,
                        message: e$23.message,
                        value: e$23.value
                      }
                    };
            }
            var e$24 = supportsCodeExport._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".supportsCodeExport" + e$24.path,
                      message: e$24.message,
                      value: e$24.value
                    }
                  };
          }
          var e$25 = stripePlanId._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".stripePlanId" + e$25.path,
                    message: e$25.message,
                    value: e$25.value
                  }
                };
        }
        var e$26 = kind._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".kind" + e$26.path,
                  message: e$26.message,
                  value: e$26.value
                }
              };
      }
      var e$27 = level._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".level" + e$27.path,
                message: e$27.message,
                value: e$27.value
              }
            };
    }
    var e$28 = name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".name" + e$28.path,
              message: e$28.message,
              value: e$28.value
            }
          };
  }
  var e$29 = uuid._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".uuid" + e$29.path,
            message: e$29.message,
            value: e$29.value
          }
        };
}

var ppx_printed_query = "fragment SimpleBillingPlanFields on BillingPlan   {\n__typename\nuuid  \nname  \nlevel  \nkind  \nstripePlanId  \nsupportsCodeExport  \nsupportsScreenCode  \nsupportsCustomCode  \nsupportsLiveCollaboration  \nmaximumCollaboratorsAllowed  \nmaximumAppsAllowed  \nmaximumAppBackupsAllowed  \nmaximumWorkspacesAllowed  \ndeletedScreenHistoryDays  \ndeletedComponentHistoryDays  \nmaximumLiveAppsAllowed  \nmaximumScreensPerApp  \nsupportsCustomPackages  \nsupportsGithubCommit  \nsupportsSplashScreenChanges  \nsupportsAppetizePreviews  \nsupportsPwaPublishing  \nsupportsIosPublishing  \nsupportsAndroidPublishing  \nsupportsSharePage  \nsupportsCustomOpenAiApiKey  \nmaximumChangeStateToLivePerMonth  \naiImageGenerationPerMonth  \naiCodeGenerationPerMonth  \naiScreenCreationPerMonth  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type BillingPlan, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type BillingPlan is missing");
  }
  var value$6 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_name = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type BillingPlan is missing");
  }
  var value$9 = Js_dict.get(value$2, "level");
  var field_level;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    if (value$11 !== undefined) {
      switch (value$11) {
        case "AGENCY" :
            field_level = "AGENCY";
            break;
        case "BASIC" :
            field_level = "BASIC";
            break;
        case "ENTERPRISE" :
            field_level = "ENTERPRISE";
            break;
        case "EXPERTS" :
            field_level = "EXPERTS";
            break;
        case "FREE" :
            field_level = "FREE";
            break;
        case "FREELANCER" :
            field_level = "FREELANCER";
            break;
        case "INDIVIDUAL" :
            field_level = "INDIVIDUAL";
            break;
        case "LEGACY" :
            field_level = "LEGACY";
            break;
        case "LOW_CODE" :
            field_level = "LOW_CODE";
            break;
        case "NO_CODE" :
            field_level = "NO_CODE";
            break;
        case "PRO" :
            field_level = "PRO";
            break;
        case "PRO_CODE" :
            field_level = "PRO_CODE";
            break;
        case "STARTER" :
            field_level = "STARTER";
            break;
        case "SUPPORT" :
            field_level = "SUPPORT";
            break;
        case "TEAM" :
            field_level = "TEAM";
            break;
        default:
          field_level = Js_exn.raiseError("graphql_ppx: Unknown enum variant for BillingPlanLevel: " + value$11);
      }
    } else {
      field_level = Js_exn.raiseError("graphql_ppx: Expected enum value for BillingPlanLevel, got " + JSON.stringify(value$10));
    }
  } else {
    field_level = Js_exn.raiseError("graphql_ppx: Field level on type BillingPlan is missing");
  }
  var value$12 = Js_dict.get(value$2, "kind");
  var field_kind;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    if (value$14 !== undefined) {
      switch (value$14) {
        case "EXPERTS" :
            field_kind = "EXPERTS";
            break;
        case "PLATFORM" :
            field_kind = "PLATFORM";
            break;
        case "SUPPORT" :
            field_kind = "SUPPORT";
            break;
        default:
          field_kind = Js_exn.raiseError("graphql_ppx: Unknown enum variant for BillingPlanKind: " + value$14);
      }
    } else {
      field_kind = Js_exn.raiseError("graphql_ppx: Expected enum value for BillingPlanKind, got " + JSON.stringify(value$13));
    }
  } else {
    field_kind = Js_exn.raiseError("graphql_ppx: Field kind on type BillingPlan is missing");
  }
  var value$15 = Js_dict.get(value$2, "stripePlanId");
  var field_stripePlanId;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeString(value$16);
    field_stripePlanId = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
  } else {
    field_stripePlanId = Js_exn.raiseError("graphql_ppx: Field stripePlanId on type BillingPlan is missing");
  }
  var value$18 = Js_dict.get(value$2, "supportsCodeExport");
  var field_supportsCodeExport;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeBoolean(value$19);
    field_supportsCodeExport = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$19));
  } else {
    field_supportsCodeExport = Js_exn.raiseError("graphql_ppx: Field supportsCodeExport on type BillingPlan is missing");
  }
  var value$21 = Js_dict.get(value$2, "supportsScreenCode");
  var field_supportsScreenCode;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var value$23 = Js_json.decodeBoolean(value$22);
    field_supportsScreenCode = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$22));
  } else {
    field_supportsScreenCode = Js_exn.raiseError("graphql_ppx: Field supportsScreenCode on type BillingPlan is missing");
  }
  var value$24 = Js_dict.get(value$2, "supportsCustomCode");
  var field_supportsCustomCode;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var value$26 = Js_json.decodeBoolean(value$25);
    field_supportsCustomCode = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$25));
  } else {
    field_supportsCustomCode = Js_exn.raiseError("graphql_ppx: Field supportsCustomCode on type BillingPlan is missing");
  }
  var value$27 = Js_dict.get(value$2, "supportsLiveCollaboration");
  var field_supportsLiveCollaboration;
  if (value$27 !== undefined) {
    var value$28 = Caml_option.valFromOption(value$27);
    var value$29 = Js_json.decodeBoolean(value$28);
    field_supportsLiveCollaboration = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$28));
  } else {
    field_supportsLiveCollaboration = Js_exn.raiseError("graphql_ppx: Field supportsLiveCollaboration on type BillingPlan is missing");
  }
  var value$30 = Js_dict.get(value$2, "maximumCollaboratorsAllowed");
  var field_maximumCollaboratorsAllowed;
  if (value$30 !== undefined) {
    var value$31 = Caml_option.valFromOption(value$30);
    var value$32 = Js_json.decodeNumber(value$31);
    field_maximumCollaboratorsAllowed = value$32 !== undefined ? value$32 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$31));
  } else {
    field_maximumCollaboratorsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumCollaboratorsAllowed on type BillingPlan is missing");
  }
  var value$33 = Js_dict.get(value$2, "maximumAppsAllowed");
  var field_maximumAppsAllowed;
  if (value$33 !== undefined) {
    var value$34 = Caml_option.valFromOption(value$33);
    var value$35 = Js_json.decodeNumber(value$34);
    field_maximumAppsAllowed = value$35 !== undefined ? value$35 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$34));
  } else {
    field_maximumAppsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumAppsAllowed on type BillingPlan is missing");
  }
  var value$36 = Js_dict.get(value$2, "maximumAppBackupsAllowed");
  var field_maximumAppBackupsAllowed;
  if (value$36 !== undefined) {
    var value$37 = Caml_option.valFromOption(value$36);
    var value$38 = Js_json.decodeNumber(value$37);
    field_maximumAppBackupsAllowed = value$38 !== undefined ? value$38 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$37));
  } else {
    field_maximumAppBackupsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumAppBackupsAllowed on type BillingPlan is missing");
  }
  var value$39 = Js_dict.get(value$2, "maximumWorkspacesAllowed");
  var field_maximumWorkspacesAllowed;
  if (value$39 !== undefined) {
    var value$40 = Caml_option.valFromOption(value$39);
    var value$41 = Js_json.decodeNumber(value$40);
    field_maximumWorkspacesAllowed = value$41 !== undefined ? value$41 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$40));
  } else {
    field_maximumWorkspacesAllowed = Js_exn.raiseError("graphql_ppx: Field maximumWorkspacesAllowed on type BillingPlan is missing");
  }
  var value$42 = Js_dict.get(value$2, "deletedScreenHistoryDays");
  var field_deletedScreenHistoryDays;
  if (value$42 !== undefined) {
    var value$43 = Caml_option.valFromOption(value$42);
    var match = Js_json.decodeNull(value$43);
    if (match !== undefined) {
      field_deletedScreenHistoryDays = undefined;
    } else {
      var value$44 = Js_json.decodeNumber(value$43);
      field_deletedScreenHistoryDays = value$44 !== undefined ? value$44 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$43));
    }
  } else {
    field_deletedScreenHistoryDays = undefined;
  }
  var value$45 = Js_dict.get(value$2, "deletedComponentHistoryDays");
  var field_deletedComponentHistoryDays;
  if (value$45 !== undefined) {
    var value$46 = Caml_option.valFromOption(value$45);
    var match$1 = Js_json.decodeNull(value$46);
    if (match$1 !== undefined) {
      field_deletedComponentHistoryDays = undefined;
    } else {
      var value$47 = Js_json.decodeNumber(value$46);
      field_deletedComponentHistoryDays = value$47 !== undefined ? value$47 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$46));
    }
  } else {
    field_deletedComponentHistoryDays = undefined;
  }
  var value$48 = Js_dict.get(value$2, "maximumLiveAppsAllowed");
  var field_maximumLiveAppsAllowed;
  if (value$48 !== undefined) {
    var value$49 = Caml_option.valFromOption(value$48);
    var value$50 = Js_json.decodeNumber(value$49);
    field_maximumLiveAppsAllowed = value$50 !== undefined ? value$50 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$49));
  } else {
    field_maximumLiveAppsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumLiveAppsAllowed on type BillingPlan is missing");
  }
  var value$51 = Js_dict.get(value$2, "maximumScreensPerApp");
  var field_maximumScreensPerApp;
  if (value$51 !== undefined) {
    var value$52 = Caml_option.valFromOption(value$51);
    var value$53 = Js_json.decodeNumber(value$52);
    field_maximumScreensPerApp = value$53 !== undefined ? value$53 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$52));
  } else {
    field_maximumScreensPerApp = Js_exn.raiseError("graphql_ppx: Field maximumScreensPerApp on type BillingPlan is missing");
  }
  var value$54 = Js_dict.get(value$2, "supportsCustomPackages");
  var field_supportsCustomPackages;
  if (value$54 !== undefined) {
    var value$55 = Caml_option.valFromOption(value$54);
    var value$56 = Js_json.decodeBoolean(value$55);
    field_supportsCustomPackages = value$56 !== undefined ? value$56 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$55));
  } else {
    field_supportsCustomPackages = Js_exn.raiseError("graphql_ppx: Field supportsCustomPackages on type BillingPlan is missing");
  }
  var value$57 = Js_dict.get(value$2, "supportsGithubCommit");
  var field_supportsGithubCommit;
  if (value$57 !== undefined) {
    var value$58 = Caml_option.valFromOption(value$57);
    var value$59 = Js_json.decodeBoolean(value$58);
    field_supportsGithubCommit = value$59 !== undefined ? value$59 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$58));
  } else {
    field_supportsGithubCommit = Js_exn.raiseError("graphql_ppx: Field supportsGithubCommit on type BillingPlan is missing");
  }
  var value$60 = Js_dict.get(value$2, "supportsSplashScreenChanges");
  var field_supportsSplashScreenChanges;
  if (value$60 !== undefined) {
    var value$61 = Caml_option.valFromOption(value$60);
    var value$62 = Js_json.decodeBoolean(value$61);
    field_supportsSplashScreenChanges = value$62 !== undefined ? value$62 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$61));
  } else {
    field_supportsSplashScreenChanges = Js_exn.raiseError("graphql_ppx: Field supportsSplashScreenChanges on type BillingPlan is missing");
  }
  var value$63 = Js_dict.get(value$2, "supportsAppetizePreviews");
  var field_supportsAppetizePreviews;
  if (value$63 !== undefined) {
    var value$64 = Caml_option.valFromOption(value$63);
    var value$65 = Js_json.decodeBoolean(value$64);
    field_supportsAppetizePreviews = value$65 !== undefined ? value$65 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$64));
  } else {
    field_supportsAppetizePreviews = Js_exn.raiseError("graphql_ppx: Field supportsAppetizePreviews on type BillingPlan is missing");
  }
  var value$66 = Js_dict.get(value$2, "supportsPwaPublishing");
  var field_supportsPwaPublishing;
  if (value$66 !== undefined) {
    var value$67 = Caml_option.valFromOption(value$66);
    var value$68 = Js_json.decodeBoolean(value$67);
    field_supportsPwaPublishing = value$68 !== undefined ? value$68 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$67));
  } else {
    field_supportsPwaPublishing = Js_exn.raiseError("graphql_ppx: Field supportsPwaPublishing on type BillingPlan is missing");
  }
  var value$69 = Js_dict.get(value$2, "supportsIosPublishing");
  var field_supportsIosPublishing;
  if (value$69 !== undefined) {
    var value$70 = Caml_option.valFromOption(value$69);
    var value$71 = Js_json.decodeBoolean(value$70);
    field_supportsIosPublishing = value$71 !== undefined ? value$71 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$70));
  } else {
    field_supportsIosPublishing = Js_exn.raiseError("graphql_ppx: Field supportsIosPublishing on type BillingPlan is missing");
  }
  var value$72 = Js_dict.get(value$2, "supportsAndroidPublishing");
  var field_supportsAndroidPublishing;
  if (value$72 !== undefined) {
    var value$73 = Caml_option.valFromOption(value$72);
    var value$74 = Js_json.decodeBoolean(value$73);
    field_supportsAndroidPublishing = value$74 !== undefined ? value$74 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$73));
  } else {
    field_supportsAndroidPublishing = Js_exn.raiseError("graphql_ppx: Field supportsAndroidPublishing on type BillingPlan is missing");
  }
  var value$75 = Js_dict.get(value$2, "supportsSharePage");
  var field_supportsSharePage;
  if (value$75 !== undefined) {
    var value$76 = Caml_option.valFromOption(value$75);
    var value$77 = Js_json.decodeBoolean(value$76);
    field_supportsSharePage = value$77 !== undefined ? value$77 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$76));
  } else {
    field_supportsSharePage = Js_exn.raiseError("graphql_ppx: Field supportsSharePage on type BillingPlan is missing");
  }
  var value$78 = Js_dict.get(value$2, "supportsCustomOpenAiApiKey");
  var field_supportsCustomOpenAiApiKey;
  if (value$78 !== undefined) {
    var value$79 = Caml_option.valFromOption(value$78);
    var value$80 = Js_json.decodeBoolean(value$79);
    field_supportsCustomOpenAiApiKey = value$80 !== undefined ? value$80 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$79));
  } else {
    field_supportsCustomOpenAiApiKey = Js_exn.raiseError("graphql_ppx: Field supportsCustomOpenAiApiKey on type BillingPlan is missing");
  }
  var value$81 = Js_dict.get(value$2, "maximumChangeStateToLivePerMonth");
  var field_maximumChangeStateToLivePerMonth;
  if (value$81 !== undefined) {
    var value$82 = Caml_option.valFromOption(value$81);
    var value$83 = Js_json.decodeNumber(value$82);
    field_maximumChangeStateToLivePerMonth = value$83 !== undefined ? value$83 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$82));
  } else {
    field_maximumChangeStateToLivePerMonth = Js_exn.raiseError("graphql_ppx: Field maximumChangeStateToLivePerMonth on type BillingPlan is missing");
  }
  var value$84 = Js_dict.get(value$2, "aiImageGenerationPerMonth");
  var field_aiImageGenerationPerMonth;
  if (value$84 !== undefined) {
    var value$85 = Caml_option.valFromOption(value$84);
    var value$86 = Js_json.decodeNumber(value$85);
    field_aiImageGenerationPerMonth = value$86 !== undefined ? value$86 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$85));
  } else {
    field_aiImageGenerationPerMonth = Js_exn.raiseError("graphql_ppx: Field aiImageGenerationPerMonth on type BillingPlan is missing");
  }
  var value$87 = Js_dict.get(value$2, "aiCodeGenerationPerMonth");
  var field_aiCodeGenerationPerMonth;
  if (value$87 !== undefined) {
    var value$88 = Caml_option.valFromOption(value$87);
    var value$89 = Js_json.decodeNumber(value$88);
    field_aiCodeGenerationPerMonth = value$89 !== undefined ? value$89 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$88));
  } else {
    field_aiCodeGenerationPerMonth = Js_exn.raiseError("graphql_ppx: Field aiCodeGenerationPerMonth on type BillingPlan is missing");
  }
  var value$90 = Js_dict.get(value$2, "aiScreenCreationPerMonth");
  var field_aiScreenCreationPerMonth;
  if (value$90 !== undefined) {
    var value$91 = Caml_option.valFromOption(value$90);
    var value$92 = Js_json.decodeNumber(value$91);
    field_aiScreenCreationPerMonth = value$92 !== undefined ? value$92 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$91));
  } else {
    field_aiScreenCreationPerMonth = Js_exn.raiseError("graphql_ppx: Field aiScreenCreationPerMonth on type BillingPlan is missing");
  }
  return {
          uuid: field_uuid,
          name: field_name,
          level: field_level,
          kind: field_kind,
          stripePlanId: field_stripePlanId,
          supportsCodeExport: field_supportsCodeExport,
          supportsScreenCode: field_supportsScreenCode,
          supportsCustomCode: field_supportsCustomCode,
          supportsLiveCollaboration: field_supportsLiveCollaboration,
          maximumCollaboratorsAllowed: field_maximumCollaboratorsAllowed,
          maximumAppsAllowed: field_maximumAppsAllowed,
          maximumAppBackupsAllowed: field_maximumAppBackupsAllowed,
          maximumWorkspacesAllowed: field_maximumWorkspacesAllowed,
          deletedScreenHistoryDays: field_deletedScreenHistoryDays,
          deletedComponentHistoryDays: field_deletedComponentHistoryDays,
          maximumLiveAppsAllowed: field_maximumLiveAppsAllowed,
          maximumScreensPerApp: field_maximumScreensPerApp,
          supportsCustomPackages: field_supportsCustomPackages,
          supportsGithubCommit: field_supportsGithubCommit,
          supportsSplashScreenChanges: field_supportsSplashScreenChanges,
          supportsAppetizePreviews: field_supportsAppetizePreviews,
          supportsPwaPublishing: field_supportsPwaPublishing,
          supportsIosPublishing: field_supportsIosPublishing,
          supportsAndroidPublishing: field_supportsAndroidPublishing,
          supportsSharePage: field_supportsSharePage,
          supportsCustomOpenAiApiKey: field_supportsCustomOpenAiApiKey,
          maximumChangeStateToLivePerMonth: field_maximumChangeStateToLivePerMonth,
          aiImageGenerationPerMonth: field_aiImageGenerationPerMonth,
          aiCodeGenerationPerMonth: field_aiCodeGenerationPerMonth,
          aiScreenCreationPerMonth: field_aiScreenCreationPerMonth
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var SimpleBillingPlanFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "SimpleBillingPlanFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var SimpleFragment = {
  SimpleBillingPlanFields: SimpleBillingPlanFields
};

var ppx_printed_query$1 = "fragment billingPlanFields on BillingPlan   {\n__typename\nuuid  \nstripeProductId  \nstripePlanId  \nname  \nlevel  \nkind  \nprice  \nisActive  \ninterval  \nintervalCount  \ntiered  \nadditionalSeatPrice  \nsupportsCodeExport  \nsupportsScreenCode  \nsupportsCustomCode  \nsupportsLiveCollaboration  \nmaximumCollaboratorsAllowed  \nmaximumWorkspacesAllowed  \nmaximumAppsAllowed  \nmaximumAppBackupsAllowed  \ndeletedScreenHistoryDays  \ndeletedComponentHistoryDays  \nuserCount  \nmaximumLiveAppsAllowed  \nmaximumScreensPerApp  \nsupportsCustomPackages  \nsupportsGithubCommit  \nsupportsSplashScreenChanges  \nsupportsAppetizePreviews  \nsupportsPwaPublishing  \nsupportsIosPublishing  \nsupportsAndroidPublishing  \nsupportsSharePage  \nsupportsCustomOpenAiApiKey  \nmaximumChangeStateToLivePerMonth  \naiImageGenerationPerMonth  \naiCodeGenerationPerMonth  \naiScreenCreationPerMonth  \n}\n";

function parse$1(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type BillingPlan, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type BillingPlan is missing");
  }
  var value$6 = Js_dict.get(value$2, "stripeProductId");
  var field_stripeProductId;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_stripeProductId = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_stripeProductId = Js_exn.raiseError("graphql_ppx: Field stripeProductId on type BillingPlan is missing");
  }
  var value$9 = Js_dict.get(value$2, "stripePlanId");
  var field_stripePlanId;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_stripePlanId = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_stripePlanId = Js_exn.raiseError("graphql_ppx: Field stripePlanId on type BillingPlan is missing");
  }
  var value$12 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    field_name = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type BillingPlan is missing");
  }
  var value$15 = Js_dict.get(value$2, "level");
  var field_level;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeString(value$16);
    if (value$17 !== undefined) {
      switch (value$17) {
        case "AGENCY" :
            field_level = "AGENCY";
            break;
        case "BASIC" :
            field_level = "BASIC";
            break;
        case "ENTERPRISE" :
            field_level = "ENTERPRISE";
            break;
        case "EXPERTS" :
            field_level = "EXPERTS";
            break;
        case "FREE" :
            field_level = "FREE";
            break;
        case "FREELANCER" :
            field_level = "FREELANCER";
            break;
        case "INDIVIDUAL" :
            field_level = "INDIVIDUAL";
            break;
        case "LEGACY" :
            field_level = "LEGACY";
            break;
        case "LOW_CODE" :
            field_level = "LOW_CODE";
            break;
        case "NO_CODE" :
            field_level = "NO_CODE";
            break;
        case "PRO" :
            field_level = "PRO";
            break;
        case "PRO_CODE" :
            field_level = "PRO_CODE";
            break;
        case "STARTER" :
            field_level = "STARTER";
            break;
        case "SUPPORT" :
            field_level = "SUPPORT";
            break;
        case "TEAM" :
            field_level = "TEAM";
            break;
        default:
          field_level = Js_exn.raiseError("graphql_ppx: Unknown enum variant for BillingPlanLevel: " + value$17);
      }
    } else {
      field_level = Js_exn.raiseError("graphql_ppx: Expected enum value for BillingPlanLevel, got " + JSON.stringify(value$16));
    }
  } else {
    field_level = Js_exn.raiseError("graphql_ppx: Field level on type BillingPlan is missing");
  }
  var value$18 = Js_dict.get(value$2, "kind");
  var field_kind;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeString(value$19);
    if (value$20 !== undefined) {
      switch (value$20) {
        case "EXPERTS" :
            field_kind = "EXPERTS";
            break;
        case "PLATFORM" :
            field_kind = "PLATFORM";
            break;
        case "SUPPORT" :
            field_kind = "SUPPORT";
            break;
        default:
          field_kind = Js_exn.raiseError("graphql_ppx: Unknown enum variant for BillingPlanKind: " + value$20);
      }
    } else {
      field_kind = Js_exn.raiseError("graphql_ppx: Expected enum value for BillingPlanKind, got " + JSON.stringify(value$19));
    }
  } else {
    field_kind = Js_exn.raiseError("graphql_ppx: Field kind on type BillingPlan is missing");
  }
  var value$21 = Js_dict.get(value$2, "price");
  var field_price;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var value$23 = Js_json.decodeNumber(value$22);
    field_price = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$22));
  } else {
    field_price = Js_exn.raiseError("graphql_ppx: Field price on type BillingPlan is missing");
  }
  var value$24 = Js_dict.get(value$2, "isActive");
  var field_isActive;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var value$26 = Js_json.decodeBoolean(value$25);
    field_isActive = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$25));
  } else {
    field_isActive = Js_exn.raiseError("graphql_ppx: Field isActive on type BillingPlan is missing");
  }
  var value$27 = Js_dict.get(value$2, "interval");
  var field_interval;
  if (value$27 !== undefined) {
    var value$28 = Caml_option.valFromOption(value$27);
    var value$29 = Js_json.decodeString(value$28);
    field_interval = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$28));
  } else {
    field_interval = Js_exn.raiseError("graphql_ppx: Field interval on type BillingPlan is missing");
  }
  var value$30 = Js_dict.get(value$2, "intervalCount");
  var field_intervalCount;
  if (value$30 !== undefined) {
    var value$31 = Caml_option.valFromOption(value$30);
    var value$32 = Js_json.decodeNumber(value$31);
    field_intervalCount = value$32 !== undefined ? value$32 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$31));
  } else {
    field_intervalCount = Js_exn.raiseError("graphql_ppx: Field intervalCount on type BillingPlan is missing");
  }
  var value$33 = Js_dict.get(value$2, "tiered");
  var field_tiered;
  if (value$33 !== undefined) {
    var value$34 = Caml_option.valFromOption(value$33);
    var value$35 = Js_json.decodeBoolean(value$34);
    field_tiered = value$35 !== undefined ? value$35 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$34));
  } else {
    field_tiered = Js_exn.raiseError("graphql_ppx: Field tiered on type BillingPlan is missing");
  }
  var value$36 = Js_dict.get(value$2, "additionalSeatPrice");
  var field_additionalSeatPrice;
  if (value$36 !== undefined) {
    var value$37 = Caml_option.valFromOption(value$36);
    var match = Js_json.decodeNull(value$37);
    if (match !== undefined) {
      field_additionalSeatPrice = undefined;
    } else {
      var value$38 = Js_json.decodeNumber(value$37);
      field_additionalSeatPrice = value$38 !== undefined ? value$38 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$37));
    }
  } else {
    field_additionalSeatPrice = undefined;
  }
  var value$39 = Js_dict.get(value$2, "supportsCodeExport");
  var field_supportsCodeExport;
  if (value$39 !== undefined) {
    var value$40 = Caml_option.valFromOption(value$39);
    var value$41 = Js_json.decodeBoolean(value$40);
    field_supportsCodeExport = value$41 !== undefined ? value$41 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$40));
  } else {
    field_supportsCodeExport = Js_exn.raiseError("graphql_ppx: Field supportsCodeExport on type BillingPlan is missing");
  }
  var value$42 = Js_dict.get(value$2, "supportsScreenCode");
  var field_supportsScreenCode;
  if (value$42 !== undefined) {
    var value$43 = Caml_option.valFromOption(value$42);
    var value$44 = Js_json.decodeBoolean(value$43);
    field_supportsScreenCode = value$44 !== undefined ? value$44 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$43));
  } else {
    field_supportsScreenCode = Js_exn.raiseError("graphql_ppx: Field supportsScreenCode on type BillingPlan is missing");
  }
  var value$45 = Js_dict.get(value$2, "supportsCustomCode");
  var field_supportsCustomCode;
  if (value$45 !== undefined) {
    var value$46 = Caml_option.valFromOption(value$45);
    var value$47 = Js_json.decodeBoolean(value$46);
    field_supportsCustomCode = value$47 !== undefined ? value$47 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$46));
  } else {
    field_supportsCustomCode = Js_exn.raiseError("graphql_ppx: Field supportsCustomCode on type BillingPlan is missing");
  }
  var value$48 = Js_dict.get(value$2, "supportsLiveCollaboration");
  var field_supportsLiveCollaboration;
  if (value$48 !== undefined) {
    var value$49 = Caml_option.valFromOption(value$48);
    var value$50 = Js_json.decodeBoolean(value$49);
    field_supportsLiveCollaboration = value$50 !== undefined ? value$50 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$49));
  } else {
    field_supportsLiveCollaboration = Js_exn.raiseError("graphql_ppx: Field supportsLiveCollaboration on type BillingPlan is missing");
  }
  var value$51 = Js_dict.get(value$2, "maximumCollaboratorsAllowed");
  var field_maximumCollaboratorsAllowed;
  if (value$51 !== undefined) {
    var value$52 = Caml_option.valFromOption(value$51);
    var value$53 = Js_json.decodeNumber(value$52);
    field_maximumCollaboratorsAllowed = value$53 !== undefined ? value$53 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$52));
  } else {
    field_maximumCollaboratorsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumCollaboratorsAllowed on type BillingPlan is missing");
  }
  var value$54 = Js_dict.get(value$2, "maximumWorkspacesAllowed");
  var field_maximumWorkspacesAllowed;
  if (value$54 !== undefined) {
    var value$55 = Caml_option.valFromOption(value$54);
    var value$56 = Js_json.decodeNumber(value$55);
    field_maximumWorkspacesAllowed = value$56 !== undefined ? value$56 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$55));
  } else {
    field_maximumWorkspacesAllowed = Js_exn.raiseError("graphql_ppx: Field maximumWorkspacesAllowed on type BillingPlan is missing");
  }
  var value$57 = Js_dict.get(value$2, "maximumAppsAllowed");
  var field_maximumAppsAllowed;
  if (value$57 !== undefined) {
    var value$58 = Caml_option.valFromOption(value$57);
    var value$59 = Js_json.decodeNumber(value$58);
    field_maximumAppsAllowed = value$59 !== undefined ? value$59 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$58));
  } else {
    field_maximumAppsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumAppsAllowed on type BillingPlan is missing");
  }
  var value$60 = Js_dict.get(value$2, "maximumAppBackupsAllowed");
  var field_maximumAppBackupsAllowed;
  if (value$60 !== undefined) {
    var value$61 = Caml_option.valFromOption(value$60);
    var value$62 = Js_json.decodeNumber(value$61);
    field_maximumAppBackupsAllowed = value$62 !== undefined ? value$62 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$61));
  } else {
    field_maximumAppBackupsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumAppBackupsAllowed on type BillingPlan is missing");
  }
  var value$63 = Js_dict.get(value$2, "deletedScreenHistoryDays");
  var field_deletedScreenHistoryDays;
  if (value$63 !== undefined) {
    var value$64 = Caml_option.valFromOption(value$63);
    var match$1 = Js_json.decodeNull(value$64);
    if (match$1 !== undefined) {
      field_deletedScreenHistoryDays = undefined;
    } else {
      var value$65 = Js_json.decodeNumber(value$64);
      field_deletedScreenHistoryDays = value$65 !== undefined ? value$65 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$64));
    }
  } else {
    field_deletedScreenHistoryDays = undefined;
  }
  var value$66 = Js_dict.get(value$2, "deletedComponentHistoryDays");
  var field_deletedComponentHistoryDays;
  if (value$66 !== undefined) {
    var value$67 = Caml_option.valFromOption(value$66);
    var match$2 = Js_json.decodeNull(value$67);
    if (match$2 !== undefined) {
      field_deletedComponentHistoryDays = undefined;
    } else {
      var value$68 = Js_json.decodeNumber(value$67);
      field_deletedComponentHistoryDays = value$68 !== undefined ? value$68 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$67));
    }
  } else {
    field_deletedComponentHistoryDays = undefined;
  }
  var value$69 = Js_dict.get(value$2, "userCount");
  var field_userCount;
  if (value$69 !== undefined) {
    var value$70 = Caml_option.valFromOption(value$69);
    var value$71 = Js_json.decodeNumber(value$70);
    field_userCount = value$71 !== undefined ? value$71 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$70));
  } else {
    field_userCount = Js_exn.raiseError("graphql_ppx: Field userCount on type BillingPlan is missing");
  }
  var value$72 = Js_dict.get(value$2, "maximumLiveAppsAllowed");
  var field_maximumLiveAppsAllowed;
  if (value$72 !== undefined) {
    var value$73 = Caml_option.valFromOption(value$72);
    var value$74 = Js_json.decodeNumber(value$73);
    field_maximumLiveAppsAllowed = value$74 !== undefined ? value$74 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$73));
  } else {
    field_maximumLiveAppsAllowed = Js_exn.raiseError("graphql_ppx: Field maximumLiveAppsAllowed on type BillingPlan is missing");
  }
  var value$75 = Js_dict.get(value$2, "maximumScreensPerApp");
  var field_maximumScreensPerApp;
  if (value$75 !== undefined) {
    var value$76 = Caml_option.valFromOption(value$75);
    var value$77 = Js_json.decodeNumber(value$76);
    field_maximumScreensPerApp = value$77 !== undefined ? value$77 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$76));
  } else {
    field_maximumScreensPerApp = Js_exn.raiseError("graphql_ppx: Field maximumScreensPerApp on type BillingPlan is missing");
  }
  var value$78 = Js_dict.get(value$2, "supportsCustomPackages");
  var field_supportsCustomPackages;
  if (value$78 !== undefined) {
    var value$79 = Caml_option.valFromOption(value$78);
    var value$80 = Js_json.decodeBoolean(value$79);
    field_supportsCustomPackages = value$80 !== undefined ? value$80 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$79));
  } else {
    field_supportsCustomPackages = Js_exn.raiseError("graphql_ppx: Field supportsCustomPackages on type BillingPlan is missing");
  }
  var value$81 = Js_dict.get(value$2, "supportsGithubCommit");
  var field_supportsGithubCommit;
  if (value$81 !== undefined) {
    var value$82 = Caml_option.valFromOption(value$81);
    var value$83 = Js_json.decodeBoolean(value$82);
    field_supportsGithubCommit = value$83 !== undefined ? value$83 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$82));
  } else {
    field_supportsGithubCommit = Js_exn.raiseError("graphql_ppx: Field supportsGithubCommit on type BillingPlan is missing");
  }
  var value$84 = Js_dict.get(value$2, "supportsSplashScreenChanges");
  var field_supportsSplashScreenChanges;
  if (value$84 !== undefined) {
    var value$85 = Caml_option.valFromOption(value$84);
    var value$86 = Js_json.decodeBoolean(value$85);
    field_supportsSplashScreenChanges = value$86 !== undefined ? value$86 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$85));
  } else {
    field_supportsSplashScreenChanges = Js_exn.raiseError("graphql_ppx: Field supportsSplashScreenChanges on type BillingPlan is missing");
  }
  var value$87 = Js_dict.get(value$2, "supportsAppetizePreviews");
  var field_supportsAppetizePreviews;
  if (value$87 !== undefined) {
    var value$88 = Caml_option.valFromOption(value$87);
    var value$89 = Js_json.decodeBoolean(value$88);
    field_supportsAppetizePreviews = value$89 !== undefined ? value$89 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$88));
  } else {
    field_supportsAppetizePreviews = Js_exn.raiseError("graphql_ppx: Field supportsAppetizePreviews on type BillingPlan is missing");
  }
  var value$90 = Js_dict.get(value$2, "supportsPwaPublishing");
  var field_supportsPwaPublishing;
  if (value$90 !== undefined) {
    var value$91 = Caml_option.valFromOption(value$90);
    var value$92 = Js_json.decodeBoolean(value$91);
    field_supportsPwaPublishing = value$92 !== undefined ? value$92 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$91));
  } else {
    field_supportsPwaPublishing = Js_exn.raiseError("graphql_ppx: Field supportsPwaPublishing on type BillingPlan is missing");
  }
  var value$93 = Js_dict.get(value$2, "supportsIosPublishing");
  var field_supportsIosPublishing;
  if (value$93 !== undefined) {
    var value$94 = Caml_option.valFromOption(value$93);
    var value$95 = Js_json.decodeBoolean(value$94);
    field_supportsIosPublishing = value$95 !== undefined ? value$95 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$94));
  } else {
    field_supportsIosPublishing = Js_exn.raiseError("graphql_ppx: Field supportsIosPublishing on type BillingPlan is missing");
  }
  var value$96 = Js_dict.get(value$2, "supportsAndroidPublishing");
  var field_supportsAndroidPublishing;
  if (value$96 !== undefined) {
    var value$97 = Caml_option.valFromOption(value$96);
    var value$98 = Js_json.decodeBoolean(value$97);
    field_supportsAndroidPublishing = value$98 !== undefined ? value$98 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$97));
  } else {
    field_supportsAndroidPublishing = Js_exn.raiseError("graphql_ppx: Field supportsAndroidPublishing on type BillingPlan is missing");
  }
  var value$99 = Js_dict.get(value$2, "supportsSharePage");
  var field_supportsSharePage;
  if (value$99 !== undefined) {
    var value$100 = Caml_option.valFromOption(value$99);
    var value$101 = Js_json.decodeBoolean(value$100);
    field_supportsSharePage = value$101 !== undefined ? value$101 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$100));
  } else {
    field_supportsSharePage = Js_exn.raiseError("graphql_ppx: Field supportsSharePage on type BillingPlan is missing");
  }
  var value$102 = Js_dict.get(value$2, "supportsCustomOpenAiApiKey");
  var field_supportsCustomOpenAiApiKey;
  if (value$102 !== undefined) {
    var value$103 = Caml_option.valFromOption(value$102);
    var value$104 = Js_json.decodeBoolean(value$103);
    field_supportsCustomOpenAiApiKey = value$104 !== undefined ? value$104 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$103));
  } else {
    field_supportsCustomOpenAiApiKey = Js_exn.raiseError("graphql_ppx: Field supportsCustomOpenAiApiKey on type BillingPlan is missing");
  }
  var value$105 = Js_dict.get(value$2, "maximumChangeStateToLivePerMonth");
  var field_maximumChangeStateToLivePerMonth;
  if (value$105 !== undefined) {
    var value$106 = Caml_option.valFromOption(value$105);
    var value$107 = Js_json.decodeNumber(value$106);
    field_maximumChangeStateToLivePerMonth = value$107 !== undefined ? value$107 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$106));
  } else {
    field_maximumChangeStateToLivePerMonth = Js_exn.raiseError("graphql_ppx: Field maximumChangeStateToLivePerMonth on type BillingPlan is missing");
  }
  var value$108 = Js_dict.get(value$2, "aiImageGenerationPerMonth");
  var field_aiImageGenerationPerMonth;
  if (value$108 !== undefined) {
    var value$109 = Caml_option.valFromOption(value$108);
    var value$110 = Js_json.decodeNumber(value$109);
    field_aiImageGenerationPerMonth = value$110 !== undefined ? value$110 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$109));
  } else {
    field_aiImageGenerationPerMonth = Js_exn.raiseError("graphql_ppx: Field aiImageGenerationPerMonth on type BillingPlan is missing");
  }
  var value$111 = Js_dict.get(value$2, "aiCodeGenerationPerMonth");
  var field_aiCodeGenerationPerMonth;
  if (value$111 !== undefined) {
    var value$112 = Caml_option.valFromOption(value$111);
    var value$113 = Js_json.decodeNumber(value$112);
    field_aiCodeGenerationPerMonth = value$113 !== undefined ? value$113 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$112));
  } else {
    field_aiCodeGenerationPerMonth = Js_exn.raiseError("graphql_ppx: Field aiCodeGenerationPerMonth on type BillingPlan is missing");
  }
  var value$114 = Js_dict.get(value$2, "aiScreenCreationPerMonth");
  var field_aiScreenCreationPerMonth;
  if (value$114 !== undefined) {
    var value$115 = Caml_option.valFromOption(value$114);
    var value$116 = Js_json.decodeNumber(value$115);
    field_aiScreenCreationPerMonth = value$116 !== undefined ? value$116 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$115));
  } else {
    field_aiScreenCreationPerMonth = Js_exn.raiseError("graphql_ppx: Field aiScreenCreationPerMonth on type BillingPlan is missing");
  }
  return {
          uuid: field_uuid,
          stripeProductId: field_stripeProductId,
          stripePlanId: field_stripePlanId,
          name: field_name,
          level: field_level,
          kind: field_kind,
          price: field_price,
          isActive: field_isActive,
          interval: field_interval,
          intervalCount: field_intervalCount,
          tiered: field_tiered,
          additionalSeatPrice: field_additionalSeatPrice,
          supportsCodeExport: field_supportsCodeExport,
          supportsScreenCode: field_supportsScreenCode,
          supportsCustomCode: field_supportsCustomCode,
          supportsLiveCollaboration: field_supportsLiveCollaboration,
          maximumCollaboratorsAllowed: field_maximumCollaboratorsAllowed,
          maximumWorkspacesAllowed: field_maximumWorkspacesAllowed,
          maximumAppsAllowed: field_maximumAppsAllowed,
          maximumAppBackupsAllowed: field_maximumAppBackupsAllowed,
          deletedScreenHistoryDays: field_deletedScreenHistoryDays,
          deletedComponentHistoryDays: field_deletedComponentHistoryDays,
          userCount: field_userCount,
          maximumLiveAppsAllowed: field_maximumLiveAppsAllowed,
          maximumScreensPerApp: field_maximumScreensPerApp,
          supportsCustomPackages: field_supportsCustomPackages,
          supportsGithubCommit: field_supportsGithubCommit,
          supportsSplashScreenChanges: field_supportsSplashScreenChanges,
          supportsAppetizePreviews: field_supportsAppetizePreviews,
          supportsPwaPublishing: field_supportsPwaPublishing,
          supportsIosPublishing: field_supportsIosPublishing,
          supportsAndroidPublishing: field_supportsAndroidPublishing,
          supportsSharePage: field_supportsSharePage,
          supportsCustomOpenAiApiKey: field_supportsCustomOpenAiApiKey,
          maximumChangeStateToLivePerMonth: field_maximumChangeStateToLivePerMonth,
          aiImageGenerationPerMonth: field_aiImageGenerationPerMonth,
          aiCodeGenerationPerMonth: field_aiCodeGenerationPerMonth,
          aiScreenCreationPerMonth: field_aiScreenCreationPerMonth
        };
}

var name = "billingPlanFields";

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var BillingPlanFields = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: name,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var Fragment = {
  BillingPlanFields: BillingPlanFields
};

var ppx_printed_query$2 = "query BillingPlans  {\n__typename\nbillingPlans  {\n__typename\n...billingPlanFields   \n}\n\n}\nfragment billingPlanFields on BillingPlan   {\n__typename\nuuid  \nstripeProductId  \nstripePlanId  \nname  \nlevel  \nkind  \nprice  \nisActive  \ninterval  \nintervalCount  \ntiered  \nadditionalSeatPrice  \nsupportsCodeExport  \nsupportsScreenCode  \nsupportsCustomCode  \nsupportsLiveCollaboration  \nmaximumCollaboratorsAllowed  \nmaximumWorkspacesAllowed  \nmaximumAppsAllowed  \nmaximumAppBackupsAllowed  \ndeletedScreenHistoryDays  \ndeletedComponentHistoryDays  \nuserCount  \nmaximumLiveAppsAllowed  \nmaximumScreensPerApp  \nsupportsCustomPackages  \nsupportsGithubCommit  \nsupportsSplashScreenChanges  \nsupportsAppetizePreviews  \nsupportsPwaPublishing  \nsupportsIosPublishing  \nsupportsAndroidPublishing  \nsupportsSharePage  \nsupportsCustomOpenAiApiKey  \nmaximumChangeStateToLivePerMonth  \naiImageGenerationPerMonth  \naiCodeGenerationPerMonth  \naiScreenCreationPerMonth  \n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "billingPlans");
  return {
          billingPlans: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(parse$1) : Js_exn.raiseError("graphql_ppx: Field billingPlans on type Query is missing")
        };
}

function make(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse$2,
  ppx_printed_query$2,
  definition_2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

function useQuery(param) {
  return ApolloHooks$DraftbitBuilder.useQuery(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition)[0];
}

var List = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2,
  useQuery: useQuery
};

var ppx_printed_query$3 = "query BillingPlanUsers($uuid: ID!, $skip: Int!, $take: Int!)  {\n__typename\nbillingPlan(uuid: $uuid)  {\n__typename\nuuid  \nusers(skip: $skip, take: $take)  {\n__typename\nuuid  \nemail  \nfirstName  \nlastName  \nimageUrl  \nlastActive  \n}\n\n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "billingPlan");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeObject(value$3);
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_dict.get(value$5, "uuid");
      var field_uuid;
      if (value$6 !== undefined) {
        var value$7 = Caml_option.valFromOption(value$6);
        var value$8 = Js_json.decodeString(value$7);
        field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
      } else {
        field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type BillingPlan is missing");
      }
      var value$9 = Js_dict.get(value$5, "users");
      var field_users = value$9 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$9))).map(function (value) {
              var value$1 = Js_json.decodeObject(value);
              if (value$1 === undefined) {
                return Js_exn.raiseError("graphql_ppx: Expected object of type User, got " + JSON.stringify(value));
              }
              var value$2 = Caml_option.valFromOption(value$1);
              var value$3 = Js_dict.get(value$2, "uuid");
              var field_uuid;
              if (value$3 !== undefined) {
                var value$4 = Caml_option.valFromOption(value$3);
                var value$5 = Js_json.decodeString(value$4);
                field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
              } else {
                field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
              }
              var value$6 = Js_dict.get(value$2, "email");
              var field_email;
              if (value$6 !== undefined) {
                var value$7 = Caml_option.valFromOption(value$6);
                var value$8 = Js_json.decodeString(value$7);
                field_email = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
              } else {
                field_email = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
              }
              var value$9 = Js_dict.get(value$2, "firstName");
              var field_firstName;
              if (value$9 !== undefined) {
                var value$10 = Caml_option.valFromOption(value$9);
                var match = Js_json.decodeNull(value$10);
                if (match !== undefined) {
                  field_firstName = undefined;
                } else {
                  var value$11 = Js_json.decodeString(value$10);
                  field_firstName = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
                }
              } else {
                field_firstName = undefined;
              }
              var value$12 = Js_dict.get(value$2, "lastName");
              var field_lastName;
              if (value$12 !== undefined) {
                var value$13 = Caml_option.valFromOption(value$12);
                var match$1 = Js_json.decodeNull(value$13);
                if (match$1 !== undefined) {
                  field_lastName = undefined;
                } else {
                  var value$14 = Js_json.decodeString(value$13);
                  field_lastName = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
                }
              } else {
                field_lastName = undefined;
              }
              var value$15 = Js_dict.get(value$2, "imageUrl");
              var field_imageUrl;
              if (value$15 !== undefined) {
                var value$16 = Caml_option.valFromOption(value$15);
                var match$2 = Js_json.decodeNull(value$16);
                if (match$2 !== undefined) {
                  field_imageUrl = undefined;
                } else {
                  var value$17 = Js_json.decodeString(value$16);
                  field_imageUrl = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
                }
              } else {
                field_imageUrl = undefined;
              }
              var value$18 = Js_dict.get(value$2, "lastActive");
              var field_lastActive;
              if (value$18 !== undefined) {
                var value$19 = Caml_option.valFromOption(value$18);
                var match$3 = Js_json.decodeNull(value$19);
                field_lastActive = match$3 !== undefined ? undefined : Caml_option.some(value$19);
              } else {
                field_lastActive = undefined;
              }
              return {
                      uuid: field_uuid,
                      email: field_email,
                      firstName: field_firstName,
                      lastName: field_lastName,
                      imageUrl: field_imageUrl,
                      lastActive: field_lastActive
                    };
            }) : Js_exn.raiseError("graphql_ppx: Field users on type BillingPlan is missing");
      tmp = {
        uuid: field_uuid,
        users: field_users
      };
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected object of type BillingPlan, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field billingPlan on type Query is missing");
  }
  return {
          billingPlan: tmp
        };
}

function make$1(uuid, skip, take, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "uuid",
                    uuid
                  ],
                  [
                    "skip",
                    skip
                  ],
                  [
                    "take",
                    take
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$1(variables) {
  var uuid = variables.uuid;
  var skip = variables.skip;
  var take = variables.take;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "uuid",
                    uuid
                  ],
                  [
                    "skip",
                    skip
                  ],
                  [
                    "take",
                    take
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$1(uuid, skip, take, param) {
  return Js_dict.fromArray([
                [
                  "uuid",
                  uuid
                ],
                [
                  "skip",
                  skip
                ],
                [
                  "take",
                  take
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, uuid, skip, take, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "uuid",
                      uuid
                    ],
                    [
                      "skip",
                      skip
                    ],
                    [
                      "take",
                      take
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$1
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function useQuery$1(uuid, skip, take, skipQuery, param) {
  return ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables$1(uuid, skip, take, undefined)), undefined, undefined, undefined, skipQuery, undefined, undefined, definition$1)[0];
}

var GetUsers = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  useQuery: useQuery$1
};

var ppx_printed_query$4 = "mutation AdminUpdateBillingPlan($input: AdminUpdateBillingPlanInput!)  {\n__typename\nadminUpdateBillingPlan(input: $input)  {\n__typename\n...billingPlanFields   \n}\n\n}\nfragment billingPlanFields on BillingPlan   {\n__typename\nuuid  \nstripeProductId  \nstripePlanId  \nname  \nlevel  \nkind  \nprice  \nisActive  \ninterval  \nintervalCount  \ntiered  \nadditionalSeatPrice  \nsupportsCodeExport  \nsupportsScreenCode  \nsupportsCustomCode  \nsupportsLiveCollaboration  \nmaximumCollaboratorsAllowed  \nmaximumWorkspacesAllowed  \nmaximumAppsAllowed  \nmaximumAppBackupsAllowed  \ndeletedScreenHistoryDays  \ndeletedComponentHistoryDays  \nuserCount  \nmaximumLiveAppsAllowed  \nmaximumScreensPerApp  \nsupportsCustomPackages  \nsupportsGithubCommit  \nsupportsSplashScreenChanges  \nsupportsAppetizePreviews  \nsupportsPwaPublishing  \nsupportsIosPublishing  \nsupportsAndroidPublishing  \nsupportsSharePage  \nsupportsCustomOpenAiApiKey  \nmaximumChangeStateToLivePerMonth  \naiImageGenerationPerMonth  \naiCodeGenerationPerMonth  \naiScreenCreationPerMonth  \n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "adminUpdateBillingPlan");
  return {
          adminUpdateBillingPlan: value$2 !== undefined ? parse$1(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field adminUpdateBillingPlan on type Mutation is missing")
        };
}

function json_of_BillingPlanKind(value) {
  if (value === "EXPERTS") {
    return "EXPERTS";
  } else if (value === "SUPPORT") {
    return "SUPPORT";
  } else {
    return "PLATFORM";
  }
}

function json_of_BillingPlanLevel(value) {
  if (value === "LEGACY") {
    return "LEGACY";
  } else if (value === "LOW_CODE") {
    return "LOW_CODE";
  } else if (value === "PRO") {
    return "PRO";
  } else if (value === "AGENCY") {
    return "AGENCY";
  } else if (value === "NO_CODE") {
    return "NO_CODE";
  } else if (value === "EXPERTS") {
    return "EXPERTS";
  } else if (value === "ENTERPRISE") {
    return "ENTERPRISE";
  } else if (value === "PRO_CODE") {
    return "PRO_CODE";
  } else if (value === "BASIC") {
    return "BASIC";
  } else if (value === "FREE") {
    return "FREE";
  } else if (value === "STARTER") {
    return "STARTER";
  } else if (value === "TEAM") {
    return "TEAM";
  } else if (value === "SUPPORT") {
    return "SUPPORT";
  } else if (value === "INDIVIDUAL") {
    return "INDIVIDUAL";
  } else {
    return "FREELANCER";
  }
}

function json_of_AdminUpdateBillingPlanInput(value) {
  var v = value.deletedComponentHistoryDays;
  var v$1 = value.deletedScreenHistoryDays;
  return Js_dict.fromArray([
                [
                  "aiCodeGenerationPerMonth",
                  value.aiCodeGenerationPerMonth
                ],
                [
                  "aiImageGenerationPerMonth",
                  value.aiImageGenerationPerMonth
                ],
                [
                  "aiScreenCreationPerMonth",
                  value.aiScreenCreationPerMonth
                ],
                [
                  "deletedComponentHistoryDays",
                  v !== undefined ? v : null
                ],
                [
                  "deletedScreenHistoryDays",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "isActive",
                  value.isActive
                ],
                [
                  "kind",
                  json_of_BillingPlanKind(value.kind)
                ],
                [
                  "level",
                  json_of_BillingPlanLevel(value.level)
                ],
                [
                  "maximumAppBackupsAllowed",
                  value.maximumAppBackupsAllowed
                ],
                [
                  "maximumAppsAllowed",
                  value.maximumAppsAllowed
                ],
                [
                  "maximumChangeStateToLivePerMonth",
                  value.maximumChangeStateToLivePerMonth
                ],
                [
                  "maximumCollaboratorsAllowed",
                  value.maximumCollaboratorsAllowed
                ],
                [
                  "maximumLiveAppsAllowed",
                  value.maximumLiveAppsAllowed
                ],
                [
                  "maximumScreensPerApp",
                  value.maximumScreensPerApp
                ],
                [
                  "maximumWorkspacesAllowed",
                  value.maximumWorkspacesAllowed
                ],
                [
                  "supportsAndroidPublishing",
                  value.supportsAndroidPublishing
                ],
                [
                  "supportsAppetizePreviews",
                  value.supportsAppetizePreviews
                ],
                [
                  "supportsCodeExport",
                  value.supportsCodeExport
                ],
                [
                  "supportsCustomCode",
                  value.supportsCustomCode
                ],
                [
                  "supportsCustomOpenAiApiKey",
                  value.supportsCustomOpenAiApiKey
                ],
                [
                  "supportsCustomPackages",
                  value.supportsCustomPackages
                ],
                [
                  "supportsGithubCommit",
                  value.supportsGithubCommit
                ],
                [
                  "supportsIosPublishing",
                  value.supportsIosPublishing
                ],
                [
                  "supportsLiveCollaboration",
                  value.supportsLiveCollaboration
                ],
                [
                  "supportsPwaPublishing",
                  value.supportsPwaPublishing
                ],
                [
                  "supportsScreenCode",
                  value.supportsScreenCode
                ],
                [
                  "supportsSharePage",
                  value.supportsSharePage
                ],
                [
                  "supportsSplashScreenChanges",
                  value.supportsSplashScreenChanges
                ],
                [
                  "uuid",
                  value.uuid
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$2(input, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_AdminUpdateBillingPlanInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeWithVariables$2(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_AdminUpdateBillingPlanInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeVariables$2(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_AdminUpdateBillingPlanInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_AdminUpdateBillingPlanInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$2
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

function useMutation(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$2);
  var mutation = match[0];
  return function (uuid, level, kind, supportsCodeExport, supportsScreenCode, supportsCustomCode, supportsLiveCollaboration, maximumCollaboratorsAllowed, maximumWorkspacesAllowed, maximumAppsAllowed, maximumAppBackupsAllowed, deletedScreenHistoryDays, deletedComponentHistoryDays, maximumLiveAppsAllowed, maximumScreensPerApp, supportsCustomPackages, supportsGithubCommit, supportsSplashScreenChanges, supportsAppetizePreviews, supportsPwaPublishing, supportsIosPublishing, supportsAndroidPublishing, supportsSharePage, supportsCustomOpenAiApiKey, maximumChangeStateToLivePerMonth, aiImageGenerationPerMonth, aiCodeGenerationPerMonth, aiScreenCreationPerMonth, isActive, param) {
    var input = {
      uuid: uuid,
      level: level,
      kind: kind,
      supportsCodeExport: supportsCodeExport,
      supportsScreenCode: supportsScreenCode,
      supportsCustomCode: supportsCustomCode,
      supportsLiveCollaboration: supportsLiveCollaboration,
      maximumCollaboratorsAllowed: maximumCollaboratorsAllowed,
      maximumWorkspacesAllowed: maximumWorkspacesAllowed,
      maximumAppsAllowed: maximumAppsAllowed,
      maximumAppBackupsAllowed: maximumAppBackupsAllowed,
      deletedScreenHistoryDays: deletedScreenHistoryDays,
      deletedComponentHistoryDays: deletedComponentHistoryDays,
      maximumLiveAppsAllowed: maximumLiveAppsAllowed,
      maximumScreensPerApp: maximumScreensPerApp,
      supportsCustomPackages: supportsCustomPackages,
      supportsGithubCommit: supportsGithubCommit,
      supportsSplashScreenChanges: supportsSplashScreenChanges,
      supportsAppetizePreviews: supportsAppetizePreviews,
      supportsPwaPublishing: supportsPwaPublishing,
      supportsIosPublishing: supportsIosPublishing,
      supportsAndroidPublishing: supportsAndroidPublishing,
      supportsSharePage: supportsSharePage,
      supportsCustomOpenAiApiKey: supportsCustomOpenAiApiKey,
      maximumChangeStateToLivePerMonth: maximumChangeStateToLivePerMonth,
      aiImageGenerationPerMonth: aiImageGenerationPerMonth,
      aiCodeGenerationPerMonth: aiCodeGenerationPerMonth,
      aiScreenCreationPerMonth: aiScreenCreationPerMonth,
      isActive: isActive
    };
    ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$2(input, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                return prim[0];
              })), "Billing plan permissions updated", undefined, undefined, undefined, Caml_option.some(Js_dict.fromArray([[
                    "uuid",
                    uuid
                  ]])), undefined, undefined, undefined, "Updated billing plan permissions", undefined, undefined, undefined);
    
  };
}

var AdminUpdate = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  json_of_BillingPlanKind: json_of_BillingPlanKind,
  json_of_BillingPlanLevel: json_of_BillingPlanLevel,
  json_of_AdminUpdateBillingPlanInput: json_of_AdminUpdateBillingPlanInput,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4,
  useMutation: useMutation
};

var GQL = {
  SimpleFragment: SimpleFragment,
  Fragment: Fragment,
  List: List,
  GetUsers: GetUsers,
  AdminUpdate: AdminUpdate
};

var dummyBillingPlan = {
  uuid: "abc123",
  name: "",
  level: "FREE",
  kind: "PLATFORM",
  stripePlanId: "plan_12345",
  supportsCodeExport: false,
  supportsScreenCode: false,
  supportsCustomCode: false,
  supportsLiveCollaboration: false,
  maximumCollaboratorsAllowed: 0,
  maximumAppsAllowed: 1,
  maximumAppBackupsAllowed: 1,
  maximumWorkspacesAllowed: 1,
  deletedScreenHistoryDays: 0,
  deletedComponentHistoryDays: 0,
  maximumLiveAppsAllowed: 1,
  maximumScreensPerApp: 6,
  supportsCustomPackages: false,
  supportsGithubCommit: false,
  supportsSplashScreenChanges: false,
  supportsAppetizePreviews: false,
  supportsPwaPublishing: false,
  supportsIosPublishing: false,
  supportsAndroidPublishing: false,
  supportsSharePage: true,
  supportsCustomOpenAiApiKey: false,
  maximumChangeStateToLivePerMonth: 1000,
  aiImageGenerationPerMonth: 5,
  aiCodeGenerationPerMonth: 1000,
  aiScreenCreationPerMonth: 1000
};

export {
  levelToJs ,
  levelFromJs ,
  levels ,
  kindToJs ,
  kindFromJs ,
  kinds ,
  level_encode ,
  level_decode ,
  kind_encode ,
  kind_decode ,
  simpleBillingPlan_encode ,
  simpleBillingPlan_decode ,
  dummyBillingPlan ,
  GQL ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
