// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as JsMap from "rescript-js-collections/src/JsMap.bs.js";
import * as JsSet from "rescript-js-collections/src/JsSet.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Lodash from "lodash";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Caml_array from "bs-platform/lib/es6/caml_array.mjs";
import * as Pervasives from "bs-platform/lib/es6/pervasives.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Belt_SortArray from "bs-platform/lib/es6/belt_SortArray.mjs";
import * as LodashIsequal from "lodash.isequal";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";

function push(arr, elem) {
  arr.push(elem);
  
}

function includesDeepCompare(arr, item) {
  return arr.some(function (item2) {
              return LodashIsequal(item, item2);
            });
}

function emptyIfNone(opt) {
  if (opt !== undefined) {
    return opt;
  } else {
    return [];
  }
}

function noneIfEmpty(arr) {
  if (arr.length !== 0) {
    return arr;
  }
  
}

function toOptionMap(arr, f) {
  if (arr.length !== 0) {
    return Caml_option.some(Curry._1(f, arr));
  }
  
}

function fromOption(opt) {
  if (opt !== undefined) {
    return [Caml_option.valFromOption(opt)];
  } else {
    return [];
  }
}

function fromOptionMap(opt, f) {
  if (opt !== undefined) {
    return [Curry._1(f, Caml_option.valFromOption(opt))];
  } else {
    return [];
  }
}

function replaceItem(arr, index, newItem) {
  return arr.slice(0, index).concat([newItem]).concat(arr.slice(index + 1 | 0));
}

function updateItem(arr, index, f) {
  var item = Belt_Array.get(arr, index);
  if (item !== undefined) {
    return replaceItem(arr, index, Curry._1(f, Caml_option.valFromOption(item)));
  } else {
    return arr;
  }
}

function updateItemMatching(arr, test, f) {
  var index = arr.findIndex(Curry.__1(test));
  if (index !== -1) {
    return updateItem(arr, index, f);
  } else {
    return arr;
  }
}

function findAndUpdateMut(arr, test, f) {
  var index = arr.findIndex(Curry.__1(test));
  if (index !== -1) {
    return Belt_Array.set(arr, index, Curry._1(f, Caml_array.get(arr, index)));
  } else {
    return false;
  }
}

function remove(arr, item) {
  return arr.filter(function (i) {
              return Caml_obj.caml_notequal(i, item);
            });
}

function removeIf(arr, cond, item) {
  if (cond) {
    return remove(arr, item);
  } else {
    return arr;
  }
}

function removeBy(arr, cond) {
  return arr.filter(function (i) {
              return !Curry._1(cond, i);
            });
}

function removeByIf(arr, cond, test) {
  if (cond) {
    return removeBy(arr, test);
  } else {
    return arr;
  }
}

function removeByIndex(arr, idx) {
  return arr.filter(function (param, i) {
              return i !== idx;
            });
}

function append(arr, newItem) {
  var result = arr.slice();
  result.push(newItem);
  return result;
}

function appendIf(arr, condition, item) {
  if (condition) {
    return append(arr, item);
  } else {
    return arr;
  }
}

function concatIf(arr, condition, arr2) {
  if (condition) {
    return arr.concat(arr2);
  } else {
    return arr;
  }
}

function appendIfNotPresent(arr, item) {
  return appendIf(arr, !arr.includes(item), item);
}

function appendIfNotPresentDeepCompare(arr, item) {
  return appendIf(arr, !includesDeepCompare(arr, item), item);
}

function appendOpt(arr, opt) {
  if (opt !== undefined) {
    return append(arr, Caml_option.valFromOption(opt));
  } else {
    return arr;
  }
}

function updateOrAppendItemMatching(arr, test, f) {
  var index = arr.findIndex(Curry.__1(test));
  if (index !== -1) {
    return updateItem(arr, index, (function (x) {
                  return Curry._1(f, Caml_option.some(x));
                }));
  } else {
    return append(arr, Curry._1(f, undefined));
  }
}

function pushIf(arr, condition, item) {
  if (condition) {
    arr.push(item);
    return ;
  }
  
}

function pushIfNotPresent(arr, item) {
  return pushIf(arr, !arr.includes(item), item);
}

function pushIfNotPresentDeepCompare(arr, item) {
  return pushIf(arr, !includesDeepCompare(arr, item), item);
}

function appendOrUpdate(arr, cond, f) {
  var index = arr.findIndex(Curry.__1(cond));
  if (index === -1) {
    return appendOpt(arr, Curry._1(f, undefined));
  }
  var newItem = Curry._1(f, Caml_option.some(Caml_array.get(arr, index)));
  if (newItem !== undefined) {
    return replaceItem(arr, index, Caml_option.valFromOption(newItem));
  } else {
    return removeByIndex(arr, index);
  }
}

function applyOrdering(arr, orderArr, ifMissingOpt, getKey) {
  var ifMissing = ifMissingOpt !== undefined ? ifMissingOpt : "drop";
  var res = [];
  var matchedIndices = JsSet.empty(undefined);
  orderArr.forEach(function (key) {
        var index = arr.findIndex(function (item) {
              return Caml_obj.caml_equal(Curry._1(getKey, item), key);
            });
        if (index !== -1) {
          JsSet.addMut(matchedIndices, index);
          res.push(Caml_array.get(arr, index));
          return ;
        }
        
      });
  if (ifMissing === "addToEnd") {
    return res.concat(Belt_Array.keepWithIndex(arr, (function (param, idx) {
                      return !JsSet.has(matchedIndices, idx);
                    })));
  } else {
    return res;
  }
}

function moveMatchToFront(arr, test) {
  var otherIndex = arr.findIndex(Curry.__1(test));
  if (otherIndex === -1 || otherIndex === 0) {
    return arr;
  } else {
    return [Caml_array.get(arr, otherIndex)].concat(arr.slice(0, otherIndex).concat(arr.slice(otherIndex + 1 | 0)));
  }
}

function moveMatchesToFront(arr, test) {
  var match = Belt_Array.partition(arr, test);
  return match[0].concat(match[1]);
}

function moveMatchToBack(arr, test) {
  var i = arr.findIndex(Curry.__1(test));
  if (i !== -1 && i !== (arr.length - 1 | 0)) {
    return append(arr.slice(0, i).concat(arr.slice(i + 1 | 0)), Caml_array.get(arr, i));
  } else {
    return arr;
  }
}

function moveMatchesToBack(arr, test) {
  var match = Belt_Array.partition(arr, test);
  return match[1].concat(match[0]);
}

function swap(arr, idx1, idx2) {
  var match = Belt_Array.get(arr, idx1);
  var match$1 = Belt_Array.get(arr, idx2);
  if (match === undefined) {
    return arr;
  }
  if (match$1 === undefined) {
    return arr;
  }
  var elem2 = Caml_option.valFromOption(match$1);
  var elem1 = Caml_option.valFromOption(match);
  return arr.map(function (elem, i) {
              if (i === idx1) {
                return elem2;
              } else if (i === idx2) {
                return elem1;
              } else {
                return elem;
              }
            });
}

function reorderByMatch(arr, first, second) {
  var match = arr.findIndex(Curry.__1(first));
  var match$1 = arr.findIndex(Curry.__1(second));
  if (match !== -1 && match$1 !== -1 && match >= match$1) {
    return swap(arr, match, match$1);
  } else {
    return arr;
  }
}

function extend(arr, arr$prime) {
  return Belt_Array.forEach(arr$prime, (function (elem) {
                arr.push(elem);
                
              }));
}

function prepend(arr, newItem) {
  var result = [newItem];
  extend(result, arr);
  return result;
}

function prependIf(arr, condition, item) {
  if (condition) {
    return prepend(arr, item);
  } else {
    return arr;
  }
}

function prependOpt(arr, opt) {
  if (opt !== undefined) {
    return prepend(arr, Caml_option.valFromOption(opt));
  } else {
    return arr;
  }
}

function intersperse(arr, newItem) {
  var result = [];
  Belt_Array.forEachWithIndex(arr, (function (index, item) {
          result.push(item);
          if ((index + 1 | 0) < arr.length) {
            result.push(newItem);
            return ;
          }
          
        }));
  return result;
}

function keepIf(arr, cond, f) {
  if (cond) {
    return arr.filter(Curry.__1(f));
  } else {
    return arr;
  }
}

function keepMapSafe(arr, onErrorOpt, f) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param, param$1) {
        
      });
  return Belt_Array.keepMap(arr, (function (x) {
                var res;
                try {
                  res = Curry._1(f, x);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  if (err.RE_EXN_ID === Js_exn.$$Error) {
                    Curry._2(onError, x, err._1);
                    return ;
                  }
                  throw err;
                }
                return Caml_option.some(res);
              }));
}

function keepFirstSome(arr, f) {
  var len = arr.length;
  var _idx = 0;
  while(true) {
    var idx = _idx;
    if (idx >= len) {
      return ;
    }
    var result = Curry._1(f, Caml_array.get(arr, idx));
    if (result !== undefined) {
      return Caml_option.some(Caml_option.valFromOption(result));
    }
    _idx = idx + 1 | 0;
    continue ;
  };
}

function keepLastSome(arr, f) {
  return keepFirstSome(Belt_Array.reverse(arr), f);
}

function keepSome(a) {
  return Belt_Array.keepMap(a, (function (x) {
                return x;
              }));
}

function first(arr) {
  return Belt_Array.get(arr, 0);
}

function last(arr) {
  return Belt_Array.get(arr, arr.length - 1 | 0);
}

function mapJoin(arr, sep, toString) {
  return arr.map(Curry.__1(toString)).join(sep);
}

function sortBy(arr, f) {
  return Belt_SortArray.stableSortBy(arr, (function (a, b) {
                return Caml_obj.caml_compare(Curry._1(f, a), Curry._1(f, b));
              }));
}

function sort(arr) {
  return sortBy(arr, (function (x) {
                return x;
              }));
}

function sortBy2(arr, outer, inner) {
  return Lodash.sortBy(arr, [
              outer,
              inner
            ]);
}

function sortByDesc(arr, f) {
  return Belt_Array.reverse(sortBy(arr, f));
}

function uniq(arr) {
  return JsSet.toArray(JsSet.fromArray(arr));
}

function uniqBy(arr, f) {
  return JsMap.valuesArray(JsMap.fromArray(arr.map(function (x) {
                      return [
                              Curry._1(f, x),
                              x
                            ];
                    })));
}

function uniqSortBy(arr, f) {
  return sortBy(uniqBy(arr, f), f);
}

function uniqByWithMerge(arr, toKey, merge) {
  return JsMap.valuesArray(arr.reduce((function (map, item) {
                    var existing = JsMap.get(map, Curry._1(toKey, item));
                    return JsMap.setMut(map, Curry._1(toKey, item), existing !== undefined ? Curry._2(merge, Caml_option.valFromOption(existing), item) : item);
                  }), JsMap.empty(undefined)));
}

function minBy(arr, f) {
  return Belt_Array.get(sortBy(arr, f), 0);
}

function maxBy(arr, f) {
  return Belt_Array.get(sortBy(arr, f), arr.length - 1 | 0);
}

function differentiateDuplicates(arr, f, g) {
  return JsMap.entriesArray(JsMap.groupBy(arr, f)).flatMap(function (param) {
              var group = param[1];
              if (group.length === 1) {
                return [[
                          param[0],
                          Caml_array.get(group, 0)
                        ]];
              } else {
                return group.map(function (item) {
                            return [
                                    Curry._1(g, item),
                                    item
                                  ];
                          });
              }
            });
}

function sliceToEnd(arr, n) {
  return Belt_Array.slice(arr, n, arr.length);
}

function orderParentsFirst(arr, toId, toParentIds) {
  var itemsById = JsMap.keyBy(arr, toId);
  var res = [];
  var resIds = JsSet.empty(undefined);
  var idsInProgress = JsSet.empty(undefined);
  var loop = function (item) {
    var id = Curry._1(toId, item);
    if (JsSet.has(idsInProgress, id)) {
      Pervasives.failwith("Cycle detected");
    }
    if (!JsSet.has(resIds, id)) {
      JsSet.addMut(idsInProgress, id);
      Belt_Array.keepMap(Curry._1(toParentIds, item), (function (param) {
                return JsMap.get(itemsById, param);
              })).forEach(loop);
      JsSet.deleteMut(idsInProgress, id);
      res.push(item);
      JsSet.addMut(resIds, id);
      return ;
    }
    
  };
  arr.forEach(loop);
  return res;
}

function orderParentsFirstIgnoreCycles(arr, toParentIds, toId) {
  try {
    return orderParentsFirst(arr, toId, toParentIds);
  }
  catch (exn){
    return arr;
  }
}

function sum(numbers) {
  return numbers.reduce((function (a, b) {
                return a + b | 0;
              }), 0);
}

function takeFirst(arr, n) {
  var length = arr.length;
  var safeN = Math.min(length, n);
  return arr.slice(0, safeN);
}

var get = Belt_Array.get;

var getExn = Belt_Array.getExn;

var set = Belt_Array.set;

var setExn = Belt_Array.setExn;

var shuffleInPlace = Belt_Array.shuffleInPlace;

var shuffle = Belt_Array.shuffle;

var reverseInPlace = Belt_Array.reverseInPlace;

var reverse = Belt_Array.reverse;

var make = Belt_Array.make;

var range = Belt_Array.range;

var rangeBy = Belt_Array.rangeBy;

var makeByU = Belt_Array.makeByU;

var makeBy = Belt_Array.makeBy;

var makeByAndShuffleU = Belt_Array.makeByAndShuffleU;

var makeByAndShuffle = Belt_Array.makeByAndShuffle;

var zip = Belt_Array.zip;

var zipByU = Belt_Array.zipByU;

var zipBy = Belt_Array.zipBy;

var unzip = Belt_Array.unzip;

var concat = Belt_Array.concat;

var concatMany = Belt_Array.concatMany;

var slice = Belt_Array.slice;

var fill = Belt_Array.fill;

var blit = Belt_Array.blit;

var blitUnsafe = Belt_Array.blitUnsafe;

var forEachU = Belt_Array.forEachU;

var forEach = Belt_Array.forEach;

var mapU = Belt_Array.mapU;

var map = Belt_Array.map;

var getByU = Belt_Array.getByU;

var getBy = Belt_Array.getBy;

var getIndexByU = Belt_Array.getIndexByU;

var getIndexBy = Belt_Array.getIndexBy;

var keepU = Belt_Array.keepU;

var keep = Belt_Array.keep;

var keepWithIndexU = Belt_Array.keepWithIndexU;

var keepWithIndex = Belt_Array.keepWithIndex;

var keepMapU = Belt_Array.keepMapU;

var forEachWithIndexU = Belt_Array.forEachWithIndexU;

var forEachWithIndex = Belt_Array.forEachWithIndex;

var mapWithIndexU = Belt_Array.mapWithIndexU;

var mapWithIndex = Belt_Array.mapWithIndex;

var partitionU = Belt_Array.partitionU;

var reduceU = Belt_Array.reduceU;

var reduce = Belt_Array.reduce;

var reduceReverseU = Belt_Array.reduceReverseU;

var reduceReverse = Belt_Array.reduceReverse;

var reduceReverse2U = Belt_Array.reduceReverse2U;

var reduceReverse2 = Belt_Array.reduceReverse2;

var reduceWithIndexU = Belt_Array.reduceWithIndexU;

var reduceWithIndex = Belt_Array.reduceWithIndex;

var joinWithU = Belt_Array.joinWithU;

var joinWith = Belt_Array.joinWith;

var someU = Belt_Array.someU;

var some = Belt_Array.some;

var everyU = Belt_Array.everyU;

var every = Belt_Array.every;

var every2U = Belt_Array.every2U;

var every2 = Belt_Array.every2;

var some2U = Belt_Array.some2U;

var some2 = Belt_Array.some2;

var cmpU = Belt_Array.cmpU;

var cmp = Belt_Array.cmp;

var eqU = Belt_Array.eqU;

var eq = Belt_Array.eq;

var keepMap = Belt_Array.keepMap;

var partition = Belt_Array.partition;

export {
  get ,
  getExn ,
  set ,
  setExn ,
  shuffleInPlace ,
  shuffle ,
  reverseInPlace ,
  reverse ,
  make ,
  range ,
  rangeBy ,
  makeByU ,
  makeBy ,
  makeByAndShuffleU ,
  makeByAndShuffle ,
  zip ,
  zipByU ,
  zipBy ,
  unzip ,
  concat ,
  concatMany ,
  slice ,
  fill ,
  blit ,
  blitUnsafe ,
  forEachU ,
  forEach ,
  mapU ,
  map ,
  getByU ,
  getBy ,
  getIndexByU ,
  getIndexBy ,
  keepU ,
  keep ,
  keepWithIndexU ,
  keepWithIndex ,
  keepMapU ,
  forEachWithIndexU ,
  forEachWithIndex ,
  mapWithIndexU ,
  mapWithIndex ,
  partitionU ,
  reduceU ,
  reduce ,
  reduceReverseU ,
  reduceReverse ,
  reduceReverse2U ,
  reduceReverse2 ,
  reduceWithIndexU ,
  reduceWithIndex ,
  joinWithU ,
  joinWith ,
  someU ,
  some ,
  everyU ,
  every ,
  every2U ,
  every2 ,
  some2U ,
  some2 ,
  cmpU ,
  cmp ,
  eqU ,
  eq ,
  push ,
  includesDeepCompare ,
  emptyIfNone ,
  noneIfEmpty ,
  toOptionMap ,
  fromOption ,
  fromOptionMap ,
  replaceItem ,
  updateItem ,
  updateItemMatching ,
  findAndUpdateMut ,
  remove ,
  removeIf ,
  removeBy ,
  removeByIf ,
  removeByIndex ,
  append ,
  appendIf ,
  concatIf ,
  appendIfNotPresent ,
  appendIfNotPresentDeepCompare ,
  appendOpt ,
  updateOrAppendItemMatching ,
  pushIf ,
  pushIfNotPresent ,
  pushIfNotPresentDeepCompare ,
  appendOrUpdate ,
  applyOrdering ,
  moveMatchToFront ,
  moveMatchesToFront ,
  moveMatchToBack ,
  moveMatchesToBack ,
  swap ,
  reorderByMatch ,
  extend ,
  prepend ,
  prependIf ,
  prependOpt ,
  intersperse ,
  keepMap ,
  keepIf ,
  partition ,
  keepMapSafe ,
  keepFirstSome ,
  keepLastSome ,
  keepSome ,
  first ,
  last ,
  mapJoin ,
  sortBy ,
  sort ,
  sortBy2 ,
  sortByDesc ,
  uniq ,
  uniqBy ,
  uniqSortBy ,
  uniqByWithMerge ,
  minBy ,
  maxBy ,
  differentiateDuplicates ,
  sliceToEnd ,
  orderParentsFirst ,
  orderParentsFirstIgnoreCycles ,
  sum ,
  takeFirst ,
  
}
/* lodash Not a pure module */
