// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as ArrayUtils$DraftbitBuilder from "./ArrayUtils.bs.js";

var singleTokenRegex = /{{\s*([\w.]+)\s*}}/;

var globalTokenRegex = /{{\s*([\w.]+)\s*}}/g;

var delimiters = /[{}]/g;

function tokenize(template) {
  var loop = function (_acc, _s) {
    while(true) {
      var s = _s;
      var acc = _acc;
      var match_ = s.match(singleTokenRegex);
      if (match_ === null) {
        return {
                hd: {
                  NAME: "PlainString",
                  VAL: s
                },
                tl: acc
              };
      }
      if (match_.length !== 2) {
        return {
                hd: {
                  NAME: "PlainString",
                  VAL: s
                },
                tl: acc
              };
      }
      var m = match_[0];
      var variableName = match_[1];
      var index = match_.index;
      var prevString = s.substring(0, index);
      var remaining = s.slice(index + m.length | 0);
      _s = remaining;
      _acc = {
        hd: {
          NAME: "Variable",
          VAL: variableName
        },
        tl: {
          hd: {
            NAME: "PlainString",
            VAL: prevString
          },
          tl: acc
        }
      };
      continue ;
    };
  };
  return Belt_List.toArray(Belt_List.reverse(loop(/* [] */0, template))).filter(function (token) {
              if (typeof token === "string" || !(token.NAME === "PlainString" && token.VAL === "")) {
                return true;
              } else {
                return false;
              }
            });
}

function parse(template) {
  return ArrayUtils$DraftbitBuilder.uniq(ArrayUtils$DraftbitBuilder.sort(Belt_Array.keepMap(tokenize(template), (function (token) {
                        if (typeof token === "string" || token.NAME !== "Variable") {
                          return ;
                        } else {
                          return token.VAL;
                        }
                      }))));
}

function replaceTokens(template, replaceToken) {
  return tokenize(template).map(Curry.__1(replaceToken)).join("");
}

function replaceVariables(template, replaceVariable) {
  return replaceTokens(template, (function (token) {
                if (token.NAME === "PlainString") {
                  return token.VAL;
                } else {
                  return Curry._1(replaceVariable, token.VAL);
                }
              }));
}

function highlight(template, classNames) {
  return replaceVariables(template, (function (v) {
                return "<span class=\"" + (classNames + ("\" >&#123;&#123;" + (v + "&#125;&#125;</span>")));
              }));
}

function render(values, defaultOpt, template) {
  var $$default = defaultOpt !== undefined ? defaultOpt : (function (n) {
        return "{{" + n + "}}";
      });
  return replaceVariables(template, (function (variableName) {
                return Belt_Option.getWithDefault(Js_dict.get(values, variableName), Curry._1($$default, variableName));
              }));
}

export {
  singleTokenRegex ,
  globalTokenRegex ,
  delimiters ,
  tokenize ,
  parse ,
  replaceTokens ,
  replaceVariables ,
  highlight ,
  render ,
  
}
/* ArrayUtils-DraftbitBuilder Not a pure module */
