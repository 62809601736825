// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";

function toNetworkStatus(status) {
  if (status == null) {
    return /* Unknown */7;
  }
  switch (status) {
    case 1 :
        return /* Loading */0;
    case 2 :
        return /* SetVariables */1;
    case 3 :
        return /* FetchMore */2;
    case 4 :
        return /* Refetch */3;
    case 5 :
        return /* Unknown */7;
    case 6 :
        return /* Poll */4;
    case 7 :
        return /* Ready */5;
    case 8 :
        return /* Error */6;
    default:
      return /* Unknown */7;
  }
}

function fetchPolicyToJs(fetchPolicy) {
  switch (fetchPolicy) {
    case /* CacheFirst */0 :
        return "cache-first";
    case /* CacheAndNetwork */1 :
        return "cache-and-network";
    case /* NetworkOnly */2 :
        return "network-only";
    case /* CacheOnly */3 :
        return "cache-only";
    case /* NoCache */4 :
        return "no-cache";
    case /* Standby */5 :
        return "standby";
    
  }
}

function errorPolicyToJs(errorPolicy) {
  switch (errorPolicy) {
    case /* None */0 :
        return "none";
    case /* Ignore */1 :
        return "ignore";
    case /* All */2 :
        return "all";
    
  }
}

var make = Js_dict.fromList;

var Context = {
  make: make
};

export {
  toNetworkStatus ,
  fetchPolicyToJs ,
  errorPolicyToJs ,
  Context ,
  
}
/* No side effect */
