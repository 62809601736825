// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Hooks$DraftbitBuilder from "./hooks/Hooks.bs.js";
import * as Utils$DraftbitBuilder from "./utilities/Utils.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as Feature$DraftbitBuilder from "./Feature.bs.js";
import * as Session$DraftbitBuilder from "./Session.bs.js";
import * as Analytics$DraftbitBuilder from "./utilities/Analytics.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as BillingPlan$DraftbitBuilder from "./BillingPlan.bs.js";
import * as QueryString$DraftbitBuilder from "./bindings/QueryString.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./utilities/PromiseUtils.bs.js";
import * as ExpoAccountGQL$DraftbitBuilder from "./ExpoAccountGQL.bs.js";
import * as SimpleWorkspace$DraftbitBuilder from "./SimpleWorkspace.bs.js";

var _map = {"Free":"Free","Active":"Active","UnableToCharge":"UnableToCharge","Overdue":"Overdue"};

function accountPaymentStateToJs(param) {
  return param;
}

function accountPaymentStateFromJs(param) {
  return _map[param];
}

function feature_encode(v) {
  return Js_dict.fromArray([
              [
                "uuid",
                Decco.stringToJson(v.uuid)
              ],
              [
                "name",
                Feature$DraftbitBuilder.t_encode(v.name)
              ]
            ]);
}

function feature_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var uuid = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
  if (uuid.TAG === /* Ok */0) {
    var name = Feature$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
    if (name.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                uuid: uuid._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = uuid._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".uuid" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function toSimpleFeature(f) {
  return {
          uuid: f.uuid,
          name: f.name
        };
}

function toSimple(u) {
  return {
          uuid: u.uuid,
          firstName: u.firstName,
          lastName: u.lastName,
          email: u.email,
          imageUrl: u.imageUrl,
          billingPlan: u.billingPlan,
          enabledFeatures: u.enabledFeatures.map(toSimpleFeature),
          numberOfUniqueCollaborators: u.numberOfUniqueCollaborators,
          numberOfAppStateChangesToLivePast30Days: u.numberOfAppStateChangesToLivePast30Days,
          expoAccounts: u.expoAccounts,
          isAdmin: u.isAdmin
        };
}

function fullName(firstName, lastName, $$default) {
  var match = StringUtils$DraftbitBuilder.emptyToNone(firstName);
  var match$1 = StringUtils$DraftbitBuilder.emptyToNone(lastName);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return match + " " + match$1;
    } else {
      return match;
    }
  } else if (match$1 !== undefined) {
    return match$1;
  } else {
    return $$default;
  }
}

function fullNameWithEmail(firstName, lastName, email) {
  if (firstName !== undefined && lastName !== undefined) {
    return firstName + " " + lastName + " &lt;" + email + "&gt;";
  } else {
    return email;
  }
}

var ppx_printed_query = "fragment UserFields on User   {\n__typename\n__typename  \nuuid  \nisAdmin  \nemail  \ncreatedAt  \nlastActive  \nipAddress  \nregisteredAt  \nsignedUpAt  \nemailVerifiedAt  \ndeletedAt  \ndeviceId  \nfirstName  \nlastName  \nimageUrl  \ngrantedAccess  \nhasUsedLivePreview  \nstripeCustomerId  \nleadSource  \nleadUtmSource  \nleadUtmMedium  \nleadUtmCampaign  \nleadUtmTerm  \nleadUtmContent  \nleadInitialReferrer  \nleadLastReferrer  \nleadInitialLandingPage  \nleadPartnerStackGspk  \nleadPartnerStackGsxid  \nleadPartnerStackPkey  \nleadPartnerStackCustomerKey  \nonboardingDraftbitUsage  \nonboardingPersona  \nonboardingBackground  \nonboardingAppData  \nonboardingExperience  \nonboardingCompanyType  \nonboardingCompanyRole  \nonboardingCompanySize  \nonboardingUseCases  \nonboardingReactNative  \nonboardingReferralSource  \naccountPaymentState  \ncancelSubscriptionAtPeriodEnd  \ncancelSubscriptionAt  \nhasEverSubscribed  \nenabledFeatures  {\n__typename\nuuid  \nname  \n}\n\nbillingPlan  {\n__typename\n..." + BillingPlan$DraftbitBuilder.GQL.SimpleFragment.SimpleBillingPlanFields.name + "   \n}\n\nexpoAccounts  {\n__typename\n..." + ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.name + "   \n}\n\nnumberOfUniqueCollaborators  \nnumberOfAppStateChangesToLivePast30Days  \nsignupAttempts  \nfailedLoginAttempts  \nlastFailedLogin  \n}\n" + BillingPlan$DraftbitBuilder.GQL.SimpleFragment.SimpleBillingPlanFields.query + ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type User, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type User is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
  }
  var value$9 = Js_dict.get(value$2, "isAdmin");
  var field_isAdmin;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeBoolean(value$10);
    field_isAdmin = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$10));
  } else {
    field_isAdmin = Js_exn.raiseError("graphql_ppx: Field isAdmin on type User is missing");
  }
  var value$12 = Js_dict.get(value$2, "email");
  var field_email;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    field_email = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
  } else {
    field_email = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
  }
  var value$15 = Js_dict.get(value$2, "createdAt");
  var field_createdAt = value$15 !== undefined ? Caml_option.valFromOption(value$15) : Js_exn.raiseError("graphql_ppx: Field createdAt on type User is missing");
  var value$16 = Js_dict.get(value$2, "lastActive");
  var field_lastActive;
  if (value$16 !== undefined) {
    var value$17 = Caml_option.valFromOption(value$16);
    var match = Js_json.decodeNull(value$17);
    field_lastActive = match !== undefined ? undefined : Caml_option.some(value$17);
  } else {
    field_lastActive = undefined;
  }
  var value$18 = Js_dict.get(value$2, "ipAddress");
  var field_ipAddress;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var match$1 = Js_json.decodeNull(value$19);
    if (match$1 !== undefined) {
      field_ipAddress = undefined;
    } else {
      var value$20 = Js_json.decodeString(value$19);
      field_ipAddress = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
    }
  } else {
    field_ipAddress = undefined;
  }
  var value$21 = Js_dict.get(value$2, "registeredAt");
  var field_registeredAt;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var match$2 = Js_json.decodeNull(value$22);
    field_registeredAt = match$2 !== undefined ? undefined : Caml_option.some(value$22);
  } else {
    field_registeredAt = undefined;
  }
  var value$23 = Js_dict.get(value$2, "signedUpAt");
  var field_signedUpAt;
  if (value$23 !== undefined) {
    var value$24 = Caml_option.valFromOption(value$23);
    var match$3 = Js_json.decodeNull(value$24);
    field_signedUpAt = match$3 !== undefined ? undefined : Caml_option.some(value$24);
  } else {
    field_signedUpAt = undefined;
  }
  var value$25 = Js_dict.get(value$2, "emailVerifiedAt");
  var field_emailVerifiedAt;
  if (value$25 !== undefined) {
    var value$26 = Caml_option.valFromOption(value$25);
    var match$4 = Js_json.decodeNull(value$26);
    field_emailVerifiedAt = match$4 !== undefined ? undefined : Caml_option.some(value$26);
  } else {
    field_emailVerifiedAt = undefined;
  }
  var value$27 = Js_dict.get(value$2, "deletedAt");
  var field_deletedAt;
  if (value$27 !== undefined) {
    var value$28 = Caml_option.valFromOption(value$27);
    var match$5 = Js_json.decodeNull(value$28);
    field_deletedAt = match$5 !== undefined ? undefined : Caml_option.some(value$28);
  } else {
    field_deletedAt = undefined;
  }
  var value$29 = Js_dict.get(value$2, "deviceId");
  var field_deviceId;
  if (value$29 !== undefined) {
    var value$30 = Caml_option.valFromOption(value$29);
    var match$6 = Js_json.decodeNull(value$30);
    if (match$6 !== undefined) {
      field_deviceId = undefined;
    } else {
      var value$31 = Js_json.decodeString(value$30);
      field_deviceId = value$31 !== undefined ? value$31 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$30));
    }
  } else {
    field_deviceId = undefined;
  }
  var value$32 = Js_dict.get(value$2, "firstName");
  var field_firstName;
  if (value$32 !== undefined) {
    var value$33 = Caml_option.valFromOption(value$32);
    var match$7 = Js_json.decodeNull(value$33);
    if (match$7 !== undefined) {
      field_firstName = undefined;
    } else {
      var value$34 = Js_json.decodeString(value$33);
      field_firstName = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$33));
    }
  } else {
    field_firstName = undefined;
  }
  var value$35 = Js_dict.get(value$2, "lastName");
  var field_lastName;
  if (value$35 !== undefined) {
    var value$36 = Caml_option.valFromOption(value$35);
    var match$8 = Js_json.decodeNull(value$36);
    if (match$8 !== undefined) {
      field_lastName = undefined;
    } else {
      var value$37 = Js_json.decodeString(value$36);
      field_lastName = value$37 !== undefined ? value$37 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$36));
    }
  } else {
    field_lastName = undefined;
  }
  var value$38 = Js_dict.get(value$2, "imageUrl");
  var field_imageUrl;
  if (value$38 !== undefined) {
    var value$39 = Caml_option.valFromOption(value$38);
    var match$9 = Js_json.decodeNull(value$39);
    if (match$9 !== undefined) {
      field_imageUrl = undefined;
    } else {
      var value$40 = Js_json.decodeString(value$39);
      field_imageUrl = value$40 !== undefined ? value$40 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$39));
    }
  } else {
    field_imageUrl = undefined;
  }
  var value$41 = Js_dict.get(value$2, "grantedAccess");
  var field_grantedAccess;
  if (value$41 !== undefined) {
    var value$42 = Caml_option.valFromOption(value$41);
    var value$43 = Js_json.decodeBoolean(value$42);
    field_grantedAccess = value$43 !== undefined ? value$43 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$42));
  } else {
    field_grantedAccess = Js_exn.raiseError("graphql_ppx: Field grantedAccess on type User is missing");
  }
  var value$44 = Js_dict.get(value$2, "hasUsedLivePreview");
  var field_hasUsedLivePreview;
  if (value$44 !== undefined) {
    var value$45 = Caml_option.valFromOption(value$44);
    var value$46 = Js_json.decodeBoolean(value$45);
    field_hasUsedLivePreview = value$46 !== undefined ? value$46 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$45));
  } else {
    field_hasUsedLivePreview = Js_exn.raiseError("graphql_ppx: Field hasUsedLivePreview on type User is missing");
  }
  var value$47 = Js_dict.get(value$2, "stripeCustomerId");
  var field_stripeCustomerId;
  if (value$47 !== undefined) {
    var value$48 = Caml_option.valFromOption(value$47);
    var match$10 = Js_json.decodeNull(value$48);
    if (match$10 !== undefined) {
      field_stripeCustomerId = undefined;
    } else {
      var value$49 = Js_json.decodeString(value$48);
      field_stripeCustomerId = value$49 !== undefined ? value$49 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$48));
    }
  } else {
    field_stripeCustomerId = undefined;
  }
  var value$50 = Js_dict.get(value$2, "leadSource");
  var field_leadSource;
  if (value$50 !== undefined) {
    var value$51 = Caml_option.valFromOption(value$50);
    var match$11 = Js_json.decodeNull(value$51);
    if (match$11 !== undefined) {
      field_leadSource = undefined;
    } else {
      var value$52 = Js_json.decodeString(value$51);
      field_leadSource = value$52 !== undefined ? value$52 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$51));
    }
  } else {
    field_leadSource = undefined;
  }
  var value$53 = Js_dict.get(value$2, "leadUtmSource");
  var field_leadUtmSource;
  if (value$53 !== undefined) {
    var value$54 = Caml_option.valFromOption(value$53);
    var match$12 = Js_json.decodeNull(value$54);
    if (match$12 !== undefined) {
      field_leadUtmSource = undefined;
    } else {
      var value$55 = Js_json.decodeString(value$54);
      field_leadUtmSource = value$55 !== undefined ? value$55 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$54));
    }
  } else {
    field_leadUtmSource = undefined;
  }
  var value$56 = Js_dict.get(value$2, "leadUtmMedium");
  var field_leadUtmMedium;
  if (value$56 !== undefined) {
    var value$57 = Caml_option.valFromOption(value$56);
    var match$13 = Js_json.decodeNull(value$57);
    if (match$13 !== undefined) {
      field_leadUtmMedium = undefined;
    } else {
      var value$58 = Js_json.decodeString(value$57);
      field_leadUtmMedium = value$58 !== undefined ? value$58 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$57));
    }
  } else {
    field_leadUtmMedium = undefined;
  }
  var value$59 = Js_dict.get(value$2, "leadUtmCampaign");
  var field_leadUtmCampaign;
  if (value$59 !== undefined) {
    var value$60 = Caml_option.valFromOption(value$59);
    var match$14 = Js_json.decodeNull(value$60);
    if (match$14 !== undefined) {
      field_leadUtmCampaign = undefined;
    } else {
      var value$61 = Js_json.decodeString(value$60);
      field_leadUtmCampaign = value$61 !== undefined ? value$61 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$60));
    }
  } else {
    field_leadUtmCampaign = undefined;
  }
  var value$62 = Js_dict.get(value$2, "leadUtmTerm");
  var field_leadUtmTerm;
  if (value$62 !== undefined) {
    var value$63 = Caml_option.valFromOption(value$62);
    var match$15 = Js_json.decodeNull(value$63);
    if (match$15 !== undefined) {
      field_leadUtmTerm = undefined;
    } else {
      var value$64 = Js_json.decodeString(value$63);
      field_leadUtmTerm = value$64 !== undefined ? value$64 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$63));
    }
  } else {
    field_leadUtmTerm = undefined;
  }
  var value$65 = Js_dict.get(value$2, "leadUtmContent");
  var field_leadUtmContent;
  if (value$65 !== undefined) {
    var value$66 = Caml_option.valFromOption(value$65);
    var match$16 = Js_json.decodeNull(value$66);
    if (match$16 !== undefined) {
      field_leadUtmContent = undefined;
    } else {
      var value$67 = Js_json.decodeString(value$66);
      field_leadUtmContent = value$67 !== undefined ? value$67 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$66));
    }
  } else {
    field_leadUtmContent = undefined;
  }
  var value$68 = Js_dict.get(value$2, "leadInitialReferrer");
  var field_leadInitialReferrer;
  if (value$68 !== undefined) {
    var value$69 = Caml_option.valFromOption(value$68);
    var match$17 = Js_json.decodeNull(value$69);
    if (match$17 !== undefined) {
      field_leadInitialReferrer = undefined;
    } else {
      var value$70 = Js_json.decodeString(value$69);
      field_leadInitialReferrer = value$70 !== undefined ? value$70 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$69));
    }
  } else {
    field_leadInitialReferrer = undefined;
  }
  var value$71 = Js_dict.get(value$2, "leadLastReferrer");
  var field_leadLastReferrer;
  if (value$71 !== undefined) {
    var value$72 = Caml_option.valFromOption(value$71);
    var match$18 = Js_json.decodeNull(value$72);
    if (match$18 !== undefined) {
      field_leadLastReferrer = undefined;
    } else {
      var value$73 = Js_json.decodeString(value$72);
      field_leadLastReferrer = value$73 !== undefined ? value$73 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$72));
    }
  } else {
    field_leadLastReferrer = undefined;
  }
  var value$74 = Js_dict.get(value$2, "leadInitialLandingPage");
  var field_leadInitialLandingPage;
  if (value$74 !== undefined) {
    var value$75 = Caml_option.valFromOption(value$74);
    var match$19 = Js_json.decodeNull(value$75);
    if (match$19 !== undefined) {
      field_leadInitialLandingPage = undefined;
    } else {
      var value$76 = Js_json.decodeString(value$75);
      field_leadInitialLandingPage = value$76 !== undefined ? value$76 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$75));
    }
  } else {
    field_leadInitialLandingPage = undefined;
  }
  var value$77 = Js_dict.get(value$2, "leadPartnerStackGspk");
  var field_leadPartnerStackGspk;
  if (value$77 !== undefined) {
    var value$78 = Caml_option.valFromOption(value$77);
    var match$20 = Js_json.decodeNull(value$78);
    if (match$20 !== undefined) {
      field_leadPartnerStackGspk = undefined;
    } else {
      var value$79 = Js_json.decodeString(value$78);
      field_leadPartnerStackGspk = value$79 !== undefined ? value$79 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$78));
    }
  } else {
    field_leadPartnerStackGspk = undefined;
  }
  var value$80 = Js_dict.get(value$2, "leadPartnerStackGsxid");
  var field_leadPartnerStackGsxid;
  if (value$80 !== undefined) {
    var value$81 = Caml_option.valFromOption(value$80);
    var match$21 = Js_json.decodeNull(value$81);
    if (match$21 !== undefined) {
      field_leadPartnerStackGsxid = undefined;
    } else {
      var value$82 = Js_json.decodeString(value$81);
      field_leadPartnerStackGsxid = value$82 !== undefined ? value$82 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$81));
    }
  } else {
    field_leadPartnerStackGsxid = undefined;
  }
  var value$83 = Js_dict.get(value$2, "leadPartnerStackPkey");
  var field_leadPartnerStackPkey;
  if (value$83 !== undefined) {
    var value$84 = Caml_option.valFromOption(value$83);
    var match$22 = Js_json.decodeNull(value$84);
    if (match$22 !== undefined) {
      field_leadPartnerStackPkey = undefined;
    } else {
      var value$85 = Js_json.decodeString(value$84);
      field_leadPartnerStackPkey = value$85 !== undefined ? value$85 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$84));
    }
  } else {
    field_leadPartnerStackPkey = undefined;
  }
  var value$86 = Js_dict.get(value$2, "leadPartnerStackCustomerKey");
  var field_leadPartnerStackCustomerKey;
  if (value$86 !== undefined) {
    var value$87 = Caml_option.valFromOption(value$86);
    var match$23 = Js_json.decodeNull(value$87);
    if (match$23 !== undefined) {
      field_leadPartnerStackCustomerKey = undefined;
    } else {
      var value$88 = Js_json.decodeString(value$87);
      field_leadPartnerStackCustomerKey = value$88 !== undefined ? value$88 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$87));
    }
  } else {
    field_leadPartnerStackCustomerKey = undefined;
  }
  var value$89 = Js_dict.get(value$2, "onboardingDraftbitUsage");
  var field_onboardingDraftbitUsage;
  if (value$89 !== undefined) {
    var value$90 = Caml_option.valFromOption(value$89);
    var match$24 = Js_json.decodeNull(value$90);
    if (match$24 !== undefined) {
      field_onboardingDraftbitUsage = undefined;
    } else {
      var value$91 = Js_json.decodeString(value$90);
      field_onboardingDraftbitUsage = value$91 !== undefined ? value$91 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$90));
    }
  } else {
    field_onboardingDraftbitUsage = undefined;
  }
  var value$92 = Js_dict.get(value$2, "onboardingPersona");
  var field_onboardingPersona;
  if (value$92 !== undefined) {
    var value$93 = Caml_option.valFromOption(value$92);
    var match$25 = Js_json.decodeNull(value$93);
    if (match$25 !== undefined) {
      field_onboardingPersona = undefined;
    } else {
      var value$94 = Js_json.decodeString(value$93);
      field_onboardingPersona = value$94 !== undefined ? value$94 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$93));
    }
  } else {
    field_onboardingPersona = undefined;
  }
  var value$95 = Js_dict.get(value$2, "onboardingBackground");
  var field_onboardingBackground;
  if (value$95 !== undefined) {
    var value$96 = Caml_option.valFromOption(value$95);
    var match$26 = Js_json.decodeNull(value$96);
    if (match$26 !== undefined) {
      field_onboardingBackground = undefined;
    } else {
      var value$97 = Js_json.decodeString(value$96);
      field_onboardingBackground = value$97 !== undefined ? value$97 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$96));
    }
  } else {
    field_onboardingBackground = undefined;
  }
  var value$98 = Js_dict.get(value$2, "onboardingAppData");
  var field_onboardingAppData;
  if (value$98 !== undefined) {
    var value$99 = Caml_option.valFromOption(value$98);
    var match$27 = Js_json.decodeNull(value$99);
    if (match$27 !== undefined) {
      field_onboardingAppData = undefined;
    } else {
      var value$100 = Js_json.decodeString(value$99);
      field_onboardingAppData = value$100 !== undefined ? value$100 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$99));
    }
  } else {
    field_onboardingAppData = undefined;
  }
  var value$101 = Js_dict.get(value$2, "onboardingExperience");
  var field_onboardingExperience;
  if (value$101 !== undefined) {
    var value$102 = Caml_option.valFromOption(value$101);
    var match$28 = Js_json.decodeNull(value$102);
    if (match$28 !== undefined) {
      field_onboardingExperience = undefined;
    } else {
      var value$103 = Js_json.decodeString(value$102);
      field_onboardingExperience = value$103 !== undefined ? value$103 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$102));
    }
  } else {
    field_onboardingExperience = undefined;
  }
  var value$104 = Js_dict.get(value$2, "onboardingCompanyType");
  var field_onboardingCompanyType;
  if (value$104 !== undefined) {
    var value$105 = Caml_option.valFromOption(value$104);
    var match$29 = Js_json.decodeNull(value$105);
    if (match$29 !== undefined) {
      field_onboardingCompanyType = undefined;
    } else {
      var value$106 = Js_json.decodeString(value$105);
      field_onboardingCompanyType = value$106 !== undefined ? value$106 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$105));
    }
  } else {
    field_onboardingCompanyType = undefined;
  }
  var value$107 = Js_dict.get(value$2, "onboardingCompanyRole");
  var field_onboardingCompanyRole;
  if (value$107 !== undefined) {
    var value$108 = Caml_option.valFromOption(value$107);
    var match$30 = Js_json.decodeNull(value$108);
    if (match$30 !== undefined) {
      field_onboardingCompanyRole = undefined;
    } else {
      var value$109 = Js_json.decodeString(value$108);
      field_onboardingCompanyRole = value$109 !== undefined ? value$109 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$108));
    }
  } else {
    field_onboardingCompanyRole = undefined;
  }
  var value$110 = Js_dict.get(value$2, "onboardingCompanySize");
  var field_onboardingCompanySize;
  if (value$110 !== undefined) {
    var value$111 = Caml_option.valFromOption(value$110);
    var match$31 = Js_json.decodeNull(value$111);
    if (match$31 !== undefined) {
      field_onboardingCompanySize = undefined;
    } else {
      var value$112 = Js_json.decodeString(value$111);
      field_onboardingCompanySize = value$112 !== undefined ? value$112 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$111));
    }
  } else {
    field_onboardingCompanySize = undefined;
  }
  var value$113 = Js_dict.get(value$2, "onboardingUseCases");
  var field_onboardingUseCases;
  if (value$113 !== undefined) {
    var value$114 = Caml_option.valFromOption(value$113);
    var match$32 = Js_json.decodeNull(value$114);
    if (match$32 !== undefined) {
      field_onboardingUseCases = undefined;
    } else {
      var value$115 = Js_json.decodeString(value$114);
      field_onboardingUseCases = value$115 !== undefined ? value$115 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$114));
    }
  } else {
    field_onboardingUseCases = undefined;
  }
  var value$116 = Js_dict.get(value$2, "onboardingReactNative");
  var field_onboardingReactNative;
  if (value$116 !== undefined) {
    var value$117 = Caml_option.valFromOption(value$116);
    var match$33 = Js_json.decodeNull(value$117);
    if (match$33 !== undefined) {
      field_onboardingReactNative = undefined;
    } else {
      var value$118 = Js_json.decodeString(value$117);
      field_onboardingReactNative = value$118 !== undefined ? value$118 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$117));
    }
  } else {
    field_onboardingReactNative = undefined;
  }
  var value$119 = Js_dict.get(value$2, "onboardingReferralSource");
  var field_onboardingReferralSource;
  if (value$119 !== undefined) {
    var value$120 = Caml_option.valFromOption(value$119);
    var match$34 = Js_json.decodeNull(value$120);
    if (match$34 !== undefined) {
      field_onboardingReferralSource = undefined;
    } else {
      var value$121 = Js_json.decodeString(value$120);
      field_onboardingReferralSource = value$121 !== undefined ? value$121 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$120));
    }
  } else {
    field_onboardingReferralSource = undefined;
  }
  var value$122 = Js_dict.get(value$2, "accountPaymentState");
  var field_accountPaymentState;
  if (value$122 !== undefined) {
    var value$123 = Caml_option.valFromOption(value$122);
    var value$124 = Js_json.decodeString(value$123);
    if (value$124 !== undefined) {
      switch (value$124) {
        case "Active" :
            field_accountPaymentState = "Active";
            break;
        case "Free" :
            field_accountPaymentState = "Free";
            break;
        case "Overdue" :
            field_accountPaymentState = "Overdue";
            break;
        case "UnableToCharge" :
            field_accountPaymentState = "UnableToCharge";
            break;
        default:
          field_accountPaymentState = Js_exn.raiseError("graphql_ppx: Unknown enum variant for AccountPaymentState: " + value$124);
      }
    } else {
      field_accountPaymentState = Js_exn.raiseError("graphql_ppx: Expected enum value for AccountPaymentState, got " + JSON.stringify(value$123));
    }
  } else {
    field_accountPaymentState = Js_exn.raiseError("graphql_ppx: Field accountPaymentState on type User is missing");
  }
  var value$125 = Js_dict.get(value$2, "cancelSubscriptionAtPeriodEnd");
  var field_cancelSubscriptionAtPeriodEnd;
  if (value$125 !== undefined) {
    var value$126 = Caml_option.valFromOption(value$125);
    var match$35 = Js_json.decodeNull(value$126);
    if (match$35 !== undefined) {
      field_cancelSubscriptionAtPeriodEnd = undefined;
    } else {
      var value$127 = Js_json.decodeBoolean(value$126);
      field_cancelSubscriptionAtPeriodEnd = value$127 !== undefined ? value$127 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$126));
    }
  } else {
    field_cancelSubscriptionAtPeriodEnd = undefined;
  }
  var value$128 = Js_dict.get(value$2, "cancelSubscriptionAt");
  var field_cancelSubscriptionAt;
  if (value$128 !== undefined) {
    var value$129 = Caml_option.valFromOption(value$128);
    var match$36 = Js_json.decodeNull(value$129);
    field_cancelSubscriptionAt = match$36 !== undefined ? undefined : Caml_option.some(value$129);
  } else {
    field_cancelSubscriptionAt = undefined;
  }
  var value$130 = Js_dict.get(value$2, "hasEverSubscribed");
  var field_hasEverSubscribed;
  if (value$130 !== undefined) {
    var value$131 = Caml_option.valFromOption(value$130);
    var value$132 = Js_json.decodeBoolean(value$131);
    field_hasEverSubscribed = value$132 !== undefined ? value$132 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$131));
  } else {
    field_hasEverSubscribed = Js_exn.raiseError("graphql_ppx: Field hasEverSubscribed on type User is missing");
  }
  var value$133 = Js_dict.get(value$2, "enabledFeatures");
  var field_enabledFeatures = value$133 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$133))).map(function (value) {
          var value$1 = Js_json.decodeObject(value);
          if (value$1 === undefined) {
            return Js_exn.raiseError("graphql_ppx: Expected object of type UserFeature, got " + JSON.stringify(value));
          }
          var value$2 = Caml_option.valFromOption(value$1);
          var value$3 = Js_dict.get(value$2, "uuid");
          var field_uuid;
          if (value$3 !== undefined) {
            var value$4 = Caml_option.valFromOption(value$3);
            var value$5 = Js_json.decodeString(value$4);
            field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
          } else {
            field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type UserFeature is missing");
          }
          var value$6 = Js_dict.get(value$2, "name");
          var field_name;
          if (value$6 !== undefined) {
            var value$7 = Caml_option.valFromOption(value$6);
            var value$8 = Js_json.decodeString(value$7);
            if (value$8 !== undefined) {
              switch (value$8) {
                case "All" :
                    field_name = "All";
                    break;
                case "AppBackups" :
                    field_name = "AppBackups";
                    break;
                case "BlockPublishing" :
                    field_name = "BlockPublishing";
                    break;
                case "ConditionalNavigation" :
                    field_name = "ConditionalNavigation";
                    break;
                case "CustomAppCode" :
                    field_name = "CustomAppCode";
                    break;
                case "CustomComponentDefinitions" :
                    field_name = "CustomComponentDefinitions";
                    break;
                case "DrawerNavigator" :
                    field_name = "DrawerNavigator";
                    break;
                case "ExternalBlockVariables" :
                    field_name = "ExternalBlockVariables";
                    break;
                case "ImportExportActions" :
                    field_name = "ImportExportActions";
                    break;
                case "ImportOpenApi" :
                    field_name = "ImportOpenApi";
                    break;
                case "Integrations" :
                    field_name = "Integrations";
                    break;
                case "None" :
                    field_name = "None";
                    break;
                case "ScreenWizard" :
                    field_name = "ScreenWizard";
                    break;
                case "Segment" :
                    field_name = "Segment";
                    break;
                case "Themes" :
                    field_name = "Themes";
                    break;
                case "ViewBuildLogs" :
                    field_name = "ViewBuildLogs";
                    break;
                default:
                  field_name = Js_exn.raiseError("graphql_ppx: Unknown enum variant for FeatureName: " + value$8);
              }
            } else {
              field_name = Js_exn.raiseError("graphql_ppx: Expected enum value for FeatureName, got " + JSON.stringify(value$7));
            }
          } else {
            field_name = Js_exn.raiseError("graphql_ppx: Field name on type UserFeature is missing");
          }
          return {
                  uuid: field_uuid,
                  name: field_name
                };
        }) : Js_exn.raiseError("graphql_ppx: Field enabledFeatures on type User is missing");
  var value$134 = Js_dict.get(value$2, "billingPlan");
  var field_billingPlan = value$134 !== undefined ? Curry._1(BillingPlan$DraftbitBuilder.GQL.SimpleFragment.SimpleBillingPlanFields.parse, Caml_option.valFromOption(value$134)) : Js_exn.raiseError("graphql_ppx: Field billingPlan on type User is missing");
  var value$135 = Js_dict.get(value$2, "expoAccounts");
  var field_expoAccounts = value$135 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$135))).map(function (value) {
          return Curry._1(ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field expoAccounts on type User is missing");
  var value$136 = Js_dict.get(value$2, "numberOfUniqueCollaborators");
  var field_numberOfUniqueCollaborators;
  if (value$136 !== undefined) {
    var value$137 = Caml_option.valFromOption(value$136);
    var value$138 = Js_json.decodeNumber(value$137);
    field_numberOfUniqueCollaborators = value$138 !== undefined ? value$138 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$137));
  } else {
    field_numberOfUniqueCollaborators = Js_exn.raiseError("graphql_ppx: Field numberOfUniqueCollaborators on type User is missing");
  }
  var value$139 = Js_dict.get(value$2, "numberOfAppStateChangesToLivePast30Days");
  var field_numberOfAppStateChangesToLivePast30Days;
  if (value$139 !== undefined) {
    var value$140 = Caml_option.valFromOption(value$139);
    var value$141 = Js_json.decodeNumber(value$140);
    field_numberOfAppStateChangesToLivePast30Days = value$141 !== undefined ? value$141 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$140));
  } else {
    field_numberOfAppStateChangesToLivePast30Days = Js_exn.raiseError("graphql_ppx: Field numberOfAppStateChangesToLivePast30Days on type User is missing");
  }
  var value$142 = Js_dict.get(value$2, "signupAttempts");
  var field_signupAttempts;
  if (value$142 !== undefined) {
    var value$143 = Caml_option.valFromOption(value$142);
    var match$37 = Js_json.decodeNull(value$143);
    if (match$37 !== undefined) {
      field_signupAttempts = undefined;
    } else {
      var value$144 = Js_json.decodeNumber(value$143);
      field_signupAttempts = value$144 !== undefined ? value$144 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$143));
    }
  } else {
    field_signupAttempts = undefined;
  }
  var value$145 = Js_dict.get(value$2, "failedLoginAttempts");
  var field_failedLoginAttempts;
  if (value$145 !== undefined) {
    var value$146 = Caml_option.valFromOption(value$145);
    var match$38 = Js_json.decodeNull(value$146);
    if (match$38 !== undefined) {
      field_failedLoginAttempts = undefined;
    } else {
      var value$147 = Js_json.decodeNumber(value$146);
      field_failedLoginAttempts = value$147 !== undefined ? value$147 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$146));
    }
  } else {
    field_failedLoginAttempts = undefined;
  }
  var value$148 = Js_dict.get(value$2, "lastFailedLogin");
  var field_lastFailedLogin;
  if (value$148 !== undefined) {
    var value$149 = Caml_option.valFromOption(value$148);
    var match$39 = Js_json.decodeNull(value$149);
    field_lastFailedLogin = match$39 !== undefined ? undefined : Caml_option.some(value$149);
  } else {
    field_lastFailedLogin = undefined;
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          isAdmin: field_isAdmin,
          email: field_email,
          deviceId: field_deviceId,
          firstName: field_firstName,
          lastName: field_lastName,
          createdAt: field_createdAt,
          lastActive: field_lastActive,
          ipAddress: field_ipAddress,
          registeredAt: field_registeredAt,
          signedUpAt: field_signedUpAt,
          emailVerifiedAt: field_emailVerifiedAt,
          deletedAt: field_deletedAt,
          imageUrl: field_imageUrl,
          grantedAccess: field_grantedAccess,
          hasUsedLivePreview: field_hasUsedLivePreview,
          stripeCustomerId: field_stripeCustomerId,
          leadSource: field_leadSource,
          leadUtmSource: field_leadUtmSource,
          leadUtmMedium: field_leadUtmMedium,
          leadUtmCampaign: field_leadUtmCampaign,
          leadUtmTerm: field_leadUtmTerm,
          leadUtmContent: field_leadUtmContent,
          leadInitialReferrer: field_leadInitialReferrer,
          leadLastReferrer: field_leadLastReferrer,
          leadInitialLandingPage: field_leadInitialLandingPage,
          leadPartnerStackGspk: field_leadPartnerStackGspk,
          leadPartnerStackGsxid: field_leadPartnerStackGsxid,
          leadPartnerStackPkey: field_leadPartnerStackPkey,
          leadPartnerStackCustomerKey: field_leadPartnerStackCustomerKey,
          onboardingDraftbitUsage: field_onboardingDraftbitUsage,
          onboardingPersona: field_onboardingPersona,
          onboardingBackground: field_onboardingBackground,
          onboardingAppData: field_onboardingAppData,
          onboardingExperience: field_onboardingExperience,
          onboardingCompanyType: field_onboardingCompanyType,
          onboardingCompanyRole: field_onboardingCompanyRole,
          onboardingCompanySize: field_onboardingCompanySize,
          onboardingUseCases: field_onboardingUseCases,
          onboardingReactNative: field_onboardingReactNative,
          onboardingReferralSource: field_onboardingReferralSource,
          accountPaymentState: field_accountPaymentState,
          cancelSubscriptionAtPeriodEnd: field_cancelSubscriptionAtPeriodEnd,
          cancelSubscriptionAt: field_cancelSubscriptionAt,
          hasEverSubscribed: field_hasEverSubscribed,
          billingPlan: field_billingPlan,
          enabledFeatures: field_enabledFeatures,
          numberOfUniqueCollaborators: field_numberOfUniqueCollaborators,
          numberOfAppStateChangesToLivePast30Days: field_numberOfAppStateChangesToLivePast30Days,
          expoAccounts: field_expoAccounts,
          signupAttempts: field_signupAttempts,
          failedLoginAttempts: field_failedLoginAttempts,
          lastFailedLogin: field_lastFailedLogin
        };
}

var name = "UserFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var UserFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var UserFragment = {
  UserFields: UserFields
};

var ppx_printed_query$1 = "mutation SignupUser($input: SignupUserInput!)  {\n__typename\nsignupUser(input: $input)  {\n__typename\nuser  {\n__typename\nuuid  \naccountPaymentState  \n}\n\ntoken  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "signupUser");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "user");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$4)));
      var value$6 = Js_dict.get(value$5, "uuid");
      var tmp$2;
      if (value$6 !== undefined) {
        var value$7 = Caml_option.valFromOption(value$6);
        var value$8 = Js_json.decodeString(value$7);
        tmp$2 = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
      }
      var value$9 = Js_dict.get(value$5, "accountPaymentState");
      var tmp$3;
      if (value$9 !== undefined) {
        var value$10 = Caml_option.valFromOption(value$9);
        var value$11 = Js_json.decodeString(value$10);
        if (value$11 !== undefined) {
          switch (value$11) {
            case "Active" :
                tmp$3 = "Active";
                break;
            case "Free" :
                tmp$3 = "Free";
                break;
            case "Overdue" :
                tmp$3 = "Overdue";
                break;
            case "UnableToCharge" :
                tmp$3 = "UnableToCharge";
                break;
            default:
              tmp$3 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for AccountPaymentState: " + value$11);
          }
        } else {
          tmp$3 = Js_exn.raiseError("graphql_ppx: Expected enum value for AccountPaymentState, got " + JSON.stringify(value$10));
        }
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field accountPaymentState on type User is missing");
      }
      tmp$1 = {
        uuid: tmp$2,
        accountPaymentState: tmp$3
      };
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field user on type SignupUserPayload is missing");
    }
    var value$12 = Js_dict.get(value$3, "token");
    var tmp$4;
    if (value$12 !== undefined) {
      var value$13 = Caml_option.valFromOption(value$12);
      var match = Js_json.decodeNull(value$13);
      if (match !== undefined) {
        tmp$4 = undefined;
      } else {
        var value$14 = Js_json.decodeString(value$13);
        tmp$4 = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
      }
    } else {
      tmp$4 = undefined;
    }
    tmp = {
      user: tmp$1,
      token: tmp$4
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field signupUser on type Mutation is missing");
  }
  return {
          signupUser: tmp
        };
}

function json_of_SignupUserInput(value) {
  var v = value.invitationUuid;
  return Js_dict.fromArray([
                [
                  "email",
                  value.email
                ],
                [
                  "firstName",
                  value.firstName
                ],
                [
                  "invitationUuid",
                  v !== undefined ? v : null
                ],
                [
                  "lastName",
                  value.lastName
                ],
                [
                  "password",
                  value.password
                ],
                [
                  "turnstileToken",
                  value.turnstileToken
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(input, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_SignupUserInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_SignupUserInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_SignupUserInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_SignupUserInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function useMutation(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition);
  var mutation = match[0];
  return function (email, password, firstName, lastName, turnstileToken, onData, onError) {
    return PromiseUtils$DraftbitBuilder.ignore(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables({
                                  email: email,
                                  firstName: firstName,
                                  lastName: lastName,
                                  password: password,
                                  invitationUuid: undefined,
                                  turnstileToken: turnstileToken
                                }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (param) {
                        var result = param[0];
                        if (typeof result === "number") {
                          return ;
                        }
                        if (result.TAG !== /* Data */0) {
                          return ;
                        }
                        var data = result._0;
                        var uuid = data.signupUser.user.uuid;
                        var properties = Js_dict.fromArray([
                              [
                                "uuid",
                                uuid
                              ],
                              [
                                "email",
                                email
                              ],
                              [
                                "firstName",
                                firstName
                              ],
                              [
                                "lastName",
                                lastName
                              ],
                              [
                                "first_name",
                                firstName
                              ],
                              [
                                "last_name",
                                lastName
                              ]
                            ]);
                        var user = JSON.stringify(Json_encode.object_({
                                  hd: [
                                    "uuid",
                                    uuid
                                  ],
                                  tl: {
                                    hd: [
                                      "firstName",
                                      firstName
                                    ],
                                    tl: {
                                      hd: [
                                        "lastName",
                                        lastName
                                      ],
                                      tl: {
                                        hd: [
                                          "email",
                                          email
                                        ],
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }));
                        localStorage.setItem("user", user);
                        Belt_Option.forEach(data.signupUser.token, (function (token) {
                                localStorage.setItem("token", token);
                                
                              }));
                        Analytics$DraftbitBuilder.identify(uuid, properties);
                        Analytics$DraftbitBuilder.trackWithProperties("Registered Onboarding", properties);
                        return Curry._1(onData, data);
                      })).catch(function (error) {
                    return Curry._1(onError, Belt_Option.getWithDefault(ApolloUtils$DraftbitBuilder.extractErrorMessage(error), "An unknown error occurred"));
                  }));
  };
}

var Signup = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_SignupUserInput: json_of_SignupUserInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  useMutation: useMutation
};

var ppx_printed_query$2 = "mutation JoinWorkspace($workspaceUuid: String!)  {\n__typename\njoinWorkspace(workspaceUuid: $workspaceUuid)  {\n__typename\nuuid  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "joinWorkspace");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type Workspace is missing");
    }
    tmp = {
      uuid: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field joinWorkspace on type Mutation is missing");
  }
  return {
          joinWorkspace: tmp
        };
}

function make$1(workspaceUuid, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "workspaceUuid",
                    workspaceUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$1(variables) {
  var workspaceUuid = variables.workspaceUuid;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "workspaceUuid",
                    workspaceUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$1(workspaceUuid, param) {
  return Js_dict.fromArray([[
                  "workspaceUuid",
                  workspaceUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, workspaceUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "workspaceUuid",
                      workspaceUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$1
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

function useMutation$1(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$1);
  var mutation = match[0];
  return function (workspaceUuid, onData) {
    ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$1(workspaceUuid, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                return prim[0];
              })), "Workspace joined", undefined, undefined, undefined, undefined, onData, undefined, "Error joining workspace", undefined, undefined, undefined, undefined);
    
  };
}

var JoinWorkspace = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2,
  useMutation: useMutation$1
};

var ppx_printed_query$3 = "mutation LeaveWorkspace($workspaceUuid: String!)  {\n__typename\nleaveWorkspace(workspaceUuid: $workspaceUuid)  {\n__typename\nuuid  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "leaveWorkspace");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
    }
    tmp = {
      uuid: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field leaveWorkspace on type Mutation is missing");
  }
  return {
          leaveWorkspace: tmp
        };
}

function make$2(workspaceUuid, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "workspaceUuid",
                    workspaceUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$2(variables) {
  var workspaceUuid = variables.workspaceUuid;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "workspaceUuid",
                    workspaceUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$2(workspaceUuid, param) {
  return Js_dict.fromArray([[
                  "workspaceUuid",
                  workspaceUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, workspaceUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "workspaceUuid",
                      workspaceUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$2
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function useMutation$2(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, (function (param) {
          return [SimpleWorkspace$DraftbitBuilder.GQL.refetchList];
        }), undefined, undefined, undefined, undefined, undefined, definition$2);
  var mutation = match[0];
  return function (workspaceUuid, onData) {
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$2(workspaceUuid, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), "You're no longer a member of this workspace", undefined, undefined, undefined, undefined, onData, undefined, "Error leaving workspace", undefined, undefined, undefined, undefined);
  };
}

var LeaveWorkspace = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  useMutation: useMutation$2
};

var ppx_printed_query$4 = "query User  {\n__typename\nuser  {\n__typename\n...UserFields   \n}\n\n}\n" + ppx_printed_query;

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "user");
  return {
          user: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field user on type Query is missing")
        };
}

function make$3(param) {
  return {
          query: ppx_printed_query$4,
          variables: null,
          parse: parse$4
        };
}

function makeWithVariables$3(param) {
  return {
          query: ppx_printed_query$4,
          variables: null,
          parse: parse$4
        };
}

function makeVariables$3(param) {
  return null;
}

function definition_2$3(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$3 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$3
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

function useQuery(fetchPolicy, param) {
  var match = Router$DraftbitBuilder.getParams(undefined);
  var refreshToken = Curry._2(Session$DraftbitBuilder.GQL.RefreshToken.useMutation, match.appUuid, undefined);
  var match$1 = ApolloHooks$DraftbitBuilder.useQuery(undefined, undefined, undefined, fetchPolicy, undefined, undefined, undefined, undefined, definition$3);
  return ApolloUtils$DraftbitBuilder.Query.tapError(ApolloUtils$DraftbitBuilder.Query.map(match$1[0], (function (data) {
                    return data.user;
                  })), (function (error) {
                if (error.message.includes("Not Authenticated")) {
                  return Curry._2(refreshToken, Utils$DraftbitBuilder.noop, Utils$DraftbitBuilder.noop);
                }
                
              }));
}

var refetch = ApolloHooks$DraftbitBuilder.toQueryObj(make$3(undefined));

var Get = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4,
  useQuery: useQuery,
  refetch: refetch
};

function useQuery$1(param) {
  var userQuery = useQuery(undefined, undefined);
  return Belt_Option.mapWithDefault(ApolloUtils$DraftbitBuilder.Query.data(userQuery), false, (function (user) {
                return user.isAdmin;
              }));
}

function useIsAdmin(param) {
  var match = Hooks$DraftbitBuilder.$$Storage.useBoolStorage("disableAdmin", false);
  var userQuery = useQuery(undefined, undefined);
  var userIsAdmin = Belt_Option.mapWithDefault(ApolloUtils$DraftbitBuilder.Query.data(userQuery), false, (function (user) {
          return user.isAdmin;
        }));
  var isAdmin = userIsAdmin && !match[0];
  return [
          isAdmin,
          match[1]
        ];
}

var IsAdmin = {
  useQuery: useQuery$1,
  useIsAdmin: useIsAdmin
};

var ppx_printed_query$5 = "mutation UpdateUser($input: UpdateUserInput!)  {\n__typename\nupdateUser(input: $input)  {\n__typename\n...UserFields   \n}\n\n}\n" + ppx_printed_query;

function parse$5(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateUser");
  return {
          updateUser: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field updateUser on type Mutation is missing")
        };
}

function json_of_UpdateUserInput(value) {
  var v = value.deviceId;
  var v$1 = value.email;
  var v$2 = value.firstName;
  var v$3 = value.hasUsedLivePreview;
  var v$4 = value.imageUrl;
  var v$5 = value.lastName;
  var v$6 = value.onboardingAppData;
  var v$7 = value.onboardingBackground;
  var v$8 = value.onboardingCompanyRole;
  var v$9 = value.onboardingCompanySize;
  var v$10 = value.onboardingCompanyType;
  var v$11 = value.onboardingDraftbitUsage;
  var v$12 = value.onboardingExperience;
  var v$13 = value.onboardingPersona;
  var v$14 = value.onboardingReactNative;
  var v$15 = value.onboardingReferralSource;
  var v$16 = value.onboardingUseCases;
  var v$17 = value.shouldUpdateBillingEmail;
  return Js_dict.fromArray([
                [
                  "deviceId",
                  v !== undefined ? v : null
                ],
                [
                  "email",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "firstName",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "hasUsedLivePreview",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "imageUrl",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "lastName",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "onboardingAppData",
                  v$6 !== undefined ? v$6 : null
                ],
                [
                  "onboardingBackground",
                  v$7 !== undefined ? v$7 : null
                ],
                [
                  "onboardingCompanyRole",
                  v$8 !== undefined ? v$8 : null
                ],
                [
                  "onboardingCompanySize",
                  v$9 !== undefined ? v$9 : null
                ],
                [
                  "onboardingCompanyType",
                  v$10 !== undefined ? v$10 : null
                ],
                [
                  "onboardingDraftbitUsage",
                  v$11 !== undefined ? v$11 : null
                ],
                [
                  "onboardingExperience",
                  v$12 !== undefined ? v$12 : null
                ],
                [
                  "onboardingPersona",
                  v$13 !== undefined ? v$13 : null
                ],
                [
                  "onboardingReactNative",
                  v$14 !== undefined ? v$14 : null
                ],
                [
                  "onboardingReferralSource",
                  v$15 !== undefined ? v$15 : null
                ],
                [
                  "onboardingUseCases",
                  v$16 !== undefined ? v$16 : null
                ],
                [
                  "shouldUpdateBillingEmail",
                  v$17 !== undefined ? v$17 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$4(input, param) {
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateUserInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeWithVariables$4(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateUserInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeVariables$4(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_UpdateUserInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_UpdateUserInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$5,
  ppx_printed_query$5,
  definition_2$4
];

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

function useMutation$3(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$4);
  var mutation = match[0];
  return function (email, shouldUpdateBillingEmail, firstName, lastName, deviceId, hasUsedLivePreview, imageUrl, onboardingBackground, onboardingAppData, onboardingCompanySize, onboardingCompanyRole, onboardingCompanyType, onboardingExperience, onboardingDraftbitUsage, onboardingPersona, onboardingUseCases, onboardingReactNative, onboardingReferralSource, notificationSuccessMessage, analyticsMessage, onDataOpt, param) {
    var onData = onDataOpt !== undefined ? onDataOpt : Utils$DraftbitBuilder.noop;
    var input = {
      email: email,
      shouldUpdateBillingEmail: shouldUpdateBillingEmail,
      firstName: firstName,
      lastName: lastName,
      deviceId: deviceId,
      hasUsedLivePreview: hasUsedLivePreview,
      imageUrl: imageUrl,
      onboardingBackground: onboardingBackground,
      onboardingAppData: onboardingAppData,
      onboardingDraftbitUsage: onboardingDraftbitUsage,
      onboardingCompanySize: onboardingCompanySize,
      onboardingCompanyRole: onboardingCompanyRole,
      onboardingCompanyType: onboardingCompanyType,
      onboardingExperience: onboardingExperience,
      onboardingPersona: onboardingPersona,
      onboardingUseCases: onboardingUseCases,
      onboardingReactNative: onboardingReactNative,
      onboardingReferralSource: onboardingReferralSource
    };
    ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$4(input, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                return prim[0];
              })), notificationSuccessMessage, undefined, undefined, undefined, Caml_option.some(input), onData, undefined, undefined, Belt_Option.getWithDefault(analyticsMessage, "Updated Profile"), undefined, undefined, undefined);
    
  };
}

var Update = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  json_of_UpdateUserInput: json_of_UpdateUserInput,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5,
  useMutation: useMutation$3
};

var ppx_printed_query$6 = "mutation UpdateUserPassword($input: UpdateUserPasswordInput!)  {\n__typename\nupdateUserPassword(input: $input)  {\n__typename\nuuid  \n}\n\n}\n";

function parse$6(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateUserPassword");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
    }
    tmp = {
      uuid: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field updateUserPassword on type Mutation is missing");
  }
  return {
          updateUserPassword: tmp
        };
}

function json_of_UpdateUserPasswordInput(value) {
  return Js_dict.fromArray([
                [
                  "currentPassword",
                  value.currentPassword
                ],
                [
                  "newPassword",
                  value.newPassword
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$5(input, param) {
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateUserPasswordInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeWithVariables$5(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateUserPasswordInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeVariables$5(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_UpdateUserPasswordInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$5(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_UpdateUserPasswordInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$5 = [
  parse$6,
  ppx_printed_query$6,
  definition_2$5
];

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

function useMutation$4(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$5);
  var mutation = match[0];
  return function (currentPassword, newPassword, confirmPassword) {
    ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$5({
                          currentPassword: currentPassword,
                          newPassword: newPassword,
                          confirmPassword: confirmPassword
                        }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                return prim[0];
              })), "Your password was updated", undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Updated Password", undefined, undefined, undefined);
    
  };
}

var UpdatePassword = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  json_of_UpdateUserPasswordInput: json_of_UpdateUserPasswordInput,
  make: make$5,
  makeWithVariables: makeWithVariables$5,
  makeVariables: makeVariables$5,
  definition: definition$5,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6,
  useMutation: useMutation$4
};

var ppx_printed_query$7 = "mutation GenerateCircleSSOCode  {\n__typename\ngenerateCircleSSOCode  {\n__typename\ncode  \n}\n\n}\n";

function parse$7(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "generateCircleSSOCode");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "code");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field code on type GenerateCircleSSOCodePayload is missing");
    }
    tmp = {
      code: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field generateCircleSSOCode on type Mutation is missing");
  }
  return {
          generateCircleSSOCode: tmp
        };
}

function make$6(param) {
  return {
          query: ppx_printed_query$7,
          variables: null,
          parse: parse$7
        };
}

function makeWithVariables$6(param) {
  return {
          query: ppx_printed_query$7,
          variables: null,
          parse: parse$7
        };
}

function makeVariables$6(param) {
  return null;
}

function definition_2$6(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$6 = [
  parse$7,
  ppx_printed_query$7,
  definition_2$6
];

function ret_type$7(f) {
  return {};
}

var MT_Ret$7 = {};

function useMutation$5(param) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var params = QueryString$DraftbitBuilder.parse(url.search);
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$6);
  var mutation = match[0];
  return function (param) {
    PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (param) {
            var result = param[0];
            if (typeof result === "number") {
              return ;
            }
            if (result.TAG !== /* Data */0) {
              return ;
            }
            var circleUrl = StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "redirect_uri")) + ("?code=" + result._0.generateCircleSSOCode.code);
            window.location.href = circleUrl;
            
          }));
    
  };
}

var GenerateCircleSSOCode = {
  ppx_printed_query: ppx_printed_query$7,
  query: ppx_printed_query$7,
  parse: parse$7,
  make: make$6,
  makeWithVariables: makeWithVariables$6,
  makeVariables: makeVariables$6,
  definition: definition$6,
  ret_type: ret_type$7,
  MT_Ret: MT_Ret$7,
  useMutation: useMutation$5
};

var ppx_printed_query$8 = "mutation CancelSubscription($input: CancelSubscriptionInput!)  {\n__typename\ncancelSubscription(input: $input)  {\n__typename\nuuid  \ncancelSubscriptionAtPeriodEnd  \n}\n\n}\n";

function parse$8(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "cancelSubscription");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
    }
    var value$7 = Js_dict.get(value$3, "cancelSubscriptionAtPeriodEnd");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var match = Js_json.decodeNull(value$8);
      if (match !== undefined) {
        tmp$2 = undefined;
      } else {
        var value$9 = Js_json.decodeBoolean(value$8);
        tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$8));
      }
    } else {
      tmp$2 = undefined;
    }
    tmp = {
      uuid: tmp$1,
      cancelSubscriptionAtPeriodEnd: tmp$2
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field cancelSubscription on type Mutation is missing");
  }
  return {
          cancelSubscription: tmp
        };
}

function json_of_CancelSubscriptionInput(value) {
  return Js_dict.fromArray([
                [
                  "comments",
                  value.comments
                ],
                [
                  "reason",
                  value.reason
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$7(input, param) {
  return {
          query: ppx_printed_query$8,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CancelSubscriptionInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$8
        };
}

function makeWithVariables$7(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$8,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CancelSubscriptionInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$8
        };
}

function makeVariables$7(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_CancelSubscriptionInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$7(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_CancelSubscriptionInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$7 = [
  parse$8,
  ppx_printed_query$8,
  definition_2$7
];

function ret_type$8(f) {
  return {};
}

var MT_Ret$8 = {};

function useMutation$6(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$7);
  var mutation = match[0];
  return function (reason, comments, onData, onError) {
    return PromiseUtils$DraftbitBuilder.ignore(ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$7({
                                    reason: reason,
                                    comments: comments
                                  }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                          return prim[0];
                        })), "Subscription canceled", undefined, undefined, undefined, undefined, onData, onError, "An error occurred while canceling subscription", undefined, undefined, undefined, undefined));
  };
}

var CancelSubscription = {
  ppx_printed_query: ppx_printed_query$8,
  query: ppx_printed_query$8,
  parse: parse$8,
  json_of_CancelSubscriptionInput: json_of_CancelSubscriptionInput,
  make: make$7,
  makeWithVariables: makeWithVariables$7,
  makeVariables: makeVariables$7,
  definition: definition$7,
  ret_type: ret_type$8,
  MT_Ret: MT_Ret$8,
  useMutation: useMutation$6
};

var ppx_printed_query$9 = "mutation SignOutAllSessions  {\n__typename\nsignOutAllSessions  {\n__typename\nsuccess  \n}\n\n}\n";

function parse$9(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "signOutAllSessions");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeObject(value$3);
    if (value$4 !== undefined) {
      var value$5 = Js_dict.get(Caml_option.valFromOption(value$4), "success");
      var field_success;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeBoolean(value$6);
        field_success = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$6));
      } else {
        field_success = Js_exn.raiseError("graphql_ppx: Field success on type SignOutAllSessionsPayload is missing");
      }
      tmp = {
        success: field_success
      };
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected object of type SignOutAllSessionsPayload, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field signOutAllSessions on type Mutation is missing");
  }
  return {
          signOutAllSessions: tmp
        };
}

function make$8(param) {
  return {
          query: ppx_printed_query$9,
          variables: null,
          parse: parse$9
        };
}

function makeWithVariables$8(param) {
  return {
          query: ppx_printed_query$9,
          variables: null,
          parse: parse$9
        };
}

function makeVariables$8(param) {
  return null;
}

function definition_2$8(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$8 = [
  parse$9,
  ppx_printed_query$9,
  definition_2$8
];

function ret_type$9(f) {
  return {};
}

var MT_Ret$9 = {};

function useMutation$7(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$8);
  var mutation = match[0];
  return function (onData) {
    ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                return prim[0];
              })), undefined, undefined, undefined, undefined, undefined, onData, undefined, "An error happened while signing out all sessions", undefined, undefined, undefined, undefined);
    
  };
}

var SignOutAllSessions = {
  ppx_printed_query: ppx_printed_query$9,
  query: ppx_printed_query$9,
  parse: parse$9,
  make: make$8,
  makeWithVariables: makeWithVariables$8,
  makeVariables: makeVariables$8,
  definition: definition$8,
  ret_type: ret_type$9,
  MT_Ret: MT_Ret$9,
  useMutation: useMutation$7
};

var GQL = {
  UserFragment: UserFragment,
  Signup: Signup,
  JoinWorkspace: JoinWorkspace,
  LeaveWorkspace: LeaveWorkspace,
  Get: Get,
  IsAdmin: IsAdmin,
  Update: Update,
  UpdatePassword: UpdatePassword,
  GenerateCircleSSOCode: GenerateCircleSSOCode,
  CancelSubscription: CancelSubscription,
  SignOutAllSessions: SignOutAllSessions
};

export {
  accountPaymentStateFromJs ,
  accountPaymentStateToJs ,
  feature_encode ,
  feature_decode ,
  toSimpleFeature ,
  toSimple ,
  fullName ,
  fullNameWithEmail ,
  GQL ,
  
}
/* refetch Not a pure module */
