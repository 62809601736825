// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Spread$DraftbitBuilder from "./Spread.bs.js";

function DataCyWrapper(Props) {
  var children = Props.children;
  var name = Props.name;
  var addIdOpt = Props.addId;
  var addId = addIdOpt !== undefined ? addIdOpt : false;
  return React.createElement(Spread$DraftbitBuilder.make, {
              props: addId ? ({
                    "data-cy": name,
                    id: name
                  }) : ({
                    "data-cy": name
                  }),
              children: children
            });
}

var make = DataCyWrapper;

export {
  make ,
  
}
/* react Not a pure module */
