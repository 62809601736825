// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";

var _map = {"CustomComponentDefinitions":"CustomComponentDefinitions","ImportExportActions":"ImportExportActions","ImportOpenApi":"ImportOpenApi","Segment":"Segment","All":"All","ExternalBlockVariables":"ExternalBlockVariables","CustomAppCode":"CustomAppCode","ConditionalNavigation":"ConditionalNavigation","AppBackups":"AppBackups","ScreenWizard":"ScreenWizard","BlockPublishing":"BlockPublishing","Integrations":"Integrations","DrawerNavigator":"DrawerNavigator","ViewBuildLogs":"ViewBuildLogs","Themes":"Themes","None":"None"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function featureFromString(str) {
  return Belt_Option.getWithDefault(tFromJs(str), "None");
}

function t_encode(f) {
  return f;
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("Feature.t", tFromJs, param);
}

var allFeatures = [
  "All",
  "CustomComponentDefinitions",
  "ImportExportActions",
  "ImportOpenApi",
  "Segment",
  "CustomAppCode",
  "ExternalBlockVariables",
  "ConditionalNavigation",
  "AppBackups",
  "ScreenWizard",
  "BlockPublishing",
  "Integrations",
  "DrawerNavigator",
  "ViewBuildLogs",
  "Themes",
  "None"
];

var allValidFeatures = allFeatures.filter(function (feature) {
      return feature !== "None";
    });

var _map$1 = {"AllUsers":"AllUsers","AdminOnly":"AdminOnly","DraftbitOnly":"DraftbitOnly"};

function featureScopeToJs(param) {
  return param;
}

function featureScopeFromJs(param) {
  return _map$1[param];
}

var allFeatureScopes = [
  "AllUsers",
  "AdminOnly",
  "DraftbitOnly"
];

function scopeToLabel(scope) {
  if (scope === "DraftbitOnly") {
    return "For @draftbit.com users";
  } else if (scope === "AdminOnly") {
    return "For admins only";
  } else {
    return "For all users";
  }
}

var featureToString = tToJs;

export {
  tToJs ,
  tFromJs ,
  featureToString ,
  featureFromString ,
  t_encode ,
  t_decode ,
  allFeatures ,
  allValidFeatures ,
  featureScopeToJs ,
  featureScopeFromJs ,
  allFeatureScopes ,
  scopeToLabel ,
  
}
/* allValidFeatures Not a pure module */
