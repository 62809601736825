import * as React from "react";
import Modal from "react-modal";
import { Router, Route, Switch, Redirect } from "Router";
import { make as LoadingSpinner } from "components/LoadingSpinner.bs";
import PostCheckoutTokenRefresher from "screens/Auth/PostCheckoutTokenRefresher";
import { make as AppRedirectToFirstScreen } from "./AppRedirectToFirstScreen.bs";
import { make as MaintenancePage } from "./MaintenancePage.bs";
import { QueryClient, QueryClientProvider } from "react-query";
import OutdatedFrontendVersionBanner from "OutdatedFrontendVersionBanner";
import ErrorBoundary from "components/ErrorBoundary";
import * as Sentry from "@sentry/react";

const { REACT_APP_MAINTENANCE_PAGE } = process.env;

// Fixes accessibility support with react-modal
Modal.setAppElement("#root");

const OnboardingRouter = React.lazy(() =>
  import("screens/Onboarding/OnboardingRouter.bs")
);

const DashboardRouter = React.lazy(() =>
  import("screens/Dashboard/DashboardRouter.bs")
);

const AuthRouter = React.lazy(() => import("screens/Auth/AuthRouter"));
const AdminFlowRoutes = React.lazy(() => import("./admin/AdminFlowRoutes"));
const ScreenFlowRoutes = React.lazy(() => import("./ScreenFlowRoutes"));
const ShareAppPage = React.lazy(() => import("screens/ShareAppPage.bs"));
const ForgotPasswordPage = React.lazy(() =>
  import("screens/Auth/ForgotPasswordPage.bs")
);
const ResetPasswordPage = React.lazy(() =>
  import("screens/Auth/ResetPasswordPage.bs")
);
const UrlListener = React.lazy(() => import("./UrlListener"));

const getToken = () => {
  return localStorage.getItem("token");
};

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = getToken();

      if (token !== null) {
        return <Component {...props} />;
      }

      const next = `${props.location.pathname}${props.location.search}`;

      return (
        <Redirect
          to={{
            pathname: "/login",
            search: `?next=${encodeURIComponent(next)}`,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const queryClient = new QueryClient();

const Apps = ({ match }) => {
  return (
    <React.Suspense
      fallback={
        <LoadingSpinner
          inline={false}
          size={36}
          label="Loading your next big idea ✨"
        />
      }
    >
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to="/dashboard" />}
        />
        <Route
          path={`${match.path}/:appUuid/screens/:screenUuid`}
          component={ScreenFlowRoutes}
        />
        <Route
          path={`${match.path}/:appUuid`}
          render={({
            match: {
              params: { appUuid },
              url,
            },
            location: { pathname },
          }) => (
            <AppRedirectToFirstScreen
              appUuid={appUuid}
              path={pathname.replace(url, "")} //remaining part of the path name without the match. Ex: The '/settings' from '/app/4y7r73/settings'
            />
          )}
        />
        <Route render={({ match }) => <Redirect to={match.url} />} />
      </Switch>
    </React.Suspense>
  );
};

class ChunkLoadErrorWrapper extends React.Component {
  componentDidCatch(error) {
    if (error.name === "ChunkLoadError") {
      window.location.reload();
    } else {
      throw error;
    }
  }

  render() {
    return this.props.children;
  }
}

function GlobalCrashShortcut() {
  const [throwError, setThrowError] = React.useState(false);

  if (throwError) {
    throw new Error("Global crash shortcut triggered");
  }

  React.useEffect(() => {
    window.crashBuilder = () => setThrowError(true);
    window.showReportDialog = () =>
      Sentry.showReportDialog({
        eventId: "f1b31437d96a47679e2f90d4357b6784",
      });
  }, []);

  return null;
}

export default function AppRouter({ history }) {
  return (
    <ChunkLoadErrorWrapper>
      <React.Suspense fallback={<LoadingSpinner inline={false} size={36} />}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <GlobalCrashShortcut />
            <OutdatedFrontendVersionBanner />
            <Router history={history}>
              {REACT_APP_MAINTENANCE_PAGE === "true" ? (
                <>
                  <Route path="/" component={MaintenancePage} />
                  <Redirect from="*" to="/" />
                </>
              ) : (
                <UrlListener>
                  <Switch>
                    <Route
                      exact
                      path={[
                        "/onboarding",
                        "/onboarding/1",
                        "/signup",
                        "/register",
                      ]}
                      render={() => <Redirect to="/onboarding/profile" />}
                    />
                    <Route
                      exact
                      path="/"
                      render={() => {
                        let route =
                          getToken() !== null ? "/dashboard" : "/login";
                        return <Redirect to={route} />;
                      }}
                    />
                    <Route
                      path="/forgot-password"
                      component={ForgotPasswordPage}
                    />
                    <Route
                      path="/reset-password"
                      component={ResetPasswordPage}
                    />
                    <Route path="/login" component={AuthRouter} />
                    <Route path="/onboarding" component={OnboardingRouter} />
                    <Route path="/share/:appUuid" component={ShareAppPage} />
                    <ProtectedRoute path="/admin" component={AdminFlowRoutes} />
                    <ProtectedRoute path="/apps" component={Apps} />
                    <ProtectedRoute
                      path={[
                        "/dashboard",
                        "/workspaces",
                        "/account",
                        "/examples",
                        "/settings",
                        "/documentation",
                        "/updates",
                        "/community",
                        "/roadmap",
                        "/starter-apps",
                        "/experts",
                        "/expert-plans",
                        "/support-services",
                        "/resources",
                        "/404",
                      ]}
                      component={DashboardRouter}
                    />
                    <ProtectedRoute
                      path="/post-checkout"
                      component={PostCheckoutTokenRefresher}
                    />
                    <Route
                      render={({ location }) => (
                        <Redirect
                          to={{
                            pathname: "/login",
                            search: `?next=${encodeURIComponent(
                              `${location.pathname}${location.search}`
                            )}`,
                            state: { from: location },
                          }}
                        />
                      )}
                    />
                  </Switch>
                </UrlListener>
              )}
            </Router>
          </QueryClientProvider>
        </ErrorBoundary>
      </React.Suspense>
    </ChunkLoadErrorWrapper>
  );
}
