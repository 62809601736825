// Generated by ReScript, PLEASE EDIT WITH CARE

import * as StringUtils$DraftbitBuilder from "../../utilities/StringUtils.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

var _map = {"Screens":"Screens","Blocks":"Blocks","Navigate":"Navigate","Preview":"Preview","Data":"Data","CustomCode":"CustomCode","Variables":"Variables","StylesAndColors":"StylesAndColors","Assets":"Assets","Integrations":"Integrations","Backups":"Backups","Settings":"Settings"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var selectionTabs = [
  "Screens",
  "Blocks",
  "Navigate",
  "Preview",
  "StylesAndColors"
];

var configTabs = [
  "Data",
  "CustomCode",
  "Variables",
  "Assets",
  "Integrations",
  "Backups",
  "Settings"
];

function toTabTitle(tabIndex) {
  if (tabIndex === "StylesAndColors") {
    return "Styles & Colors";
  } else {
    return StringUtils$DraftbitBuilder.pascalToWords(tabIndex);
  }
}

function toTabCy(tabIndex) {
  if (tabIndex === "Settings") {
    return "AppSettings";
  } else if (tabIndex === "Data") {
    return "DataServices";
  } else {
    return tabIndex;
  }
}

function toInactiveTabIcon(tabIndex) {
  if (tabIndex === "StylesAndColors") {
    return ProLightSvgIcons.faSwatchbook;
  } else if (tabIndex === "Integrations") {
    return ProLightSvgIcons.faBolt;
  } else if (tabIndex === "Blocks") {
    return ProLightSvgIcons.faCube;
  } else if (tabIndex === "Settings") {
    return ProLightSvgIcons.faCog;
  } else if (tabIndex === "Screens") {
    return ProLightSvgIcons.faPage;
  } else if (tabIndex === "Assets") {
    return ProLightSvgIcons.faImages;
  } else if (tabIndex === "Preview") {
    return ProLightSvgIcons.faEye;
  } else if (tabIndex === "CustomCode") {
    return ProLightSvgIcons.faFunction;
  } else if (tabIndex === "Navigate") {
    return ProLightSvgIcons.faSplit;
  } else if (tabIndex === "Variables") {
    return ProLightSvgIcons.faSubscript;
  } else if (tabIndex === "Data") {
    return ProLightSvgIcons.faDatabase;
  } else {
    return ProLightSvgIcons.faSave;
  }
}

function toActiveTabIcon(tabIndex) {
  if (tabIndex === "StylesAndColors") {
    return ProRegularSvgIcons.faSwatchbook;
  } else if (tabIndex === "Integrations") {
    return ProRegularSvgIcons.faBolt;
  } else if (tabIndex === "Blocks") {
    return ProRegularSvgIcons.faCube;
  } else if (tabIndex === "Settings") {
    return ProRegularSvgIcons.faCog;
  } else if (tabIndex === "Screens") {
    return ProRegularSvgIcons.faPage;
  } else if (tabIndex === "Assets") {
    return ProRegularSvgIcons.faImages;
  } else if (tabIndex === "Preview") {
    return ProRegularSvgIcons.faEye;
  } else if (tabIndex === "CustomCode") {
    return ProRegularSvgIcons.faFunction;
  } else if (tabIndex === "Navigate") {
    return ProRegularSvgIcons.faSplit;
  } else if (tabIndex === "Variables") {
    return ProRegularSvgIcons.faSubscript;
  } else if (tabIndex === "Data") {
    return ProRegularSvgIcons.faDatabase;
  } else {
    return ProRegularSvgIcons.faSave;
  }
}

function toToolTipText(tabIndex) {
  if (tabIndex === "StylesAndColors") {
    return "Manage your project's styles and colors.";
  } else if (tabIndex === "Integrations") {
    return "Integrate external tools into your project.";
  } else if (tabIndex === "Blocks") {
    return "Visually design and build your custom blocks.";
  } else if (tabIndex === "Settings") {
    return "Manage your project's settings.";
  } else if (tabIndex === "Screens") {
    return "Visually design and build your screens.";
  } else if (tabIndex === "Assets") {
    return "Manage your project's assets.";
  } else if (tabIndex === "Preview") {
    return "Preview your project on a variety of devices.";
  } else if (tabIndex === "CustomCode") {
    return "Add custom code to your project.";
  } else if (tabIndex === "Navigate") {
    return "Update navigation settings for your project.";
  } else if (tabIndex === "Variables") {
    return "Manage your project's variables.";
  } else if (tabIndex === "Data") {
    return "Manage your project's API's and Services.";
  } else {
    return "Save and restore app backups.";
  }
}

export {
  tToJs ,
  tFromJs ,
  selectionTabs ,
  configTabs ,
  toTabTitle ,
  toTabCy ,
  toInactiveTabIcon ,
  toActiveTabIcon ,
  toToolTipText ,
  
}
/* StringUtils-DraftbitBuilder Not a pure module */
