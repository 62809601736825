// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TailwindConfig from "./tailwind.config";

function unit(v) {
  switch (v) {
    case -80 :
        return {
                NAME: "rem",
                VAL: -20
              };
    case -64 :
        return {
                NAME: "rem",
                VAL: -16
              };
    case -56 :
        return {
                NAME: "rem",
                VAL: -14
              };
    case -48 :
        return {
                NAME: "rem",
                VAL: -12
              };
    case -40 :
        return {
                NAME: "rem",
                VAL: -10
              };
    case -32 :
        return {
                NAME: "rem",
                VAL: -8
              };
    case -24 :
        return {
                NAME: "rem",
                VAL: -6
              };
    case -20 :
        return {
                NAME: "rem",
                VAL: -5
              };
    case -18 :
        return {
                NAME: "rem",
                VAL: -4.5
              };
    case -16 :
        return {
                NAME: "rem",
                VAL: -4
              };
    case -14 :
        return {
                NAME: "rem",
                VAL: -3.5
              };
    case -12 :
        return {
                NAME: "rem",
                VAL: -3
              };
    case -10 :
        return {
                NAME: "rem",
                VAL: -2.5
              };
    case -8 :
        return {
                NAME: "rem",
                VAL: -2
              };
    case -6 :
        return {
                NAME: "rem",
                VAL: -1.5
              };
    case -4 :
        return {
                NAME: "rem",
                VAL: -1
              };
    case -3 :
        return {
                NAME: "rem",
                VAL: -0.5
              };
    case -2 :
        return {
                NAME: "rem",
                VAL: -0.25
              };
    case -1 :
        return {
                NAME: "px",
                VAL: -1
              };
    case 1 :
        return {
                NAME: "px",
                VAL: 1
              };
    case 2 :
        return {
                NAME: "rem",
                VAL: 0.25
              };
    case 3 :
        return {
                NAME: "rem",
                VAL: 0.5
              };
    case 4 :
        return {
                NAME: "rem",
                VAL: 1
              };
    case 6 :
        return {
                NAME: "rem",
                VAL: 1.5
              };
    case 8 :
        return {
                NAME: "rem",
                VAL: 2
              };
    case 10 :
        return {
                NAME: "rem",
                VAL: 2.5
              };
    case 12 :
        return {
                NAME: "rem",
                VAL: 3
              };
    case 14 :
        return {
                NAME: "rem",
                VAL: 3.5
              };
    case 16 :
        return {
                NAME: "rem",
                VAL: 4
              };
    case 18 :
        return {
                NAME: "rem",
                VAL: 4.5
              };
    case 20 :
        return {
                NAME: "rem",
                VAL: 5
              };
    case 24 :
        return {
                NAME: "rem",
                VAL: 6
              };
    case 32 :
        return {
                NAME: "rem",
                VAL: 8
              };
    case 40 :
        return {
                NAME: "rem",
                VAL: 10
              };
    case 48 :
        return {
                NAME: "rem",
                VAL: 12
              };
    case 56 :
        return {
                NAME: "rem",
                VAL: 14
              };
    case 64 :
        return {
                NAME: "rem",
                VAL: 16
              };
    case -79 :
    case -78 :
    case -77 :
    case -76 :
    case -75 :
    case -74 :
    case -73 :
    case -72 :
    case -71 :
    case -70 :
    case -69 :
    case -68 :
    case -67 :
    case -66 :
    case -65 :
    case -63 :
    case -62 :
    case -61 :
    case -60 :
    case -59 :
    case -58 :
    case -57 :
    case -55 :
    case -54 :
    case -53 :
    case -52 :
    case -51 :
    case -50 :
    case -49 :
    case -47 :
    case -46 :
    case -45 :
    case -44 :
    case -43 :
    case -42 :
    case -41 :
    case -39 :
    case -38 :
    case -37 :
    case -36 :
    case -35 :
    case -34 :
    case -33 :
    case -31 :
    case -30 :
    case -29 :
    case -28 :
    case -27 :
    case -26 :
    case -25 :
    case -23 :
    case -22 :
    case -21 :
    case -19 :
    case -17 :
    case -15 :
    case -13 :
    case -11 :
    case -9 :
    case -7 :
    case -5 :
    case 0 :
    case 5 :
    case 7 :
    case 9 :
    case 11 :
    case 13 :
    case 15 :
    case 17 :
    case 19 :
    case 21 :
    case 22 :
    case 23 :
    case 25 :
    case 26 :
    case 27 :
    case 28 :
    case 29 :
    case 30 :
    case 31 :
    case 33 :
    case 34 :
    case 35 :
    case 36 :
    case 37 :
    case 38 :
    case 39 :
    case 41 :
    case 42 :
    case 43 :
    case 44 :
    case 45 :
    case 46 :
    case 47 :
    case 49 :
    case 50 :
    case 51 :
    case 52 :
    case 53 :
    case 54 :
    case 55 :
    case 57 :
    case 58 :
    case 59 :
    case 60 :
    case 61 :
    case 62 :
    case 63 :
    case 65 :
    case 66 :
    case 67 :
    case 68 :
    case 69 :
    case 70 :
    case 71 :
    case 72 :
    case 73 :
    case 74 :
    case 75 :
    case 76 :
    case 77 :
    case 78 :
    case 79 :
        return "zero";
    case 80 :
        return {
                NAME: "rem",
                VAL: 20
              };
    default:
      return "zero";
  }
}

function concatFonts(fonts) {
  return fonts.map(function (f) {
                return "\"" + (f + "\"");
              }).join(", ");
}

var monoFonts = TailwindConfig.theme.fontFamily.mono;

var mono = concatFonts(monoFonts);

var sansFonts = TailwindConfig.theme.fontFamily.sans;

var sans = concatFonts(sansFonts);

var FontFamily = {
  concatFonts: concatFonts,
  monoFonts: monoFonts,
  mono: mono,
  sansFonts: sansFonts,
  sans: sans
};

var Color;

export {
  Color ,
  unit ,
  FontFamily ,
  
}
/* monoFonts Not a pure module */
