import * as React from "react";
import queryString from "query-string";
import { withApollo } from "react-apollo";
import { REFRESH_TOKEN } from "queries/UserQueries";
import { make as LoadingSpinner } from "components/LoadingSpinner.bs";

const PostCheckoutTokenRefresher = ({ history, client }) => {
  React.useEffect(() => {
    const refreshToken = async () => {
      // This route is hit when redirecting from Stripe Checkout
      // and Stripe Portal. A user may have upgraded or downgraded, so they
      // must requery their token to have the correct permissions
      const {
        data: { refreshToken },
      } = await client.mutate({
        mutation: REFRESH_TOKEN,
      });

      localStorage.setItem("token", refreshToken);
      const params = queryString.parse(window.location.search);
      const route = params.redirect ? params.redirect : "/dashboard";
      history.replace(route);
    };

    refreshToken();
  }, [client, history]);

  return <LoadingSpinner inline={false} size={36} />;
};

export default withApollo(PostCheckoutTokenRefresher);
