// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";

function toInput(i) {
  return {
          package: i.package,
          module: i.module,
          isDefaultExport: i.isDefaultExport,
          wildCardImport: i.wildCardImport
        };
}

var ppx_printed_query = "fragment ImportFields on Import   {\n__typename\nuuid  \npackage  \nmodule_: module  \nalias  \nisDefaultExport  \nwildCardImport  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type Import, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type Import is missing");
  }
  var value$6 = Js_dict.get(value$2, "package");
  var field_package;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_package = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_package = Js_exn.raiseError("graphql_ppx: Field package on type Import is missing");
  }
  var value$9 = Js_dict.get(value$2, "module_");
  var field_module_;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_module_ = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_module_ = Js_exn.raiseError("graphql_ppx: Field module_ on type Import is missing");
  }
  var value$12 = Js_dict.get(value$2, "alias");
  var field_alias;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var match = Js_json.decodeNull(value$13);
    if (match !== undefined) {
      field_alias = undefined;
    } else {
      var value$14 = Js_json.decodeString(value$13);
      field_alias = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
    }
  } else {
    field_alias = undefined;
  }
  var value$15 = Js_dict.get(value$2, "isDefaultExport");
  var field_isDefaultExport;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeBoolean(value$16);
    field_isDefaultExport = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$16));
  } else {
    field_isDefaultExport = Js_exn.raiseError("graphql_ppx: Field isDefaultExport on type Import is missing");
  }
  var value$18 = Js_dict.get(value$2, "wildCardImport");
  var field_wildCardImport;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeBoolean(value$19);
    field_wildCardImport = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$19));
  } else {
    field_wildCardImport = Js_exn.raiseError("graphql_ppx: Field wildCardImport on type Import is missing");
  }
  return {
          uuid: field_uuid,
          package: field_package,
          module: field_module_,
          alias: field_alias,
          isDefaultExport: field_isDefaultExport,
          wildCardImport: field_wildCardImport
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ImportFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "ImportFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  ImportFields: ImportFields
};

export {
  toInput ,
  Fragment ,
  
}
/* No side effect */
