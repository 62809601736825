import * as React from "react";
import * as Sentry from "@sentry/react";
import { make as ErrorBoundaryInternal } from "./ErrorBoundaryInternal.bs";
import * as Analytics from "utilities/Analytics.gen";
import { extractJavascriptError } from "utilities/Sentry.bs";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo); // eslint-disable-line
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      scope.setLevel("fatal");

      Sentry.captureException(extractJavascriptError(error));
      this.setState({ error });
    });

    Sentry.forceLoad();

    Analytics.trackWithProperties("Error Boundary Shown", {
      properties: { error, ...errorInfo },
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorBoundaryInternal />;
    } else {
      return this.props.children;
    }
  }
}
