// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Stack$DraftbitBuilder from "../Layout/Stack.bs.js";
import * as Spacer$DraftbitBuilder from "../Layout/Spacer.bs.js";
import * as OptionUtils$DraftbitBuilder from "../../../utilities/OptionUtils.bs.js";
import * as DataCyButton$DraftbitBuilder from "../../../components/DataCyButton.bs.js";
import * as LoadingSpinner$DraftbitBuilder from "../../../components/LoadingSpinner.bs.js";

function styles(kind, size, disabled, loading, fullWidth, cursor, pointerEventsNone, className) {
  var tmp;
  switch (size) {
    case /* XSmall */0 :
        tmp = "px-2 py-1 text-xs";
        break;
    case /* Small */1 :
        tmp = "min-w-12 px-2 py-1 text-sm";
        break;
    case /* Medium */2 :
        tmp = "min-w-20 px-4 py-2 text-md";
        break;
    case /* Large */3 :
        tmp = "min-w-32 px-8 py-4 text-lg";
        break;
    
  }
  var tmp$1;
  switch (kind) {
    case /* Dark */0 :
        tmp$1 = "text-mono-50 border-mono-600 bg-mono-800 hover:bg-mono-700";
        break;
    case /* Darker */1 :
        tmp$1 = "text-mono-50 bg-mono-600 hover:bg-mono-500 border-none";
        break;
    case /* Solid */2 :
        tmp$1 = "text-mono-50 border-brand bg-brand hover:bg-primary-400 hover:border-primary-400 disabled:text-mono-300";
        break;
    case /* Outline */3 :
        tmp$1 = "text-mono-100 border-mono-700 bg-transparent hover:text-mono-50 hover:border-mono-400 hover:bg-mono-700 disabled:text-mono-500 disabled:border-mono-500";
        break;
    case /* Light */4 :
        tmp$1 = "text-brand border-brand bg-mono-100 hover:text-mono-50 hover:bg-primary-400";
        break;
    case /* Warning */5 :
        tmp$1 = "text-mono-50 border-failure-400 bg-failure-400 hover:bg-failure-300 disabled:text-mono-400 disabled:bg-failure-400";
        break;
    case /* Secondary */6 :
        tmp$1 = "text-mono-300 bg-mono-900 hover:text-mono-50 hover:bg-mono-700 border-none";
        break;
    
  }
  return Cn.make({
              hd: "relative",
              tl: {
                hd: "inline-flex items-center justify-center",
                tl: {
                  hd: "border border-r rounded flex-shrink-0",
                  tl: {
                    hd: "transition duration-150 ease-in-out",
                    tl: {
                      hd: Belt_Option.getWithDefault(cursor, disabled ? "cursor-not-allowed" : "cursor-pointer"),
                      tl: {
                        hd: "whitespace-nowrap",
                        tl: {
                          hd: "shadow",
                          tl: {
                            hd: Cn.ifTrue("opacity-60", disabled),
                            tl: {
                              hd: Cn.ifTrue("pointer-events-none", pointerEventsNone || loading),
                              tl: {
                                hd: Cn.ifTrue("w-full", fullWidth),
                                tl: {
                                  hd: tmp,
                                  tl: {
                                    hd: tmp$1,
                                    tl: {
                                      hd: Belt_Option.getWithDefault(className, ""),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Button$Text(Props) {
  var children = Props.children;
  return React.createElement("span", {
              className: Cn.make({
                    hd: "font-semibold",
                    tl: /* [] */0
                  })
            }, children);
}

var $$Text = {
  make: Button$Text
};

function Button$Visibility(Props) {
  var children = Props.children;
  var hidden = Props.hidden;
  if (hidden) {
    return React.createElement("div", {
                className: "invisible inline-flex"
              }, children);
  } else {
    return children;
  }
}

var Visibility = {
  make: Button$Visibility
};

function Button(Props) {
  var id = Props.id;
  var icon = Props.icon;
  var iconPositionOpt = Props.iconPosition;
  var label = Props.label;
  var kindOpt = Props.kind;
  var disabledReason = Props.disabledReason;
  var disabledOpt = Props.disabled;
  var loadingOpt = Props.loading;
  var fullWidthOpt = Props.fullWidth;
  var sizeOpt = Props.size;
  var dataCyOpt = Props.dataCy;
  var onClick = Props.onClick;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var cursor = Props.cursor;
  var pointerEventsNoneOpt = Props.pointerEventsNone;
  var title = Props.title;
  var stopPropagationOpt = Props.stopPropagation;
  var className = Props.className;
  var iconPosition = iconPositionOpt !== undefined ? iconPositionOpt : "Left";
  var kind = kindOpt !== undefined ? kindOpt : /* Solid */2;
  var disabled = disabledOpt !== undefined ? disabledOpt : disabledReason !== undefined;
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */2;
  var dataCy = dataCyOpt !== undefined ? dataCyOpt : "Button";
  var pointerEventsNone = pointerEventsNoneOpt !== undefined ? pointerEventsNoneOpt : false;
  var stopPropagation = stopPropagationOpt !== undefined ? stopPropagationOpt : false;
  var spinnerSize;
  switch (size) {
    case /* XSmall */0 :
        spinnerSize = 12;
        break;
    case /* Small */1 :
        spinnerSize = 16;
        break;
    case /* Medium */2 :
        spinnerSize = 24;
        break;
    case /* Large */3 :
        spinnerSize = 32;
        break;
    
  }
  var tmp = {
    dataCy: dataCy,
    className: styles(kind, size, disabled, loading, fullWidth, cursor, pointerEventsNone, className),
    onClick: (function (e) {
        if (onClick !== undefined) {
          if (stopPropagation) {
            e.stopPropagation();
          }
          return Curry._1(onClick, undefined);
        }
        
      }),
    disabled: disabled,
    children: null
  };
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var tmp$1 = OptionUtils$DraftbitBuilder.or_(title, disabledReason);
  if (tmp$1 !== undefined) {
    tmp.title = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(DataCyButton$DraftbitBuilder.make, tmp, loading ? React.createElement(LoadingSpinner$DraftbitBuilder.make, {
                    className: "absolute",
                    size: spinnerSize,
                    inline: true
                  }) : null, React.createElement(Button$Visibility, {
                  children: null,
                  hidden: loading
                }, iconPosition === "Left" && icon !== undefined ? React.createElement(Spacer$DraftbitBuilder.make, {
                        children: React.createElement(Stack$DraftbitBuilder.make, {
                              align: "Center",
                              distribute: "Center",
                              children: Caml_option.valFromOption(icon)
                            }),
                        right: 2
                      }) : null, React.createElement(Button$Text, {
                      children: label,
                      weight: /* SemiBold */0
                    }), iconPosition === "Right" && icon !== undefined ? React.createElement(Spacer$DraftbitBuilder.make, {
                        children: React.createElement(Stack$DraftbitBuilder.make, {
                              align: "Center",
                              distribute: "Center",
                              children: Caml_option.valFromOption(icon)
                            }),
                        left: 2
                      }) : null));
}

var make = Button;

export {
  styles ,
  $$Text ,
  Visibility ,
  make ,
  
}
/* react Not a pure module */
