// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as DataCyWrapper$DraftbitBuilder from "./DataCyWrapper.bs.js";

function DataCyButton(Props) {
  var dataCy = Props.dataCy;
  var className = Props.className;
  var onClick = Props.onClick;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var disabled = Props.disabled;
  var style = Props.style;
  var childrenOpt = Props.children;
  var addId = Props.addId;
  var id = Props.id;
  var title = Props.title;
  var alt = Props.alt;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var tmp = {};
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (alt !== undefined) {
    tmp.alt = Caml_option.valFromOption(alt);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  var tmp$1 = {
    children: React.createElement("button", tmp, children),
    name: dataCy
  };
  if (addId !== undefined) {
    tmp$1.addId = Caml_option.valFromOption(addId);
  }
  return React.createElement(DataCyWrapper$DraftbitBuilder.make, tmp$1);
}

var make = DataCyButton;

export {
  make ,
  
}
/* react Not a pure module */
