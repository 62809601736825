// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Sucrase from "sucrase";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";

function safeTransform(transformsOpt, code) {
  var transforms = transformsOpt !== undefined ? transformsOpt : [];
  var result;
  try {
    result = Sucrase.transform(code, {
          transforms: transforms
        });
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Js_exn.$$Error) {
      return {
              TAG: /* Error */1,
              _0: e._1
            };
    }
    throw e;
  }
  return {
          TAG: /* Ok */0,
          _0: result.code
        };
}

export {
  safeTransform ,
  
}
/* sucrase Not a pure module */
