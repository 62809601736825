// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";

var _map = {"Screen":"Screen","Block":"Block"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function t_encode(t) {
  return t;
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("BlockType.t", tFromJs, param);
}

var allTypes = [
  "Screen",
  "Block"
];

export {
  tToJs ,
  tFromJs ,
  t_encode ,
  t_decode ,
  allTypes ,
  
}
/* JsonUtils-DraftbitBuilder Not a pure module */
