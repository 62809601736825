// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.mjs";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Pervasives from "bs-platform/lib/es6/pervasives.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as QueryString from "query-string";
import * as IsArray from "lodash/isArray";
import * as ReactRouterDom from "./ReactRouterDom";
import * as ReactRouterDom$1 from "react-router-dom";
import * as Hooks$DraftbitBuilder from "./hooks/Hooks.bs.js";
import * as Utils$DraftbitBuilder from "./utilities/Utils.bs.js";
import * as Device$DraftbitBuilder from "./Device.bs.js";
import * as ViewMode$DraftbitBuilder from "./ViewMode.bs.js";
import * as BlockType$DraftbitBuilder from "./BlockType.bs.js";
import * as DictUtils$DraftbitBuilder from "./utilities/DictUtils.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as StyleName$DraftbitBuilder from "./StyleName.bs.js";
import * as Breakpoint$DraftbitBuilder from "./Breakpoint.bs.js";
import * as OptionUtils$DraftbitBuilder from "./utilities/OptionUtils.bs.js";
import * as QueryString$DraftbitBuilder from "./bindings/QueryString.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";
import * as CanvasModeTab$DraftbitBuilder from "./screens/Builder/CanvasModeTab.bs.js";
import * as EditThemesTab$DraftbitBuilder from "./screens/Builder/StylesAndColors/pages/Themes/EditThemesTab.bs.js";
import * as NavigatorType$DraftbitBuilder from "./NavigatorType.bs.js";
import * as BottomPanelTab$DraftbitBuilder from "./components/BottomPanelTab.bs.js";
import * as PropertiesPanelTab$DraftbitBuilder from "./components/PropertiesPanel/PropertiesPanelTab.bs.js";
import * as StylesAndColorsTab$DraftbitBuilder from "./screens/Builder/StylesAndColors/StylesAndColorsTab.bs.js";
import * as PublicationSettings$DraftbitBuilder from "./PublicationSettings.bs.js";

function getAppAndScreenUuids(history) {
  var match = history.location;
  var pathname = match.pathname;
  var match$1 = pathname.match(/\/apps\/(\w{8})\/screens\/(\w{8})/);
  if (match$1 === null) {
    return Pervasives.failwith("Couldn't extract appUuid and screenUuid from " + pathname);
  }
  if (match$1.length !== 3) {
    return Pervasives.failwith("Couldn't extract appUuid and screenUuid from " + pathname);
  }
  var appUuid = match$1[1];
  var screenUuid = match$1[2];
  return [
          appUuid,
          screenUuid
        ];
}

function pushWithSearch(history, additionalSearchOpt, newPath) {
  var additionalSearch = additionalSearchOpt !== undefined ? additionalSearchOpt : [];
  var match = history.location.search;
  var newSearch = match === "" ? (
      additionalSearch.length !== 0 ? "?" + QueryString$DraftbitBuilder.stringifyArray(additionalSearch) : ""
    ) : StringUtils$DraftbitBuilder.ensureSuffix(match, "&") + StringUtils$DraftbitBuilder.ensurePrefixNot(QueryString$DraftbitBuilder.stringifyArray(additionalSearch), "&");
  history.push(newPath + newSearch, undefined);
  
}

function pushWithState(history, newPath, state) {
  history.push(newPath + history.location.search, Caml_option.some(state));
  
}

function pushWithPartialSearch(history, newPath, paramsToRemove) {
  var search = history.location.search;
  var urlSearchParams = new URLSearchParams(search);
  paramsToRemove.forEach(function (param) {
        urlSearchParams.delete(param);
        
      });
  history.push(newPath + "?" + urlSearchParams.toString(), undefined);
  
}

var RouterHistory = {
  getAppAndScreenUuids: getAppAndScreenUuids,
  pushWithSearch: pushWithSearch,
  pushWithState: pushWithState,
  pushWithPartialSearch: pushWithPartialSearch
};

function back(param) {
  var $$window = typeof window === "undefined" ? undefined : window;
  if ($$window === undefined) {
    return ;
  }
  var history = $$window.history;
  if (history !== undefined) {
    return Caml_option.valFromOption(history).back();
  }
  
}

function useIsOnScreenEditor(strictOpt, param) {
  var strict = strictOpt !== undefined ? strictOpt : true;
  var match_ = ReactRouterDom$1.useRouteMatch({
        isExact: true,
        path: "/apps/:appUuid/screens/:screenUuid",
        url: "",
        strict: true,
        sensitive: false,
        params: {}
      });
  if (strict) {
    if (match_ == null) {
      return false;
    } else {
      return match_.isExact;
    }
  } else {
    return !(match_ == null);
  }
}

function useIsOnScreenWizardModal(param) {
  var match_ = ReactRouterDom$1.useRouteMatch({
        isExact: true,
        path: "/apps/:appUuid/screens/:screenUuid/screen-wizard",
        url: "",
        strict: true,
        sensitive: false,
        params: {}
      });
  return Belt_Option.isSome((match_ == null) ? undefined : Caml_option.some(match_));
}

function useQuery(param) {
  var $$location = ReactRouterDom$1.useLocation();
  return QueryString$DraftbitBuilder.parse($$location.search);
}

function push(history, path) {
  history.push(path, undefined);
  
}

function replace(history, path) {
  history.replace(path);
  
}

function modify(history, update, pathname, f) {
  var $$location = history.location;
  var pathname$1 = Belt_Option.getWithDefault(pathname, $$location.pathname);
  var search = QueryString$DraftbitBuilder.parse($$location.search);
  var currentPath = $$location.pathname + $$location.search;
  var newSearch = QueryString.stringify(Curry._1(f, search));
  var newPath = newSearch === "" ? pathname$1 : pathname$1 + ("?" + newSearch);
  if (currentPath !== newPath) {
    return Curry._2(update, history, newPath);
  }
  
}

function removeKey(history, update, pathname, key) {
  return modify(history, update, pathname, (function (param) {
                return DictUtils$DraftbitBuilder.removeL(key, param);
              }));
}

function removeKeys(history, updateMethod, pathname, keysToRemove) {
  return modify(history, updateMethod, pathname, (function (dict) {
                return DictUtils$DraftbitBuilder.removeMany(dict, keysToRemove);
              }));
}

function makeQueryParamHook(updateMethodOpt, parse, toString, key, keysToRemoveOpt, cleanupOnUnmountOpt, onUpdateOpt, param) {
  var updateMethod = updateMethodOpt !== undefined ? updateMethodOpt : push;
  var keysToRemove = keysToRemoveOpt !== undefined ? keysToRemoveOpt : [];
  var cleanupOnUnmount = cleanupOnUnmountOpt !== undefined ? cleanupOnUnmountOpt : true;
  var onUpdate = onUpdateOpt !== undefined ? onUpdateOpt : Utils$DraftbitBuilder.noop;
  return function () {
    var history = ReactRouterDom$1.useHistory();
    var query = useQuery(undefined);
    var currentValue = Belt_Option.flatMap(Js_dict.get(query, key), parse);
    var update = function (newValue) {
      Curry._1(onUpdate, newValue);
      if (newValue === undefined) {
        return removeKey(history, updateMethod, undefined, key);
      }
      var value = Caml_option.valFromOption(newValue);
      if (Caml_option.some(value) === currentValue) {
        return ;
      } else {
        return modify(history, updateMethod, undefined, (function (t) {
                      return DictUtils$DraftbitBuilder.removeMany(DictUtils$DraftbitBuilder.add(t, key, Curry._1(toString, value)), keysToRemove);
                    }));
      }
    };
    React.useEffect((function () {
            return OptionUtils$DraftbitBuilder.someIf(cleanupOnUnmount, (function (param) {
                          return removeKey(history, updateMethod, undefined, key);
                        }));
          }), []);
    return [
            currentValue,
            update
          ];
  };
}

function makeQueryParamHookWithDefault(updateMethodOpt, parse, toString, key, keysToRemoveOpt, cleanupOnUnmountOpt, $$default) {
  var updateMethod = updateMethodOpt !== undefined ? updateMethodOpt : push;
  var keysToRemove = keysToRemoveOpt !== undefined ? keysToRemoveOpt : [];
  var cleanupOnUnmount = cleanupOnUnmountOpt !== undefined ? cleanupOnUnmountOpt : true;
  return function () {
    var history = ReactRouterDom$1.useHistory();
    var query = useQuery(undefined);
    var currentValue = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(query, key), parse), $$default);
    var update = function (newValue) {
      if (Curry._1(toString, newValue) === Curry._1(toString, $$default)) {
        return removeKey(history, updateMethod, undefined, key);
      } else {
        return modify(history, updateMethod, undefined, (function (t) {
                      return DictUtils$DraftbitBuilder.removeMany(DictUtils$DraftbitBuilder.add(t, key, Curry._1(toString, newValue)), keysToRemove);
                    }));
      }
    };
    React.useEffect((function () {
            return OptionUtils$DraftbitBuilder.someIf(cleanupOnUnmount, (function (param) {
                          return removeKey(history, updateMethod, undefined, key);
                        }));
          }), []);
    return [
            currentValue,
            update
          ];
  };
}

function makeBoolQueryParamHook(updateMethod, key, keysToRemove, cleanupOnUnmount, param) {
  return makeQueryParamHookWithDefault(updateMethod, (function (s) {
                if (s === "") {
                  return false;
                } else {
                  return true;
                }
              }), (function (b) {
                if (b) {
                  return "1";
                } else {
                  return "";
                }
              }), key, keysToRemove, cleanupOnUnmount, false);
}

var Query = {
  useQuery: useQuery,
  push: push,
  replace: replace,
  modify: modify,
  removeKey: removeKey,
  removeKeys: removeKeys,
  makeQueryParamHook: makeQueryParamHook,
  makeQueryParamHookWithDefault: makeQueryParamHookWithDefault,
  makeBoolQueryParamHook: makeBoolQueryParamHook
};

var make = ReactRouterDom.Link;

var Link = {
  make: make
};

var make$1 = ReactRouterDom.NavLink;

var NavLink = {
  make: make$1
};

var Switch = {};

var propertiesPanel = "p";

var canvasMode = "m";

var componentUuid = "c";

var bottomPanel = "b";

var actionUuid = "a";

var device = "d";

var orientation = "o";

var scale = "s";

var breakpoint = "bp";

var stylesAndColorsTab = "sct";

var styleSheetUuid = "ss";

var highlightedStyle = "hs";

var viewMode = "v";

var publicationEnvironment = "pe";

var navigatorType = "nt";

var blockType = "bt";

var editThemeUuid = "et";

var editThemeTab = "ett";

var themeUuid = "th";

var QueryKeys = {
  propertiesPanel: propertiesPanel,
  canvasMode: canvasMode,
  componentUuid: componentUuid,
  bottomPanel: bottomPanel,
  actionUuid: actionUuid,
  device: device,
  orientation: orientation,
  scale: scale,
  breakpoint: breakpoint,
  stylesAndColorsTab: stylesAndColorsTab,
  styleSheetUuid: styleSheetUuid,
  highlightedStyle: highlightedStyle,
  viewMode: viewMode,
  trigger: "t",
  publicationEnvironment: publicationEnvironment,
  webPreviewBackground: "wpb",
  navigatorType: navigatorType,
  blockType: blockType,
  editThemeUuid: editThemeUuid,
  editThemeTab: editThemeTab,
  themeUuid: themeUuid
};

function getParams(param) {
  var params = ReactRouterDom$1.useParams();
  var query = useQuery(undefined);
  var getQ = function ($$default, key) {
    return OptionUtils$DraftbitBuilder.or_(Belt_Option.flatMap(Js_dict.get(query, key), (function (value) {
                      if (IsArray(value)) {
                        return Belt_Array.get(value, 0);
                      } else {
                        return value;
                      }
                    })), $$default);
  };
  var getQParse = function ($$default, key, fromJs) {
    return OptionUtils$DraftbitBuilder.or_(Belt_Option.flatMap(Js_dict.get(query, key), fromJs), $$default);
  };
  var breakpoint$1 = Belt_Option.getWithDefault(getQParse(undefined, breakpoint, Breakpoint$DraftbitBuilder.tFromJs), "Mobile");
  var viewMode$1 = Belt_Option.getWithDefault(getQParse(undefined, viewMode, ViewMode$DraftbitBuilder.tFromJs), "SingleScreen");
  var device$1 = Device$DraftbitBuilder.getDevice(Belt_Option.getWithDefault(getQParse(undefined, device, Device$DraftbitBuilder.deviceFromJs), Device$DraftbitBuilder.getDefaultDevice(viewMode$1, breakpoint$1)), viewMode$1, breakpoint$1);
  return {
          appUuid: StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "appUuid")),
          screenUuid: StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "screenUuid")),
          exampleUuid: StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "exampleUuid")),
          serviceUuid: StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "serviceUuid")),
          endpointUuid: StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "endpointUuid")),
          canvasModeTab: getQParse(undefined, canvasMode, CanvasModeTab$DraftbitBuilder.tFromJs),
          propertiesPanelTab: getQParse(undefined, propertiesPanel, PropertiesPanelTab$DraftbitBuilder.tFromJs),
          componentUuid: getQ(undefined, componentUuid),
          actionUuid: getQ(undefined, actionUuid),
          bottomPanelTab: getQParse(undefined, bottomPanel, BottomPanelTab$DraftbitBuilder.tFromString),
          workspaceUuid: StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "workspaceUuid")),
          restTypeSlug: StringUtils$DraftbitBuilder.emptyIfNone(Js_dict.get(params, "restType")),
          device: device$1,
          viewMode: viewMode$1,
          breakpoint: breakpoint$1,
          orientation: Belt_Option.getWithDefault(getQParse(undefined, orientation, Device$DraftbitBuilder.orientationFromJs), "portrait"),
          scale: Belt_Option.getWithDefault(getQParse(undefined, scale, Belt_Int.fromString), 100)
        };
}

function usePropertiesPanelTab(param) {
  return makeQueryParamHook(undefined, PropertiesPanelTab$DraftbitBuilder.tFromJs, PropertiesPanelTab$DraftbitBuilder.tToJs, propertiesPanel, undefined, false, undefined, undefined)();
}

function useSelectedBlockType(param) {
  return makeQueryParamHook(undefined, BlockType$DraftbitBuilder.tFromJs, BlockType$DraftbitBuilder.tToJs, blockType, undefined, true, undefined, undefined)();
}

function useSelectedComponent(param) {
  return makeQueryParamHook(replace, StringUtils$DraftbitBuilder.noneIfEmpty, (function (s) {
                  return s;
                }), componentUuid, [actionUuid], false, undefined, undefined)();
}

function useSelectedAction(param) {
  return makeQueryParamHook(replace, StringUtils$DraftbitBuilder.noneIfEmpty, (function (s) {
                  return s;
                }), actionUuid, undefined, false, undefined, undefined)();
}

function useSelectedStyleSheet(param) {
  return makeQueryParamHook(replace, StringUtils$DraftbitBuilder.noneIfEmpty, (function (s) {
                  return s;
                }), styleSheetUuid, undefined, true, undefined, undefined)();
}

function useSelectedEditTheme(param) {
  return makeQueryParamHook(replace, StringUtils$DraftbitBuilder.noneIfEmpty, (function (s) {
                  return s;
                }), editThemeUuid, undefined, true, undefined, undefined)();
}

function useSelectedTheme(param) {
  return makeQueryParamHook(replace, StringUtils$DraftbitBuilder.noneIfEmpty, (function (s) {
                  return s;
                }), themeUuid, undefined, false, undefined, undefined)();
}

function useEditThemeTab(param) {
  return makeQueryParamHookWithDefault(undefined, EditThemesTab$DraftbitBuilder.tFromJs, EditThemesTab$DraftbitBuilder.tToJs, editThemeTab, undefined, true, "Colors")();
}

function useSelectedNavigatorType(param) {
  return makeQueryParamHook(replace, NavigatorType$DraftbitBuilder.tFromJs, NavigatorType$DraftbitBuilder.tToJs, navigatorType, undefined, false, undefined, undefined)();
}

function useBottomPanelTab(param) {
  return makeQueryParamHook(undefined, BottomPanelTab$DraftbitBuilder.tFromString, BottomPanelTab$DraftbitBuilder.tToString, bottomPanel, undefined, false, undefined, undefined)();
}

function useDevice(param) {
  return makeQueryParamHook(undefined, Device$DraftbitBuilder.deviceFromJs, Device$DraftbitBuilder.deviceToJs, device, undefined, false, undefined, undefined)();
}

function useBreakpoint(param) {
  return makeQueryParamHookWithDefault(replace, Breakpoint$DraftbitBuilder.tFromJs, Breakpoint$DraftbitBuilder.tToJs, breakpoint, [device], false, "Mobile")();
}

function useStylesAndColorsTab(param) {
  return makeQueryParamHookWithDefault(undefined, StylesAndColorsTab$DraftbitBuilder.tFromJs, StylesAndColorsTab$DraftbitBuilder.tToJs, stylesAndColorsTab, undefined, true, "CustomColors")();
}

function useHighlightedStyle(param) {
  return makeQueryParamHook(replace, StyleName$DraftbitBuilder.tFromJs, StyleName$DraftbitBuilder.tToJs, highlightedStyle, undefined, undefined, undefined, undefined)();
}

function useScale(param) {
  return makeQueryParamHookWithDefault(replace, Belt_Int.fromString, (function (prim) {
                  return String(prim);
                }), scale, undefined, false, 100)();
}

function usePublicationEnvironment(param) {
  var match = Hooks$DraftbitBuilder.useJsonStorage("publicationEnvironment", "Development", PublicationSettings$DraftbitBuilder.envToJs, (function (param) {
          return JsonUtils$DraftbitBuilder.decodeDecco(PublicationSettings$DraftbitBuilder.env_decode, param);
        }));
  var setStoredEnv = match[1];
  var storedEnv = match[0];
  var match$1 = makeQueryParamHookWithDefault(replace, PublicationSettings$DraftbitBuilder.envFromJs, PublicationSettings$DraftbitBuilder.envToJs, publicationEnvironment, undefined, false, storedEnv)();
  var env = match$1[0];
  React.useEffect((function () {
          if (env !== storedEnv) {
            Curry._1(setStoredEnv, env);
          }
          
        }), [env]);
  return [
          env,
          match$1[1]
        ];
}

function useViewMode(param) {
  var match = useScale(undefined);
  var setScale = match[1];
  var scale = match[0];
  var match$1 = makeQueryParamHookWithDefault(undefined, ViewMode$DraftbitBuilder.tFromJs, ViewMode$DraftbitBuilder.tToJs, viewMode, undefined, false, "SingleScreen")();
  var setViewMode = match$1[1];
  var set = function (newViewMode) {
    var maximumScale = ViewMode$DraftbitBuilder.maximumScale(newViewMode);
    if (scale > maximumScale) {
      Curry._1(setScale, maximumScale);
    }
    return Curry._1(setViewMode, newViewMode);
  };
  return [
          match$1[0],
          set
        ];
}

function useCanvasModeTab(param) {
  var match = makeQueryParamHook(undefined, CanvasModeTab$DraftbitBuilder.tFromJs, CanvasModeTab$DraftbitBuilder.tToJs, canvasMode, undefined, false, undefined, undefined)();
  var setCanvasModeTab = match[1];
  var match$1 = useViewMode(undefined);
  var setViewMode = match$1[1];
  var viewMode = match$1[0];
  var set = function (tab) {
    if (Caml_obj.caml_equal(tab, "Preview")) {
      Curry._1(setViewMode, "SingleScreen");
    } else if (Caml_obj.caml_equal(tab, "Navigate") && viewMode === "SingleScreen") {
      Curry._1(setViewMode, "WebPreview");
    }
    return Curry._1(setCanvasModeTab, tab);
  };
  return [
          match[0],
          set
        ];
}

function useOrientation(param) {
  return makeQueryParamHookWithDefault(undefined, Device$DraftbitBuilder.orientationFromJs, Device$DraftbitBuilder.orientationToJs, orientation, undefined, false, "portrait")();
}

function buildUrl(paths) {
  return paths.join("/");
}

function buildScreenUrl(appUuid, screenUuid) {
  return [
            "/apps",
            appUuid,
            "screens",
            screenUuid
          ].join("/");
}

function examples(history) {
  history.push("/examples", undefined);
  
}

function dashboard(history) {
  history.push("/dashboard", undefined);
  
}

function workspaces(history) {
  history.push("/workspaces", undefined);
  
}

function accountSettings(history) {
  history.push("/account", undefined);
  
}

function upgradePlan(history) {
  history.push("/account/pricing", undefined);
  
}

function onboardingSurvey(history) {
  history.push("/onboarding/welcome", undefined);
  
}

function app(history, appUuid) {
  history.push("/apps/" + appUuid, undefined);
  
}

function screen(appUuid, screenUuid, componentUuid$1, blockType$1, updateCanvasModeOpt, pathOpt, history) {
  var updateCanvasMode = updateCanvasModeOpt !== undefined ? updateCanvasModeOpt : true;
  var path = pathOpt !== undefined ? pathOpt : "";
  return modify(history, push, "/apps/" + appUuid + "/screens/" + screenUuid + path, (function (query) {
                var query$1 = componentUuid$1 !== undefined ? DictUtils$DraftbitBuilder.add(query, componentUuid, componentUuid$1) : DictUtils$DraftbitBuilder.remove(query, componentUuid);
                return DictUtils$DraftbitBuilder.addIf(blockType$1 !== undefined ? DictUtils$DraftbitBuilder.add(query$1, blockType, BlockType$DraftbitBuilder.tToJs(blockType$1)) : DictUtils$DraftbitBuilder.remove(query$1, blockType), updateCanvasMode, canvasMode, BlockType$DraftbitBuilder.tToJs(Belt_Option.getWithDefault(blockType$1, "Screen")) + "s");
              }));
}

function screenWithSearch(appUuid, screenUuid, history) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  return pushWithSearch(history, undefined, screenUrl);
}

function variables(appUuid, screenUuid, history) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "variables"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function appVariables(appUuid, screenUuid, history) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "variables",
      "app-variables"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function screenVariables(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "variables",
      "screen-variables"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function makeEditStyleSheetUrl(history, styleName, styleSheetUuid$1) {
  var match = getAppAndScreenUuids(history);
  var query = DictUtils$DraftbitBuilder.addOpt(DictUtils$DraftbitBuilder.add(DictUtils$DraftbitBuilder.add(QueryString$DraftbitBuilder.parse(history.location.search), stylesAndColorsTab, StylesAndColorsTab$DraftbitBuilder.tToJs("StyleSheets")), styleSheetUuid, styleSheetUuid$1), highlightedStyle, styleName);
  var screenUrl = buildScreenUrl(match[0], match[1]);
  return screenUrl + "?" + QueryString.stringify(query);
}

function editStyleSheet(history, styleSheetUuid, styleName, param) {
  var url = makeEditStyleSheetUrl(history, styleName, styleSheetUuid);
  history.push(url, undefined);
  
}

function settings(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "settings",
      "basic"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function integrations(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "integrations",
      "basic"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function backups(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "backups",
      "basic"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function advancedSettings(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "settings",
      "advanced"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function expoVersionSettings(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "settings",
      "expo-version"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function githubSettings(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "settings",
      "github"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function appleAppStoreSettings(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "settings",
      "apple-app-store"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function googlePlayStoreSettings(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "settings",
      "google-play-store"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function assets(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "assets"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function customCode(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "custom-code"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function addScreen(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "add-screen"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function newScreen(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "new-screen"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function screenWizard(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "screen-wizard"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function blockGallery(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "block-gallery"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function addScreenWithState(history, appUuid, screenUuid, state) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "add-screen"
    ].join("/");
  return pushWithState(history, url, state);
}

function servicesModal(history, appUuid, screenUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "services"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function newServiceModal(type_Opt, appUuid, screenUuid, history) {
  var type_ = type_Opt !== undefined ? type_Opt : "restapi";
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "services",
      type_,
      "new"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function editServiceModal(type_Opt, appUuid, screenUuid, serviceUuid, history) {
  var type_ = type_Opt !== undefined ? type_Opt : "restapi";
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "services",
      type_,
      serviceUuid,
      "edit"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function endpointsModal(history, type_Opt, appUuid, screenUuid, serviceUuid) {
  var type_ = type_Opt !== undefined ? type_Opt : "restapi";
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "services",
      type_,
      serviceUuid
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function newEndpoint(history, type_Opt, appUuid, screenUuid, serviceUuid) {
  var type_ = type_Opt !== undefined ? type_Opt : "restapi";
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "services",
      type_,
      serviceUuid,
      "endpoints",
      "new"
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function endpointDetailModal(type_Opt, appUuid, screenUuid, serviceUuid, endpointUuid, history) {
  var type_ = type_Opt !== undefined ? type_Opt : "restapi";
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "services",
      type_,
      serviceUuid,
      "endpoints",
      endpointUuid
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function templatePreviewStr(history, appUuid, screenUuid, templateUuid) {
  return [
            buildScreenUrl(appUuid, screenUuid),
            "add-screen",
            templateUuid,
            history.location.search
          ].join("/");
}

function dashboardTemplatePreview(history, uuid) {
  var url = [
      "/examples",
      uuid
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

function screenEditorTemplatePreview(history, appUuid, screenUuid, exampleUuid) {
  var screenUrl = buildScreenUrl(appUuid, screenUuid);
  var url = [
      screenUrl,
      "add-screen",
      exampleUuid
    ].join("/");
  return pushWithSearch(history, undefined, url);
}

var NavigateTo = {
  buildUrl: buildUrl,
  buildScreenUrl: buildScreenUrl,
  examples: examples,
  dashboard: dashboard,
  workspaces: workspaces,
  accountSettings: accountSettings,
  upgradePlan: upgradePlan,
  onboardingSurvey: onboardingSurvey,
  app: app,
  screen: screen,
  screenWithSearch: screenWithSearch,
  variables: variables,
  appVariables: appVariables,
  screenVariables: screenVariables,
  makeEditStyleSheetUrl: makeEditStyleSheetUrl,
  editStyleSheet: editStyleSheet,
  settings: settings,
  integrations: integrations,
  backups: backups,
  advancedSettings: advancedSettings,
  expoVersionSettings: expoVersionSettings,
  githubSettings: githubSettings,
  appleAppStoreSettings: appleAppStoreSettings,
  googlePlayStoreSettings: googlePlayStoreSettings,
  assets: assets,
  customCode: customCode,
  addScreen: addScreen,
  newScreen: newScreen,
  screenWizard: screenWizard,
  blockGallery: blockGallery,
  addScreenWithState: addScreenWithState,
  servicesModal: servicesModal,
  newServiceModal: newServiceModal,
  editServiceModal: editServiceModal,
  endpointsModal: endpointsModal,
  newEndpoint: newEndpoint,
  endpointDetailModal: endpointDetailModal,
  templatePreviewStr: templatePreviewStr,
  dashboardTemplatePreview: dashboardTemplatePreview,
  screenEditorTemplatePreview: screenEditorTemplatePreview
};

export {
  RouterHistory ,
  back ,
  useIsOnScreenEditor ,
  useIsOnScreenWizardModal ,
  Query ,
  Link ,
  NavLink ,
  Switch ,
  QueryKeys ,
  getParams ,
  usePropertiesPanelTab ,
  useSelectedBlockType ,
  useSelectedComponent ,
  useSelectedAction ,
  useSelectedStyleSheet ,
  useSelectedEditTheme ,
  useSelectedTheme ,
  useEditThemeTab ,
  useSelectedNavigatorType ,
  useBottomPanelTab ,
  useDevice ,
  useBreakpoint ,
  useStylesAndColorsTab ,
  useHighlightedStyle ,
  useScale ,
  usePublicationEnvironment ,
  useViewMode ,
  useCanvasModeTab ,
  useOrientation ,
  NavigateTo ,
  
}
/* make Not a pure module */
