// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as ImportGQL$DraftbitBuilder from "./ImportGQL.bs.js";
import * as CustomFileGQL$DraftbitBuilder from "./CustomFileGQL.bs.js";

var emptyInput_imports = [];

var emptyInput_customFiles = [];

var emptyInput = {
  providerStart: "",
  providerEnd: "",
  navigatorWrapperStart: "",
  navigatorWrapperEnd: "",
  preAppComponent: "",
  inAppComponent: "",
  rootPeerComponent: "",
  imports: emptyInput_imports,
  rawImports: "",
  customFiles: emptyInput_customFiles
};

function toInput(p) {
  return {
          providerStart: p.providerStart,
          providerEnd: p.providerEnd,
          navigatorWrapperStart: p.navigatorWrapperStart,
          navigatorWrapperEnd: p.navigatorWrapperEnd,
          preAppComponent: p.preAppComponent,
          inAppComponent: p.inAppComponent,
          rootPeerComponent: p.rootPeerComponent,
          imports: p.imports.map(ImportGQL$DraftbitBuilder.toInput),
          rawImports: p.rawImports,
          customFiles: p.customFiles
        };
}

function inputToGraphql(i) {
  return {
          providerStart: i.providerStart,
          providerEnd: i.providerEnd,
          navigatorWrapperStart: i.navigatorWrapperStart,
          navigatorWrapperEnd: i.navigatorWrapperEnd,
          preAppComponent: i.preAppComponent,
          inAppComponent: i.inAppComponent,
          rootPeerComponent: i.rootPeerComponent,
          imports: i.imports,
          rawImports: i.rawImports,
          customFileUuids: i.customFiles.map(function (cf) {
                return cf.uuid;
              })
        };
}

var ppx_printed_query = "fragment CustomAppCodeFields on CustomAppCode   {\n__typename\nuuid  \nproviderStart  \nproviderEnd  \nnavigatorWrapperStart  \nnavigatorWrapperEnd  \npreAppComponent  \ninAppComponent  \nrootPeerComponent  \nimports  {\n__typename\n..." + ImportGQL$DraftbitBuilder.Fragment.ImportFields.name + "   \n}\n\nrawImports  \ncustomFiles  {\n__typename\n..." + CustomFileGQL$DraftbitBuilder.Fragment.CustomFileFields.name + "   \n}\n\n}\n" + CustomFileGQL$DraftbitBuilder.Fragment.CustomFileFields.query + ImportGQL$DraftbitBuilder.Fragment.ImportFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type CustomAppCode, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type CustomAppCode is missing");
  }
  var value$6 = Js_dict.get(value$2, "providerStart");
  var field_providerStart;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_providerStart = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_providerStart = Js_exn.raiseError("graphql_ppx: Field providerStart on type CustomAppCode is missing");
  }
  var value$9 = Js_dict.get(value$2, "providerEnd");
  var field_providerEnd;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_providerEnd = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_providerEnd = Js_exn.raiseError("graphql_ppx: Field providerEnd on type CustomAppCode is missing");
  }
  var value$12 = Js_dict.get(value$2, "navigatorWrapperStart");
  var field_navigatorWrapperStart;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    field_navigatorWrapperStart = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
  } else {
    field_navigatorWrapperStart = Js_exn.raiseError("graphql_ppx: Field navigatorWrapperStart on type CustomAppCode is missing");
  }
  var value$15 = Js_dict.get(value$2, "navigatorWrapperEnd");
  var field_navigatorWrapperEnd;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeString(value$16);
    field_navigatorWrapperEnd = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
  } else {
    field_navigatorWrapperEnd = Js_exn.raiseError("graphql_ppx: Field navigatorWrapperEnd on type CustomAppCode is missing");
  }
  var value$18 = Js_dict.get(value$2, "preAppComponent");
  var field_preAppComponent;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeString(value$19);
    field_preAppComponent = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
  } else {
    field_preAppComponent = Js_exn.raiseError("graphql_ppx: Field preAppComponent on type CustomAppCode is missing");
  }
  var value$21 = Js_dict.get(value$2, "inAppComponent");
  var field_inAppComponent;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var value$23 = Js_json.decodeString(value$22);
    field_inAppComponent = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$22));
  } else {
    field_inAppComponent = Js_exn.raiseError("graphql_ppx: Field inAppComponent on type CustomAppCode is missing");
  }
  var value$24 = Js_dict.get(value$2, "rootPeerComponent");
  var field_rootPeerComponent;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var value$26 = Js_json.decodeString(value$25);
    field_rootPeerComponent = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$25));
  } else {
    field_rootPeerComponent = Js_exn.raiseError("graphql_ppx: Field rootPeerComponent on type CustomAppCode is missing");
  }
  var value$27 = Js_dict.get(value$2, "imports");
  var field_imports = value$27 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$27))).map(function (value) {
          return Curry._1(ImportGQL$DraftbitBuilder.Fragment.ImportFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field imports on type CustomAppCode is missing");
  var value$28 = Js_dict.get(value$2, "rawImports");
  var field_rawImports;
  if (value$28 !== undefined) {
    var value$29 = Caml_option.valFromOption(value$28);
    var value$30 = Js_json.decodeString(value$29);
    field_rawImports = value$30 !== undefined ? value$30 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$29));
  } else {
    field_rawImports = Js_exn.raiseError("graphql_ppx: Field rawImports on type CustomAppCode is missing");
  }
  var value$31 = Js_dict.get(value$2, "customFiles");
  var field_customFiles = value$31 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$31))).map(function (value) {
          return Curry._1(CustomFileGQL$DraftbitBuilder.Fragment.CustomFileFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field customFiles on type CustomAppCode is missing");
  return {
          uuid: field_uuid,
          providerStart: field_providerStart,
          providerEnd: field_providerEnd,
          navigatorWrapperStart: field_navigatorWrapperStart,
          navigatorWrapperEnd: field_navigatorWrapperEnd,
          preAppComponent: field_preAppComponent,
          inAppComponent: field_inAppComponent,
          rootPeerComponent: field_rootPeerComponent,
          imports: field_imports,
          rawImports: field_rawImports,
          customFiles: field_customFiles
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var CustomAppCodeFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "CustomAppCodeFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  CustomAppCodeFields: CustomAppCodeFields
};

export {
  emptyInput ,
  toInput ,
  inputToGraphql ,
  Fragment ,
  
}
/* CustomFileGQL-DraftbitBuilder Not a pure module */
