// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Expo$DraftbitBuilder from "./Expo.bs.js";

function toMinimal(param) {
  return {
          __typename: param.__typename,
          uuid: param.uuid,
          name: param.name,
          iconUrl: param.iconUrl,
          description: param.description,
          numScreens: param.numScreens,
          state: param.state,
          expoVersion: param.expoVersion
        };
}

function getExpoVersion(app) {
  return Expo$DraftbitBuilder.tFromString(app.expoVersion);
}

function getExpoVersionMinimal(app) {
  return Expo$DraftbitBuilder.tFromString(app.expoVersion);
}

var ppx_printed_query = "fragment SimpleAppFields on App   {\n__typename\n__typename  \nuuid  \nname  \ndescription  \nversion  \niconUrl  \ncoverImageUrl  \nnumScreens  \nexpoVersion  \nstate  \nworkspace  {\n__typename\n__typename  \nuuid  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type App, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
  }
  var value$9 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_name = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type App is missing");
  }
  var value$12 = Js_dict.get(value$2, "description");
  var field_description;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    field_description = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
  } else {
    field_description = Js_exn.raiseError("graphql_ppx: Field description on type App is missing");
  }
  var value$15 = Js_dict.get(value$2, "version");
  var field_version;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeNumber(value$16);
    field_version = value$17 !== undefined ? value$17 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$16));
  } else {
    field_version = Js_exn.raiseError("graphql_ppx: Field version on type App is missing");
  }
  var value$18 = Js_dict.get(value$2, "iconUrl");
  var field_iconUrl;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var match = Js_json.decodeNull(value$19);
    if (match !== undefined) {
      field_iconUrl = undefined;
    } else {
      var value$20 = Js_json.decodeString(value$19);
      field_iconUrl = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
    }
  } else {
    field_iconUrl = undefined;
  }
  var value$21 = Js_dict.get(value$2, "coverImageUrl");
  var field_coverImageUrl;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var match$1 = Js_json.decodeNull(value$22);
    if (match$1 !== undefined) {
      field_coverImageUrl = undefined;
    } else {
      var value$23 = Js_json.decodeString(value$22);
      field_coverImageUrl = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$22));
    }
  } else {
    field_coverImageUrl = undefined;
  }
  var value$24 = Js_dict.get(value$2, "numScreens");
  var field_numScreens;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var value$26 = Js_json.decodeNumber(value$25);
    field_numScreens = value$26 !== undefined ? value$26 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$25));
  } else {
    field_numScreens = Js_exn.raiseError("graphql_ppx: Field numScreens on type App is missing");
  }
  var value$27 = Js_dict.get(value$2, "expoVersion");
  var field_expoVersion;
  if (value$27 !== undefined) {
    var value$28 = Caml_option.valFromOption(value$27);
    var value$29 = Js_json.decodeString(value$28);
    field_expoVersion = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$28));
  } else {
    field_expoVersion = Js_exn.raiseError("graphql_ppx: Field expoVersion on type App is missing");
  }
  var value$30 = Js_dict.get(value$2, "state");
  var field_state;
  if (value$30 !== undefined) {
    var value$31 = Caml_option.valFromOption(value$30);
    var value$32 = Js_json.decodeString(value$31);
    if (value$32 !== undefined) {
      switch (value$32) {
        case "Draft" :
            field_state = "Draft";
            break;
        case "Live" :
            field_state = "Live";
            break;
        default:
          field_state = Js_exn.raiseError("graphql_ppx: Unknown enum variant for AppState: " + value$32);
      }
    } else {
      field_state = Js_exn.raiseError("graphql_ppx: Expected enum value for AppState, got " + JSON.stringify(value$31));
    }
  } else {
    field_state = Js_exn.raiseError("graphql_ppx: Field state on type App is missing");
  }
  var value$33 = Js_dict.get(value$2, "workspace");
  var field_workspace;
  if (value$33 !== undefined) {
    var value$34 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$33)));
    var value$35 = Js_dict.get(value$34, "__typename");
    var tmp;
    if (value$35 !== undefined) {
      var value$36 = Caml_option.valFromOption(value$35);
      var value$37 = Js_json.decodeString(value$36);
      tmp = value$37 !== undefined ? value$37 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$36));
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type Workspace is missing");
    }
    var value$38 = Js_dict.get(value$34, "uuid");
    var tmp$1;
    if (value$38 !== undefined) {
      var value$39 = Caml_option.valFromOption(value$38);
      var value$40 = Js_json.decodeString(value$39);
      tmp$1 = value$40 !== undefined ? value$40 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$39));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type Workspace is missing");
    }
    field_workspace = {
      __typename: tmp,
      uuid: tmp$1
    };
  } else {
    field_workspace = Js_exn.raiseError("graphql_ppx: Field workspace on type App is missing");
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          name: field_name,
          description: field_description,
          version: field_version,
          iconUrl: field_iconUrl,
          coverImageUrl: field_coverImageUrl,
          numScreens: field_numScreens,
          expoVersion: field_expoVersion,
          state: field_state,
          workspace: field_workspace
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var SimpleAppFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "SimpleAppFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  SimpleAppFields: SimpleAppFields
};

var GQL = {
  Fragment: Fragment
};

export {
  toMinimal ,
  getExpoVersion ,
  getExpoVersionMinimal ,
  GQL ,
  
}
/* Expo-DraftbitBuilder Not a pure module */
