// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as Feature$DraftbitBuilder from "./Feature.bs.js";
import * as BillingPlan$DraftbitBuilder from "./BillingPlan.bs.js";
import * as ExpoAccount$DraftbitBuilder from "./ExpoAccount.bs.js";
import * as ExpoAccountGQL$DraftbitBuilder from "./ExpoAccountGQL.bs.js";

function feature_encode(v) {
  return Js_dict.fromArray([
              [
                "uuid",
                Decco.stringToJson(v.uuid)
              ],
              [
                "name",
                Feature$DraftbitBuilder.t_encode(v.name)
              ]
            ]);
}

function feature_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var uuid = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
  if (uuid.TAG === /* Ok */0) {
    var name = Feature$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
    if (name.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                uuid: uuid._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = uuid._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".uuid" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "firstName",
                Decco.optionToJson(Decco.stringToJson, v.firstName)
              ],
              [
                "lastName",
                Decco.optionToJson(Decco.stringToJson, v.lastName)
              ],
              [
                "email",
                Decco.stringToJson(v.email)
              ],
              [
                "imageUrl",
                Decco.optionToJson(Decco.stringToJson, v.imageUrl)
              ],
              [
                "billingPlan",
                BillingPlan$DraftbitBuilder.simpleBillingPlan_encode(v.billingPlan)
              ],
              [
                "enabledFeatures",
                Decco.arrayToJson(feature_encode, v.enabledFeatures)
              ],
              [
                "numberOfUniqueCollaborators",
                Decco.intToJson(v.numberOfUniqueCollaborators)
              ],
              [
                "numberOfAppStateChangesToLivePast30Days",
                Decco.intToJson(v.numberOfAppStateChangesToLivePast30Days)
              ],
              [
                "expoAccounts",
                Decco.arrayToJson(ExpoAccount$DraftbitBuilder.t_encode, v.expoAccounts)
              ],
              [
                "isAdmin",
                Decco.boolToJson(v.isAdmin)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
  if (uuid.TAG === /* Ok */0) {
    var firstName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "firstName"), null));
    if (firstName.TAG === /* Ok */0) {
      var lastName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastName"), null));
      if (lastName.TAG === /* Ok */0) {
        var email = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "email"), null));
        if (email.TAG === /* Ok */0) {
          var imageUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "imageUrl"), null));
          if (imageUrl.TAG === /* Ok */0) {
            var billingPlan = BillingPlan$DraftbitBuilder.simpleBillingPlan_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "billingPlan"), null));
            if (billingPlan.TAG === /* Ok */0) {
              var enabledFeatures = Decco.arrayFromJson(feature_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "enabledFeatures"), null));
              if (enabledFeatures.TAG === /* Ok */0) {
                var numberOfUniqueCollaborators = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "numberOfUniqueCollaborators"), null));
                if (numberOfUniqueCollaborators.TAG === /* Ok */0) {
                  var numberOfAppStateChangesToLivePast30Days = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "numberOfAppStateChangesToLivePast30Days"), null));
                  if (numberOfAppStateChangesToLivePast30Days.TAG === /* Ok */0) {
                    var expoAccounts = Decco.arrayFromJson(ExpoAccount$DraftbitBuilder.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "expoAccounts"), null));
                    if (expoAccounts.TAG === /* Ok */0) {
                      var isAdmin = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isAdmin"), null));
                      if (isAdmin.TAG === /* Ok */0) {
                        return {
                                TAG: /* Ok */0,
                                _0: {
                                  uuid: uuid._0,
                                  firstName: firstName._0,
                                  lastName: lastName._0,
                                  email: email._0,
                                  imageUrl: imageUrl._0,
                                  billingPlan: billingPlan._0,
                                  enabledFeatures: enabledFeatures._0,
                                  numberOfUniqueCollaborators: numberOfUniqueCollaborators._0,
                                  numberOfAppStateChangesToLivePast30Days: numberOfAppStateChangesToLivePast30Days._0,
                                  expoAccounts: expoAccounts._0,
                                  isAdmin: isAdmin._0
                                }
                              };
                      }
                      var e = isAdmin._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".isAdmin" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = expoAccounts._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".expoAccounts" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = numberOfAppStateChangesToLivePast30Days._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".numberOfAppStateChangesToLivePast30Days" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = numberOfUniqueCollaborators._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".numberOfUniqueCollaborators" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = enabledFeatures._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".enabledFeatures" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = billingPlan._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".billingPlan" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = imageUrl._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".imageUrl" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = email._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".email" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = lastName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".lastName" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = firstName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".firstName" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = uuid._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".uuid" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function toMinimal(u) {
  return {
          __typename: "User",
          uuid: u.uuid,
          email: u.email,
          firstName: u.firstName,
          lastName: u.lastName,
          isAdmin: u.isAdmin,
          imageUrl: u.imageUrl
        };
}

var ppx_printed_query = "fragment SimpleUserFields on User   {\n__typename\nemail  \nuuid  \nfirstName  \nlastName  \nimageUrl  \nenabledFeatures  {\n__typename\nuuid  \nname  \n}\n\nbillingPlan  {\n__typename\n..." + BillingPlan$DraftbitBuilder.GQL.SimpleFragment.SimpleBillingPlanFields.name + "   \n}\n\nexpoAccounts  {\n__typename\n..." + ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.name + "   \n}\n\nnumberOfUniqueCollaborators  \nnumberOfAppStateChangesToLivePast30Days  \nisAdmin  \n}\n" + BillingPlan$DraftbitBuilder.GQL.SimpleFragment.SimpleBillingPlanFields.query + ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type User, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "email");
  var field_email;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_email = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_email = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
  }
  var value$9 = Js_dict.get(value$2, "firstName");
  var field_firstName;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var match = Js_json.decodeNull(value$10);
    if (match !== undefined) {
      field_firstName = undefined;
    } else {
      var value$11 = Js_json.decodeString(value$10);
      field_firstName = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
    }
  } else {
    field_firstName = undefined;
  }
  var value$12 = Js_dict.get(value$2, "lastName");
  var field_lastName;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var match$1 = Js_json.decodeNull(value$13);
    if (match$1 !== undefined) {
      field_lastName = undefined;
    } else {
      var value$14 = Js_json.decodeString(value$13);
      field_lastName = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
    }
  } else {
    field_lastName = undefined;
  }
  var value$15 = Js_dict.get(value$2, "imageUrl");
  var field_imageUrl;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var match$2 = Js_json.decodeNull(value$16);
    if (match$2 !== undefined) {
      field_imageUrl = undefined;
    } else {
      var value$17 = Js_json.decodeString(value$16);
      field_imageUrl = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
    }
  } else {
    field_imageUrl = undefined;
  }
  var value$18 = Js_dict.get(value$2, "enabledFeatures");
  var field_enabledFeatures = value$18 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$18))).map(function (value) {
          var value$1 = Js_json.decodeObject(value);
          if (value$1 === undefined) {
            return Js_exn.raiseError("graphql_ppx: Expected object of type UserFeature, got " + JSON.stringify(value));
          }
          var value$2 = Caml_option.valFromOption(value$1);
          var value$3 = Js_dict.get(value$2, "uuid");
          var field_uuid;
          if (value$3 !== undefined) {
            var value$4 = Caml_option.valFromOption(value$3);
            var value$5 = Js_json.decodeString(value$4);
            field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
          } else {
            field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type UserFeature is missing");
          }
          var value$6 = Js_dict.get(value$2, "name");
          var field_name;
          if (value$6 !== undefined) {
            var value$7 = Caml_option.valFromOption(value$6);
            var value$8 = Js_json.decodeString(value$7);
            if (value$8 !== undefined) {
              switch (value$8) {
                case "All" :
                    field_name = "All";
                    break;
                case "AppBackups" :
                    field_name = "AppBackups";
                    break;
                case "BlockPublishing" :
                    field_name = "BlockPublishing";
                    break;
                case "ConditionalNavigation" :
                    field_name = "ConditionalNavigation";
                    break;
                case "CustomAppCode" :
                    field_name = "CustomAppCode";
                    break;
                case "CustomComponentDefinitions" :
                    field_name = "CustomComponentDefinitions";
                    break;
                case "DrawerNavigator" :
                    field_name = "DrawerNavigator";
                    break;
                case "ExternalBlockVariables" :
                    field_name = "ExternalBlockVariables";
                    break;
                case "ImportExportActions" :
                    field_name = "ImportExportActions";
                    break;
                case "ImportOpenApi" :
                    field_name = "ImportOpenApi";
                    break;
                case "Integrations" :
                    field_name = "Integrations";
                    break;
                case "None" :
                    field_name = "None";
                    break;
                case "ScreenWizard" :
                    field_name = "ScreenWizard";
                    break;
                case "Segment" :
                    field_name = "Segment";
                    break;
                case "Themes" :
                    field_name = "Themes";
                    break;
                case "ViewBuildLogs" :
                    field_name = "ViewBuildLogs";
                    break;
                default:
                  field_name = Js_exn.raiseError("graphql_ppx: Unknown enum variant for FeatureName: " + value$8);
              }
            } else {
              field_name = Js_exn.raiseError("graphql_ppx: Expected enum value for FeatureName, got " + JSON.stringify(value$7));
            }
          } else {
            field_name = Js_exn.raiseError("graphql_ppx: Field name on type UserFeature is missing");
          }
          return {
                  uuid: field_uuid,
                  name: field_name
                };
        }) : Js_exn.raiseError("graphql_ppx: Field enabledFeatures on type User is missing");
  var value$19 = Js_dict.get(value$2, "billingPlan");
  var field_billingPlan = value$19 !== undefined ? Curry._1(BillingPlan$DraftbitBuilder.GQL.SimpleFragment.SimpleBillingPlanFields.parse, Caml_option.valFromOption(value$19)) : Js_exn.raiseError("graphql_ppx: Field billingPlan on type User is missing");
  var value$20 = Js_dict.get(value$2, "expoAccounts");
  var field_expoAccounts = value$20 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$20))).map(function (value) {
          return Curry._1(ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field expoAccounts on type User is missing");
  var value$21 = Js_dict.get(value$2, "numberOfUniqueCollaborators");
  var field_numberOfUniqueCollaborators;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var value$23 = Js_json.decodeNumber(value$22);
    field_numberOfUniqueCollaborators = value$23 !== undefined ? value$23 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$22));
  } else {
    field_numberOfUniqueCollaborators = Js_exn.raiseError("graphql_ppx: Field numberOfUniqueCollaborators on type User is missing");
  }
  var value$24 = Js_dict.get(value$2, "numberOfAppStateChangesToLivePast30Days");
  var field_numberOfAppStateChangesToLivePast30Days;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var value$26 = Js_json.decodeNumber(value$25);
    field_numberOfAppStateChangesToLivePast30Days = value$26 !== undefined ? value$26 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$25));
  } else {
    field_numberOfAppStateChangesToLivePast30Days = Js_exn.raiseError("graphql_ppx: Field numberOfAppStateChangesToLivePast30Days on type User is missing");
  }
  var value$27 = Js_dict.get(value$2, "isAdmin");
  var field_isAdmin;
  if (value$27 !== undefined) {
    var value$28 = Caml_option.valFromOption(value$27);
    var value$29 = Js_json.decodeBoolean(value$28);
    field_isAdmin = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$28));
  } else {
    field_isAdmin = Js_exn.raiseError("graphql_ppx: Field isAdmin on type User is missing");
  }
  return {
          uuid: field_uuid,
          firstName: field_firstName,
          lastName: field_lastName,
          email: field_email,
          imageUrl: field_imageUrl,
          billingPlan: field_billingPlan,
          enabledFeatures: field_enabledFeatures,
          numberOfUniqueCollaborators: field_numberOfUniqueCollaborators,
          numberOfAppStateChangesToLivePast30Days: field_numberOfAppStateChangesToLivePast30Days,
          expoAccounts: field_expoAccounts,
          isAdmin: field_isAdmin
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var SimpleUserFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "SimpleUserFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var SimpleUserFragment = {
  SimpleUserFields: SimpleUserFields
};

var GQL = {
  SimpleUserFragment: SimpleUserFragment
};

export {
  feature_encode ,
  feature_decode ,
  t_encode ,
  t_decode ,
  toMinimal ,
  GQL ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
