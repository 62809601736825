// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as ApolloClient$DraftbitBuilder from "./bindings/ReasonApollo/ApolloClient.bs.js";

var ppx_printed_query = "fragment AssetFields on Asset   {\n__typename\n__typename  \nuuid  \nslug  \nfileName  \nfileType  \nmimeType  \nurl  \nsnackUrl  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type Asset, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type Asset is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type Asset is missing");
  }
  var value$9 = Js_dict.get(value$2, "slug");
  var field_slug;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_slug = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_slug = Js_exn.raiseError("graphql_ppx: Field slug on type Asset is missing");
  }
  var value$12 = Js_dict.get(value$2, "fileName");
  var field_fileName;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    field_fileName = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
  } else {
    field_fileName = Js_exn.raiseError("graphql_ppx: Field fileName on type Asset is missing");
  }
  var value$15 = Js_dict.get(value$2, "fileType");
  var field_fileType;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeString(value$16);
    field_fileType = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
  } else {
    field_fileType = Js_exn.raiseError("graphql_ppx: Field fileType on type Asset is missing");
  }
  var value$18 = Js_dict.get(value$2, "mimeType");
  var field_mimeType;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeString(value$19);
    field_mimeType = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
  } else {
    field_mimeType = Js_exn.raiseError("graphql_ppx: Field mimeType on type Asset is missing");
  }
  var value$21 = Js_dict.get(value$2, "url");
  var field_url;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var value$23 = Js_json.decodeString(value$22);
    field_url = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$22));
  } else {
    field_url = Js_exn.raiseError("graphql_ppx: Field url on type Asset is missing");
  }
  var value$24 = Js_dict.get(value$2, "snackUrl");
  var field_snackUrl;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var match = Js_json.decodeNull(value$25);
    if (match !== undefined) {
      field_snackUrl = undefined;
    } else {
      var value$26 = Js_json.decodeString(value$25);
      field_snackUrl = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$25));
    }
  } else {
    field_snackUrl = undefined;
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          fileName: field_fileName,
          fileType: field_fileType,
          mimeType: field_mimeType,
          url: field_url,
          slug: field_slug,
          snackUrl: field_snackUrl
        };
}

var name = "AssetFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var AssetFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var AssetFragment = {
  AssetFields: AssetFields
};

var ppx_printed_query$1 = "query GetAssets($filter: AssetFilter!)  {\n__typename\nassets(filter: $filter)  {\n__typename\n...AssetFields   \n}\n\n}\nfragment AssetFields on Asset   {\n__typename\n__typename  \nuuid  \nslug  \nfileName  \nfileType  \nmimeType  \nurl  \nsnackUrl  \n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "assets");
  return {
          assets: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(parse) : Js_exn.raiseError("graphql_ppx: Field assets on type Query is missing")
        };
}

function json_of_AssetFilter(value) {
  var v = value.fileType;
  return Js_dict.fromArray([
                [
                  "appUuid",
                  value.appUuid
                ],
                [
                  "fileType",
                  v !== undefined ? v : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(filter, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "filter",
                    json_of_AssetFilter(filter)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var filter = variables.filter;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "filter",
                    json_of_AssetFilter(filter)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(filter, param) {
  return Js_dict.fromArray([[
                  "filter",
                  json_of_AssetFilter(filter)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, filter, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "filter",
                      json_of_AssetFilter(filter)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function refetch(appUuid, fileType, param) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make({
                  appUuid: appUuid,
                  fileType: fileType
                }, undefined));
}

function useQuery(appUuid, fileType, skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables({
                appUuid: appUuid,
                fileType: fileType
              }, undefined)), undefined, undefined, undefined, skip || !Uuid$DraftbitBuilder.isUuid(appUuid), undefined, undefined, definition);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (d) {
                return d.assets;
              }));
}

var List = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_AssetFilter: json_of_AssetFilter,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  refetch: refetch,
  useQuery: useQuery
};

var AssetReadQuery = ApolloClient$DraftbitBuilder.ReadQuery({
      query: ppx_printed_query$1,
      parse: parse$1
    });

var AssetWriteQuery = ApolloClient$DraftbitBuilder.WriteQuery({
      query: ppx_printed_query$1,
      parse: parse$1
    });

function make$1(asset) {
  return {
          __typename: "Mutation",
          createAsset: asset
        };
}

var OptimisticCreate = {
  make: make$1
};

var ppx_printed_query$2 = "mutation CreateAsset($input: CreateAssetInput!)  {\n__typename\ncreateAsset(input: $input)  {\n__typename\n...AssetFields   \n}\n\n}\nfragment AssetFields on Asset   {\n__typename\n__typename  \nuuid  \nslug  \nfileName  \nfileType  \nmimeType  \nurl  \nsnackUrl  \n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createAsset");
  return {
          createAsset: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field createAsset on type Mutation is missing")
        };
}

function json_of_CreateAssetInput(value) {
  var v = value.snackUrl;
  return Js_dict.fromArray([
                [
                  "appUuid",
                  value.appUuid
                ],
                [
                  "fileName",
                  value.fileName
                ],
                [
                  "fileType",
                  value.fileType
                ],
                [
                  "mimeType",
                  value.mimeType
                ],
                [
                  "snackUrl",
                  v !== undefined ? v : null
                ],
                [
                  "url",
                  value.url
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$2(input, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CreateAssetInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$1(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CreateAssetInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$1(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_CreateAssetInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_CreateAssetInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$1
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var CreateAsset = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  json_of_CreateAssetInput: json_of_CreateAssetInput,
  make: make$2,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function addToCache(appUuid, assetType, client, mutationResult) {
  var data = mutationResult.data;
  if (data === undefined) {
    return ;
  }
  var asset = Caml_option.valFromOption(data).createAsset;
  var query = make({
        appUuid: appUuid,
        fileType: assetType
      }, undefined);
  var options = ApolloHooks$DraftbitBuilder.toQueryObj(query);
  var cachedResponse;
  try {
    cachedResponse = client.readQuery(options);
  }
  catch (exn){
    return ;
  }
  if (cachedResponse == null) {
    return ;
  }
  var updatedAssets = cachedResponse.assets.concat([asset]);
  var data$1 = {
    assets: updatedAssets
  };
  return Curry._4(AssetWriteQuery.make, client, Caml_option.some(query.variables), data$1, undefined);
}

function make$3(uuid) {
  return {
          __typename: "Mutation",
          removeAsset: uuid
        };
}

var OptimisticRemove = {
  make: make$3
};

var ppx_printed_query$3 = "mutation RemoveAsset($uuid: ID!)  {\n__typename\nremoveAsset(uuid: $uuid)  \n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "removeAsset");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field removeAsset on type Mutation is missing");
  }
  return {
          removeAsset: tmp
        };
}

function make$4(uuid, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "uuid",
                    uuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$2(variables) {
  var uuid = variables.uuid;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "uuid",
                    uuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$2(uuid, param) {
  return Js_dict.fromArray([[
                  "uuid",
                  uuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, uuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "uuid",
                      uuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$2
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var RemoveAsset = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$4,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function removeFromCache(appUuid, assetType, client, mutationResult) {
  var data = mutationResult.data;
  if (data === undefined) {
    return ;
  }
  var assetUuid = Caml_option.valFromOption(data).removeAsset;
  var query = make({
        appUuid: appUuid,
        fileType: assetType
      }, undefined);
  var options = ApolloHooks$DraftbitBuilder.toQueryObj(query);
  var cachedResponse;
  try {
    cachedResponse = client.readQuery(options);
  }
  catch (exn){
    return ;
  }
  if (cachedResponse == null) {
    return ;
  }
  var data$1 = {
    assets: cachedResponse.assets.filter(function (asset) {
          return asset.uuid !== assetUuid;
        })
  };
  return Curry._4(AssetWriteQuery.make, client, Caml_option.some(query.variables), data$1, undefined);
}

export {
  AssetFragment ,
  List ,
  AssetReadQuery ,
  AssetWriteQuery ,
  OptimisticCreate ,
  CreateAsset ,
  addToCache ,
  OptimisticRemove ,
  RemoveAsset ,
  removeFromCache ,
  
}
/* AssetReadQuery Not a pure module */
