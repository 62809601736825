import React, { forwardRef } from "react";
import { Link, NavLink } from "react-router-dom";

const WrappedLink = forwardRef(({ to_, ...props }, ref) => (
  <Link to={to_} {...props} ref={ref} />
));

const WrappedNavLink = forwardRef(({ to_, ...props }, ref) => (
  <NavLink to={to_} {...props} ref={ref} />
));

export { WrappedLink as Link, WrappedNavLink as NavLink };
