// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Text$DraftbitBuilder from "../Text/Text.bs.js";
import * as Utils$DraftbitBuilder from "../../../utilities/Utils.bs.js";
import * as DomUtils$DraftbitBuilder from "../../../utilities/DomUtils.bs.js";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__HtmlElement.bs.js";
import * as ReactUtils$DraftbitBuilder from "../../../utilities/ReactUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "../../../utilities/OptionUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "../../../utilities/StringUtils.bs.js";
import * as DataCyWrapper$DraftbitBuilder from "../../../components/DataCyWrapper.bs.js";
import * as LoadingSpinner$DraftbitBuilder from "../../../components/LoadingSpinner.bs.js";
import * as InputReminderTooltip$DraftbitBuilder from "../InputReminderTooltip.bs.js";

var structure = "w-full text-sm font-sans rounded border placeholder-mono-300 text-mono-50 px-2 py-1\n      focus:outline-none focus:border-solid";

var $$default = "focus:text-mono-50 focus:placeholder-mono-300 focus:border-selection-400 disabled:text-mono-200";

var error = "border-warning-300 text-warning-300 placeholder-warning-200 focus:border-warning-300 focus:text-warning-300 focus:outline-none focus:border-solid";

function buildStyles(classNameOpt, hasError, bgColorClassName, borderColorClassName, heightClassName, param) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return Cn.make({
              hd: structure,
              tl: {
                hd: bgColorClassName,
                tl: {
                  hd: borderColorClassName,
                  tl: {
                    hd: heightClassName,
                    tl: {
                      hd: Cn.ifTrue($$default, !hasError),
                      tl: {
                        hd: Cn.ifTrue(error, hasError),
                        tl: {
                          hd: className,
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Styles = {
  structure: structure,
  $$default: $$default,
  error: error,
  buildStyles: buildStyles
};

function makeDefault(internalValue) {
  return {
          internalValue: internalValue,
          status: /* Ready */0
        };
}

function reducer(onInternalValueChange, state, action) {
  switch (action.TAG | 0) {
    case /* Value */0 :
        var internalValue = action._0;
        Curry._1(onInternalValueChange, internalValue);
        return {
                internalValue: internalValue,
                status: /* Active */1
              };
    case /* Reset */1 :
        var internalValue$1 = action._0;
        Curry._1(onInternalValueChange, internalValue$1);
        return {
                internalValue: internalValue$1,
                status: /* Ready */0
              };
    case /* Status */2 :
        return {
                internalValue: state.internalValue,
                status: action._0
              };
    
  }
}

var State = {
  makeDefault: makeDefault,
  reducer: reducer
};

var make = React.forwardRef(function (Props, ref_) {
      var id = Props.id;
      var name = Props.name;
      var pattern = Props.pattern;
      var classNameOpt = Props.className;
      var maxLength = Props.maxLength;
      var valueOpt = Props.value;
      var requiredOpt = Props.required;
      var placeholderOpt = Props.placeholder;
      var onChange = Props.onChange;
      var onPressEnter = Props.onPressEnter;
      var onPressEscapeOpt = Props.onPressEscape;
      var onFocus = Props.onFocus;
      var onBlur = Props.onBlur;
      var errorMessageOpt = Props.errorMessage;
      var errorPlacementOpt = Props.errorPlacement;
      var errorOpt = Props.error;
      var loadingOpt = Props.loading;
      var disabledOpt = Props.disabled;
      var autoFocus = Props.autoFocus;
      var autoSelectOpt = Props.autoSelect;
      var dataCyOpt = Props.dataCy;
      var bgColorClassNameOpt = Props.bgColorClassName;
      var borderColorClassNameOpt = Props.borderColorClassName;
      var heightClassNameOpt = Props.heightClassName;
      var onInternalValueChangeOpt = Props.onInternalValueChange;
      var hideReminderTooltipOpt = Props.hideReminderTooltip;
      var sanitizationTypeOpt = Props.sanitizationType;
      var spellCheck = Props.spellCheck;
      var title = Props.title;
      var onTextWidthOpt = Props.onTextWidth;
      var className = classNameOpt !== undefined ? classNameOpt : "";
      var value = valueOpt !== undefined ? valueOpt : "";
      var required = requiredOpt !== undefined ? requiredOpt : false;
      var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
      var onPressEscape = onPressEscapeOpt !== undefined ? onPressEscapeOpt : Utils$DraftbitBuilder.noop;
      var errorMessage = errorMessageOpt !== undefined ? Caml_option.valFromOption(errorMessageOpt) : undefined;
      var errorPlacement = errorPlacementOpt !== undefined ? errorPlacementOpt : "Right";
      var error = errorOpt !== undefined ? errorOpt : false;
      var loading = loadingOpt !== undefined ? loadingOpt : false;
      var disabled = disabledOpt !== undefined ? disabledOpt : loading;
      var autoSelect = autoSelectOpt !== undefined ? autoSelectOpt : false;
      var dataCy = dataCyOpt !== undefined ? dataCyOpt : "TextInput";
      var bgColorClassName = bgColorClassNameOpt !== undefined ? bgColorClassNameOpt : "bg-mono-800";
      var borderColorClassName = borderColorClassNameOpt !== undefined ? borderColorClassNameOpt : "border-mono-800";
      var heightClassName = heightClassNameOpt !== undefined ? heightClassNameOpt : "h-6";
      var onInternalValueChange = onInternalValueChangeOpt !== undefined ? onInternalValueChangeOpt : Utils$DraftbitBuilder.noop;
      var hideReminderTooltip = hideReminderTooltipOpt !== undefined ? hideReminderTooltipOpt : false;
      var sanitizationType = sanitizationTypeOpt !== undefined ? sanitizationTypeOpt : "Text";
      var onTextWidth = onTextWidthOpt !== undefined ? onTextWidthOpt : Utils$DraftbitBuilder.noop;
      var isDeferred = typeof onChange === "string" ? false : onChange.NAME === "Deferred";
      var match = React.useReducer((function (param, param$1) {
              return reducer(onInternalValueChange, param, param$1);
            }), {
            internalValue: value,
            status: /* Ready */0
          });
      var dispatch = match[1];
      var state = match[0];
      var inputRef = Belt_Option.getWithDefault((ref_ == null) ? undefined : Caml_option.some(ref_), React.createRef());
      var styles = buildStyles(className, error || errorMessage !== undefined, bgColorClassName, borderColorClassName, heightClassName, undefined);
      React.useEffect((function () {
              if (autoSelect) {
                OptionUtils$DraftbitBuilder.forEachNullable(inputRef.current, (function (prim) {
                        prim.select();
                        
                      }));
              }
              
            }), [autoSelect]);
      React.useLayoutEffect((function () {
              OptionUtils$DraftbitBuilder.forEachNullable(inputRef.current, (function (el) {
                      var text = OptionUtils$DraftbitBuilder.mapOrEmpty(Webapi__Dom__HtmlElement.ofElement(el), (function (prim) {
                              return prim.value;
                            }));
                      var style = window.getComputedStyle(el);
                      var font = style.font;
                      return Curry._1(onTextWidth, DomUtils$DraftbitBuilder.getTextWidth(text, font));
                    }));
              
            }), [inputRef.current]);
      React.useEffect((function () {
              if (value !== state.internalValue) {
                Curry._1(dispatch, {
                      TAG: /* Reset */1,
                      _0: value
                    });
              }
              
            }), [value]);
      React.useEffect((function () {
              if (state.status !== /* Active */1) {
                return ;
              }
              var idleTimer = setTimeout((function (param) {
                      return Curry._1(dispatch, {
                                  TAG: /* Status */2,
                                  _0: /* Idle */2
                                });
                    }), 2000);
              return (function (param) {
                        clearTimeout(idleTimer);
                        
                      });
            }), [state.status]);
      var handleChangeInternal = function (e) {
        var inputElement = e.target;
        var value = inputElement.value;
        var nextValue = StringUtils$DraftbitBuilder.SpecialCharacters.sanitize(value, sanitizationType);
        var style = window.getComputedStyle(inputElement);
        var font = style.font;
        Curry._1(onTextWidth, DomUtils$DraftbitBuilder.getTextWidth(nextValue, font));
        if (onChange.NAME === "Controlled") {
          Curry._1(dispatch, {
                TAG: /* Value */0,
                _0: nextValue
              });
          return Curry._1(onChange.VAL, nextValue);
        } else {
          return Curry._1(dispatch, {
                      TAG: /* Value */0,
                      _0: nextValue
                    });
        }
      };
      var onBlur$1 = function (param) {
        if (typeof onChange === "string" || !(onChange.NAME === "Deferred" && value !== state.internalValue)) {
          
        } else {
          Curry._1(onChange.VAL, state.internalValue);
          Curry._1(dispatch, {
                TAG: /* Status */2,
                _0: /* Ready */0
              });
        }
        return Belt_Option.forEach(onBlur, (function (f) {
                      return Curry._1(f, undefined);
                    }));
      };
      var onPressEnter$1 = Belt_Option.getWithDefault(onPressEnter, (function (param) {
              return Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (prim) {
                            prim.blur();
                            
                          }));
            }));
      var onKeyPress = function (e) {
        if (e.key === "Enter") {
          return Curry._1(onPressEnter$1, e);
        }
        
      };
      var onKeyDown = function (e) {
        if (e.key === "Escape") {
          return Curry._1(onPressEscape, e);
        }
        
      };
      var tmp = {
        ref: inputRef,
        className: styles,
        disabled: disabled,
        placeholder: placeholder,
        required: required,
        value: state.internalValue,
        onKeyDown: onKeyDown,
        onKeyPress: onKeyPress,
        onBlur: onBlur$1,
        onChange: handleChangeInternal
      };
      if (id !== undefined) {
        tmp.id = Caml_option.valFromOption(id);
      }
      if (spellCheck !== undefined) {
        tmp.spellCheck = Caml_option.valFromOption(spellCheck);
      }
      if (title !== undefined) {
        tmp.title = Caml_option.valFromOption(title);
      }
      if (autoFocus !== undefined) {
        tmp.autoFocus = Caml_option.valFromOption(autoFocus);
      }
      if (maxLength !== undefined) {
        tmp.maxLength = Caml_option.valFromOption(maxLength);
      }
      if (name !== undefined) {
        tmp.name = Caml_option.valFromOption(name);
      }
      if (pattern !== undefined) {
        tmp.pattern = Caml_option.valFromOption(pattern);
      }
      if (onFocus !== undefined) {
        tmp.onFocus = Caml_option.valFromOption(onFocus);
      }
      return React.createElement(InputReminderTooltip$DraftbitBuilder.make, {
                  visible: !hideReminderTooltip && isDeferred && state.status === /* Idle */2,
                  onClose: (function (param) {
                      return Curry._1(dispatch, {
                                  TAG: /* Status */2,
                                  _0: /* Ready */0
                                });
                    }),
                  children: React.createElement("div", {
                        className: Cn.make({
                              hd: "flex gap-2 w-full h-full",
                              tl: {
                                hd: Cn.ifTrue("relative", loading),
                                tl: {
                                  hd: Cn.ifTrue("opacity-80", disabled),
                                  tl: {
                                    hd: errorPlacement === "Bottom" ? "flex-col" : "flex-row items-center",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(DataCyWrapper$DraftbitBuilder.make, {
                            children: React.createElement("input", tmp),
                            name: dataCy
                          }), ReactUtils$DraftbitBuilder.ifTrueRender(loading, React.createElement("div", {
                                className: "absolute right-1 top-1/2 -translate-y-1/2"
                              }, React.createElement(LoadingSpinner$DraftbitBuilder.make, {
                                    size: 20,
                                    inline: true
                                  }))), ReactUtils$DraftbitBuilder.maybeRender(errorMessage, (function (error) {
                              return React.createElement(Text$DraftbitBuilder.make, {
                                          color: "Warning400",
                                          children: error,
                                          isMarkdown: true
                                        });
                            })))
                });
    });

export {
  Styles ,
  State ,
  make ,
  
}
/* make Not a pure module */
