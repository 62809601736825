// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as BlockType$DraftbitBuilder from "./BlockType.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as StatusBarColor$DraftbitBuilder from "./StatusBarColor.bs.js";

function uuidAndTypename_encode(v) {
  return Js_dict.fromArray([
              [
                "__typename",
                Decco.stringToJson(v.__typename)
              ],
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ]
            ]);
}

function uuidAndTypename_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var __typename = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "__typename"), null));
  if (__typename.TAG === /* Ok */0) {
    var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
    if (uuid.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                __typename: __typename._0,
                uuid: uuid._0
              }
            };
    }
    var e = uuid._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".uuid" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = __typename._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".__typename" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "__typename",
                Decco.stringToJson(v.__typename)
              ],
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "blockType",
                BlockType$DraftbitBuilder.t_encode(v.blockType)
              ],
              [
                "name",
                Decco.stringToJson(v.name)
              ],
              [
                "slug",
                Decco.stringToJson(v.slug)
              ],
              [
                "deepLink",
                Decco.boolToJson(v.deepLink)
              ],
              [
                "createdAt",
                v.createdAt
              ],
              [
                "deletedAt",
                Decco.optionToJson((function (v) {
                        return v;
                      }), v.deletedAt)
              ],
              [
                "updatedAt",
                Decco.optionToJson((function (v) {
                        return v;
                      }), v.updatedAt)
              ],
              [
                "description",
                Decco.stringToJson(v.description)
              ],
              [
                "statusBarColor",
                StatusBarColor$DraftbitBuilder.t_encode(v.statusBarColor)
              ],
              [
                "isHidden",
                Decco.boolToJson(v.isHidden)
              ],
              [
                "isTemporary",
                Decco.boolToJson(v.isTemporary)
              ],
              [
                "component",
                uuidAndTypename_encode(v.component)
              ],
              [
                "publishedBlockOriginal",
                Decco.optionToJson(uuidAndTypename_encode, v.publishedBlockOriginal)
              ],
              [
                "publishedBlockPublished",
                Decco.optionToJson(uuidAndTypename_encode, v.publishedBlockPublished)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var __typename = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "__typename"), null));
  if (__typename.TAG === /* Ok */0) {
    var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
    if (uuid.TAG === /* Ok */0) {
      var blockType = BlockType$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "blockType"), null));
      if (blockType.TAG === /* Ok */0) {
        var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
        if (name.TAG === /* Ok */0) {
          var slug = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "slug"), null));
          if (slug.TAG === /* Ok */0) {
            var deepLink = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "deepLink"), null));
            if (deepLink.TAG === /* Ok */0) {
              var createdAt = {
                TAG: /* Ok */0,
                _0: Belt_Option.getWithDefault(Js_dict.get(dict$1, "createdAt"), null)
              };
              if (createdAt.TAG === /* Ok */0) {
                var deletedAt = Decco.optionFromJson((function (v) {
                        return {
                                TAG: /* Ok */0,
                                _0: v
                              };
                      }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "deletedAt"), null));
                if (deletedAt.TAG === /* Ok */0) {
                  var updatedAt = Decco.optionFromJson((function (v) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: v
                                };
                        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "updatedAt"), null));
                  if (updatedAt.TAG === /* Ok */0) {
                    var description = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
                    if (description.TAG === /* Ok */0) {
                      var statusBarColor = StatusBarColor$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "statusBarColor"), null));
                      if (statusBarColor.TAG === /* Ok */0) {
                        var isHidden = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isHidden"), null));
                        if (isHidden.TAG === /* Ok */0) {
                          var isTemporary = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isTemporary"), null));
                          if (isTemporary.TAG === /* Ok */0) {
                            var component = uuidAndTypename_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "component"), null));
                            if (component.TAG === /* Ok */0) {
                              var publishedBlockOriginal = Decco.optionFromJson(uuidAndTypename_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "publishedBlockOriginal"), null));
                              if (publishedBlockOriginal.TAG === /* Ok */0) {
                                var publishedBlockPublished = Decco.optionFromJson(uuidAndTypename_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "publishedBlockPublished"), null));
                                if (publishedBlockPublished.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: {
                                            __typename: __typename._0,
                                            uuid: uuid._0,
                                            blockType: blockType._0,
                                            name: name._0,
                                            slug: slug._0,
                                            deepLink: deepLink._0,
                                            createdAt: createdAt._0,
                                            deletedAt: deletedAt._0,
                                            updatedAt: updatedAt._0,
                                            description: description._0,
                                            statusBarColor: statusBarColor._0,
                                            isHidden: isHidden._0,
                                            isTemporary: isTemporary._0,
                                            component: component._0,
                                            publishedBlockOriginal: publishedBlockOriginal._0,
                                            publishedBlockPublished: publishedBlockPublished._0
                                          }
                                        };
                                }
                                var e = publishedBlockPublished._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".publishedBlockPublished" + e.path,
                                          message: e.message,
                                          value: e.value
                                        }
                                      };
                              }
                              var e$1 = publishedBlockOriginal._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".publishedBlockOriginal" + e$1.path,
                                        message: e$1.message,
                                        value: e$1.value
                                      }
                                    };
                            }
                            var e$2 = component._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".component" + e$2.path,
                                      message: e$2.message,
                                      value: e$2.value
                                    }
                                  };
                          }
                          var e$3 = isTemporary._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".isTemporary" + e$3.path,
                                    message: e$3.message,
                                    value: e$3.value
                                  }
                                };
                        }
                        var e$4 = isHidden._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".isHidden" + e$4.path,
                                  message: e$4.message,
                                  value: e$4.value
                                }
                              };
                      }
                      var e$5 = statusBarColor._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".statusBarColor" + e$5.path,
                                message: e$5.message,
                                value: e$5.value
                              }
                            };
                    }
                    var e$6 = description._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".description" + e$6.path,
                              message: e$6.message,
                              value: e$6.value
                            }
                          };
                  }
                  var e$7 = updatedAt._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".updatedAt" + e$7.path,
                            message: e$7.message,
                            value: e$7.value
                          }
                        };
                }
                var e$8 = deletedAt._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".deletedAt" + e$8.path,
                          message: e$8.message,
                          value: e$8.value
                        }
                      };
              }
              var e$9 = createdAt._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".createdAt" + e$9.path,
                        message: e$9.message,
                        value: e$9.value
                      }
                    };
            }
            var e$10 = deepLink._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".deepLink" + e$10.path,
                      message: e$10.message,
                      value: e$10.value
                    }
                  };
          }
          var e$11 = slug._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".slug" + e$11.path,
                    message: e$11.message,
                    value: e$11.value
                  }
                };
        }
        var e$12 = name._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".name" + e$12.path,
                  message: e$12.message,
                  value: e$12.value
                }
              };
      }
      var e$13 = blockType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".blockType" + e$13.path,
                message: e$13.message,
                value: e$13.value
              }
            };
    }
    var e$14 = uuid._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".uuid" + e$14.path,
              message: e$14.message,
              value: e$14.value
            }
          };
  }
  var e$15 = __typename._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".__typename" + e$15.path,
            message: e$15.message,
            value: e$15.value
          }
        };
}

var ppx_printed_query = "fragment SimpleScreenFields on Screen   {\n__typename\n__typename  \nuuid  \nblockType  \nname  \ndeepLink  \nslug  \ndeletedAt  \ncreatedAt  \nupdatedAt  \ndescription  \nstatusBarColor  \nisHidden  \nisTemporary  \ncomponent  {\n__typename\n__typename  \nuuid  \n}\n\npublishedBlockOriginal  {\n__typename\n__typename  \nuuid  \n}\n\npublishedBlockPublished  {\n__typename\n__typename  \nuuid  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type Screen, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type Screen is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type Screen is missing");
  }
  var value$9 = Js_dict.get(value$2, "blockType");
  var field_blockType;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    if (value$11 !== undefined) {
      switch (value$11) {
        case "Block" :
            field_blockType = "Block";
            break;
        case "Screen" :
            field_blockType = "Screen";
            break;
        default:
          field_blockType = Js_exn.raiseError("graphql_ppx: Unknown enum variant for BlockType: " + value$11);
      }
    } else {
      field_blockType = Js_exn.raiseError("graphql_ppx: Expected enum value for BlockType, got " + JSON.stringify(value$10));
    }
  } else {
    field_blockType = Js_exn.raiseError("graphql_ppx: Field blockType on type Screen is missing");
  }
  var value$12 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    field_name = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type Screen is missing");
  }
  var value$15 = Js_dict.get(value$2, "deepLink");
  var field_deepLink;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeBoolean(value$16);
    field_deepLink = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$16));
  } else {
    field_deepLink = Js_exn.raiseError("graphql_ppx: Field deepLink on type Screen is missing");
  }
  var value$18 = Js_dict.get(value$2, "slug");
  var field_slug;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeString(value$19);
    field_slug = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
  } else {
    field_slug = Js_exn.raiseError("graphql_ppx: Field slug on type Screen is missing");
  }
  var value$21 = Js_dict.get(value$2, "deletedAt");
  var field_deletedAt;
  if (value$21 !== undefined) {
    var value$22 = Caml_option.valFromOption(value$21);
    var match = Js_json.decodeNull(value$22);
    field_deletedAt = match !== undefined ? undefined : Caml_option.some(value$22);
  } else {
    field_deletedAt = undefined;
  }
  var value$23 = Js_dict.get(value$2, "createdAt");
  var field_createdAt = value$23 !== undefined ? Caml_option.valFromOption(value$23) : Js_exn.raiseError("graphql_ppx: Field createdAt on type Screen is missing");
  var value$24 = Js_dict.get(value$2, "updatedAt");
  var field_updatedAt;
  if (value$24 !== undefined) {
    var value$25 = Caml_option.valFromOption(value$24);
    var match$1 = Js_json.decodeNull(value$25);
    field_updatedAt = match$1 !== undefined ? undefined : Caml_option.some(value$25);
  } else {
    field_updatedAt = undefined;
  }
  var value$26 = Js_dict.get(value$2, "description");
  var field_description;
  if (value$26 !== undefined) {
    var value$27 = Caml_option.valFromOption(value$26);
    var value$28 = Js_json.decodeString(value$27);
    field_description = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
  } else {
    field_description = Js_exn.raiseError("graphql_ppx: Field description on type Screen is missing");
  }
  var value$29 = Js_dict.get(value$2, "statusBarColor");
  var field_statusBarColor;
  if (value$29 !== undefined) {
    var value$30 = Caml_option.valFromOption(value$29);
    var value$31 = Js_json.decodeString(value$30);
    if (value$31 !== undefined) {
      switch (value$31) {
        case "Dark" :
            field_statusBarColor = "Dark";
            break;
        case "Default" :
            field_statusBarColor = "Default";
            break;
        case "Light" :
            field_statusBarColor = "Light";
            break;
        default:
          field_statusBarColor = Js_exn.raiseError("graphql_ppx: Unknown enum variant for StatusBarColor: " + value$31);
      }
    } else {
      field_statusBarColor = Js_exn.raiseError("graphql_ppx: Expected enum value for StatusBarColor, got " + JSON.stringify(value$30));
    }
  } else {
    field_statusBarColor = Js_exn.raiseError("graphql_ppx: Field statusBarColor on type Screen is missing");
  }
  var value$32 = Js_dict.get(value$2, "isHidden");
  var field_isHidden;
  if (value$32 !== undefined) {
    var value$33 = Caml_option.valFromOption(value$32);
    var value$34 = Js_json.decodeBoolean(value$33);
    field_isHidden = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$33));
  } else {
    field_isHidden = Js_exn.raiseError("graphql_ppx: Field isHidden on type Screen is missing");
  }
  var value$35 = Js_dict.get(value$2, "isTemporary");
  var field_isTemporary;
  if (value$35 !== undefined) {
    var value$36 = Caml_option.valFromOption(value$35);
    var value$37 = Js_json.decodeBoolean(value$36);
    field_isTemporary = value$37 !== undefined ? value$37 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$36));
  } else {
    field_isTemporary = Js_exn.raiseError("graphql_ppx: Field isTemporary on type Screen is missing");
  }
  var value$38 = Js_dict.get(value$2, "component");
  var field_component;
  if (value$38 !== undefined) {
    var value$39 = Caml_option.valFromOption(value$38);
    var value$40 = Js_json.decodeObject(value$39);
    if (value$40 !== undefined) {
      var value$41 = Caml_option.valFromOption(value$40);
      var value$42 = Js_dict.get(value$41, "__typename");
      var field___typename$1;
      if (value$42 !== undefined) {
        var value$43 = Caml_option.valFromOption(value$42);
        var value$44 = Js_json.decodeString(value$43);
        field___typename$1 = value$44 !== undefined ? value$44 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$43));
      } else {
        field___typename$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type Component is missing");
      }
      var value$45 = Js_dict.get(value$41, "uuid");
      var field_uuid$1;
      if (value$45 !== undefined) {
        var value$46 = Caml_option.valFromOption(value$45);
        var value$47 = Js_json.decodeString(value$46);
        field_uuid$1 = value$47 !== undefined ? value$47 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$46));
      } else {
        field_uuid$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type Component is missing");
      }
      field_component = {
        __typename: field___typename$1,
        uuid: field_uuid$1
      };
    } else {
      field_component = Js_exn.raiseError("graphql_ppx: Expected object of type Component, got " + JSON.stringify(value$39));
    }
  } else {
    field_component = Js_exn.raiseError("graphql_ppx: Field component on type Screen is missing");
  }
  var value$48 = Js_dict.get(value$2, "publishedBlockOriginal");
  var field_publishedBlockOriginal;
  if (value$48 !== undefined) {
    var value$49 = Caml_option.valFromOption(value$48);
    var match$2 = Js_json.decodeNull(value$49);
    if (match$2 !== undefined) {
      field_publishedBlockOriginal = undefined;
    } else {
      var value$50 = Js_json.decodeObject(value$49);
      var tmp;
      if (value$50 !== undefined) {
        var value$51 = Caml_option.valFromOption(value$50);
        var value$52 = Js_dict.get(value$51, "__typename");
        var field___typename$2;
        if (value$52 !== undefined) {
          var value$53 = Caml_option.valFromOption(value$52);
          var value$54 = Js_json.decodeString(value$53);
          field___typename$2 = value$54 !== undefined ? value$54 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$53));
        } else {
          field___typename$2 = Js_exn.raiseError("graphql_ppx: Field __typename on type PublishedBlock is missing");
        }
        var value$55 = Js_dict.get(value$51, "uuid");
        var field_uuid$2;
        if (value$55 !== undefined) {
          var value$56 = Caml_option.valFromOption(value$55);
          var value$57 = Js_json.decodeString(value$56);
          field_uuid$2 = value$57 !== undefined ? value$57 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$56));
        } else {
          field_uuid$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type PublishedBlock is missing");
        }
        tmp = {
          __typename: field___typename$2,
          uuid: field_uuid$2
        };
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Expected object of type PublishedBlock, got " + JSON.stringify(value$49));
      }
      field_publishedBlockOriginal = tmp;
    }
  } else {
    field_publishedBlockOriginal = undefined;
  }
  var value$58 = Js_dict.get(value$2, "publishedBlockPublished");
  var field_publishedBlockPublished;
  if (value$58 !== undefined) {
    var value$59 = Caml_option.valFromOption(value$58);
    var match$3 = Js_json.decodeNull(value$59);
    if (match$3 !== undefined) {
      field_publishedBlockPublished = undefined;
    } else {
      var value$60 = Js_json.decodeObject(value$59);
      var tmp$1;
      if (value$60 !== undefined) {
        var value$61 = Caml_option.valFromOption(value$60);
        var value$62 = Js_dict.get(value$61, "__typename");
        var field___typename$3;
        if (value$62 !== undefined) {
          var value$63 = Caml_option.valFromOption(value$62);
          var value$64 = Js_json.decodeString(value$63);
          field___typename$3 = value$64 !== undefined ? value$64 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$63));
        } else {
          field___typename$3 = Js_exn.raiseError("graphql_ppx: Field __typename on type PublishedBlock is missing");
        }
        var value$65 = Js_dict.get(value$61, "uuid");
        var field_uuid$3;
        if (value$65 !== undefined) {
          var value$66 = Caml_option.valFromOption(value$65);
          var value$67 = Js_json.decodeString(value$66);
          field_uuid$3 = value$67 !== undefined ? value$67 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$66));
        } else {
          field_uuid$3 = Js_exn.raiseError("graphql_ppx: Field uuid on type PublishedBlock is missing");
        }
        tmp$1 = {
          __typename: field___typename$3,
          uuid: field_uuid$3
        };
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Expected object of type PublishedBlock, got " + JSON.stringify(value$59));
      }
      field_publishedBlockPublished = tmp$1;
    }
  } else {
    field_publishedBlockPublished = undefined;
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          blockType: field_blockType,
          name: field_name,
          slug: field_slug,
          deepLink: field_deepLink,
          createdAt: field_createdAt,
          deletedAt: field_deletedAt,
          updatedAt: field_updatedAt,
          description: field_description,
          statusBarColor: field_statusBarColor,
          isHidden: field_isHidden,
          isTemporary: field_isTemporary,
          component: field_component,
          publishedBlockOriginal: field_publishedBlockOriginal,
          publishedBlockPublished: field_publishedBlockPublished
        };
}

var name = "SimpleScreenFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var SimpleScreenFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  SimpleScreenFields: SimpleScreenFields
};

var ppx_printed_query$1 = "query Screen($screenUuid: ID!)  {\n__typename\nscreen(screenUuid: $screenUuid)  {\n__typename\n...SimpleScreenFields   \n}\n\n}\nfragment SimpleScreenFields on Screen   {\n__typename\n__typename  \nuuid  \nblockType  \nname  \ndeepLink  \nslug  \ndeletedAt  \ncreatedAt  \nupdatedAt  \ndescription  \nstatusBarColor  \nisHidden  \nisTemporary  \ncomponent  {\n__typename\n__typename  \nuuid  \n}\n\npublishedBlockOriginal  {\n__typename\n__typename  \nuuid  \n}\n\npublishedBlockPublished  {\n__typename\n__typename  \nuuid  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "screen");
  return {
          screen: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field screen on type Query is missing")
        };
}

function make(screenUuid, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "screenUuid",
                    screenUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var screenUuid = variables.screenUuid;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "screenUuid",
                    screenUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(screenUuid, param) {
  return Js_dict.fromArray([[
                  "screenUuid",
                  screenUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, screenUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "screenUuid",
                      screenUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function useQuery(screenUuid, skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables(screenUuid, undefined)), undefined, /* CacheFirst */0, undefined, skip || !Uuid$DraftbitBuilder.isUuid(screenUuid), undefined, undefined, definition);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (v) {
                return v.screen;
              }));
}

function refetch(screenUuid) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make(screenUuid, undefined));
}

var Get = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  useQuery: useQuery,
  refetch: refetch
};

var GQL = {
  Fragment: Fragment,
  Get: Get
};

export {
  uuidAndTypename_encode ,
  uuidAndTypename_decode ,
  t_encode ,
  t_decode ,
  GQL ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
