// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as DataCyWrapper$DraftbitBuilder from "../../components/DataCyWrapper.bs.js";

function OverlayTrigger(Props) {
  var id = Props.id;
  var onClick = Props.onClick;
  var children = Props.children;
  var pointerOpt = Props.pointer;
  var stopPropagationOpt = Props.stopPropagation;
  var dataCy = Props.dataCy;
  var classNameOpt = Props.className;
  var pointer = pointerOpt !== undefined ? pointerOpt : true;
  var stopPropagation = stopPropagationOpt !== undefined ? stopPropagationOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var outsideContainer = React.useRef(null);
  var onClickHandler = function ($$event) {
    var target = $$event.target;
    if (stopPropagation) {
      $$event.stopPropagation();
    }
    var match = target.closest("#" + id);
    var targetIsOutsideOverlayTrigger = (match == null);
    var targetIsInsideTooltip = Belt_Option.isSome(Caml_option.nullable_to_opt(target.closest("#TooltipOverlay")));
    if (targetIsOutsideOverlayTrigger && !targetIsInsideTooltip) {
      return Curry._1(onClick, undefined);
    }
    
  };
  React.useEffect((function () {
          document.addEventListener("mousedown", onClickHandler);
          return (function (param) {
                    document.removeEventListener("mousedown", onClickHandler);
                    
                  });
        }), [
        onClick,
        outsideContainer.current
      ]);
  return React.createElement(DataCyWrapper$DraftbitBuilder.make, {
              children: React.createElement("div", {
                    ref: outsideContainer,
                    className: Cn.make({
                          hd: Cn.ifTrue("cursor-pointer", pointer),
                          tl: {
                            hd: className,
                            tl: /* [] */0
                          }
                        }),
                    id: id
                  }, children),
              name: dataCy
            });
}

var make = OverlayTrigger;

export {
  make ,
  
}
/* react Not a pure module */
