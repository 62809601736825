import "./Notify.css";
import * as React from "react";
import Notification from "rc-notification";
import LoadingSpinner from "components/LoadingSpinner.gen";
import { make as ReactMarkdown } from "components/ReactMarkdown.gen";
import { Icon } from "waterloo/components/Icon/Icon.gen";
import * as Colors from "waterloo/WL_Color.gen";

let notification: any = null;
Notification.newInstance(
  {
    style: {
      top: 0,
      left: "50%",
    },
  },
  n => {
    notification = n;
  }
);

export const success = (
  message: string,
  dataCy = "SuccessNotification",
  duration?: number
) => {
  notification.notice({
    duration,
    content: (
      <div
        data-cy={dataCy}
        className="inline-flex items-center p-2 rounded bg-brand"
      >
        <Icon size="Small" icon="CheckCircle" />
        <div className="ml-2 font-sans text-mono-50">
          <ReactMarkdown source={message} />
        </div>
      </div>
    ),
  });
};

export const successWithAction = (
  key: string,
  message: string,
  actionText: string,
  action: () => void
) => {
  notification.notice({
    key,
    duration: 5,
    content: (
      <div className="inline-flex items-center p-2 rounded bg-brand">
        <Icon size="Small" icon="CheckCircle" />
        <div className="ml-2 font-sans text-mono-50 flex">
          <ReactMarkdown source={message} className="mr-1" />
          <span className="underline cursor-pointer" onClick={action}>
            {actionText}
          </span>
        </div>
      </div>
    ),
  });
};

export const newVersionAvailable = (action: () => void) => {
  notification.notice({
    key: "New Version Available",
    duration: 0,
    content: (
      <div className="inline-flex items-center p-4 rounded bg-brand">
        <div className="ml-2 font-sans text-mono-50">
          New version available! Make sure to save any unsaved work first.{" "}
          <span className="underline cursor-pointer" onClick={action}>
            Reload
          </span>
        </div>
      </div>
    ),
  });
};

export const loading = (key: string, message: string, duration = 1.5) => {
  notification.notice({
    key,
    duration,
    content: (
      <div className="inline-flex items-center p-2 rounded bg-brand">
        <LoadingSpinner size={16} inline={true} />
        <div className="ml-2 font-sans text-mono-50">{message}</div>
      </div>
    ),
  });
};

export const failure = (message: string, key?: string) => {
  notification.notice({
    key,
    duration: 3,
    content: (
      <div className="inline-flex items-center p-2 rounded bg-failure-400">
        <Icon size="Small" color={Colors.mono100} icon="ExclamationTriangle" />
        <div className="ml-2 font-sans text-mono-50">{message}</div>
      </div>
    ),
  });
};

export const remove = (key: string) => notification.removeNotice(key);

export default success;
