// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as ReactMarkdown from "react-markdown";
import * as ReactRouterDom from "react-router-dom";
import * as Utils$DraftbitBuilder from "../utilities/Utils.bs.js";
import * as OptionUtils$DraftbitBuilder from "../utilities/OptionUtils.bs.js";

var JsComponent = {};

function ReactMarkdown$1(Props) {
  var onClickLinkOpt = Props.onClickLink;
  var source = Props.source;
  var className = Props.className;
  var onClickLink = onClickLinkOpt !== undefined ? onClickLinkOpt : Utils$DraftbitBuilder.noop;
  var history = ReactRouterDom.useHistory();
  var tmp = {
    source: source,
    renderers: {
      text: (function (props) {
          return React.createElement("span", undefined, props.value);
        }),
      link: (function (props) {
          var href = props.href;
          var onClick = OptionUtils$DraftbitBuilder.someIf(props.href.startsWith("/"), (function (e) {
                  e.preventDefault();
                  history.push(href, undefined);
                  return Curry._1(onClickLink, undefined);
                }));
          var tmp = {
            className: "underline",
            href: props.href,
            target: "_blank"
          };
          if (onClick !== undefined) {
            tmp.onClick = Caml_option.valFromOption(onClick);
          }
          return React.createElement("a", tmp, props.children);
        }),
      inlineCode: (function (props) {
          return React.createElement("span", {
                      className: "font-mono text-center"
                    }, props.value);
        })
    }
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(ReactMarkdown, tmp);
}

var make = ReactMarkdown$1;

export {
  JsComponent ,
  make ,
  
}
/* react Not a pure module */
