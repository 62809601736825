// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as DictUtils$DraftbitBuilder from "./utilities/DictUtils.bs.js";

var _map = {"46":"46","47":"47","48":"48","49":"49","50":"50"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var allExpoVersions = [
  "46",
  "47",
  "48",
  "49",
  "50"
];

var adminExpoVersions = [
  "47",
  "48",
  "49",
  "50"
];

var userExpoVersions = [
  "49",
  "50"
];

function tFromString(string) {
  return Belt_Option.getWithDefault(tFromJs(string), "50");
}

function tFromOptionalString(string) {
  if (string !== undefined) {
    return Belt_Option.getWithDefault(tFromJs(string), "50");
  } else {
    return "50";
  }
}

function sdkVersion(_version) {
  while(true) {
    var version = _version;
    if (version === "47") {
      return "47.0.14";
    }
    if (version === "48") {
      return "48.0.21";
    }
    if (version === "49") {
      return "49.0.23";
    }
    if (version === "50") {
      return "50.0.17";
    }
    _version = "50";
    continue ;
  };
}

function snackVersion(_version) {
  while(true) {
    var version = _version;
    if (version === "47") {
      return "47.0.0";
    }
    if (version === "48") {
      return "48.0.0";
    }
    if (version === "49") {
      return "49.0.0";
    }
    if (version === "50") {
      return "50.0.0";
    }
    _version = "50";
    continue ;
  };
}

function versionStatus(version) {
  if (version === "49") {
    return "(Legacy)";
  } else if (version === "50") {
    return "(Recommended)";
  } else {
    return "(Expired)";
  }
}

function jigsawVersion(_version) {
  while(true) {
    var version = _version;
    if (version === "47") {
      return "47.8.9";
    }
    if (version === "48") {
      return "48.10.3";
    }
    if (version === "49") {
      return "49.7.5";
    }
    if (version === "50") {
      return "50.3.5";
    }
    _version = "50";
    continue ;
  };
}

function reactNativeVersion(_version) {
  while(true) {
    var version = _version;
    if (version === "47") {
      return "0.70.14";
    }
    if (version === "48") {
      return "0.71.15";
    }
    if (version === "49") {
      return "0.72.10";
    }
    if (version === "50") {
      return "0.73.6";
    }
    _version = "50";
    continue ;
  };
}

function basePackages(expoVersion) {
  return DictUtils$DraftbitBuilder.union2(expoVersion === "47" ? Js_dict.fromArray([
                    [
                      "@expo/webpack-config",
                      "^0.17.2"
                    ],
                    [
                      "@expo/dev-server",
                      "0.1.120"
                    ],
                    [
                      "@react-navigation/bottom-tabs",
                      "^6.5.7"
                    ],
                    [
                      "@react-navigation/drawer",
                      "^6.4.1"
                    ],
                    [
                      "@react-navigation/native",
                      "^6.1.8"
                    ],
                    [
                      "@react-navigation/stack",
                      "^6.3.16"
                    ],
                    [
                      "@shopify/mime-types",
                      "^2.1.0"
                    ],
                    [
                      "react-native-modal-datetime-picker",
                      "^13.0.0"
                    ],
                    [
                      "react-native-keyboard-aware-scroll-view",
                      "~0.9.5"
                    ],
                    [
                      "react-native-typography",
                      "1.4.1"
                    ],
                    [
                      "react-native-web",
                      "~0.18.9"
                    ],
                    [
                      "react-native-pager-view",
                      "6.0.1"
                    ],
                    [
                      "react-native-reanimated",
                      "~2.12.0"
                    ],
                    [
                      "react-native-web-swiper",
                      "^2.2.3"
                    ],
                    [
                      "react-fetch-hook",
                      "1.9.5"
                    ],
                    [
                      "react-request",
                      "3.2.0"
                    ],
                    [
                      "react-query",
                      "^3.39.3"
                    ],
                    [
                      "expo",
                      "~" + sdkVersion(expoVersion)
                    ],
                    [
                      "react",
                      "18.1.0"
                    ],
                    [
                      "react-dom",
                      "18.1.0"
                    ]
                  ]) : (
                expoVersion === "48" ? Js_dict.fromArray([
                        [
                          "@expo/webpack-config",
                          "^18.0.1"
                        ],
                        [
                          "@expo/dev-server",
                          "0.3.0"
                        ],
                        [
                          "@react-navigation/bottom-tabs",
                          "^6.5.7"
                        ],
                        [
                          "@react-navigation/drawer",
                          "^6.4.1"
                        ],
                        [
                          "@react-navigation/native",
                          "^6.1.8"
                        ],
                        [
                          "@react-navigation/stack",
                          "^6.3.16"
                        ],
                        [
                          "@shopify/mime-types",
                          "^2.1.0"
                        ],
                        [
                          "react-native-modal-datetime-picker",
                          "^13.0.0"
                        ],
                        [
                          "react-native-keyboard-aware-scroll-view",
                          "~0.9.5"
                        ],
                        [
                          "react-native-typography",
                          "1.4.1"
                        ],
                        [
                          "react-native-web",
                          "~0.18.11"
                        ],
                        [
                          "react-native-pager-view",
                          "6.1.2"
                        ],
                        [
                          "react-native-reanimated",
                          "~2.14.4"
                        ],
                        [
                          "react-native-web-swiper",
                          "^2.2.3"
                        ],
                        [
                          "react-fetch-hook",
                          "1.9.5"
                        ],
                        [
                          "react-request",
                          "3.2.0"
                        ],
                        [
                          "react-query",
                          "^3.39.3"
                        ],
                        [
                          "expo",
                          "~" + sdkVersion(expoVersion)
                        ],
                        [
                          "react",
                          "18.2.0"
                        ],
                        [
                          "react-dom",
                          "18.2.0"
                        ]
                      ]) : (
                    expoVersion === "49" ? Js_dict.fromArray([
                            [
                              "@expo/webpack-config",
                              "^19.0.0"
                            ],
                            [
                              "@expo/dev-server",
                              "0.5.5"
                            ],
                            [
                              "@react-navigation/bottom-tabs",
                              "^6.5.7"
                            ],
                            [
                              "@react-navigation/drawer",
                              "^6.4.1"
                            ],
                            [
                              "@react-navigation/native",
                              "^6.1.8"
                            ],
                            [
                              "@react-navigation/stack",
                              "^6.3.16"
                            ],
                            [
                              "@shopify/mime-types",
                              "^2.1.0"
                            ],
                            [
                              "react-native-modal-datetime-picker",
                              "^13.0.0"
                            ],
                            [
                              "react-native-keyboard-aware-scroll-view",
                              "~0.9.5"
                            ],
                            [
                              "react-native-typography",
                              "1.4.1"
                            ],
                            [
                              "react-native-web",
                              "~0.19.6"
                            ],
                            [
                              "react-native-pager-view",
                              "6.2.0"
                            ],
                            [
                              "react-native-reanimated",
                              "~3.3.0"
                            ],
                            [
                              "react-native-web-swiper",
                              "^2.2.3"
                            ],
                            [
                              "react-fetch-hook",
                              "1.9.5"
                            ],
                            [
                              "react-request",
                              "3.2.0"
                            ],
                            [
                              "react-query",
                              "^3.39.3"
                            ],
                            [
                              "expo",
                              "~" + sdkVersion(expoVersion)
                            ],
                            [
                              "react",
                              "18.2.0"
                            ],
                            [
                              "react-dom",
                              "18.2.0"
                            ]
                          ]) : (
                        expoVersion === "50" ? Js_dict.fromArray([
                                [
                                  "@expo/webpack-config",
                                  "~19.0.1"
                                ],
                                [
                                  "@expo/dev-server",
                                  "0.5.5"
                                ],
                                [
                                  "@react-navigation/bottom-tabs",
                                  "^6.5.7"
                                ],
                                [
                                  "@react-navigation/drawer",
                                  "^6.7.2"
                                ],
                                [
                                  "@react-navigation/native",
                                  "^6.1.8"
                                ],
                                [
                                  "@react-navigation/stack",
                                  "^6.3.16"
                                ],
                                [
                                  "@shopify/mime-types",
                                  "^2.1.0"
                                ],
                                [
                                  "react-native-modal-datetime-picker",
                                  "^13.0.0"
                                ],
                                [
                                  "react-native-keyboard-aware-scroll-view",
                                  "~0.9.5"
                                ],
                                [
                                  "react-native-typography",
                                  "1.4.1"
                                ],
                                [
                                  "react-native-web",
                                  "~0.19.6"
                                ],
                                [
                                  "react-native-pager-view",
                                  "6.2.3"
                                ],
                                [
                                  "react-native-reanimated",
                                  "~3.6.2"
                                ],
                                [
                                  "react-native-web-swiper",
                                  "^2.2.3"
                                ],
                                [
                                  "react-fetch-hook",
                                  "1.9.5"
                                ],
                                [
                                  "react-request",
                                  "3.2.0"
                                ],
                                [
                                  "react-query",
                                  "^3.39.3"
                                ],
                                [
                                  "expo",
                                  "~" + sdkVersion(expoVersion)
                                ],
                                [
                                  "react",
                                  "18.2.0"
                                ],
                                [
                                  "react-dom",
                                  "18.2.0"
                                ]
                              ]) : basePackages("50")
                      )
                  )
              ), Js_dict.fromArray([
                  [
                    "@draftbit/ui",
                    jigsawVersion(expoVersion)
                  ],
                  [
                    "@draftbit/maps",
                    jigsawVersion(expoVersion)
                  ],
                  [
                    "react-native",
                    reactNativeVersion(expoVersion)
                  ],
                  [
                    "patch-package",
                    "^6.5.1"
                  ],
                  [
                    "postinstall-postinstall",
                    "^2.1.0"
                  ]
                ]));
}

var defaultVersion = "50";

var upcomingVersion = "50";

var recommendedExpoVersion = "50";

export {
  tToJs ,
  tFromJs ,
  allExpoVersions ,
  defaultVersion ,
  upcomingVersion ,
  recommendedExpoVersion ,
  adminExpoVersions ,
  userExpoVersions ,
  tFromString ,
  tFromOptionalString ,
  sdkVersion ,
  snackVersion ,
  versionStatus ,
  jigsawVersion ,
  reactNativeVersion ,
  basePackages ,
  
}
/* DictUtils-DraftbitBuilder Not a pure module */
