// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

var _map = {"BottomTab":"BottomTab","Drawer":"Drawer","Root":"Root","Stack":"Stack"};

function tToJs(param) {
  return param;
}

function tFromJs(str) {
  if (str === "ROOT") {
    return "Root";
  } else {
    return _map[StringUtils$DraftbitBuilder.capitalize(str)];
  }
}

function t_encode(t) {
  return t;
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("NavigatorType.t", tFromJs, param);
}

var allTypes = [
  "BottomTab",
  "Drawer",
  "Root",
  "Stack"
];

export {
  tToJs ,
  tFromJs ,
  t_encode ,
  t_decode ,
  allTypes ,
  
}
/* JsonUtils-DraftbitBuilder Not a pure module */
