// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as ReactRouterDom from "react-router-dom";
import * as Text$DraftbitBuilder from "./waterloo/components/Text/Text.bs.js";
import * as Utils$DraftbitBuilder from "./utilities/Utils.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as DataCyDiv$DraftbitBuilder from "./components/DataCyDiv.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as ContextMenu$DraftbitBuilder from "./waterloo/components/ContextMenu.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

function getUsages(headers) {
  return Belt_Array.keepMap(ArrayUtils$DraftbitBuilder.uniqBy(headers, (function (h) {
                    return h.uuid;
                  })), (function (h) {
                var match = h.endpoint;
                var match$1 = h.service;
                if (match !== undefined) {
                  return {
                          NAME: "Endpoint",
                          VAL: Caml_option.valFromOption(match)
                        };
                } else if (match$1 !== undefined) {
                  return {
                          NAME: "Service",
                          VAL: Caml_option.valFromOption(match$1)
                        };
                } else {
                  return ;
                }
              }));
}

function isUsed(headers) {
  return getUsages(headers).length > 0;
}

function renderUsageLong(headers, objectName) {
  var usages = getUsages(headers);
  if (usages.length !== 0) {
    return "This " + objectName + " is used by " + StringUtils$DraftbitBuilder.renderLength(usages, "header");
  } else {
    return "This " + objectName + " is not currently used by any headers";
  }
}

var ppx_printed_query = "fragment RestApiHeaderUsageFields on RestApiHeader   {\n__typename\n__typename  \nuuid  \nkey  \nservice  {\n__typename\n__typename  \nuuid  \nname  \n}\n\nendpoint  {\n__typename\n__typename  \nuuid  \nname  \nservice  {\n__typename\n__typename  \nuuid  \nname  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "__typename");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type RestApiHeader is missing");
  }
  var value$5 = Js_dict.get(value$1, "uuid");
  var tmp$1;
  if (value$5 !== undefined) {
    var value$6 = Caml_option.valFromOption(value$5);
    var value$7 = Js_json.decodeString(value$6);
    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
  } else {
    tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type RestApiHeader is missing");
  }
  var value$8 = Js_dict.get(value$1, "key");
  var tmp$2;
  if (value$8 !== undefined) {
    var value$9 = Caml_option.valFromOption(value$8);
    var value$10 = Js_json.decodeString(value$9);
    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
  } else {
    tmp$2 = Js_exn.raiseError("graphql_ppx: Field key on type RestApiHeader is missing");
  }
  var value$11 = Js_dict.get(value$1, "service");
  var tmp$3;
  if (value$11 !== undefined) {
    var value$12 = Caml_option.valFromOption(value$11);
    var match = Js_json.decodeNull(value$12);
    if (match !== undefined) {
      tmp$3 = undefined;
    } else {
      var value$13 = Js_option.getExn(Js_json.decodeObject(value$12));
      var value$14 = Js_dict.get(value$13, "__typename");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field __typename on type RestApiService is missing");
      }
      var value$17 = Js_dict.get(value$13, "uuid");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Caml_option.valFromOption(value$17);
        var value$19 = Js_json.decodeString(value$18);
        tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field uuid on type RestApiService is missing");
      }
      var value$20 = Js_dict.get(value$13, "name");
      var tmp$6;
      if (value$20 !== undefined) {
        var value$21 = Caml_option.valFromOption(value$20);
        var value$22 = Js_json.decodeString(value$21);
        tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
      } else {
        tmp$6 = Js_exn.raiseError("graphql_ppx: Field name on type RestApiService is missing");
      }
      tmp$3 = {
        __typename: tmp$4,
        uuid: tmp$5,
        name: tmp$6
      };
    }
  } else {
    tmp$3 = undefined;
  }
  var value$23 = Js_dict.get(value$1, "endpoint");
  var tmp$7;
  if (value$23 !== undefined) {
    var value$24 = Caml_option.valFromOption(value$23);
    var match$1 = Js_json.decodeNull(value$24);
    if (match$1 !== undefined) {
      tmp$7 = undefined;
    } else {
      var value$25 = Js_option.getExn(Js_json.decodeObject(value$24));
      var value$26 = Js_dict.get(value$25, "__typename");
      var tmp$8;
      if (value$26 !== undefined) {
        var value$27 = Caml_option.valFromOption(value$26);
        var value$28 = Js_json.decodeString(value$27);
        tmp$8 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
      } else {
        tmp$8 = Js_exn.raiseError("graphql_ppx: Field __typename on type RestApiEndpoint is missing");
      }
      var value$29 = Js_dict.get(value$25, "uuid");
      var tmp$9;
      if (value$29 !== undefined) {
        var value$30 = Caml_option.valFromOption(value$29);
        var value$31 = Js_json.decodeString(value$30);
        tmp$9 = value$31 !== undefined ? value$31 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$30));
      } else {
        tmp$9 = Js_exn.raiseError("graphql_ppx: Field uuid on type RestApiEndpoint is missing");
      }
      var value$32 = Js_dict.get(value$25, "name");
      var tmp$10;
      if (value$32 !== undefined) {
        var value$33 = Caml_option.valFromOption(value$32);
        var value$34 = Js_json.decodeString(value$33);
        tmp$10 = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$33));
      } else {
        tmp$10 = Js_exn.raiseError("graphql_ppx: Field name on type RestApiEndpoint is missing");
      }
      var value$35 = Js_dict.get(value$25, "service");
      var tmp$11;
      if (value$35 !== undefined) {
        var value$36 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$35)));
        var value$37 = Js_dict.get(value$36, "__typename");
        var tmp$12;
        if (value$37 !== undefined) {
          var value$38 = Caml_option.valFromOption(value$37);
          var value$39 = Js_json.decodeString(value$38);
          tmp$12 = value$39 !== undefined ? value$39 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$38));
        } else {
          tmp$12 = Js_exn.raiseError("graphql_ppx: Field __typename on type RestApiService is missing");
        }
        var value$40 = Js_dict.get(value$36, "uuid");
        var tmp$13;
        if (value$40 !== undefined) {
          var value$41 = Caml_option.valFromOption(value$40);
          var value$42 = Js_json.decodeString(value$41);
          tmp$13 = value$42 !== undefined ? value$42 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$41));
        } else {
          tmp$13 = Js_exn.raiseError("graphql_ppx: Field uuid on type RestApiService is missing");
        }
        var value$43 = Js_dict.get(value$36, "name");
        var tmp$14;
        if (value$43 !== undefined) {
          var value$44 = Caml_option.valFromOption(value$43);
          var value$45 = Js_json.decodeString(value$44);
          tmp$14 = value$45 !== undefined ? value$45 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$44));
        } else {
          tmp$14 = Js_exn.raiseError("graphql_ppx: Field name on type RestApiService is missing");
        }
        tmp$11 = {
          __typename: tmp$12,
          uuid: tmp$13,
          name: tmp$14
        };
      } else {
        tmp$11 = Js_exn.raiseError("graphql_ppx: Field service on type RestApiEndpoint is missing");
      }
      tmp$7 = {
        __typename: tmp$8,
        uuid: tmp$9,
        name: tmp$10,
        service: tmp$11
      };
    }
  } else {
    tmp$7 = undefined;
  }
  return {
          __typename: tmp,
          uuid: tmp$1,
          key: tmp$2,
          service: tmp$3,
          endpoint: tmp$7
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var RestApiHeaderUsageFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "RestApiHeaderUsageFields",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  RestApiHeaderUsageFields: RestApiHeaderUsageFields
};

var GQL = {
  Fragment: Fragment
};

function RestApiHeaderUsage$UsageMenu(Props) {
  var objectName = Props.objectName;
  var headers = Props.headers;
  var onCloseOpt = Props.onClose;
  var maxHeightClass = Props.maxHeightClass;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : Utils$DraftbitBuilder.noop;
  var match = Router$DraftbitBuilder.getParams(undefined);
  var screenUuid = match.screenUuid;
  var appUuid = match.appUuid;
  var history = ReactRouterDom.useHistory();
  var options = Belt_List.fromArray(getUsages(headers).map(function ($$enum) {
            return ContextMenu$DraftbitBuilder.makeOption($$enum.VAL.name, undefined, undefined, undefined, undefined, $$enum.NAME === "Endpoint" ? "Endpoints" : "Services", undefined, {
                        TAG: /* CloseAfter */1,
                        _0: (function (param) {
                            if ($$enum.NAME !== "Endpoint") {
                              return Router$DraftbitBuilder.NavigateTo.editServiceModal(undefined, appUuid, screenUuid, $$enum.VAL.uuid, history);
                            }
                            var e = $$enum.VAL;
                            return Router$DraftbitBuilder.NavigateTo.endpointDetailModal(undefined, appUuid, screenUuid, e.service.uuid, e.uuid, history);
                          })
                      });
          }));
  var tmp = {
    onClose: onClose,
    options: options,
    sortGroups: (function (prim) {
        return prim[0];
      })
  };
  if (maxHeightClass !== undefined) {
    tmp.maxHeightClass = Caml_option.valFromOption(maxHeightClass);
  }
  return React.createElement(DataCyDiv$DraftbitBuilder.make, {
              dataCy: objectName + "Usage",
              children: null
            }, React.createElement(Text$DraftbitBuilder.make, {
                  color: "Mono50",
                  children: renderUsageLong(headers, objectName) + ".",
                  kind: "B3"
                }), React.createElement(ContextMenu$DraftbitBuilder.make, tmp));
}

var UsageMenu = {
  make: RestApiHeaderUsage$UsageMenu
};

export {
  getUsages ,
  isUsed ,
  renderUsageLong ,
  GQL ,
  UsageMenu ,
  
}
/* react Not a pure module */
