// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as JsMap from "rescript-js-collections/src/JsMap.bs.js";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Belt_Result from "bs-platform/lib/es6/belt_Result.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";
import * as DictUtils$DraftbitBuilder from "./DictUtils.bs.js";
import * as ResultUtils$DraftbitBuilder from "./ResultUtils.bs.js";
import * as TemplateString$DraftbitBuilder from "./TemplateString.bs.js";

function emptyObject(param) {
  var __x = {};
  return Json_encode.dict((function (j) {
                return j;
              }), __x);
}

function validate(unvalidatedJson) {
  return JSON.parse(JSON.stringify(unvalidatedJson));
}

var nullJsonMagicValue = "@@@NULL@@@";

function convertToMagicNull(j) {
  if (Caml_obj.caml_equal(j, null)) {
    return nullJsonMagicValue;
  } else {
    return j;
  }
}

function convertFromMagicNull(j) {
  if (Caml_obj.caml_equal(j, nullJsonMagicValue)) {
    return null;
  } else {
    return j;
  }
}

function removeUndefineds(outerJson) {
  var seen = JsMap.empty(undefined);
  var counter = {
    contents: 1
  };
  var loop = function (jsonOrUndefined) {
    return Belt_Option.map(jsonOrUndefined === undefined ? undefined : Caml_option.some(jsonOrUndefined), (function (json) {
                  var obj = Js_json.classify(json);
                  if (typeof obj === "number") {
                    return json;
                  }
                  switch (obj.TAG | 0) {
                    case /* JSONObject */2 :
                        var obj$1 = obj._0;
                        var match = JsMap.get(seen, obj$1);
                        if (match !== undefined) {
                          if (match.NAME === "Computed") {
                            return match.VAL;
                          } else {
                            return DictUtils$DraftbitBuilder.one("__ref__", match.VAL);
                          }
                        }
                        var c = counter.contents;
                        counter.contents = c + 1 | 0;
                        JsMap.setMut(seen, obj$1, {
                              NAME: "InProgress",
                              VAL: c
                            });
                        var fixed = DictUtils$DraftbitBuilder.keepMap(obj$1, loop);
                        JsMap.setMut(seen, obj$1, {
                              NAME: "Computed",
                              VAL: fixed
                            });
                        return fixed;
                    case /* JSONArray */3 :
                        return Belt_Array.keepMap(obj._0, loop);
                    default:
                      return json;
                  }
                }));
  };
  return Belt_Option.getWithDefault(loop(outerJson), null);
}

function undefinedsToNulls(json) {
  if (json === undefined) {
    return null;
  }
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    return json;
  }
  switch (dict.TAG | 0) {
    case /* JSONObject */2 :
        return DictUtils$DraftbitBuilder.map(dict._0, undefinedsToNulls);
    case /* JSONArray */3 :
        return dict._0.map(undefinedsToNulls);
    default:
      return json;
  }
}

function removeKeysWithNullValues(outerJson) {
  var seen = JsMap.empty(undefined);
  var counter = {
    contents: 1
  };
  var loop = function (json) {
    var obj = Js_json.classify(json);
    if (typeof obj === "number") {
      return json;
    }
    switch (obj.TAG | 0) {
      case /* JSONObject */2 :
          var obj$1 = obj._0;
          var match = JsMap.get(seen, obj$1);
          if (match !== undefined) {
            if (match.NAME === "Computed") {
              return match.VAL;
            } else {
              return DictUtils$DraftbitBuilder.one("__ref__", match.VAL);
            }
          }
          var c = counter.contents;
          counter.contents = c + 1 | 0;
          JsMap.setMut(seen, obj$1, {
                NAME: "InProgress",
                VAL: c
              });
          var res = DictUtils$DraftbitBuilder.keepMap(obj$1, (function (j) {
                  if (j == null) {
                    return ;
                  } else {
                    return Caml_option.some(loop(j));
                  }
                }));
          JsMap.setMut(seen, obj$1, {
                NAME: "Computed",
                VAL: res
              });
          return res;
      case /* JSONArray */3 :
          return obj._0.map(removeKeysWithNullValues);
      default:
        return json;
    }
  };
  return loop(outerJson);
}

function stringify(indent, json) {
  return JSON.stringify(json, "null", indent);
}

function makeDeccoError(message, value) {
  return {
          path: "",
          message: message,
          value: value
        };
}

function makeStringLikeDecode(name, fromJs, json) {
  return Belt_Result.flatMap(Decco.stringFromJson(json), (function (s) {
                return ResultUtils$DraftbitBuilder.fromOption(Curry._1(fromJs, s), {
                            path: "",
                            message: "Invalid string value for " + name,
                            value: json
                          });
              }));
}

function formatDeccoError(param) {
  return "At path " + (param.path + (", value " + (JSON.stringify(param.value) + (": " + param.message))));
}

function decodeDecco(decoder, json) {
  var result = Curry._1(decoder, json);
  if (result.TAG === /* Ok */0) {
    return result._0;
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Decco decoder failed: " + formatDeccoError(result._0),
        Error: new Error()
      };
}

function makeDeccoDecoder(decoder, json) {
  var res;
  try {
    res = Curry._1(decoder, json);
  }
  catch (raw_message){
    var message = Caml_js_exceptions.internalToOCamlException(raw_message);
    if (message.RE_EXN_ID === Json_decode.DecodeError) {
      return {
              TAG: /* Error */1,
              _0: {
                path: "",
                message: message._1,
                value: json
              }
            };
    }
    throw message;
  }
  return {
          TAG: /* Ok */0,
          _0: res
        };
}

function decodeSome(opt) {
  if (opt !== undefined) {
    return Caml_option.valFromOption(opt);
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Expected a defined value",
        Error: new Error()
      };
}

function decodeAsOption(onErrorOpt, decoder, json) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  try {
    return Caml_option.some(Curry._1(decoder, json));
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Json_decode.DecodeError) {
      Curry._1(onError, e._1);
      return ;
    }
    throw e;
  }
}

function decodeAsResult(decoder, json) {
  try {
    return {
            TAG: /* Ok */0,
            _0: Curry._1(decoder, json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg.RE_EXN_ID === Json_decode.DecodeError) {
      return {
              TAG: /* Error */1,
              _0: msg._1
            };
    }
    throw msg;
  }
}

function parseAsResult(s) {
  try {
    return {
            TAG: /* Ok */0,
            _0: JSON.parse(s)
          };
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    var message = Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(e), (function (prim) {
            return prim.message;
          }));
    if (message !== undefined) {
      return {
              TAG: /* Error */1,
              _0: message
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: s
            };
    }
  }
}

function parseAsOption(s) {
  var json = parseAsResult(s);
  if (json.TAG === /* Ok */0) {
    return Caml_option.some(json._0);
  }
  
}

var _map = {"String":"String","Number":"Number","Null":"Null","Boolean":"Boolean","Object":"Object","Array":"Array"};

function flatTypeToJs(param) {
  return param;
}

function flatTypeFromJs(param) {
  return _map[param];
}

function getFlatType(json) {
  var match = Js_json.classify(json);
  if (typeof match === "number") {
    switch (match) {
      case /* JSONFalse */0 :
      case /* JSONTrue */1 :
          return "Boolean";
      case /* JSONNull */2 :
          return "Null";
      
    }
  } else {
    switch (match.TAG | 0) {
      case /* JSONString */0 :
          return "String";
      case /* JSONNumber */1 :
          return "Number";
      case /* JSONObject */2 :
          return "Object";
      case /* JSONArray */3 :
          return "Array";
      
    }
  }
}

function isString(json) {
  return getFlatType(json) === "String";
}

function recursivelyOrderKeys(json) {
  var obj = Js_json.classify(json);
  if (typeof obj === "number") {
    return json;
  }
  switch (obj.TAG | 0) {
    case /* JSONObject */2 :
        return DictUtils$DraftbitBuilder.map(DictUtils$DraftbitBuilder.withOrderedKeys(obj._0), recursivelyOrderKeys);
    case /* JSONArray */3 :
        return obj._0.map(recursivelyOrderKeys);
    default:
      return json;
  }
}

function parseWithNormalizedBooleans(str) {
  var match = str.trim().toLowerCase();
  switch (match) {
    case "false" :
        return Caml_option.some(false);
    case "true" :
        return Caml_option.some(true);
    default:
      return parseAsOption(str);
  }
}

function parseOrReturnAsString(str) {
  return Belt_Option.getWithDefault(parseWithNormalizedBooleans(str), str);
}

function encodeString(json) {
  return Belt_Option.getWithDefault(Js_json.decodeString(json), JSON.stringify(json));
}

var jsonSpecialCharacters = /[\[\]\{\}\":]/g;

function mightBeBadlyFormattedJson(str) {
  var match = parseAsOption(str);
  if (match !== undefined) {
    return false;
  }
  var arr = str.match(jsonSpecialCharacters);
  if (arr !== null && TemplateString$DraftbitBuilder.parse(str).length === 0) {
    return arr.length > 2;
  } else {
    return false;
  }
}

function toQueryParam(json) {
  return encodeURIComponent(JSON.stringify(json));
}

var emptyString = "";

export {
  emptyObject ,
  emptyString ,
  validate ,
  nullJsonMagicValue ,
  convertToMagicNull ,
  convertFromMagicNull ,
  removeUndefineds ,
  undefinedsToNulls ,
  removeKeysWithNullValues ,
  stringify ,
  makeDeccoError ,
  makeStringLikeDecode ,
  formatDeccoError ,
  decodeDecco ,
  makeDeccoDecoder ,
  decodeSome ,
  decodeAsOption ,
  decodeAsResult ,
  parseAsResult ,
  parseAsOption ,
  flatTypeToJs ,
  flatTypeFromJs ,
  getFlatType ,
  isString ,
  recursivelyOrderKeys ,
  parseWithNormalizedBooleans ,
  parseOrReturnAsString ,
  encodeString ,
  jsonSpecialCharacters ,
  mightBeBadlyFormattedJson ,
  toQueryParam ,
  
}
/* DictUtils-DraftbitBuilder Not a pure module */
