// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";
import * as Sentry$DraftbitBuilder from "./utilities/Sentry.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as SimpleUser$DraftbitBuilder from "./SimpleUser.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "./utilities/OptionUtils.bs.js";
import * as ApolloClient$DraftbitBuilder from "./bindings/ReasonApollo/ApolloClient.bs.js";

function getWorkspaces(user) {
  return user.ownedWorkspaces.concat(user.memberWorkspaces).concat(user.invitedWorkspaces);
}

function getValidWorkspaceUuid(user, optUuid) {
  var workspaces = getWorkspaces(user);
  return Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Option.map(optUuid, (function (prim) {
                        return decodeURIComponent(prim);
                      })), (function (uuidOrName) {
                    return OptionUtils$DraftbitBuilder.or_(Caml_option.undefined_to_opt(workspaces.find(function (w) {
                                        return w.uuid === uuidOrName;
                                      })), Caml_option.undefined_to_opt(workspaces.find(function (w) {
                                        return w.name === uuidOrName;
                                      })));
                  })), Belt_Option.mapWithDefault(OptionUtils$DraftbitBuilder.or_(Belt_Array.get(user.ownedWorkspaces, 0), Belt_Array.get(user.memberWorkspaces, 0)), "", (function (w) {
                    return w.uuid;
                  })), (function (w) {
                return w.uuid;
              }));
}

function numApps(user) {
  return ArrayUtils$DraftbitBuilder.sum(user.ownedWorkspaces.map(function (w) {
                  return w.apps.length;
                }));
}

function numLiveApps(user) {
  return ArrayUtils$DraftbitBuilder.sum(user.memberWorkspaces.map(function (w) {
                  return w.numLiveApps;
                }));
}

var ppx_printed_query = "fragment SimpleWorkspaceFields on Workspace   {\n__typename\n__typename  \nname  \nuuid  \niconUrl  \nowner  {\n__typename\n..." + SimpleUser$DraftbitBuilder.GQL.SimpleUserFragment.SimpleUserFields.name + "   \n}\n\ncollaborators  {\n__typename\nreferrer  {\n__typename\n__typename  \nuuid  \nemail  \n}\n\nuser  {\n__typename\n__typename  \nuuid  \nisAdmin  \n}\n\njoinedAt  \n}\n\napps  {\n__typename\n__typename  \nuuid  \nname  \niconUrl  \ndescription  \nnumScreens  \nstate  \nexpoVersion  \n}\n\nnumLiveApps  \n}\n" + SimpleUser$DraftbitBuilder.GQL.SimpleUserFragment.SimpleUserFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type Workspace, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type Workspace is missing");
  }
  var value$6 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_name = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type Workspace is missing");
  }
  var value$9 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_uuid = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type Workspace is missing");
  }
  var value$12 = Js_dict.get(value$2, "iconUrl");
  var field_iconUrl;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var match = Js_json.decodeNull(value$13);
    if (match !== undefined) {
      field_iconUrl = undefined;
    } else {
      var value$14 = Js_json.decodeString(value$13);
      field_iconUrl = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
    }
  } else {
    field_iconUrl = undefined;
  }
  var value$15 = Js_dict.get(value$2, "owner");
  var field_owner = value$15 !== undefined ? Curry._1(SimpleUser$DraftbitBuilder.GQL.SimpleUserFragment.SimpleUserFields.parse, Caml_option.valFromOption(value$15)) : Js_exn.raiseError("graphql_ppx: Field owner on type Workspace is missing");
  var value$16 = Js_dict.get(value$2, "collaborators");
  var field_collaborators = value$16 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$16))).map(function (value) {
          var value$1 = Js_option.getExn(Js_json.decodeObject(value));
          var value$2 = Js_dict.get(value$1, "referrer");
          var tmp;
          if (value$2 !== undefined) {
            var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
            var value$4 = Js_dict.get(value$3, "__typename");
            var tmp$1;
            if (value$4 !== undefined) {
              var value$5 = Caml_option.valFromOption(value$4);
              var value$6 = Js_json.decodeString(value$5);
              tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
            } else {
              tmp$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type User is missing");
            }
            var value$7 = Js_dict.get(value$3, "uuid");
            var tmp$2;
            if (value$7 !== undefined) {
              var value$8 = Caml_option.valFromOption(value$7);
              var value$9 = Js_json.decodeString(value$8);
              tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
            } else {
              tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
            }
            var value$10 = Js_dict.get(value$3, "email");
            var tmp$3;
            if (value$10 !== undefined) {
              var value$11 = Caml_option.valFromOption(value$10);
              var value$12 = Js_json.decodeString(value$11);
              tmp$3 = value$12 !== undefined ? value$12 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$11));
            } else {
              tmp$3 = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
            }
            tmp = {
              __typename: tmp$1,
              uuid: tmp$2,
              email: tmp$3
            };
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field referrer on type Collaborator is missing");
          }
          var value$13 = Js_dict.get(value$1, "user");
          var tmp$4;
          if (value$13 !== undefined) {
            var value$14 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$13)));
            var value$15 = Js_dict.get(value$14, "__typename");
            var tmp$5;
            if (value$15 !== undefined) {
              var value$16 = Caml_option.valFromOption(value$15);
              var value$17 = Js_json.decodeString(value$16);
              tmp$5 = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
            } else {
              tmp$5 = Js_exn.raiseError("graphql_ppx: Field __typename on type User is missing");
            }
            var value$18 = Js_dict.get(value$14, "uuid");
            var tmp$6;
            if (value$18 !== undefined) {
              var value$19 = Caml_option.valFromOption(value$18);
              var value$20 = Js_json.decodeString(value$19);
              tmp$6 = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
            } else {
              tmp$6 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
            }
            var value$21 = Js_dict.get(value$14, "isAdmin");
            var tmp$7;
            if (value$21 !== undefined) {
              var value$22 = Caml_option.valFromOption(value$21);
              var value$23 = Js_json.decodeBoolean(value$22);
              tmp$7 = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$22));
            } else {
              tmp$7 = Js_exn.raiseError("graphql_ppx: Field isAdmin on type User is missing");
            }
            tmp$4 = {
              __typename: tmp$5,
              uuid: tmp$6,
              isAdmin: tmp$7
            };
          } else {
            tmp$4 = Js_exn.raiseError("graphql_ppx: Field user on type Collaborator is missing");
          }
          var value$24 = Js_dict.get(value$1, "joinedAt");
          var tmp$8;
          if (value$24 !== undefined) {
            var value$25 = Caml_option.valFromOption(value$24);
            var match = Js_json.decodeNull(value$25);
            tmp$8 = match !== undefined ? undefined : Caml_option.some(value$25);
          } else {
            tmp$8 = undefined;
          }
          return {
                  referrer: tmp,
                  user: tmp$4,
                  joinedAt: tmp$8
                };
        }) : Js_exn.raiseError("graphql_ppx: Field collaborators on type Workspace is missing");
  var value$17 = Js_dict.get(value$2, "apps");
  var field_apps = value$17 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$17))).map(function (value) {
          var value$1 = Js_option.getExn(Js_json.decodeObject(value));
          var value$2 = Js_dict.get(value$1, "__typename");
          var tmp;
          if (value$2 !== undefined) {
            var value$3 = Caml_option.valFromOption(value$2);
            var value$4 = Js_json.decodeString(value$3);
            tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
          }
          var value$5 = Js_dict.get(value$1, "uuid");
          var tmp$1;
          if (value$5 !== undefined) {
            var value$6 = Caml_option.valFromOption(value$5);
            var value$7 = Js_json.decodeString(value$6);
            tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
          } else {
            tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
          }
          var value$8 = Js_dict.get(value$1, "name");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field name on type App is missing");
          }
          var value$11 = Js_dict.get(value$1, "iconUrl");
          var tmp$3;
          if (value$11 !== undefined) {
            var value$12 = Caml_option.valFromOption(value$11);
            var match = Js_json.decodeNull(value$12);
            if (match !== undefined) {
              tmp$3 = undefined;
            } else {
              var value$13 = Js_json.decodeString(value$12);
              tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
            }
          } else {
            tmp$3 = undefined;
          }
          var value$14 = Js_dict.get(value$1, "description");
          var tmp$4;
          if (value$14 !== undefined) {
            var value$15 = Caml_option.valFromOption(value$14);
            var value$16 = Js_json.decodeString(value$15);
            tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
          } else {
            tmp$4 = Js_exn.raiseError("graphql_ppx: Field description on type App is missing");
          }
          var value$17 = Js_dict.get(value$1, "numScreens");
          var tmp$5;
          if (value$17 !== undefined) {
            var value$18 = Caml_option.valFromOption(value$17);
            var value$19 = Js_json.decodeNumber(value$18);
            tmp$5 = value$19 !== undefined ? value$19 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$18));
          } else {
            tmp$5 = Js_exn.raiseError("graphql_ppx: Field numScreens on type App is missing");
          }
          var value$20 = Js_dict.get(value$1, "state");
          var tmp$6;
          if (value$20 !== undefined) {
            var value$21 = Caml_option.valFromOption(value$20);
            var value$22 = Js_json.decodeString(value$21);
            if (value$22 !== undefined) {
              switch (value$22) {
                case "Draft" :
                    tmp$6 = "Draft";
                    break;
                case "Live" :
                    tmp$6 = "Live";
                    break;
                default:
                  tmp$6 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for AppState: " + value$22);
              }
            } else {
              tmp$6 = Js_exn.raiseError("graphql_ppx: Expected enum value for AppState, got " + JSON.stringify(value$21));
            }
          } else {
            tmp$6 = Js_exn.raiseError("graphql_ppx: Field state on type App is missing");
          }
          var value$23 = Js_dict.get(value$1, "expoVersion");
          var tmp$7;
          if (value$23 !== undefined) {
            var value$24 = Caml_option.valFromOption(value$23);
            var value$25 = Js_json.decodeString(value$24);
            tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
          } else {
            tmp$7 = Js_exn.raiseError("graphql_ppx: Field expoVersion on type App is missing");
          }
          return {
                  __typename: tmp,
                  uuid: tmp$1,
                  name: tmp$2,
                  iconUrl: tmp$3,
                  description: tmp$4,
                  numScreens: tmp$5,
                  state: tmp$6,
                  expoVersion: tmp$7
                };
        }) : Js_exn.raiseError("graphql_ppx: Field apps on type Workspace is missing");
  var value$18 = Js_dict.get(value$2, "numLiveApps");
  var field_numLiveApps;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeNumber(value$19);
    field_numLiveApps = value$20 !== undefined ? value$20 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$19));
  } else {
    field_numLiveApps = Js_exn.raiseError("graphql_ppx: Field numLiveApps on type Workspace is missing");
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          name: field_name,
          iconUrl: field_iconUrl,
          owner: field_owner,
          apps: field_apps,
          numLiveApps: field_numLiveApps,
          collaborators: field_collaborators
        };
}

var name = "SimpleWorkspaceFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var SimpleWorkspaceFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  SimpleWorkspaceFields: SimpleWorkspaceFields
};

var ppx_printed_query$1 = "query SimpleWorkspace($uuid: ID!)  {\n__typename\n__typename  \nworkspace(uuid: $uuid)  {\n__typename\n...SimpleWorkspaceFields   \n}\n\n}\n" + ppx_printed_query;

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "__typename");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type Query is missing");
  }
  var value$5 = Js_dict.get(value$1, "workspace");
  return {
          __typename: tmp,
          workspace: value$5 !== undefined ? parse(Caml_option.valFromOption(value$5)) : Js_exn.raiseError("graphql_ppx: Field workspace on type Query is missing")
        };
}

function make(uuid, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "uuid",
                    uuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var uuid = variables.uuid;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "uuid",
                    uuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(uuid, param) {
  return Js_dict.fromArray([[
                  "uuid",
                  uuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, uuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "uuid",
                      uuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function useQuery(uuid) {
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables(uuid, undefined)), undefined, undefined, undefined, undefined, undefined, undefined, definition);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.workspace;
              }));
}

var Get = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  useQuery: useQuery
};

function modifySimpleWorkspaceInCache(client, workspaceUuid, modify) {
  ApolloClient$DraftbitBuilder.ReadQuery({
        query: ppx_printed_query$1,
        parse: parse$1
      });
  var WriteQuery = ApolloClient$DraftbitBuilder.WriteQuery({
        query: ppx_printed_query$1,
        parse: parse$1
      });
  var query = make(workspaceUuid, undefined);
  var options = ApolloHooks$DraftbitBuilder.toQueryObj(query);
  var cachedWorkspace;
  try {
    cachedWorkspace = Caml_option.nullable_to_opt(client.readQuery(options));
  }
  catch (raw_e){
    return Sentry$DraftbitBuilder.captureException(Caml_js_exceptions.internalToOCamlException(raw_e));
  }
  if (cachedWorkspace === undefined) {
    return Sentry$DraftbitBuilder.captureMessage("Workspace read query returned no data");
  }
  var workspace = Caml_option.valFromOption(cachedWorkspace).workspace;
  return Curry._4(WriteQuery.make, client, Caml_option.some(query.variables), {
              __typename: "Query",
              workspace: Curry._1(modify, workspace)
            }, undefined);
}

var ppx_printed_query$2 = "query UserWorkspaces  {\n__typename\nuser  {\n__typename\n__typename  \nuuid  \nownedWorkspaces  {\n__typename\n...SimpleWorkspaceFields   \n}\n\nmemberWorkspaces  {\n__typename\n...SimpleWorkspaceFields   \n}\n\ninvitedWorkspaces  {\n__typename\n...SimpleWorkspaceFields   \n}\n\n}\n\n}\n" + ppx_printed_query;

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "user");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeObject(value$3);
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_dict.get(value$5, "__typename");
      var field___typename;
      if (value$6 !== undefined) {
        var value$7 = Caml_option.valFromOption(value$6);
        var value$8 = Js_json.decodeString(value$7);
        field___typename = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
      } else {
        field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type User is missing");
      }
      var value$9 = Js_dict.get(value$5, "uuid");
      var field_uuid;
      if (value$9 !== undefined) {
        var value$10 = Caml_option.valFromOption(value$9);
        var value$11 = Js_json.decodeString(value$10);
        field_uuid = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
      } else {
        field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
      }
      var value$12 = Js_dict.get(value$5, "ownedWorkspaces");
      var field_ownedWorkspaces = value$12 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$12))).map(parse) : Js_exn.raiseError("graphql_ppx: Field ownedWorkspaces on type User is missing");
      var value$13 = Js_dict.get(value$5, "memberWorkspaces");
      var field_memberWorkspaces = value$13 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$13))).map(parse) : Js_exn.raiseError("graphql_ppx: Field memberWorkspaces on type User is missing");
      var value$14 = Js_dict.get(value$5, "invitedWorkspaces");
      var field_invitedWorkspaces = value$14 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$14))).map(parse) : Js_exn.raiseError("graphql_ppx: Field invitedWorkspaces on type User is missing");
      tmp = {
        __typename: field___typename,
        uuid: field_uuid,
        ownedWorkspaces: field_ownedWorkspaces,
        memberWorkspaces: field_memberWorkspaces,
        invitedWorkspaces: field_invitedWorkspaces
      };
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected object of type User, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field user on type Query is missing");
  }
  return {
          user: tmp
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$1
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

function useQuery$1(param) {
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$1);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.user;
              }));
}

var UserWorkspaces = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2,
  useQuery: useQuery$1
};

function useQuery$2(param) {
  var userWorkspacesQuery = useQuery$1(undefined);
  return ApolloUtils$DraftbitBuilder.Query.map(userWorkspacesQuery, (function (user) {
                return ArrayUtils$DraftbitBuilder.uniqBy(user.ownedWorkspaces.concat(user.memberWorkspaces), (function (w) {
                              return w.uuid;
                            }));
              }));
}

var List = {
  useQuery: useQuery$2
};

var refetchList = ApolloHooks$DraftbitBuilder.toQueryObj(make$1(undefined));

var GQL = {
  Fragment: Fragment,
  Get: Get,
  modifySimpleWorkspaceInCache: modifySimpleWorkspaceInCache,
  UserWorkspaces: UserWorkspaces,
  List: List,
  refetchList: refetchList
};

export {
  getWorkspaces ,
  getValidWorkspaceUuid ,
  numApps ,
  numLiveApps ,
  GQL ,
  
}
/* refetchList Not a pure module */
