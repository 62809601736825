// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";

function googleMaps_encode(v) {
  return Js_dict.fromArray([[
                "apiKey",
                Decco.stringToJson(v.apiKey)
              ]]);
}

function googleMaps_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var apiKey = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "apiKey"), null));
  if (apiKey.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              apiKey: apiKey._0
            }
          };
  }
  var e = apiKey._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".apiKey" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function branch_encode(v) {
  return Js_dict.fromArray([[
                "apiKey",
                Decco.stringToJson(v.apiKey)
              ]]);
}

function branch_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var apiKey = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "apiKey"), null));
  if (apiKey.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              apiKey: apiKey._0
            }
          };
  }
  var e = apiKey._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".apiKey" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "branch",
                Decco.optionToJson(branch_encode, v.branch)
              ],
              [
                "googleMaps",
                Decco.optionToJson(googleMaps_encode, v.googleMaps)
              ],
              [
                "googleMobileAdsAppId",
                Decco.optionToJson(Decco.stringToJson, v.googleMobileAdsAppId)
              ],
              [
                "googleMobileAdsAutoInit",
                Decco.optionToJson(Decco.boolToJson, v.googleMobileAdsAutoInit)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var branch = Decco.optionFromJson(branch_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "branch"), null));
  if (branch.TAG === /* Ok */0) {
    var googleMaps = Decco.optionFromJson(googleMaps_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "googleMaps"), null));
    if (googleMaps.TAG === /* Ok */0) {
      var googleMobileAdsAppId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "googleMobileAdsAppId"), null));
      if (googleMobileAdsAppId.TAG === /* Ok */0) {
        var googleMobileAdsAutoInit = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "googleMobileAdsAutoInit"), null));
        if (googleMobileAdsAutoInit.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    branch: branch._0,
                    googleMaps: googleMaps._0,
                    googleMobileAdsAppId: googleMobileAdsAppId._0,
                    googleMobileAdsAutoInit: googleMobileAdsAutoInit._0
                  }
                };
        }
        var e = googleMobileAdsAutoInit._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".googleMobileAdsAutoInit" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = googleMobileAdsAppId._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".googleMobileAdsAppId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = googleMaps._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".googleMaps" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = branch._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".branch" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

export {
  googleMaps_encode ,
  googleMaps_decode ,
  branch_encode ,
  branch_decode ,
  t_encode ,
  t_decode ,
  
}
/* No side effect */
