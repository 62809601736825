// This allows us to have parameterized transaction names within Sentry
// https://docs.sentry.io/platforms/javascript/guides/react/integrations/react-router/#parameterized-transaction-names
import { Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
// TODO Consider overloading this with withProfiler to add profiling everywhere
// docs.sentry.io/product/performance/getting-started/?platform=react
const SentryRoute = Sentry.withSentryRouting(Route);

// eslint-disable-next-line
export * from "react-router-dom";
// eslint-disable-next-line
export { SentryRoute as Route };
