// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Pervasives from "bs-platform/lib/es6/pervasives.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";

function getOrFailwith(opt, msg) {
  if (opt !== undefined) {
    return Caml_option.valFromOption(opt);
  } else {
    return Pervasives.failwith(msg);
  }
}

function flatMapWithDefault(opt, def, f) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(opt, f), def);
}

function mapNullable(nullable, f) {
  return Belt_Option.map((nullable == null) ? undefined : Caml_option.some(nullable), f);
}

function forEachNullable(nullable, f) {
  return Belt_Option.forEach((nullable == null) ? undefined : Caml_option.some(nullable), f);
}

function getBool(defaultOpt, opt) {
  var $$default = defaultOpt !== undefined ? defaultOpt : false;
  return Belt_Option.getWithDefault(opt, $$default);
}

function getString(defaultOpt, opt) {
  var $$default = defaultOpt !== undefined ? defaultOpt : "";
  return Belt_Option.getWithDefault(opt, $$default);
}

function getArray(defaultOpt, opt) {
  var $$default = defaultOpt !== undefined ? defaultOpt : [];
  return Belt_Option.getWithDefault(opt, $$default);
}

function mapOrFalse(opt, f) {
  return Belt_Option.mapWithDefault(opt, false, f);
}

function mapOrTrue(opt, f) {
  return Belt_Option.mapWithDefault(opt, true, f);
}

function mapOrEmpty(opt, f) {
  return Belt_Option.mapWithDefault(opt, "", f);
}

function mapOrEmptyArray(opt, f) {
  return Belt_Option.mapWithDefault(opt, [], f);
}

function mapOrEmptyDict(opt, f) {
  return Belt_Option.mapWithDefault(opt, {}, f);
}

function flatMapOrFalse(opt, f) {
  return flatMapWithDefault(opt, false, f);
}

function flatMapOrTrue(opt, f) {
  return flatMapWithDefault(opt, true, f);
}

function flatMapOrEmpty(opt, f) {
  return flatMapWithDefault(opt, "", f);
}

function flatMapOrEmptyArray(opt, f) {
  return flatMapWithDefault(opt, [], f);
}

function tap(opt, f) {
  Belt_Option.forEach(opt, f);
  return opt;
}

function tapL(f, opt) {
  if (opt !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(opt));
  }
  
}

function invert(opt, ifNone) {
  if (opt !== undefined) {
    return ;
  } else {
    return Caml_option.some(ifNone);
  }
}

function or_(opt1, opt2) {
  if (opt1 !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(opt1));
  } else {
    return opt2;
  }
}

function and_(opt1, opt2) {
  if (opt1 !== undefined && opt2 !== undefined) {
    return [
            Caml_option.valFromOption(opt1),
            Caml_option.valFromOption(opt2)
          ];
  }
  
}

function andMap(opt1, f) {
  if (opt1 === undefined) {
    return ;
  }
  var x = Caml_option.valFromOption(opt1);
  var y = Curry._1(f, x);
  if (y !== undefined) {
    return [
            x,
            Caml_option.valFromOption(y)
          ];
  }
  
}

function someIf(flag, val_) {
  if (flag) {
    return Caml_option.some(val_);
  }
  
}

function noneIf(opt, test) {
  if (opt === undefined) {
    return ;
  }
  var x = Caml_option.valFromOption(opt);
  if (!Curry._1(test, x)) {
    return Caml_option.some(x);
  }
  
}

function isNoneOrEmpty(optStr) {
  if (optStr !== undefined && optStr !== "") {
    return false;
  } else {
    return true;
  }
}

export {
  getOrFailwith ,
  flatMapWithDefault ,
  mapNullable ,
  forEachNullable ,
  getBool ,
  getString ,
  getArray ,
  mapOrFalse ,
  mapOrTrue ,
  mapOrEmpty ,
  mapOrEmptyArray ,
  mapOrEmptyDict ,
  flatMapOrFalse ,
  flatMapOrTrue ,
  flatMapOrEmpty ,
  flatMapOrEmptyArray ,
  tap ,
  tapL ,
  invert ,
  or_ ,
  and_ ,
  andMap ,
  someIf ,
  noneIf ,
  isNoneOrEmpty ,
  
}
/* No side effect */
