// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as WL_Color$DraftbitBuilder from "../../WL_Color.bs.js";
import * as DataCyWrapper$DraftbitBuilder from "../../../components/DataCyWrapper.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";

((require("./Icon.css")));

((require("./FAIconList.js")));

var FontAwesomeIcon = {};

var _map = {"DuoTone":"fad","Regular":"far","Light":"fal","Solid":"fas"};

var _revMap = {"fad":"DuoTone","far":"Regular","fal":"Light","fas":"Solid"};

function styleToJs(param) {
  return _map[param];
}

function styleFromJs(param) {
  return _revMap[param];
}

function iconToJs(param) {
  return param + 0 | 0;
}

function iconFromJs(param) {
  if (param <= 75 && 0 <= param) {
    return param - 0 | 0;
  }
  
}

function getIcon(icon) {
  switch (icon) {
    case /* ArrowCircleDown */0 :
        return {
                name: "arrow-circle-down",
                style: "DuoTone",
                className: "Icon-arrowCircleDown--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* ArrowCircleRight */1 :
        return {
                name: "arrow-circle-right",
                style: "Light",
                className: undefined,
                defaultColor: "Brand",
                transformations: ""
              };
    case /* ArrowCircleUp */2 :
        return {
                name: "arrow-circle-up",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* ArrowToRight */3 :
        return {
                name: "arrow-to-right",
                style: "Regular",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* Ban */4 :
        return {
                name: "ban",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* BookOpen */5 :
        return {
                name: "book-open",
                style: "Solid",
                className: "Icon-bookOpen--dt",
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* BoxBallot */6 :
        return {
                name: "box-ballot",
                style: "DuoTone",
                className: "Icon-boxBallot--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* BracketsCurly */7 :
        return {
                name: "brackets-curly",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* Browser */8 :
        return {
                name: "browser",
                style: "Solid",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* Check */9 :
        return {
                name: "check",
                style: "DuoTone",
                className: "Icon-check--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* CheckCircle */10 :
        return {
                name: "check-circle",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* ChevronDown */11 :
        return {
                name: "chevron-down",
                style: "Light",
                className: undefined,
                defaultColor: "Mono300",
                transformations: ""
              };
    case /* ChevronLeft */12 :
        return {
                name: "chevron-left",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* ChevronRight */13 :
        return {
                name: "chevron-right",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* Clone */14 :
        return {
                name: "clone",
                style: "Regular",
                className: "Icon-duotone",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Code */15 :
        return {
                name: "code",
                style: "DuoTone",
                className: "Icon-code--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* CodeBranch */16 :
        return {
                name: "code-branch",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* CodeSolid */17 :
        return {
                name: "code",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* Cog */18 :
        return {
                name: "cog",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono200",
                transformations: ""
              };
    case /* Copy */19 :
        return {
                name: "copy",
                style: "Regular",
                className: undefined,
                defaultColor: "Mono200",
                transformations: ""
              };
    case /* Database */20 :
        return {
                name: "database",
                style: "Light",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* DatabaseDuotone */21 :
        return {
                name: "database",
                style: "DuoTone",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* Ellipsis */22 :
        return {
                name: "ellipsis",
                style: "Regular",
                className: undefined,
                defaultColor: "Mono300",
                transformations: ""
              };
    case /* EllipsisV */23 :
        return {
                name: "ellipsis-v",
                style: "Regular",
                className: undefined,
                defaultColor: "Mono300",
                transformations: ""
              };
    case /* ExclamationTriangle */24 :
        return {
                name: "exclamation-triangle",
                style: "DuoTone",
                className: "Icon-exclamationTriangle--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* ExternalLink */25 :
        return {
                name: "external-link",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: "shrink-4"
              };
    case /* ExternalLinkRegular */26 :
        return {
                name: "external-link",
                style: "Regular",
                className: "Icon-duotone",
                defaultColor: undefined,
                transformations: "shrink-4"
              };
    case /* EyeDropper */27 :
        return {
                name: "eye-dropper",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono300",
                transformations: ""
              };
    case /* Folder */28 :
        return {
                name: "folder",
                style: "DuoTone",
                className: "Icon-folder--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* FontCase */29 :
        return {
                name: "font-case",
                style: "Light",
                className: undefined,
                defaultColor: "Mono500",
                transformations: ""
              };
    case /* Image */30 :
        return {
                name: "image",
                style: "DuoTone",
                className: "Icon-image--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* InfoCircle */31 :
        return {
                name: "info-circle",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono600",
                transformations: "shrink-4"
              };
    case /* Keyboard */32 :
        return {
                name: "keyboard",
                style: "DuoTone",
                className: "Icon-keyboard--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* LayerGroup */33 :
        return {
                name: "layer-group",
                style: "DuoTone",
                className: "Icon-layer-group--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Megaphone */34 :
        return {
                name: "megaphone",
                style: "DuoTone",
                className: "Icon-duotone",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Minus */35 :
        return {
                name: "minus",
                style: "DuoTone",
                className: "Icon-minus--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Mobile */36 :
        return {
                name: "mobile-alt",
                style: "DuoTone",
                className: "Icon-mobile--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* MobileSolid */37 :
        return {
                name: "mobile-alt",
                style: "Solid",
                className: "Icon-mobile--dt",
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* MobileLight */38 :
        return {
                name: "mobile-alt",
                style: "Light",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* PaperPlane */39 :
        return {
                name: "paper-plane",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono200",
                transformations: "shrink-4"
              };
    case /* Pen */40 :
        return {
                name: "pen",
                style: "DuoTone",
                className: "Icon-pen--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* PenSquare */41 :
        return {
                name: "pen-square",
                style: "DuoTone",
                className: "Icon-penSquare--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* PhotoVideo */42 :
        return {
                name: "photo-video",
                style: "DuoTone",
                className: "Icon-photoVideo--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Play */43 :
        return {
                name: "play",
                style: "DuoTone",
                className: "Icon-play--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* PlayLivePreview */44 :
        return {
                name: "play",
                style: "Solid",
                className: "Icon-play--dt",
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* PlayCircle */45 :
        return {
                name: "play-circle",
                style: "DuoTone",
                className: "Icon-playCircle--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Plus */46 :
        return {
                name: "plus",
                style: "Regular",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* PlusCircle */47 :
        return {
                name: "plus-circle",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* PlusCircleRegular */48 :
        return {
                name: "plus-circle",
                style: "Regular",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* PlusCircleDuoTone */49 :
        return {
                name: "plus-circle",
                style: "DuoTone",
                className: "Icon-blue-on-white--dt",
                defaultColor: "Mono100",
                transformations: "grow-4"
              };
    case /* PlusCircleWorkspaceAppCard */50 :
        return {
                name: "plus-circle",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: "grow-16"
              };
    case /* PlusSquare */51 :
        return {
                name: "plus-square",
                style: "DuoTone",
                className: "Icon-plusSquare--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* QuestionCircle */52 :
        return {
                name: "question-circle",
                style: "DuoTone",
                className: "Icon-questionCircle--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* RedoDuoTone */53 :
        return {
                name: "redo",
                style: "DuoTone",
                className: "Icon-redo--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Redo */54 :
        return {
                name: "redo",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: "shrink-2"
              };
    case /* Rocket */55 :
        return {
                name: "rocket",
                style: "DuoTone",
                className: "Icon-duotone",
                defaultColor: undefined,
                transformations: ""
              };
    case /* RocketSolid */56 :
        return {
                name: "rocket",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* Save */57 :
        return {
                name: "save",
                style: "Regular",
                className: "Icon-save--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Search */58 :
        return {
                name: "search",
                style: "Light",
                className: undefined,
                defaultColor: "Mono400",
                transformations: ""
              };
    case /* ShareSquare */59 :
        return {
                name: "share-alt-square",
                style: "DuoTone",
                className: "Icon-shareSquare--dt",
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* ShareSquareSolid */60 :
        return {
                name: "share-alt-square",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* SiteMap */61 :
        return {
                name: "sitemap",
                style: "DuoTone",
                className: "Icon-sitemap--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Stream */62 :
        return {
                name: "stream",
                style: "DuoTone",
                className: "Icon-stream--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Times */63 :
        return {
                name: "times",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* ThLarge */64 :
        return {
                name: "th-large",
                style: "Solid",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* TimesCircle */65 :
        return {
                name: "times-circle",
                style: "Light",
                className: undefined,
                defaultColor: "Mono100",
                transformations: ""
              };
    case /* Trash */66 :
        return {
                name: "trash-alt",
                style: "Regular",
                className: "Icon-trash--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Undo */67 :
        return {
                name: "undo",
                style: "DuoTone",
                className: "Icon-undo--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* User */68 :
        return {
                name: "user-circle",
                style: "DuoTone",
                className: "Icon-userCircle--dt",
                defaultColor: undefined,
                transformations: ""
              };
    case /* Users */69 :
        return {
                name: "users",
                style: "DuoTone",
                className: "Icon-duotone",
                defaultColor: undefined,
                transformations: ""
              };
    case /* AngleRight */70 :
        return {
                name: "angle-right",
                style: "Regular",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* AngleDown */71 :
        return {
                name: "angle-down",
                style: "Regular",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* Swatchbook */72 :
        return {
                name: "swatchbook",
                style: "DuoTone",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* PaintBrush */73 :
        return {
                name: "paintbrush-fine",
                style: "Light",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* SlidersVSquare */74 :
        return {
                name: "square-sliders-vertical",
                style: "Light",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    case /* Magic */75 :
        return {
                name: "wand-magic-sparkles",
                style: "Light",
                className: undefined,
                defaultColor: undefined,
                transformations: ""
              };
    
  }
}

function getSizeClassNames(size) {
  switch (size) {
    case /* XSmall */0 :
        return "text-2xl max-h-3 max-w-3";
    case /* Small */1 :
        return "text-2xl max-h-4 max-w-4";
    case /* Medium */2 :
        return "text-2xl max-h-6 max-w-6";
    case /* Large */3 :
        return "text-2xl max-h-8 max-w-8";
    case /* XLarge */4 :
        return "text-3xl max-h-12 max-w-12";
    
  }
}

function getColor(color, icon) {
  if (color !== undefined) {
    return WL_Color$DraftbitBuilder.toHexString(color);
  } else {
    return Belt_Option.mapWithDefault(getIcon(icon).defaultColor, "", WL_Color$DraftbitBuilder.toHexString);
  }
}

function getClassName(icon, size) {
  return Cn.make({
              hd: Belt_Option.getWithDefault(getIcon(icon).className, ""),
              tl: {
                hd: getSizeClassNames(size),
                tl: /* [] */0
              }
            });
}

function Icon(Props) {
  var icon = Props.icon;
  var size = Props.size;
  var color = Props.color;
  var transformOpt = Props.transform;
  var transform = transformOpt !== undefined ? transformOpt : "";
  var i = getIcon(icon);
  return React.createElement(ReactFontawesome.FontAwesomeIcon, {
              icon: [
                styleToJs(i.style),
                i.name
              ],
              className: getClassName(icon, size),
              transform: i.transformations + transform,
              color: getColor(color, icon)
            });
}

function Icon$Button(Props) {
  var icon = Props.icon;
  var size = Props.size;
  var onClick = Props.onClick;
  var onMouseOver = Props.onMouseOver;
  var disabledOpt = Props.disabled;
  var alt = Props.alt;
  var color = Props.color;
  var dataCy = Props.dataCy;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var i = getIcon(icon);
  var tmp = {
    "aria-label": alt,
    className: "flex",
    title: alt,
    disabled: disabled,
    onClick: onClick
  };
  if (onMouseOver !== undefined) {
    tmp.onMouseOver = Caml_option.valFromOption(onMouseOver);
  }
  var button = React.createElement("button", tmp, React.createElement(ReactFontawesome.FontAwesomeIcon, {
            icon: [
              styleToJs(i.style),
              i.name
            ],
            className: Cn.make({
                  hd: getSizeClassNames(size),
                  tl: /* [] */0
                }),
            transform: i.transformations,
            color: getColor(color, icon)
          }));
  if (dataCy !== undefined) {
    return React.createElement(DataCyWrapper$DraftbitBuilder.make, {
                children: button,
                name: Caml_option.valFromOption(dataCy)
              });
  } else {
    return button;
  }
}

var Button = {
  make: Icon$Button
};

var make = Icon;

export {
  FontAwesomeIcon ,
  styleToJs ,
  styleFromJs ,
  iconToJs ,
  iconFromJs ,
  getIcon ,
  getSizeClassNames ,
  getColor ,
  getClassName ,
  make ,
  Button ,
  
}
/*  Not a pure module */
