// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";

function space(inner) {
  if (inner) {
    return "p";
  } else {
    return "m";
  }
}

function create(inner, dir, unit) {
  if (unit < 0) {
    return "-" + ((
              inner ? "p" : "m"
            ) + (dir + ("-" + String(-unit | 0))));
  } else if (unit > 0) {
    return (
            inner ? "p" : "m"
          ) + (dir + ("-" + String(unit)));
  } else {
    return "";
  }
}

function Spacer(Props) {
  var fullWidthOpt = Props.fullWidth;
  var childrenOpt = Props.children;
  var innerOpt = Props.inner;
  var topOpt = Props.top;
  var bottomOpt = Props.bottom;
  var leftOpt = Props.left;
  var rightOpt = Props.right;
  var allOpt = Props.all;
  var xOpt = Props.x;
  var yOpt = Props.y;
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : false;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var inner = innerOpt !== undefined ? innerOpt : false;
  var top = topOpt !== undefined ? topOpt : 0;
  var bottom = bottomOpt !== undefined ? bottomOpt : 0;
  var left = leftOpt !== undefined ? leftOpt : 0;
  var right = rightOpt !== undefined ? rightOpt : 0;
  var all = allOpt !== undefined ? allOpt : 0;
  var x = xOpt !== undefined ? xOpt : 0;
  var y = yOpt !== undefined ? yOpt : 0;
  var className = Cn.make({
        hd: Cn.ifTrue("w-full", fullWidth),
        tl: {
          hd: create(inner, "t", top),
          tl: {
            hd: create(inner, "b", bottom),
            tl: {
              hd: create(inner, "l", left),
              tl: {
                hd: create(inner, "r", right),
                tl: {
                  hd: create(inner, "y", y),
                  tl: {
                    hd: create(inner, "x", x),
                    tl: {
                      hd: create(inner, "", all),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      });
  return React.createElement("div", {
              className: className
            }, children);
}

var make = Spacer;

export {
  space ,
  create ,
  make ,
  
}
/* react Not a pure module */
