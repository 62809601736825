// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import GraphqlTag from "graphql-tag";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.mjs";
import * as ReactHooks from "@apollo/react-hooks";
import * as ApolloHooksTypes$DraftbitBuilder from "./ApolloHooksTypes.bs.js";

function useQuery(client, variables, notifyOnNetworkStatusChange, fetchPolicy, errorPolicy, skip, pollInterval, context, param) {
  var parse = param[0];
  var tmp = {};
  if (variables !== undefined) {
    tmp.variables = Caml_option.valFromOption(variables);
  }
  if (client !== undefined) {
    tmp.client = Caml_option.valFromOption(client);
  }
  if (notifyOnNetworkStatusChange !== undefined) {
    tmp.notifyOnNetworkStatusChange = Caml_option.valFromOption(notifyOnNetworkStatusChange);
  }
  var tmp$1 = Belt_Option.map(fetchPolicy, ApolloHooksTypes$DraftbitBuilder.fetchPolicyToJs);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(errorPolicy, ApolloHooksTypes$DraftbitBuilder.errorPolicyToJs);
  if (tmp$2 !== undefined) {
    tmp.errorPolicy = Caml_option.valFromOption(tmp$2);
  }
  if (skip !== undefined) {
    tmp.skip = Caml_option.valFromOption(skip);
  }
  if (pollInterval !== undefined) {
    tmp.pollInterval = Caml_option.valFromOption(pollInterval);
  }
  if (context !== undefined) {
    tmp.context = Caml_option.valFromOption(context);
  }
  var jsResult = ReactHooks.useQuery(GraphqlTag(param[1]), tmp);
  var getData = function (obj) {
    return Belt_Option.getExn(Belt_Option.flatMap(Js_json.decodeObject(obj), (function (x) {
                      return Js_dict.get(x, "data");
                    })));
  };
  var result = React.useMemo((function () {
          return {
                  data: Belt_Option.flatMap(Caml_option.nullable_to_opt(jsResult.data), (function (data) {
                          var parsedData;
                          try {
                            parsedData = Curry._1(parse, data);
                          }
                          catch (exn){
                            return ;
                          }
                          return Caml_option.some(parsedData);
                        })),
                  loading: jsResult.loading,
                  error: Caml_option.nullable_to_opt(jsResult.error),
                  refetch: (function (variables, param) {
                      return jsResult.refetch(Js_null_undefined.fromOption(variables)).then(function (result) {
                                  return Promise.resolve(Curry._1(parse, getData(result)));
                                });
                    }),
                  fetchMore: (function (variables, updateQuery, param) {
                      var tmp = {
                        updateQuery: updateQuery
                      };
                      if (variables !== undefined) {
                        tmp.variables = Caml_option.valFromOption(variables);
                      }
                      return jsResult.fetchMore(tmp);
                    }),
                  networkStatus: ApolloHooksTypes$DraftbitBuilder.toNetworkStatus(jsResult.networkStatus),
                  startPolling: (function (interval) {
                      return jsResult.startPolling(interval);
                    }),
                  stopPolling: (function (param) {
                      return jsResult.stopPolling();
                    }),
                  subscribeToMore: (function ($$document, variables, updateQuery, param) {
                      var tmp = {
                        document: $$document
                      };
                      if (variables !== undefined) {
                        tmp.variables = Caml_option.valFromOption(variables);
                      }
                      if (updateQuery !== undefined) {
                        tmp.updateQuery = Caml_option.valFromOption(updateQuery);
                      }
                      return jsResult.subscribeToMore(tmp);
                    })
                };
        }), [jsResult]);
  var simple = React.useMemo((function () {
          var data = result.data;
          if (result.loading) {
            return /* Loading */0;
          }
          var error = result.error;
          if (error !== undefined) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          } else if (data !== undefined) {
            return {
                    TAG: /* Data */0,
                    _0: Caml_option.valFromOption(data)
                  };
          } else {
            return /* NoData */1;
          }
        }), [result]);
  return [
          simple,
          result
        ];
}

export {
  useQuery ,
  
}
/* react Not a pure module */
