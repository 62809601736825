// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as WL_Color$DraftbitBuilder from "../../WL_Color.bs.js";
import * as OptionUtils$DraftbitBuilder from "../../../utilities/OptionUtils.bs.js";
import * as DataCyWrapper$DraftbitBuilder from "../../../components/DataCyWrapper.bs.js";
import * as ReactMarkdown$DraftbitBuilder from "../../../components/ReactMarkdown.bs.js";

function Text$Typography(Props) {
  var isMarkdownOpt = Props.isMarkdown;
  var color = Props.color;
  var hoverColor = Props.hoverColor;
  var groupHoverColor = Props.groupHoverColor;
  var trackingOpt = Props.tracking;
  var sizeOpt = Props.size;
  var weightOpt = Props.weight;
  var leadingOpt = Props.leading;
  var alignOpt = Props.align;
  var inlineOpt = Props.inline;
  var uppercaseOpt = Props.uppercase;
  var truncateOpt = Props.truncate;
  var fontFamilyOpt = Props.fontFamily;
  var children = Props.children;
  var dataCyOpt = Props.dataCy;
  var userSelect = Props.userSelect;
  var title = Props.title;
  var isMarkdown = isMarkdownOpt !== undefined ? isMarkdownOpt : false;
  var tracking = trackingOpt !== undefined ? trackingOpt : "Normal";
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var weight = weightOpt !== undefined ? weightOpt : "Normal";
  var leading = leadingOpt !== undefined ? leadingOpt : "Normal";
  var align = alignOpt !== undefined ? alignOpt : "Left";
  var inline = inlineOpt !== undefined ? inlineOpt : false;
  var uppercase = uppercaseOpt !== undefined ? uppercaseOpt : false;
  var truncate = truncateOpt !== undefined ? truncateOpt : false;
  var fontFamily = fontFamilyOpt !== undefined ? fontFamilyOpt : "Sans";
  var dataCy = dataCyOpt !== undefined ? dataCyOpt : "Typography";
  var tracking$1 = tracking === "Normal" ? "tracking-normal" : (
      tracking === "Widest" ? "tracking-widest" : (
          tracking === "Tight" ? "tracking-tight" : (
              tracking === "Tighter" ? "tracking-tighter" : (
                  tracking === "Wide" ? "tracking-wide" : "tracking-wider"
                )
            )
        )
    );
  var size$1 = size === "XXLarge" ? "text-2xl" : (
      size === "Large" ? "text-lg" : (
          size === "Small" ? "text-sm" : (
              size === "XLarge" ? "text-xl" : (
                  size === "Medium" ? "text-md" : "text-xs"
                )
            )
        )
    );
  var weight$1 = weight === "Normal" ? "font-normal" : (
      weight === "Light" ? "font-light" : (
          weight === "Bold" ? "font-bold" : (
              weight === "Extrabold" ? "font-extrabold" : (
                  weight === "Medium" ? "font-medium" : (
                      weight === "Thin" ? "font-extralight" : (
                          weight === "Semibold" ? "font-semibold" : (
                              weight === "Hairline" ? "font-thin" : "font-black"
                            )
                        )
                    )
                )
            )
        )
    );
  var align$1 = align === "Right" ? "text-right" : (
      align === "Left" ? "text-left" : (
          align === "Center" ? "text-center" : "text-justify"
        )
    );
  var lineHeight = leading === "Loose" ? "leading-loose" : (
      leading === "Tight" ? "leading-tight" : (
          leading === "Relaxed" ? "leading-relaxed" : (
              leading === "None" ? "leading-none" : (
                  leading === "Snug" ? "leading-snug" : "leading-normal"
                )
            )
        )
    );
  var fontFamily$1 = fontFamily === "Sans" ? "font-sans" : "font-mono";
  var userSelect$1 = Belt_Option.mapWithDefault(userSelect, "", (function (s) {
          if (s === "Auto") {
            return "select-auto";
          } else if (s === "None") {
            return "select-none";
          } else if (s === "Text") {
            return "select-text";
          } else {
            return "select-all";
          }
        }));
  var className = Cn.make({
        hd: fontFamily$1,
        tl: {
          hd: tracking$1,
          tl: {
            hd: size$1,
            tl: {
              hd: weight$1,
              tl: {
                hd: align$1,
                tl: {
                  hd: WL_Color$DraftbitBuilder.toTailwind(color, "text"),
                  tl: {
                    hd: lineHeight,
                    tl: {
                      hd: Cn.ifTrue("inline", inline),
                      tl: {
                        hd: Cn.ifTrue("block", !inline),
                        tl: {
                          hd: Cn.ifTrue("uppercase", uppercase),
                          tl: {
                            hd: Cn.ifTrue("block truncate", truncate),
                            tl: {
                              hd: userSelect$1,
                              tl: {
                                hd: hoverColor !== undefined ? WL_Color$DraftbitBuilder.toTailwind(hoverColor, "hover:text") : "",
                                tl: {
                                  hd: groupHoverColor !== undefined ? WL_Color$DraftbitBuilder.toTailwind(groupHoverColor, "group-hover:text") : "",
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var tmp;
  if (isMarkdown) {
    tmp = React.createElement(ReactMarkdown$DraftbitBuilder.make, {
          source: children,
          className: className
        });
  } else {
    var tmp$1 = {
      className: className
    };
    var tmp$2 = OptionUtils$DraftbitBuilder.or_(title, OptionUtils$DraftbitBuilder.someIf(truncate, children));
    if (tmp$2 !== undefined) {
      tmp$1.title = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement("span", tmp$1, children);
  }
  return React.createElement(DataCyWrapper$DraftbitBuilder.make, {
              children: tmp,
              name: dataCy
            });
}

var Typography = {
  make: Text$Typography
};

function $$Text(Props) {
  var colorOpt = Props.color;
  var hoverColor = Props.hoverColor;
  var groupHoverColor = Props.groupHoverColor;
  var alignOpt = Props.align;
  var inlineOpt = Props.inline;
  var uppercaseOpt = Props.uppercase;
  var truncateOpt = Props.truncate;
  var children = Props.children;
  var kindOpt = Props.kind;
  var dataCyOpt = Props.dataCy;
  var fontFamilyOpt = Props.fontFamily;
  var trackingOpt = Props.tracking;
  var userSelect = Props.userSelect;
  var isMarkdown = Props.isMarkdown;
  var title = Props.title;
  var color = colorOpt !== undefined ? colorOpt : "CurrentColor";
  var align = alignOpt !== undefined ? alignOpt : "Left";
  var inline = inlineOpt !== undefined ? inlineOpt : false;
  var uppercase = uppercaseOpt !== undefined ? uppercaseOpt : false;
  var truncate = truncateOpt !== undefined ? truncateOpt : false;
  var kind = kindOpt !== undefined ? kindOpt : "B2";
  var dataCy = dataCyOpt !== undefined ? dataCyOpt : "Text";
  var fontFamily = fontFamilyOpt !== undefined ? fontFamilyOpt : "Sans";
  var tracking = trackingOpt !== undefined ? trackingOpt : "Normal";
  var match = kind === "H5" || kind === "B2" ? [
      "Small",
      "Normal",
      "Normal"
    ] : (
      kind === "B3" ? [
          "XSmall",
          "Normal",
          "Normal"
        ] : (
          kind === "H1" ? [
              "XLarge",
              "Semibold",
              "Normal"
            ] : (
              kind === "H2" ? [
                  "Large",
                  "Semibold",
                  "Normal"
                ] : (
                  kind === "H3" ? [
                      "Medium",
                      "Semibold",
                      "Normal"
                    ] : (
                      kind === "H4" ? [
                          "Small",
                          "Semibold",
                          "Normal"
                        ] : (
                          kind === "Label" ? [
                              "Small",
                              "Medium",
                              "Loose"
                            ] : [
                              "Medium",
                              "Normal",
                              "Normal"
                            ]
                        )
                    )
                )
            )
        )
    );
  var tmp = {
    color: color,
    tracking: tracking,
    size: match[0],
    weight: match[1],
    leading: match[2],
    align: align,
    inline: inline,
    uppercase: uppercase,
    truncate: truncate,
    fontFamily: fontFamily,
    children: children,
    dataCy: dataCy
  };
  if (isMarkdown !== undefined) {
    tmp.isMarkdown = Caml_option.valFromOption(isMarkdown);
  }
  if (hoverColor !== undefined) {
    tmp.hoverColor = Caml_option.valFromOption(hoverColor);
  }
  if (groupHoverColor !== undefined) {
    tmp.groupHoverColor = Caml_option.valFromOption(groupHoverColor);
  }
  if (userSelect !== undefined) {
    tmp.userSelect = Caml_option.valFromOption(userSelect);
  }
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  return React.createElement(Text$Typography, tmp);
}

var make = $$Text;

var $$default = $$Text;

export {
  Typography ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
