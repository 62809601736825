// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";

var _map = {"Dark":"Dark","Default":"Default","Light":"Light"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function t_encode(t) {
  return t;
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("StatusBarColor.t", tFromJs, param);
}

var allColors = [
  "Dark",
  "Default",
  "Light"
];

export {
  tToJs ,
  tFromJs ,
  t_encode ,
  t_decode ,
  allColors ,
  
}
/* JsonUtils-DraftbitBuilder Not a pure module */
