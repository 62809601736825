// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Slug from "slug";
import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as JsSet from "rescript-js-collections/src/JsSet.bs.js";
import * as Humps from "humps";
import Slugify from "slugify";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.mjs";
import * as Pluralize from "pluralize";
import * as Caml_array from "bs-platform/lib/es6/caml_array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Belt_Result from "bs-platform/lib/es6/belt_Result.mjs";
import * as Caml_format from "bs-platform/lib/es6/caml_format.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as LodashIsstring from "lodash.isstring";
import * as NumberToWords from "number-to-words";
import * as IsValidVarName from "is-valid-var-name";
import * as Valid from "semver/ranges/valid";
import * as Sucrase$DraftbitBuilder from "../bindings/Sucrase.bs.js";
import * as ValidateNpmPackageName from "validate-npm-package-name";
import * as ArrayUtils$DraftbitBuilder from "./ArrayUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "./OptionUtils.bs.js";

function isMatch(str, reg) {
  return Caml_option.null_to_opt(str.match(reg)) !== undefined;
}

function isInt(s) {
  return isMatch(s, /^[0-9]+$/);
}

function noneIfEmpty(s) {
  if (s === "") {
    return ;
  } else {
    return s;
  }
}

function getNonEmpty(s) {
  return Belt_Option.flatMap(s, noneIfEmpty);
}

function isVariable(s) {
  return isMatch(s, /^{{\s*[a-zA-Z]\w*\s*}}$/);
}

function emptyIfNone(optS) {
  return Belt_Option.getWithDefault(optS, "");
}

function emptyToNone(s) {
  if (s === "") {
    return ;
  } else {
    return s;
  }
}

function isEmpty(value) {
  return value.length === 0;
}

function nonEmpties(arr) {
  return arr.filter(function (s) {
              var value = s.trim();
              return value.length !== 0;
            });
}

function mapJoinNonEmpty(arr, sep, f) {
  return nonEmpties(arr.map(Curry.__1(f))).join(sep);
}

function or_(s1, s2) {
  if (s1 !== "") {
    return s1;
  } else {
    return s2;
  }
}

function and_(str1, str2) {
  if (str1 === "") {
    return "";
  } else {
    return str1 + str2;
  }
}

function capitalize(str) {
  var firstLetterUppercase = str.charAt(0).toUpperCase();
  var restOfStr = str.slice(1, str.length);
  return firstLetterUppercase + restOfStr;
}

function toInt(str) {
  return Belt_Option.getWithDefault(Belt_Int.fromString(str), 0);
}

function isIntString(str) {
  return isMatch(str, /^[-+]?([0-9])+$/);
}

function quote(s) {
  return JSON.stringify(s);
}

function pascalize(str) {
  return Humps.pascalize(str.replace(/[^\w]/g, " "));
}

function camelize(str) {
  return Humps.camelize(str.replace(/[^\w]/g, " "));
}

function pascalToWords(s) {
  return s.replace(/([A-Z])/g, " $1").trim();
}

function capitalizeAllWords(str) {
  return pascalToWords(pascalize(str));
}

function slugify(str) {
  return Slug(str, "_", {
              lower: false
            });
}

function slugifyHyphens(str) {
  return Slug(str, "-", {
              lower: false
            });
}

var sanitizedNameForProjects = (function (name) {
    return name
      .replace(/[\W_]+/g, '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  });

function sanitizedName(name) {
  return or_(or_(sanitizedNameForProjects(name), sanitizedNameForProjects(Slugify(name, {
                          lower: false
                        }))), "app");
}

function slugifyDashesLower(str) {
  return Slug(Humps.decamelize(camelize(str), {"separator": "-"}), "-", {
              lower: true
            });
}

function nonEmpty(s) {
  return s.length > 0;
}

function removeEmptyStrings(arr) {
  return arr.filter(nonEmpty);
}

function toJsonOrString(x) {
  try {
    return Json.stringify(x);
  }
  catch (exn){
    return x.toString();
  }
}

function renderCount(count, noun) {
  return count.toString() + (" " + (
            count === 1 ? Pluralize.singular(noun) : Pluralize.plural(noun)
          ));
}

function renderLength(arr, noun) {
  return renderCount(arr.length, noun);
}

function renderCountWords(count, noun) {
  return NumberToWords.toWords(count) + (" " + (
            count === 1 ? Pluralize.singular(noun) : Pluralize.plural(noun)
          ));
}

function renderLengthWords(arr, noun) {
  return renderCountWords(arr.length, noun);
}

function renderLineAndColumn(param) {
  return "line " + param.line.toString() + ", column " + param.column.toString();
}

function getLineAndColumn(str, index) {
  if (index >= str.length || index < 0) {
    return ;
  }
  var substr = str.slice(0, index);
  var lines = substr.split("\n");
  return {
          line: lines.length,
          column: Belt_Option.mapWithDefault(ArrayUtils$DraftbitBuilder.last(lines), 0, (function (prim) {
                  return prim.length;
                }))
        };
}

function splitFirst(str, separator) {
  var n = str.indexOf(separator);
  if (n === -1) {
    return [
            str,
            ""
          ];
  }
  var first = str.slice(0, n);
  var rest = str.slice(n + 1 | 0);
  return [
          first,
          rest
        ];
}

function splitLast(str, separator) {
  var _i = str.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i <= 0) {
      return [
              "",
              str
            ];
    }
    if (str.charAt(i) === separator) {
      return [
              str.slice(0, i),
              str.slice(i + 1 | 0)
            ];
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function isValid(name) {
  return ValidateNpmPackageName(name);
}

var Name = {
  isValid: isValid
};

function isValid$1(version) {
  if (version === "") {
    return false;
  } else {
    return !(Valid(version) == null);
  }
}

var Version = {
  isValid: isValid$1
};

var NpmPackage = {
  Name: Name,
  Version: Version
};

var reservedWords = [
  "abstract",
  "await",
  "boolean",
  "break",
  "byte",
  "case",
  "catch",
  "char",
  "class",
  "const",
  "continue",
  "debugger",
  "default",
  "delete",
  "do",
  "double",
  "else",
  "enum",
  "export",
  "extends",
  "false",
  "final",
  "finally",
  "float",
  "for",
  "function",
  "goto",
  "if",
  "implements",
  "import",
  "in",
  "instanceof",
  "int",
  "interface",
  "let",
  "long",
  "native",
  "new",
  "null",
  "package",
  "private",
  "protected",
  "public",
  "return",
  "short",
  "static",
  "string",
  "super",
  "switch",
  "synchronized",
  "this",
  "throw",
  "throws",
  "transient",
  "true",
  "try",
  "typeof",
  "var",
  "void",
  "volatile",
  "while",
  "with",
  "yield"
];

var re = /^[_$a-zA-Z][_$a-zA-Z0-9]*$/;

var FunctionName = {
  reservedWords: reservedWords,
  re: re
};

var bundleIdRe = /^[a-zA-Z0-9-.]+$/;

var packageRe = /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)+$/;

function validateBundleId(value) {
  return isMatch(value, bundleIdRe);
}

function validatePackage(value) {
  return isMatch(value, packageRe);
}

var AppJson = {
  bundleIdRe: bundleIdRe,
  packageRe: packageRe,
  validateBundleId: validateBundleId,
  validatePackage: validatePackage
};

function validateExpoSlug(value) {
  return isMatch(value, /^[_a-z][-_a-z0-9]*$/);
}

function validateExpoScheme(value) {
  return isMatch(value, /^[a-z][\.\+\-a-z0-9]*$/);
}

function isValidVarName(prim) {
  return IsValidVarName(prim);
}

function convertToJsIdentifier(name) {
  if (IsValidVarName(name)) {
    return name;
  } else {
    return camelize(slugify(name));
  }
}

function sanitize(value, regex) {
  var safeRegex = typeof regex === "string" ? (
      regex === "ConstrainedText" ? /[\u0000-\u0009\u000B-\u000C\u000E-\u001F\u00A0\u2000-\u2009\u200A\u2028\u205F\u3000]|[/\#,+$~%^":*?<>{}!@]+/g : (
          regex === "Text" ? /[\u0000-\u0009\u000B-\u000C\u000E-\u001F\u00A0\u2000-\u2009\u200A\u2028\u205F\u3000]+/g : /[\u0000-\u0009\u000B-\u000C\u000E-\u001F\u00A0\u2000-\u2009\u200A\u2028\u205F\u3000]|[/\#+()$~%^"*<>{}@]+/g
        )
    ) : regex.VAL;
  return value.replace(safeRegex, "");
}

var SpecialCharacters = {
  sanitize: sanitize
};

function take(str, n) {
  return str.slice(0, n);
}

function drop(str, n) {
  return str.slice(n);
}

function getUniqueString(stringsInUse, initialCandidate, separator) {
  var stringsInUse$1 = JsSet.fromArray(stringsInUse);
  var _candidate = initialCandidate;
  while(true) {
    var candidate = _candidate;
    if (!JsSet.has(stringsInUse$1, candidate)) {
      return candidate;
    }
    var m = candidate.match(/(\d*)$/);
    if (m !== null) {
      if (m.length === 2) {
        var match = m[1];
        if (match === "") {
          _candidate = candidate + separator + "2";
          continue ;
        }
        
      }
      var index = m.index;
      var string = candidate.slice(0, index);
      var number = Caml_format.caml_int_of_string(Caml_array.get(m, 1));
      _candidate = string + (number + 1 | 0).toString();
      continue ;
    }
    _candidate = candidate + separator + "2";
    continue ;
  };
}

function ensureSuffix(str, suffix) {
  if (str.endsWith(suffix)) {
    return str;
  } else {
    return str + suffix;
  }
}

function ensureSuffixNot(_str, suffix) {
  while(true) {
    var str = _str;
    if (!str.endsWith(suffix)) {
      return str;
    }
    _str = str.slice(0, str.length - suffix.length | 0);
    continue ;
  };
}

function ensurePrefix(str, prefix) {
  if (str.startsWith(prefix)) {
    return str;
  } else {
    return prefix + str;
  }
}

function ensurePrefixOneOf(str, prefixes) {
  if (prefixes.some(function (prefix) {
          return str.startsWith(prefix);
        })) {
    return str;
  } else {
    return Caml_array.get(prefixes, 0) + str;
  }
}

function ensurePrefixNot(_str, prefix) {
  while(true) {
    var str = _str;
    if (!str.startsWith(prefix)) {
      return str;
    }
    _str = str.slice(prefix.length);
    continue ;
  };
}

function ensurePrefixNotOneOf(_str, prefixes) {
  while(true) {
    var str = _str;
    var prefix = prefixes.find((function(str){
        return function (prefix) {
          return str.startsWith(prefix);
        }
        }(str)));
    if (prefix === undefined) {
      return str;
    }
    _str = str.slice(prefix.length);
    continue ;
  };
}

function createColorKey(name, value) {
  var key;
  if (name.startsWith("Custom")) {
    var slug = slugify(value.toLowerCase());
    key = "custom_" + slug;
  } else {
    key = camelize(slugify(name.replace(/\//g, " ")));
  }
  if (isMatch(key, /^\d/)) {
    return "_" + key;
  } else {
    return key;
  }
}

function truncateEllipses(str, maxLen) {
  if (str.length <= maxLen) {
    return str;
  } else {
    return str.slice(0, maxLen - 3 | 0) + "...";
  }
}

function iStartsWith(str, prefix) {
  return str.toLowerCase().startsWith(prefix.toLowerCase());
}

function iStartsWithOneOf(str, prefixes) {
  return prefixes.some(function (param) {
              return iStartsWith(str, param);
            });
}

function endsWithOneOf(str, suffixes) {
  return suffixes.some(function (param) {
              return str.endsWith(param);
            });
}

function dropSuffix(str, suffix) {
  if (str.endsWith(suffix)) {
    return str.slice(0, str.length - suffix.length | 0);
  } else {
    return str;
  }
}

function dropSuffixes(str, suffixes) {
  var suf = suffixes.find(function (suf) {
        return str.endsWith(suf);
      });
  if (suf !== undefined) {
    return dropSuffix(str, suf);
  } else {
    return str;
  }
}

function dropExtension(str) {
  return str.replace(/\.\w+$/, "");
}

var vowels = JsSet.fromArray([
      "a",
      "e",
      "i",
      "o",
      "u"
    ]);

function addA(str) {
  if (JsSet.has(vowels, str.slice(0, 1).toLowerCase())) {
    return "an " + str;
  } else {
    return "a " + str;
  }
}

function joinAnd(arr) {
  var len = arr.length;
  if (len >= 3) {
    return ArrayUtils$DraftbitBuilder.updateItem(arr, arr.length - 1 | 0, (function (str) {
                    return "and " + str;
                  })).join(", ");
  }
  switch (len) {
    case 0 :
        return "";
    case 1 :
        return arr[0];
    case 2 :
        var str1 = arr[0];
        var str2 = arr[1];
        return str1 + " and " + str2;
    
  }
}

function joinOr(arr) {
  var len = arr.length;
  if (len >= 3) {
    return ArrayUtils$DraftbitBuilder.updateItem(arr, arr.length - 1 | 0, (function (str) {
                    return "or " + str;
                  })).join(", ");
  }
  switch (len) {
    case 0 :
        return "";
    case 1 :
        return arr[0];
    case 2 :
        var str1 = arr[0];
        var str2 = arr[1];
        return str1 + " or " + str2;
    
  }
}

function backticks(str) {
  return "`" + str + "`";
}

function curlies(str) {
  return "{" + str + "}";
}

function brackets(str) {
  return "[" + str + "]";
}

function if_(str, cond) {
  if (cond) {
    return str;
  } else {
    return "";
  }
}

function caseInsensitiveIncludes(str, substr) {
  return str.toLowerCase().includes(substr.toLowerCase());
}

function isValidKeyPath(str) {
  if (str.trim() !== "") {
    return Belt_Result.isOk(Sucrase$DraftbitBuilder.safeTransform(undefined, "x" + str));
  } else {
    return false;
  }
}

function parseKeyPath(str) {
  var result = [];
  var badFormat = {
    contents: false
  };
  var isQuote = function (s) {
    return [
              "'",
              "`",
              "\""
            ].includes(s);
  };
  var isSeparator = function (s) {
    return [
              "?",
              ".",
              "[",
              "]"
            ].includes(s);
  };
  var loop = function (_index, _currentWord, _quote) {
    while(true) {
      var quote = _quote;
      var currentWord = _currentWord;
      var index = _index;
      var chr = str.charAt(index);
      var exit = 0;
      if (index > str.length) {
        badFormat.contents = true;
        return ;
      }
      if (quote !== undefined) {
        if (chr === "") {
          badFormat.contents = true;
          return ;
        }
        exit = 2;
      } else {
        if (chr === "") {
          return Belt_Option.forEach(currentWord, (function (param) {
                        return ArrayUtils$DraftbitBuilder.push(result, param);
                      }));
        }
        exit = 2;
      }
      if (exit === 2) {
        if (currentWord !== undefined) {
          if (quote !== undefined) {
            if (chr === "\\" && str.charAt(index + 1 | 0) === quote) {
              _currentWord = currentWord + quote;
              _index = index + 2 | 0;
              continue ;
            }
            if (chr !== quote) {
              _currentWord = currentWord + chr;
              _index = index + 1 | 0;
              continue ;
            }
            if (chr !== quote) {
              badFormat.contents = true;
              return ;
            }
            ArrayUtils$DraftbitBuilder.push(result, currentWord);
            _quote = undefined;
            _currentWord = undefined;
            _index = index + 1 | 0;
            continue ;
          }
          if (isMatch(chr, /\w/)) {
            _quote = undefined;
            _currentWord = currentWord + chr;
            _index = index + 1 | 0;
            continue ;
          }
          
        } else {
          if (quote !== undefined) {
            badFormat.contents = true;
            return ;
          }
          if (isQuote(chr)) {
            _quote = chr;
            _currentWord = "";
            _index = index + 1 | 0;
            continue ;
          }
          if (isMatch(chr, /\w/)) {
            _quote = undefined;
            _currentWord = chr;
            _index = index + 1 | 0;
            continue ;
          }
          
        }
      }
      if (!isSeparator(chr)) {
        badFormat.contents = true;
        return ;
      }
      Belt_Option.forEach(currentWord, (function (param) {
              return ArrayUtils$DraftbitBuilder.push(result, param);
            }));
      _quote = undefined;
      _currentWord = undefined;
      _index = index + 1 | 0;
      continue ;
    };
  };
  loop(0, undefined, undefined);
  return OptionUtils$DraftbitBuilder.someIf(!badFormat.contents, result);
}

function renderKeyPath(checkNulls, startOpt, keys) {
  var start = startOpt !== undefined ? startOpt : "";
  return keys.reduce((function (lastExpr, key) {
                var checkNulls$1 = checkNulls && lastExpr !== "";
                if (isInt(key)) {
                  if (checkNulls$1) {
                    return lastExpr + "?.[" + key + "]";
                  } else {
                    return lastExpr + "[" + key + "]";
                  }
                } else if (IsValidVarName(key)) {
                  if (checkNulls$1) {
                    return lastExpr + "?." + key;
                  } else {
                    return lastExpr + "." + key;
                  }
                } else if (checkNulls$1) {
                  return lastExpr + "?.[" + JSON.stringify(key) + "]";
                } else {
                  return lastExpr + "[" + JSON.stringify(key) + "]";
                }
              }), start);
}

function validVarNameOrQuote(str) {
  if (IsValidVarName(str)) {
    return str;
  } else {
    return JSON.stringify(str);
  }
}

function isUrlEncoded(s) {
  if (!LodashIsstring(s)) {
    return false;
  }
  if (!isMatch(s, /%[0-9A-F][0-9A-F]/)) {
    return false;
  }
  try {
    var decoded = decodeURIComponent(s);
    return decoded !== s;
  }
  catch (exn){
    return false;
  }
}

export {
  isMatch ,
  isInt ,
  noneIfEmpty ,
  getNonEmpty ,
  isVariable ,
  emptyIfNone ,
  emptyToNone ,
  isEmpty ,
  nonEmpties ,
  mapJoinNonEmpty ,
  or_ ,
  and_ ,
  capitalize ,
  toInt ,
  isIntString ,
  quote ,
  pascalize ,
  camelize ,
  pascalToWords ,
  capitalizeAllWords ,
  slugify ,
  slugifyHyphens ,
  sanitizedNameForProjects ,
  sanitizedName ,
  slugifyDashesLower ,
  nonEmpty ,
  removeEmptyStrings ,
  toJsonOrString ,
  renderCount ,
  renderLength ,
  renderCountWords ,
  renderLengthWords ,
  renderLineAndColumn ,
  getLineAndColumn ,
  splitFirst ,
  splitLast ,
  NpmPackage ,
  FunctionName ,
  AppJson ,
  validateExpoSlug ,
  validateExpoScheme ,
  isValidVarName ,
  convertToJsIdentifier ,
  SpecialCharacters ,
  take ,
  drop ,
  getUniqueString ,
  ensureSuffix ,
  ensureSuffixNot ,
  ensurePrefix ,
  ensurePrefixOneOf ,
  ensurePrefixNot ,
  ensurePrefixNotOneOf ,
  createColorKey ,
  truncateEllipses ,
  iStartsWith ,
  iStartsWithOneOf ,
  endsWithOneOf ,
  dropSuffix ,
  dropSuffixes ,
  dropExtension ,
  addA ,
  joinAnd ,
  joinOr ,
  backticks ,
  curlies ,
  brackets ,
  if_ ,
  caseInsensitiveIncludes ,
  isValidKeyPath ,
  parseKeyPath ,
  renderKeyPath ,
  validVarNameOrQuote ,
  isUrlEncoded ,
  
}
/* vowels Not a pure module */
