// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as JsMap from "rescript-js-collections/src/JsMap.bs.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Text$DraftbitBuilder from "./Text/Text.bs.js";
import * as ArrayUtils$DraftbitBuilder from "../../utilities/ArrayUtils.bs.js";
import * as ReactUtils$DraftbitBuilder from "../../utilities/ReactUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "../../utilities/OptionUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "../../utilities/StringUtils.bs.js";
import * as DataCyButton$DraftbitBuilder from "../../components/DataCyButton.bs.js";
import * as HoverTooltip$DraftbitBuilder from "./Tooltip/HoverTooltip.bs.js";
import * as PromiseUtils$DraftbitBuilder from "../../utilities/PromiseUtils.bs.js";
import * as WL_TextInput$DraftbitBuilder from "./TextInput/WL_TextInput.bs.js";
import * as ReactMarkdown$DraftbitBuilder from "../../components/ReactMarkdown.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as LoadingSpinner$DraftbitBuilder from "../../components/LoadingSpinner.bs.js";
import * as ScrollContainer$DraftbitBuilder from "../../components/ScrollContainer.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function setScreenWith(f) {
  return {
          TAG: /* SetMenuState */0,
          _0: (function (param) {
              return {
                      TAG: /* Screen */0,
                      _0: Curry._1(f, undefined)
                    };
            })
        };
}

function setScreenAsync(loadingMessage, spinnerSize, prom) {
  return {
          TAG: /* SetMenuStateAsync */2,
          _0: loadingMessage,
          _1: spinnerSize,
          _2: (function (param) {
              return PromiseUtils$DraftbitBuilder.map(Curry._1(prom, undefined), (function (screen) {
                            return {
                                    TAG: /* Screen */0,
                                    _0: screen
                                  };
                          }));
            })
        };
}

function closeAfterAsync(loadingMessage, spinnerSize, prom) {
  return {
          TAG: /* CloseAfterAsync */3,
          _0: loadingMessage,
          _1: spinnerSize,
          _2: prom
        };
}

function setScreen(screen) {
  return {
          TAG: /* SetMenuState */0,
          _0: (function (param) {
              return {
                      TAG: /* Screen */0,
                      _0: screen
                    };
            })
        };
}

function ContextMenu$MenuOption(Props) {
  var label = Props.label;
  var icon = Props.icon;
  var onClick = Props.onClick;
  var description = Props.description;
  var title = Props.title;
  var disabledReason = Props.disabledReason;
  var disableHoverHighlightOpt = Props.disableHoverHighlight;
  var highlightedOpt = Props.highlighted;
  var disableHoverHighlight = disableHoverHighlightOpt !== undefined ? disableHoverHighlightOpt : false;
  var highlighted = highlightedOpt !== undefined ? highlightedOpt : false;
  var divRef = React.useRef(null);
  var disabled = Belt_Option.isSome(disabledReason);
  var tmp = {
    dataCy: "MenuOption::" + label,
    className: Cn.make({
          hd: "flex items-center border-b border-mono-600 w-full",
          tl: {
            hd: "px-2 py-1 last:border-b-0",
            tl: {
              hd: "text-mono-50 first:rounded-t last:rounded-b",
              tl: {
                hd: Cn.ifTrue("cursor-pointer", !disabled),
                tl: {
                  hd: Cn.ifTrue("opacity-40 cursor-not-allowed", disabled),
                  tl: {
                    hd: Cn.ifTrue("hover:bg-mono-700", !disableHoverHighlight),
                    tl: {
                      hd: Cn.ifTrue("bg-mono-700", highlighted),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }),
    disabled: disabled,
    children: null
  };
  var tmp$1 = OptionUtils$DraftbitBuilder.someIf(!disabled, onClick);
  if (tmp$1 !== undefined) {
    tmp.onClick = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = OptionUtils$DraftbitBuilder.noneIf(title, (function (param) {
          return description !== undefined;
        }));
  if (tmp$2 !== undefined) {
    tmp.title = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = {
    children: React.createElement(DataCyButton$DraftbitBuilder.make, tmp, ReactUtils$DraftbitBuilder.maybeRender(icon, (function (icon) {
                return React.createElement(ReactFontawesome.FontAwesomeIcon, {
                            icon: icon,
                            size: "xs"
                          });
              })), React.createElement(ReactMarkdown$DraftbitBuilder.make, {
              source: label,
              className: "font-sans text-sm truncate ml-2"
            })),
    size: /* Medium */2,
    overlayDataCy: "MenuOptionOverlay::" + label
  };
  var tmp$4 = Belt_Option.map(OptionUtils$DraftbitBuilder.or_(disabledReason, description), (function (text) {
          return React.createElement(Text$DraftbitBuilder.make, {
                      color: "Mono50",
                      children: text
                    });
        }));
  if (tmp$4 !== undefined) {
    tmp$3.content = Caml_option.valFromOption(tmp$4);
  }
  return React.createElement("div", {
              ref: divRef
            }, React.createElement(HoverTooltip$DraftbitBuilder.make, tmp$3));
}

var MenuOption = {
  make: ContextMenu$MenuOption
};

function makeOption(label, icon, description, title, disabledReason, groupLabel, highlightedOpt, action) {
  var highlighted = highlightedOpt !== undefined ? highlightedOpt : false;
  return {
          groupLabel: groupLabel,
          label: label,
          icon: icon,
          action: action,
          description: description,
          title: title,
          disabledReason: disabledReason,
          highlighted: highlighted
        };
}

function ContextMenu(Props) {
  var menuStateOpt = Props.menuState;
  var searchBarOpt = Props.searchBar;
  var renderScreenOpt = Props.renderScreen;
  var setMenuStateOpt = Props.setMenuState;
  var onClose = Props.onClose;
  var options = Props.options;
  var sortGroups = Props.sortGroups;
  var stopPropagationOpt = Props.stopPropagation;
  var maxHeightClass = Props.maxHeightClass;
  var menuState = menuStateOpt !== undefined ? menuStateOpt : /* Menu */0;
  var searchBar = searchBarOpt !== undefined ? searchBarOpt : false;
  var renderScreen = renderScreenOpt !== undefined ? renderScreenOpt : (function (param, param$1) {
        return null;
      });
  var setMenuState = setMenuStateOpt !== undefined ? setMenuStateOpt : (function (prim) {
        
      });
  var stopPropagation = stopPropagationOpt !== undefined ? stopPropagationOpt : true;
  var options$1 = Belt_List.toArray(options);
  var match = React.useState(function () {
        
      });
  var setSearchText = match[1];
  var searchText = match[0];
  var handleAction = function (x) {
    if (typeof x === "number") {
      return Curry._1(onClose, undefined);
    }
    switch (x.TAG | 0) {
      case /* SetMenuState */0 :
          return Curry._1(setMenuState, Curry._1(x._0, undefined));
      case /* CloseAfter */1 :
          Curry._1(x._0, undefined);
          return Curry._1(onClose, undefined);
      case /* SetMenuStateAsync */2 :
          Curry._1(setMenuState, {
                TAG: /* Loading */1,
                _0: x._0,
                _1: x._1
              });
          PromiseUtils$DraftbitBuilder.map(Curry._1(x._2, undefined), setMenuState);
          return ;
      case /* CloseAfterAsync */3 :
          Curry._1(setMenuState, {
                TAG: /* Loading */1,
                _0: x._0,
                _1: x._1
              });
          PromiseUtils$DraftbitBuilder.map(Curry._1(x._2, undefined), onClose);
          return ;
      
    }
  };
  var search = Belt_Option.map(searchText, (function (prim) {
          return prim.toLowerCase();
        }));
  var filteredOptions = search !== undefined ? options$1.filter(function (o) {
          return [
                    o.label,
                    o.description,
                    o.title,
                    o.groupLabel
                  ].some(function (s) {
                      return StringUtils$DraftbitBuilder.emptyIfNone(s).toLowerCase().includes(search);
                    });
        }) : options$1;
  var optionsWrapper = function (e) {
    if (maxHeightClass !== undefined) {
      return React.createElement(ScrollContainer$DraftbitBuilder.make, {
                  className: maxHeightClass,
                  children: e
                });
    } else {
      return e;
    }
  };
  var groups = JsMap.entriesArray(JsMap.groupBy(filteredOptions.map(function (opt, i) {
                return [
                        opt,
                        i
                      ];
              }), (function (param) {
              return param[0].groupLabel;
            })));
  var groups$1 = sortGroups !== undefined ? ArrayUtils$DraftbitBuilder.sortBy(groups, sortGroups) : groups;
  var renderOptions = function (options) {
    return ReactUtils$DraftbitBuilder.mapArray(options, undefined, (function (_key, param) {
                  var opt = param[0];
                  var action = opt.action;
                  return React.createElement(ContextMenu$MenuOption, {
                              label: opt.label,
                              icon: opt.icon,
                              onClick: (function ($$event) {
                                  if (stopPropagation) {
                                    $$event.stopPropagation();
                                  }
                                  return handleAction(action);
                                }),
                              description: opt.description,
                              title: opt.title,
                              disabledReason: opt.disabledReason,
                              highlighted: opt.highlighted,
                              key: param[1].toString()
                            });
                }));
  };
  var onKeyDown = function (e) {
    var match = e.key;
    if (match === "Escape") {
      return Curry._1(onClose, undefined);
    }
    
  };
  if (typeof menuState === "number") {
    return React.createElement("div", {
                className: "-p-2",
                onKeyDown: onKeyDown
              }, ReactUtils$DraftbitBuilder.ifTrueRender(searchBar, React.createElement("div", {
                        className: "w-full flex items-center text-mono-50 p-1"
                      }, React.createElement(ReactFontawesome.FontAwesomeIcon, {
                            icon: ProSolidSvgIcons.faSearch,
                            className: "mr-1"
                          }), React.createElement(WL_TextInput$DraftbitBuilder.make, {
                            value: StringUtils$DraftbitBuilder.emptyIfNone(searchText),
                            placeholder: "Filter...",
                            onChange: {
                              NAME: "Controlled",
                              VAL: (function (v) {
                                  return Curry._1(setSearchText, (function (param) {
                                                return StringUtils$DraftbitBuilder.noneIfEmpty(v);
                                              }));
                                })
                            },
                            autoFocus: true
                          }))), optionsWrapper(ReactUtils$DraftbitBuilder.mapArray(groups$1, undefined, (function (param, param$1) {
                          var optLabel = param$1[0];
                          return React.createElement(React.Fragment, {
                                      children: null,
                                      key: Belt_Option.getWithDefault(optLabel, "NoLabel")
                                    }, ReactUtils$DraftbitBuilder.maybeRender(optLabel, (function (label) {
                                            return React.createElement("div", {
                                                        className: "mt-1"
                                                      }, React.createElement(Text$DraftbitBuilder.make, {
                                                            color: "Mono400",
                                                            children: label.toUpperCase(),
                                                            kind: "B3",
                                                            userSelect: "None"
                                                          }));
                                          })), renderOptions(param$1[1]));
                        }))));
  }
  if (menuState.TAG === /* Screen */0) {
    return Curry._2(renderScreen, handleAction, menuState._0);
  }
  var tmp = {
    size: Belt_Option.getWithDefault(menuState._1, 20),
    inline: true
  };
  if (menuState._0 !== undefined) {
    tmp.label = Caml_option.valFromOption(menuState._0);
  }
  return React.createElement(LoadingSpinner$DraftbitBuilder.make, tmp);
}

var make = ContextMenu;

export {
  setScreenWith ,
  setScreenAsync ,
  closeAfterAsync ,
  setScreen ,
  MenuOption ,
  makeOption ,
  make ,
  
}
/* react Not a pure module */
