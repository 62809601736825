// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as ReactRouterDom from "react-router-dom";
import * as ReactHooks from "@apollo/react-hooks";
import * as LocalStorage from "@rehooks/local-storage";
import * as Notify$DraftbitBuilder from "./waterloo/components/Notify/Notify.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as EnvUtils$DraftbitBuilder from "./utilities/EnvUtils.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "./utilities/OptionUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./utilities/PromiseUtils.bs.js";

var ppx_printed_query = "mutation RefreshToken($input: RefreshTokenInput)  {\n__typename\nrefreshToken(input: $input)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "refreshToken");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field refreshToken on type Mutation is missing");
  }
  return {
          refreshToken: tmp
        };
}

function json_of_RefreshTokenInput(value) {
  return Js_dict.fromArray([[
                  "appUuid",
                  value.appUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(input, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "input",
                    input !== undefined ? json_of_RefreshTokenInput(Caml_option.valFromOption(input)) : null
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "input",
                    input !== undefined ? json_of_RefreshTokenInput(Caml_option.valFromOption(input)) : null
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  input !== undefined ? json_of_RefreshTokenInput(Caml_option.valFromOption(input)) : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      input !== undefined ? json_of_RefreshTokenInput(Caml_option.valFromOption(input)) : null
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

function useMutation(appUuid, param) {
  var match = appUuid !== undefined ? ApolloHooks$DraftbitBuilder.useMutation(undefined, Caml_option.some(makeVariables({
                  appUuid: appUuid
                }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined, definition) : ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition);
  var mutation = match[0];
  return function (onSuccess, onError) {
    return PromiseUtils$DraftbitBuilder.ignore(PromiseUtils$DraftbitBuilder.map(PromiseUtils$DraftbitBuilder.catchMap(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                              return prim[0];
                            })), (function (e) {
                          return {
                                  TAG: /* Errors */1,
                                  _0: ArrayUtils$DraftbitBuilder.append(OptionUtils$DraftbitBuilder.getArray(undefined, Caml_option.nullable_to_opt(e.graphQLErrors)), {
                                        message: e.message,
                                        name: null,
                                        extensions: JsonUtils$DraftbitBuilder.emptyObject(undefined),
                                        locations: null,
                                        path: null,
                                        nodes: null
                                      })
                                };
                        })), (function (result) {
                      if (typeof result === "number") {
                        return ;
                      }
                      if (result.TAG !== /* Data */0) {
                        return Curry._1(onError, result._0);
                      }
                      var data = result._0;
                      localStorage.setItem("token", data.refreshToken);
                      return Curry._1(onSuccess, data.refreshToken);
                    })));
  };
}

var RefreshToken = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_RefreshTokenInput: json_of_RefreshTokenInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret,
  useMutation: useMutation
};

function stringifyUserData(user) {
  return JSON.stringify(Json_encode.object_({
                  hd: [
                    "uuid",
                    user.uuid
                  ],
                  tl: {
                    hd: [
                      "email",
                      user.email
                    ],
                    tl: {
                      hd: [
                        "firstName",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), user.firstName)
                      ],
                      tl: {
                        hd: [
                          "lastName",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), user.lastName)
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }));
}

var ppx_printed_query$1 = "mutation LoginUser($input: LoginUserInput!)  {\n__typename\nloginUser(input: $input)  {\n__typename\ntoken  \nuser  {\n__typename\nuuid  \nemail  \nfirstName  \nlastName  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "loginUser");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "token");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field token on type LoginUserPayload is missing");
    }
    var value$7 = Js_dict.get(value$3, "user");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$7)));
      var value$9 = Js_dict.get(value$8, "uuid");
      var tmp$3;
      if (value$9 !== undefined) {
        var value$10 = Caml_option.valFromOption(value$9);
        var value$11 = Js_json.decodeString(value$10);
        tmp$3 = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
      }
      var value$12 = Js_dict.get(value$8, "email");
      var tmp$4;
      if (value$12 !== undefined) {
        var value$13 = Caml_option.valFromOption(value$12);
        var value$14 = Js_json.decodeString(value$13);
        tmp$4 = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field email on type User is missing");
      }
      var value$15 = Js_dict.get(value$8, "firstName");
      var tmp$5;
      if (value$15 !== undefined) {
        var value$16 = Caml_option.valFromOption(value$15);
        var match = Js_json.decodeNull(value$16);
        if (match !== undefined) {
          tmp$5 = undefined;
        } else {
          var value$17 = Js_json.decodeString(value$16);
          tmp$5 = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
        }
      } else {
        tmp$5 = undefined;
      }
      var value$18 = Js_dict.get(value$8, "lastName");
      var tmp$6;
      if (value$18 !== undefined) {
        var value$19 = Caml_option.valFromOption(value$18);
        var match$1 = Js_json.decodeNull(value$19);
        if (match$1 !== undefined) {
          tmp$6 = undefined;
        } else {
          var value$20 = Js_json.decodeString(value$19);
          tmp$6 = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
        }
      } else {
        tmp$6 = undefined;
      }
      tmp$2 = {
        uuid: tmp$3,
        email: tmp$4,
        firstName: tmp$5,
        lastName: tmp$6
      };
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field user on type LoginUserPayload is missing");
    }
    tmp = {
      token: tmp$1,
      user: tmp$2
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field loginUser on type Mutation is missing");
  }
  return {
          loginUser: tmp
        };
}

function json_of_LoginUserInput(value) {
  return Js_dict.fromArray([
                [
                  "email",
                  value.email
                ],
                [
                  "password",
                  value.password
                ],
                [
                  "turnstileToken",
                  value.turnstileToken
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(input, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_LoginUserInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_LoginUserInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_LoginUserInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_LoginUserInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function useMutation$1(param) {
  var apolloClient = ReactHooks.useApolloClient();
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$1);
  var mutation = match[0];
  return function (email, password, turnstileToken, onData, onError) {
    var variables = makeVariables$1({
          email: email,
          password: password,
          turnstileToken: turnstileToken
        }, undefined);
    return PromiseUtils$DraftbitBuilder.ignore(ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(variables), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                          return prim[0];
                        })), undefined, undefined, undefined, undefined, undefined, (function (data) {
                      localStorage.clear();
                      localStorage.setItem("token", data.loginUser.token);
                      localStorage.setItem("user", stringifyUserData(data.loginUser.user));
                      Promise.resolve(apolloClient.resetStore());
                      return Curry._1(onData, data);
                    }), (function (error) {
                      return Curry._1(onError, Belt_Option.getWithDefault(error, "Something went wrong. Please try again."));
                    }), undefined, "Logged In", undefined, undefined, undefined));
  };
}

var Login = {
  stringifyUserData: stringifyUserData,
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_LoginUserInput: json_of_LoginUserInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  useMutation: useMutation$1
};

var ppx_printed_query$2 = "mutation RequestNewPassword($input: ResetPasswordInput!)  {\n__typename\nsendResetPasswordEmail(input: $input)  \n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "sendResetPasswordEmail");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeBoolean(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field sendResetPasswordEmail on type Mutation is missing");
  }
  return {
          sendResetPasswordEmail: tmp
        };
}

function json_of_ResetPasswordInput(value) {
  return Js_dict.fromArray([
                [
                  "email",
                  value.email
                ],
                [
                  "turnstileToken",
                  value.turnstileToken
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$2(input, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_ResetPasswordInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_ResetPasswordInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_ResetPasswordInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_ResetPasswordInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

function useMutation$2(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$2);
  var mutation = match[0];
  return function (email, turnstileToken, onData, onError) {
    var variables = makeVariables$2({
          email: email,
          turnstileToken: turnstileToken
        }, undefined);
    return PromiseUtils$DraftbitBuilder.ignore(ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(variables), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                          return prim[0];
                        })), undefined, undefined, undefined, undefined, undefined, onData, onError, undefined, "Requested New Password", undefined, undefined, undefined));
  };
}

var RequestNewPassword = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  json_of_ResetPasswordInput: json_of_ResetPasswordInput,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2,
  useMutation: useMutation$2
};

var ppx_printed_query$3 = "mutation ResetPassword($input: ResetUserPasswordInput!)  {\n__typename\nresetUserPassword(input: $input)  {\n__typename\nuuid  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "resetUserPassword");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type User is missing");
    }
    tmp = {
      uuid: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field resetUserPassword on type Mutation is missing");
  }
  return {
          resetUserPassword: tmp
        };
}

function json_of_ResetUserPasswordInput(value) {
  return Js_dict.fromArray([
                [
                  "password",
                  value.password
                ],
                [
                  "token",
                  value.token
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$3(input, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_ResetUserPasswordInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_ResetUserPasswordInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$3(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_ResetUserPasswordInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_ResetUserPasswordInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function useMutation$3(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$3);
  var mutation = match[0];
  return function (token, password, onData, onError) {
    var variables = makeVariables$3({
          password: password,
          token: token
        }, undefined);
    return PromiseUtils$DraftbitBuilder.ignore(ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(variables), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                          return prim[0];
                        })), undefined, undefined, undefined, undefined, undefined, Curry.__1(onData), onError, undefined, "Updated Password", undefined, undefined, undefined));
  };
}

var UpdatePassword = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  json_of_ResetUserPasswordInput: json_of_ResetUserPasswordInput,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  useMutation: useMutation$3
};

var GQL = {
  RefreshToken: RefreshToken,
  Login: Login,
  RequestNewPassword: RequestNewPassword,
  UpdatePassword: UpdatePassword
};

function useSession(appUuid, param) {
  var history = ReactRouterDom.useHistory();
  var match = LocalStorage.useLocalStorage("token", "");
  var setToken = match[1];
  var token = Belt_Option.flatMap(Js_json.decodeString(match[0]), StringUtils$DraftbitBuilder.noneIfEmpty);
  var refreshToken = useMutation(appUuid, undefined);
  React.useEffect((function () {
          var refresh_ = function (attempt) {
            return Curry._2(refreshToken, Curry.__1(setToken), (function (errors) {
                          errors.forEach(function (e) {
                                return Notify$DraftbitBuilder.failure(undefined, e.message);
                              });
                          if (errors.length === 1) {
                            var match = errors[0];
                            if (match.message === "Network error: Failed to fetch" && EnvUtils$DraftbitBuilder.env === "local") {
                              console.warn("No response from server. Will retry after a few seconds");
                              return PromiseUtils$DraftbitBuilder.tapIgnore(PromiseUtils$DraftbitBuilder.sleep(2000), (function (param) {
                                            var attempt$1 = attempt + 1 | 0;
                                            console.warn("Retrying refreshToken (attempt " + attempt$1.toString() + ")");
                                            return refresh_(attempt$1);
                                          }));
                            }
                            
                          }
                          console.warn("Session error(s):", StringUtils$DraftbitBuilder.toJsonOrString(errors));
                          return Router$DraftbitBuilder.NavigateTo.dashboard(history);
                        }));
          };
          if (token === undefined) {
            refresh_(1);
          }
          
        }), [appUuid]);
  return token;
}

export {
  GQL ,
  useSession ,
  
}
/* react Not a pure module */
