/* TypeScript file generated from ReactMarkdown.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const ReactMarkdownBS = require('./ReactMarkdown.bs');

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly onClickLink?: () => void; 
  readonly source: string
};

export const make: React.ComponentType<{
  readonly className?: string; 
  readonly onClickLink?: () => void; 
  readonly source: string
}> = ReactMarkdownBS.make;
