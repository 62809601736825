// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Notify$DraftbitBuilder from "../waterloo/components/Notify/Notify.bs.js";
import * as Sentry$DraftbitBuilder from "./Sentry.bs.js";
import * as Analytics$DraftbitBuilder from "./Analytics.bs.js";
import * as ReactUtils$DraftbitBuilder from "./ReactUtils.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./PromiseUtils.bs.js";
import * as LoadingSpinner$DraftbitBuilder from "../components/LoadingSpinner.bs.js";
import * as ErrorBoundaryInternal$DraftbitBuilder from "../components/ErrorBoundaryInternal.bs.js";

function extractErrorMessage(err) {
  return Belt_Array.get(Json_decode.oneOf({
                  hd: (function (j) {
                      return Json_decode.array((function (param) {
                                      return Json_decode.field("errors", (function (param) {
                                                    return Json_decode.array((function (param) {
                                                                  return Json_decode.field("message", Json_decode.string, param);
                                                                }), param);
                                                  }), param);
                                    }), j).flat();
                    }),
                  tl: {
                    hd: (function (param) {
                        return Json_decode.field("graphQLErrors", (function (param) {
                                      return Json_decode.array((function (param) {
                                                    return Json_decode.field("message", Json_decode.string, param);
                                                  }), param);
                                    }), param);
                      }),
                    tl: {
                      hd: (function (j) {
                          return [Json_decode.field("message", Json_decode.string, j)];
                        }),
                      tl: /* [] */0
                    }
                  }
                }, err), 0);
}

var _map = {"APP_NOT_FOUND":"APP_NOT_FOUND","BAD_USER_INPUT":"BAD_USER_INPUT","FORBIDDEN":"FORBIDDEN","INVALID_LOGIN":"INVALID_LOGIN","PERMISSIONS_ERROR":"PERMISSIONS_ERROR","TOKEN_EXPIRED":"TOKEN_EXPIRED","UNAUTHENTICATED":"UNAUTHENTICATED"};

function errorCodeToJs(param) {
  return param;
}

function errorCodeFromJs(param) {
  return _map[param];
}

function errorIsOfType(err, type_) {
  var errorCode = Belt_Array.get(Json_decode.field("graphQLErrors", (function (param) {
              return Json_decode.array((function (param) {
                            return Json_decode.field("extensions", (function (param) {
                                          return Json_decode.field("code", Json_decode.string, param);
                                        }), param);
                          }), param);
            }), err), 0);
  return errorCode === type_;
}

function data(v) {
  if (typeof v === "number" || v.TAG !== /* Data */0) {
    return ;
  } else {
    return Caml_option.some(v._0);
  }
}

function renderData(query, onData) {
  return ReactUtils$DraftbitBuilder.maybeRender(data(query), onData);
}

function dataMap(query, f) {
  return Belt_Option.map(data(query), f);
}

function dataFlatMap(query, f) {
  return Belt_Option.flatMap(data(query), f);
}

function getWithDefault(v, def) {
  return Belt_Option.getWithDefault(data(v), def);
}

function mapWithDefault(v, def, f) {
  return Belt_Option.mapWithDefault(data(v), def, f);
}

function map(v, f) {
  if (typeof v === "number") {
    if (v === /* Loading */0) {
      return /* Loading */0;
    } else {
      return /* NoData */1;
    }
  } else if (v.TAG === /* Data */0) {
    return {
            TAG: /* Data */0,
            _0: Curry._1(f, v._0)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: v._0
          };
  }
}

function tapError(v, f) {
  if (typeof v === "number") {
    return v;
  }
  if (v.TAG !== /* Error */1) {
    return v;
  }
  var e = v._0;
  Curry._1(f, e);
  return {
          TAG: /* Error */1,
          _0: e
        };
}

function mapCacheAndNetwork(v, f) {
  var data = v.data;
  var e = v.error;
  if (e !== undefined) {
    return {
            TAG: /* Error */1,
            _0: e
          };
  } else if (data !== undefined) {
    return {
            TAG: /* Data */0,
            _0: Curry._1(f, Caml_option.valFromOption(data))
          };
  } else if (v.loading) {
    return /* Loading */0;
  } else {
    return /* NoData */1;
  }
}

function apply(vFunc, v) {
  if (typeof vFunc === "number") {
    vFunc === /* Loading */0;
  } else {
    if (vFunc.TAG !== /* Data */0) {
      return {
              TAG: /* Error */1,
              _0: vFunc._0
            };
    }
    if (typeof v === "number") {
      return /* Loading */0;
    }
    if (v.TAG === /* Data */0) {
      return {
              TAG: /* Data */0,
              _0: Curry._1(vFunc._0, v._0)
            };
    }
    
  }
  if (typeof v === "number" || v.TAG !== /* Error */1) {
    return /* Loading */0;
  } else {
    return {
            TAG: /* Error */1,
            _0: v._0
          };
  }
}

function combine2(r1, r2) {
  return apply(map(r1, (function (data1, data2) {
                    return [
                            data1,
                            data2
                          ];
                  })), r2);
}

function combine3(r1, r2, r3) {
  return apply(apply(map(r1, (function (data1, data2, data3) {
                        return [
                                data1,
                                data2,
                                data3
                              ];
                      })), r2), r3);
}

function combine4(r1, r2, r3, r4) {
  return apply(apply(apply(map(r1, (function (data1, data2, data3, data4) {
                            return [
                                    data1,
                                    data2,
                                    data3,
                                    data4
                                  ];
                          })), r2), r3), r4);
}

function combine5(r1, r2, r3, r4, r5) {
  return apply(apply(apply(apply(map(r1, (function (data1, data2, data3, data4, data5) {
                                return [
                                        data1,
                                        data2,
                                        data3,
                                        data4,
                                        data5
                                      ];
                              })), r2), r3), r4), r5);
}

function combine6(r1, r2, r3, r4, r5, r6) {
  return apply(apply(apply(apply(apply(map(r1, (function (data1, data2, data3, data4, data5, data6) {
                                    return [
                                            data1,
                                            data2,
                                            data3,
                                            data4,
                                            data5,
                                            data6
                                          ];
                                  })), r2), r3), r4), r5), r6);
}

function combine7(r1, r2, r3, r4, r5, r6, r7) {
  return apply(apply(apply(apply(apply(apply(map(r1, (function (d1, d2, d3, d4, d5, d6, d7) {
                                        return [
                                                d1,
                                                d2,
                                                d3,
                                                d4,
                                                d5,
                                                d6,
                                                d7
                                              ];
                                      })), r2), r3), r4), r5), r6), r7);
}

function combine8(r1, r2, r3, r4, r5, r6, r7, r8) {
  return apply(apply(apply(apply(apply(apply(apply(map(r1, (function (d1, d2, d3, d4, d5, d6, d7, d8) {
                                            return [
                                                    d1,
                                                    d2,
                                                    d3,
                                                    d4,
                                                    d5,
                                                    d6,
                                                    d7,
                                                    d8
                                                  ];
                                          })), r2), r3), r4), r5), r6), r7), r8);
}

function combine9(r1, r2, r3, r4, r5, r6, r7, r8, r9) {
  return apply(apply(apply(apply(apply(apply(apply(apply(map(r1, (function (d1, d2, d3, d4, d5, d6, d7, d8, d9) {
                                                return [
                                                        d1,
                                                        d2,
                                                        d3,
                                                        d4,
                                                        d5,
                                                        d6,
                                                        d7,
                                                        d8,
                                                        d9
                                                      ];
                                              })), r2), r3), r4), r5), r6), r7), r8), r9);
}

function convertQueryResultToVariant(queryResult) {
  var data = queryResult.data;
  var error = queryResult.error;
  if (error !== undefined) {
    return {
            TAG: /* Error */1,
            _0: error
          };
  } else if (data !== undefined) {
    return {
            TAG: /* Data */0,
            _0: Caml_option.valFromOption(data)
          };
  } else if (queryResult.loading) {
    return /* Loading */0;
  } else {
    return /* NoData */1;
  }
}

function toOptionResult(v, onNoDataOpt, param) {
  var onNoData = onNoDataOpt !== undefined ? onNoDataOpt : (function (param) {
        
      });
  if (typeof v === "number") {
    if (v === /* Loading */0) {
      return ;
    } else {
      return Curry._1(onNoData, undefined);
    }
  } else if (v.TAG === /* Data */0) {
    return {
            TAG: /* Ok */0,
            _0: v._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: v._0
          };
  }
}

var Query = {
  data: data,
  renderData: renderData,
  dataMap: dataMap,
  dataFlatMap: dataFlatMap,
  getWithDefault: getWithDefault,
  mapWithDefault: mapWithDefault,
  map: map,
  tapError: tapError,
  mapCacheAndNetwork: mapCacheAndNetwork,
  apply: apply,
  combine2: combine2,
  combine3: combine3,
  combine4: combine4,
  combine5: combine5,
  combine6: combine6,
  combine7: combine7,
  combine8: combine8,
  combine9: combine9,
  convertQueryResultToVariant: convertQueryResultToVariant,
  toOptionResult: toOptionResult
};

function data$1(v) {
  if (typeof v === "number" || v.TAG !== /* Data */0) {
    return ;
  } else {
    return Caml_option.some(v._0);
  }
}

function map$1(v, f) {
  if (typeof v === "number") {
    return /* NoData */0;
  } else if (v.TAG === /* Data */0) {
    return {
            TAG: /* Data */0,
            _0: Curry._1(f, v._0)
          };
  } else {
    return {
            TAG: /* Errors */1,
            _0: v._0
          };
  }
}

function apply$1(vFunc, v) {
  if (typeof vFunc !== "number") {
    if (vFunc.TAG !== /* Data */0) {
      return {
              TAG: /* Errors */1,
              _0: vFunc._0
            };
    }
    if (typeof v === "number") {
      return /* NoData */0;
    }
    if (v.TAG === /* Data */0) {
      return {
              TAG: /* Data */0,
              _0: Curry._1(vFunc._0, v._0)
            };
    }
    
  }
  if (typeof v === "number" || v.TAG === /* Data */0) {
    return /* NoData */0;
  } else {
    return {
            TAG: /* Errors */1,
            _0: v._0
          };
  }
}

function handlePromise(promise, onNoDataOpt, onData, onError, param) {
  var onNoData = onNoDataOpt !== undefined ? onNoDataOpt : (function (param) {
        
      });
  return PromiseUtils$DraftbitBuilder.catchMap(PromiseUtils$DraftbitBuilder.map(promise, (function (x) {
                    if (typeof x === "number") {
                      return Curry._1(onNoData, undefined);
                    }
                    if (x.TAG === /* Data */0) {
                      return Curry._1(onData, x._0);
                    }
                    var errors = x._0;
                    Sentry$DraftbitBuilder.captureMessageExtra("mutation error", {
                          errors: errors
                        });
                    return Curry._1(onError, extractErrorMessage(errors));
                  })), (function (errors) {
                return Curry._1(onError, extractErrorMessage(errors));
              }));
}

function trackResult(promise, notificationSuccessMessage, notificationSuccessDuration, notificationSuccessDataCy, formatNotificationMessage, propertiesOpt, onDataOpt, onErrorOpt, errorMessage, analyticsMessage, failureOpt, successOpt, param) {
  var properties = propertiesOpt !== undefined ? Caml_option.valFromOption(propertiesOpt) : ({});
  var onData = onDataOpt !== undefined ? onDataOpt : (function (param) {
        
      });
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var failure = failureOpt !== undefined ? failureOpt : Notify$DraftbitBuilder.failure;
  var success = successOpt !== undefined ? successOpt : (function (msg) {
        return Notify$DraftbitBuilder.successWithProps(msg, notificationSuccessDataCy, notificationSuccessDuration);
      });
  return handlePromise(promise, undefined, (function (data) {
                Belt_Option.forEach(analyticsMessage, (function (m) {
                        return Analytics$DraftbitBuilder.trackWithProperties(m, properties);
                      }));
                if (notificationSuccessMessage !== undefined) {
                  Curry._1(success, notificationSuccessMessage);
                } else if (formatNotificationMessage !== undefined) {
                  Curry._1(success, Curry._1(formatNotificationMessage, data));
                }
                return Curry._1(onData, data);
              }), (function (apolloErrorMessage) {
                Belt_Option.forEach(analyticsMessage, (function (m) {
                        return Analytics$DraftbitBuilder.trackWithProperties(m, properties);
                      }));
                if (errorMessage !== undefined) {
                  Curry._2(failure, undefined, errorMessage);
                } else if (apolloErrorMessage !== undefined) {
                  Curry._2(failure, undefined, apolloErrorMessage);
                }
                return Curry._1(onError, apolloErrorMessage);
              }), undefined);
}

function track(promise, getData, notificationSuccessMessage, notificationSuccessDuration, notificationSuccessDataCy, formatNotificationMessage, analyticsProperties, errorMessage, analyticsMessage, failure, success, param) {
  return new Promise((function (resolve, param) {
                return PromiseUtils$DraftbitBuilder.ignore(trackResult(PromiseUtils$DraftbitBuilder.map(promise, (function (prim) {
                                      return prim[0];
                                    })), notificationSuccessMessage, notificationSuccessDuration, notificationSuccessDataCy, formatNotificationMessage, analyticsProperties, (function (data) {
                                  return resolve({
                                              TAG: /* Ok */0,
                                              _0: Curry._1(getData, data)
                                            });
                                }), (function (err) {
                                  return resolve({
                                              TAG: /* Error */1,
                                              _0: err
                                            });
                                }), errorMessage, analyticsMessage, failure, success, undefined));
              }));
}

var Mutation = {
  data: data$1,
  map: map$1,
  apply: apply$1,
  handlePromise: handlePromise,
  trackResult: trackResult,
  track: track
};

function ApolloUtils$RenderQuery(Props) {
  var query = Props.query;
  var loadingLabel = Props.loadingLabel;
  var loadingInlineOpt = Props.loadingInline;
  var loadingSize = Props.loadingSize;
  var loadingFullHeightOpt = Props.loadingFullHeight;
  var loadingComponentOpt = Props.loadingComponent;
  var noDataComponentOpt = Props.noDataComponent;
  var errorMessage = Props.errorMessage;
  var errorComponentOpt = Props.errorComponent;
  var onErrorOpt = Props.onError;
  var children = Props.children;
  var loadingInline = loadingInlineOpt !== undefined ? loadingInlineOpt : true;
  var loadingFullHeight = loadingFullHeightOpt !== undefined ? loadingFullHeightOpt : true;
  var loadingComponent;
  if (loadingComponentOpt !== undefined) {
    loadingComponent = Caml_option.valFromOption(loadingComponentOpt);
  } else {
    var tmp = {
      className: Cn.ifTrue("h-full", loadingFullHeight),
      inline: loadingInline
    };
    if (loadingLabel !== undefined) {
      tmp.label = Caml_option.valFromOption(loadingLabel);
    }
    if (loadingSize !== undefined) {
      tmp.size = Caml_option.valFromOption(loadingSize);
    }
    loadingComponent = React.createElement(LoadingSpinner$DraftbitBuilder.make, tmp);
  }
  var noDataComponent = noDataComponentOpt !== undefined ? Caml_option.valFromOption(noDataComponentOpt) : loadingComponent;
  var errorComponent = errorComponentOpt !== undefined ? errorComponentOpt : (function (error) {
        var tmp = {
          error: error
        };
        if (errorMessage !== undefined) {
          tmp.message = Caml_option.valFromOption(errorMessage);
        }
        return React.createElement(ErrorBoundaryInternal$DraftbitBuilder.make, tmp);
      });
  var onError = onErrorOpt !== undefined ? onErrorOpt : Sentry$DraftbitBuilder.captureException;
  if (typeof query === "number") {
    if (query === /* Loading */0) {
      return loadingComponent;
    } else {
      return noDataComponent;
    }
  }
  if (query.TAG === /* Data */0) {
    return Curry._1(children, query._0);
  }
  var error = query._0;
  Curry._1(onError, error);
  return Curry._1(errorComponent, error);
}

var RenderQuery = {
  make: ApolloUtils$RenderQuery
};

export {
  extractErrorMessage ,
  errorCodeToJs ,
  errorCodeFromJs ,
  errorIsOfType ,
  Query ,
  Mutation ,
  RenderQuery ,
  
}
/* react Not a pure module */
