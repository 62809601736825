// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactApollo from "react-apollo";
import * as ReactHooks from "@apollo/react-hooks";

function ReasonApolloHooksProvider(Props) {
  var children = Props.children;
  var client = Props.client;
  return React.createElement(ReactApollo.ApolloProvider, {
              client: client,
              children: React.createElement(ReactHooks.ApolloProvider, {
                    client: client,
                    children: children
                  })
            });
}

var make = ReasonApolloHooksProvider;

export {
  make ,
  
}
/* react Not a pure module */
