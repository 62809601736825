// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Emotion from "@ahrefs/bs-emotion/src/Emotion.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_format from "bs-platform/lib/es6/caml_format.mjs";
import * as LodashIsstring from "lodash.isstring";
import * as LodashIsinteger from "lodash.isinteger";
import * as TailwindConfigJs from "./tailwind.config.js";
import * as StringUtils$DraftbitBuilder from "../utilities/StringUtils.bs.js";

var _map = {"Transparent":"Transparent","CurrentColor":"CurrentColor","Mono50":"Mono50","Mono100":"Mono100","Mono200":"Mono200","Mono300":"Mono300","Mono400":"Mono400","Mono500":"Mono500","Mono600":"Mono600","Mono700":"Mono700","Mono800":"Mono800","Mono900":"Mono900","Mono950":"Mono950","Black":"Black","White":"White","Brand":"Brand","Accent600":"Accent600","Primary100":"Primary100","Primary200":"Primary200","Primary300":"Primary300","Primary400":"Primary400","Primary500":"Primary500","Primary600":"Primary600","Selection100":"Selection100","Selection200":"Selection200","Selection300":"Selection300","Selection400":"Selection400","Selection500":"Selection500","Selection600":"Selection600","Warning100":"Warning100","Warning200":"Warning200","Warning300":"Warning300","Warning400":"Warning400","Warning500":"Warning500","Success100":"Success100","Success200":"Success200","Success300":"Success300","Success400":"Success400","Success500":"Success500","Failure100":"Failure100","Failure200":"Failure200","Failure300":"Failure300","Failure400":"Failure400","Failure500":"Failure500"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function normalize(stringOrColor) {
  if (LodashIsinteger(stringOrColor)) {
    return stringOrColor;
  }
  if (!LodashIsstring(stringOrColor)) {
    return Js_exn.raiseError("Color is not a string or an integer: " + StringUtils$DraftbitBuilder.toJsonOrString(stringOrColor));
  }
  var color = tFromJs(stringOrColor);
  if (color !== undefined) {
    return color;
  } else {
    return Js_exn.raiseError("Invalid color string: " + stringOrColor);
  }
}

function toTuple(color) {
  var colorStr = normalize(color);
  var match = colorStr.match(/([A-Za-z]+)(\d*)/);
  if (match === null) {
    return Js_exn.raiseError("Bad format for color: " + colorStr);
  }
  if (match.length !== 3) {
    return Js_exn.raiseError("Bad format for color: " + colorStr);
  }
  var prefix = match[1];
  if (prefix === "CurrentColor") {
    var match$1 = match[2];
    if (match$1 === "") {
      return [
              "current",
              undefined
            ];
    }
    
  }
  var num = match[2];
  if (num === "") {
    return [
            prefix.toLowerCase(),
            undefined
          ];
  } else {
    return [
            prefix.toLowerCase(),
            Caml_format.caml_int_of_string(num)
          ];
  }
}

function toTailwind(color, classPrefix) {
  var match = toTuple(color);
  var num = match[1];
  var name = match[0];
  return classPrefix + ("-" + (
            num !== undefined ? name + ("-" + String(num)) : name
          ));
}

var tailwindColors = TailwindConfigJs.theme.colors;

function toHexString(color) {
  var match = normalize(color);
  var match$1 = toTuple(color);
  if (match === "Black") {
    return "#000000";
  }
  if (match === "White") {
    return "#FFFFFF";
  }
  if (match === "Transparent") {
    return "transparent";
  }
  var n = match$1[1];
  if (n === undefined) {
    return Js_exn.raiseError("No number associated with color " + match);
  }
  var colorStr = Belt_Option.flatMap(Js_dict.get(tailwindColors, match$1[0]), (function (defs) {
          return Js_dict.get(defs, String(n));
        }));
  if (colorStr !== undefined) {
    return colorStr;
  } else {
    return Js_exn.raiseError("No tailwind entry associated with color " + color);
  }
}

function toHexStringWithoutHash(color) {
  return toHexString(color).replace("#", "");
}

function toEmotion(c) {
  var match = normalize(c);
  if (match === "CurrentColor") {
    return Emotion.currentColor;
  } else if (match === "Transparent") {
    return Emotion.transparent;
  } else {
    return Emotion.hex(toHexStringWithoutHash(c));
  }
}

var transparent = "Transparent";

var currentColor = "CurrentColor";

var mono50 = "Mono50";

var mono100 = "Mono100";

var mono200 = "Mono200";

var mono300 = "Mono300";

var mono400 = "Mono400";

var mono500 = "Mono500";

var mono600 = "Mono600";

var mono700 = "Mono700";

var mono800 = "Mono800";

var mono900 = "Mono900";

var mono950 = "Mono950";

var black = "Black";

var white = "White";

var brand = "Brand";

var accent600 = "Accent600";

var primary100 = "Primary100";

var primary200 = "Primary200";

var primary300 = "Primary300";

var primary400 = "Primary400";

var primary500 = "Primary500";

var primary600 = "Primary600";

var selection100 = "Selection100";

var selection200 = "Selection200";

var selection300 = "Selection300";

var selection400 = "Selection400";

var selection500 = "Selection500";

var selection600 = "Selection600";

var warning100 = "Warning100";

var warning200 = "Warning200";

var warning300 = "Warning300";

var warning400 = "Warning400";

var warning500 = "Warning500";

var success100 = "Success100";

var success200 = "Success200";

var success300 = "Success300";

var success400 = "Success400";

var success500 = "Success500";

var failure100 = "Failure100";

var failure200 = "Failure200";

var failure300 = "Failure300";

var failure400 = "Failure400";

var failure500 = "Failure500";

export {
  tToJs ,
  tFromJs ,
  normalize ,
  toTuple ,
  toTailwind ,
  tailwindColors ,
  toHexString ,
  toHexStringWithoutHash ,
  toEmotion ,
  transparent ,
  currentColor ,
  mono50 ,
  mono100 ,
  mono200 ,
  mono300 ,
  mono400 ,
  mono500 ,
  mono600 ,
  mono700 ,
  mono800 ,
  mono900 ,
  mono950 ,
  black ,
  white ,
  brand ,
  accent600 ,
  primary100 ,
  primary200 ,
  primary300 ,
  primary400 ,
  primary500 ,
  primary600 ,
  selection100 ,
  selection200 ,
  selection300 ,
  selection400 ,
  selection500 ,
  selection600 ,
  warning100 ,
  warning200 ,
  warning300 ,
  warning400 ,
  warning500 ,
  success100 ,
  success200 ,
  success300 ,
  success400 ,
  success500 ,
  failure100 ,
  failure200 ,
  failure300 ,
  failure400 ,
  failure500 ,
  
}
/* tailwindColors Not a pure module */
