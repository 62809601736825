// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";
import * as Expo$DraftbitBuilder from "./Expo.bs.js";
import * as User$DraftbitBuilder from "./User.bs.js";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as Utils$DraftbitBuilder from "./utilities/Utils.bs.js";
import * as Notify$DraftbitBuilder from "./waterloo/components/Notify/Notify.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as Sentry$DraftbitBuilder from "./utilities/Sentry.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as SimpleApp$DraftbitBuilder from "./SimpleApp.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as BillingPlan$DraftbitBuilder from "./BillingPlan.bs.js";
import * as ApolloClient$DraftbitBuilder from "./bindings/ReasonApollo/ApolloClient.bs.js";
import * as Integrations$DraftbitBuilder from "./Integrations.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./utilities/PromiseUtils.bs.js";
import * as SimpleScreen$DraftbitBuilder from "./SimpleScreen.bs.js";
import * as AppJsonConfig$DraftbitBuilder from "./AppJsonConfig.bs.js";
import * as ExpoAccountGQL$DraftbitBuilder from "./ExpoAccountGQL.bs.js";
import * as GlobalVariable$DraftbitBuilder from "./GlobalVariable.bs.js";
import * as SimpleWorkspace$DraftbitBuilder from "./SimpleWorkspace.bs.js";
import * as CustomAppCodeGQL$DraftbitBuilder from "./CustomAppCodeGQL.bs.js";
import * as PublicationSettings$DraftbitBuilder from "./PublicationSettings.bs.js";

function customPackage_encode(v) {
  return Js_dict.fromArray([
              [
                "__typename",
                Decco.stringToJson(v.__typename)
              ],
              [
                "package",
                Decco.stringToJson(v.package)
              ],
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "isResolution",
                Decco.boolToJson(v.isResolution)
              ]
            ]);
}

function customPackage_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var __typename = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "__typename"), null));
  if (__typename.TAG === /* Ok */0) {
    var $$package = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "package"), null));
    if ($$package.TAG === /* Ok */0) {
      var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
      if (version.TAG === /* Ok */0) {
        var isResolution = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isResolution"), null));
        if (isResolution.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    __typename: __typename._0,
                    package: $$package._0,
                    version: version._0,
                    isResolution: isResolution._0
                  }
                };
        }
        var e = isResolution._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".isResolution" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = version._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".version" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = $$package._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".package" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = __typename._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".__typename" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var _map = {"None":"None","Banner":"Banner","FullScreen":"FullScreen"};

function offlineModeTypeToJs(param) {
  return param;
}

function offlineModeTypeFromJs(param) {
  return _map[param];
}

function offlineModeType_encode(v) {
  if (v === "FullScreen") {
    return ["FullScreen"];
  } else if (v === "None") {
    return ["None"];
  } else {
    return ["Banner"];
  }
}

function offlineModeType_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a polyvariant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a polyvariant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Banner" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: "Banner"
                  };
          }
      case "FullScreen" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: "FullScreen"
                  };
          }
      case "None" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: "None"
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid polyvariant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

var _map$1 = {"Draft":"Draft","Live":"Live"};

function stateToJs(param) {
  return param;
}

function stateFromJs(param) {
  return _map$1[param];
}

function state_encode(v) {
  if (v === "Live") {
    return ["Live"];
  } else {
    return ["Draft"];
  }
}

function state_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a polyvariant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a polyvariant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Draft" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: "Draft"
                  };
          }
      case "Live" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: "Live"
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid polyvariant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function __typename(param) {
  return param.__typename;
}

function uuid(param) {
  return param.uuid;
}

function name(param) {
  return param.name;
}

function version(param) {
  return param.version;
}

function updatedAt(param) {
  return param.updatedAt;
}

function createdAt(param) {
  return param.createdAt;
}

function iconUrl(param) {
  return param.iconUrl;
}

function numScreens(param) {
  return param.numScreens;
}

function readOnly(param) {
  return param.readOnly;
}

function showPwaWrapper(param) {
  return param.showPwaWrapper;
}

function config(param) {
  return param.config;
}

function buildConfig(param) {
  return param.buildConfig;
}

function googleServiceConfig(param) {
  return param.googleServiceConfig;
}

function appJsonOverride(param) {
  return param.appJsonOverride;
}

function linkingConfiguration(param) {
  return param.linkingConfiguration;
}

function githubToken(param) {
  return param.githubToken;
}

function githubOwner(param) {
  return param.githubOwner;
}

function githubRepo(param) {
  return param.githubRepo;
}

function githubBranch(param) {
  return param.githubBranch;
}

function segmentKey(param) {
  return param.segmentKey;
}

function sentryData(param) {
  return param.sentryData;
}

function revenueCatData(param) {
  return param.revenueCatData;
}

function disabledIntegrations(param) {
  return param.disabledIntegrations;
}

function expoVersion(param) {
  return param.expoVersion;
}

function useExpoGo(param) {
  return param.useExpoGo;
}

function notificationsConfig(param) {
  return param.notificationsConfig;
}

function platformBuildType(param) {
  return param.platformBuildType;
}

function lastCommitAt(param) {
  return param.lastCommitAt;
}

function customPackages(param) {
  return param.customPackages;
}

function globalVariables(param) {
  return param.globalVariables;
}

function screens(param) {
  return param.screens;
}

function awsAmplifyConfig(param) {
  return param.awsAmplifyConfig;
}

function offlineModeType(param) {
  return param.offlineModeType;
}

function offlineModeMessage(param) {
  return param.offlineModeMessage;
}

function state(param) {
  return param.state;
}

function workspace(param) {
  return param.workspace;
}

function coverImageUrl(param) {
  return param.coverImageUrl;
}

function expoAccount(param) {
  return param.expoAccount;
}

function customAppCode(param) {
  return param.customAppCode;
}

function openAiApiKey(param) {
  return param.openAiApiKey;
}

function numberOfRemainingAiImageGeneration(param) {
  return param.numberOfRemainingAiImageGeneration;
}

function publishedSnackId(param) {
  return param.publishedSnackId;
}

function publishedExpoVersion(param) {
  return param.publishedExpoVersion;
}

function lastPublishDate(param) {
  return param.lastPublishDate;
}

function description(param) {
  return param.description;
}

function backupDate(param) {
  return param.backupDate;
}

function toIntegrationsApp(app) {
  return {
          disabledIntegrations: app.disabledIntegrations,
          config: AppJsonConfig$DraftbitBuilder.Decode.config(app.config),
          openAiApiKey: app.openAiApiKey,
          segmentKey: app.segmentKey,
          sentryData: Belt_Option.map(app.sentryData, (function (param) {
                  return JsonUtils$DraftbitBuilder.decodeDecco(Integrations$DraftbitBuilder.sentryData_decode, param);
                })),
          revenueCatData: Belt_Option.map(app.revenueCatData, (function (param) {
                  return JsonUtils$DraftbitBuilder.decodeDecco(Integrations$DraftbitBuilder.revenueCatData_decode, param);
                }))
        };
}

function getIntegrations(app) {
  return Integrations$DraftbitBuilder.getIntegrations(toIntegrationsApp(app));
}

function hasIntegration(app, integrationType) {
  return Belt_Option.isSome(Integrations$DraftbitBuilder.getIntegration(toIntegrationsApp(app), integrationType));
}

function getActiveIntegrations(app) {
  return Integrations$DraftbitBuilder.getIntegrations(toIntegrationsApp(app)).map(Integrations$DraftbitBuilder.fullToSimple).filter(function (it) {
              return !app.disabledIntegrations.includes(it);
            });
}

function getBillingPlan(app) {
  return app.workspace.owner.billingPlan;
}

function getNumCollaborators(app) {
  return app.workspace.collaborators.length;
}

function getExpoVersion(app) {
  return Expo$DraftbitBuilder.tFromString(app.expoVersion);
}

function getUseExpoGo(app) {
  return app.useExpoGo;
}

function getFirstScreenUuid(screens) {
  return Belt_Option.map(Caml_option.undefined_to_opt(screens.find(function (s) {
                      return s.blockType === "Screen";
                    })), (function (screen) {
                return screen.uuid;
              }));
}

function getGlobalVariableByUuid(app, uuid) {
  return Caml_option.undefined_to_opt(app.globalVariables.find(function (gv) {
                  return gv.uuid === uuid;
                }));
}

function getGlobalVariableByName(app, name) {
  return Caml_option.undefined_to_opt(app.globalVariables.find(function (gv) {
                  return gv.name === name;
                }));
}

function getAndroidPublicationSettings(app) {
  var partial_arg = app.expoAccount;
  return Belt_Option.flatMap(app.buildConfig, (function (param) {
                return PublicationSettings$DraftbitBuilder.parseAndroidFromBuildConfig(partial_arg, param);
              }));
}

function getIosPublicationSettings(app) {
  return Belt_Option.flatMap(app.buildConfig, (function (buildConfig) {
                return PublicationSettings$DraftbitBuilder.parseIosFromBuildConfig(app.name, buildConfig, app.config);
              }));
}

var ppx_printed_query = "fragment AppFields on App   {\n__typename\n__typename  \nuuid  \nname  \nshowPwaWrapper  \nupdatedAt  \ncreatedAt  \nversion  \niconUrl  \nnumScreens  \nreadOnly  \nconfig  \nbuildConfig  \ngoogleServiceConfig  \nappJsonOverride  \nlinkingConfiguration  \ngithubToken  \ngithubOwner  \ngithubRepo  \ngithubBranch  \nsegmentKey  \nsentryData  \nrevenueCatData  \ndisabledIntegrations  \nexpoVersion  \nuseExpoGo  \ncoverImageUrl  \nnotificationsConfig  \nplatformBuildType  \nlastCommitAt  \nawsAmplifyConfig  \nofflineModeType  \nofflineModeMessage  \nstate  \nopenAiApiKey  \nnumberOfRemainingAiImageGeneration  \npublishedSnackId  \npublishedExpoVersion  \nlastPublishDate  \ndescription  \nbackupDate  \nworkspace  {\n__typename\n..." + SimpleWorkspace$DraftbitBuilder.GQL.Fragment.SimpleWorkspaceFields.name + "   \n}\n\nglobalVariables  {\n__typename\n..." + GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.name + "   \n}\n\ncustomPackages  {\n__typename\n__typename  \npackage  \nversion  \nisResolution  \n}\n\nscreens  {\n__typename\n..." + SimpleScreen$DraftbitBuilder.GQL.Fragment.SimpleScreenFields.name + "   \n}\n\nexpoAccount  {\n__typename\n..." + ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.name + "   \n}\n\ncustomAppCode  {\n__typename\n..." + CustomAppCodeGQL$DraftbitBuilder.Fragment.CustomAppCodeFields.name + "   \n}\n\n}\n" + CustomAppCodeGQL$DraftbitBuilder.Fragment.CustomAppCodeFields.query + ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.query + GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.query + SimpleScreen$DraftbitBuilder.GQL.Fragment.SimpleScreenFields.query + SimpleWorkspace$DraftbitBuilder.GQL.Fragment.SimpleWorkspaceFields.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type App, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
  }
  var value$9 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_name = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type App is missing");
  }
  var value$12 = Js_dict.get(value$2, "showPwaWrapper");
  var field_showPwaWrapper;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeBoolean(value$13);
    field_showPwaWrapper = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$13));
  } else {
    field_showPwaWrapper = Js_exn.raiseError("graphql_ppx: Field showPwaWrapper on type App is missing");
  }
  var value$15 = Js_dict.get(value$2, "updatedAt");
  var field_updatedAt;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var match = Js_json.decodeNull(value$16);
    field_updatedAt = match !== undefined ? undefined : Caml_option.some(value$16);
  } else {
    field_updatedAt = undefined;
  }
  var value$17 = Js_dict.get(value$2, "createdAt");
  var field_createdAt;
  if (value$17 !== undefined) {
    var value$18 = Caml_option.valFromOption(value$17);
    var match$1 = Js_json.decodeNull(value$18);
    field_createdAt = match$1 !== undefined ? undefined : Caml_option.some(value$18);
  } else {
    field_createdAt = undefined;
  }
  var value$19 = Js_dict.get(value$2, "version");
  var field_version;
  if (value$19 !== undefined) {
    var value$20 = Caml_option.valFromOption(value$19);
    var value$21 = Js_json.decodeNumber(value$20);
    field_version = value$21 !== undefined ? value$21 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$20));
  } else {
    field_version = Js_exn.raiseError("graphql_ppx: Field version on type App is missing");
  }
  var value$22 = Js_dict.get(value$2, "iconUrl");
  var field_iconUrl;
  if (value$22 !== undefined) {
    var value$23 = Caml_option.valFromOption(value$22);
    var match$2 = Js_json.decodeNull(value$23);
    if (match$2 !== undefined) {
      field_iconUrl = undefined;
    } else {
      var value$24 = Js_json.decodeString(value$23);
      field_iconUrl = value$24 !== undefined ? value$24 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$23));
    }
  } else {
    field_iconUrl = undefined;
  }
  var value$25 = Js_dict.get(value$2, "numScreens");
  var field_numScreens;
  if (value$25 !== undefined) {
    var value$26 = Caml_option.valFromOption(value$25);
    var value$27 = Js_json.decodeNumber(value$26);
    field_numScreens = value$27 !== undefined ? value$27 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$26));
  } else {
    field_numScreens = Js_exn.raiseError("graphql_ppx: Field numScreens on type App is missing");
  }
  var value$28 = Js_dict.get(value$2, "readOnly");
  var field_readOnly;
  if (value$28 !== undefined) {
    var value$29 = Caml_option.valFromOption(value$28);
    var value$30 = Js_json.decodeBoolean(value$29);
    field_readOnly = value$30 !== undefined ? value$30 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$29));
  } else {
    field_readOnly = Js_exn.raiseError("graphql_ppx: Field readOnly on type App is missing");
  }
  var value$31 = Js_dict.get(value$2, "config");
  var field_config = value$31 !== undefined ? Caml_option.valFromOption(value$31) : Js_exn.raiseError("graphql_ppx: Field config on type App is missing");
  var value$32 = Js_dict.get(value$2, "buildConfig");
  var field_buildConfig;
  if (value$32 !== undefined) {
    var value$33 = Caml_option.valFromOption(value$32);
    var match$3 = Js_json.decodeNull(value$33);
    field_buildConfig = match$3 !== undefined ? undefined : Caml_option.some(value$33);
  } else {
    field_buildConfig = undefined;
  }
  var value$34 = Js_dict.get(value$2, "googleServiceConfig");
  var field_googleServiceConfig;
  if (value$34 !== undefined) {
    var value$35 = Caml_option.valFromOption(value$34);
    var match$4 = Js_json.decodeNull(value$35);
    field_googleServiceConfig = match$4 !== undefined ? undefined : Caml_option.some(value$35);
  } else {
    field_googleServiceConfig = undefined;
  }
  var value$36 = Js_dict.get(value$2, "appJsonOverride");
  var field_appJsonOverride;
  if (value$36 !== undefined) {
    var value$37 = Caml_option.valFromOption(value$36);
    var match$5 = Js_json.decodeNull(value$37);
    field_appJsonOverride = match$5 !== undefined ? undefined : Caml_option.some(value$37);
  } else {
    field_appJsonOverride = undefined;
  }
  var value$38 = Js_dict.get(value$2, "linkingConfiguration");
  var field_linkingConfiguration;
  if (value$38 !== undefined) {
    var value$39 = Caml_option.valFromOption(value$38);
    var match$6 = Js_json.decodeNull(value$39);
    if (match$6 !== undefined) {
      field_linkingConfiguration = undefined;
    } else {
      var value$40 = Js_json.decodeString(value$39);
      field_linkingConfiguration = value$40 !== undefined ? value$40 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$39));
    }
  } else {
    field_linkingConfiguration = undefined;
  }
  var value$41 = Js_dict.get(value$2, "githubToken");
  var field_githubToken;
  if (value$41 !== undefined) {
    var value$42 = Caml_option.valFromOption(value$41);
    var match$7 = Js_json.decodeNull(value$42);
    if (match$7 !== undefined) {
      field_githubToken = undefined;
    } else {
      var value$43 = Js_json.decodeString(value$42);
      field_githubToken = value$43 !== undefined ? value$43 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$42));
    }
  } else {
    field_githubToken = undefined;
  }
  var value$44 = Js_dict.get(value$2, "githubOwner");
  var field_githubOwner;
  if (value$44 !== undefined) {
    var value$45 = Caml_option.valFromOption(value$44);
    var match$8 = Js_json.decodeNull(value$45);
    if (match$8 !== undefined) {
      field_githubOwner = undefined;
    } else {
      var value$46 = Js_json.decodeString(value$45);
      field_githubOwner = value$46 !== undefined ? value$46 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$45));
    }
  } else {
    field_githubOwner = undefined;
  }
  var value$47 = Js_dict.get(value$2, "githubRepo");
  var field_githubRepo;
  if (value$47 !== undefined) {
    var value$48 = Caml_option.valFromOption(value$47);
    var match$9 = Js_json.decodeNull(value$48);
    if (match$9 !== undefined) {
      field_githubRepo = undefined;
    } else {
      var value$49 = Js_json.decodeString(value$48);
      field_githubRepo = value$49 !== undefined ? value$49 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$48));
    }
  } else {
    field_githubRepo = undefined;
  }
  var value$50 = Js_dict.get(value$2, "githubBranch");
  var field_githubBranch;
  if (value$50 !== undefined) {
    var value$51 = Caml_option.valFromOption(value$50);
    var match$10 = Js_json.decodeNull(value$51);
    if (match$10 !== undefined) {
      field_githubBranch = undefined;
    } else {
      var value$52 = Js_json.decodeString(value$51);
      field_githubBranch = value$52 !== undefined ? value$52 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$51));
    }
  } else {
    field_githubBranch = undefined;
  }
  var value$53 = Js_dict.get(value$2, "segmentKey");
  var field_segmentKey;
  if (value$53 !== undefined) {
    var value$54 = Caml_option.valFromOption(value$53);
    var match$11 = Js_json.decodeNull(value$54);
    if (match$11 !== undefined) {
      field_segmentKey = undefined;
    } else {
      var value$55 = Js_json.decodeString(value$54);
      field_segmentKey = value$55 !== undefined ? value$55 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$54));
    }
  } else {
    field_segmentKey = undefined;
  }
  var value$56 = Js_dict.get(value$2, "sentryData");
  var field_sentryData;
  if (value$56 !== undefined) {
    var value$57 = Caml_option.valFromOption(value$56);
    var match$12 = Js_json.decodeNull(value$57);
    field_sentryData = match$12 !== undefined ? undefined : Caml_option.some(value$57);
  } else {
    field_sentryData = undefined;
  }
  var value$58 = Js_dict.get(value$2, "revenueCatData");
  var field_revenueCatData;
  if (value$58 !== undefined) {
    var value$59 = Caml_option.valFromOption(value$58);
    var match$13 = Js_json.decodeNull(value$59);
    field_revenueCatData = match$13 !== undefined ? undefined : Caml_option.some(value$59);
  } else {
    field_revenueCatData = undefined;
  }
  var value$60 = Js_dict.get(value$2, "disabledIntegrations");
  var field_disabledIntegrations = value$60 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$60))).map(function (value) {
          var value$1 = Js_json.decodeString(value);
          if (value$1 === undefined) {
            return Js_exn.raiseError("graphql_ppx: Expected enum value for SimpleIntegration, got " + JSON.stringify(value));
          }
          switch (value$1) {
            case "GoogleMaps" :
                return "GoogleMaps";
            case "OpenAI" :
                return "OpenAI";
            case "RevenueCat" :
                return "RevenueCat";
            case "Segment" :
                return "Segment";
            case "Sentry" :
                return "Sentry";
            default:
              return Js_exn.raiseError("graphql_ppx: Unknown enum variant for SimpleIntegration: " + value$1);
          }
        }) : Js_exn.raiseError("graphql_ppx: Field disabledIntegrations on type App is missing");
  var value$61 = Js_dict.get(value$2, "expoVersion");
  var field_expoVersion;
  if (value$61 !== undefined) {
    var value$62 = Caml_option.valFromOption(value$61);
    var value$63 = Js_json.decodeString(value$62);
    field_expoVersion = value$63 !== undefined ? value$63 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$62));
  } else {
    field_expoVersion = Js_exn.raiseError("graphql_ppx: Field expoVersion on type App is missing");
  }
  var value$64 = Js_dict.get(value$2, "useExpoGo");
  var field_useExpoGo;
  if (value$64 !== undefined) {
    var value$65 = Caml_option.valFromOption(value$64);
    var value$66 = Js_json.decodeBoolean(value$65);
    field_useExpoGo = value$66 !== undefined ? value$66 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$65));
  } else {
    field_useExpoGo = Js_exn.raiseError("graphql_ppx: Field useExpoGo on type App is missing");
  }
  var value$67 = Js_dict.get(value$2, "coverImageUrl");
  var field_coverImageUrl;
  if (value$67 !== undefined) {
    var value$68 = Caml_option.valFromOption(value$67);
    var match$14 = Js_json.decodeNull(value$68);
    if (match$14 !== undefined) {
      field_coverImageUrl = undefined;
    } else {
      var value$69 = Js_json.decodeString(value$68);
      field_coverImageUrl = value$69 !== undefined ? value$69 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$68));
    }
  } else {
    field_coverImageUrl = undefined;
  }
  var value$70 = Js_dict.get(value$2, "notificationsConfig");
  var field_notificationsConfig;
  if (value$70 !== undefined) {
    var value$71 = Caml_option.valFromOption(value$70);
    var match$15 = Js_json.decodeNull(value$71);
    field_notificationsConfig = match$15 !== undefined ? undefined : Caml_option.some(value$71);
  } else {
    field_notificationsConfig = undefined;
  }
  var value$72 = Js_dict.get(value$2, "platformBuildType");
  var field_platformBuildType;
  if (value$72 !== undefined) {
    var value$73 = Caml_option.valFromOption(value$72);
    var match$16 = Js_json.decodeNull(value$73);
    field_platformBuildType = match$16 !== undefined ? undefined : Caml_option.some(value$73);
  } else {
    field_platformBuildType = undefined;
  }
  var value$74 = Js_dict.get(value$2, "lastCommitAt");
  var field_lastCommitAt;
  if (value$74 !== undefined) {
    var value$75 = Caml_option.valFromOption(value$74);
    var match$17 = Js_json.decodeNull(value$75);
    field_lastCommitAt = match$17 !== undefined ? undefined : Caml_option.some(value$75);
  } else {
    field_lastCommitAt = undefined;
  }
  var value$76 = Js_dict.get(value$2, "awsAmplifyConfig");
  var field_awsAmplifyConfig;
  if (value$76 !== undefined) {
    var value$77 = Caml_option.valFromOption(value$76);
    var match$18 = Js_json.decodeNull(value$77);
    field_awsAmplifyConfig = match$18 !== undefined ? undefined : Caml_option.some(value$77);
  } else {
    field_awsAmplifyConfig = undefined;
  }
  var value$78 = Js_dict.get(value$2, "offlineModeType");
  var field_offlineModeType;
  if (value$78 !== undefined) {
    var value$79 = Caml_option.valFromOption(value$78);
    var value$80 = Js_json.decodeString(value$79);
    if (value$80 !== undefined) {
      switch (value$80) {
        case "Banner" :
            field_offlineModeType = "Banner";
            break;
        case "FullScreen" :
            field_offlineModeType = "FullScreen";
            break;
        case "None" :
            field_offlineModeType = "None";
            break;
        default:
          field_offlineModeType = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OfflineModeType: " + value$80);
      }
    } else {
      field_offlineModeType = Js_exn.raiseError("graphql_ppx: Expected enum value for OfflineModeType, got " + JSON.stringify(value$79));
    }
  } else {
    field_offlineModeType = Js_exn.raiseError("graphql_ppx: Field offlineModeType on type App is missing");
  }
  var value$81 = Js_dict.get(value$2, "offlineModeMessage");
  var field_offlineModeMessage;
  if (value$81 !== undefined) {
    var value$82 = Caml_option.valFromOption(value$81);
    var match$19 = Js_json.decodeNull(value$82);
    if (match$19 !== undefined) {
      field_offlineModeMessage = undefined;
    } else {
      var value$83 = Js_json.decodeString(value$82);
      field_offlineModeMessage = value$83 !== undefined ? value$83 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$82));
    }
  } else {
    field_offlineModeMessage = undefined;
  }
  var value$84 = Js_dict.get(value$2, "state");
  var field_state;
  if (value$84 !== undefined) {
    var value$85 = Caml_option.valFromOption(value$84);
    var value$86 = Js_json.decodeString(value$85);
    if (value$86 !== undefined) {
      switch (value$86) {
        case "Draft" :
            field_state = "Draft";
            break;
        case "Live" :
            field_state = "Live";
            break;
        default:
          field_state = Js_exn.raiseError("graphql_ppx: Unknown enum variant for AppState: " + value$86);
      }
    } else {
      field_state = Js_exn.raiseError("graphql_ppx: Expected enum value for AppState, got " + JSON.stringify(value$85));
    }
  } else {
    field_state = Js_exn.raiseError("graphql_ppx: Field state on type App is missing");
  }
  var value$87 = Js_dict.get(value$2, "openAiApiKey");
  var field_openAiApiKey;
  if (value$87 !== undefined) {
    var value$88 = Caml_option.valFromOption(value$87);
    var match$20 = Js_json.decodeNull(value$88);
    if (match$20 !== undefined) {
      field_openAiApiKey = undefined;
    } else {
      var value$89 = Js_json.decodeString(value$88);
      field_openAiApiKey = value$89 !== undefined ? value$89 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$88));
    }
  } else {
    field_openAiApiKey = undefined;
  }
  var value$90 = Js_dict.get(value$2, "numberOfRemainingAiImageGeneration");
  var field_numberOfRemainingAiImageGeneration;
  if (value$90 !== undefined) {
    var value$91 = Caml_option.valFromOption(value$90);
    var match$21 = Js_json.decodeNull(value$91);
    if (match$21 !== undefined) {
      field_numberOfRemainingAiImageGeneration = undefined;
    } else {
      var value$92 = Js_json.decodeNumber(value$91);
      field_numberOfRemainingAiImageGeneration = value$92 !== undefined ? value$92 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$91));
    }
  } else {
    field_numberOfRemainingAiImageGeneration = undefined;
  }
  var value$93 = Js_dict.get(value$2, "publishedSnackId");
  var field_publishedSnackId;
  if (value$93 !== undefined) {
    var value$94 = Caml_option.valFromOption(value$93);
    var match$22 = Js_json.decodeNull(value$94);
    if (match$22 !== undefined) {
      field_publishedSnackId = undefined;
    } else {
      var value$95 = Js_json.decodeString(value$94);
      field_publishedSnackId = value$95 !== undefined ? value$95 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$94));
    }
  } else {
    field_publishedSnackId = undefined;
  }
  var value$96 = Js_dict.get(value$2, "publishedExpoVersion");
  var field_publishedExpoVersion;
  if (value$96 !== undefined) {
    var value$97 = Caml_option.valFromOption(value$96);
    var match$23 = Js_json.decodeNull(value$97);
    if (match$23 !== undefined) {
      field_publishedExpoVersion = undefined;
    } else {
      var value$98 = Js_json.decodeString(value$97);
      field_publishedExpoVersion = value$98 !== undefined ? value$98 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$97));
    }
  } else {
    field_publishedExpoVersion = undefined;
  }
  var value$99 = Js_dict.get(value$2, "lastPublishDate");
  var field_lastPublishDate;
  if (value$99 !== undefined) {
    var value$100 = Caml_option.valFromOption(value$99);
    var match$24 = Js_json.decodeNull(value$100);
    field_lastPublishDate = match$24 !== undefined ? undefined : Caml_option.some(value$100);
  } else {
    field_lastPublishDate = undefined;
  }
  var value$101 = Js_dict.get(value$2, "description");
  var field_description;
  if (value$101 !== undefined) {
    var value$102 = Caml_option.valFromOption(value$101);
    var value$103 = Js_json.decodeString(value$102);
    field_description = value$103 !== undefined ? value$103 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$102));
  } else {
    field_description = Js_exn.raiseError("graphql_ppx: Field description on type App is missing");
  }
  var value$104 = Js_dict.get(value$2, "backupDate");
  var field_backupDate;
  if (value$104 !== undefined) {
    var value$105 = Caml_option.valFromOption(value$104);
    var match$25 = Js_json.decodeNull(value$105);
    field_backupDate = match$25 !== undefined ? undefined : Caml_option.some(value$105);
  } else {
    field_backupDate = undefined;
  }
  var value$106 = Js_dict.get(value$2, "workspace");
  var field_workspace = value$106 !== undefined ? Curry._1(SimpleWorkspace$DraftbitBuilder.GQL.Fragment.SimpleWorkspaceFields.parse, Caml_option.valFromOption(value$106)) : Js_exn.raiseError("graphql_ppx: Field workspace on type App is missing");
  var value$107 = Js_dict.get(value$2, "globalVariables");
  var field_globalVariables = value$107 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$107))).map(function (value) {
          return Curry._1(GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field globalVariables on type App is missing");
  var value$108 = Js_dict.get(value$2, "customPackages");
  var field_customPackages = value$108 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$108))).map(function (value) {
          var value$1 = Js_json.decodeObject(value);
          if (value$1 === undefined) {
            return Js_exn.raiseError("graphql_ppx: Expected object of type CustomPackage, got " + JSON.stringify(value));
          }
          var value$2 = Caml_option.valFromOption(value$1);
          var value$3 = Js_dict.get(value$2, "__typename");
          var field___typename;
          if (value$3 !== undefined) {
            var value$4 = Caml_option.valFromOption(value$3);
            var value$5 = Js_json.decodeString(value$4);
            field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
          } else {
            field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type CustomPackage is missing");
          }
          var value$6 = Js_dict.get(value$2, "package");
          var field_package;
          if (value$6 !== undefined) {
            var value$7 = Caml_option.valFromOption(value$6);
            var value$8 = Js_json.decodeString(value$7);
            field_package = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
          } else {
            field_package = Js_exn.raiseError("graphql_ppx: Field package on type CustomPackage is missing");
          }
          var value$9 = Js_dict.get(value$2, "version");
          var field_version;
          if (value$9 !== undefined) {
            var value$10 = Caml_option.valFromOption(value$9);
            var value$11 = Js_json.decodeString(value$10);
            field_version = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
          } else {
            field_version = Js_exn.raiseError("graphql_ppx: Field version on type CustomPackage is missing");
          }
          var value$12 = Js_dict.get(value$2, "isResolution");
          var field_isResolution;
          if (value$12 !== undefined) {
            var value$13 = Caml_option.valFromOption(value$12);
            var value$14 = Js_json.decodeBoolean(value$13);
            field_isResolution = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$13));
          } else {
            field_isResolution = Js_exn.raiseError("graphql_ppx: Field isResolution on type CustomPackage is missing");
          }
          return {
                  __typename: field___typename,
                  package: field_package,
                  version: field_version,
                  isResolution: field_isResolution
                };
        }) : Js_exn.raiseError("graphql_ppx: Field customPackages on type App is missing");
  var value$109 = Js_dict.get(value$2, "screens");
  var field_screens = value$109 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$109))).map(function (value) {
          return Curry._1(SimpleScreen$DraftbitBuilder.GQL.Fragment.SimpleScreenFields.parse, value);
        }) : Js_exn.raiseError("graphql_ppx: Field screens on type App is missing");
  var value$110 = Js_dict.get(value$2, "expoAccount");
  var field_expoAccount;
  if (value$110 !== undefined) {
    var value$111 = Caml_option.valFromOption(value$110);
    var match$26 = Js_json.decodeNull(value$111);
    field_expoAccount = match$26 !== undefined ? undefined : Curry._1(ExpoAccountGQL$DraftbitBuilder.Fragment.ExpoAccountFields.parse, value$111);
  } else {
    field_expoAccount = undefined;
  }
  var value$112 = Js_dict.get(value$2, "customAppCode");
  var field_customAppCode;
  if (value$112 !== undefined) {
    var value$113 = Caml_option.valFromOption(value$112);
    var match$27 = Js_json.decodeNull(value$113);
    field_customAppCode = match$27 !== undefined ? undefined : Curry._1(CustomAppCodeGQL$DraftbitBuilder.Fragment.CustomAppCodeFields.parse, value$113);
  } else {
    field_customAppCode = undefined;
  }
  return {
          __typename: field___typename,
          uuid: field_uuid,
          name: field_name,
          version: field_version,
          updatedAt: field_updatedAt,
          createdAt: field_createdAt,
          iconUrl: field_iconUrl,
          numScreens: field_numScreens,
          readOnly: field_readOnly,
          showPwaWrapper: field_showPwaWrapper,
          config: field_config,
          buildConfig: field_buildConfig,
          googleServiceConfig: field_googleServiceConfig,
          appJsonOverride: field_appJsonOverride,
          linkingConfiguration: field_linkingConfiguration,
          githubToken: field_githubToken,
          githubOwner: field_githubOwner,
          githubRepo: field_githubRepo,
          githubBranch: field_githubBranch,
          segmentKey: field_segmentKey,
          sentryData: field_sentryData,
          revenueCatData: field_revenueCatData,
          disabledIntegrations: field_disabledIntegrations,
          expoVersion: field_expoVersion,
          useExpoGo: field_useExpoGo,
          notificationsConfig: field_notificationsConfig,
          platformBuildType: field_platformBuildType,
          lastCommitAt: field_lastCommitAt,
          customPackages: field_customPackages,
          globalVariables: field_globalVariables,
          screens: field_screens,
          awsAmplifyConfig: field_awsAmplifyConfig,
          offlineModeType: field_offlineModeType,
          offlineModeMessage: field_offlineModeMessage,
          state: field_state,
          workspace: field_workspace,
          coverImageUrl: field_coverImageUrl,
          expoAccount: field_expoAccount,
          customAppCode: field_customAppCode,
          openAiApiKey: field_openAiApiKey,
          numberOfRemainingAiImageGeneration: field_numberOfRemainingAiImageGeneration,
          publishedSnackId: field_publishedSnackId,
          publishedExpoVersion: field_publishedExpoVersion,
          lastPublishDate: field_lastPublishDate,
          description: field_description,
          backupDate: field_backupDate
        };
}

var name$1 = "AppFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var AppFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name$1,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var AppFragment = {
  AppFields: AppFields
};

var ppx_printed_query$1 = "query App($appUuid: ID!)  {\n__typename\n__typename  \napp(appUuid: $appUuid)  {\n__typename\n...AppFields   \n}\n\n}\n" + ppx_printed_query;

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "__typename");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type Query is missing");
  }
  var value$5 = Js_dict.get(value$1, "app");
  return {
          __typename: tmp,
          app: value$5 !== undefined ? parse(Caml_option.valFromOption(value$5)) : Js_exn.raiseError("graphql_ppx: Field app on type Query is missing")
        };
}

function make(appUuid, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var appUuid = variables.appUuid;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(appUuid, param) {
  return Js_dict.fromArray([[
                  "appUuid",
                  appUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, appUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "appUuid",
                      appUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function useQuery(appUuid, skip, param) {
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables(appUuid, undefined)), undefined, undefined, undefined, Belt_Option.getWithDefault(skip, !Uuid$DraftbitBuilder.isUuid(appUuid)), undefined, undefined, definition);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.app;
              }));
}

function refetch(appUuid) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make(appUuid, undefined));
}

var Get = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  useQuery: useQuery,
  refetch: refetch
};

var ppx_printed_query$2 = "query MostRecentApps  {\n__typename\nmostRecentApps  {\n__typename\n..." + SimpleApp$DraftbitBuilder.GQL.Fragment.SimpleAppFields.name + "   \n}\n\n}\n" + SimpleApp$DraftbitBuilder.GQL.Fragment.SimpleAppFields.query;

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "mostRecentApps");
  return {
          mostRecentApps: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  return Curry._1(SimpleApp$DraftbitBuilder.GQL.Fragment.SimpleAppFields.parse, value);
                }) : Js_exn.raiseError("graphql_ppx: Field mostRecentApps on type Query is missing")
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$1
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

function useQuery$1(skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, undefined, undefined, undefined, undefined, skip, undefined, undefined, definition$1);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.mostRecentApps;
              }));
}

function refetch$1(param) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make$1(undefined));
}

var GetMostRecent = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2,
  useQuery: useQuery$1,
  refetch: refetch$1
};

function modifyAppInCache(client, appUuid, modify) {
  ApolloClient$DraftbitBuilder.ReadQuery({
        query: ppx_printed_query$1,
        parse: parse$1
      });
  var WriteQuery = ApolloClient$DraftbitBuilder.WriteQuery({
        query: ppx_printed_query$1,
        parse: parse$1
      });
  var query = make(appUuid, undefined);
  var options = ApolloHooks$DraftbitBuilder.toQueryObj(query);
  var cachedApp;
  try {
    cachedApp = Caml_option.nullable_to_opt(client.readQuery(options));
  }
  catch (raw_e){
    return Sentry$DraftbitBuilder.captureException(Caml_js_exceptions.internalToOCamlException(raw_e));
  }
  if (cachedApp === undefined) {
    return Sentry$DraftbitBuilder.captureMessage("App read query returned no data");
  }
  var app = Caml_option.valFromOption(cachedApp).app;
  return Curry._4(WriteQuery.make, client, Caml_option.some(query.variables), {
              __typename: "Query",
              app: Curry._1(modify, app)
            }, undefined);
}

function useQuery$2(appUuid) {
  var appQuery = useQuery(appUuid, undefined, undefined);
  return Belt_Option.mapWithDefault(ApolloUtils$DraftbitBuilder.Query.data(appQuery), BillingPlan$DraftbitBuilder.dummyBillingPlan, getBillingPlan);
}

var GetBillingPlan = {
  useQuery: useQuery$2
};

function useQuery$3(appUuid) {
  var appQuery = useQuery(appUuid, undefined, undefined);
  return Belt_Option.mapWithDefault(ApolloUtils$DraftbitBuilder.Query.data(appQuery), 0, getNumCollaborators);
}

var GetNumCollaborators = {
  useQuery: useQuery$3
};

function useQuery$4(appUuid) {
  var appQuery = useQuery(appUuid, undefined, undefined);
  return Belt_Option.mapWithDefault(ApolloUtils$DraftbitBuilder.Query.data(appQuery), Expo$DraftbitBuilder.defaultVersion, getExpoVersion);
}

var GetExpoVersion = {
  useQuery: useQuery$4
};

function useQuery$5(appUuid) {
  var appQuery = useQuery(appUuid, undefined, undefined);
  return Belt_Option.mapWithDefault(ApolloUtils$DraftbitBuilder.Query.data(appQuery), false, useExpoGo);
}

var GetUseExpoGo = {
  useQuery: useQuery$5
};

var ppx_printed_query$3 = "query AppLastCommit($appUuid: ID!)  {\n__typename\napp(appUuid: $appUuid)  {\n__typename\n__typename  \nuuid  \nlastCommitAt  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "app");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeObject(value$3);
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_dict.get(value$5, "__typename");
      var field___typename;
      if (value$6 !== undefined) {
        var value$7 = Caml_option.valFromOption(value$6);
        var value$8 = Js_json.decodeString(value$7);
        field___typename = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
      } else {
        field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
      }
      var value$9 = Js_dict.get(value$5, "uuid");
      var field_uuid;
      if (value$9 !== undefined) {
        var value$10 = Caml_option.valFromOption(value$9);
        var value$11 = Js_json.decodeString(value$10);
        field_uuid = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
      } else {
        field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
      }
      var value$12 = Js_dict.get(value$5, "lastCommitAt");
      var field_lastCommitAt;
      if (value$12 !== undefined) {
        var value$13 = Caml_option.valFromOption(value$12);
        var match = Js_json.decodeNull(value$13);
        field_lastCommitAt = match !== undefined ? undefined : Caml_option.some(value$13);
      } else {
        field_lastCommitAt = undefined;
      }
      tmp = {
        __typename: field___typename,
        uuid: field_uuid,
        lastCommitAt: field_lastCommitAt
      };
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected object of type App, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field app on type Query is missing");
  }
  return {
          app: tmp
        };
}

function make$2(appUuid, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$2(variables) {
  var appUuid = variables.appUuid;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$2(appUuid, param) {
  return Js_dict.fromArray([[
                  "appUuid",
                  appUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, appUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "appUuid",
                      appUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$2
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function useQuery$6(appUuid, pollInterval) {
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables$2(appUuid, undefined)), undefined, undefined, undefined, undefined, pollInterval, undefined, definition$2);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.app;
              }));
}

var GetLastCommit = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  useQuery: useQuery$6
};

var ppx_printed_query$4 = "query TemplateApps  {\n__typename\ntemplateApps  {\n__typename\nuuid  \nname  \ndescription  \niconUrl  \ncoverImageUrl  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "templateApps");
  return {
          templateApps: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "uuid");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "name");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type App is missing");
                  }
                  var value$8 = Js_dict.get(value$1, "description");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field description on type App is missing");
                  }
                  var value$11 = Js_dict.get(value$1, "iconUrl");
                  var tmp$3;
                  if (value$11 !== undefined) {
                    var value$12 = Caml_option.valFromOption(value$11);
                    var match = Js_json.decodeNull(value$12);
                    if (match !== undefined) {
                      tmp$3 = undefined;
                    } else {
                      var value$13 = Js_json.decodeString(value$12);
                      tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                    }
                  } else {
                    tmp$3 = undefined;
                  }
                  var value$14 = Js_dict.get(value$1, "coverImageUrl");
                  var tmp$4;
                  if (value$14 !== undefined) {
                    var value$15 = Caml_option.valFromOption(value$14);
                    var match$1 = Js_json.decodeNull(value$15);
                    if (match$1 !== undefined) {
                      tmp$4 = undefined;
                    } else {
                      var value$16 = Js_json.decodeString(value$15);
                      tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                    }
                  } else {
                    tmp$4 = undefined;
                  }
                  return {
                          uuid: tmp,
                          name: tmp$1,
                          description: tmp$2,
                          iconUrl: tmp$3,
                          coverImageUrl: tmp$4
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field templateApps on type Query is missing")
        };
}

function make$3(param) {
  return {
          query: ppx_printed_query$4,
          variables: null,
          parse: parse$4
        };
}

function makeWithVariables$3(param) {
  return {
          query: ppx_printed_query$4,
          variables: null,
          parse: parse$4
        };
}

function makeVariables$3(param) {
  return null;
}

function definition_2$3(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$3 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$3
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

function useQuery$7(param) {
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$3);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.templateApps;
              }));
}

var TemplateApps = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4,
  useQuery: useQuery$7
};

var ppx_printed_query$5 = "mutation UpdateApp($appUuid: ID!, $input: UpdateAppInput!)  {\n__typename\nupdateApp(appUuid: $appUuid, input: $input)  {\n__typename\n...AppFields   \n}\n\n}\n" + ppx_printed_query;

function parse$5(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateApp");
  return {
          updateApp: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field updateApp on type Mutation is missing")
        };
}

function json_of_UpdateBuildConfigInput(value) {
  var v = value.android_eas_applicationId;
  var v$1 = value.android_eas_releaseStatus;
  var v$2 = value.android_eas_serviceAccountFile;
  var v$3 = value.ios_appStore_appId;
  var v$4 = value.ios_appStore_connectIssuerId;
  var v$5 = value.ios_appStore_connectKeyId;
  var v$6 = value.ios_appStore_connectPrivateKey;
  return Js_dict.fromArray([
                [
                  "android_eas_applicationId",
                  v !== undefined ? v : null
                ],
                [
                  "android_eas_releaseStatus",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "android_eas_serviceAccountFile",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "ios_appStore_appId",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "ios_appStore_connectIssuerId",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "ios_appStore_connectKeyId",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "ios_appStore_connectPrivateKey",
                  v$6 !== undefined ? v$6 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_SimpleIntegration(value) {
  if (value === "GoogleMaps") {
    return "GoogleMaps";
  } else if (value === "Segment") {
    return "Segment";
  } else if (value === "Sentry") {
    return "Sentry";
  } else if (value === "RevenueCat") {
    return "RevenueCat";
  } else {
    return "OpenAI";
  }
}

function json_of_UpdateGithubSettingsInput(value) {
  var v = value.githubBranch;
  var v$1 = value.githubOwner;
  var v$2 = value.githubRepo;
  var v$3 = value.githubToken;
  return Js_dict.fromArray([
                [
                  "githubBranch",
                  v !== undefined ? v : null
                ],
                [
                  "githubOwner",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "githubRepo",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "githubToken",
                  v$3 !== undefined ? v$3 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_UpdateNotificationsConfigInput(value) {
  return Js_dict.fromArray([
                [
                  "shouldAlwaysClearBadges",
                  value.shouldAlwaysClearBadges
                ],
                [
                  "shouldPlaySound",
                  value.shouldPlaySound
                ],
                [
                  "shouldSetBadge",
                  value.shouldSetBadge
                ],
                [
                  "shouldShowAlert",
                  value.shouldShowAlert
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_OfflineModeType(value) {
  if (value === "FullScreen") {
    return "FullScreen";
  } else if (value === "None") {
    return "None";
  } else {
    return "Banner";
  }
}

function json_of_BuildType(value) {
  if (value === "build") {
    return "build";
  } else if (value === "custom") {
    return "custom";
  } else {
    return "publish";
  }
}

function json_of_UpdatePlatformBuildType(value) {
  return Js_dict.fromArray([
                [
                  "android",
                  json_of_BuildType(value.android)
                ],
                [
                  "ios",
                  json_of_BuildType(value.ios)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_AppState(value) {
  if (value === "Live") {
    return "Live";
  } else {
    return "Draft";
  }
}

function json_of_UpdateAppInput(value) {
  var v = value.appJsonOverride;
  var v$1 = value.buildConfig;
  var v$2 = value.config;
  var v$3 = value.disabledIntegrations;
  var v$4 = value.expoAccountUuid;
  var v$5 = value.expoVersion;
  var v$6 = value.githubSettings;
  var v$7 = value.googleServiceConfig;
  var v$8 = value.linkingConfiguration;
  var v$9 = value.name;
  var v$10 = value.notificationsConfig;
  var v$11 = value.offlineModeMessage;
  var v$12 = value.offlineModeType;
  var v$13 = value.openAiApiKey;
  var v$14 = value.platformBuildType;
  var v$15 = value.revenueCatData;
  var v$16 = value.segmentKey;
  var v$17 = value.sentryData;
  var v$18 = value.showPwaWrapper;
  var v$19 = value.state;
  var v$20 = value.useExpoGo;
  return Js_dict.fromArray([
                [
                  "appJsonOverride",
                  v !== undefined ? Caml_option.valFromOption(v) : null
                ],
                [
                  "buildConfig",
                  v$1 !== undefined ? json_of_UpdateBuildConfigInput(Caml_option.valFromOption(v$1)) : null
                ],
                [
                  "config",
                  v$2 !== undefined ? Caml_option.valFromOption(v$2) : null
                ],
                [
                  "disabledIntegrations",
                  v$3 !== undefined ? v$3.map(json_of_SimpleIntegration) : null
                ],
                [
                  "expoAccountUuid",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "expoVersion",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "githubSettings",
                  v$6 !== undefined ? json_of_UpdateGithubSettingsInput(Caml_option.valFromOption(v$6)) : null
                ],
                [
                  "googleServiceConfig",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ],
                [
                  "linkingConfiguration",
                  v$8 !== undefined ? v$8 : null
                ],
                [
                  "name",
                  v$9 !== undefined ? v$9 : null
                ],
                [
                  "notificationsConfig",
                  v$10 !== undefined ? json_of_UpdateNotificationsConfigInput(Caml_option.valFromOption(v$10)) : null
                ],
                [
                  "offlineModeMessage",
                  v$11 !== undefined ? v$11 : null
                ],
                [
                  "offlineModeType",
                  v$12 !== undefined ? json_of_OfflineModeType(v$12) : null
                ],
                [
                  "openAiApiKey",
                  v$13 !== undefined ? v$13 : null
                ],
                [
                  "platformBuildType",
                  v$14 !== undefined ? json_of_UpdatePlatformBuildType(Caml_option.valFromOption(v$14)) : null
                ],
                [
                  "revenueCatData",
                  v$15 !== undefined ? Caml_option.valFromOption(v$15) : null
                ],
                [
                  "segmentKey",
                  v$16 !== undefined ? v$16 : null
                ],
                [
                  "sentryData",
                  v$17 !== undefined ? Caml_option.valFromOption(v$17) : null
                ],
                [
                  "showPwaWrapper",
                  v$18 !== undefined ? v$18 : null
                ],
                [
                  "state",
                  v$19 !== undefined ? json_of_AppState(v$19) : null
                ],
                [
                  "useExpoGo",
                  v$20 !== undefined ? v$20 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$4(appUuid, input, param) {
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "input",
                    json_of_UpdateAppInput(input)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeWithVariables$4(variables) {
  var appUuid = variables.appUuid;
  var input = variables.input;
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "input",
                    json_of_UpdateAppInput(input)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeVariables$4(appUuid, input, param) {
  return Js_dict.fromArray([
                [
                  "appUuid",
                  appUuid
                ],
                [
                  "input",
                  json_of_UpdateAppInput(input)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, appUuid, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "appUuid",
                      appUuid
                    ],
                    [
                      "input",
                      json_of_UpdateAppInput(input)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$5,
  ppx_printed_query$5,
  definition_2$4
];

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

function useMutation(appUuid) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, (function (param) {
          return [User$DraftbitBuilder.GQL.Get.refetch];
        }), undefined, undefined, undefined, undefined, undefined, definition$4);
  var updateApp = match[0];
  var f = function (name, showPwaWrapper, config, buildConfig, googleServiceConfig, appJsonOverride, linkingConfiguration, githubSettings, notificationsConfig, platformBuildType, offlineModeTypeOpt, offlineModeMessage, segmentKey, sentryData, revenueCatData, disabledIntegrations, expoVersion, state, useExpoGo, onDataOpt, onErrorOpt, expoAccountUuid, openAiApiKey, param) {
    var offlineModeType = offlineModeTypeOpt !== undefined ? Caml_option.valFromOption(offlineModeTypeOpt) : undefined;
    var onData = onDataOpt !== undefined ? onDataOpt : Utils$DraftbitBuilder.noop;
    var onError = onErrorOpt !== undefined ? onErrorOpt : Utils$DraftbitBuilder.noop;
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(updateApp, Caml_option.some(makeVariables$4(appUuid, {
                                name: name,
                                showPwaWrapper: showPwaWrapper,
                                config: config,
                                buildConfig: buildConfig,
                                googleServiceConfig: googleServiceConfig,
                                appJsonOverride: appJsonOverride,
                                linkingConfiguration: linkingConfiguration,
                                notificationsConfig: notificationsConfig,
                                platformBuildType: platformBuildType,
                                githubSettings: githubSettings,
                                offlineModeType: offlineModeType,
                                offlineModeMessage: offlineModeMessage,
                                segmentKey: segmentKey,
                                sentryData: Belt_Option.map(sentryData, Integrations$DraftbitBuilder.sentryData_encode),
                                revenueCatData: Belt_Option.map(revenueCatData, Integrations$DraftbitBuilder.revenueCatData_encode),
                                disabledIntegrations: disabledIntegrations,
                                expoVersion: expoVersion,
                                state: state,
                                useExpoGo: useExpoGo,
                                expoAccountUuid: expoAccountUuid,
                                openAiApiKey: openAiApiKey
                              }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), "Your settings were saved", undefined, undefined, undefined, undefined, onData, onError, "Something went wrong, please try again.", "Updated App Settings", undefined, undefined, undefined);
  };
  return [
          f,
          match[2].loading
        ];
}

var Update = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  json_of_UpdateBuildConfigInput: json_of_UpdateBuildConfigInput,
  json_of_SimpleIntegration: json_of_SimpleIntegration,
  json_of_UpdateGithubSettingsInput: json_of_UpdateGithubSettingsInput,
  json_of_UpdateNotificationsConfigInput: json_of_UpdateNotificationsConfigInput,
  json_of_OfflineModeType: json_of_OfflineModeType,
  json_of_BuildType: json_of_BuildType,
  json_of_UpdatePlatformBuildType: json_of_UpdatePlatformBuildType,
  json_of_AppState: json_of_AppState,
  json_of_UpdateAppInput: json_of_UpdateAppInput,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5,
  useMutation: useMutation
};

var ppx_printed_query$6 = "mutation ExportApp($appUuid: ID!, $env: PublicationEnvironment)  {\n__typename\ndownloadApp(appUuid: $appUuid, env: $env)  \n}\n";

function parse$6(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "downloadApp");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field downloadApp on type Mutation is missing");
  }
  return {
          downloadApp: tmp
        };
}

function json_of_PublicationEnvironment(value) {
  if (value === "Production") {
    return "Production";
  } else {
    return "Development";
  }
}

function make$5(appUuid, env, param) {
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "env",
                    env !== undefined ? json_of_PublicationEnvironment(env) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeWithVariables$5(variables) {
  var appUuid = variables.appUuid;
  var env = variables.env;
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "env",
                    env !== undefined ? json_of_PublicationEnvironment(env) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeVariables$5(appUuid, env, param) {
  return Js_dict.fromArray([
                [
                  "appUuid",
                  appUuid
                ],
                [
                  "env",
                  env !== undefined ? json_of_PublicationEnvironment(env) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$5(graphql_ppx_use_json_variables_fn, appUuid, env, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "appUuid",
                      appUuid
                    ],
                    [
                      "env",
                      env !== undefined ? json_of_PublicationEnvironment(env) : null
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$5 = [
  parse$6,
  ppx_printed_query$6,
  definition_2$5
];

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

function useMutation$1(appUuid, appName, env) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$5);
  var mutation = match[0];
  return function (param) {
    Notify$DraftbitBuilder.success("Your app is being prepared for download. You'll receive an email when it's ready.");
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables$5(appUuid, env, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                  return data.downloadApp;
                }), "Export Complete", undefined, undefined, undefined, Caml_option.some(Js_dict.fromArray([
                        [
                          "uuid",
                          appUuid
                        ],
                        [
                          "name",
                          appName
                        ]
                      ])), "Error exporting app. Please try again", "Exported Project Source Code", undefined, undefined, undefined);
  };
}

var Export = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  json_of_PublicationEnvironment: json_of_PublicationEnvironment,
  make: make$5,
  makeWithVariables: makeWithVariables$5,
  makeVariables: makeVariables$5,
  definition: definition$5,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6,
  useMutation: useMutation$1
};

var ppx_printed_query$7 = "mutation SaveCustomPackages($input: SaveCustomPackagesInput!)  {\n__typename\nsaveCustomPackages(input: $input)  {\n__typename\n__typename  \nuuid  \ncustomPackages  {\n__typename\n__typename  \npackage  \nversion  \nisResolution  \n}\n\nimports  {\n__typename\n__typename  \nmodule  \npackage  \n}\n\n}\n\n}\n";

function parse$7(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "saveCustomPackages");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "__typename");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "uuid");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeString(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$10 = Js_dict.get(value$3, "customPackages");
    var value$11 = Js_dict.get(value$3, "imports");
    tmp = {
      __typename: tmp$1,
      uuid: tmp$2,
      customPackages: value$10 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$10))).map(function (value) {
              var value$1 = Js_json.decodeObject(value);
              if (value$1 === undefined) {
                return Js_exn.raiseError("graphql_ppx: Expected object of type CustomPackage, got " + JSON.stringify(value));
              }
              var value$2 = Caml_option.valFromOption(value$1);
              var value$3 = Js_dict.get(value$2, "__typename");
              var field___typename;
              if (value$3 !== undefined) {
                var value$4 = Caml_option.valFromOption(value$3);
                var value$5 = Js_json.decodeString(value$4);
                field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
              } else {
                field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type CustomPackage is missing");
              }
              var value$6 = Js_dict.get(value$2, "package");
              var field_package;
              if (value$6 !== undefined) {
                var value$7 = Caml_option.valFromOption(value$6);
                var value$8 = Js_json.decodeString(value$7);
                field_package = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
              } else {
                field_package = Js_exn.raiseError("graphql_ppx: Field package on type CustomPackage is missing");
              }
              var value$9 = Js_dict.get(value$2, "version");
              var field_version;
              if (value$9 !== undefined) {
                var value$10 = Caml_option.valFromOption(value$9);
                var value$11 = Js_json.decodeString(value$10);
                field_version = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
              } else {
                field_version = Js_exn.raiseError("graphql_ppx: Field version on type CustomPackage is missing");
              }
              var value$12 = Js_dict.get(value$2, "isResolution");
              var field_isResolution;
              if (value$12 !== undefined) {
                var value$13 = Caml_option.valFromOption(value$12);
                var value$14 = Js_json.decodeBoolean(value$13);
                field_isResolution = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$13));
              } else {
                field_isResolution = Js_exn.raiseError("graphql_ppx: Field isResolution on type CustomPackage is missing");
              }
              return {
                      __typename: field___typename,
                      package: field_package,
                      version: field_version,
                      isResolution: field_isResolution
                    };
            }) : Js_exn.raiseError("graphql_ppx: Field customPackages on type App is missing"),
      imports: value$11 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$11))).map(function (value) {
              var value$1 = Js_option.getExn(Js_json.decodeObject(value));
              var value$2 = Js_dict.get(value$1, "__typename");
              var tmp;
              if (value$2 !== undefined) {
                var value$3 = Caml_option.valFromOption(value$2);
                var value$4 = Js_json.decodeString(value$3);
                tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
              } else {
                tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type Import is missing");
              }
              var value$5 = Js_dict.get(value$1, "module");
              var tmp$1;
              if (value$5 !== undefined) {
                var value$6 = Caml_option.valFromOption(value$5);
                var value$7 = Js_json.decodeString(value$6);
                tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Field module on type Import is missing");
              }
              var value$8 = Js_dict.get(value$1, "package");
              var tmp$2;
              if (value$8 !== undefined) {
                var value$9 = Caml_option.valFromOption(value$8);
                var value$10 = Js_json.decodeString(value$9);
                tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
              } else {
                tmp$2 = Js_exn.raiseError("graphql_ppx: Field package on type Import is missing");
              }
              return {
                      __typename: tmp,
                      module: tmp$1,
                      package: tmp$2
                    };
            }) : Js_exn.raiseError("graphql_ppx: Field imports on type App is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field saveCustomPackages on type Mutation is missing");
  }
  return {
          saveCustomPackages: tmp
        };
}

function json_of_CustomPackageInput(value) {
  return Js_dict.fromArray([
                [
                  "isResolution",
                  value.isResolution
                ],
                [
                  "package",
                  value.package
                ],
                [
                  "version",
                  value.version
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_SaveCustomPackagesInput(value) {
  var v = value.customPackages;
  return Js_dict.fromArray([
                [
                  "customPackages",
                  v.map(json_of_CustomPackageInput)
                ],
                [
                  "uuid",
                  value.uuid
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$6(input, param) {
  return {
          query: ppx_printed_query$7,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_SaveCustomPackagesInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$7
        };
}

function makeWithVariables$6(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$7,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_SaveCustomPackagesInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$7
        };
}

function makeVariables$6(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_SaveCustomPackagesInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$6(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_SaveCustomPackagesInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$6 = [
  parse$7,
  ppx_printed_query$7,
  definition_2$6
];

function ret_type$7(f) {
  return {};
}

var MT_Ret$7 = {};

function useMutation$2(param) {
  var match = Router$DraftbitBuilder.getParams(undefined);
  var appUuid = match.appUuid;
  var match$1 = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$6);
  var saveCustomPackages = match$1[0];
  return function (customPackages) {
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(saveCustomPackages, Caml_option.some(makeVariables$6({
                                uuid: appUuid,
                                customPackages: customPackages.map(function (pak) {
                                      return {
                                              package: pak.package,
                                              version: pak.version,
                                              isResolution: pak.isResolution
                                            };
                                    })
                              }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), "Packages successfully updated", undefined, undefined, undefined, undefined, undefined, undefined, "Something went wrong, please try again.", "Updated custom packages", undefined, undefined, undefined);
  };
}

var SaveCustomPackages = {
  ppx_printed_query: ppx_printed_query$7,
  query: ppx_printed_query$7,
  parse: parse$7,
  json_of_CustomPackageInput: json_of_CustomPackageInput,
  json_of_SaveCustomPackagesInput: json_of_SaveCustomPackagesInput,
  make: make$6,
  makeWithVariables: makeWithVariables$6,
  makeVariables: makeVariables$6,
  definition: definition$6,
  ret_type: ret_type$7,
  MT_Ret: MT_Ret$7,
  useMutation: useMutation$2
};

var ppx_printed_query$8 = "mutation SaveCustomAppCode($appUuid: ID!, $input: CustomAppCodeInput)  {\n__typename\nsaveCustomAppCode(appUuid: $appUuid, input: $input)  {\n__typename\n__typename  \nuuid  \ncustomAppCode  {\n__typename\n..." + CustomAppCodeGQL$DraftbitBuilder.Fragment.CustomAppCodeFields.name + "   \n}\n\n}\n\n}\n" + CustomAppCodeGQL$DraftbitBuilder.Fragment.CustomAppCodeFields.query;

function parse$8(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "saveCustomAppCode");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "__typename");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "uuid");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeString(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$10 = Js_dict.get(value$3, "customAppCode");
    var tmp$3;
    if (value$10 !== undefined) {
      var value$11 = Caml_option.valFromOption(value$10);
      var match = Js_json.decodeNull(value$11);
      tmp$3 = match !== undefined ? undefined : Curry._1(CustomAppCodeGQL$DraftbitBuilder.Fragment.CustomAppCodeFields.parse, value$11);
    } else {
      tmp$3 = undefined;
    }
    tmp = {
      __typename: tmp$1,
      uuid: tmp$2,
      customAppCode: tmp$3
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field saveCustomAppCode on type Mutation is missing");
  }
  return {
          saveCustomAppCode: tmp
        };
}

function json_of_ImportInput(value) {
  return Js_dict.fromArray([
                [
                  "isDefaultExport",
                  value.isDefaultExport
                ],
                [
                  "module",
                  value.module
                ],
                [
                  "package",
                  value.package
                ],
                [
                  "wildCardImport",
                  value.wildCardImport
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_CustomAppCodeInput(value) {
  var v = value.customFileUuids;
  var v$1 = value.imports;
  return Js_dict.fromArray([
                [
                  "customFileUuids",
                  v.map(function (prim) {
                        return prim;
                      })
                ],
                [
                  "imports",
                  v$1.map(json_of_ImportInput)
                ],
                [
                  "inAppComponent",
                  value.inAppComponent
                ],
                [
                  "navigatorWrapperEnd",
                  value.navigatorWrapperEnd
                ],
                [
                  "navigatorWrapperStart",
                  value.navigatorWrapperStart
                ],
                [
                  "preAppComponent",
                  value.preAppComponent
                ],
                [
                  "providerEnd",
                  value.providerEnd
                ],
                [
                  "providerStart",
                  value.providerStart
                ],
                [
                  "rawImports",
                  value.rawImports
                ],
                [
                  "rootPeerComponent",
                  value.rootPeerComponent
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$7(appUuid, input, param) {
  return {
          query: ppx_printed_query$8,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "input",
                    input !== undefined ? json_of_CustomAppCodeInput(Caml_option.valFromOption(input)) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$8
        };
}

function makeWithVariables$7(variables) {
  var appUuid = variables.appUuid;
  var input = variables.input;
  return {
          query: ppx_printed_query$8,
          variables: Js_dict.fromArray([
                  [
                    "appUuid",
                    appUuid
                  ],
                  [
                    "input",
                    input !== undefined ? json_of_CustomAppCodeInput(Caml_option.valFromOption(input)) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$8
        };
}

function makeVariables$7(appUuid, input, param) {
  return Js_dict.fromArray([
                [
                  "appUuid",
                  appUuid
                ],
                [
                  "input",
                  input !== undefined ? json_of_CustomAppCodeInput(Caml_option.valFromOption(input)) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$7(graphql_ppx_use_json_variables_fn, appUuid, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "appUuid",
                      appUuid
                    ],
                    [
                      "input",
                      input !== undefined ? json_of_CustomAppCodeInput(Caml_option.valFromOption(input)) : null
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$7 = [
  parse$8,
  ppx_printed_query$8,
  definition_2$7
];

function ret_type$8(f) {
  return {};
}

var MT_Ret$8 = {};

function useMutation$3(appUuid) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$7);
  var mutation = match[0];
  return function (input) {
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$7(appUuid, Belt_Option.map(input, CustomAppCodeGQL$DraftbitBuilder.inputToGraphql), undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), (
                input === undefined ? "Removed" : "Saved"
              ) + " custom App.js code", undefined, undefined, undefined, undefined, undefined, undefined, "Something went wrong, please try again.", "Updated custom provider", undefined, undefined, undefined);
  };
}

var SaveCustomAppCode = {
  ppx_printed_query: ppx_printed_query$8,
  query: ppx_printed_query$8,
  parse: parse$8,
  json_of_ImportInput: json_of_ImportInput,
  json_of_CustomAppCodeInput: json_of_CustomAppCodeInput,
  make: make$7,
  makeWithVariables: makeWithVariables$7,
  makeVariables: makeVariables$7,
  definition: definition$7,
  ret_type: ret_type$8,
  MT_Ret: MT_Ret$8,
  useMutation: useMutation$3
};

var ppx_printed_query$9 = "mutation CreateGlobalVariable($input: AddGlobalVariableInput!)  {\n__typename\naddGlobalVariable(input: $input)  {\n__typename\n..." + GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.name + "   \n}\n\n}\n" + GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.query;

function parse$9(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "addGlobalVariable");
  return {
          addGlobalVariable: value$2 !== undefined ? Curry._1(GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.parse, Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field addGlobalVariable on type Mutation is missing")
        };
}

function json_of_GlobalVariableKind(value) {
  if (value === "STATIC") {
    return "STATIC";
  } else {
    return "PERSISTED";
  }
}

function json_of_AddGlobalVariableInput(value) {
  var v = value.kind;
  var v$1 = value.prodValue;
  return Js_dict.fromArray([
                [
                  "appUuid",
                  value.appUuid
                ],
                [
                  "fallbackToDevValue",
                  value.fallbackToDevValue
                ],
                [
                  "kind",
                  v !== undefined ? json_of_GlobalVariableKind(v) : null
                ],
                [
                  "name",
                  value.name
                ],
                [
                  "prodValue",
                  v$1 !== undefined ? Caml_option.valFromOption(v$1) : null
                ],
                [
                  "value",
                  value.value
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$8(input, param) {
  return {
          query: ppx_printed_query$9,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_AddGlobalVariableInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$9
        };
}

function makeWithVariables$8(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$9,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_AddGlobalVariableInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$9
        };
}

function makeVariables$8(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_AddGlobalVariableInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$8(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_AddGlobalVariableInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$8 = [
  parse$9,
  ppx_printed_query$9,
  definition_2$8
];

function ret_type$9(f) {
  return {};
}

var MT_Ret$9 = {};

function useMutation$4(appUuid) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, (function (param) {
          return [ApolloHooks$DraftbitBuilder.toQueryObj(make(appUuid, undefined))];
        }), undefined, undefined, undefined, undefined, undefined, definition$8);
  var mutation = match[0];
  return function (name, value, prodValue, kind, fallbackToDevValue) {
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables$8({
                            appUuid: appUuid,
                            name: name,
                            value: JsonUtils$DraftbitBuilder.convertToMagicNull(value),
                            prodValue: prodValue,
                            kind: kind,
                            fallbackToDevValue: fallbackToDevValue
                          }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (d) {
                  return d.addGlobalVariable;
                }), "Created new variable " + name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  };
}

var CreateGlobalVariable = {
  ppx_printed_query: ppx_printed_query$9,
  query: ppx_printed_query$9,
  parse: parse$9,
  json_of_GlobalVariableKind: json_of_GlobalVariableKind,
  json_of_AddGlobalVariableInput: json_of_AddGlobalVariableInput,
  make: make$8,
  makeWithVariables: makeWithVariables$8,
  makeVariables: makeVariables$8,
  definition: definition$8,
  ret_type: ret_type$9,
  MT_Ret: MT_Ret$9,
  useMutation: useMutation$4
};

var ppx_printed_query$10 = "mutation UpdateGlobalVariable($input: UpdateGlobalVariableInput!)  {\n__typename\nupdateGlobalVariable(input: $input)  {\n__typename\n..." + GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.name + "   \n}\n\n}\n" + GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.query;

function parse$10(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateGlobalVariable");
  return {
          updateGlobalVariable: value$2 !== undefined ? Curry._1(GlobalVariable$DraftbitBuilder.GQL.GlobalVariableFragment.GlobalVariableFields.parse, Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field updateGlobalVariable on type Mutation is missing")
        };
}

function json_of_UpdateGlobalVariableInput(value) {
  var v = value.prodValue;
  return Js_dict.fromArray([
                [
                  "fallbackToDevValue",
                  value.fallbackToDevValue
                ],
                [
                  "name",
                  value.name
                ],
                [
                  "prodValue",
                  v !== undefined ? Caml_option.valFromOption(v) : null
                ],
                [
                  "uuid",
                  value.uuid
                ],
                [
                  "value",
                  value.value
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$9(input, param) {
  return {
          query: ppx_printed_query$10,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateGlobalVariableInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$10
        };
}

function makeWithVariables$9(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$10,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateGlobalVariableInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$10
        };
}

function makeVariables$9(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_UpdateGlobalVariableInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$9(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_UpdateGlobalVariableInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$9 = [
  parse$10,
  ppx_printed_query$10,
  definition_2$9
];

function ret_type$10(f) {
  return {};
}

var MT_Ret$10 = {};

function useMutation$5(appUuid) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, (function (param) {
          return [ApolloHooks$DraftbitBuilder.toQueryObj(make(appUuid, undefined))];
        }), undefined, undefined, undefined, undefined, undefined, definition$9);
  var mutation = match[0];
  return function (globalVariable, name, value, prodValue, fallbackToDevValue) {
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables$9({
                            uuid: globalVariable.uuid,
                            name: name,
                            value: JsonUtils$DraftbitBuilder.convertToMagicNull(value),
                            prodValue: prodValue,
                            fallbackToDevValue: fallbackToDevValue
                          }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (d) {
                  return d.updateGlobalVariable;
                }), globalVariable.name !== name ? "Renamed variable " + globalVariable.name + " to " + name : (
                  Caml_obj.caml_notequal(globalVariable.value, value) ? "Updated value of " + name : (
                      Caml_obj.caml_notequal(globalVariable.prodValue, prodValue) ? "Updated production value of " + name : (
                          globalVariable.fallbackToDevValue && !fallbackToDevValue ? "Disabled dev fallback behavior for " + name : (
                              !globalVariable.fallbackToDevValue && fallbackToDevValue ? "Enabled dev fallback behavior for " + name : "Updated variable " + name
                            )
                        )
                    )
                ), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  };
}

var UpdateGlobalVariable = {
  ppx_printed_query: ppx_printed_query$10,
  query: ppx_printed_query$10,
  parse: parse$10,
  json_of_UpdateGlobalVariableInput: json_of_UpdateGlobalVariableInput,
  make: make$9,
  makeWithVariables: makeWithVariables$9,
  makeVariables: makeVariables$9,
  definition: definition$9,
  ret_type: ret_type$10,
  MT_Ret: MT_Ret$10,
  useMutation: useMutation$5
};

var ppx_printed_query$11 = "mutation RemoveGlobalVariable($globalVariableUuid: ID!)  {\n__typename\nremoveGlobalVariable(uuid: $globalVariableUuid)  \n}\n";

function parse$11(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "removeGlobalVariable");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field removeGlobalVariable on type Mutation is missing");
  }
  return {
          removeGlobalVariable: tmp
        };
}

function make$10(globalVariableUuid, param) {
  return {
          query: ppx_printed_query$11,
          variables: Js_dict.fromArray([[
                    "globalVariableUuid",
                    globalVariableUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$11
        };
}

function makeWithVariables$10(variables) {
  var globalVariableUuid = variables.globalVariableUuid;
  return {
          query: ppx_printed_query$11,
          variables: Js_dict.fromArray([[
                    "globalVariableUuid",
                    globalVariableUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$11
        };
}

function makeVariables$10(globalVariableUuid, param) {
  return Js_dict.fromArray([[
                  "globalVariableUuid",
                  globalVariableUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$10(graphql_ppx_use_json_variables_fn, globalVariableUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "globalVariableUuid",
                      globalVariableUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$10 = [
  parse$11,
  ppx_printed_query$11,
  definition_2$10
];

function ret_type$11(f) {
  return {};
}

var MT_Ret$11 = {};

function useMutation$6(appUuid) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, (function (param) {
          return [ApolloHooks$DraftbitBuilder.toQueryObj(make(appUuid, undefined))];
        }), undefined, undefined, undefined, undefined, undefined, definition$10);
  var mutation = match[0];
  return function (name, globalVariableUuid) {
    return ApolloUtils$DraftbitBuilder.Mutation.track(Curry._7(mutation, Caml_option.some(makeVariables$10(globalVariableUuid, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (d) {
                  return d.removeGlobalVariable;
                }), "Removed " + name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  };
}

var RemoveGlobalVariable = {
  ppx_printed_query: ppx_printed_query$11,
  query: ppx_printed_query$11,
  parse: parse$11,
  make: make$10,
  makeWithVariables: makeWithVariables$10,
  makeVariables: makeVariables$10,
  definition: definition$10,
  ret_type: ret_type$11,
  MT_Ret: MT_Ret$11,
  useMutation: useMutation$6
};

var ppx_printed_query$12 = "mutation RebuildAllScreens($appUuid: ID!)  {\n__typename\nrebuildAllScreens(appUuid: $appUuid)  {\n__typename\nuuid  \ncode  \nwebPreviewCode  \n}\n\n}\n";

function parse$12(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "rebuildAllScreens");
  return {
          rebuildAllScreens: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "uuid");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field uuid on type Screen is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "code");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field code on type Screen is missing");
                  }
                  var value$8 = Js_dict.get(value$1, "webPreviewCode");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field webPreviewCode on type Screen is missing");
                  }
                  return {
                          uuid: tmp,
                          code: tmp$1,
                          webPreviewCode: tmp$2
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field rebuildAllScreens on type Mutation is missing")
        };
}

function make$11(appUuid, param) {
  return {
          query: ppx_printed_query$12,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$12
        };
}

function makeWithVariables$11(variables) {
  var appUuid = variables.appUuid;
  return {
          query: ppx_printed_query$12,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$12
        };
}

function makeVariables$11(appUuid, param) {
  return Js_dict.fromArray([[
                  "appUuid",
                  appUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$11(graphql_ppx_use_json_variables_fn, appUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "appUuid",
                      appUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$11 = [
  parse$12,
  ppx_printed_query$12,
  definition_2$11
];

function ret_type$12(f) {
  return {};
}

var MT_Ret$12 = {};

function useMutation$7(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$11);
  var mutation = match[0];
  return function (appUuid, onData, onError, param) {
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$11(appUuid, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), "Rebuilt all screens in app " + appUuid, undefined, undefined, undefined, undefined, onData, onError, undefined, undefined, undefined, undefined, undefined);
  };
}

var RebuildScreens = {
  ppx_printed_query: ppx_printed_query$12,
  query: ppx_printed_query$12,
  parse: parse$12,
  make: make$11,
  makeWithVariables: makeWithVariables$11,
  makeVariables: makeVariables$11,
  definition: definition$11,
  ret_type: ret_type$12,
  MT_Ret: MT_Ret$12,
  useMutation: useMutation$7
};

var ppx_printed_query$13 = "mutation CreateCommit($input: CreateCommitInput!)  {\n__typename\ncreateCommit(input: $input)  {\n__typename\nuuid  \n}\n\n}\n";

function parse$13(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createCommit");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    tmp = {
      uuid: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field createCommit on type Mutation is missing");
  }
  return {
          createCommit: tmp
        };
}

function json_of_PublicationEnvironment$1(value) {
  if (value === "Production") {
    return "Production";
  } else {
    return "Development";
  }
}

function json_of_CreateCommitInput(value) {
  return Js_dict.fromArray([
                [
                  "appUuid",
                  value.appUuid
                ],
                [
                  "env",
                  json_of_PublicationEnvironment$1(value.env)
                ],
                [
                  "message",
                  value.message
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$12(input, param) {
  return {
          query: ppx_printed_query$13,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CreateCommitInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$13
        };
}

function makeWithVariables$12(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$13,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CreateCommitInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$13
        };
}

function makeVariables$12(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_CreateCommitInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$12(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_CreateCommitInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$12 = [
  parse$13,
  ppx_printed_query$13,
  definition_2$12
];

function ret_type$13(f) {
  return {};
}

var MT_Ret$13 = {};

function useMutation$8(param) {
  var match = Router$DraftbitBuilder.getParams(undefined);
  var appUuid = match.appUuid;
  var match$1 = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$12);
  var mutation = match$1[0];
  return function (message, env, onData, onError) {
    ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$12({
                          appUuid: appUuid,
                          message: message,
                          env: env
                        }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                return prim[0];
              })), undefined, undefined, undefined, undefined, Caml_option.some(Js_dict.fromArray([[
                    "uuid",
                    appUuid
                  ]])), onData, onError, undefined, "Created commit", undefined, undefined, undefined);
    
  };
}

var CreateCommit = {
  ppx_printed_query: ppx_printed_query$13,
  query: ppx_printed_query$13,
  parse: parse$13,
  json_of_PublicationEnvironment: json_of_PublicationEnvironment$1,
  json_of_CreateCommitInput: json_of_CreateCommitInput,
  make: make$12,
  makeWithVariables: makeWithVariables$12,
  makeVariables: makeVariables$12,
  definition: definition$12,
  ret_type: ret_type$13,
  MT_Ret: MT_Ret$13,
  useMutation: useMutation$8
};

var ppx_printed_query$14 = "mutation AdminUpdateBuildVersion($input: UpdateBuildVersionInput!)  {\n__typename\nadminUpdateBuildVersion(input: $input)  {\n__typename\nuuid  \nconfig  \nbuildConfig  \n}\n\n}\n";

function parse$14(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "adminUpdateBuildVersion");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "config");
    var value$8 = Js_dict.get(value$3, "buildConfig");
    var tmp$2;
    if (value$8 !== undefined) {
      var value$9 = Caml_option.valFromOption(value$8);
      var match = Js_json.decodeNull(value$9);
      tmp$2 = match !== undefined ? undefined : Caml_option.some(value$9);
    } else {
      tmp$2 = undefined;
    }
    tmp = {
      uuid: tmp$1,
      config: value$7 !== undefined ? Caml_option.valFromOption(value$7) : Js_exn.raiseError("graphql_ppx: Field config on type App is missing"),
      buildConfig: tmp$2
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field adminUpdateBuildVersion on type Mutation is missing");
  }
  return {
          adminUpdateBuildVersion: tmp
        };
}

function json_of_UpdateBuildVersionInput(value) {
  var v = value.explicitNumber;
  return Js_dict.fromArray([
                [
                  "appUuid",
                  value.appUuid
                ],
                [
                  "explicitNumber",
                  v !== undefined ? v : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$13(input, param) {
  return {
          query: ppx_printed_query$14,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateBuildVersionInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$14
        };
}

function makeWithVariables$13(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$14,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateBuildVersionInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$14
        };
}

function makeVariables$13(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_UpdateBuildVersionInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$13(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_UpdateBuildVersionInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$13 = [
  parse$14,
  ppx_printed_query$14,
  definition_2$13
];

function ret_type$14(f) {
  return {};
}

var MT_Ret$14 = {};

function useMutation$9(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$13);
  var mutation = match[0];
  return function (appUuid, explicitNumber, onData, onError) {
    ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$13({
                          appUuid: appUuid,
                          explicitNumber: explicitNumber
                        }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined), (function (prim) {
                return prim[0];
              })), "App version number updated", undefined, undefined, undefined, Caml_option.some(Js_dict.fromArray([[
                    "uuid",
                    appUuid
                  ]])), onData, onError, undefined, "Admin: Updated app version number", undefined, undefined, undefined);
    
  };
}

var AdminUpdateBuildVersion = {
  ppx_printed_query: ppx_printed_query$14,
  query: ppx_printed_query$14,
  parse: parse$14,
  json_of_UpdateBuildVersionInput: json_of_UpdateBuildVersionInput,
  make: make$13,
  makeWithVariables: makeWithVariables$13,
  makeVariables: makeVariables$13,
  definition: definition$13,
  ret_type: ret_type$14,
  MT_Ret: MT_Ret$14,
  useMutation: useMutation$9
};

var GQL = {
  GlobalVariableFragment: undefined,
  AppFragment: AppFragment,
  Get: Get,
  GetMostRecent: GetMostRecent,
  modifyAppInCache: modifyAppInCache,
  GetBillingPlan: GetBillingPlan,
  GetNumCollaborators: GetNumCollaborators,
  GetExpoVersion: GetExpoVersion,
  GetUseExpoGo: GetUseExpoGo,
  GetLastCommit: GetLastCommit,
  TemplateApps: TemplateApps,
  Update: Update,
  Export: Export,
  SaveCustomPackages: SaveCustomPackages,
  SaveCustomAppCode: SaveCustomAppCode,
  CreateGlobalVariable: CreateGlobalVariable,
  UpdateGlobalVariable: UpdateGlobalVariable,
  RemoveGlobalVariable: RemoveGlobalVariable,
  RebuildScreens: RebuildScreens,
  CreateCommit: CreateCommit,
  AdminUpdateBuildVersion: AdminUpdateBuildVersion
};

export {
  customPackage_encode ,
  customPackage_decode ,
  offlineModeTypeToJs ,
  offlineModeTypeFromJs ,
  offlineModeType_encode ,
  offlineModeType_decode ,
  stateToJs ,
  stateFromJs ,
  state_encode ,
  state_decode ,
  __typename ,
  uuid ,
  name ,
  version ,
  updatedAt ,
  createdAt ,
  iconUrl ,
  numScreens ,
  readOnly ,
  showPwaWrapper ,
  config ,
  buildConfig ,
  googleServiceConfig ,
  appJsonOverride ,
  linkingConfiguration ,
  githubToken ,
  githubOwner ,
  githubRepo ,
  githubBranch ,
  segmentKey ,
  sentryData ,
  revenueCatData ,
  disabledIntegrations ,
  expoVersion ,
  useExpoGo ,
  notificationsConfig ,
  platformBuildType ,
  lastCommitAt ,
  customPackages ,
  globalVariables ,
  screens ,
  awsAmplifyConfig ,
  offlineModeType ,
  offlineModeMessage ,
  state ,
  workspace ,
  coverImageUrl ,
  expoAccount ,
  customAppCode ,
  openAiApiKey ,
  numberOfRemainingAiImageGeneration ,
  publishedSnackId ,
  publishedExpoVersion ,
  lastPublishDate ,
  description ,
  backupDate ,
  toIntegrationsApp ,
  getIntegrations ,
  hasIntegration ,
  getActiveIntegrations ,
  getBillingPlan ,
  getNumCollaborators ,
  getExpoVersion ,
  getUseExpoGo ,
  getFirstScreenUuid ,
  getGlobalVariableByUuid ,
  getGlobalVariableByName ,
  getAndroidPublicationSettings ,
  getIosPublicationSettings ,
  GQL ,
  
}
/* Expo-DraftbitBuilder Not a pure module */
