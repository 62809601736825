// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as Snack$DraftbitBuilder from "./Snack.bs.js";
import * as Router$DraftbitBuilder from "./Router.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as ApolloUtils$DraftbitBuilder from "./utilities/ApolloUtils.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./utilities/PromiseUtils.bs.js";
import * as ComponentUsage$DraftbitBuilder from "./ComponentUsage.bs.js";

var ppx_printed_query = "fragment CustomFileFields on CustomFile   {\n__typename\nuuid  \nname  \nlocation  \nfileType  \ndescription  \ncontents  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type CustomFile, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field_uuid = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type CustomFile is missing");
  }
  var value$6 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_name = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type CustomFile is missing");
  }
  var value$9 = Js_dict.get(value$2, "location");
  var field_location;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    if (value$11 !== undefined) {
      switch (value$11) {
        case "Custom" :
            field_location = "Custom";
            break;
        case "TopLevel" :
            field_location = "TopLevel";
            break;
        default:
          field_location = Js_exn.raiseError("graphql_ppx: Unknown enum variant for Location: " + value$11);
      }
    } else {
      field_location = Js_exn.raiseError("graphql_ppx: Expected enum value for Location, got " + JSON.stringify(value$10));
    }
  } else {
    field_location = Js_exn.raiseError("graphql_ppx: Field location on type CustomFile is missing");
  }
  var value$12 = Js_dict.get(value$2, "fileType");
  var field_fileType;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeString(value$13);
    if (value$14 !== undefined) {
      switch (value$14) {
        case "js" :
            field_fileType = "js";
            break;
        case "json" :
            field_fileType = "json";
            break;
        case "md" :
            field_fileType = "md";
            break;
        case "ts" :
            field_fileType = "ts";
            break;
        case "txt" :
            field_fileType = "txt";
            break;
        default:
          field_fileType = Js_exn.raiseError("graphql_ppx: Unknown enum variant for FileType: " + value$14);
      }
    } else {
      field_fileType = Js_exn.raiseError("graphql_ppx: Expected enum value for FileType, got " + JSON.stringify(value$13));
    }
  } else {
    field_fileType = Js_exn.raiseError("graphql_ppx: Field fileType on type CustomFile is missing");
  }
  var value$15 = Js_dict.get(value$2, "description");
  var field_description;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var match = Js_json.decodeNull(value$16);
    if (match !== undefined) {
      field_description = undefined;
    } else {
      var value$17 = Js_json.decodeString(value$16);
      field_description = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
    }
  } else {
    field_description = undefined;
  }
  var value$18 = Js_dict.get(value$2, "contents");
  var field_contents;
  if (value$18 !== undefined) {
    var value$19 = Caml_option.valFromOption(value$18);
    var value$20 = Js_json.decodeString(value$19);
    field_contents = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
  } else {
    field_contents = Js_exn.raiseError("graphql_ppx: Field contents on type CustomFile is missing");
  }
  return {
          uuid: field_uuid,
          name: field_name,
          location: field_location,
          fileType: field_fileType,
          description: field_description,
          contents: field_contents
        };
}

var name = "CustomFileFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var CustomFileFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  CustomFileFields: CustomFileFields
};

var ppx_printed_query$1 = "fragment CustomFileUsageFields on CustomFile   {\n__typename\n__typename  \nuuid  \ncomponentsUsingCustomFile  {\n__typename\n..." + ComponentUsage$DraftbitBuilder.GQL.Fragment.ComponentUsageFields.name + "   \n}\n\n}\n" + ComponentUsage$DraftbitBuilder.GQL.Fragment.ComponentUsageFields.query;

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "__typename");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeString(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field __typename on type CustomFile is missing");
  }
  var value$5 = Js_dict.get(value$1, "uuid");
  var tmp$1;
  if (value$5 !== undefined) {
    var value$6 = Caml_option.valFromOption(value$5);
    var value$7 = Js_json.decodeString(value$6);
    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
  } else {
    tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type CustomFile is missing");
  }
  var value$8 = Js_dict.get(value$1, "componentsUsingCustomFile");
  return {
          __typename: tmp,
          uuid: tmp$1,
          componentsUsingCustomFile: value$8 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$8))).map(function (value) {
                  return Curry._1(ComponentUsage$DraftbitBuilder.GQL.Fragment.ComponentUsageFields.parse, value);
                }) : Js_exn.raiseError("graphql_ppx: Field componentsUsingCustomFile on type CustomFile is missing")
        };
}

var name$1 = "CustomFileUsageFields";

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var CustomFileUsageFields = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: name$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var UsageFragment = {
  CustomFileUsageFields: CustomFileUsageFields
};

var ppx_printed_query$2 = "query CustomFilesUsage($customFileUuid: ID!)  {\n__typename\ncustomFile(customFileUuid: $customFileUuid)  {\n__typename\n...CustomFileUsageFields   \n}\n\n}\n" + ppx_printed_query$1;

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "customFile");
  return {
          customFile: value$2 !== undefined ? parse$1(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field customFile on type Query is missing")
        };
}

function make(customFileUuid, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "customFileUuid",
                    customFileUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables(variables) {
  var customFileUuid = variables.customFileUuid;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "customFileUuid",
                    customFileUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables(customFileUuid, param) {
  return Js_dict.fromArray([[
                  "customFileUuid",
                  customFileUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, customFileUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "customFileUuid",
                      customFileUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$2,
  ppx_printed_query$2,
  definition_2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

function useQuery(customFileUuid, skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables(customFileUuid, undefined)), undefined, undefined, undefined, skip || !Uuid$DraftbitBuilder.isUuid(customFileUuid), undefined, undefined, definition);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.customFile.componentsUsingCustomFile;
              }));
}

function refetch(customFileUuid) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make(customFileUuid, undefined));
}

var GetUsage = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2,
  useQuery: useQuery,
  refetch: refetch
};

var ppx_printed_query$3 = "query CustomFilesByApp($appUuid: ID!)  {\n__typename\napp(appUuid: $appUuid)  {\n__typename\n__typename  \nuuid  \ncustomFiles  {\n__typename\n...CustomFileFields   \n}\n\n}\n\n}\nfragment CustomFileFields on CustomFile   {\n__typename\nuuid  \nname  \nlocation  \nfileType  \ndescription  \ncontents  \n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "app");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "__typename");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "uuid");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeString(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$10 = Js_dict.get(value$3, "customFiles");
    tmp = {
      __typename: tmp$1,
      uuid: tmp$2,
      customFiles: value$10 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$10))).map(parse) : Js_exn.raiseError("graphql_ppx: Field customFiles on type App is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field app on type Query is missing");
  }
  return {
          app: tmp
        };
}

function make$1(appUuid, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$1(variables) {
  var appUuid = variables.appUuid;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "appUuid",
                    appUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$1(appUuid, param) {
  return Js_dict.fromArray([[
                  "appUuid",
                  appUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, appUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "appUuid",
                      appUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$1
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

function useQuery$1(appUuid, skipOpt, param) {
  var skip = skipOpt !== undefined ? skipOpt : false;
  var match = ApolloHooks$DraftbitBuilder.useQuery(undefined, Caml_option.some(makeVariables$1(appUuid, undefined)), undefined, undefined, undefined, skip || !Uuid$DraftbitBuilder.isUuid(appUuid), undefined, undefined, definition$1);
  return ApolloUtils$DraftbitBuilder.Query.map(match[0], (function (data) {
                return data.app.customFiles;
              }));
}

function refetch$1(appUuid) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make$1(appUuid, undefined));
}

var ListByApp = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3,
  useQuery: useQuery$1,
  refetch: refetch$1
};

var ppx_printed_query$4 = "mutation CreateCustomFile($input: CreateCustomFileInput!)  {\n__typename\ncreateCustomFile(input: $input)  {\n__typename\nuuid  \ncustomFiles  {\n__typename\n...CustomFileFields   \n}\n\n}\n\n}\nfragment CustomFileFields on CustomFile   {\n__typename\nuuid  \nname  \nlocation  \nfileType  \ndescription  \ncontents  \n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "createCustomFile");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "customFiles");
    tmp = {
      uuid: tmp$1,
      customFiles: value$7 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$7))).map(parse) : Js_exn.raiseError("graphql_ppx: Field customFiles on type App is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field createCustomFile on type Mutation is missing");
  }
  return {
          createCustomFile: tmp
        };
}

function json_of_FileType(value) {
  if (value === "js") {
    return "js";
  } else if (value === "md") {
    return "md";
  } else if (value === "ts") {
    return "ts";
  } else if (value === "txt") {
    return "txt";
  } else {
    return "json";
  }
}

function json_of_Location(value) {
  if (value === "TopLevel") {
    return "TopLevel";
  } else {
    return "Custom";
  }
}

function json_of_CreateCustomFileInput(value) {
  var v = value.description;
  return Js_dict.fromArray([
                [
                  "appUuid",
                  value.appUuid
                ],
                [
                  "contents",
                  value.contents
                ],
                [
                  "description",
                  v !== undefined ? v : null
                ],
                [
                  "fileType",
                  json_of_FileType(value.fileType)
                ],
                [
                  "location",
                  json_of_Location(value.location)
                ],
                [
                  "name",
                  value.name
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$2(input, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CreateCustomFileInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeWithVariables$2(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_CreateCustomFileInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeVariables$2(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_CreateCustomFileInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_CreateCustomFileInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$2
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

function useMutation(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$2);
  var mutation = match[0];
  var match$1 = Router$DraftbitBuilder.usePublicationEnvironment(undefined);
  var env = match$1[0];
  return function (appUuid, contents, name, $$location, fileType, description, onData) {
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$2({
                                appUuid: appUuid,
                                name: name,
                                location: $$location,
                                fileType: fileType,
                                contents: contents,
                                description: description
                              }, undefined)), undefined, (function (param) {
                          return [Curry._3(Snack$DraftbitBuilder.GQL.GetFiles.refetch, appUuid, env, undefined)];
                        }), undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), "Created New File `" + name + "`", undefined, undefined, undefined, undefined, (function (data) {
                  return Curry._1(onData, data.createCustomFile);
                }), undefined, "There was a problem creating your custom file", "Created Custom File", undefined, undefined, undefined);
  };
}

var Create = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  json_of_FileType: json_of_FileType,
  json_of_Location: json_of_Location,
  json_of_CreateCustomFileInput: json_of_CreateCustomFileInput,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4,
  useMutation: useMutation
};

var ppx_printed_query$5 = "mutation UpdateCustomFile($input: UpdateCustomFileInput!)  {\n__typename\nupdateCustomFile(input: $input)  {\n__typename\n...CustomFileFields   \n}\n\n}\nfragment CustomFileFields on CustomFile   {\n__typename\nuuid  \nname  \nlocation  \nfileType  \ndescription  \ncontents  \n}\n";

function parse$5(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "updateCustomFile");
  return {
          updateCustomFile: value$2 !== undefined ? parse(Caml_option.valFromOption(value$2)) : Js_exn.raiseError("graphql_ppx: Field updateCustomFile on type Mutation is missing")
        };
}

function json_of_FileType$1(value) {
  if (value === "js") {
    return "js";
  } else if (value === "md") {
    return "md";
  } else if (value === "ts") {
    return "ts";
  } else if (value === "txt") {
    return "txt";
  } else {
    return "json";
  }
}

function json_of_Location$1(value) {
  if (value === "TopLevel") {
    return "TopLevel";
  } else {
    return "Custom";
  }
}

function json_of_UpdateCustomFileInput(value) {
  var v = value.description;
  return Js_dict.fromArray([
                [
                  "contents",
                  value.contents
                ],
                [
                  "description",
                  v !== undefined ? v : null
                ],
                [
                  "fileType",
                  json_of_FileType$1(value.fileType)
                ],
                [
                  "location",
                  json_of_Location$1(value.location)
                ],
                [
                  "name",
                  value.name
                ],
                [
                  "uuid",
                  value.uuid
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$3(input, param) {
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateCustomFileInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeWithVariables$3(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_UpdateCustomFileInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeVariables$3(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_UpdateCustomFileInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_UpdateCustomFileInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$5,
  ppx_printed_query$5,
  definition_2$3
];

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

function useMutation$1(param) {
  var match = Router$DraftbitBuilder.getParams(undefined);
  var appUuid = match.appUuid;
  var match$1 = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$3);
  var mutation = match$1[0];
  var match$2 = Router$DraftbitBuilder.usePublicationEnvironment(undefined);
  var env = match$2[0];
  return function (uuid, contents, name, $$location, fileType, description, param) {
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$3({
                                uuid: uuid,
                                name: name,
                                location: $$location,
                                fileType: fileType,
                                contents: contents,
                                description: description
                              }, undefined)), undefined, (function (param) {
                          return [Curry._3(Snack$DraftbitBuilder.GQL.GetFiles.refetch, appUuid, env, undefined)];
                        }), undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), "Updated `" + name + "`", undefined, undefined, undefined, undefined, undefined, undefined, "There was a problem updating your custom file", "Updated Custom File", undefined, undefined, undefined);
  };
}

var Update = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  json_of_FileType: json_of_FileType$1,
  json_of_Location: json_of_Location$1,
  json_of_UpdateCustomFileInput: json_of_UpdateCustomFileInput,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5,
  useMutation: useMutation$1
};

var ppx_printed_query$6 = "mutation DeleteCustomFile($input: DeleteCustomFileInput!)  {\n__typename\ndeleteCustomFile(input: $input)  {\n__typename\nuuid  \ncustomFiles  {\n__typename\n...CustomFileFields   \n}\n\n}\n\n}\nfragment CustomFileFields on CustomFile   {\n__typename\nuuid  \nname  \nlocation  \nfileType  \ndescription  \ncontents  \n}\n";

function parse$6(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "deleteCustomFile");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "uuid");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field uuid on type App is missing");
    }
    var value$7 = Js_dict.get(value$3, "customFiles");
    tmp = {
      uuid: tmp$1,
      customFiles: value$7 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$7))).map(parse) : Js_exn.raiseError("graphql_ppx: Field customFiles on type App is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field deleteCustomFile on type Mutation is missing");
  }
  return {
          deleteCustomFile: tmp
        };
}

function json_of_DeleteCustomFileInput(value) {
  return Js_dict.fromArray([
                [
                  "appUuid",
                  value.appUuid
                ],
                [
                  "customFileUuid",
                  value.customFileUuid
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$4(input, param) {
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_DeleteCustomFileInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeWithVariables$4(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query$6,
          variables: Js_dict.fromArray([[
                    "input",
                    json_of_DeleteCustomFileInput(input)
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$6
        };
}

function makeVariables$4(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_DeleteCustomFileInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_DeleteCustomFileInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$6,
  ppx_printed_query$6,
  definition_2$4
];

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

function useMutation$2(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition$4);
  var mutation = match[0];
  var match$1 = Router$DraftbitBuilder.usePublicationEnvironment(undefined);
  var env = match$1[0];
  return function (appUuid, customFile) {
    return ApolloUtils$DraftbitBuilder.Mutation.trackResult(PromiseUtils$DraftbitBuilder.map(Curry._7(mutation, Caml_option.some(makeVariables$4({
                                appUuid: appUuid,
                                customFileUuid: customFile.uuid
                              }, undefined)), undefined, (function (param) {
                          return [Curry._3(Snack$DraftbitBuilder.GQL.GetFiles.refetch, appUuid, env, undefined)];
                        }), undefined, undefined, undefined, undefined), (function (prim) {
                      return prim[0];
                    })), "Deleted `" + customFile.name + "`", undefined, undefined, undefined, undefined, undefined, undefined, "There was a problem deleting your custom file", "Deleted Custom File", undefined, undefined, undefined);
  };
}

var Delete = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  json_of_DeleteCustomFileInput: json_of_DeleteCustomFileInput,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6,
  useMutation: useMutation$2
};

export {
  Fragment ,
  UsageFragment ,
  GetUsage ,
  ListByApp ,
  Create ,
  Update ,
  Delete ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
