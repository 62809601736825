// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as ApolloHooks$DraftbitBuilder from "../bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as PromiseUtils$DraftbitBuilder from "./PromiseUtils.bs.js";

function track($$event) {
  window.analytics.track($$event);
  
}

function trackWithProperties($$event, properties) {
  window.analytics.track($$event, properties);
  
}

function identify(userId, properties) {
  window.analytics.identify(userId, properties);
  
}

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "proxyAnalyticsEvent");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeBoolean(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field proxyAnalyticsEvent on type Mutation is missing");
  }
  return {
          proxyAnalyticsEvent: tmp
        };
}

function json_of_AnalyticsProxyActionType(value) {
  if (value === "TRACK") {
    return "TRACK";
  } else {
    return "IDENTIFY";
  }
}

function json_of_ProxyAnalyticsEventInput(value) {
  var v = value.event;
  var v$1 = value.properties;
  return Js_dict.fromArray([
                [
                  "event",
                  v !== undefined ? "COMPLETED_ONBOARDING" : null
                ],
                [
                  "properties",
                  v$1 !== undefined ? Caml_option.valFromOption(v$1) : null
                ],
                [
                  "type",
                  json_of_AnalyticsProxyActionType(value.type)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function makeVariables(input, param) {
  return Js_dict.fromArray([[
                  "input",
                  json_of_ProxyAnalyticsEventInput(input)
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "input",
                      json_of_ProxyAnalyticsEventInput(input)
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  "mutation ProxyAnalyticsEvent($input: ProxyAnalyticsEventInput!)  {\n__typename\nproxyAnalyticsEvent(input: $input)  \n}\n",
  definition_2
];

function use(param) {
  var match = ApolloHooks$DraftbitBuilder.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition);
  var mutation = match[0];
  return function (type_Opt, eventOpt, propertiesOpt, param) {
    var type_ = type_Opt !== undefined ? type_Opt : "TRACK";
    var $$event = eventOpt !== undefined ? Caml_option.valFromOption(eventOpt) : undefined;
    var properties = propertiesOpt !== undefined ? Caml_option.valFromOption(propertiesOpt) : undefined;
    return PromiseUtils$DraftbitBuilder.ignore(Curry._7(mutation, Caml_option.some(makeVariables({
                            type: type_,
                            event: $$event,
                            properties: properties
                          }, undefined)), undefined, undefined, undefined, undefined, undefined, undefined));
  };
}

var GQL = {
  $$Proxy: {
    use: use
  }
};

export {
  track ,
  trackWithProperties ,
  identify ,
  GQL ,
  
}
/* ApolloHooks-DraftbitBuilder Not a pure module */
