// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import Tabs from "react-tabs/lib/components/Tabs";

function WL_Tabs$Tabs(Props) {
  var onSelect = Props.onSelect;
  var defaultFocus = Props.defaultFocus;
  var defaultIndex = Props.defaultIndex;
  var selectedIndex = Props.selectedIndex;
  var selectedTabClassName = Props.selectedTabClassName;
  var disabledTabClassName = Props.disabledTabClassName;
  var children = Props.children;
  var tmp = {
    className: "flex flex-col h-full",
    selectedTabClassName: Belt_Option.getWithDefault(selectedTabClassName, "bg-mono-800"),
    children: children
  };
  if (defaultFocus !== undefined) {
    tmp.defaultFocus = Caml_option.valFromOption(defaultFocus);
  }
  if (defaultIndex !== undefined) {
    tmp.defaultIndex = Caml_option.valFromOption(defaultIndex);
  }
  if (selectedIndex !== undefined) {
    tmp.selectedIndex = Caml_option.valFromOption(selectedIndex);
  }
  if (onSelect !== undefined) {
    tmp.onSelect = Caml_option.valFromOption(onSelect);
  }
  if (disabledTabClassName !== undefined) {
    tmp.disabledTabClassName = Caml_option.valFromOption(disabledTabClassName);
  }
  return React.createElement(Tabs, tmp);
}

var Tabs$1 = {
  make: WL_Tabs$Tabs
};

var iconTab = Cn.make({
      hd: "flex",
      tl: {
        hd: "w-full",
        tl: {
          hd: "justify-center",
          tl: {
            hd: "items-center",
            tl: {
              hd: "hover:text-mono-100",
              tl: {
                hd: "h-12",
                tl: {
                  hd: "border-r",
                  tl: {
                    hd: "border-mono-700/75",
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var deselectedTab = Cn.make({
      hd: "bg-mono-900",
      tl: {
        hd: "border-b",
        tl: {
          hd: "cursor-pointer",
          tl: /* [] */0
        }
      }
    });

var selectedTab = "";

var disabledTab = Cn.make({
      hd: "bg-mono-900",
      tl: {
        hd: "border-b",
        tl: {
          hd: "cursor-not-allowed",
          tl: /* [] */0
        }
      }
    });

function getTabStyles(selected, disabled) {
  return Cn.make({
              hd: iconTab,
              tl: {
                hd: Cn.ifTrue(deselectedTab, !selected && !disabled),
                tl: {
                  hd: Cn.ifTrue(selectedTab, selected),
                  tl: {
                    hd: Cn.ifTrue(disabledTab, disabled),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Styles = {
  iconTab: iconTab,
  deselectedTab: deselectedTab,
  selectedTab: selectedTab,
  disabledTab: disabledTab,
  getTabStyles: getTabStyles
};

var Tab;

var TabList;

var TabPanel;

export {
  Tab ,
  TabList ,
  TabPanel ,
  Tabs$1 as Tabs,
  Styles ,
  
}
/* iconTab Not a pure module */
