// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Caml_splice_call from "bs-platform/lib/es6/caml_splice_call.mjs";
import * as ArrayUtils$DraftbitBuilder from "./ArrayUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "./OptionUtils.bs.js";

function keys(prim) {
  return Object.keys(prim);
}

function empty(prim) {
  return {};
}

function set(prim, prim$1, prim$2) {
  prim[prim$1] = prim$2;
  
}

function getOrFailwith(dict, key, message) {
  return OptionUtils$DraftbitBuilder.getOrFailwith(Js_dict.get(dict, key), message);
}

function keyBy(arr, toKey) {
  return Js_dict.fromArray(arr.map(function (x) {
                  return [
                          Curry._1(toKey, x),
                          x
                        ];
                }));
}

function fromObjects(objects, toKey) {
  return Js_dict.fromArray(objects.map(function (o) {
                  return [
                          Curry._1(toKey, o),
                          o
                        ];
                }));
}

function has(d, k) {
  return Belt_Option.isSome(Js_dict.get(d, k));
}

function size(d) {
  return Object.keys(d).length;
}

function one(key, value) {
  return Js_dict.fromArray([[
                key,
                value
              ]]);
}

function two(param, param$1) {
  return Js_dict.fromArray([
              [
                param[0],
                param[1]
              ],
              [
                param$1[0],
                param$1[1]
              ]
            ]);
}

function add(dict, k, v) {
  return Js_dict.fromArray(Js_dict.entries(dict).concat([[
                    k,
                    v
                  ]]));
}

function addIf(dict, condition, k, v) {
  if (condition) {
    return add(dict, k, v);
  } else {
    return dict;
  }
}

function addMany(dict, keyValuePairs) {
  return Js_dict.fromArray(Js_dict.entries(dict).concat(keyValuePairs));
}

function addOpt(dict, k, v) {
  if (v !== undefined) {
    return add(dict, k, Caml_option.valFromOption(v));
  } else {
    return dict;
  }
}

function addL(k, v, dict) {
  return add(dict, k, v);
}

function remove(dict, key) {
  return Js_dict.fromArray(Js_dict.entries(dict).filter(function (param) {
                  return param[0] !== key;
                }));
}

function removeL(k, dict) {
  return remove(dict, k);
}

function removeMany(dict, keys) {
  return Js_dict.fromArray(Js_dict.entries(dict).filter(function (param) {
                  return !keys.includes(param[0]);
                }));
}

function keepWithKey(dict, f) {
  return Js_dict.fromArray(Js_dict.entries(dict).filter(function (param) {
                  return Curry._2(f, param[0], param[1]);
                }));
}

function keepByKey(dict, f) {
  return keepWithKey(dict, (function (key, _value) {
                return Curry._1(f, key);
              }));
}

function keep(dict, f) {
  return keepWithKey(dict, (function (_key, value) {
                return Curry._1(f, value);
              }));
}

function keepIf(dict, condition, f) {
  if (condition) {
    return keepWithKey(dict, (function (_key, value) {
                  return Curry._1(f, value);
                }));
  } else {
    return dict;
  }
}

function map(dict, f) {
  return Js_dict.fromArray(Js_dict.entries(dict).map(function (param) {
                  return [
                          param[0],
                          Curry._1(f, param[1])
                        ];
                }));
}

function keepMapWithKey(dict, f) {
  return Js_dict.fromArray(Belt_Array.keepMap(Js_dict.entries(dict), (function (param) {
                    var k = param[0];
                    return Belt_Option.map(Curry._2(f, k, param[1]), (function (b) {
                                  return [
                                          k,
                                          b
                                        ];
                                }));
                  })));
}

function keepMap(dict, f) {
  return Js_dict.fromArray(Belt_Array.keepMap(Js_dict.entries(dict), (function (param) {
                    var k = param[0];
                    return Belt_Option.map(Curry._1(f, param[1]), (function (b) {
                                  return [
                                          k,
                                          b
                                        ];
                                }));
                  })));
}

function mapKeys(dict, f) {
  return Js_dict.fromArray(Belt_Array.map(Js_dict.entries(dict), (function (param) {
                    return [
                            Curry._1(f, param[0]),
                            param[1]
                          ];
                  })));
}

function keepMapKeys(dict, f) {
  return Js_dict.fromArray(Belt_Array.keepMap(Js_dict.entries(dict), (function (param) {
                    var v = param[1];
                    return Belt_Option.map(Curry._1(f, param[0]), (function (k2) {
                                  return [
                                          k2,
                                          v
                                        ];
                                }));
                  })));
}

function mapWithKey(dict, f) {
  return Js_dict.fromArray(Js_dict.entries(dict).map(function (param) {
                  var k = param[0];
                  return [
                          k,
                          Curry._2(f, k, param[1])
                        ];
                }));
}

function reduce(dict, start, f) {
  return Belt_Array.reduce(Js_dict.values(dict), start, f);
}

function unionArray(dicts) {
  return Caml_splice_call.spliceApply(Object.assign, [[{}].concat(dicts)]);
}

function union2(dict1, dict2) {
  return unionArray([
              dict1,
              dict2
            ]);
}

function unionArrayWith(dicts, joiner) {
  var result = {};
  dicts.forEach(function (dict) {
        Js_dict.entries(dict).forEach(function (param) {
              var v = param[1];
              var k = param[0];
              var v$prime = Js_dict.get(result, k);
              return set(result, k, v$prime !== undefined ? Curry._2(joiner, Caml_option.valFromOption(v$prime), v) : v);
            });
        
      });
  return result;
}

function withOrderedKeys(dict) {
  return Js_dict.fromArray(ArrayUtils$DraftbitBuilder.sortBy(Js_dict.entries(dict), (function (prim) {
                    return prim[0];
                  })));
}

function updateByKey(dict, key, f) {
  var v = Js_dict.get(dict, key);
  if (v !== undefined) {
    return add(dict, key, Curry._1(f, Caml_option.valFromOption(v)));
  } else {
    return dict;
  }
}

var get = Js_dict.get;

var fromArray = Js_dict.fromArray;

var entries = Js_dict.entries;

var values = Js_dict.values;

export {
  get ,
  fromArray ,
  keys ,
  entries ,
  values ,
  empty ,
  set ,
  getOrFailwith ,
  keyBy ,
  fromObjects ,
  has ,
  size ,
  one ,
  two ,
  add ,
  addIf ,
  addMany ,
  addOpt ,
  addL ,
  remove ,
  removeL ,
  removeMany ,
  keepWithKey ,
  keepByKey ,
  keep ,
  keepIf ,
  map ,
  keepMapWithKey ,
  keepMap ,
  mapKeys ,
  keepMapKeys ,
  mapWithKey ,
  reduce ,
  unionArray ,
  union2 ,
  unionArrayWith ,
  withOrderedKeys ,
  updateByKey ,
  
}
/* ArrayUtils-DraftbitBuilder Not a pure module */
