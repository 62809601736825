// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Decco from "decco/src/Decco.bs.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.mjs";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

function isUuid(str) {
  return StringUtils$DraftbitBuilder.isMatch(str, /^\w{8}$/);
}

function shortUuid(param) {
  return Caml_array.get(Uuid.v4().split("-"), 0);
}

function t_encode(prim) {
  return prim;
}

function componentUuid_encode(v) {
  return v;
}

var componentUuid_decode = Decco.stringFromJson;

function actionUuid_encode(v) {
  return v;
}

var actionUuid_decode = Decco.stringFromJson;

function propUuid_encode(v) {
  return v;
}

var propUuid_decode = Decco.stringFromJson;

var t_decode = Decco.stringFromJson;

export {
  isUuid ,
  shortUuid ,
  t_encode ,
  t_decode ,
  componentUuid_encode ,
  componentUuid_decode ,
  actionUuid_encode ,
  actionUuid_decode ,
  propUuid_encode ,
  propUuid_decode ,
  
}
/* uuid Not a pure module */
