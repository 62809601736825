// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.mjs";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Js_option from "bs-platform/lib/es6/js_option.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as ApolloHooks$DraftbitBuilder from "./bindings/ReasonApolloHooks/ApolloHooks.bs.js";
import * as VariableType$DraftbitBuilder from "./VariableType.bs.js";

function usageComponent_encode(v) {
  return Js_dict.fromArray([
              [
                "__typename",
                Decco.stringToJson(v.__typename)
              ],
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "name",
                Decco.stringToJson(v.name)
              ],
              [
                "deletedAt",
                Decco.optionToJson((function (v) {
                        return v;
                      }), v.deletedAt)
              ]
            ]);
}

function usageComponent_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var __typename = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "__typename"), Decco.stringFromJson), {
        TAG: /* Ok */0,
        _0: "Component"
      });
  if (__typename.TAG === /* Ok */0) {
    var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
    if (uuid.TAG === /* Ok */0) {
      var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
      if (name.TAG === /* Ok */0) {
        var deletedAt = Decco.optionFromJson((function (v) {
                return {
                        TAG: /* Ok */0,
                        _0: v
                      };
              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "deletedAt"), null));
        if (deletedAt.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    __typename: __typename._0,
                    uuid: uuid._0,
                    name: name._0,
                    deletedAt: deletedAt._0
                  }
                };
        }
        var e = deletedAt._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".deletedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".name" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = uuid._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".uuid" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = __typename._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".__typename" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "__typename",
                Decco.stringToJson(v.__typename)
              ],
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "name",
                Decco.stringToJson(v.name)
              ],
              [
                "type",
                VariableType$DraftbitBuilder.t_encode(v.type)
              ],
              [
                "isExternal",
                Decco.boolToJson(v.isExternal)
              ],
              [
                "componentsUsingScreenVariable",
                Decco.arrayToJson(usageComponent_encode, v.componentsUsingScreenVariable)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var __typename = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "__typename"), null));
  if (__typename.TAG === /* Ok */0) {
    var uuid = Uuid$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "uuid"), null));
    if (uuid.TAG === /* Ok */0) {
      var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
      if (name.TAG === /* Ok */0) {
        var type_ = VariableType$DraftbitBuilder.t_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "type"), null));
        if (type_.TAG === /* Ok */0) {
          var isExternal = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isExternal"), null));
          if (isExternal.TAG === /* Ok */0) {
            var componentsUsingScreenVariable = Decco.arrayFromJson(usageComponent_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "componentsUsingScreenVariable"), null));
            if (componentsUsingScreenVariable.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        __typename: __typename._0,
                        uuid: uuid._0,
                        name: name._0,
                        type: type_._0,
                        isExternal: isExternal._0,
                        componentsUsingScreenVariable: componentsUsingScreenVariable._0
                      }
                    };
            }
            var e = componentsUsingScreenVariable._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".componentsUsingScreenVariable" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = isExternal._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".isExternal" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = type_._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".type" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".name" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = uuid._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".uuid" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = __typename._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".__typename" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function toMeta(param) {
  return {
          uuid: param.uuid,
          name: param.name,
          type: param.type
        };
}

function getLabel(param) {
  return param.name + " (" + VariableType$DraftbitBuilder.tToJs(param.type) + ")";
}

var ppx_printed_query = "fragment ScreenVariableFields on ScreenVariable   {\n__typename\n__typename  \nuuid  \nname  \nisExternal  \ntype_: type  \ncomponentsUsingScreenVariable  {\n__typename\n__typename  \nuuid  \nname  \ndeletedAt  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("graphql_ppx: Expected object of type ScreenVariable, got " + JSON.stringify(value));
  }
  var value$2 = Caml_option.valFromOption(value$1);
  var value$3 = Js_dict.get(value$2, "__typename");
  var field___typename;
  if (value$3 !== undefined) {
    var value$4 = Caml_option.valFromOption(value$3);
    var value$5 = Js_json.decodeString(value$4);
    field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
  } else {
    field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type ScreenVariable is missing");
  }
  var value$6 = Js_dict.get(value$2, "uuid");
  var field_uuid;
  if (value$6 !== undefined) {
    var value$7 = Caml_option.valFromOption(value$6);
    var value$8 = Js_json.decodeString(value$7);
    field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
  } else {
    field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type ScreenVariable is missing");
  }
  var value$9 = Js_dict.get(value$2, "name");
  var field_name;
  if (value$9 !== undefined) {
    var value$10 = Caml_option.valFromOption(value$9);
    var value$11 = Js_json.decodeString(value$10);
    field_name = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
  } else {
    field_name = Js_exn.raiseError("graphql_ppx: Field name on type ScreenVariable is missing");
  }
  var value$12 = Js_dict.get(value$2, "isExternal");
  var field_isExternal;
  if (value$12 !== undefined) {
    var value$13 = Caml_option.valFromOption(value$12);
    var value$14 = Js_json.decodeBoolean(value$13);
    field_isExternal = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$13));
  } else {
    field_isExternal = Js_exn.raiseError("graphql_ppx: Field isExternal on type ScreenVariable is missing");
  }
  var value$15 = Js_dict.get(value$2, "type_");
  var field_type_;
  if (value$15 !== undefined) {
    var value$16 = Caml_option.valFromOption(value$15);
    var value$17 = Js_json.decodeString(value$16);
    if (value$17 !== undefined) {
      switch (value$17) {
        case "ArrayArray" :
            field_type_ = "ArrayArray";
            break;
        case "Boolean" :
            field_type_ = "Boolean";
            break;
        case "BooleanArray" :
            field_type_ = "BooleanArray";
            break;
        case "Color" :
            field_type_ = "Color";
            break;
        case "Date" :
            field_type_ = "Date";
            break;
        case "DateArray" :
            field_type_ = "DateArray";
            break;
        case "Function" :
            field_type_ = "Function";
            break;
        case "Number" :
            field_type_ = "Number";
            break;
        case "NumberArray" :
            field_type_ = "NumberArray";
            break;
        case "Object" :
            field_type_ = "Object";
            break;
        case "ObjectArray" :
            field_type_ = "ObjectArray";
            break;
        case "String" :
            field_type_ = "String";
            break;
        case "StringArray" :
            field_type_ = "StringArray";
            break;
        case "Void" :
            field_type_ = "Void";
            break;
        default:
          field_type_ = Js_exn.raiseError("graphql_ppx: Unknown enum variant for VariableType: " + value$17);
      }
    } else {
      field_type_ = Js_exn.raiseError("graphql_ppx: Expected enum value for VariableType, got " + JSON.stringify(value$16));
    }
  } else {
    field_type_ = Js_exn.raiseError("graphql_ppx: Field type_ on type ScreenVariable is missing");
  }
  var value$18 = Js_dict.get(value$2, "componentsUsingScreenVariable");
  var field_componentsUsingScreenVariable = value$18 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$18))).map(function (value) {
          var value$1 = Js_json.decodeObject(value);
          if (value$1 === undefined) {
            return Js_exn.raiseError("graphql_ppx: Expected object of type Component, got " + JSON.stringify(value));
          }
          var value$2 = Caml_option.valFromOption(value$1);
          var value$3 = Js_dict.get(value$2, "__typename");
          var field___typename;
          if (value$3 !== undefined) {
            var value$4 = Caml_option.valFromOption(value$3);
            var value$5 = Js_json.decodeString(value$4);
            field___typename = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
          } else {
            field___typename = Js_exn.raiseError("graphql_ppx: Field __typename on type Component is missing");
          }
          var value$6 = Js_dict.get(value$2, "uuid");
          var field_uuid;
          if (value$6 !== undefined) {
            var value$7 = Caml_option.valFromOption(value$6);
            var value$8 = Js_json.decodeString(value$7);
            field_uuid = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
          } else {
            field_uuid = Js_exn.raiseError("graphql_ppx: Field uuid on type Component is missing");
          }
          var value$9 = Js_dict.get(value$2, "name");
          var field_name;
          if (value$9 !== undefined) {
            var value$10 = Caml_option.valFromOption(value$9);
            var value$11 = Js_json.decodeString(value$10);
            field_name = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
          } else {
            field_name = Js_exn.raiseError("graphql_ppx: Field name on type Component is missing");
          }
          var value$12 = Js_dict.get(value$2, "deletedAt");
          var field_deletedAt;
          if (value$12 !== undefined) {
            var value$13 = Caml_option.valFromOption(value$12);
            var match = Js_json.decodeNull(value$13);
            field_deletedAt = match !== undefined ? undefined : Caml_option.some(value$13);
          } else {
            field_deletedAt = undefined;
          }
          return {
                  __typename: field___typename,
                  uuid: field_uuid,
                  name: field_name,
                  deletedAt: field_deletedAt
                };
        }) : Js_exn.raiseError("graphql_ppx: Field componentsUsingScreenVariable on type ScreenVariable is missing");
  return {
          __typename: field___typename,
          uuid: field_uuid,
          name: field_name,
          type: field_type_,
          isExternal: field_isExternal,
          componentsUsingScreenVariable: field_componentsUsingScreenVariable
        };
}

var name = "ScreenVariableFields";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ScreenVariableFields = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  ScreenVariableFields: ScreenVariableFields
};

var ppx_printed_query$1 = "query ScreenVariablesByScreen($screenUuid: ID!)  {\n__typename\nscreen(screenUuid: $screenUuid)  {\n__typename\n__typename  \nuuid  \nvariables  {\n__typename\n...ScreenVariableFields   \n}\n\n}\n\n}\nfragment ScreenVariableFields on ScreenVariable   {\n__typename\n__typename  \nuuid  \nname  \nisExternal  \ntype_: type  \ncomponentsUsingScreenVariable  {\n__typename\n__typename  \nuuid  \nname  \ndeletedAt  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "screen");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "__typename");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeString(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field __typename on type Screen is missing");
    }
    var value$7 = Js_dict.get(value$3, "uuid");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeString(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field uuid on type Screen is missing");
    }
    var value$10 = Js_dict.get(value$3, "variables");
    tmp = {
      __typename: tmp$1,
      uuid: tmp$2,
      variables: value$10 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$10))).map(parse) : Js_exn.raiseError("graphql_ppx: Field variables on type Screen is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field screen on type Query is missing");
  }
  return {
          screen: tmp
        };
}

function make(screenUuid, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "screenUuid",
                    screenUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables(variables) {
  var screenUuid = variables.screenUuid;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([[
                    "screenUuid",
                    screenUuid
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables(screenUuid, param) {
  return Js_dict.fromArray([[
                  "screenUuid",
                  screenUuid
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, screenUuid, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "screenUuid",
                      screenUuid
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse$1,
  ppx_printed_query$1,
  definition_2
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

function refetch(screenUuid) {
  return ApolloHooks$DraftbitBuilder.toQueryObj(make(screenUuid, undefined));
}

var ListByScreen = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1,
  refetch: refetch
};

var GQL = {
  Fragment: Fragment,
  ListByScreen: ListByScreen
};

export {
  usageComponent_encode ,
  usageComponent_decode ,
  t_encode ,
  t_decode ,
  toMeta ,
  getLabel ,
  GQL ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
