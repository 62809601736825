import { gql } from "graphql.macro";

export const VALIDATE_SSO_CREDENTIALS = gql`
  mutation ValidateSSOCredentials($input: ValidateSSOCredentialsInput!) {
    validateSSOCredentials(input: $input) {
      success
      loginString
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken
  }
`;
