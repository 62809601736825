// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.mjs";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

function parseIosFromBuildConfig(appName, buildConfig, appConfig) {
  var nameSlug = StringUtils$DraftbitBuilder.sanitizedName(appName);
  try {
    return {
            appId: Json_decode.field("ios_appStore_appId", Json_decode.string, buildConfig),
            connectPrivateKey: Json_decode.field("ios_appStore_connectPrivateKey", Json_decode.string, buildConfig),
            connectKeyId: Json_decode.field("ios_appStore_connectKeyId", Json_decode.string, buildConfig),
            connectIssuerId: Json_decode.field("ios_appStore_connectIssuerId", Json_decode.string, buildConfig),
            xcodeWorkspace: nameSlug + ".xcworkspace",
            xcodeScheme: nameSlug,
            bundleId: Json_decode.field("expo", (function (param) {
                    return Json_decode.field("ios", (function (param) {
                                  return Json_decode.field("bundleIdentifier", Json_decode.string, param);
                                }), param);
                  }), appConfig)
          };
  }
  catch (raw__msg){
    var _msg = Caml_js_exceptions.internalToOCamlException(raw__msg);
    if (_msg.RE_EXN_ID === Json_decode.DecodeError) {
      return ;
    }
    throw _msg;
  }
}

function parseAndroidFromBuildConfig(expoAccount, j) {
  try {
    return Belt_Option.map(expoAccount, (function (account) {
                  return {
                          expoAccountName: account.userAccountName,
                          releaseStatus: Json_decode.field("android_eas_releaseStatus", Json_decode.string, j),
                          serviceAccountFile: Json_decode.field("android_eas_serviceAccountFile", Json_decode.string, j)
                        };
                }));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return ;
    }
    throw exn;
  }
}

var _map = {"Production":"Production","Development":"Development"};

function envToJs(param) {
  return param;
}

function envFromJs(param) {
  return _map[param];
}

function env_encode(st) {
  return st;
}

function env_decode(param) {
  return JsonUtils$DraftbitBuilder.makeStringLikeDecode("PublicationSettings.env", envFromJs, param);
}

function envToShortString(env) {
  if (env === "Production") {
    return "Prod";
  } else {
    return "Dev";
  }
}

var allEnvs = [
  "Production",
  "Development"
];

export {
  parseIosFromBuildConfig ,
  parseAndroidFromBuildConfig ,
  envToJs ,
  envFromJs ,
  env_encode ,
  env_decode ,
  envToShortString ,
  allEnvs ,
  
}
/* JsonUtils-DraftbitBuilder Not a pure module */
