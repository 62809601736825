// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import FadeLoader from "components/FadeLoader";
import * as ReactUtils$DraftbitBuilder from "../utilities/ReactUtils.bs.js";
import * as DataCyWrapper$DraftbitBuilder from "./DataCyWrapper.bs.js";

var FadeLoader$1 = {};

function LoadingSpinner(Props) {
  var label = Props.label;
  var className = Props.className;
  var sizeOpt = Props.size;
  var themeOpt = Props.theme;
  var paddingBottom = Props.paddingBottom;
  var paddingLeft = Props.paddingLeft;
  var inline = Props.inline;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var theme = themeOpt !== undefined ? themeOpt : "Light";
  var tmp = {
    backgroundColor: inline ? "transparent" : "#0f172a",
    bottom: "0",
    color: "white",
    display: "flex",
    left: "0",
    position: inline ? "block" : "fixed",
    right: "0",
    textAlign: "center",
    top: "0",
    zIndex: inline ? "10" : "100",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    userSelect: "none"
  };
  if (paddingBottom !== undefined) {
    tmp.paddingBottom = Caml_option.valFromOption(paddingBottom);
  }
  if (paddingLeft !== undefined) {
    tmp.paddingLeft = Caml_option.valFromOption(paddingLeft);
  }
  return React.createElement(DataCyWrapper$DraftbitBuilder.make, {
              children: React.createElement("div", {
                    className: Cn.make({
                          hd: Cn.ifSome(Cn.unpack(className), className),
                          tl: /* [] */0
                        }),
                    style: tmp
                  }, React.createElement(React.Fragment, undefined, React.createElement(FadeLoader, {
                            height: size / 4 | 0,
                            width: size / 12 | 0,
                            loaderRadius: size / 3 | 0,
                            color: theme === "Light" ? "#FFF" : "#464968"
                          }), ReactUtils$DraftbitBuilder.maybeRender(label, (function (label) {
                              return React.createElement("div", {
                                          className: "mt-2 font-sans font-normal text-md text-mono-50"
                                        }, label);
                            })))),
              name: "LoadingSpinner"
            });
}

var make = LoadingSpinner;

var $$default = LoadingSpinner;

export {
  FadeLoader$1 as FadeLoader,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
