// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as React from "@sentry/react";
import * as EnvUtils$DraftbitBuilder from "./EnvUtils.bs.js";

function captureMessage(message) {
  if (Caml_obj.caml_notequal(EnvUtils$DraftbitBuilder.env, "production")) {
    console.warn("captureMessage", message);
  }
  React.captureMessage(message, undefined);
  
}

function captureMessageExtra(message, extra) {
  if (Caml_obj.caml_notequal(EnvUtils$DraftbitBuilder.env, "production")) {
    console.warn("captureMessage", message, extra);
  }
  React.captureMessage(message, {
        extra: extra
      });
  
}

var extractJavascriptError = (function (err) {
      if (err.RE_EXN_ID && err.Error && err.Error instanceof Error) {
        err.Error.message = err.message || err._1 || err.RE_EXN_ID;
        return err.Error;
      } else if (err.RE_EXN_ID && err._1) {
        if (err._1 instanceof Error) {
          return err._1
        } else {
          return new Error(err._1);
        }
      } else {
        return err;
      }
    });

function captureException(e) {
  if (Caml_obj.caml_notequal(EnvUtils$DraftbitBuilder.env, "production")) {
    console.error("captureException", e);
  }
  React.captureException(extractJavascriptError(e), undefined);
  
}

function captureExceptionExtra(e, extra) {
  if (Caml_obj.caml_notequal(EnvUtils$DraftbitBuilder.env, "production")) {
    console.error("captureException", e, extra);
  }
  React.captureException(extractJavascriptError(e), {
        extra: extra
      });
  
}

export {
  captureMessage ,
  captureMessageExtra ,
  extractJavascriptError ,
  captureException ,
  captureExceptionExtra ,
  
}
/* @sentry/react Not a pure module */
