// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as WL_Tabs$DraftbitBuilder from "../../waterloo/components/Tabs/WL_Tabs.bs.js";
import * as ArrayUtils$DraftbitBuilder from "../../utilities/ArrayUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "../../utilities/StringUtils.bs.js";
import Tab from "react-tabs/lib/components/Tab";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as PropertiesPanelIcon$DraftbitBuilder from "./PropertiesPanelIcon.bs.js";

var _map = {"Styles":"Styles","Configs":"Configs","Data":"Data","Interactions":"Interactions"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function toInt(tabIndex) {
  if (tabIndex === "Interactions") {
    return 3;
  } else if (tabIndex === "Configs") {
    return 1;
  } else if (tabIndex === "Data") {
    return 2;
  } else {
    return 0;
  }
}

function initSelectedTab(tag, collection) {
  if (tag === "Fetch") {
    return "Data";
  } else {
    return "Styles";
  }
}

function fromString(s) {
  return Belt_Option.getWithDefault(tFromJs(s), "Styles");
}

function fromInt(i) {
  switch (i) {
    case 1 :
        return "Configs";
    case 2 :
        return "Data";
    case 3 :
        return "Interactions";
    default:
      return "Styles";
  }
}

var ordered = Belt_Array.makeBy(4, fromInt);

function toTabTitle(tabIndex) {
  if (tabIndex === "Interactions") {
    return "interactions";
  } else if (tabIndex === "Configs") {
    return "configs";
  } else if (tabIndex === "Data") {
    return "data";
  } else {
    return "styles";
  }
}

function inactiveIcon(tabIndex) {
  if (tabIndex === "Interactions") {
    return ProLightSvgIcons.faWandMagicSparkles;
  } else if (tabIndex === "Configs") {
    return ProLightSvgIcons.faSquareSliders;
  } else if (tabIndex === "Data") {
    return ProLightSvgIcons.faCloudArrowDown;
  } else {
    return ProLightSvgIcons.faPaintbrushPencil;
  }
}

function activeIcon(tabIndex) {
  if (tabIndex === "Interactions") {
    return ProSolidSvgIcons.faWandMagicSparkles;
  } else if (tabIndex === "Configs") {
    return ProSolidSvgIcons.faSquareSliders;
  } else if (tabIndex === "Data") {
    return ProSolidSvgIcons.faCloudArrowDown;
  } else {
    return ProSolidSvgIcons.faPaintbrushPencil;
  }
}

function toCapitalizedName(tabIndex) {
  return StringUtils$DraftbitBuilder.capitalize(toTabTitle(tabIndex));
}

function toDataCy(tabIndex) {
  return StringUtils$DraftbitBuilder.capitalize(toTabTitle(tabIndex)) + "Panel";
}

function getDisabledTabs(tag, collection, triggers, group) {
  var tmp;
  var exit = 0;
  if (group === "customBlock") {
    tmp = [
      "Styles",
      "Configs"
    ];
  } else {
    exit = 1;
  }
  if (exit === 1) {
    switch (tag) {
      case "Fetch" :
          tmp = [
            "Styles",
            "Configs"
          ];
          break;
      case "CustomCode" :
      case "Row" :
      case "Spacer" :
      case "Stack" :
          tmp = [
            "Styles",
            "Configs",
            "Data"
          ];
          break;
      default:
        tmp = [];
    }
  }
  return ArrayUtils$DraftbitBuilder.appendIf(tmp, triggers.length === 0, "Interactions");
}

function component(tag, collection, selectedTabIndex, tabIndex, triggers, group, additionalDisabledTabs, param) {
  var disabled = getDisabledTabs(tag, collection, triggers, group).concat(additionalDisabledTabs).includes(tabIndex);
  var selected = selectedTabIndex === tabIndex;
  return React.createElement(Tab, {
              className: WL_Tabs$DraftbitBuilder.Styles.getTabStyles(selected, disabled),
              disabled: disabled,
              children: React.createElement(PropertiesPanelIcon$DraftbitBuilder.make, {
                    icon: selected ? activeIcon(tabIndex) : inactiveIcon(tabIndex),
                    name: StringUtils$DraftbitBuilder.capitalize(toTabTitle(tabIndex)),
                    size: "3x",
                    selected: selected,
                    disabled: disabled,
                    dataCy: StringUtils$DraftbitBuilder.capitalize(toTabTitle(tabIndex)) + "Panel"
                  }),
              key: toTabTitle(tabIndex)
            });
}

var toString = tToJs;

export {
  tToJs ,
  tFromJs ,
  toInt ,
  initSelectedTab ,
  toString ,
  fromString ,
  fromInt ,
  ordered ,
  toTabTitle ,
  inactiveIcon ,
  activeIcon ,
  toCapitalizedName ,
  toDataCy ,
  getDisabledTabs ,
  component ,
  
}
/* ordered Not a pure module */
