// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import GraphqlTag from "graphql-tag";
import * as Caml_exceptions from "bs-platform/lib/es6/caml_exceptions.mjs";
import * as ReactHooks from "@apollo/react-hooks";
import * as ApolloHooksTypes$DraftbitBuilder from "./ApolloHooksTypes.bs.js";

var $$Error = /* @__PURE__ */Caml_exceptions.create("ApolloHooksMutation-DraftbitBuilder.Error");

function useMutation(client, variables, refetchQueries, awaitRefetchQueries, update, optimisticResponse, errorPolicy, context, param) {
  var parse = param[0];
  var tmp = {};
  if (variables !== undefined) {
    tmp.variables = Caml_option.valFromOption(variables);
  }
  if (client !== undefined) {
    tmp.client = Caml_option.valFromOption(client);
  }
  if (refetchQueries !== undefined) {
    tmp.refetchQueries = Caml_option.valFromOption(refetchQueries);
  }
  if (awaitRefetchQueries !== undefined) {
    tmp.awaitRefetchQueries = Caml_option.valFromOption(awaitRefetchQueries);
  }
  if (update !== undefined) {
    tmp.update = Caml_option.valFromOption(update);
  }
  if (optimisticResponse !== undefined) {
    tmp.optimisticResponse = Caml_option.valFromOption(optimisticResponse);
  }
  var tmp$1 = Belt_Option.map(errorPolicy, ApolloHooksTypes$DraftbitBuilder.errorPolicyToJs);
  if (tmp$1 !== undefined) {
    tmp.errorPolicy = Caml_option.valFromOption(tmp$1);
  }
  if (context !== undefined) {
    tmp.context = Caml_option.valFromOption(context);
  }
  var match = ReactHooks.useMutation(GraphqlTag(param[1]), tmp);
  var jsResult = match[1];
  var jsMutate = match[0];
  var mutate = React.useMemo((function () {
          return function (param, param$1, param$2, param$3, param$4, param$5, param$6) {
            var tmp = {};
            if (param !== undefined) {
              tmp.variables = Caml_option.valFromOption(param);
            }
            if (param$1 !== undefined) {
              tmp.client = Caml_option.valFromOption(param$1);
            }
            if (param$2 !== undefined) {
              tmp.refetchQueries = Caml_option.valFromOption(param$2);
            }
            if (param$3 !== undefined) {
              tmp.awaitRefetchQueries = Caml_option.valFromOption(param$3);
            }
            if (param$5 !== undefined) {
              tmp.update = Caml_option.valFromOption(param$5);
            }
            if (param$4 !== undefined) {
              tmp.optimisticResponse = Caml_option.valFromOption(param$4);
            }
            return jsMutate(tmp).then(function (jsResult) {
                        var errors = jsResult.errors;
                        var full_data = Belt_Option.map(Caml_option.nullable_to_opt(jsResult.data), parse);
                        var full_errors = !(errors == null) && errors.length > 0 ? errors : undefined;
                        var full = {
                          data: full_data,
                          errors: full_errors
                        };
                        var data = full_data;
                        var errors$1 = full_errors;
                        var simple = errors$1 !== undefined ? ({
                              TAG: /* Errors */1,
                              _0: errors$1
                            }) : (
                            data !== undefined ? ({
                                  TAG: /* Data */0,
                                  _0: Caml_option.valFromOption(data)
                                }) : /* NoData */0
                          );
                        return Promise.resolve([
                                    simple,
                                    full
                                  ]);
                      });
          };
        }), [variables]);
  var full = React.useMemo((function () {
          return {
                  loading: jsResult.loading,
                  called: jsResult.called,
                  data: Belt_Option.map(Caml_option.nullable_to_opt(jsResult.data), parse),
                  error: Caml_option.nullable_to_opt(jsResult.error)
                };
        }), [jsResult]);
  var simple = React.useMemo((function () {
          if (full.loading) {
            return /* Loading */0;
          }
          var data = full.data;
          var error = full.error;
          if (error !== undefined) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          } else if (data !== undefined) {
            return {
                    TAG: /* Data */0,
                    _0: Caml_option.valFromOption(data)
                  };
          } else if (full.called) {
            return /* NoData */2;
          } else {
            return /* NotCalled */1;
          }
        }), [full]);
  return [
          mutate,
          simple,
          full
        ];
}

export {
  $$Error ,
  useMutation ,
  
}
/* react Not a pure module */
