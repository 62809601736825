// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Uuid$DraftbitBuilder from "./Uuid.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "__typename",
                Decco.stringToJson(v.__typename)
              ],
              [
                "uuid",
                Uuid$DraftbitBuilder.t_encode(v.uuid)
              ],
              [
                "fileName",
                Decco.stringToJson(v.fileName)
              ],
              [
                "fileType",
                Decco.stringToJson(v.fileType)
              ],
              [
                "mimeType",
                Decco.stringToJson(v.mimeType)
              ],
              [
                "url",
                Decco.stringToJson(v.url)
              ],
              [
                "slug",
                Decco.stringToJson(v.slug)
              ],
              [
                "snackUrl",
                Decco.optionToJson(Decco.stringToJson, v.snackUrl)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var __typename = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "__typename"), Decco.stringFromJson), {
        TAG: /* Ok */0,
        _0: "Asset"
      });
  if (__typename.TAG === /* Ok */0) {
    var uuid = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "uuid"), Uuid$DraftbitBuilder.t_decode), {
          TAG: /* Ok */0,
          _0: ""
        });
    if (uuid.TAG === /* Ok */0) {
      var fileName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "fileName"), null));
      if (fileName.TAG === /* Ok */0) {
        var fileType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "fileType"), null));
        if (fileType.TAG === /* Ok */0) {
          var mimeType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "mimeType"), null));
          if (mimeType.TAG === /* Ok */0) {
            var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
            if (url.TAG === /* Ok */0) {
              var slug = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "slug"), null));
              if (slug.TAG === /* Ok */0) {
                var snackUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "snackUrl"), null));
                if (snackUrl.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            __typename: __typename._0,
                            uuid: uuid._0,
                            fileName: fileName._0,
                            fileType: fileType._0,
                            mimeType: mimeType._0,
                            url: url._0,
                            slug: slug._0,
                            snackUrl: snackUrl._0
                          }
                        };
                }
                var e = snackUrl._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".snackUrl" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = slug._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".slug" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = url._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".url" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = mimeType._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".mimeType" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = fileType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".fileType" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = fileName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".fileName" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = uuid._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".uuid" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = __typename._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".__typename" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function fromJson(json) {
  return JsonUtils$DraftbitBuilder.decodeDecco(t_decode, JsonUtils$DraftbitBuilder.removeKeysWithNullValues(json));
}

function getFolderName(param) {
  if (param.fileType === "font") {
    return "fonts";
  } else {
    return "images";
  }
}

function getBaseName(param) {
  var match = StringUtils$DraftbitBuilder.splitLast(param.fileName, ".");
  var name = StringUtils$DraftbitBuilder.splitFirst(StringUtils$DraftbitBuilder.splitFirst(match[0], "@")[0], ".")[0];
  return name + "." + match[1].toLowerCase();
}

function getFilepath(a) {
  return "assets/" + getFolderName(a) + "/" + getBaseName(a);
}

function url(asset) {
  return Belt_Option.getWithDefault(asset.snackUrl, asset.url);
}

function buildAssetsCode(assets) {
  return Js_dict.fromArray(assets.map(function (a) {
                  return [
                          getFilepath(a),
                          url(a)
                        ];
                }));
}

function renderAssetsFile(assets, context) {
  var kvs = ArrayUtils$DraftbitBuilder.uniqBy(assets, (function (a) {
            return a.slug;
          })).map(function (a) {
        if (context === "LivePreview") {
          return [
                  StringUtils$DraftbitBuilder.quote(a.slug),
                  "{uri: " + StringUtils$DraftbitBuilder.quote(url(a)) + "}"
                ];
        } else {
          return [
                  StringUtils$DraftbitBuilder.quote(a.slug),
                  "require(\"../" + getFilepath(a) + "\")"
                ];
        }
      });
  return "export default { " + ArrayUtils$DraftbitBuilder.mapJoin(kvs, ",\n", (function (param) {
                return param[0] + ": " + param[1];
              })) + " }";
}

export {
  t_encode ,
  t_decode ,
  fromJson ,
  getFolderName ,
  getBaseName ,
  getFilepath ,
  url ,
  buildAssetsCode ,
  renderAssetsFile ,
  
}
/* Uuid-DraftbitBuilder Not a pure module */
