// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Emotion from "@ahrefs/bs-emotion/src/Emotion.bs.js";
import * as WL$DraftbitBuilder from "../../WL.bs.js";

function styles(gap, fit) {
  return Emotion.css(undefined, {
              hd: Emotion.select("& > *:not(:last-child)", {
                    hd: Emotion.marginBottom(WL$DraftbitBuilder.unit(gap)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: fit ? Emotion.select("& > *", {
                        hd: Emotion.flex({
                              NAME: "some",
                              VAL: [
                                1,
                                1,
                                Emotion.pct(0)
                              ]
                            }),
                        tl: /* [] */0
                      }) : Emotion.select("& > *", {
                        hd: Emotion.flex({
                              NAME: "some",
                              VAL: [
                                0,
                                0,
                                "auto"
                              ]
                            }),
                        tl: /* [] */0
                      }),
                tl: /* [] */0
              }
            });
}

function Stack(Props) {
  var gapOpt = Props.gap;
  var alignOpt = Props.align;
  var distributeOpt = Props.distribute;
  var fullHeightOpt = Props.fullHeight;
  var fullWidthOpt = Props.fullWidth;
  var fitOpt = Props.fit;
  var children = Props.children;
  var gap = gapOpt !== undefined ? gapOpt : 0;
  var align = alignOpt !== undefined ? alignOpt : "Center";
  var distribute = distributeOpt !== undefined ? distributeOpt : "SpaceAround";
  var fullHeight = fullHeightOpt !== undefined ? fullHeightOpt : true;
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : true;
  var fit = fitOpt !== undefined ? fitOpt : false;
  var className = Cn.make({
        hd: "flex flex-col",
        tl: {
          hd: Cn.ifTrue("h-full", fullHeight),
          tl: {
            hd: Cn.ifTrue("w-full", fullWidth),
            tl: {
              hd: align === "End" ? "items-end" : (
                  align === "Start" ? "items-start" : (
                      align === "Center" ? "items-center" : "items-stretch"
                    )
                ),
              tl: {
                hd: distribute === "SpaceBetween" ? "justify-between" : (
                    distribute === "End" ? "justify-end" : (
                        distribute === "Start" ? "justify-start" : (
                            distribute === "SpaceEvenly" ? "justify-evenly" : (
                                distribute === "Center" ? "justify-center" : "justify-around"
                              )
                          )
                      )
                  ),
                tl: {
                  hd: styles(gap, fit),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      });
  return React.createElement("div", {
              className: className
            }, children);
}

var make = Stack;

export {
  styles ,
  make ,
  
}
/* react Not a pure module */
