// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsSet from "rescript-js-collections/src/JsSet.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Pluralize from "pluralize";
import * as Caml_array from "bs-platform/lib/es6/caml_array.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as GenerateSchema from "generate-schema";
import * as DictUtils$DraftbitBuilder from "./utilities/DictUtils.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as OptionUtils$DraftbitBuilder from "./utilities/OptionUtils.bs.js";
import * as StringUtils$DraftbitBuilder from "./utilities/StringUtils.bs.js";

function fromVariant(nullableOpt, description, example, variant) {
  var nullable = nullableOpt !== undefined ? nullableOpt : true;
  return {
          nullable: nullable,
          description: description,
          example: example,
          variant: variant
        };
}

function makeObject(properties) {
  return {
          nullable: true,
          description: undefined,
          example: undefined,
          variant: {
            NAME: "Object",
            VAL: [
              properties,
              []
            ]
          }
        };
}

function empty(param) {
  return makeObject({});
}

function fromJson(json) {
  var nullable = Json_decode.withDefault(false, (function (param) {
          return Json_decode.field("nullable", Json_decode.bool, param);
        }), json);
  var description = Json_decode.optional((function (param) {
          return Json_decode.field("description", Json_decode.string, param);
        }), json);
  var ref = Json_decode.optional((function (param) {
          return Json_decode.field("$ref", Json_decode.string, param);
        }), json);
  var variant;
  if (ref !== undefined) {
    variant = {
      NAME: "Ref",
      VAL: ref
    };
  } else {
    var schemas = Json_decode.optional((function (param) {
            return Json_decode.field("oneOf", (function (param) {
                          return Json_decode.array(fromJson, param);
                        }), param);
          }), json);
    if (schemas !== undefined) {
      variant = {
        NAME: "OneOf",
        VAL: schemas
      };
    } else {
      var t = Json_decode.withDefault("object", (function (param) {
              return Json_decode.field("type", Json_decode.string, param);
            }), json);
      switch (t) {
        case "array" :
            variant = {
              NAME: "Array",
              VAL: Json_decode.withDefault(makeObject({}), (function (param) {
                      return Json_decode.field("items", fromJson, param);
                    }), json)
            };
            break;
        case "boolean" :
            variant = "Boolean";
            break;
        case "null" :
            variant = "Null";
            break;
        case "integer" :
        case "number" :
            variant = "Number";
            break;
        case "object" :
            variant = {
              NAME: "Object",
              VAL: [
                Json_decode.withDefault({}, (function (param) {
                        return Json_decode.field("properties", (function (param) {
                                      return Json_decode.dict(fromJson, param);
                                    }), param);
                      }), json),
                Json_decode.withDefault([], (function (param) {
                        return Json_decode.field("required", (function (param) {
                                      return Json_decode.array(Json_decode.string, param);
                                    }), param);
                      }), json)
              ]
            };
            break;
        case "string" :
            variant = "String";
            break;
        default:
          throw {
                RE_EXN_ID: Json_decode.DecodeError,
                _1: "Type " + t + " not implemented",
                Error: new Error()
              };
      }
    }
  }
  var example = OptionUtils$DraftbitBuilder.noneIf(Json_decode.optional((function (param) {
              return Json_decode.field("example", (function (j) {
                            return j;
                          }), param);
            }), json), (function (j) {
          return Caml_obj.caml_equal(j, null);
        }));
  return {
          nullable: nullable,
          description: description,
          example: example,
          variant: variant
        };
}

function toJson(schema) {
  var match = schema.variant;
  var tmp;
  if (typeof match === "string") {
    tmp = match === "String" ? [
        "type",
        "string"
      ] : (
        match === "Boolean" ? [
            "type",
            "boolean"
          ] : (
            match === "Number" ? [
                "type",
                "number"
              ] : (
                match === "Null" ? [
                    "type",
                    "null"
                  ] : undefined
              )
          )
      );
  } else {
    var variant = match.NAME;
    tmp = variant === "Object" ? [
        "type",
        "object"
      ] : (
        variant === "Array" ? [
            "type",
            "array"
          ] : undefined
      );
  }
  var match$1 = schema.variant;
  var tmp$1;
  if (typeof match$1 === "string") {
    tmp$1 = undefined;
  } else {
    var variant$1 = match$1.NAME;
    if (variant$1 === "Object") {
      var properties = match$1.VAL[0];
      tmp$1 = DictUtils$DraftbitBuilder.size(properties) === 0 ? undefined : [
          "properties",
          Json_encode.dict(toJson, properties)
        ];
    } else {
      tmp$1 = variant$1 === "Ref" ? [
          "$ref",
          match$1.VAL
        ] : (
          variant$1 === "Array" ? [
              "items",
              toJson(match$1.VAL)
            ] : undefined
        );
    }
  }
  var match$2 = schema.variant;
  var tmp$2;
  if (typeof match$2 === "string" || match$2.NAME !== "Object") {
    tmp$2 = undefined;
  } else {
    var required = match$2.VAL[1];
    tmp$2 = required.length !== 0 ? [
        "required",
        Json_encode.array((function (prim) {
                return prim;
              }), required)
      ] : undefined;
  }
  var match$3 = schema.variant;
  var match$4 = schema.nullable;
  var tmp$3;
  var exit = 0;
  if (typeof match$3 === "string" || match$3.NAME !== "Ref") {
    exit = 1;
  } else {
    tmp$3 = undefined;
  }
  if (exit === 1) {
    tmp$3 = match$4 ? [
        "nullable",
        true
      ] : undefined;
  }
  var match$5 = schema.variant;
  var match$6 = schema.description;
  var tmp$4;
  var exit$1 = 0;
  if (typeof match$5 === "string" || match$5.NAME !== "Ref") {
    exit$1 = 1;
  } else {
    tmp$4 = undefined;
  }
  if (exit$1 === 1) {
    tmp$4 = match$6 !== undefined ? [
        "description",
        match$6
      ] : undefined;
  }
  var match$7 = schema.variant;
  var tmp$5;
  var exit$2 = 0;
  if (typeof match$7 === "string" || match$7.NAME !== "Ref") {
    exit$2 = 1;
  } else {
    tmp$5 = undefined;
  }
  if (exit$2 === 1) {
    tmp$5 = Belt_Option.map(schema.example, (function (j) {
            return [
                    "example",
                    j
                  ];
          }));
  }
  return Json_encode.object_(Belt_List.fromArray(ArrayUtils$DraftbitBuilder.keepSome([
                      tmp,
                      tmp$1,
                      tmp$2,
                      tmp$3,
                      tmp$4,
                      tmp$5
                    ])));
}

function t_decode(param) {
  return JsonUtils$DraftbitBuilder.makeDeccoDecoder(fromJson, param);
}

function detect(json) {
  var str = Js_json.classify(json);
  var tmp;
  tmp = typeof str === "number" || str.TAG !== /* JSONString */0 ? GenerateSchema.json(json) : GenerateSchema.json(str._0, json);
  return fromJson(tmp);
}

function removeRefs(refs, schema) {
  var failed = JsSet.empty(undefined);
  var loop = function (_seen, _sch) {
    while(true) {
      var sch = _sch;
      var seen = _seen;
      var match = sch.variant;
      if (typeof match === "string") {
        return sch;
      }
      var variant = match.NAME;
      if (variant === "Object") {
        var match$1 = match.VAL;
        return {
                nullable: sch.nullable,
                description: sch.description,
                example: sch.example,
                variant: {
                  NAME: "Object",
                  VAL: [
                    DictUtils$DraftbitBuilder.map(match$1[0], (function(seen){
                        return function (param) {
                          return loop(seen, param);
                        }
                        }(seen))),
                    match$1[1]
                  ]
                }
              };
      }
      if (variant !== "Ref") {
        if (variant === "Array") {
          return {
                  nullable: sch.nullable,
                  description: sch.description,
                  example: sch.example,
                  variant: {
                    NAME: "Array",
                    VAL: loop(seen, match.VAL)
                  }
                };
        } else {
          return {
                  nullable: sch.nullable,
                  description: sch.description,
                  example: sch.example,
                  variant: {
                    NAME: "OneOf",
                    VAL: match.VAL.map((function(seen){
                        return function (param) {
                          return loop(seen, param);
                        }
                        }(seen)))
                  }
                };
        }
      }
      var ref = match.VAL;
      if (JsSet.has(seen, ref) || JsSet.has(failed, ref)) {
        JsSet.addMut(failed, ref);
        return sch;
      }
      var refSchema = Js_dict.get(refs, ref);
      if (refSchema !== undefined) {
        _sch = refSchema;
        _seen = JsSet.addPure(seen, ref);
        continue ;
      }
      JsSet.addMut(failed, ref);
      return sch;
    };
  };
  var resolvedSchema = loop(JsSet.empty(undefined), schema);
  return [
          resolvedSchema,
          failed
        ];
}

function properties(refs, param) {
  var variant = param.variant;
  if (typeof variant === "string") {
    return ;
  }
  var variant$1 = variant.NAME;
  if (variant$1 === "OneOf") {
    return ArrayUtils$DraftbitBuilder.keepFirstSome(variant.VAL, (function (param) {
                  return properties(refs, param);
                }));
  }
  if (variant$1 !== "Object") {
    if (variant$1 === "Ref") {
      return Belt_Option.flatMap(Js_dict.get(refs, variant.VAL), (function (param) {
                    return properties(refs, param);
                  }));
    } else {
      return ;
    }
  }
  var match = variant.VAL;
  var req = JsSet.fromArray(match[1]);
  return Caml_option.some(DictUtils$DraftbitBuilder.mapWithKey(match[0], (function (k, p) {
                    return [
                            p,
                            JsSet.has(req, k)
                          ];
                  })));
}

function getArrayItemSchema(schema) {
  var match = schema.variant;
  if (typeof match === "string" || match.NAME !== "Array") {
    return ;
  } else {
    return match.VAL;
  }
}

function makeExampleJson(topLevelKey, refsOpt, schema, param) {
  var refs = refsOpt !== undefined ? Caml_option.valFromOption(refsOpt) : ({});
  var loop = function (optKey, _param) {
    while(true) {
      var param = _param;
      var example = param.example;
      if (example !== undefined) {
        return Caml_option.valFromOption(example);
      }
      var variant = param.variant;
      if (typeof variant === "string") {
        if (variant === "Boolean") {
          return true;
        } else if (variant === "Number") {
          return 123;
        } else if (variant === "Null") {
          return null;
        } else if (optKey !== undefined && optKey !== "") {
          return "some " + Pluralize.singular(optKey);
        } else {
          return "some string";
        }
      }
      var variant$1 = variant.NAME;
      if (variant$1 === "Object") {
        return Json_encode.dict((function (j) {
                      return j;
                    }), DictUtils$DraftbitBuilder.mapWithKey(variant.VAL[0], (function (key, schema) {
                          return loop(key, schema);
                        })));
      }
      if (variant$1 === "Ref") {
        return Belt_Option.mapWithDefault(Js_dict.get(refs, variant.VAL), {}, (function (param) {
                      return loop(undefined, param);
                    }));
      }
      if (variant$1 === "Array") {
        return Json_encode.array((function (j) {
                      return j;
                    }), [loop(optKey, variant.VAL)]);
      }
      var schemas = variant.VAL;
      if (schemas.length === 0) {
        return null;
      }
      var nonArraySchema = schemas.find(function (s) {
            var match = s.variant;
            if (typeof match === "string") {
              return true;
            } else {
              return match.NAME !== "Array";
            }
          });
      if (nonArraySchema !== undefined) {
        _param = nonArraySchema;
        continue ;
      }
      _param = Caml_array.get(schemas, 0);
      continue ;
    };
  };
  return loop(topLevelKey, schema);
}

function getKeyPaths(refsOpt, schema) {
  var refs = refsOpt !== undefined ? Caml_option.valFromOption(refsOpt) : ({});
  var paths = [{
      path: [],
      schema: schema
    }];
  var loop = function (_param, _currentPath) {
    while(true) {
      var param = _param;
      var currentPath = _currentPath;
      var variant = param.variant;
      if (typeof variant === "string") {
        return ;
      }
      var variant$1 = variant.NAME;
      if (variant$1 === "Object") {
        Js_dict.entries(variant.VAL[0]).forEach((function(currentPath){
            return function (param) {
              var subSchema = param[1];
              var newPath = ArrayUtils$DraftbitBuilder.append(currentPath, param[0]);
              ArrayUtils$DraftbitBuilder.push(paths, {
                    path: newPath,
                    schema: subSchema
                  });
              return loop(subSchema, newPath);
            }
            }(currentPath)));
        return ;
      }
      if (variant$1 === "Ref") {
        return Belt_Option.forEach(Js_dict.get(refs, variant.VAL), (function(currentPath){
                  return function (s) {
                    return loop(s, currentPath);
                  }
                  }(currentPath)));
      }
      if (variant$1 !== "Array") {
        return ;
      }
      var subSchema = variant.VAL;
      var newPath = ArrayUtils$DraftbitBuilder.append(currentPath, "0");
      ArrayUtils$DraftbitBuilder.push(paths, {
            path: newPath,
            schema: subSchema
          });
      _currentPath = newPath;
      _param = subSchema;
      continue ;
    };
  };
  loop(schema, []);
  return paths;
}

function extractKeyPath(schema, keyPath) {
  var loop = function (_s, _keys) {
    while(true) {
      var keys = _keys;
      var s = _s;
      var match = s.variant;
      if (!keys) {
        return s;
      }
      if (typeof match === "string") {
        return ;
      }
      var variant = match.NAME;
      if (variant === "Object") {
        var rest = keys.tl;
        return Belt_Option.flatMap(Js_dict.get(match.VAL[0], keys.hd), (function(rest){
                  return function (s2) {
                    return loop(s2, rest);
                  }
                  }(rest)));
      }
      if (variant !== "Array") {
        return ;
      }
      if (!StringUtils$DraftbitBuilder.isInt(keys.hd)) {
        return ;
      }
      _keys = keys.tl;
      _s = match.VAL;
      continue ;
    };
  };
  return loop(schema, Belt_List.fromArray(keyPath));
}

var t_encode = toJson;

export {
  fromVariant ,
  makeObject ,
  empty ,
  fromJson ,
  toJson ,
  t_decode ,
  t_encode ,
  detect ,
  removeRefs ,
  properties ,
  getArrayItemSchema ,
  makeExampleJson ,
  getKeyPaths ,
  extractKeyPath ,
  
}
/* pluralize Not a pure module */
