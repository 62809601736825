// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.mjs";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./utilities/ArrayUtils.bs.js";
import * as AppJsonConfig_InfoPlist$DraftbitBuilder from "./AppJsonConfig_InfoPlist.bs.js";
import * as AppJsonConfig_IosConfig$DraftbitBuilder from "./AppJsonConfig_IosConfig.bs.js";
import * as AppJsonConfig_AndroidConfig$DraftbitBuilder from "./AppJsonConfig_AndroidConfig.bs.js";

var _map = {"IOS":"ios","Android":"android","Web":"web"};

var _revMap = {"ios":"IOS","android":"Android","web":"Web"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

function safeFromJs(platformString) {
  var platform = _revMap[platformString];
  if (platform !== undefined) {
    return platform;
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Invalid platform from expo config platform" + platformString,
        Error: new Error()
      };
}

function decode(json) {
  return safeFromJs(Json_decode.string(json));
}

function encode(platform) {
  return _map[platform];
}

var Platform = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  safeFromJs: safeFromJs,
  decode: decode,
  encode: encode
};

var defaults = {
  expo: {
    name: "Untitled App",
    description: "My app powered by Draftbit",
    owner: undefined,
    slug: "untitled-app",
    scheme: "untitled-app",
    privacy: "unlisted",
    platforms: [
      "IOS",
      "Android",
      "Web"
    ],
    orientation: "default",
    userInterfaceStyle: "light",
    backgroundColor: "#FFFFFF",
    primaryColor: "#000000",
    icon: undefined,
    notification: {
      color: undefined,
      iosDisplayInForeground: undefined,
      androidMode: "default",
      androidCollapsedTitle: undefined,
      androidIcon: undefined
    },
    splash: {
      image: undefined,
      resizeMode: "contain",
      backgroundColor: "#FFFFFF"
    },
    android: {
      package: "com.draftbit.untitledapp",
      permissions: [],
      config: undefined,
      backgroundColor: undefined,
      userInterfaceStyle: undefined,
      playStoreUrl: undefined,
      allowBackup: undefined,
      adaptiveIcon: {
        foregroundImage: undefined,
        monochromeImage: undefined,
        backgroundColor: undefined
      },
      accentColor: undefined,
      softwareKeyboardLayoutMode: undefined
    },
    androidStatusBar: {
      barStyle: undefined,
      backgroundColor: undefined,
      hidden: undefined,
      translucent: undefined
    },
    iosStatusBar: {
      barStyle: undefined,
      hidden: undefined,
      networkActivityIndicatorVisible: undefined
    },
    androidNavigationBar: {
      visible: undefined,
      barStyle: undefined,
      backgroundColor: undefined
    },
    ios: {
      supportsTablet: undefined,
      isTabletOnly: undefined,
      requireFullScreen: undefined,
      userInterfaceStyle: undefined,
      associatedDomains: undefined,
      usesAppleSignIn: undefined,
      bundleIdentifier: "com.draftbit.untitledapp",
      infoPlist: {
        UIBackgroundModes: [],
        LSApplicationQueriesSchemes: [],
        NSBluetoothPeripheralUsageDescription: "",
        NSBluetoothAlwaysUsageDescription: "",
        NSCalendarsUsageDescription: "",
        NSRemindersUsageDescription: "",
        NSCameraUsageDescription: "",
        NSMicrophoneUsageDescription: "",
        NSContactsUsageDescription: "",
        NSFaceIDUsageDescription: "",
        NSGKFriendListUsageDescription: "",
        NSHealthClinicalHealthRecordsShareUsageDescription: "",
        NSHealthShareUsageDescription: "",
        NSHealthUpdateUsageDescription: "",
        NSHomeKitUsageDescription: "",
        NSLocationAlwaysAndWhenInUseUsageDescription: "",
        NSLocationWhenInUseUsageDescription: "",
        NSAppleMusicUsageDescription: "",
        NSMotionUsageDescription: "",
        NSFallDetectionUsageDescription: "",
        NSLocalNetworkUsageDescription: "",
        NFCReaderUsageDescription: "",
        NSPhotoLibraryAddUsageDescription: "",
        NSPhotoLibraryUsageDescription: "",
        NSUserTrackingUsageDescription: "",
        NSSensorKitUsageDescription: "",
        NSSiriUsageDescription: "",
        NSSpeechRecognitionUsageDescription: "",
        CFBundleLocalizations: "",
        CFBundleDevelopmentRegion: [],
        TICapsLockLanguageSwitchCapable: undefined,
        CFBundleAllowMixedLocalizations: undefined
      },
      usesIcloudStorage: false,
      config: AppJsonConfig_IosConfig$DraftbitBuilder.defaults,
      backgroundColor: undefined,
      appStoreUrl: undefined
    },
    web: {
      themeColor: "#000000",
      display: undefined,
      orientation: undefined,
      backgroundColor: undefined,
      barStyle: undefined,
      preferRelatedApplications: undefined,
      googleMapsApiKey: undefined
    },
    extra: {
      eas: {
        projectId: undefined
      }
    },
    plugins: undefined,
    allowFontScaling: true,
    locales: undefined
  }
};

function setAndroidGoogleMapsKey(config, apiKey) {
  var newrecord = Caml_obj.caml_obj_dup(config.expo);
  var init = config.expo.android;
  var config$1 = config.expo.android.config;
  newrecord.android = {
    package: init.package,
    permissions: init.permissions,
    config: config$1 !== undefined ? ({
          branch: config$1.branch,
          googleMaps: {
            apiKey: apiKey
          },
          googleMobileAdsAppId: config$1.googleMobileAdsAppId,
          googleMobileAdsAutoInit: config$1.googleMobileAdsAutoInit
        }) : ({
          branch: undefined,
          googleMaps: {
            apiKey: apiKey
          },
          googleMobileAdsAppId: undefined,
          googleMobileAdsAutoInit: undefined
        }),
    backgroundColor: init.backgroundColor,
    userInterfaceStyle: init.userInterfaceStyle,
    playStoreUrl: init.playStoreUrl,
    allowBackup: init.allowBackup,
    adaptiveIcon: init.adaptiveIcon,
    accentColor: init.accentColor,
    softwareKeyboardLayoutMode: init.softwareKeyboardLayoutMode
  };
  return {
          expo: newrecord
        };
}

function setIosGoogleMapsKey(config, apiKey) {
  var newrecord = Caml_obj.caml_obj_dup(config.expo);
  var init = config.expo.ios;
  var init$1 = config.expo.ios.config;
  newrecord.ios = {
    supportsTablet: init.supportsTablet,
    isTabletOnly: init.isTabletOnly,
    requireFullScreen: init.requireFullScreen,
    userInterfaceStyle: init.userInterfaceStyle,
    associatedDomains: init.associatedDomains,
    usesAppleSignIn: init.usesAppleSignIn,
    bundleIdentifier: init.bundleIdentifier,
    infoPlist: init.infoPlist,
    usesIcloudStorage: init.usesIcloudStorage,
    config: {
      googleMapsApiKey: apiKey,
      googleMobileAdsAppId: init$1.googleMobileAdsAppId,
      googleMobileAdsAutoInit: init$1.googleMobileAdsAutoInit,
      branch: init$1.branch,
      usesNonExemptEncryption: init$1.usesNonExemptEncryption
    },
    backgroundColor: init.backgroundColor,
    appStoreUrl: init.appStoreUrl
  };
  return {
          expo: newrecord
        };
}

function setWebGoogleMapsKey(config, apiKey) {
  var newrecord = Caml_obj.caml_obj_dup(config.expo);
  var init = config.expo.web;
  newrecord.web = {
    themeColor: init.themeColor,
    display: init.display,
    orientation: init.orientation,
    backgroundColor: init.backgroundColor,
    barStyle: init.barStyle,
    preferRelatedApplications: init.preferRelatedApplications,
    googleMapsApiKey: apiKey
  };
  return {
          expo: newrecord
        };
}

function androidStatusBar(json) {
  return {
          barStyle: Json_decode.optional((function (param) {
                  return Json_decode.field("barStyle", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.optional((function (param) {
                  return Json_decode.field("backgroundColor", Json_decode.string, param);
                }), json),
          hidden: Json_decode.optional((function (param) {
                  return Json_decode.field("hidden", Json_decode.bool, param);
                }), json),
          translucent: Json_decode.optional((function (param) {
                  return Json_decode.field("translucent", Json_decode.bool, param);
                }), json)
        };
}

function iosStatusBar(json) {
  return {
          barStyle: Json_decode.optional((function (param) {
                  return Json_decode.field("barStyle", Json_decode.string, param);
                }), json),
          hidden: Json_decode.optional((function (param) {
                  return Json_decode.field("hidden", Json_decode.bool, param);
                }), json),
          networkActivityIndicatorVisible: Json_decode.optional((function (param) {
                  return Json_decode.field("networkActivityIndicatorVisible", Json_decode.bool, param);
                }), json)
        };
}

function androidNavigationBar(json) {
  return {
          visible: Json_decode.optional((function (param) {
                  return Json_decode.field("visible", Json_decode.string, param);
                }), json),
          barStyle: Json_decode.optional((function (param) {
                  return Json_decode.field("barStyle", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.optional((function (param) {
                  return Json_decode.field("backgroundColor", Json_decode.string, param);
                }), json)
        };
}

function adaptiveIcon(json) {
  return {
          foregroundImage: Json_decode.optional((function (param) {
                  return Json_decode.field("foregroundImage", Json_decode.string, param);
                }), json),
          monochromeImage: Json_decode.optional((function (param) {
                  return Json_decode.field("monochromeImage", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.optional((function (param) {
                  return Json_decode.field("backgroundColor", Json_decode.string, param);
                }), json)
        };
}

function android(json) {
  return {
          package: Json_decode.field("package", Json_decode.string, json),
          permissions: Json_decode.withDefault(defaults.expo.android.permissions, (function (param) {
                  return Json_decode.field("permissions", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          config: Json_decode.optional((function (param) {
                  return Json_decode.field("config", (function (param) {
                                return JsonUtils$DraftbitBuilder.decodeDecco(AppJsonConfig_AndroidConfig$DraftbitBuilder.t_decode, param);
                              }), param);
                }), json),
          backgroundColor: Json_decode.withDefault(defaults.expo.android.backgroundColor, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("backgroundColor", Json_decode.string, param);
                              }), param);
                }), json),
          userInterfaceStyle: Json_decode.optional((function (param) {
                  return Json_decode.field("userInterfaceStyle", Json_decode.string, param);
                }), json),
          playStoreUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("playStoreUrl", Json_decode.string, param);
                }), json),
          allowBackup: Json_decode.withDefault(defaults.expo.android.allowBackup, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("allowBackup", Json_decode.bool, param);
                              }), param);
                }), json),
          adaptiveIcon: Json_decode.withDefault(defaults.expo.android.adaptiveIcon, (function (param) {
                  return Json_decode.field("adaptiveIcon", adaptiveIcon, param);
                }), json),
          accentColor: Json_decode.optional((function (param) {
                  return Json_decode.field("accentColor", Json_decode.string, param);
                }), json),
          softwareKeyboardLayoutMode: Json_decode.optional((function (param) {
                  return Json_decode.field("softwareKeyboardLayoutMode", Json_decode.string, param);
                }), json)
        };
}

function ios(json) {
  return {
          supportsTablet: Json_decode.withDefault(defaults.expo.ios.supportsTablet, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("supportsTablet", Json_decode.bool, param);
                              }), param);
                }), json),
          isTabletOnly: Json_decode.withDefault(defaults.expo.ios.isTabletOnly, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("isTabletOnly", Json_decode.bool, param);
                              }), param);
                }), json),
          requireFullScreen: Json_decode.withDefault(defaults.expo.ios.requireFullScreen, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("requireFullScreen", Json_decode.bool, param);
                              }), param);
                }), json),
          userInterfaceStyle: Json_decode.optional((function (param) {
                  return Json_decode.field("userInterfaceStyle", Json_decode.string, param);
                }), json),
          associatedDomains: Json_decode.optional((function (param) {
                  return Json_decode.field("associatedDomains", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          usesAppleSignIn: Json_decode.withDefault(defaults.expo.ios.usesAppleSignIn, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("usesAppleSignIn", Json_decode.bool, param);
                              }), param);
                }), json),
          bundleIdentifier: Json_decode.field("bundleIdentifier", Json_decode.string, json),
          infoPlist: Json_decode.field("infoPlist", (function (param) {
                  return JsonUtils$DraftbitBuilder.decodeDecco(AppJsonConfig_InfoPlist$DraftbitBuilder.t_decode, param);
                }), json),
          usesIcloudStorage: Json_decode.withDefault(defaults.expo.ios.usesIcloudStorage, (function (param) {
                  return Json_decode.field("usesIcloudStorage", Json_decode.bool, param);
                }), json),
          config: Json_decode.withDefault(defaults.expo.ios.config, (function (param) {
                  return Json_decode.field("config", AppJsonConfig_IosConfig$DraftbitBuilder.Decode.decode, param);
                }), json),
          backgroundColor: Json_decode.withDefault(defaults.expo.ios.backgroundColor, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("backgroundColor", Json_decode.string, param);
                              }), param);
                }), json),
          appStoreUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("appStoreUrl", Json_decode.string, param);
                }), json)
        };
}

function notification(json) {
  return {
          color: Json_decode.optional((function (param) {
                  return Json_decode.field("color", Json_decode.string, param);
                }), json),
          iosDisplayInForeground: Json_decode.optional((function (param) {
                  return Json_decode.field("iosDisplayInForeground", Json_decode.bool, param);
                }), json),
          androidMode: Json_decode.optional((function (param) {
                  return Json_decode.field("androidMode", Json_decode.string, param);
                }), json),
          androidCollapsedTitle: Json_decode.optional((function (param) {
                  return Json_decode.field("androidCollapsedTitle", Json_decode.string, param);
                }), json),
          androidIcon: Json_decode.optional((function (param) {
                  return Json_decode.field("androidIcon", Json_decode.string, param);
                }), json)
        };
}

function web(json) {
  return {
          themeColor: Json_decode.field("themeColor", Json_decode.string, json),
          display: Json_decode.optional((function (param) {
                  return Json_decode.field("display", Json_decode.string, param);
                }), json),
          orientation: Json_decode.optional((function (param) {
                  return Json_decode.field("orientation", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.withDefault(defaults.expo.web.backgroundColor, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("backgroundColor", Json_decode.string, param);
                              }), param);
                }), json),
          barStyle: Json_decode.optional((function (param) {
                  return Json_decode.field("barStyle", Json_decode.string, param);
                }), json),
          preferRelatedApplications: Json_decode.optional((function (param) {
                  return Json_decode.field("preferRelatedApplications", Json_decode.bool, param);
                }), json),
          googleMapsApiKey: Json_decode.optional((function (param) {
                  return Json_decode.field("googleMapsApiKey", Json_decode.string, param);
                }), json)
        };
}

function splash(json) {
  return {
          image: Json_decode.optional((function (param) {
                  return Json_decode.field("image", Json_decode.string, param);
                }), json),
          resizeMode: Json_decode.withDefault(defaults.expo.splash.resizeMode, (function (param) {
                  return Json_decode.field("resizeMode", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.withDefault(defaults.expo.splash.backgroundColor, (function (param) {
                  return Json_decode.field("backgroundColor", Json_decode.string, param);
                }), json)
        };
}

function extraEas(json) {
  return {
          projectId: Json_decode.withDefault(defaults.expo.extra.eas.projectId, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("projectId", Json_decode.string, param);
                              }), param);
                }), json)
        };
}

function extra(json) {
  return {
          eas: Json_decode.field("eas", extraEas, json)
        };
}

function expo(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          owner: Json_decode.optional((function (param) {
                  return Json_decode.field("owner", Json_decode.string, param);
                }), json),
          slug: Json_decode.field("slug", Json_decode.string, json),
          scheme: Json_decode.optional((function (param) {
                  return Json_decode.field("scheme", Json_decode.string, param);
                }), json),
          privacy: Json_decode.field("privacy", Json_decode.string, json),
          platforms: Json_decode.withDefault(defaults.expo.platforms, (function (param) {
                  return Json_decode.field("platforms", (function (param) {
                                return Json_decode.array(decode, param);
                              }), param);
                }), json),
          orientation: Json_decode.withDefault(defaults.expo.orientation, (function (param) {
                  return Json_decode.field("orientation", Json_decode.string, param);
                }), json),
          userInterfaceStyle: Json_decode.optional((function (param) {
                  return Json_decode.field("userInterfaceStyle", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.withDefault(defaults.expo.backgroundColor, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("backgroundColor", Json_decode.string, param);
                              }), param);
                }), json),
          primaryColor: Json_decode.optional((function (param) {
                  return Json_decode.field("primaryColor", Json_decode.string, param);
                }), json),
          icon: Json_decode.optional((function (param) {
                  return Json_decode.field("icon", Json_decode.string, param);
                }), json),
          notification: Json_decode.withDefault(defaults.expo.notification, (function (param) {
                  return Json_decode.field("notification", notification, param);
                }), json),
          splash: Json_decode.field("splash", splash, json),
          android: Json_decode.withDefault(defaults.expo.android, (function (param) {
                  return Json_decode.field("android", android, param);
                }), json),
          androidStatusBar: Json_decode.withDefault(defaults.expo.androidStatusBar, (function (param) {
                  return Json_decode.field("androidStatusBar", androidStatusBar, param);
                }), json),
          iosStatusBar: Json_decode.withDefault(defaults.expo.iosStatusBar, (function (param) {
                  return Json_decode.field("iosStatusBar", iosStatusBar, param);
                }), json),
          androidNavigationBar: Json_decode.withDefault(defaults.expo.androidNavigationBar, (function (param) {
                  return Json_decode.field("androidNavigationBar", androidNavigationBar, param);
                }), json),
          ios: Json_decode.withDefault(defaults.expo.ios, (function (param) {
                  return Json_decode.field("ios", ios, param);
                }), json),
          web: Json_decode.withDefault(defaults.expo.web, (function (param) {
                  return Json_decode.field("web", web, param);
                }), json),
          extra: Json_decode.withDefault(defaults.expo.extra, (function (param) {
                  return Json_decode.field("extra", extra, param);
                }), json),
          plugins: Json_decode.withDefault(defaults.expo.plugins, (function (param) {
                  return Json_decode.field("plugins", Js_json.decodeArray, param);
                }), json),
          allowFontScaling: Json_decode.withDefault(defaults.expo.allowFontScaling, (function (param) {
                  return Json_decode.field("allowFontScaling", Json_decode.bool, param);
                }), json),
          locales: Json_decode.withDefault(defaults.expo.locales, (function (param) {
                  return Json_decode.field("locales", Js_json.decodeObject, param);
                }), json)
        };
}

function config(json) {
  return {
          expo: Json_decode.field("expo", expo, json)
        };
}

var Decode = {
  androidStatusBar: androidStatusBar,
  iosStatusBar: iosStatusBar,
  androidNavigationBar: androidNavigationBar,
  adaptiveIcon: adaptiveIcon,
  android: android,
  ios: ios,
  notification: notification,
  web: web,
  splash: splash,
  extraEas: extraEas,
  extra: extra,
  expo: expo,
  config: config
};

function androidStatusBar$1(androidStatusBar$2) {
  return Json_encode.object_(Belt_List.fromArray(ArrayUtils$DraftbitBuilder.keepSome([
                      [
                        "hidden",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), androidStatusBar$2.hidden)
                      ],
                      [
                        "barStyle",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), androidStatusBar$2.barStyle)
                      ],
                      [
                        "backgroundColor",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), androidStatusBar$2.backgroundColor)
                      ],
                      [
                        "translucent",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), androidStatusBar$2.translucent)
                      ]
                    ])));
}

function iosStatusBar$1(iosStatusBar$2) {
  return Json_encode.object_(Belt_List.fromArray(ArrayUtils$DraftbitBuilder.keepSome([
                      [
                        "barStyle",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), iosStatusBar$2.barStyle)
                      ],
                      [
                        "hidden",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), iosStatusBar$2.hidden)
                      ],
                      [
                        "networkActivityIndicatorVisible",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), iosStatusBar$2.networkActivityIndicatorVisible)
                      ]
                    ])));
}

function androidNavigationBar$1(androidNavigationBar$2) {
  return Json_encode.object_(Belt_List.fromArray(ArrayUtils$DraftbitBuilder.keepSome([
                      [
                        "visible",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), androidNavigationBar$2.visible)
                      ],
                      [
                        "barStyle",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), androidNavigationBar$2.barStyle)
                      ],
                      [
                        "backgroundColor",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), androidNavigationBar$2.backgroundColor)
                      ]
                    ])));
}

function adaptiveIcon$1(adaptiveIcon$2) {
  return Json_encode.object_({
              hd: [
                "foregroundImage",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), adaptiveIcon$2.foregroundImage)
              ],
              tl: {
                hd: [
                  "monochromeImage",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), adaptiveIcon$2.monochromeImage)
                ],
                tl: {
                  hd: [
                    "backgroundColor",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), adaptiveIcon$2.backgroundColor)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function android$1(android$2) {
  return Json_encode.object_(Belt_List.fromArray(ArrayUtils$DraftbitBuilder.keepSome([
                      [
                        "package",
                        android$2.package
                      ],
                      [
                        "permissions",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), android$2.permissions)
                      ],
                      Belt_Option.map(android$2.config, (function (config) {
                              return [
                                      "config",
                                      AppJsonConfig_AndroidConfig$DraftbitBuilder.t_encode(config)
                                    ];
                            })),
                      [
                        "backgroundColor",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), android$2.backgroundColor)
                      ],
                      [
                        "userInterfaceStyle",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), android$2.userInterfaceStyle)
                      ],
                      [
                        "playStoreUrl",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), android$2.playStoreUrl)
                      ],
                      [
                        "allowBackup",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), android$2.allowBackup)
                      ],
                      [
                        "adaptiveIcon",
                        adaptiveIcon$1(android$2.adaptiveIcon)
                      ],
                      [
                        "accentColor",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), android$2.accentColor)
                      ],
                      [
                        "softwareKeyboardLayoutMode",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), android$2.softwareKeyboardLayoutMode)
                      ]
                    ])));
}

function ios$1(ios$2) {
  return Json_encode.object_({
              hd: [
                "bundleIdentifier",
                ios$2.bundleIdentifier
              ],
              tl: {
                hd: [
                  "infoPlist",
                  AppJsonConfig_InfoPlist$DraftbitBuilder.t_encode(ios$2.infoPlist)
                ],
                tl: {
                  hd: [
                    "usesIcloudStorage",
                    ios$2.usesIcloudStorage
                  ],
                  tl: {
                    hd: [
                      "config",
                      AppJsonConfig_IosConfig$DraftbitBuilder.Encode.encode(ios$2.config)
                    ],
                    tl: {
                      hd: [
                        "supportsTablet",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), ios$2.supportsTablet)
                      ],
                      tl: {
                        hd: [
                          "isTabletOnly",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), ios$2.isTabletOnly)
                        ],
                        tl: {
                          hd: [
                            "requireFullScreen",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), ios$2.requireFullScreen)
                          ],
                          tl: {
                            hd: [
                              "userInterfaceStyle",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), ios$2.userInterfaceStyle)
                            ],
                            tl: {
                              hd: [
                                "associatedDomains",
                                Json_encode.nullable((function (param) {
                                        return Json_encode.array((function (prim) {
                                                      return prim;
                                                    }), param);
                                      }), ios$2.associatedDomains)
                              ],
                              tl: {
                                hd: [
                                  "usesAppleSignIn",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), ios$2.usesAppleSignIn)
                                ],
                                tl: {
                                  hd: [
                                    "appStoreUrl",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), ios$2.appStoreUrl)
                                  ],
                                  tl: {
                                    hd: [
                                      "backgroundColor",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), ios$2.backgroundColor)
                                    ],
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function notification$1(notification$2) {
  return Json_encode.object_({
              hd: [
                "androidMode",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), notification$2.androidMode)
              ],
              tl: {
                hd: [
                  "androidCollapsedTitle",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), notification$2.androidCollapsedTitle)
                ],
                tl: {
                  hd: [
                    "iosDisplayInForeground",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), notification$2.iosDisplayInForeground)
                  ],
                  tl: {
                    hd: [
                      "color",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), notification$2.color)
                    ],
                    tl: {
                      hd: [
                        "androidIcon",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), notification$2.androidIcon)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function splash$1(splash$2) {
  return Json_encode.object_({
              hd: [
                "image",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), splash$2.image)
              ],
              tl: {
                hd: [
                  "resizeMode",
                  splash$2.resizeMode
                ],
                tl: {
                  hd: [
                    "backgroundColor",
                    splash$2.backgroundColor
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function web$1(web$2) {
  return Json_encode.object_({
              hd: [
                "themeColor",
                web$2.themeColor
              ],
              tl: {
                hd: [
                  "backgroundColor",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), web$2.backgroundColor)
                ],
                tl: {
                  hd: [
                    "barStyle",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), web$2.barStyle)
                  ],
                  tl: {
                    hd: [
                      "preferRelatedApplications",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), web$2.preferRelatedApplications)
                    ],
                    tl: {
                      hd: [
                        "display",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), web$2.display)
                      ],
                      tl: {
                        hd: [
                          "orientation",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), web$2.orientation)
                        ],
                        tl: {
                          hd: [
                            "googleMapsApiKey",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), web$2.googleMapsApiKey)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function extraEas$1(extraEas$2) {
  return Json_encode.object_({
              hd: [
                "projectId",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), extraEas$2.projectId)
              ],
              tl: /* [] */0
            });
}

function extra$1(extra$2) {
  return Json_encode.object_({
              hd: [
                "eas",
                extraEas$1(extra$2.eas)
              ],
              tl: /* [] */0
            });
}

function expo$1(expo$2) {
  return Json_encode.object_({
              hd: [
                "name",
                expo$2.name
              ],
              tl: {
                hd: [
                  "description",
                  expo$2.description
                ],
                tl: {
                  hd: [
                    "owner",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), expo$2.owner)
                  ],
                  tl: {
                    hd: [
                      "slug",
                      expo$2.slug
                    ],
                    tl: {
                      hd: [
                        "scheme",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), expo$2.scheme)
                      ],
                      tl: {
                        hd: [
                          "privacy",
                          expo$2.privacy
                        ],
                        tl: {
                          hd: [
                            "platforms",
                            Json_encode.array(encode, expo$2.platforms)
                          ],
                          tl: {
                            hd: [
                              "orientation",
                              expo$2.orientation
                            ],
                            tl: {
                              hd: [
                                "userInterfaceStyle",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), expo$2.userInterfaceStyle)
                              ],
                              tl: {
                                hd: [
                                  "backgroundColor",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), expo$2.backgroundColor)
                                ],
                                tl: {
                                  hd: [
                                    "primaryColor",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), expo$2.primaryColor)
                                  ],
                                  tl: {
                                    hd: [
                                      "icon",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), expo$2.icon)
                                    ],
                                    tl: {
                                      hd: [
                                        "notification",
                                        notification$1(expo$2.notification)
                                      ],
                                      tl: {
                                        hd: [
                                          "splash",
                                          splash$1(expo$2.splash)
                                        ],
                                        tl: {
                                          hd: [
                                            "android",
                                            android$1(expo$2.android)
                                          ],
                                          tl: {
                                            hd: [
                                              "androidStatusBar",
                                              androidStatusBar$1(expo$2.androidStatusBar)
                                            ],
                                            tl: {
                                              hd: [
                                                "iosStatusBar",
                                                iosStatusBar$1(expo$2.iosStatusBar)
                                              ],
                                              tl: {
                                                hd: [
                                                  "androidNavigationBar",
                                                  androidNavigationBar$1(expo$2.androidNavigationBar)
                                                ],
                                                tl: {
                                                  hd: [
                                                    "ios",
                                                    ios$1(expo$2.ios)
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      "web",
                                                      web$1(expo$2.web)
                                                    ],
                                                    tl: {
                                                      hd: [
                                                        "extra",
                                                        extra$1(expo$2.extra)
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          "plugins",
                                                          Json_encode.nullable((function (param) {
                                                                  return Json_encode.array((function (foo) {
                                                                                return foo;
                                                                              }), param);
                                                                }), expo$2.plugins)
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            "allowFontScaling",
                                                            expo$2.allowFontScaling
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              "locales",
                                                              Json_encode.nullable((function (prim) {
                                                                      return prim;
                                                                    }), expo$2.locales)
                                                            ],
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function config$1(config$2) {
  return Json_encode.object_({
              hd: [
                "expo",
                expo$1(config$2.expo)
              ],
              tl: /* [] */0
            });
}

var Encode = {
  androidStatusBar: androidStatusBar$1,
  iosStatusBar: iosStatusBar$1,
  androidNavigationBar: androidNavigationBar$1,
  adaptiveIcon: adaptiveIcon$1,
  android: android$1,
  ios: ios$1,
  notification: notification$1,
  splash: splash$1,
  web: web$1,
  extraEas: extraEas$1,
  extra: extra$1,
  expo: expo$1,
  config: config$1
};

function config_decode(param) {
  return JsonUtils$DraftbitBuilder.makeDeccoDecoder(config, param);
}

var config_encode = config$1;

export {
  Platform ,
  defaults ,
  setAndroidGoogleMapsKey ,
  setIosGoogleMapsKey ,
  setWebGoogleMapsKey ,
  Decode ,
  Encode ,
  config_encode ,
  config_decode ,
  
}
/* JsonUtils-DraftbitBuilder Not a pure module */
