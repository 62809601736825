/* TypeScript file generated from LoadingSpinner.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const LoadingSpinnerBS = require('./LoadingSpinner.bs');

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly className?: string; 
  readonly inline: boolean; 
  readonly label?: string; 
  readonly paddingBottom?: string; 
  readonly paddingLeft?: string; 
  readonly size?: number; 
  readonly theme?: 
    "Light"
};

export const make: React.ComponentType<{
  readonly className?: string; 
  readonly inline: boolean; 
  readonly label?: string; 
  readonly paddingBottom?: string; 
  readonly paddingLeft?: string; 
  readonly size?: number; 
  readonly theme?: 
    "Light"
}> = LoadingSpinnerBS.make;

// tslint:disable-next-line:interface-over-type-literal
export type $$default_Props = {
  readonly className?: string; 
  readonly inline: boolean; 
  readonly label?: string; 
  readonly paddingBottom?: string; 
  readonly paddingLeft?: string; 
  readonly size?: number; 
  readonly theme?: 
    "Light"
};

export const $$default: React.ComponentType<{
  readonly className?: string; 
  readonly inline: boolean; 
  readonly label?: string; 
  readonly paddingBottom?: string; 
  readonly paddingLeft?: string; 
  readonly size?: number; 
  readonly theme?: 
    "Light"
}> = LoadingSpinnerBS.default;

export default $$default;
