/* TypeScript file generated from Utils.res by genType. */
/* eslint-disable import/first */


// tslint:disable-next-line:no-var-requires
const Curry = require('bs-platform/lib/es6/curry.mjs');

// tslint:disable-next-line:no-var-requires
const UtilsBS = require('./Utils.bs');

import {apolloClient as Hooks_apolloClient} from '../../src/hooks/Hooks.gen';

export const tapLog: <T1,T2>(value:T1, description:T2) => T1 = function <T1,T2>(Arg1: any, Arg2: any) {
  const result = Curry._2(UtilsBS.tapLog, Arg1, Arg2);
  return result
};

export const tapLogIf: <T1,T2>(value:T1, description:T2, cond:((_1:T1) => boolean)) => T1 = function <T1,T2>(Arg1: any, Arg2: any, Arg3: any) {
  const result = Curry._3(UtilsBS.tapLogIf, Arg1, Arg2, Arg3);
  return result
};

export const tapLogMap: <T1,T2,T3>(value:T1, description:T2, f:((_1:T1) => T3)) => T1 = function <T1,T2,T3>(Arg1: any, Arg2: any, Arg3: any) {
  const result = Curry._3(UtilsBS.tapLogMap, Arg1, Arg2, Arg3);
  return result
};

export const tapLogMapIf: <T1,T2,T3>(value:T1, description:T2, cond:((_1:T1) => boolean), f:((_1:T1) => T3)) => T1 = function <T1,T2,T3>(Arg1: any, Arg2: any, Arg3: any, Arg4: any) {
  const result = Curry._4(UtilsBS.tapLogMapIf, Arg1, Arg2, Arg3, Arg4);
  return result
};

export const tapLogOpt: <T1,T2>(value:(null | undefined | T1), description:T2) => (null | undefined | T1) = function <T1,T2>(Arg1: any, Arg2: any) {
  const result = Curry._2(UtilsBS.tapLogOpt, (Arg1 == null ? undefined : Arg1), Arg2);
  return result
};

export const resetStorageAndLogout: (_1:{ readonly client?: Hooks_apolloClient }, param:void) => void = function (Arg1: any, Arg2: any) {
  const result = Curry._2(UtilsBS.resetStorageAndLogout, Arg1.client, Arg2);
  return result
};
