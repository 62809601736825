// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as QueryString from "query-string";

function parse(s) {
  return QueryString.parse(s, {
              arrayFormat: "bracket"
            });
}

function stringifyArray(arr) {
  return QueryString.stringify(Js_dict.fromArray(arr));
}

export {
  parse ,
  stringifyArray ,
  
}
/* query-string Not a pure module */
