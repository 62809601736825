// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as JsSet from "rescript-js-collections/src/JsSet.bs.js";
import * as React from "@rescript/react/src/React.bs.js";
import * as React$1 from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.mjs";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Spread$DraftbitBuilder from "../components/Spread.bs.js";
import * as ArrayUtils$DraftbitBuilder from "./ArrayUtils.bs.js";

function mapArray(arr, ifEmpty, f) {
  if (arr.length === 0 && ifEmpty !== undefined) {
    return Caml_option.valFromOption(ifEmpty);
  }
  return arr.map(function (x, i) {
              return Curry._2(f, String(i), x);
            });
}

function mapArrayI(arr, f) {
  return arr.map(function (x, i) {
              return Curry._2(f, i, x);
            });
}

function mapArraySep(arr, ifEmpty, sep, f) {
  if (arr.length === 0 && ifEmpty !== undefined) {
    return Caml_option.valFromOption(ifEmpty);
  }
  return ArrayUtils$DraftbitBuilder.intersperse(arr.map(Curry.__1(f)), sep).map(function (e, key) {
              return React$1.createElement(Spread$DraftbitBuilder.make, {
                          props: {
                            key: key
                          },
                          children: e
                        });
            });
}

function mapList(l, f) {
  return mapArray(Belt_List.toArray(l), undefined, f);
}

function mapDict(dict, ifEmptyOpt, f) {
  var ifEmpty = ifEmptyOpt !== undefined ? Caml_option.valFromOption(ifEmptyOpt) : null;
  var entries = Js_dict.entries(dict);
  if (entries.length !== 0) {
    return entries.map(function (param) {
                return Curry._2(f, param[0], param[1]);
              });
  } else {
    return ifEmpty;
  }
}

function mapSet(set, f) {
  return mapArray(JsSet.toArray(set), undefined, f);
}

function ifTrueRender(cond, elem) {
  if (cond) {
    return elem;
  } else {
    return null;
  }
}

function maybeRender(o, f) {
  if (o !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(o));
  } else {
    return null;
  }
}

function opt(o) {
  return Belt_Option.getWithDefault(o, null);
}

var $$Option = {
  some: Belt_Option.map,
  none: Belt_Option.getWithDefault
};

function ReactUtils$MaybeRender(Props) {
  var children = Props.children;
  if (children !== undefined) {
    return Caml_option.valFromOption(children);
  } else {
    return null;
  }
}

var MaybeRender = {
  make: ReactUtils$MaybeRender
};

var CopyToClipboard = {};

var HTMLInput = {};

function getData(e) {
  try {
    return (e.clipboardData.getData("text/plain"));
  }
  catch (exn){
    return ;
  }
}

var $$ClipboardEvent = {
  getData: getData
};

function isCtrlKeyPress(e) {
  if (e.ctrlKey) {
    return true;
  } else {
    return e.metaKey;
  }
}

function isCtrlClick(e) {
  if (e.ctrlKey) {
    return true;
  } else {
    return e.metaKey;
  }
}

var Ref = React.Ref;

var Children = React.Children;

var Context = React.Context;

var Fragment = React.Fragment;

var StrictMode = React.StrictMode;

var Suspense = React.Suspense;

var Experimental = React.Experimental;

var Uncurried = React.Uncurried;

export {
  Ref ,
  Children ,
  Context ,
  Fragment ,
  StrictMode ,
  Suspense ,
  Experimental ,
  Uncurried ,
  mapArray ,
  mapArrayI ,
  mapArraySep ,
  mapList ,
  mapDict ,
  mapSet ,
  ifTrueRender ,
  maybeRender ,
  opt ,
  $$Option ,
  MaybeRender ,
  CopyToClipboard ,
  HTMLInput ,
  $$ClipboardEvent ,
  isCtrlKeyPress ,
  isCtrlClick ,
  
}
/* react Not a pure module */
