import React, { useEffect } from "react";
import frontendPackageJson from "../package.json";
import { compare, diff } from "semver";
import { minutesToSeconds } from "date-fns";

declare global {
  interface WindowEventMap {
    serverVersionChanged: CustomEvent;
  }
}

export function updateServerVersion(serverVersion: string) {
  window.dispatchEvent(
    new CustomEvent("serverVersionChanged", {
      detail: {
        serverVersion,
      },
    })
  );
}

function MinorOrPatchVersionBump() {
  return (
    <div className="flex flex-row p-2 bg-selection-600 text-white font-bold items-center w-full gap-4 justify-center">
      <span>A new version of Draftbit is available!</span>
      <button
        className="px-4 py-1 rounded-md bg-white text-selection-600 animate-bounce"
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh Page
      </button>
    </div>
  );
}

const maxElapsedTime = minutesToSeconds(3);

function MajorVersionBump() {
  const [elapsedTime, setElapsedTime] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (elapsedTime > maxElapsedTime) {
      window.location.reload();
    }
  }, [elapsedTime]);

  const remainingTime = maxElapsedTime - elapsedTime;

  return (
    <div className="flex flex-col p-4 bg-red-500 text-white font-bold items-center w-full gap-2">
      <span>A new major version of Draftbit is available!</span>
      <button
        className="px-4 py-1 rounded-md bg-white text-red-500"
        onClick={() => {
          window.location.reload();
        }}
      >
        {`Refresh Page ${
          remainingTime > 0
            ? `(or wait ${maxElapsedTime - elapsedTime} seconds)`
            : "(Refreshing...)"
        }`}
      </button>
    </div>
  );
}

export default function OutdatedFrontendVersionBanner() {
  const [serverVersion, setServerVersion] = React.useState(undefined);

  React.useEffect(() => {
    return window.addEventListener(
      "serverVersionChanged",
      (event: CustomEvent) => {
        setServerVersion(event.detail.serverVersion);
      }
    );
  }, []);

  if (serverVersion === undefined) {
    return null;
  }

  const versionDiff = diff(frontendPackageJson.version, serverVersion);
  const isFrontendVersionLower =
    compare(frontendPackageJson.version, serverVersion) === -1;

  if (isFrontendVersionLower) {
    switch (versionDiff) {
      case "major":
      case "premajor":
        return <MajorVersionBump />;
      case "minor":
      case "preminor":
      case "patch":
        return <MinorOrPatchVersionBump />;
      case "prepatch":
      case "prerelease":
      case null:
        return null;
    }
  }

  return null;
}
