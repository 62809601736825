import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowCircleDown,
  faBookOpen,
  faBoxBallot,
  faCheck,
  faCheckCircle,
  faCode,
  faCog,
  faExclamationTriangle,
  faImage,
  faKeyboard,
  faLayerGroup,
  faMegaphone,
  faMinus,
  faMobile,
  faMobileAlt,
  faPen,
  faPenSquare,
  faPhotoVideo,
  faPlay,
  faPlayCircle,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faRocket,
  faShareAltSquare,
  faSitemap,
  faPlusCircle,
  faFileCheck,
  faStream,
  faSwatchbook,
  faUndo,
  faUserCircle,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faArrowCircleRight as falArrowCircleRight,
  faArrowCircleUp as falArrowCircleUp,
  faBookOpen as falBookOpen,
  faCheck as falCheck,
  faCheckCircle as falCheckCircle,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faExternalLink as falExternalLink,
  faFontCase as falFontCase,
  faMobileAlt as falMobileAlt,
  faPlusCircle as falPlusCircle,
  faSearch as falSearch,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faPlus as falPlus,
  faWandMagicSparkles as falWandMagicSparkles,
  faDatabase as falDatabase,
  faSquareSlidersVertical as falSquareSlidersVertical,
  faPaintBrushFine as falPaintBrushFine,
} from "@fortawesome/pro-light-svg-icons";

import {
  faBan as fasBan,
  faBrowser as fasBrowser,
  faBookOpen as fasBookOpen,
  faBracketsCurly as fasBracketsCurly,
  faCode as fasCode,
  faCodeBranch as fasCodeBranch,
  faCog as fasCog,
  faEyeDropper as fasEyeDropper,
  faInfoCircle as fasInfoCircle,
  faMobileAlt as fasMobileAlt,
  faPaperPlane as fasPaperPlane,
  faThLarge as fasThLarge,
  faPen as fasPen,
  faPlay as fasPlay,
  faPlus as fasPlus,
  faRedo as fasRedo,
  faRocket as fasRocket,
  faExternalLink as fasExternalLink,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faAngleDown as farAngleDown,
  faAngleRight as farAngleRight,
  faArrowToRight as farArrowToRight,
  faPlusCircle as farPlusCircle,
  faClone as farClone,
  faCopy as farCopy,
  faDatabase as farDatabase,
  faEllipsisH as farEllipsisH,
  faEllipsisV as farEllipsisV,
  faExternalLink as farExternalLink,
  faSave as farSave,
  faTrash as farTrash,
  faTrashAlt as farTrashAlt,
  faUndo as farUndo,
  faPlus as farPlus,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faArrowCircleDown,
  faBookOpen,
  faBoxBallot,
  faCheck,
  faCheckCircle,
  faCode,
  faCog,
  falDatabase,
  faExclamationTriangle,
  faImage,
  faKeyboard,
  faLayerGroup,
  falWandMagicSparkles,
  faMegaphone,
  faMinus,
  faMobile,
  faMobileAlt,
  falPaintBrushFine,
  faPen,
  faPenSquare,
  faPhotoVideo,
  faPlay,
  faPlayCircle,
  fasPlus,
  faPlusSquare,
  faPlusCircle,
  faFileCheck,
  faQuestionCircle,
  farDatabase,
  farArrowToRight,
  faRedo,
  faRocket,
  faShareAltSquare,
  faSitemap,
  falSquareSlidersVertical,
  faStream,
  faSwatchbook,
  faUndo,
  faUserCircle,
  faUsers,
  falArrowCircleRight,
  falArrowCircleUp,
  falBookOpen,
  falCheck,
  falCheckCircle,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falExternalLink,
  falFontCase,
  falMobileAlt,
  falPlus,
  falPlusCircle,
  falSearch,
  falTimes,
  falTimesCircle,
  farAngleDown,
  farAngleRight,
  farPlusCircle,
  farClone,
  farCopy,
  farEllipsisH,
  farEllipsisV,
  farExternalLink,
  farPlus,
  farSave,
  farTrash,
  farTrashAlt,
  farUndo,
  fasBan,
  fasBracketsCurly,
  fasBrowser,
  fasBookOpen,
  fasCog,
  fasCode,
  fasCodeBranch,
  fasEyeDropper,
  fasExternalLink,
  fasInfoCircle,
  fasMobileAlt,
  fasPaperPlane,
  fasPen,
  fasPlay,
  fasPlus,
  fasRedo,
  fasRocket,
  fasThLarge
);
