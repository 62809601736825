import * as React from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const ScrollContainer = ({ className, children, overflowX = "hidden" }) => {
  return (
    <OverlayScrollbarsComponent
      className={"os-theme-dark " + className}
      options={{
        autoUpdateInterval: 40,
        overflowBehavior: {
          x: overflowX,
          y: "scroll",
        },
        scrollbars: { autoHide: "scroll" },
      }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};

export default ScrollContainer;
