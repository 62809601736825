// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import * as Hooks$DraftbitBuilder from "../../../hooks/Hooks.bs.js";
import * as Utils$DraftbitBuilder from "../../../utilities/Utils.bs.js";
import * as Tooltip$DraftbitBuilder from "./Tooltip.bs.js";

function HoverTooltip(Props) {
  var content = Props.content;
  var className = Props.className;
  var children = Props.children;
  var sizeOpt = Props.size;
  var placement = Props.placement;
  var offset = Props.offset;
  var overlayDataCy = Props.overlayDataCy;
  var overlayClassName = Props.overlayClassName;
  var dataCy = Props.dataCy;
  var forceVisibleOpt = Props.forceVisible;
  var size = sizeOpt !== undefined ? sizeOpt : /* Fluid */5;
  var forceVisible = forceVisibleOpt !== undefined ? forceVisibleOpt : false;
  var match = Hooks$DraftbitBuilder.Hover.useHover(undefined);
  var tmp = {
    size: size,
    content: Belt_Option.getWithDefault(content, null),
    visible: Belt_Option.isSome(content) && (match[1] || forceVisible),
    onClose: Utils$DraftbitBuilder.noop,
    children: React.createElement("div", {
          ref: match[0],
          className: "h-full"
        }, children)
  };
  if (placement !== undefined) {
    tmp.placement = Caml_option.valFromOption(placement);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (overlayDataCy !== undefined) {
    tmp.overlayDataCy = Caml_option.valFromOption(overlayDataCy);
  }
  if (overlayClassName !== undefined) {
    tmp.overlayClassName = Caml_option.valFromOption(overlayClassName);
  }
  if (dataCy !== undefined) {
    tmp.dataCy = Caml_option.valFromOption(dataCy);
  }
  return React.createElement(Tooltip$DraftbitBuilder.make, tmp);
}

var make = HoverTooltip;

export {
  make ,
  
}
/* react Not a pure module */
