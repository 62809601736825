// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";
import GraphqlTag from "graphql-tag";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.mjs";

function ReadQuery(Config) {
  var graphqlQueryAST = GraphqlTag(Config.query);
  var apolloDataToRecord = function (apolloData) {
    return Belt_Option.map((apolloData == null) ? undefined : Caml_option.some(apolloData), Config.parse);
  };
  var make = function (client, variables, param) {
    var apolloData = client.readQuery({
          query: graphqlQueryAST,
          variables: Belt_Option.getWithDefault(variables, null)
        });
    return Belt_Option.map((apolloData == null) ? undefined : Caml_option.some(apolloData), Config.parse);
  };
  return {
          graphqlQueryAST: graphqlQueryAST,
          apolloDataToRecord: apolloDataToRecord,
          make: make
        };
}

function WriteQuery(Config) {
  var graphqlQueryAST = GraphqlTag(Config.query);
  var make = function (client, variables, data, param) {
    client.writeQuery({
          query: graphqlQueryAST,
          variables: Js_null_undefined.fromOption(variables),
          data: data
        });
    
  };
  return {
          graphqlQueryAST: graphqlQueryAST,
          make: make
        };
}

export {
  ReadQuery ,
  WriteQuery ,
  
}
/* graphql-tag Not a pure module */
